import { differenceInMinutes, differenceInYears, parseISO } from "date-fns";
import { AppointmentCardProps, AppointmentStatus } from "../types/appointments";

export const specializationMapping: Record<string, string> = {
  PRIMARY_CARE: "General Physician",
  NEUROLOGIST: "Neurologist",
  PEDIATRICS: "Pediatrician",
};

export const getAppointmentStatus = (
  startTimeString: string,
  endTimeString: string
): AppointmentStatus => {
  const startTime = new Date(startTimeString);
  const endTime = new Date(endTimeString);
  const now = new Date();

  // Helper function to check if a given date is today
  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  if (isToday(startTime) || isToday(endTime)) {
    // Check if the current time is before the end time
    if (now <= endTime) {
      return "ONGOING";
    } else {
      return "COMPLETED";
    }
  }

  // Check if the appointment is in the future
  if (startTime > now) {
    return "UPCOMING";
  }

  // Check if the appointment is today

  // Otherwise, the appointment is in the past
  return "COMPLETED";
};

export const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "ongoing":
      return "var(--neuro-button-bg-success)";
    case "upcoming":
      return "var(--neuro-button-bg-primary)";
    case "completed":
      return "var(--neuro-grey-variant1)";
    default:
      return "var(--neuro-grey-variant1)";
  }
};

export const getStatusRank = (status: string): number => {
  const statusRank: { [key in AppointmentStatus | "CANCELLED"]: number } = {
    ONGOING: 1,
    UPCOMING: 2,
    COMPLETED: 3,
    CANCELLED: 4,
  };

  return statusRank[status as AppointmentStatus | "CANCELLED"];
};

export const sortAppointments = (appointments: AppointmentCardProps[]) => {
  return appointments.sort((a, b) => {
    const statusA =
      a.status === "CANCELLED"
        ? "CANCELLED"
        : getAppointmentStatus(
            a.availableSlot?.startTime,
            a.availableSlot?.endTime
          );

    const statusB =
      b.status === "CANCELLED"
        ? "CANCELLED"
        : getAppointmentStatus(
            b.availableSlot?.startTime,
            b.availableSlot?.endTime
          );

    return getStatusRank(statusA) - getStatusRank(statusB);
  });
};

export const sortAppoointmentsByDateTime = (
  appointments: AppointmentCardProps[]
) => {
  return appointments.sort(
    (a, b) =>
      new Date(b.availableSlot?.startTime).getTime() -
      new Date(a.availableSlot?.startTime).getTime()
  );
};

export const extractAppointmentId = (input: string): string => {
  const prefix = "receipt_order_";
  const index = input.indexOf(prefix);
  if (index !== -1) {
    return input.substring(index + prefix.length);
  }
  return ""; // Return an empty string if the prefix is not found
};

export const handleOpenMap = (hospitalAddress: string) => {
  const destination = hospitalAddress;
  const defaultOrigin = "";

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const origin = `${latitude},${longitude}`;
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${encodeURIComponent(destination)}`;

        window.open(googleMapsUrl, "_blank");
      },
      (error) => {
        console.error(error);
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(defaultOrigin)}&destination=${encodeURIComponent(destination)}`;
        window.open(googleMapsUrl, "_blank");
      }
    );
  } else {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(defaultOrigin)}&destination=${encodeURIComponent(destination)}`;
    window.open(googleMapsUrl, "_blank");
  }
};

export const getFullHospitalAddress = (address: any) => {
  let hospitalAddress = "";
  if (address?.streetAddress && address?.streetAddress !== "") {
    hospitalAddress = hospitalAddress + `${address?.streetAddress},`;
  }
  if (address?.city && address?.city !== "") {
    hospitalAddress = hospitalAddress + `${address?.city},`;
  }
  if (address?.state && address?.state !== "") {
    hospitalAddress = hospitalAddress + `${address?.state},`;
  }
  if (address?.zipCode && address?.zipCode !== "") {
    hospitalAddress = hospitalAddress + `${address?.zipCode},`;
  }
  if (address?.country && address?.country !== "") {
    hospitalAddress = hospitalAddress + `${address?.country}`;
  }
  return hospitalAddress;
};

export const getTimeDiffereForOngoing = (
  appointmentDateString: string
): number => {
  const now = new Date();
  const appointmentDate = parseISO(appointmentDateString);
  const timeDifference = differenceInMinutes(appointmentDate, now);
  return timeDifference;
};

export const getLocalTimeString = (dateString: string) => {
  const utcDateFormat = new Date(dateString);

  const localTimeString = utcDateFormat.toLocaleString();
  return localTimeString;
};

export const calculateAge = (dateString: string): number | string => {
  if (!dateString || dateString === "") {
    return "";
  }
  const birthDate = parseISO(dateString);

  const age = differenceInYears(new Date(), birthDate);

  return age;
};

export const calculateAgeInYearsOrMonths = (dateString: string): string => {
  if (!dateString || dateString === "") {
    return "";
  }

  let birthDate: Date;

  if (!isNaN(Date.parse(dateString))) {
    birthDate = new Date(dateString);
  } else {
    const [day, month, year] = dateString.split("-").map(Number);
    birthDate = new Date(year, month - 1, day);
  }

  const today = new Date();
  let ageInYears = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    ageInYears--;
  }

  if (ageInYears >= 1) {
    if (ageInYears === 1) {
      return `1 yr`;
    }
    return `${ageInYears} yrs`;
  } else {
    const months =
      (today.getFullYear() - birthDate.getFullYear()) * 12 + monthDiff;
    return `${months} mths`;
  }
};

export const getFormattedDateTime = (dateString: string) => {
  if (!dateString) return "";

  const dateObject = new Date(dateString);

  if (isNaN(dateObject.getTime())) return "";
  const day = dateObject.getDate().toString().padStart(2, "0"); // Get the day (numeric)
  const month = dateObject.toLocaleString(undefined, { month: "long" }); // Get the month (long name)
  const year = dateObject.getFullYear(); // Get the year (numeric)
  const monthNumber = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const hours = dateObject.getHours(); // Get hours in 24-hour format
  const minutes = dateObject.getMinutes(); // Get minutes

  // Convert hours to 12-hour format and determine AM or PM
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedHoursWithoutZero = hours % 12 || 12;
  const amOrPm = hours >= 12 ? "pm" : "am";

  return {
    day,
    month,
    year,
    hoursWithoutZero: formattedHoursWithoutZero,
    hours: formattedHours,
    minutes: minutes.toString().padStart(2, "0"),
    amOrPm,
    monthNumber,
  };
};
