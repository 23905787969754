import api from "./apiService";

export const loadAdminDashboard = (start: string, end: string) => {
  const params = {
    start,
    end,
  };
  return api.get(`/api/dashboard`, { params });
};

export const loadDoctorDashboard = (start: string, end: string) => {
  const params = {
    start,
    end,
  };
  return api.get(`/api/dashboard/doctor`, { params });
};

export const loadPatientDashboard = () => {
  return api.get(`/api/dashboard/patient`);
};
