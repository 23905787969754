import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Documents from "../documents/list";
import ReportViewer from "../documents/view/ReportViewer";
import { DocumentsProps } from "../../types/documents";
import SingleReportViewer from "../documents/view/SingleReportViewer";
import useAuthStore from "../../store/authStore";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import useAppStore from "../../store/appStore";

const PatientDocuments: React.FC<DocumentsProps> = ({
  patientId,
  from = "",
}) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  const { documentsConferenceView } = useAppStore();

  useEffect(() => {
    if (!patientId) {
      if (isDoctorOrAdminOrClinicAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [patientId]);

  return (
    <>
      {from === "meet" ? (
        <>
          {documentsConferenceView === "List" && (
            <Documents patientId={patientId} from="meet" />
          )}

          {documentsConferenceView === "Details" && (
            <ReportViewer patientId={patientId} from="meet" />
          )}

          {documentsConferenceView === "Single" && (
            <SingleReportViewer from="meet" />
          )}
        </>
      ) : (
        <>
          {window?.location?.pathname.includes("view") ? (
            <ReportViewer patientId={patientId} />
          ) : window?.location?.pathname.includes("details") ? (
            <SingleReportViewer />
          ) : (
            <Documents patientId={patientId} from={from} />
          )}
        </>
      )}
    </>
  );
};

export default PatientDocuments;
