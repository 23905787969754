// import React, { useState, useEffect } from "react";
// import { FaCopy, FaTrash, FaStar, FaRegStar } from "react-icons/fa";
// // import "./component.css";
// // import './compo.css';

// interface Choice {
//   value: string | boolean;
//   text: string;
//   score?: number;
// }

// interface RadioGroupQuestion {
//   type: string;
//   name: string;
//   title: string;
//   choices: Choice[];
//   isRequired: boolean;
// }

// interface DynamicRadioGroupProps {
//   question: RadioGroupQuestion;
//   index: number;
//   handleDuplicateQuestion: (index: number) => void;
//   handleRemoveQuestion: (index: number) => void;
//   updateQuestion: (index: number, updatedQuestion: RadioGroupQuestion) => void;
// }

// const DynamicRadioGroup: React.FC<DynamicRadioGroupProps> = ({
//   question,
//   index,
//   handleDuplicateQuestion,
//   handleRemoveQuestion,
//   updateQuestion,
// }) => {
//   const [localQuestion, setLocalQuestion] = useState<RadioGroupQuestion>(question);

//   // Sync local state with parent prop changes
//   useEffect(() => {
//     setLocalQuestion(question);
//   }, [question]);

//   // Debounced update to parent component
//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       updateQuestion(index, { ...localQuestion });
//     }, 200);
//     return () => clearTimeout(timeout);
//   }, [localQuestion, index, updateQuestion]);

//   // Handle title change
//   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newTitle = e.target.value;
//     setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
//   };

//   // Handle required toggle
//   const handleToggleRequired = () => {
//     setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
//   };

//   // Handle choices update
//   const handleChoiceChange = (
//     choiceIndex: number,
//     field: keyof Choice,
//     value: string | boolean | number | undefined
//   ) => {
//     const updatedChoices = localQuestion.choices.map((choice, idx) =>
//       idx === choiceIndex ? { ...choice, [field]: value } : choice
//     );
//     setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
//   };

//   // Handle radio button selection
//   const handleRadioSelect = (choiceIndex: number) => {
//     const updatedChoices = localQuestion.choices.map((choice, idx) =>
//       idx === choiceIndex ? { ...choice, value: true } : { ...choice, value: false }
//     );
//     setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
//   };

//   // Add a new choice
//   const addChoice = () => {
//     setLocalQuestion((prev) => ({
//       ...prev,
//       choices: [
//         ...prev.choices,
//         { value: false, text: "New Choice", score: undefined },
//       ],
//     }));
//   };

//   // Remove a choice
//   const removeChoice = (choiceIndex: number) => {
//     const updatedChoices = localQuestion.choices.filter(
//       (_, idx) => idx !== choiceIndex
//     );
//     setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
//   };

//   return (
//     <div className="question-container">
//       {/* Title Input */}
//       <div className="question-header">
//       {localQuestion.name} {" "}
//         <input
//           type="text"
//           value={localQuestion.title}
//           onChange={handleTitleChange}      
//           className="question-name-input"
//           placeholder="Enter question title"
//         />
//         {localQuestion.isRequired && <span className="required">*</span>}
//       </div>

//       {/* Choices */}
//       <div className="radio-group-choices">
//         {localQuestion.choices.map((choice, idx) => ( 
//           <div key={idx} className="choice-item">
//                <button
//               className="remove-button"
//               onClick={() => removeChoice(idx)}
//             > -
//               {/* <FaTrash style={{ color: "red" }} /> */}
//             </button>
//             <input
//               type="radio"
//               name={`question-${index}`} // Ensures only one radio is selected at a time
//               checked={choice.value === true}
//               onChange={() => handleRadioSelect(idx)} // Handles radio selection
//               className="radio"
//             />
//             <input
//               type="text"
//               value={choice.text}
//               onChange={(e) =>
//                 handleChoiceChange(idx, "text", e.target.value)
//               }
//               placeholder="Choice text"
//               className="edit-label-input"
//             />
//             {/* <input
//               type="number"
//               value={choice.score || ""}
//               onChange={(e) =>
//                 handleChoiceChange(
//                   idx,
//                   "score",
//                   e.target.value ? parseFloat(e.target.value) : undefined
//                 )
//               }
//               placeholder="Score"
//               className="choice-score-input"
//             /> */}
         
//           </div>
//         ))}
//         <button className="add-button" onClick={addChoice}>
//           +
//         </button>
//       </div>

//       {/* Control Buttons */}
//       <div className="question-controls">
//         <button
//           className="duplicate-button button-spacing blue-bg"
//           onClick={() => handleDuplicateQuestion(index)}
//         >
//      Duplicate
//         </button>

//         <button
//           className="delete-button button-spacing red-bg"
//           onClick={() => handleRemoveQuestion(index)}
//         >
//        Delete
//         </button>

//         <button
//           className={`required-button ${
//             localQuestion.isRequired ? "active1" : ""
//           }`}
//           onClick={handleToggleRequired}
//           aria-pressed={localQuestion.isRequired}
//         >
       
//           {localQuestion.isRequired ? " Required" : " Not Required"}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DynamicRadioGroup;


import React, { useState, useEffect } from "react";


interface Choice {
  value: string | boolean | number;
  text: string;
  score?: number;
}

interface RadioGroupQuestion {
  type: string;
  name: string;
  title: string;
  choices: Choice[];
  isRequired: boolean;
  showScore: boolean;
}

interface DynamicRadioGroupProps {
  question: RadioGroupQuestion;
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: RadioGroupQuestion) => void;
}

const DynamicRadioGroup: React.FC<DynamicRadioGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [localQuestion, setLocalQuestion] =
    useState<RadioGroupQuestion>(question);

  useEffect(() => {
    setLocalQuestion(question);
  }, [question]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateQuestion(index, { ...localQuestion });
    }, 200);
    return () => clearTimeout(timeout);
  }, [localQuestion, index, updateQuestion]);

  const handleChoiceChange = (
    choiceIndex: number,
    field: keyof Choice,
    value: string | boolean | number | undefined
  ) => {
    const updatedChoices = localQuestion.choices.map((choice, idx) => {
      if (idx === choiceIndex) {
        const updatedChoice = { ...choice, [field]: value };

        if (field === "score" && typeof value === "number") {
          updatedChoice.value = value; // Sync value with score
        }

        return updatedChoice;
      }
      return choice;
    });

    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  const addChoice = () => {
    setLocalQuestion((prev) => ({
      ...prev,
      choices: [
        ...prev.choices,
        { value: false, text: "New Choice", score: undefined },
      ],
    }));
  };

  const removeChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter(
      (_, idx) => idx !== choiceIndex
    );
    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  return (
    <div className="question-container">
      <div className="question-header">
        {localQuestion.name}{" "}
        <input
          type="text"
          value={localQuestion.title}
          onChange={(e) =>
            setLocalQuestion((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
          className="question-name-input"
          placeholder="Enter question title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="radio-group-choices">
        {localQuestion.choices.map((choice, idx) => (
          <div key={idx} className="choice-item">
            <button className="remove-button" onClick={() => removeChoice(idx)}>
              -
            </button>
            <input
              type="radio"
              name={`question-${index}`}
              checked={choice.value === true}
              onChange={() =>
                handleChoiceChange(idx, "value", true) // Ensures only one is selected
              }
              className="radio"
            />
            <input
              type="text"
              value={choice.text}
              onChange={(e) =>
                handleChoiceChange(idx, "text", e.target.value)
              }
              placeholder="Choice text"
              className="edit-label-input"
            />
            {localQuestion.showScore && (
              <input
                type="number"
                value={choice.score || ""}
                onChange={(e) =>
                  handleChoiceChange(
                    idx,
                    "score",
                    e.target.value ? parseFloat(e.target.value) : undefined
                  )
                }
                placeholder="Score"
                className="choice-score-input"
              />
            )}
          </div>
        ))}
        <button className="add-button" onClick={addChoice}>
          +
        </button>
      </div>

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>

        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>

        <button
          className={`required-button ${
            localQuestion.isRequired ? "active1" : ""
          }`}
          onClick={() =>
            setLocalQuestion((prev) => ({
              ...prev,
              isRequired: !prev.isRequired,
            }))
          }
          aria-pressed={localQuestion.isRequired}
        >
          {localQuestion.isRequired ? "Required" : "Not Required"}
        </button>
      </div>
    </div>
  );
};

export default DynamicRadioGroup;
