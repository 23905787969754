import React, { useEffect, useState } from 'react';
// import './App2.css';



import DynamicTextField from '../DynamicTextField/DynamicTextField';
import DynamicRadioGroup from '../DynamicRadioGroup/DynamicRadioGroup';
import DynamicCheckboxGroup from '../DynamicCheckboxGroup';
import DynamicSelect from '../DynamicSelect';

import DynamicSelectMulti from '../DynamicSelectMulti';
import DynamicRatingGroup from '../DynamicRatingGroup';
import FileInput from '../FileInput';
import DynamicRanking from '../DynamicRanking';
import DynamicBooleanSwitchGroup from '../DynamicBooleanSwitchGroup';


import { Box } from '@mui/system';
import {  List } from '@mui/material';


import {  useLocation, useNavigate, useParams } from 'react-router-dom';

import AddNewFormCompFormid from './AddNewFormCompFormid';
import { submitFormResponseApisuryvegetcall, submitFormResponseApisuryveputcall } from '../../../services/formService';
import AddNewFormComp from '../AddNewFormComp';
import SurveyComponent1 from '../SurveyComponents/SurveyComponent1';
import SurveyComponenttext from '../SurveyComponents/SurveyComponenttext';
import DynamicImagePickerNot from '../DynamicImagePickerNot';


  const FormpagesEdit: React.FC<{ navlinks: string; submitTrigger: boolean; submitTriggerQuestion:boolean; }> = ({ navlinks, submitTrigger  ,submitTriggerQuestion}) => {
    const [selectedTab, setSelectedTab] = useState<string>(navlinks);
    useEffect(() => {
        setSelectedTab(navlinks);
    }, [navlinks]); // Runs whenever `navlinks` changes
    console.log("Formpages received navlinks:", navlinks);
    const [isonclick, setIsOnClick] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [apiresponse, setApiResponse] = useState<any>({ name: '', id: '', questions: { pages: [] } });

    const [questionData, setQuestionData] = useState<any[]>([]);
    const userId = localStorage.getItem("formid");








    useEffect(() => {
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages];
            updatedPages[currentPage - 1].elements = questionData; // Update the current page's elements
            return { ...prev, pages: updatedPages };
        });
    }, [questionData, currentPage]);

    
    
    const isScoreBased = localStorage.getItem("isScoreBased") === "true";
    useEffect(() => {
        if (questionData.length > 0) {
            // Save updated questionData to localStorage whenever it changes
            localStorage.setItem("questionData", JSON.stringify(questionData));
        }
    }, [questionData]);

    // const [allData, setAllData] = useState<any>({
    //     pages: [
    //         {
    //             name: "page1",
    //             elements: [
    //                 [],
    //             ],
    //         },
    //     ],
    // });
    const [allData, setAllData] = useState<any>({
        pages: [
            {
                name: "page1",
                elements: [
                    [],
                ],
            },
        ],
    });





    const handlePageChange = (pageIndex: number) => {
        // Save the current questionData back to the allData structure
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages];
            updatedPages[currentPage - 1].elements = questionData; // Save current questionData
            return { ...prev, pages: updatedPages };
        });

        // Load the new page's data into questionData
        setQuestionData(allData.pages[pageIndex - 1]?.elements || []);
        setCurrentPage(pageIndex);
    };

    // Adds a new page to allData
    // const addPage = () => {
    //     setAllData((prev: any) => {
    //         const newPage = {
    //             name: `page${prev.pages.length + 1}`,
    //             elements: [[]], // Initialize with empty elements
    //         };
    //         return { ...prev, pages: [...prev.pages, newPage] };
    //     });

    //     // Automatically navigate to the new page after adding it
    //     handlePageChange(allData.pages.length + 1);
    // };
    

    const addPage = () => {
        // Define the new page with empty elements initially
        const newPage = {
            name: `page${allData.pages.length + 1}`, // For example, "page2"
            elements: [], // Initialize with empty elements
        };
    
        // Update the pages in your state with the new empty page
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages, newPage]; // Add the new page
            return { ...prev, pages: updatedPages };
        });
    
        // After adding the page, automatically navigate to the new page
        const newPageIndex = allData.pages.length + 1;
        handlePageChange(newPageIndex);
    };
    

    const duplicatePage = (pageIndex: number) => {
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages];
            const pageToDuplicate = updatedPages[pageIndex];
    
            if (pageToDuplicate) {
                const duplicatedPage = {
                    ...pageToDuplicate,
                    name: `page${updatedPages.length + 1}`, // Assign a new name for the duplicated page
                    elements: [...pageToDuplicate.elements], // Deep copy the elements array to avoid mutation
                };
    
                return { ...prev, pages: [...updatedPages, duplicatedPage] };
            }
    
            return prev; // If no page to duplicate, return the current state
        });
    
        // Optionally navigate to the duplicated page
        handlePageChange(allData.pages.length + 1);
    };
    
    const deletePage = (pageIndex: number) => {
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages];
    
            // Remove the page at the specified index
            updatedPages.splice(pageIndex, 1);
    
            // Reassign names to maintain sequential naming
            const renamedPages = updatedPages.map((page, index) => ({
                ...page,
                name: `page${index + 1}`,
            }));
    
            return { ...prev, pages: renamedPages };
        });
    
        setCurrentPage((prevCurrentPage) => {
            const newCurrentPage = pageIndex + 1 === prevCurrentPage 
                ? Math.max(1, prevCurrentPage - 1) 
                : prevCurrentPage > pageIndex + 1 
                    ? prevCurrentPage - 1 
                    : prevCurrentPage;
    
            // Validate the new current page index
            setAllData((prev: any) => {
                const updatedPages = [...prev.pages];
                if (updatedPages[newCurrentPage - 1]) {
                    // Update questionData or other attributes safely
                    updatedPages[newCurrentPage - 1].elements = questionData; // Replace questionData with your variable
                }
                return {
                    ...prev,
                    pages: updatedPages,
                };
            });
    
            return newCurrentPage;
        });
    };
    
    

    // const handlePageChange = (pageIndex: number) => {
    //     // Save current page's data back to allData
    //     setAllData((prev: any) => {
    //         const updatedPages = [...prev.pages];
    //         updatedPages[currentPage - 1].elements = [questionData]; // Save current page data to allData
    //         return { ...prev, pages: updatedPages };
    //     });

    //     // Load the new page's data into questionData
    //     setQuestionData(allData.pages[pageIndex - 1]?.elements[0] || []);
    //     setCurrentPage(pageIndex);
    // };

    // const addPage = () => {
    //     setAllData((prev: any) => {
    //         const newPage = {
    //             name: `page${prev.pages.length + 1}`,
    //             elements: [], // Start with an empty questionData array
    //         };
    //         return { ...prev, pages: [...prev.pages, newPage] };
    //     });

    //     setCurrentPage((prevPage) => prevPage + 1);
    //     setQuestionData([]); // Set questionData to empty for the new page
    // };











    // Update function for the question
    const updateQuestion = (index: number, updatedQuestion: any) => {
        const updatedQuestionData = [...questionData];
        updatedQuestionData[index] = updatedQuestion;
        setQuestionData(updatedQuestionData);
    };

    // Toggle the 'isRequired' field for the question
    const toggleRequired = (index: number) => {
        const updatedQuestionData = [...questionData];
        updatedQuestionData[index].isRequired = !updatedQuestionData[index].isRequired;
        setQuestionData(updatedQuestionData);
    };




    const updateQuestionName = (index: number, updatedName: string) => {
        setQuestionData((prev) => {
            const updatedQuestions = [...prev];
            updatedQuestions[index] = { ...updatedQuestions[index], name: updatedName };
            return updatedQuestions;
        });
    };

    const generateQuestionName = (index: number) => `${index + 1}`;

    // const addCheckboxQuestion = () => {
    //   const newCheckboxQuestion = {
    //     type: 'checkbox',
    //     name: generateQuestionName(questionData.length),
    //     choices: ["Item 22", "Item 2", "Item 3", "Item 4"],
    //     marks: {
    //       "Item 1": 2,
    //       "Item 2": 4,
    //       "Item 3": 2,
    //       "Item 4": 4,
    //     },
    //     required: false,
    //   };
    //   setQuestionData([...questionData, newCheckboxQuestion]);
    // };








    const [processedRadio, _] = useState(false);



    const { id } = useParams(); // Extract the id from the route parameters
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
        // Create a URLSearchParams object to parse query parameters
        const urlParams = new URLSearchParams(location.search);
        console.log(urlParams); // Log the URLSearchParams object for debugging

        const radio = urlParams.get("radio"); // Get the "radio" query parameter

        // Debugging: Log the value of radio
        console.log("radio:", radio); // Log the radio parameter value

        if (radio && !processedRadio) {
            // Check the value of the radio parameter and call the relevant function
            switch (radio) {
                case "Checkboxes":
                    addCheckboxQuestion();
                    break;
                case "RatingScale":
                    addRatingScale();
                    break;
                case "MultiDropdown":
                    DynamicSelectSelect();
                    break;
                case "FileUpload":
                    Fileuploadmodel();
                    break;
                case "ImagePicker":
                    imagepicker();
                    break;
                case "RadioButtonGroup":
                    Radiogroup11();
                    break;
                case "SelectDropdown":
                    DropdownSelectSelect();
                    break;
                case "StarRatings":
                    addSwitchranking();
                    break;
                case "Yes/No(Boolean)":
                    addSwitchboolean();
                    break;
                case "TextFiled":
                    addTestfiled();
                    break;
                case "Radiogroup":
                    addRadioQuestion();
                    break;
                default:
                    console.warn("Unhandled radio value:", radio);
            }
            navigate(`?radio=${""}`);

            // const params = new URLSearchParams(window.location.search);
            // params.set('radio1', ''); // This sets the radio parameter to an empty string
            // window.history.replaceState({}, '', `${window.location.pathname}?${""}`);
            
            // setProcessedRadio(true); // Mark as processed    

            // // Remove the "radio" parameter from the URL without navigation
            // urlParams.delete("radio");
            // const newUrl = `${location.pathname}?${urlParams.toString()}`;
            // window.history.replaceState({}, "", newUrl); // Update URL
        }
    }, [location.search]); 

    // If the radio has been processed, set processedRadio to true to prevent re-running the useEffect
    useEffect(() => {
        if (processedRadio) {
            // If needed, handle additional UI state updates after processing the radio parameter
            console.log("Radio processed, UI updated.");


        }
    }, [processedRadio]);






    const addCheckboxQuestion = () => {
        const newCheckboxQuestion = {
            type: "checkbox",
            name: generateQuestionName(questionData.length), // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            showScore: isScoreBased,
            choices: [
                { value: "10", text: "Feature 1" ,"score": 0},
                { value: "feature2", text: "Feature 2" ,"score": 0},
                { value: "feature3", text: "Feature 3" ,"score": 0},
                { value: "feature4", text: "Feature 4" ,"score": 0},
            ],
            correctAnswer: ["feature1", "feature2"], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    };


    const handleOnClickPage = () => {
        setIsOnClick(!isonclick)
    }

   

    const DynamicSelectSelect = () => {
        const newCheckboxQuestion = {
            type: "tagbox",
            name: generateQuestionName(questionData.length), // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            showScore: isScoreBased,
            
            choices: [
                { value: "feature1", text: "Feature 1" , "score": 0},
                { value: "feature2", text: "Feature 2" , "score": 0},
                { value: "feature3", text: "Feature 3" , "score": 0},
                { value: "feature4", text: "Feature 4" , "score": 0},
            ],
            correctAnswer: ["feature2"], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    };

    const addRatingScale = () => {
        const newQuestion = {
            type: "rating",
            showScore: isScoreBased,
            name: generateQuestionName(questionData.length), // Generate question name
            title: "What features did you like the most?",
            rateValues: [1, 2, { value: 3, text: "2" }, 4, 5],
            // rateValues: [
            //     { value: "feature1", text: "Feature 1" , "score": 0},
            //     { value: "feature2", text: "Feature 2" , "score": 0},
            //     { value: "feature3", text: "Feature 3" , "score": 0},
            //     { value: "feature4", text: "Feature 4" , "score": 0},
            // ],
            required: false,
            autoGenerate: false,
            correctAnswer: ["feature1", "feature2"],
        };
        setQuestionData([...questionData, newQuestion]);
    };

    // Function to add a radio question
    const addRadioQuestion = () => {
        const newQuestion = {
            "type": "radiogroup",
            "name": generateQuestionName(questionData.length),
            "title": "What features did you like the most?",
            "isRequired": true,
            "choices": [
                {
                    "value": "feature1",
                    "text": "Feature 1"
                },
                {
                    "value": "feature2",
                    "text": "Feature 2"
                },
                {
                    "value": "feature3",
                    "text": "Feature 3"
                },
                {
                    "value": "feature4",
                    "text": "Feature 4"
                }
            ],
            "correctAnswer": [
                "feature1",
            ]
        }
        setQuestionData((prevData) => [...prevData, newQuestion]);

    };

    // Function to remove a question
    const handleRemoveQuestion = (index: number) => {
        const updatedData = questionData.filter((_, i) => i !== index);
        setQuestionData(
            updatedData.map((question) => ({
                ...question,
                name: generateQuestionName(questionData.length),
            }))
        );
    };




    const DropdownSelectSelect = () => {
        const newCheckboxQuestion = {
            type: "dropdown",
            name: "question1",
            title: "What features did you like the most?",
            showScore: isScoreBased,
            
            choices: [
                { value: "10", text: "Feature 1" , "score": 0},
                { value: "feature2", text: "Feature 2" , "score": 0},
                { value: "feature3", text: "Feature 3" , "score": 0},
                { value: "feature4", text: "Feature 4" , "score": 0},
            ],
            isRequired: true,
          };
          setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
        
    }

    // const DropdownSelectSelect = () => {
    //     const newCheckboxQuestion = {
    //         type: "dropdown",
    //         name: generateQuestionName(questionData.length), // Dynamically generate the name
    //         title: "What features did you like the most?", // Add the question title
    //         isRequired: true, // Make the question required
    //         choices: [
    //             { value: "feature1", text: "Feature 1" },
    //             { value: "feature2", text: "Feature 2" },
    //             { value: "feature3", text: "Feature 3" },
    //             { value: "feature4", text: "Feature 4" },
    //         ],
    //         correctAnswer: ["feature1"], // Set the correct answers
    //     };

    //     setQuestionData([...questionData, newCheckboxQuestion]);
    // }


    const addSwitchranking = () => {
        const newRankingQuestion = {
            type: "ranking",
            name: generateQuestionName(questionData.length),
            title: "Rank the following features based on importance:",
            isRequired: false,
            showScore: isScoreBased,
            // choices: [
            //     "Feature A",
            //     "Feature B",
            //     "Feature C",
            //     "Feature D"
            // ],
     
            choices: [
                { value: "feature1", text: "Feature 1" ,"score": 0},
                { value: "feature2", text: "Feature 2" ,"score": 0},
                { value: "feature3", text: "Feature 3" ,"score": 0},
                { value: "feature4", text: "Feature 4" ,"score": 0},
            ],
            correctAnswer: ["Feature A", "Feature B", "Feature C", "Feature D"] // Full ranking order
        };

        // Update question data immutably
        setQuestionData((prevData) => [...prevData, newRankingQuestion]);
    };


    const imagepicker = () => {
        const newRadioQuestion = {
          type: "imagepicker",
          name: generateQuestionName(questionData.length), // Ensure unique name based on the current length
          title: "Which animal design do you prefer?", // Title for the question
          isRequired: true,  // Mark the question as required
          showScore: isScoreBased,
          choices: [
            {
              value: "Image 1",
              imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg",
              score: 0 // Initially set score
            },
            {
              value: "Image 2",
              imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/giraffe.jpg",
              score: 0
            },
            {
              value: "Image 3",
              imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/panda.jpg",
              score: 0
            },
            {
              value: "Image 4",
              imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/camel.jpg",
              score: 0
            }
          ],
          imageFit: "cover", // How the images should be displayed
          correctAnswer: "Image 2"  // Example of the correct answer (you can change this)
        };
      
        // Add the new question to the question data
        setQuestionData([...questionData, newRadioQuestion]);
      };
      



    const handleDuplicateQuestion = (index: number) => {
        const duplicatedQuestion = { ...questionData[index], name: generateQuestionName(questionData.length), };
        setQuestionData([...questionData, duplicatedQuestion]);
    };


    const addTestfiled = () => {
        // Generate a unique name based on the current length of questionData
        const questionIndex = questionData.length; // Use the current length of questionData for indexing
        const newCheckboxQuestion = {
            "type": "text",
            "name": generateQuestionName(questionData.length), // Assuming generateQuestionName is a function that generates unique names
            "title": `You miss work or school because of your headaches? ${generateQuestionName(questionData.length)}`, // Adding unique identifier to the title
            ...(questionIndex > 0 ? { "visibleIf": `{${generateQuestionName(questionIndex - 1)}} notempty` } : {}),
            "inputType": "number",
            "placeholder": "23",
            "isRequired": true,
            "showScore": isScoreBased,
            "validators": [
                {
                    "type": "numeric",
                    "text": "The value must be between 0 and 90.",
                    "minValue": 0,
                    "maxValue": 90
                }
            ]
        };

        // Add the new question to the existing questionData
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
    };


    const addSwitchboolean = () => {
        const newCheckboxQuestion = {
            type: "boolean",
            name: generateQuestionName(questionData.length),
            title: "What features did you like the most?",
            labelTrue: "Yes",
            labelFalse: "No",
            showScore: isScoreBased, // This is where isScoreBased comes into play
            isRequired: true,
            score: 2,
            correctAnswer: ["feature1"],
        };
        setQuestionData([...questionData, newCheckboxQuestion]);
    };



 


    const Radiogroup11 = () => {
        // Generate a unique name based on the current length of questionData
        const questionIndex = questionData.length; // Use the current length of questionData for indexing
        const questionName = generateQuestionName(questionIndex); // This generates a unique name for the question

        // Define the new question with conditional visibility
        const newCheckboxQuestion = {
            "type": "radiogroup",
            "name": questionName,
            "title": "When you have headaches, how often is the pain severe?",
            "isRequired": true,
            showScore: isScoreBased,
            // Conditionally add the visibleIf property, incrementing based on question index
            ...(questionIndex > 0 ? { "visibleIf": `{${generateQuestionName(questionIndex - 1)}} notempty` } : {}),
            "choices": [
                { "value": "0", "text": "Never", "score": 0 },
                { "value": "2", "text": "Rarely", "score": 2 },
                { "value": "5", "text": "Sometimes", "score": 5 },
                { "value": "8", "text": "Very Often", "score": 8 },
                { "value": "10", "text": "Always", "score": 10 }
            ]
        }

        // Add the new question to the existing questionData
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
    };


  

    const Fileuploadmodel = () => {

        const newCheckboxQuestion = {
            type: "file",
            name: generateQuestionName(questionData.length),
            title: "Upload a picture of your purchase receipt.",
            isRequired: false,
            showScore: isScoreBased,
            storeDataAsText: false,
            "score": 0,
            maxSize: 102400
        };
        setQuestionData([...questionData, newCheckboxQuestion]);
    }



    useEffect(() => {
        if (!userId) return;

        const fetchData = async () => {

            try {
                // Make the API call
                const result = await submitFormResponseApisuryvegetcall(userId);
                console.log("result", result.data.data)
                setApiResponse(result.data.data)

            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [userId]);


    const UserId = localStorage.getItem("formid");

    const handleSubmit = async () => {
        if (!apiresponse.id) {
            console.error("API response does not have an ID");
            return;
        }

        // Update the state with new questions
        const updatedApiResponse = {
            ...apiresponse,
            questions: allData, // Add or update questions
        };

        setApiResponse(updatedApiResponse);

        try {
            // Make the PUT API call
            const response = await submitFormResponseApisuryveputcall(apiresponse.id, updatedApiResponse);
            console.log("PUT response", response);
          
            //   message
            // Optionally update the state with the response
            setApiResponse(response.data);
            if (id) {
                // If `id` exists, update the URL
                console.log(`Redirecting to /forms with id: ${id}`);
                window.location.href = `/forms`; // This will reload the page with the new URL
            } else if (UserId && UserId.trim() !== "") {
                // If `UserId` exists and is valid, update the URL
                console.log(`Redirecting to /forms with UserId: ${UserId}`);
                window.location.href = `/forms`; // This will reload the page with the new URL
            } else {
                // If `UserId` is invalid or missing, show an alert
                alert("Invalid or missing UserId!");
            }
          
           
            // showSnackbar(response.data.message, "success")
        } catch (err) {
            console.error("Error updating questions", err);
        }
    };

    useEffect(() => {
        console.log("apiresponse structure:", apiresponse);

        // Delay setting data to simulate asynchronous behavior
        const timeoutId = setTimeout(() => {
            if (apiresponse && apiresponse?.questions?.pages?.[0]?.elements) {
                console.log("Setting questionData:", apiresponse.questions.pages[0].elements);
                setQuestionData(apiresponse.questions.pages[0].elements);
            } else {
                console.log("questions.pages[0].elements is undefined or null");
                setQuestionData([]); // Fallback to an empty array
            }
        }, 1000);

        // Cleanup timeout on component unmount or apiresponse change
        return () => clearTimeout(timeoutId);
    }, [apiresponse]);




    const [activeIndex] = useState<number | null>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        if (activeIndex !== null) {
            const updatedData = [...questionData];
            updatedData[activeIndex] = {
                ...updatedData[activeIndex],
                [field]: event.target.value,
            };
            setQuestionData(updatedData);
        }
    };

    useEffect(() => {
        if (submitTrigger) {
        //   alert("Triggered in child component!");
        }
      }, [submitTrigger]);
    
useEffect(()=>{
    if(submitTriggerQuestion){
        handleSubmit()
    }
},[submitTriggerQuestion])

  


    const renderTabContent = () => {
        if (selectedTab === 'Form Designer') {
            return (
                <div>
                    {/* <h2>Page 1  </h2>      */}
                    <div className="form-container">

                        {/* <span onClick={addPage} style={{ cursor: "pointer", color: "blue" }}>
          +
        </span> */}
                        {/* <p>Description</p> */}


                        <div className="app-container" style={{ width: '90%' }}>

                            {/* <h3 style={{ margin: '10px' }}>Welcome {apiresponse.name}!</h3> */}
                            {/* <h2>Page {currentPage}</h2> */}
                            {/* <input type='text' placeholder='Survey Title...' className='surveytitle' />
                            <input type='text' placeholder='Description...' className='Description' />

                            <div className='linepage'></div> */}
                            {/* <div style={{ marginBottom: "20px" }}>
                                {allData.pages.map((page: any, index: any) => (
                                    <button
                                        key={page.name}
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{
                                            margin: "5px",
                                            padding: "10px",
                                            backgroundColor: currentPage === index + 1 ? "#21b9a9" : "white",
                                            color: currentPage === index + 1 ? "white" : "black",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {page.name}
                                    </button>
                                ))}


                            </div>
                            <button
                      onClick={() => {
                        addPage();
                        handlePageChange(index + 1);
                      }}
                                style={{
                                    margin: "5px",
                                    padding: "13px",
                                    backgroundColor: "white",
                                    color: "rgb(33, 185, 169)",
                                    border: "none",
                                    borderRadius: "5px",
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    cursor: "pointer",
                                    marginBottom: '2rem',
                                    marginTop: '1rem',
                                    width: '80%',
                                    marginLeft: '3rem',
                                    boxShadow: '1px 0px 2px 2px rgb(210, 210, 210)'
                                }}
                            >
                                + Add Page
                            </button> */}

                            <div style={{ marginBottom: "20px" }}>
                                {allData.pages.map((page: any, index: number) => (
                                
                                    <button
                                        key={page.name}
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{
                                            margin: "5px",
                                            padding: "10px",
                                            backgroundColor: currentPage === index + 1 ? "#21b9a9" : "white",
                                            color: currentPage === index + 1 ? "white" : "black",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {page.name}
                                    </button>
                                    
                                   
                                ))}
                            </div>

                            {/* <button
                                onClick={() => {
                                    addPage(); // Adds a new page to your data structure
                                    handlePageChange(allData.pages.length + 1); // Navigates to the new page
                                }}
                                className='addbuttonpage'    
                            >
                                + Add Page
                            </button> */}



<div
  className={`${isonclick ? 'formpage2' : 'formpage1'}`}
  onClick={handleOnClickPage} // Parent click event
>
  <div className={`${isonclick ? 'formpage' : 'formpage'}`}>
    {allData.pages.length >= 1 && (
      <div>
        <button
          className="delete-button button-spacing red-bg required-button"
          style={{
            backgroundColor: 'transparent',
            position: 'relative',
            left: '10rem',
            top: '10px',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent click
            duplicatePage(currentPage - 1);
            // Add logic for Duplicate button here
          }}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg required-button"
          style={{
            backgroundColor: 'transparent',
            position: 'relative',
            left: '10rem',
            top: '10px',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent click
            deletePage(currentPage - 1);
            // Add logic for Delete button here
          }}
        >
          {/* Delete */}
        </button>
      </div>
    )}
  </div>
  <h2 className="pagebutton">Page {currentPage}</h2>
  <input
    type="text"
    placeholder="Description"
    className="Description"
    onChange={(e) => handleInputChange(e, 'description')} // Update the description field
    onClick={(e) => e.stopPropagation()} // Prevent click bubbling
  />
  <div>
    {questionData.map((question, index) => (
      <div key={index} onClick={(e) => e.stopPropagation()}>
        <p></p>
        {question.type === 'dropdown' && (
          <DynamicSelect
            key={index}
            question={question}
            index={index}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestion={updateQuestion}
          />
        )}
        {question.type === 'tagbox' && (
          <DynamicSelectMulti
            key={index}
            question={question}
            toggleRequired={toggleRequired}
            index={index}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestion={updateQuestion}
          />
        )}
        {question.type === 'imagepicker' && (
          <DynamicImagePickerNot
            key={index}
            initialQuestion={question}
            index={index}
            toggleRequired={toggleRequired}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestion={updateQuestion}
          />
        )}
        {question.type === 'boolean' && (
          <DynamicBooleanSwitchGroup
            key={index}
            question={question}
            index={index}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestion={updateQuestion}
            toggleRequired={toggleRequired}
          />
        )}
        {question.type === 'ranking' && (
          <DynamicRanking
            key={index}
            question={question}
            index={index}
            updateQuestion={(index, updatedQuestion) =>
              updateQuestion(index, updatedQuestion)
            }
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
          />
        )}
        {question.type === 'file' && (
          <FileInput
            key={index}
            index={index}
            question={question}
            updateQuestion={updateQuestion}
            toggleRequired={toggleRequired}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestionTitle={(updatedTitle: string) =>
              updateQuestion(index, { ...question, title: updatedTitle })
            }
          />
        )}
        {question.type === 'rating' && (
          <DynamicRatingGroup
            key={index}
            question={question}
            index={index}
            toggleRequired={toggleRequired}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestionName={(updatedName) =>
              updateQuestionName(index, updatedName)
            }
          />
        )}
        {question.type === 'checkbox' && (
          <DynamicCheckboxGroup
            key={index}
            question={question}
            index={index}
            updateQuestion={(index, updatedQuestion) =>
              updateQuestion(index, updatedQuestion)
            }
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
          />
        )}
        {question.type === 'text' && (
          <DynamicTextField
            key={index}
            question={question}
            index={index}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestion={updateQuestion}
          />
        )}
        {question.type === 'radiogroup' && (
          <DynamicRadioGroup
            key={index}
            question={question}
            index={index}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleRemoveQuestion={handleRemoveQuestion}
            updateQuestion={updateQuestion}
          />
        )}
      </div>
    ))}
  </div>
  <button
    onClick={(e) => {
      e.stopPropagation(); // Prevent parent click
      addPage(); // Adds a new page
      handlePageChange(allData.pages.length + 1); // Navigates to the new page
    }}
    className="addbuttonpage"
  >
    + Add Page
  </button>
  <pre
    style={{ fontSize: '6px', }}
  >
    {/* {JSON.stringify(allData, null, 2)} */}
  </pre>
</div>


                        </div>

                        {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}

                    </div>
                    {/* {pages.map((pageNumber) => (
          <div key={pageNumber}>
            <h2>Page {pageNumber}</h2>
   
          </div>
        ))} */}
                </div>
            );
        } else if (selectedTab === 'JSON Editor') {
            return (
                <>
                    {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}

                    <pre>{JSON.stringify(allData, null, 2)}</pre>

                    {/* <pre>{JSON.stringify(jsonviewobject, null, 2)}</pre> */}

                    {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}

                </>
            )
        }
        else if (selectedTab === 'Preview') {
            return (
                <>
                    {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(jsonviewobject, null, 2)}</pre> */}
                    <div className="app-container">

                        {/* <SurveyComponent questionData={questionData} /> */}

                        <>
                            {/* <SurveyComponent questionData={allData} /> */}


                            {isScoreBased ? (
  <>
    {questionData && questionData.length > 0 ? (
    //   questionData[0].type === 'text' ? (
    //     <SurveyComponenttext questionData={questionData} />
    //   ) : (
    //     <>
    //     <SurveyComponent1 questionData={allData} />
    //     </>
    //   )
    <>    <SurveyComponent1 questionData={allData} /></>
    ) : (
      <p>Loading or no data available...</p>
    )}
  </>
) : (

    <SurveyComponent1 questionData={allData} />
//   <SurveyComponent questionData={allData} />
)}


                            {/* <pre style={{ fontSize: '6px', color: 'transparent' }}>{JSON.stringify(allData, null, 2)}</pre> */}
                        </>

                        {/* {questionData[0].type === 'text' && (
                            <>
                                <SurveyComponenttext questionData={questionData} />
                            </>)}
                        {questionData[0].type !== 'text' && questionData[0].type !== 'radiogroup' && (
                            <>
                                <SurveyComponentcomman questionData={questionData} />
                            </>
                        )} */}





                    </div>
                </>
            )
        } else if (selectedTab === 'Form Details') {
            return (
                <>

                
                   
                   {id ? <><AddNewFormCompFormid submitTrigger={submitTrigger} /></>:<> 
                   <AddNewFormComp  submitTrigger={submitTrigger}  />
                   </>} 
                    {/* AddNewFormComp */}

                </>
            )
        } else {
            return null;  // For all other tabs, render nothing
        }
    };

    return (
   
        <div className="app-container" style={{ overflow: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* Sidebar */}
                {/* <FormSidebar /> */}


                <Box

                >
                    <Box
                        sx={{
                            height: true
                                ? "auto"
                                : "calc(100% - 11.25rem)",
                        }}
                    >
                        <List
                            sx={{
                                padding: "0rem",
                                height: "auto",
                                overflow: "none",
                            }}
                        >


                           


                        </List>
                    </Box>
                </Box>
                {/* Main Content Area */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                
         

                    <div className="content-container" style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        {/* Main Content */}
                        <main className="main-content" style={{ flex: 1 }}>
                            {renderTabContent()}
                        </main>


                    </div>
                </div>
            </div>
        </div>

    );
};

export default FormpagesEdit;
