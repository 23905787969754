const HospitalIconcard = (props: any) => {
  const { height = 36, width = 36 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2043 6.71677L26.3257 5.83823C24.5952 4.10773 21.7913 4.10773 20.0608 5.83823L5.41058 20.4884L3.08652 18.1658C2.50919 17.59 1.57454 17.59 0.998693 18.1673C0.422844 18.7432 0.422844 19.6793 0.998693 20.2551L7.33156 26.5821L4.71957 29.2L2.51953 27.0014C1.94368 26.4256 1.00755 26.4256 0.431703 27.0029C-0.144147 27.5788 -0.144147 28.5149 0.433179 29.0907L6.88269 35.5373C7.46002 36.1131 8.39614 36.1131 8.97199 35.5358C9.54784 34.96 9.54784 34.0238 8.97052 33.448L6.80887 31.2863L9.41938 28.6699L15.7611 35.0057C16.3384 35.5816 17.2731 35.5816 17.8489 35.0057C18.4248 34.4284 18.4248 33.4938 17.8489 32.9164L15.8556 30.9246L30.5014 16.2788C32.2319 14.5483 32.2319 11.7444 30.5014 10.0139L29.2921 8.80459L35.5689 2.52045C36.1447 1.94312 36.1432 1.007 35.5674 0.431149C34.99 -0.1447 34.0539 -0.143223 33.4781 0.432626L27.2043 6.71677ZM13.7663 28.8382L28.4136 14.191C28.9909 13.6137 28.9909 12.679 28.4136 12.1017L24.2379 7.92605C23.6606 7.34872 22.7259 7.34872 22.1501 7.92605L20.8832 9.19144L22.5192 10.8274C23.0951 11.4033 23.0951 12.3394 22.5192 12.9153C21.9434 13.4926 21.0072 13.4926 20.4314 12.9153L18.7954 11.2807L16.4329 13.6432L18.0689 15.2777C18.6448 15.8536 18.6448 16.7897 18.0689 17.3655C17.4916 17.9429 16.557 17.9429 15.9811 17.3655L14.3451 15.731L12.024 18.0507L13.66 19.6867C14.2358 20.2625 14.2358 21.1986 13.66 21.7745C13.0841 22.3518 12.148 22.3518 11.5722 21.7745L9.93617 20.14L7.49989 22.5763L13.7663 28.8382Z"
        fill="#62B55A"
      />
    </svg>
  );
};
export default HospitalIconcard;
