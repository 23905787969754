import { Box } from "@mui/material";
import SpecialityList from "./SpecialityList";
import ComponentHeader from "../../shared/ComponentHeader";
import { useNavigate } from "react-router-dom";
import HelpSupportModal from "../../shared/HelpSupportModal";
import React from "react";
import { SpecialityProps } from "../../../types/appointments";

const Speciality: React.FC<SpecialityProps> = ({ patientId }) => {
  // props & state values
  const navigate = useNavigate();

  // callbacks & functions
  const handleBack = () => {
    navigate("/appointments");
  };

  return (
    <Box
      sx={{
        // padding: "20px",
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: "1.875rem",
      }}
    >
      {patientId ? (
        <ComponentHeader title="Top Specialties" />
      ) : (
        <ComponentHeader
          title="Top Specialties"
          page="appointments"
          handleBack={handleBack}
        >
          <HelpSupportModal isButton={true} />
        </ComponentHeader>
      )}
      <SpecialityList patientId={patientId} />
    </Box>
  );
};

export default Speciality;
