import React, { useEffect } from "react";
import useAppointmentStore from "../../../store/appointmentsStore";
import { AllAppointmentsProps } from "../../../types/appointments";
import NewAppointmentList from "./NewAppointmentList";
import useAppStore from "../../../store/appStore";

const AllAppointments: React.FC<AllAppointmentsProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const {
    sortedAppointments,
    dashboardSortedAppointments,
    loadingFetchAppointments,
    errorFetchAppointments,
    fetchAppointments,
    cleanUpAppointments,
  } = useAppointmentStore();

  const { appointmentsFilter } = useAppStore();

  // callbacks & functions
  useEffect(() => {
    if (patientId) {
      if (from === "dashboard") {
        // setLoading(false);
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          patientId,
          "DASHBOARD",
          appointmentsFilter?.searchValue || ""
        );
      } else {
        // setLoading(false);
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          patientId,
          "",
          appointmentsFilter?.searchValue || ""
        );
      }
    } else {
      if (from === "dashboard") {
        // setLoading(false);
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          "",
          "DASHBOARD",
          appointmentsFilter?.searchValue || ""
        );
      } else {
        // setLoading(false);
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          "",
          "",
          appointmentsFilter?.searchValue || ""
        );
      }
    }

    return () => {
      cleanUpAppointments();
    };
  }, [
    appointmentsFilter.customStartDate,
    appointmentsFilter.customEndDate,
    appointmentsFilter.status,
  ]);

  return (
    <NewAppointmentList
      appointments={
        from === "dashboard" ? dashboardSortedAppointments : sortedAppointments
      }
      loadingFetchAppointments={loadingFetchAppointments}
      errorFetchAppointments={errorFetchAppointments}
      from={from}
      patientId={patientId}
    />
  );
};

export default AllAppointments;
