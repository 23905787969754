import React, { useCallback, useState } from "react";
import { deleteDoctor } from "../../services/doctorService";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditDoctorModal from "./AddEditDoctorModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Doctor } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { DoctorsListProps } from "../../types/doctors";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import useAppStore from "../../store/appStore";
import { TableWrapper } from "../shared/TableWrapper";
import CardTableToggle from "../shared/CardTableToggle";
import CardViewDoctorCard from "./CardViewDoctorCard";
import ComponentFilter from "../shared/ComponentFilter";
import { debounce } from "lodash";
// import config from "../../config";
import { DOCTORS } from "../../utils/constants";

const DoctorsList: React.FC<DoctorsListProps> = ({
  loading,
  doctors,
  loadDoctors,
}) => {
  // props & state values
  console.log("doctors in list", doctors);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteDoctorId, setDeleteDoctorId] = useState<string | null>(null);
  const [editDoctor, setEditDoctor] = useState<Doctor | null>(null);

  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("doctorsView") || "table"
  );

  const { setFilter, doctorsFilter } = useAppStore();

  // callbacks & functions
  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    loadDoctors(DOCTORS, filter.customStartDate, filter.customEndDate, value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string, filter: any) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, filter);
      // }
      handleInputChange(value, filter);
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("doctorsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, doctorsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all doctors
    //   handleInputChange("", doctorsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, doctorsFilter);
    // }
  };
  // search filter handlers end

  const handleAddNew = () => {
    setEditDoctor(null);
    setDialogOpen(true);
  };

  const handleEdit = (doctor: Doctor) => {
    setEditDoctor(doctor);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteDoctorId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteDoctorId) {
      try {
        await deleteDoctor(deleteDoctorId);
        loadDoctors(
          DOCTORS,
          doctorsFilter.customStartDate,
          doctorsFilter.customEndDate,
          doctorsFilter.searchValue || ""
        );
      } catch (error) {
        console.error("Error deleting doctor:", error);
      }
    }
    setDeleteDoctorId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadDoctors(
      DOCTORS,
      doctorsFilter.customStartDate,
      doctorsFilter.customEndDate,
      doctorsFilter.searchValue || ""
    );
  };

  const updateDoctorsRangeFilter = (value: string) => {
    setFilter("doctorsFilter", {
      range: value,
    });
  };

  const updateDoctorsCustomDateFilter = (start: string, end: string) => {
    setFilter("doctorsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  // table columns
  const columns = [
    {
      id: "firstName",
      label: "Name(Years)",
      sortable: true,
      render: (value: string, row: Doctor) =>
        `${capitalizeFirstLetter(value || "")}(${row?.yearsOfExperience || 0} yrs)`,
    },
    {
      id: "specializations",
      label: "Specializations",
      sortable: true,
      render: (value: any) => value?.join(", ") || "-",
    },
    {
      id: "spokenLanguages",
      label: "Languages",
      render: (value: any) => value?.join(", ") || "-",
      sortable: true,
    },
    {
      id: "hospital.name",
      label: "Clinic",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.hospital?.name || "";
        const bName = b?.hospital?.name || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) => row?.hospital?.name || "-",
    },
    {
      id: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (value: string) => getFormattedDate(value || "") || "-",
    },
    {
      id: "actions",
      label: "Actions",
      render: (_: any, row: Doctor) => (
        <Box display="flex" justifyContent="space-around">
          <IconButton
            onClick={() => handleEdit(row)}
            color="secondary"
            disableRipple
            sx={{
              p: 0,
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row?.id)}
            color="primary"
            disableRipple
            sx={{
              p: 0,
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("doctorsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          minHeight: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <ComponentHeader title="Doctor List" />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </Box>
        </Box>

        <Box sx={{ marginBottom: "1rem" }}>
          <ComponentFilter
            updateRange={updateDoctorsRangeFilter}
            updateDates={updateDoctorsCustomDateFilter}
            range={doctorsFilter.range}
            isEmpty
            viewComponent={
              <CardTableToggle view={view} setView={handleToggleView} />
            }
            hideStatusFilter
            handleStoreSearchValueChange={handleStoreSearchValueChange}
            searchValue={doctorsFilter.searchValue || ""}
            searchFilterPlaceholder="Name, Mobile, Qualifications, Email"
            searchFilterWidth="20rem"
          />
        </Box>

        {loading ? (
          <Box
            sx={{
              height: "calc(100% - 8rem)",
            }}
          >
            <LoadingScreen />
          </Box>
        ) : doctors && doctors?.length > 0 ? (
          view === "card" ? (
            <Grid
              container
              spacing={2}
              sx={{
                maxHeight: "calc(100% - 7rem)",
                overflow: "auto",
              }}
            >
              {doctors?.map((doctor) => (
                <Grid item xs={12} key={doctor.id}>
                  <CardViewDoctorCard
                    doctor={doctor}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableWrapper
              columns={columns}
              rows={doctors}
              stickyHeader={true}
              maxHeight="calc(100% - 8rem)"
            />
          )
        ) : (
          <Box
            sx={{
              height: "calc(100% - 8rem)",
            }}
          >
            <ContentCenter>
              <Typography className="no-data">No doctors found</Typography>
            </ContentCenter>
          </Box>
        )}

        <AddEditDoctorModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          doctor={editDoctor}
          loadDoctors={loadDoctors}
        />

        <ConfirmDialog
          open={confirmOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this doctor?"
          onClose={handleConfirmClose}
        />
      </Box>
    </>
  );
};

export default DoctorsList;
