// import React, { useState, useEffect } from 'react';

// interface DropdownGroupProps {
//   question: {
//     type: string;
//     name: string;
//     title: string;
//     choices: { value: string; text: string }[];
//     isRequired: boolean;
//   };
//   index: number;
//   handleDuplicateQuestion: (index: number) => void;
//   handleRemoveQuestion: (index: number) => void;
//   updateQuestion: (index: number, updatedQuestion: any) => void;
// }

// const DynamicSelect: React.FC<DropdownGroupProps> = ({
//   question,
//   index,
//   handleDuplicateQuestion,
//   handleRemoveQuestion,
//   updateQuestion,
// }) => {
//   const [localQuestion, setLocalQuestion] = useState(question);
//   const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);

//   // Sync local state with parent state
//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       updateQuestion(index, { ...localQuestion });
//     }, 300);
//     return () => clearTimeout(timeout);
//   }, [localQuestion, index, updateQuestion]);

//   // Handle title change
//   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setLocalQuestion((prev) => ({ ...prev, title: e.target.value }));
//   };

//   // Toggle "required" field
//   const handleToggleRequired = () => {
//     setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
//   };

//   // Add a new choice
//   const handleAddChoice = () => {
//     const newChoice = {
//       value: `option${localQuestion.choices.length + 1}`,
//       text: `Option ${localQuestion.choices.length + 1}`,
//     };
//     setLocalQuestion((prev) => ({
//       ...prev,
//       choices: [...prev.choices, newChoice],
//     }));
//   };

//   // Remove a choice
//   const handleRemoveChoice = (choiceIndex: number) => {
//     setLocalQuestion((prev) => ({
//       ...prev,
//       choices: prev.choices.filter((_, idx) => idx !== choiceIndex),
//     }));
//   };

//   // Edit a choice
//   const handleEditChoice = (choiceIndex: number, newText: string) => {
//     setLocalQuestion((prev) => {
//       const updatedChoices = [...prev.choices];
//       updatedChoices[choiceIndex].text = newText;
//       return { ...prev, choices: updatedChoices };
//     });
//   };

//   return (
//     <div className="question-container">
//       {/* Question Title */}
//       <div className="question-header">
//         {localQuestion.name}
//         <input
//           type="text"
//           value={localQuestion.title}
//           onChange={handleTitleChange}
//           className="question-name-input"
//         />
//         {localQuestion.isRequired && <span className="required">*</span>}
//       </div>

//       {/* Dropdown Choices */}
//       <div className="dropdown-group">
//         <select name={localQuestion.name} className="dropdown-select">
//           {localQuestion.choices.map((choice, choiceIndex) => (
//             <option key={choiceIndex} value={choice.value}>
//               {choice.text}
//             </option>
//           ))}
//         </select>

//         {/* Editable Choices */}
//         {localQuestion.choices.map((choice, choiceIndex) => (
//           <div className="editable-choice-container" key={choiceIndex}>
//             <button
//               className="remove-button"
//               onClick={() => handleRemoveChoice(choiceIndex)}
//             >
//               -
//             </button>
//             <div className="editable-choice">
//               {editingChoiceIndex === choiceIndex ? (
//                 <input
//                   type="text"
//                   value={choice.text}
//                   onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
//                   onBlur={() => setEditingChoiceIndex(null)}
//                   autoFocus
//                   className="edit-label-input"
//                 />
//               ) : (
//                 <span
//                   className="editable-text"
//                   onClick={() => setEditingChoiceIndex(choiceIndex)}
//                 >
//                   {choice.text}
//                 </span>
//               )}
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Add Choice Button */}
//       <div className="dropdown-item">
//         <button className="add-button" onClick={handleAddChoice}>
//           +
//         </button>
//       </div>

//       {/* Control Buttons */}
//       <div className="question-controls">
//         <button
//           className="duplicate-button button-spacing blue-bg"
//           onClick={() => handleDuplicateQuestion(index)}
//         >
//           Duplicate
//         </button>
//         <button
//           className="delete-button button-spacing red-bg"
//           onClick={() => handleRemoveQuestion(index)}
//         >
//           Delete
//         </button>
//         <button
//           className={`required-button ${localQuestion.isRequired ? 'active' : ''}`}
//           onClick={handleToggleRequired}
//         >
//           {localQuestion.isRequired ? 'Required' : 'Not Required'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DynamicSelect;

import React, { useState, useEffect } from "react";

interface DropdownGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    choices: { value: string; text: string; score?: number }[]; // `score` is optional
    isRequired: boolean;
    showScore: boolean; // New property to control score display
  };
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
}

const DynamicSelect: React.FC<DropdownGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState(question);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
  const [editingScoreIndex, setEditingScoreIndex] = useState<number | null>(null);

  // Sync local state with parent state
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateQuestion(index, { ...localQuestion });
    }, 300);
    return () => clearTimeout(timeout);
  }, [localQuestion, index, updateQuestion]);

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalQuestion((prev) => ({ ...prev, title: e.target.value }));
  };

  // Toggle "required" field
  const handleToggleRequired = () => {
    setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
  };

  // Add a new choice
  const handleAddChoice = () => {
    const newChoice = {
      value: `option${localQuestion.choices.length + 1}`,
      text: `Option ${localQuestion.choices.length + 1}`,
      score: localQuestion.showScore ? 0 : undefined, // Include score only if showScore is true
    };
    setLocalQuestion((prev) => ({
      ...prev,
      choices: [...prev.choices, newChoice],
    }));
  };



  
  // Remove a choice
  const handleRemoveChoice = (choiceIndex: number) => {
    setLocalQuestion((prev) => ({
      ...prev,
      choices: prev.choices.filter((_, idx) => idx !== choiceIndex),
    }));
  };

  // Edit a choice
  const handleEditChoice = (choiceIndex: number, newText: string) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      updatedChoices[choiceIndex].text = newText;
      return { ...prev, choices: updatedChoices };
    });
  };


  const handleEditScore = (choiceIndex: number, newScore: number) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      // Update both the `score` and `value` fields based on `newScore`
      updatedChoices[choiceIndex] = {
        ...updatedChoices[choiceIndex],
        score: newScore,
        value: `${newScore}`, // Convert the score to string for the `value`
      };
      return { ...prev, choices: updatedChoices };
    });
  };
  // Edit a score (only if showScore is true)
// Edit a score (and update the value accordingly)
// const handleEditScore = (choiceIndex: number, newScore: number) => {
//   if (!localQuestion.showScore) return; // Prevent editing if showScore is false
//   setLocalQuestion((prev) => {
//     const updatedChoices = [...prev.choices];
//     const updatedScore = newScore || 0; // Default to 0 if the input is invalid
//     updatedChoices[choiceIndex].score = updatedScore;
//     updatedChoices[choiceIndex].value = String(updatedScore); // Update value to match the score
//     return { ...prev, choices: updatedChoices };
//   });
// };


  return (
    <div className="question-container">
      {/* Question Title */}
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      {/* Dropdown Choices */}
      <div className="dropdown-group">
        <select name={localQuestion.name} className="dropdown-select">
          {localQuestion.choices.map((choice, choiceIndex) => (
            <option key={choiceIndex} value={choice.value}>
              {choice.text}
              {localQuestion.showScore && choice.score !== undefined
                ? ` (${choice.score})`
                : ""}
            </option>
          ))}
        </select>

        {/* Editable Choices */}
        {localQuestion.choices.map((choice, choiceIndex) => (
          <div className="editable-choice-container" key={choiceIndex}>
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
            >
              -
            </button>
            <div className="editable-choice">
              {/* Editable Text */}
              {editingChoiceIndex === choiceIndex ? (
                <input
                  type="text"
                  value={choice.text}
                  onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                  onBlur={() => setEditingChoiceIndex(null)}
                  autoFocus
                  className="edit-label-input"
                />
              ) : (
                <span
                  className="editable-text"  style={{marginRight:'11px'}}
                  onClick={() => setEditingChoiceIndex(choiceIndex)}
                >
                  {choice.text}
                </span>
              )}
              {/* Editable Score (only if showScore is true) */}
              {localQuestion.showScore && (
                editingScoreIndex === choiceIndex ? (
                  <input
                    type="number"
                    value={choice.score || 0} // Default to 0 if score is undefined
                    // onChange={(e) =>
                    //   handleEditScore(choiceIndex, parseInt(e.target.value, 10) || 0)
                    // }

                    onChange={(e) => handleEditScore(choiceIndex, Number(e.target.value))}
                    onBlur={() => setEditingScoreIndex(null)}
                    autoFocus
                    className="edit-score-input"
                  />
                ) : (
                  <span
                    className="editable-score"
                    onClick={() => setEditingScoreIndex(choiceIndex)}
                  >
                    (Score: {choice.score || 0})
                  </span>
                )
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Add Choice Button */}
      <div className="dropdown-item">
        <button className="add-button" onClick={handleAddChoice}>
          +
        </button>
      </div>

      {/* Control Buttons */}
      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? "active" : ""}`}
          onClick={handleToggleRequired}
        >
          {localQuestion.isRequired ? "Required" : "Not Required"}
        </button>
      </div>
    </div>
  );
};

export default DynamicSelect;

