import React from "react";
import { Box, Typography } from "@mui/material";
import { CardViewItemProps } from "../../types/common";

const CardViewItem: React.FC<CardViewItemProps> = ({ label, value }) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Typography
      sx={{
        color: "var(--neuro-button-bg-primary)",
        fontWeight: "var(--neuro-font-weight-bold)",
        fontSize: "var(--neuro-font-size-x-small)",
        fontFamily: "var(--neuro-font-family-roboto-slab)",
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        color: "var(--neuro-black-text)",
        marginTop: "0.313rem",
        overflowWrap: "anywhere",
      }}
    >
      {value}
    </Typography>
  </Box>
);

export default CardViewItem;
