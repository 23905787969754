import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import GeneralForm from "../../forms/GeneralForm";
import { scoreBasedFormSchema } from "../../forms/GeneralFormSchema";
import DisorderInfo from "./DisorderInfo";
import { DisorderFormProps } from "../../../types/appointments";

const DisorderForm: React.FC<DisorderFormProps> = ({ selectedDisorder }) => {
  // props & state
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownXl = useMediaQuery(theme.breakpoints.down("xl"));
  return (
    <Box
      sx={{
        bgcolor: "var(--neuro-bg-light-grey-secondary)",
        p: isDownXl ? "60px" : "60px 95px 60px 60px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isDownLg ? "column" : "row",
      }}
    >
      <Box sx={{ width: isDownLg ? "100%" : "50%" }}>
        <GeneralForm
          formSchema={scoreBasedFormSchema}
          disorder={selectedDisorder?.disorderName}
        />
      </Box>
      {selectedDisorder?.disorderName !== "" && (
        <Box
          sx={{
            width: isDownLg ? "100%" : "40%",
            marginTop: isDownLg ? "20px" : "60px",
          }}
        >
          <DisorderInfo selectedDisorder={selectedDisorder} />
        </Box>
      )}
    </Box>
  );
};

export default DisorderForm;
