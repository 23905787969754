import React from "react";
import { Grid } from "@mui/material";
import CardViewItem from "../shared/CardViewItem";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import CardViewWrapper from "../shared/CardViewWrapper";
import { CardViewPatientCardProps } from "../../types/patients";
import { getFullHospitalAddress } from "../../utils/appointments";
import { useNavigate } from "react-router-dom";

const CardViewPatientCard: React.FC<CardViewPatientCardProps> = ({
  patient,
  selectedPatient,
  setSelectedPatient,
}) => {
  // props & state values
  const navigate = useNavigate();

  // callbacks & functios
  const showAddress = (
    streetAddress: any,
    city: any,
    state: any,
    zipCode: any,
    country: any
  ) => {
    const address = {
      streetAddress: streetAddress || "",
      city: city || "",
      state: state || "",
      zipCode: zipCode || "",
      country: country || "",
    };
    const fullAddress = getFullHospitalAddress(address);
    return fullAddress || "-";
  };

  const CardClick = (patient: any) => {
    setSelectedPatient(patient || null);
    navigate(`/patients/${patient?.id}/profile`);
  };

  return (
    <CardViewWrapper
      sx={{
        cursor: "pointer",
        backgroundColor:
          selectedPatient && selectedPatient.id === patient.id
            ? "var(--neuro-bg-light-grey-secondary)"
            : "var(--neuro-white-text)",
      }}
      onClick={() => CardClick(patient)}
    >
      <Grid container sx={{ pointerEvents: "auto" }}>
        <Grid
          item
          container
          alignItems="baseline"
          sx={{ lineHeight: "120%", pointerEvents: "none" }}
          spacing={5}
          xs={12}
        >
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Patient Name"
              value={`${capitalizeFirstLetter(patient?.user?.firstName || "")}`}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Mobile Number"
              value={patient?.user?.mobile || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Address"
              value={
                showAddress(
                  patient?.streetAddress,
                  patient?.city,
                  patient?.state,
                  patient?.zipCode,
                  patient?.country
                ) || "-"
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Gender"
              value={capitalizeFirstLetter(patient?.gender || "") || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem label="Email" value={patient?.user?.email || "-"} />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Reasons for Appointment"
              value={patient?.reasonsForAppointments || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Reg. Date"
              value={getFormattedDate(patient?.createdAt || "") || "-"}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardViewWrapper>
  );
};

export default CardViewPatientCard;
