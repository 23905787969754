// import React from "react";

// // third-party imports
// import { Box, Typography, Switch } from "@mui/material";

// // project imports
// import DoctorGrid from "./DoctorGrid";
// import YourAvailability from "./YourAvailability";

// const NewAppointment: React.FC = () => {
//   // props & state values
//   const [yourAvailabilitySelected, setYourAvailabilitySelected] =
//     React.useState(false);
//   const [isNeurologistSelected, setIsNeurologistSelected] =
//     React.useState(false);

//   // callbacks & functions
//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setYourAvailabilitySelected(event.target.checked);
//   };
//   const handleDoctorTypeChange = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     setIsNeurologistSelected(event.target.checked);
//   };

//   return (
//     <Box
//       sx={{
//         backgroundColor: "var(--neuro-bg-light-grey-secondary)",
//         padding: "26px 20px",
//       }}
//     >
//       <Box
//         sx={{
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           gap: "28px",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <Typography
//             sx={{
//               color: "var(--neuro-black-text)",
//               font: "400 31px/120% Roboto Condensed, -apple-system, Roboto, Helvetica, sans-serif",
//             }}
//           >
//             New Appointment
//           </Typography>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <Typography
//               sx={{
//                 color: yourAvailabilitySelected
//                   ? "var(--neuro-darkgrey_border)"
//                   : "var(--neuro-black-text)",
//                 textAlign: "right",
//                 alignSelf: "stretch",
//                 flexGrow: 1,
//                 margin: "auto 0",
//                 fontWeight: yourAvailabilitySelected ? 400 : 700,
//                 lineHeight: "120%",
//                 fontFamily:
//                   "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
//               }}
//             >
//               Doctor availability
//             </Typography>

//             <Switch
//               checked={yourAvailabilitySelected}
//               onChange={handleChange}
//               inputProps={{ "aria-label": "controlled" }}
//             />

//             <Typography
//               sx={{
//                 color: yourAvailabilitySelected
//                   ? "var(--neuro-black-text)"
//                   : "var(--neuro-darkgrey_border)",
//                 alignSelf: "stretch",
//                 flexGrow: 1,
//                 margin: "auto 0",
//                 fontWeight: yourAvailabilitySelected ? 700 : 400,
//                 lineHeight: "120%",
//                 fontFamily:
//                   "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
//               }}
//             >
//               Your Availability
//             </Typography>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           <Typography
//             sx={{
//               color: isNeurologistSelected
//                 ? "var(--neuro-darkgrey_border)"
//                 : "var(--neuro-black-text)",
//               textAlign: "right",
//               alignSelf: "stretch",
//               margin: "auto 0",
//               fontWeight: isNeurologistSelected ? 400 : 700,
//               lineHeight: "120%",
//               fontFamily:
//                 "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
//             }}
//           >
//             General Physician
//           </Typography>

//           <Switch
//             checked={isNeurologistSelected}
//             onChange={handleDoctorTypeChange}
//             inputProps={{ "aria-label": "controlled" }}
//           />

//           <Typography
//             sx={{
//               color: isNeurologistSelected
//                 ? "var(--neuro-black-text)"
//                 : "var(--neuro-darkgrey_border)",
//               alignSelf: "stretch",
//               margin: "auto 0",
//               fontWeight: isNeurologistSelected ? 700 : 400,
//               lineHeight: "120%",
//               fontFamily:
//                 "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
//             }}
//           >
//             Neurologist
//           </Typography>
//         </Box>
//         {!yourAvailabilitySelected && (
//           <DoctorGrid isNeurologistSelected={isNeurologistSelected} />
//         )}
//         {yourAvailabilitySelected && (
//           <YourAvailability
//             yourAvailabilitySelected={yourAvailabilitySelected}
//             isNeurologistSelected={isNeurologistSelected}
//           />
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default NewAppointment;

import NewFlow from "../AppointmentBooking/NewFlow";

const NewAppointment = () => {
  return <NewFlow />;
};

export default NewAppointment;
