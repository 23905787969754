import { Box, useMediaQuery } from "@mui/material";
import { CustomStepperProps } from "../../types/forms";

const CustomStepper = ({
  steps,
  activeStep,
  onStepChange,
  stepCompleted,
  readOnly = false,
}: CustomStepperProps) => {
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1.625rem",
        marginBottom: "2.125rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: isMobile ? "0.938rem" : "1.875rem",
          overflowX: "auto",
        }}
      >
        {steps.map((_: any, index: number) => (
          <Box
            key={index}
            onClick={() => {
              if (
                readOnly ||
                index === activeStep ||
                stepCompleted[index + 1] ||
                stepCompleted[index - 1] ||
                stepCompleted[index]
              ) {
                onStepChange(index);
              }
            }}
            sx={{
              width: "6.25rem",
              height: "0.625rem",
              borderRadius: "0.125rem",
              cursor:
                readOnly ||
                stepCompleted[index + 1] ||
                stepCompleted[index - 1] ||
                stepCompleted[index] ||
                index === activeStep
                  ? "pointer"
                  : "not-allowed",
              backgroundColor:
                index === activeStep
                  ? "var(--neuro-button-bg-success)"
                  : stepCompleted[index + 1] ||
                      stepCompleted[index - 1] ||
                      stepCompleted[index]
                    ? "var(--neuro-button-bg-secondary)"
                    : "var(--neuro-light-grey_border)",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CustomStepper;
