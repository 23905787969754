// import React, { useState, useCallback, useEffect } from 'react';


// interface CheckboxGroupProps {
//   question: {
//     type: string;
//     name: string;
//     title: string;
//     isRequired: boolean;
//     choices: { value: string; text: string }[];
//     correctAnswer: string[] | string;
//   };
//   index: number;
//   updateQuestion: (index: number, updatedQuestion: any) => void;
//   handleDuplicateQuestion: (index: number) => void;
//   handleRemoveQuestion: (index: number) => void;
// }

// const DynamicCheckboxGroup: React.FC<CheckboxGroupProps> = ({
//   question,
//   index,
//   updateQuestion,
//   handleDuplicateQuestion,
//   handleRemoveQuestion,
// }) => {
//   const [localQuestion, setLocalQuestion] = useState(question);
//   const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
//   const [editingName, _] = useState(question.name);
//   const [editingTitle, setEditingTitle] = useState(question.title);

//   // Synchronize with parent whenever the local state changes
//   const syncWithParent = useCallback(() => {
//     updateQuestion(index, { ...localQuestion, name: editingName });
//   }, [index, localQuestion, editingName, updateQuestion]);

//   // Update state on title change
//   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newTitle = e.target.value;
//     setEditingTitle(newTitle);
//     setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
//   };

//   useEffect(() => {
//     // Synchronize with parent whenever localQuestion changes
//     syncWithParent();
//   }, [localQuestion, syncWithParent]);

//   const handleToggleRequired = () => {
//     const updatedRequired = !localQuestion.isRequired;
//     setLocalQuestion((prev) => ({ ...prev, isRequired: updatedRequired }));
//   };

//   const handleAddChoice = () => {
//     const newChoice = { value: `feature${localQuestion.choices.length + 1}`, text: `Feature ${localQuestion.choices.length + 1}` };
//     setLocalQuestion((prev) => {
//       const updatedChoices = [...prev.choices, newChoice];
//       return { ...prev, choices: updatedChoices };
//     });
//   };

//   const handleRemoveChoice = (choiceIndex: number) => {
//     setLocalQuestion((prev) => {
//       const updatedChoices = prev.choices.filter((_, idx) => idx !== choiceIndex);
//       const updatedCorrectAnswer = Array.isArray(prev.correctAnswer)
//         ? prev.correctAnswer.filter((answer) => answer !== prev.choices[choiceIndex].value)
//         : [];
//       return { ...prev, choices: updatedChoices, correctAnswer: updatedCorrectAnswer };
//     });
//   };

//   const handleEditChoice = (choiceIndex: number, newLabel: string) => {
//     setLocalQuestion((prev) => {
//       const updatedChoices = [...prev.choices];
//       updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], text: newLabel };
//       return { ...prev, choices: updatedChoices };
//     });
//   };

//   const handleToggleCorrectAnswer = (choiceValue: string) => {
//     setLocalQuestion((prev) => {
//       let updatedCorrectAnswer = Array.isArray(prev.correctAnswer)
//         ? prev.correctAnswer.includes(choiceValue)
//           ? prev.correctAnswer.filter((value) => value !== choiceValue)
//           : [...prev.correctAnswer, choiceValue]
//         : [];
//       return { ...prev, correctAnswer: updatedCorrectAnswer };
//     });
//   };

//   const handleCorrectAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const inputValue = e.target.value;
//     setLocalQuestion((prev) => ({ ...prev, correctAnswer: inputValue }));
//   };

//   return (
//     <div className="question-container">
//       <div className="question-header">
//         <div>{localQuestion.name} {" "}</div>
//         <input
//           type="text"
//           value={editingTitle}
//           onChange={handleTitleChange}
//           className="question-name-input"
//           aria-label="Question Name"
//         />
//         {localQuestion.isRequired && <span className="required">*</span>}
//       </div>

//       <div className="checkbox-group">
//         {localQuestion.choices.map((choice, choiceIndex) => (
//           <div key={choice.value} className="checkbox-item">
//             <button
//               className="remove-button"
//               onClick={() => handleRemoveChoice(choiceIndex)}
//               aria-label={`Remove ${choice.text}`}
//             >
//               -
//             </button>
//             <input
//               type="checkbox"
//               id={choice.value}
//               name={localQuestion.name}
//               className="checkbox"
//               checked={Array.isArray(localQuestion.correctAnswer) && localQuestion.correctAnswer.includes(choice.value)}
//               onChange={() => handleToggleCorrectAnswer(choice.value)}
//               aria-label={`Toggle correct answer for ${choice.text}`}
//             />
//             {editingChoiceIndex === choiceIndex ? (
//               <input
//                 type="text"
//                 value={choice.text}
//                 onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
//                 onBlur={() => setEditingChoiceIndex(null)}
//                 autoFocus
//                 className="edit-label-input"
//                 aria-label={`Edit ${choice.text}`}
//               />
//             ) : (
//               <span
//                 onClick={() => setEditingChoiceIndex(choiceIndex)}
//                 className="editable-text"
//               >
//                 {choice.text}
//               </span>
//             )}
//           </div>
//         ))}
//         <div className="checkbox-item">
//           <button
//             className="add-button"
//             onClick={handleAddChoice}
//             aria-label="Add Choice"
//           >
//             +
//           </button>
//         </div>
//       </div>

//       <div className="correct-answer-input">
//         <label style={{ fontSize: '14px' }}>Correct Answer(s): </label>
//         <input
//           type="text"
//           value={Array.isArray(localQuestion.correctAnswer)
//             ? localQuestion.correctAnswer.join(', ')
//             : localQuestion.correctAnswer}
//           onChange={handleCorrectAnswerChange}
//           placeholder="Enter correct answers, separated by commas"
//         />
//       </div>

//       <div className="question-controls">
//         <button
//           className="duplicate-button button-spacing blue-bg"
//           onClick={() => handleDuplicateQuestion(index)}
//         >
//            Duplicate
//         </button>
//         <button
//           className="delete-button button-spacing red-bg required-button"
//           onClick={() => handleRemoveQuestion(index)}
//         >
//       Delete
//         </button>
//         <button
//           className={`required-button ${localQuestion.isRequired ? 'active1' : ''}`}
//           onClick={handleToggleRequired}
//           aria-label={`Mark as ${localQuestion.isRequired ? 'Not Required' : 'Required'}`}
//         >
    
//           {localQuestion.isRequired ? ' Required' : ' Required'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DynamicCheckboxGroup;




import React, { useState, useCallback, useEffect } from "react";

interface CheckboxGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    isRequired: boolean;
    showScore: boolean;
    choices: { value: string; text: string; score: number }[];
    correctAnswer: string[] | string;
  };
  index: number;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
}

const DynamicCheckboxGroup: React.FC<CheckboxGroupProps> = ({
  question,
  index,
  updateQuestion,
  handleDuplicateQuestion,
  handleRemoveQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState(question);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(
    null
  );
  const [editingName, _] = useState(question.name);
  const [editingTitle, setEditingTitle] = useState(question.title);

  // Synchronize with parent whenever the local state changes
  const syncWithParent = useCallback(() => {
    updateQuestion(index, { ...localQuestion, name: editingName });
  }, [index, localQuestion, editingName, updateQuestion]);

  // Update state on title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setEditingTitle(newTitle);
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
  };

  useEffect(() => {
    // Synchronize with parent whenever localQuestion changes
    syncWithParent();
  }, [localQuestion, syncWithParent]);

  const handleToggleRequired = () => {
    const updatedRequired = !localQuestion.isRequired;
    setLocalQuestion((prev) => ({ ...prev, isRequired: updatedRequired }));
  };


  const handleAddChoice = () => {
    const newChoice = {
      value: `feature${localQuestion.choices.length + 1}`,
      text: `Feature ${localQuestion.choices.length + 1}`,
      score: 0,
    };
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices, newChoice];
      return { ...prev, choices: updatedChoices };
    });
  };

  const handleRemoveChoice = (choiceIndex: number) => {
    setLocalQuestion((prev) => {
      const updatedChoices = prev.choices.filter((_, idx) => idx !== choiceIndex);
      const updatedCorrectAnswer = Array.isArray(prev.correctAnswer)
        ? prev.correctAnswer.filter((answer) => answer !== prev.choices[choiceIndex].value)
        : [];
      return { ...prev, choices: updatedChoices, correctAnswer: updatedCorrectAnswer };
    });
  };

  const handleEditChoice = (choiceIndex: number, newLabel: string) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], text: newLabel };
      return { ...prev, choices: updatedChoices };
    });
  };

  const handleEditScore = (choiceIndex: number, newScore: number) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      // Update both the `score` and `value` fields based on `newScore`
      updatedChoices[choiceIndex] = {
        ...updatedChoices[choiceIndex],
        score: newScore,
        value: `${newScore}`, // Convert the score to string for the `value`
      };
      return { ...prev, choices: updatedChoices };
    });
  };
  

  const handleToggleCorrectAnswer = (choiceValue: string) => {
    setLocalQuestion((prev) => {
      let updatedCorrectAnswer = Array.isArray(prev.correctAnswer)
        ? prev.correctAnswer.includes(choiceValue)
          ? prev.correctAnswer.filter((value) => value !== choiceValue)
          : [...prev.correctAnswer, choiceValue]
        : [];
      return { ...prev, correctAnswer: updatedCorrectAnswer };
    });
  };

  return (
    <div className="question-container">
      <div className="question-header">
        <div>{localQuestion.name} {" "}</div>
        <input
          type="text"
          value={editingTitle}
          onChange={handleTitleChange}
          className="question-name-input"
          aria-label="Question Name"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="checkbox-group">
        {localQuestion.choices.map((choice, choiceIndex) => (
          <div key={choice.value} className="checkbox-item">
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
              aria-label={`Remove ${choice.text}`}
            >
              -
            </button>
            <input
              type="checkbox"
              id={choice.value}
              name={localQuestion.name}
              className="checkbox"
              checked={Array.isArray(localQuestion.correctAnswer) && localQuestion.correctAnswer.includes(choice.value)}
              onChange={() => handleToggleCorrectAnswer(choice.value)}
              aria-label={`Toggle correct answer for ${choice.text}`}
            />
            {editingChoiceIndex === choiceIndex ? (
              <input
                type="text"
                value={choice.text}
                onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                onBlur={() => setEditingChoiceIndex(null)}
                autoFocus
                className="edit-label-input"
                aria-label={`Edit ${choice.text}`}
              />
            ) : (
              <span
                onClick={() => setEditingChoiceIndex(choiceIndex)}
                className="editable-text"
              >
                {choice.text}
              </span>
            )}
            {localQuestion.showScore && (
              <input
                type="number"
                value={choice.score}
                onChange={(e) => handleEditScore(choiceIndex, Number(e.target.value))}
                className="score-input"
                aria-label={`Edit score for ${choice.text}`}
              />
            )}
          </div>
        ))}
        <div className="checkbox-item">
          <button
            className="add-button"
            onClick={handleAddChoice}
            aria-label="Add Choice"
          >
            +
          </button>
        </div>
      </div>

      <div className="question-controls">
        {/* <button
          className={`show-score-toggle ${localQuestion.showScore ? "active" : ""}`}
          onClick={handleToggleShowScore}
        >
          {localQuestion.showScore ? "Hide Scores" : "Show Scores"}
        </button> */}
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? "active" : ""}`}
          onClick={handleToggleRequired}
          aria-label={`Mark as ${localQuestion.isRequired ? "Not Required" : "Required"}`}
        >
          {localQuestion.isRequired ? "Required" : "Not Required"}
        </button>
      </div>
    </div>
  );
};

export default DynamicCheckboxGroup;
