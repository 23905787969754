import * as yup from "yup";

export const adminProfileSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number")
    .required("Phone number is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  profileImage: yup.mixed().required("Profile image is required"),
});

export const doctorProfileSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number")
    .required("Phone number is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  profileImage: yup.mixed().required("Profile image is required"),
  specializations: yup
    .array()
    .of(yup.string().required()) // Ensure every element is a string
    .required("At least one specialization is required"),
  acceptsInPerson: yup.boolean().default(false).optional(),
  onlineConsultationFee: yup
    .number()
    .typeError("Online consultation fee must be a number")
    .positive("Online consultation fee must be a positive number")
    .required("Online consultation fee is required"),
  inPersonConsultationFee: yup
    .number()
    .typeError("In-person consultation fee must be a number")
    .positive("In-person consultation fee must be a positive number")
    .required("In-person consultation fee is required"), // Ensure it's a number, not null
  yearsOfExperience: yup
    .number()
    .typeError("Invalid number format.")
    .required("Years of experience is required")
    .positive("Years of experience must be a positive number")
    .integer("Years of experience must be an integer"),
  spokenLanguages: yup
    .array()
    .of(yup.string().required()) // Ensure every element is a string
    .required("Spoken languages are required"),
  experienceDetails: yup.string().nullable(),
  publishedWorks: yup.string().nullable(),
  qualifications: yup.string().required("Qualifications is required"),
  defaultHospitalId: yup.string().required(),
});

export const patientProfileSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  gender: yup.string().required("Gender is required"),
  age: yup
    .string()
    .nullable()
    .matches(/^\d*$/, "Age must be a number")
    .test(
      "age-or-dob",
      "Either Date of Birth or Age is required",
      function (value) {
        const { dateOfBirth } = this.parent;
        return !!value || !!dateOfBirth;
      }
    ),
  dateOfBirth: yup
    .date()
    .nullable()
    .test(
      "dob-or-age",
      "Either Date of Birth or Age is required",
      function (value) {
        const { age } = this.parent;
        return !!value || !!age;
      }
    ),
  streetAddress: yup.string(),
  profileImage: yup.mixed().required("Profile image is required"),
  height: yup.string(),
  weight: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipCode: yup.string(),
  country: yup.string(),
});
