// import React, { useState, useCallback } from 'react';

// interface RadioGroupProps {
//   question: {
//     type: string;
//     name: string;
//     title: string;
//     isRequired: boolean;
//     choices: { value: string; text: string }[];
//     correctAnswer: string[]; // Correct answer is an array of strings
//   };
//   index: number;
//   updateQuestion: (index: number, updatedQuestion: any) => void;
//   handleDuplicateQuestion: (index: number) => void;
//   handleRemoveQuestion: (index: number) => void;
// }

// const DynamicRanking: React.FC<RadioGroupProps> = ({
//   question,
//   index,
//   updateQuestion,
//   handleDuplicateQuestion,
//   handleRemoveQuestion,
// }) => {
//   const [localQuestion, setLocalQuestion] = useState(question);
//   const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
//   const [editingName, _] = useState(question.name);
//   const [editingTitle, setEditingTitle] = useState(question.title);  // New state for editing title

//   // Sync local question state with the parent
//   const syncWithParent = useCallback(() => {
//     updateQuestion(index, { ...localQuestion, name: editingName, title: editingTitle });  // Include title in update
//   }, [index, localQuestion, editingName, editingTitle, updateQuestion]);

//   // Handle title change
//   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newTitle = e.target.value;
//     setEditingTitle(newTitle);
//     setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
//     syncWithParent();
//   };

//   // Handle toggling required
//   const handleToggleRequired = () => {
//     const updatedRequired = !localQuestion.isRequired;
//     setLocalQuestion((prev) => ({ ...prev, isRequired: updatedRequired }));
//     syncWithParent();
//   };

//   // Handle adding a choice
//   const handleAddChoice = () => {
//     const newChoice = { value: `feature${localQuestion.choices.length + 1}`, text: `Feature ${localQuestion.choices.length + 1}` };
//     setLocalQuestion((prev) => {
//       const updatedChoices = [...prev.choices, newChoice];
//       return { ...prev, choices: updatedChoices };
//     });
//     syncWithParent();
//   };

//   // Handle removing a choice
//   const handleRemoveChoice = (choiceIndex: number) => {
//     setLocalQuestion((prev) => {
//       const updatedChoices = prev.choices.filter((_, idx) => idx !== choiceIndex);
//       const updatedCorrectAnswer = prev.correctAnswer.filter((answer) => answer !== prev.choices[choiceIndex].value);

//       return { ...prev, choices: updatedChoices, correctAnswer: updatedCorrectAnswer };
//     });
//     syncWithParent();
//   };

//   // Handle editing a choice label
//   const handleEditChoice = (choiceIndex: number, newLabel: string) => {
//     setLocalQuestion((prev) => {
//       const updatedChoices = [...prev.choices];
//       updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], text: newLabel };
//       return { ...prev, choices: updatedChoices };
//     });
//     syncWithParent();
//   };


//   const handleCorrectAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const inputValue = e.target.value;
//     const parsedCorrectAnswers = inputValue.split(',').map((val) => val.trim());
  
//     setLocalQuestion((prev) => ({ ...prev, correctAnswer: parsedCorrectAnswers }));
//     syncWithParent();
//   };
  


//   return (
//     <div className="question-container">
//       <div className="question-header">
//         {/* Editable title */}
//         {editingName}
//         <input
//           type="text"
//           value={editingTitle}  // Bind the title input to editingTitle state
//           onChange={handleTitleChange}  // Handle title change
//           className="question-name-input"
//           aria-label="Question Title"
//         />
//         {localQuestion.isRequired && <span className="required">*</span>}
//       </div>

//       <div className="radio-group">
//         {localQuestion.choices.map((choice, choiceIndex) => (
//           <div key={choice.value} className="radio-item">
//             <button
//               className="remove-button"
//               onClick={() => handleRemoveChoice(choiceIndex)}
//               aria-label={`Remove ${choice.text}`}
//             >
//               -
//             </button>
     
//             {editingChoiceIndex === choiceIndex ? (
//               <input
//                 type="text"
//                 value={choice.text}
//                 onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
//                 onBlur={() => setEditingChoiceIndex(null)}
//                 autoFocus
//                 className="edit-label-input"
//                 aria-label={`Edit ${choice.text}`}
//               />
//             ) : (
//               <span
//                 onClick={() => setEditingChoiceIndex(choiceIndex)}
//                 className="editable-text"
//               >
//                 {choice.text}
//               </span>
//             )}
//           </div>
//         ))}
//         <div className="radio-item">
//           <button
//             className="add-button"
//             onClick={handleAddChoice}
//             aria-label="Add Choice"
//           >
//             +
//           </button>
//         </div>
//       </div>


//       <div className="correct-answer-input">
//         <label style={{fontSize:'14px'}}>Correct Answer(s): </label>
//         <input
//           type="text"
//           value={Array.isArray(localQuestion.correctAnswer)
//             ? localQuestion.correctAnswer.join(', ')  // If it's an array, display as a comma-separated string
//             : localQuestion.correctAnswer}
//           onChange={handleCorrectAnswerChange}
//           placeholder="Enter correct answers, separated by commas"
//         />
//       </div>
//       <div className="question-controls">
//         <button
//           className="duplicate-button button-spacing blue-bg"
//           onClick={() => handleDuplicateQuestion(index)}
//         >
//    Duplicate
//         </button>
//         <button
//           className="delete-button button-spacing red-bg required-button"
//           onClick={() => handleRemoveQuestion(index)}
//         >
//    Delete
//         </button>
//         <button
//           className={`required-button ${localQuestion.isRequired ? 'active1' : ''}`}
//           onClick={handleToggleRequired}
//           aria-label={`Mark as ${localQuestion.isRequired ? 'Not Required' : 'Required'}`}
//         >
      
//           {localQuestion.isRequired ? ' Required' : ' Required'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DynamicRanking;




import React, { useState, useCallback } from 'react';

interface RadioGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    isRequired: boolean;
    choices: { value: string; text: string; score: number }[];
    correctAnswer: string[];
    showScore: boolean;
  };
  index: number;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
}

const DynamicRanking: React.FC<RadioGroupProps> = ({
  question,
  index,
  updateQuestion,
  handleDuplicateQuestion,
  handleRemoveQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState(question);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
  const [editingName, _] = useState(question.name);
  const [editingTitle, setEditingTitle] = useState(question.title);

  const syncWithParent = useCallback(() => {
    updateQuestion(index, { ...localQuestion, name: editingName, title: editingTitle });
  }, [index, localQuestion, editingName, editingTitle, updateQuestion]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setEditingTitle(newTitle);
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
    syncWithParent();
  };

  const handleToggleRequired = () => {
    const updatedRequired = !localQuestion.isRequired;
    setLocalQuestion((prev) => ({ ...prev, isRequired: updatedRequired }));
    syncWithParent();
  };

  const handleAddChoice = () => {
    const newChoice = {
      value: `feature${localQuestion.choices.length + 1}`,
      text: `Feature ${localQuestion.choices.length + 1}`,
      score: 0,
    };
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices, newChoice];
      return { ...prev, choices: updatedChoices };
    });
    syncWithParent();
  };

  const handleRemoveChoice = (choiceIndex: number) => {
    setLocalQuestion((prev) => {
      const updatedChoices = prev.choices.filter((_, idx) => idx !== choiceIndex);
      return { ...prev, choices: updatedChoices };
    });
    syncWithParent();
  };

  const handleEditChoice = (choiceIndex: number, newLabel: string) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], text: newLabel };
      return { ...prev, choices: updatedChoices };
    });
    syncWithParent();
  };

  const syncWithParentImmediate = (updatedQuestion: typeof localQuestion) => {
    updateQuestion(index, { ...updatedQuestion, name: editingName, title: editingTitle });
  };
  // const handleEditScore = (choiceIndex: number, newScore: number) => {
  //   setLocalQuestion((prev) => {
  //     const updatedChoices = [...prev.choices];
  //     updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], score: newScore };
  //     const updatedQuestion = { ...prev, choices: updatedChoices };
  //     // Sync immediately after updating the state
  //     syncWithParentImmediate(updatedQuestion);
  //     return updatedQuestion;
  //   });
  // };

  const handleEditScore = (choiceIndex: number, newScore: number) => {
    setLocalQuestion((prev) => {
      const updatedChoices = [...prev.choices];
      updatedChoices[choiceIndex] = { 
        ...updatedChoices[choiceIndex], 
        score: newScore, 
        value: `${newScore}` // Update `value` as a string of the new score
      };
  
      const updatedQuestion = { ...prev, choices: updatedChoices };
  
      // Sync immediately after updating the state
      syncWithParentImmediate(updatedQuestion);
  
      return updatedQuestion;
    });
  };
  
  

  const handleCorrectAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const parsedCorrectAnswers = inputValue.split(',').map((val) => val.trim());
    setLocalQuestion((prev) => ({ ...prev, correctAnswer: parsedCorrectAnswers }));
    syncWithParent();
  };

  return (
    <div className="question-container">
      <div className="question-header">
        {editingName}
        <input
          type="text"
          value={editingTitle}
          onChange={handleTitleChange}
          className="question-name-input"
          aria-label="Question Title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="radio-group">
        {localQuestion.choices.map((choice, choiceIndex) => (
          <div key={choice.value} className="radio-item">
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
              aria-label={`Remove ${choice.text}`}
            >
              -
            </button>

            {editingChoiceIndex === choiceIndex ? (
              <input
                type="text"
                value={choice.text}
                onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                onBlur={() => setEditingChoiceIndex(null)}
                autoFocus
                className="edit-label-input"
                aria-label={`Edit ${choice.text}`}
              />
            ) : (
              <span
                onClick={() => setEditingChoiceIndex(choiceIndex)}
                className="editable-text"
              >
                {choice.text}
              </span>
            )}

            {localQuestion.showScore && (
              <input
                type="number"
                value={choice.score}
                onChange={(e) => handleEditScore(choiceIndex, parseInt(e.target.value, 10))}
                className="score-input"
                aria-label={`Score for ${choice.text}`}
              />
            )}
          </div>
        ))}
        <div className="radio-item">
          <button
            className="add-button"
            onClick={handleAddChoice}
            aria-label="Add Choice"
          >
            +
          </button>
        </div>
      </div>

      <div className="correct-answer-input">
        <label style={{ fontSize: '14px' }}>Correct Answer(s): </label>
        <input
          type="text"
          value={Array.isArray(localQuestion.correctAnswer)
            ? localQuestion.correctAnswer.join(', ')
            : localQuestion.correctAnswer}
          onChange={handleCorrectAnswerChange}
          placeholder="Enter correct answers, separated by commas"
        />
      </div>

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? 'active1' : ''}`}
          onClick={handleToggleRequired}
          aria-label={`Mark as ${localQuestion.isRequired ? 'Not Required' : 'Required'}`}
        >
          {localQuestion.isRequired ? 'Required' : 'Not Required'}
        </button>
      </div>
    </div>
  );
};

export default DynamicRanking;
