// import React, { useState, useEffect } from "react";
// import { FaCopy, FaTrash, FaStar, FaRegStar } from "react-icons/fa";
// // import "./component.css";

// interface Validator {
//   type: string;
//   text: string;
//   minValue: number;
//   maxValue: number;
// }

// interface Question {
//   type: string;
//   name: string;
//   title: string;
//   inputType: string;
//   placeholder: string;
//   isRequired: boolean;
//   validators: Validator[];
// }

// interface DynamicTextFieldProps {
//   question: Question;
//   index: number;
//   handleDuplicateQuestion: (index: number) => void;
//   handleRemoveQuestion: (index: number) => void;
//   updateQuestion: (index: number, updatedQuestion: Question) => void;
// }

// const DynamicTextField: React.FC<DynamicTextFieldProps> = ({
//   question,
//   index,
//   handleDuplicateQuestion,
//   handleRemoveQuestion,
//   updateQuestion,
// }) => {
//   const [localQuestion, setLocalQuestion] = useState<Question>(question);
//   const [errorMessage, setErrorMessage] = useState("");

//   // Sync local state with parent prop changes
//   useEffect(() => {
//     setLocalQuestion(question);
//   }, [question]);


//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       updateQuestion(index, { ...localQuestion });
//     }, 300);
//     return () => clearTimeout(timeout);
//   }, [localQuestion, index, updateQuestion]);

//   // Handle title change
//   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newTitle = e.target.value;
//     setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
//   };

//   // Handle input value change
//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newValue = e.target.value;
//     const numericValue = parseFloat(newValue);

//     if (
//       localQuestion.validators[0]?.type === "numeric" &&
//       (isNaN(numericValue) ||
//         numericValue < localQuestion.validators[0].minValue ||
//         numericValue > localQuestion.validators[0].maxValue)
//     ) {
//       setErrorMessage(localQuestion.validators[0]?.text || "Invalid input.");
//     } else {
//       setErrorMessage("");
//       setLocalQuestion((prev) => ({
//         ...prev,
//         placeholder: newValue, // Update the placeholder or other property as needed
//       }));
//     }
//   };

//   // Toggle required field
//   const handleToggleRequired = () => {
//     setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
//   };

//   return (
//     <div className="question-container">
//       {/* Title Input */}
//       <div className="question-header">
//       {localQuestion.name}
//         <input
//           type="text"
//           value={localQuestion.title}
//           onChange={handleTitleChange}
//           className="question-name-input"
//         />
//         {localQuestion.isRequired && <span className="required">*</span>}
//       </div>

//       {/* Numeric Input Field */}
//       <div className="numeric-input-group">
//         <input
//           type={localQuestion.inputType}
//           name={localQuestion.name}
//           value={localQuestion.placeholder}
//           onChange={handleInputChange}
//           placeholder={localQuestion.placeholder}
//           className={`numeric-input ${errorMessage ? "input-error" : ""}`}
//         />
//         {errorMessage && <span className="error-text">{errorMessage}</span>}
//       </div>

//       {/* Control Buttons */}
//       <div className="question-controls">
//         <button
//           className="duplicate-button button-spacing blue-bg"
//           onClick={() => handleDuplicateQuestion(index)}
//         >
//      Duplicate
//         </button>

//         <button
//           className="delete-button button-spacing red-bg"
//           onClick={() => handleRemoveQuestion(index)}
//         >
//          Delete
//         </button>

//         <button
//           className={`required-button ${
//             localQuestion.isRequired ? "active1" : ""
//           }`}
//           onClick={handleToggleRequired}
//           aria-pressed={localQuestion.isRequired}
//         >
    
//           {localQuestion.isRequired ? " Required" : " Not Required"}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DynamicTextField;

import React, { useState, useEffect } from "react";

interface Validator {
  type: string;
  text: string;
  minValue: number;
  maxValue: number;
}

interface Question {
  type: string;
  name: string;
  title: string;
  inputType: string;
  placeholder: string;
  isRequired: boolean;
  validators: Validator[];
  showScore: boolean;
}

interface DynamicTextFieldProps {
  question: Question;
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: Question) => void;
}

const DynamicTextField: React.FC<DynamicTextFieldProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [localQuestion, setLocalQuestion] = useState<Question>(question);
  const [errorMessage, setErrorMessage] = useState("");

  // Sync local state with parent prop changes
  useEffect(() => {
    setLocalQuestion(question);
  }, [question]);

  // Debounce updates to parent state
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateQuestion(index, { ...localQuestion });
    }, 300);
    return () => clearTimeout(timeout);
  }, [localQuestion, index, updateQuestion]);

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
  };

  // Handle input value change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const numericValue = parseFloat(newValue);

    if (
      localQuestion.validators[0]?.type === "numeric" &&
      (isNaN(numericValue) ||
        numericValue < localQuestion.validators[0].minValue ||
        numericValue > localQuestion.validators[0].maxValue)
    ) {
      setErrorMessage(localQuestion.validators[0]?.text || "Invalid input.");
    } else {
      setErrorMessage("");
      setLocalQuestion((prev) => ({
        ...prev,
        placeholder: newValue,
      }));
    }
  };

  // Handle validator changes
  const handleValidatorChange = (
    field: "minValue" | "maxValue",
    value: string
  ) => {
    const numericValue = parseFloat(value);
    setLocalQuestion((prev) => ({
      ...prev,
      validators: prev.validators.map((validator) =>
        validator.type === "numeric"
          ? {
              ...validator,
              [field]: isNaN(numericValue) ? 0 : numericValue,
            }
          : validator
      ),
    }));
  };

  // Toggle required field
  const handleToggleRequired = () => {
    setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
  };

  return (
    <div className="question-container">
      {/* Title Input */}
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      {/* Numeric Input Field */}
      <div className="numeric-input-group">
        <input
          type={localQuestion.inputType}
          name={localQuestion.name}
          value={localQuestion.placeholder}
          onChange={handleInputChange}
          placeholder={localQuestion.placeholder}
          className={`numeric-input ${errorMessage ? "input-error" : ""}`}
        />
        {errorMessage && <span className="error-text">{errorMessage}</span>}
      </div>

      {/* Min and Max Value Fields (Conditional) */}
      {localQuestion.showScore && (
        <div className="validator-fields">
          <div className="validator-field">
            <label htmlFor={`minValue-${index}`}>Min Value:</label>
            <input
              id={`minValue-${index}`}
              type="number"
              value={localQuestion.validators[0]?.minValue || ""}
              onChange={(e) =>
                handleValidatorChange("minValue", e.target.value)
              }
              placeholder="Min"
              className="validator-input"
            />
          </div>
          <div className="validator-field">
            <label htmlFor={`maxValue-${index}`}>Max Value:</label>
            <input
              id={`maxValue-${index}`}
              type="number"
              value={localQuestion.validators[0]?.maxValue || ""}
              onChange={(e) =>
                handleValidatorChange("maxValue", e.target.value)
              }
              placeholder="Max"
              className="validator-input"
            />
          </div>
        </div>
      )}

      {/* Control Buttons */}
      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>

        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>

        <button
          className={`required-button ${
            localQuestion.isRequired ? "active1" : ""
          }`}
          onClick={handleToggleRequired}
          aria-pressed={localQuestion.isRequired}
        >
          {localQuestion.isRequired ? "Required" : "Not Required"}
        </button>
      </div>
    </div>
  );
};

export default DynamicTextField;
