import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import DoctorCard from "../AppointmentBooking/DoctorCard";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { getAllDoctors } from "../../../services/appointmentsService";
import useAppStore from "../../../store/appStore";
import { NewAppointmentDoctorGridProps } from "../../../types/appointments";

const DoctorGrid: React.FC<NewAppointmentDoctorGridProps> = ({
  selectedSpeciality,
  patientId,
  from = "",
}) => {
  // props & state values
  const { isMenuCollapsed } = useAppStore();
  const [loading, setLoading] = useState(true);
  const [doctors, setDoctors] = useState([]);

  // callbacks & functions
  const specialityName = (speciality: string) => {
    switch (speciality) {
      case "primary_care":
        return "PRIMARY_CARE";
      case "neurology":
        return "NEUROLOGIST";
      default:
        return "PEDIATRICS";
    }
  };

  const loadDoctors = async (specialization: string) => {
    try {
      const params = {
        specialization: patientId ? undefined : specialization,
        type: "APPOINTMENTS",
      };
      const response = await getAllDoctors(params);
      setDoctors(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const specialization = specialityName(selectedSpeciality);
    loadDoctors(specialization);
  }, [selectedSpeciality]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!loading && doctors?.length === 0) {
    return (
      <ContentCenter>
        <Typography className="no-data">Doctors not found</Typography>
      </ContentCenter>
    );
  }

  return (
    <Grid container spacing={2.5} sx={{ height: "100%" }}>
      {doctors?.map((doctor: any) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={
            isMenuCollapsed
              ? from === "meet"
                ? 12
                : from === "patient-list"
                  ? 6
                  : 4
              : from === "meet"
                ? 12
                : 6
          }
          key={doctor?.id}
        >
          <DoctorCard doctor={doctor} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DoctorGrid;
