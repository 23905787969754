// project imports
import { Box, Grid, Typography } from "@mui/material";
import useAppointmentStore from "../../../store/appointmentsStore";
import CustomRadioGroup from "../../ui/CustomRadioGroup";
import Input from "../../ui/Input";
import { inputLabelStyles } from "../../../utils/styles";
import { consultationTypes } from "../../../utils/common";

const AppointmentInputs = () => {
    // props & state values
    const { selectedConsultationType, setSelectedConsultationType, reasonForAppointment, setReasonForAppointment } = useAppointmentStore();

    // callbacks & functions
    const handleRadioChange = (value: string) => {
        setSelectedConsultationType(value);
    };

    return (
        <Box
        >
            <Box
                sx={{
                    paddingInline: "1.25rem",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Consultation type */}
                <Box sx={{ width: "100%" }}>
                    <Grid container spacing={1.25} alignItems="center">
                        <Grid item xs={4}>
                            <Typography sx={inputLabelStyles}>
                                Service :
                            </Typography>
                        </Grid>

                        <Grid item xs={8}>
                            <CustomRadioGroup
                                options={consultationTypes}
                                selectedValue={selectedConsultationType}
                                onChange={handleRadioChange}
                            />
                        </Grid>
                    </Grid>

                </Box>

                {/* Reason for appointment */}
                <Box sx={{ marginBlock: "1.25rem", width: "100%" }}>
                    <Grid container spacing={1.25} alignItems="center">
                        <Grid item xs={4}>
                            <Typography sx={inputLabelStyles}>
                                Reason For Appointment :
                            </Typography>
                        </Grid>

                        <Grid item xs={8}>
                            <Input
                                type="text"
                                className="input-secondary-textarea"
                                rows={2}
                                multiline
                                sx={{
                                    "& textarea": {
                                        minHeight: "20px",
                                    },
                                }}
                                // className="input-primary"
                                // sx={{
                                //     "& >.MuiInputBase-root": {
                                //         height: "2.5rem",
                                //     },
                                // }}
                                fullWidth
                                value={reasonForAppointment}
                                onChange={(e) => setReasonForAppointment(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* Divider */}
                <Box
                    sx={{
                        width: "100%",
                        height: "0.063rem",
                        border: "0.063rem dashed var(--neuro-secondary_border)",
                    }}
                />
            </Box>
        </Box>
    );
};

export default AppointmentInputs;
