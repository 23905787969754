import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import { CustomTooltipProps } from "../../types/ui";

const Tooltip: React.FC<CustomTooltipProps> = ({
  title,
  children,
  sx,
  placement = "top",
  ...props
}) => {
  return (
    <MuiTooltip title={title} placement={placement} sx={{ ...sx }} {...props}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
