const GeneralPhysicianIcon = (props: any) => (
  <svg
    width={54}
    height={60}
    viewBox="0 0 54 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4658 20.23L16.8187 20.2C16.9524 20.185 17.0734 20.1375 17.1742 20.0675L17.1717 20.07C17.5096 21.225 17.923 22.44 18.4399 23.7125C19.1029 25.3775 19.7559 26.745 20.4871 28.065L20.3862 27.865C20.3257 28.775 20.2526 29.71 20.1568 30.615C20.1114 31.1725 20.0005 31.6925 19.8316 32.1825L19.8442 32.1375C19.8139 32.15 19.7711 37.3375 19.7711 37.3375C19.7736 40.72 22.1359 43.5525 25.3126 44.305L25.3631 44.315C25.5068 43.9525 25.8547 43.7 26.2631 43.7H27.7103C28.1162 43.705 28.4641 43.955 28.6078 44.3075L28.6104 44.315C31.8451 43.56 34.2175 40.7275 34.2301 37.3425C34.2301 37.3425 34.1419 32.175 34.0965 32.14C33.9578 31.7025 33.8469 31.1875 33.7889 30.6575L33.7864 30.62C33.6981 29.71 33.6401 28.7975 33.5569 27.87C34.1797 26.755 34.8327 25.39 35.4075 23.9825L35.5033 23.7175C36.0201 22.445 36.421 21.2325 36.7715 20.075C36.8698 20.145 36.9908 20.1925 37.1219 20.2075H37.1244L37.4799 20.2375C37.9085 20.2825 38.2867 19.9325 38.322 19.39L38.7052 14.5625C38.7052 14.56 38.7052 14.5575 38.7052 14.5575C38.7052 14.15 38.3976 13.815 38.0018 13.765H37.9968H37.9539C38.0522 13.0625 38.1077 12.2525 38.1077 11.43C38.1077 9.635 37.8404 7.9 37.3438 6.2675L37.3765 6.395C35.9218 3.23 33.0376 0.9325 29.5634 0.29L29.4978 0.28C28.7515 0.1275 27.8817 0.025 26.9943 0H26.9716H26.9413C26.0362 0.0175 25.1664 0.1175 24.3243 0.295L24.4151 0.28C20.8804 0.94 18.0012 3.2375 16.5641 6.3275L16.5364 6.395C16.0674 7.895 15.7977 9.62 15.7977 11.405C15.7977 12.2375 15.8557 13.0575 15.9691 13.8575L15.959 13.765C15.5582 13.81 15.248 14.1425 15.248 14.5475V14.565L15.6313 19.3925C15.6666 19.9475 16.0498 20.2825 16.4633 20.2375L16.4658 20.23Z"
      fill="#273467"
    />
    <path
      d="M53.9959 52.1799C53.8194 44.5799 53.053 42.8599 52.6395 41.6949C52.4756 41.2274 52.342 38.0674 47.0778 36.0174C43.8506 34.7574 39.6856 34.7324 36.161 33.2549V37.3649C36.1534 41.7849 32.9616 45.4624 28.7386 46.2649L28.6806 46.2749C28.6025 46.7299 28.2092 47.0699 27.7377 47.0749H27.6923V50.1749C27.6923 53.2049 30.1681 55.6599 33.2238 55.6599C36.2795 55.6599 38.7553 53.2049 38.7553 50.1749V48.0099C37.2224 47.7824 36.0602 46.4874 36.0602 44.9224C36.0602 43.1999 37.4695 41.8024 39.2066 41.8024C40.9437 41.8024 42.3531 43.1999 42.3531 44.9224C42.3531 46.2874 41.4681 47.4474 40.2378 47.8724L40.2151 47.8799V50.1749V50.2924C40.2151 54.1224 37.0838 57.2274 33.2213 57.2274C29.3588 57.2274 26.2275 54.1224 26.2275 50.2924C26.2275 50.2499 26.2275 50.2099 26.2275 50.1674V50.1724V47.0449C25.7787 47.0149 25.4157 46.6799 25.3451 46.2499V46.2449C21.0843 45.4224 17.9076 41.7574 17.8975 37.3549V33.2124C14.3577 34.7199 10.1675 34.7499 6.92275 36.0124C1.64337 38.0599 1.52235 41.2124 1.361 41.6874C0.957607 42.8574 0.18108 44.5699 0.00459616 52.1749C-0.0256581 53.1749 0.0045963 54.7074 3.04515 56.0474C9.6658 58.6224 18.195 59.4224 26.6032 59.9974H27.41C35.8358 59.4274 44.3524 58.6299 50.968 56.0474C53.9959 54.7149 54.0236 53.1899 53.9959 52.1799ZM16.9823 49.0799H13.8409V52.2074H11.597V49.0899H8.45563V46.8649H11.597V43.7499H13.8409V46.8649H16.9823V49.0799Z"
      fill="#2171B0"
    />
  </svg>
);
export default GeneralPhysicianIcon;
