import React from "react";

// third-party imports
import api from "../../../services/apiService";

// project imports
import Button from "../../ui/Button";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import config from "../../../config";
import useAppStore from "../../../store/appStore";
import useAppointmentStore from "../../../store/appointmentsStore";
import { AppointmentCheckoutPaymentProps } from "../../../types/appointments";

const Payment: React.FC<AppointmentCheckoutPaymentProps> = ({
  amount,
  selectedDoctor,
  appointmentDate,
  availableSlotId,
  appointmentMode,
}) => {
  const { user } = useAuthStore();

  const navigate = useNavigate();

  const { showSnackbar } = useAppStore();

  const { setAppointmentId } = useAppointmentStore();

  // callbacks & functions
  const handlePaymentSuccess = () => {
    const data = {
      selectedDoctor: selectedDoctor,
    };
    navigate("/appointments/new-appointment/appointment-confirmation", {
      state: data,
    });
    // }
  };

  const extractAppointmentId = (input: string): string => {
    const prefix = "receipt_order_";
    const index = input.indexOf(prefix);
    if (index !== -1) {
      return input.substring(index + prefix.length);
    }
    return ""; // Return an empty string if the prefix is not found
  };

  const handlePayment = async () => {
    try {
      // Create order on the server
      const order = await api.post(`/api/payments/createOrder`, {
        patientId: user?.patientId as string,
        doctorId: selectedDoctor?.id as string,
        appointmentDate: appointmentDate,
        availableSlotId: availableSlotId,
        appointmentMode: appointmentMode,
        amount: amount,
      });

      const idString = extractAppointmentId(order?.data?.receipt);

      setAppointmentId(idString);
      const { id, currency } = order.data;

      // Configure Razorpay checkout options
      const options = {
        key: config.RAZORPAY_KEY_ID!,
        amount,
        currency: currency,
        name: config.APP_NAME,
        description: config.RAZORPAY_DESCRIPTION,
        order_id: id,
        handler: async (response: any) => {
          const data = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            appointmentId: idString,
          };

          // Verify payment signature and update status
          try {
            const result = await api.post(
              `/api/payments/verifySignature`,
              data
            );
            if (result.data.status === "success") {
              showSnackbar("Payment successfull", "success");
              handlePaymentSuccess();
            } else {
              showSnackbar("Payment not completed, please try again", "error");
            }
          } catch (error) {
            showSnackbar("Payment not completed, please try again", "error");
          }
        },
        prefill: {
          // name: `${user?.firstName} ${user?.lastName}`,
          name: `${user?.firstName}`,
          email: user?.email,
          contact: user?.mobile,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            showSnackbar("Payment was cancelled by the user", "info");
          },
        },
      };

      const rzp1 = new (window as any).Razorpay(options);
      rzp1.on("payment.failed", (response: any) => {
        alert("Payment failed. Reason: " + response.error.description);
      });
      rzp1.open();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Button
        fullWidth
        sx={{ marginTop: "40px", fontSize: "20px", height: "48px" }}
        className="warning-button"
        variant="contained"
        disabled={amount === null ? true : false}
        onClick={handlePayment}
      >
        Pay Now
      </Button>
    </div>
  );
};

export default Payment;
