const OintmentsIcon = (props: any) => (
  <svg
    fill="#54b895"
    height="17px"
    width="17px"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 56.989 56.989"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M56.626,39.241L41.881,27.045c0,0,0,0-0.001-0.001l-11.586-9.583l-0.001,0L15.548,5.264 c-0.397-0.329-0.979-0.301-1.344,0.063l-0.325,0.325l-8.798-5.5C4.686-0.095,4.172-0.036,3.844,0.293L0.293,3.844 c-0.33,0.329-0.388,0.842-0.141,1.237l5.501,8.798l-0.325,0.325c-0.365,0.365-0.392,0.947-0.063,1.344l33.977,41.078 c0.179,0.217,0.442,0.349,0.723,0.362c0.016,0,0.032,0.001,0.047,0.001c0.265,0,0.519-0.105,0.707-0.293l15.978-15.978 c0.199-0.199,0.305-0.473,0.292-0.754C56.975,39.683,56.843,39.42,56.626,39.241z M39.759,27.885l-6.618,6.619l-9.17-9.17 l5.751-5.75L39.759,27.885z M4.695,2.27l4.18,2.614L4.883,8.876L2.27,4.695L4.695,2.27z M5.971,10.616l4.645-4.645l1.812,1.133 l-5.324,5.324L5.971,10.616z M14.975,7.385l13.198,10.916l-6.324,6.324c-0.391,0.391-0.391,1.023,0,1.414l10.584,10.584 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l7.459-7.459l7.932,6.56l-1.429,1.429l-0.001,0.001 c0,0-0.001,0.001-0.001,0.001l-2.661,2.661c0,0-0.001,0.001-0.001,0.001c-0.001,0.001-0.001,0.001-0.001,0.002l-5.323,5.323 c0,0-0.001,0.001-0.001,0.001s-0.001,0.001-0.001,0.001l-2.661,2.661c0,0-0.001,0.001-0.001,0.001s-0.001,0.001-0.001,0.001 l-1.43,1.43L7.385,14.975L14.975,7.385z M50.663,43.923l-3.397-3.397l1.249-1.249l3.397,3.397L50.663,43.923z M48,46.586 l-3.397-3.397l1.249-1.249l3.397,3.397L48,46.586z M45.337,49.249l-3.397-3.397l1.249-1.249L46.586,48L45.337,49.249z  M42.674,51.912l-3.397-3.397l1.249-1.249l3.397,3.397L42.674,51.912z M40.081,54.505l-3.075-3.718l0.857-0.857l3.397,3.397 L40.081,54.505z M53.326,41.26l-3.397-3.397l0.857-0.857l3.718,3.075L53.326,41.26z" />
  </svg>
);
export default OintmentsIcon;
