const LeftExtandIcon = (props: any) => (
  <svg
    width={14}
    height={24}
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.87263 12.6278C3.67318 12.4315 3.647 12.2173 3.647 12.0415C3.647 11.8675 3.67318 11.5774 3.87263 11.3811C6.30529 8.8243 12.7226 3.52062 13.0722 3.03068C13.5896 2.30424 13.2673 1.30741 12.6802 0.729113C12.1018 0.157961 11.696 0 11.2635 0C10.9687 0 1.95336 8.2585 1.06706 9.09203C0.215032 9.89164 0 10.1522 0 11.5203L0 12.5448C0 13.9102 0.215032 14.1075 1.06643 14.9071C1.95398 15.7415 10.9699 24 11.2647 24C11.6985 24 12.103 23.842 12.6814 23.2709C13.2686 22.6926 13.5921 21.6958 13.0735 20.9693C12.7238 20.4794 6.30654 15.1837 3.8745 12.6269L3.87263 12.6278Z"
      fill="black"
    />
  </svg>
);
export default LeftExtandIcon;
