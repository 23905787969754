import React from "react";
import { Box } from "@mui/material";
import { DisorderListProps } from "../../../types/appointments";

const DisorderList: React.FC<DisorderListProps> = ({
  disorders,
  selectedDisorder,
  onSelectDisorder,
}) => {
  return (
    <>
      {disorders.map((disorder, index) => (
        <Box
          key={index}
          onClick={() => onSelectDisorder(disorder)}
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "10px",
            cursor: "pointer",
            width: "100%",
            height: "60px",
            paddingLeft: "30px",
            marginBottom: "23px",
            fontSize: "20px",
            fontFamily: "Inter",
            fontWeight:
              disorder.disorderName === selectedDisorder.disorderName
                ? 600
                : 400,
            color: "var(--neuro-black-text)",
            backgroundColor:
              disorder.disorderName === selectedDisorder.disorderName
                ? "var(--neuro-light-grey_border)"
                : "var(--neuro-white-text)",
            "&:hover": {
              backgroundColor: "var(--neuro-light-grey_border)",
            },
            "&:last-child": {
              marginBottom: "0px",
            },
          }}
        >
          {disorder.disorderName}
        </Box>
      ))}
    </>
  );
};

export default DisorderList;
