import React, { useEffect, useState } from "react";
import { fetchForms } from "../../services/formService";
import { AllFormsListProps } from "../../types/patients";
import AllForms from "./AllForms";

const AllFormsList: React.FC<AllFormsListProps> = ({
  setSelectedForm,
  selectedForm,
}) => {
  // props & state values
  const [formsList, setFormsList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // callbacks & functions
  const loadForms = async () => {
    try {
      const response = await fetchForms();
      setFormsList(response?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadForms();
  }, []);

  return (
    <AllForms
      formsList={formsList}
      loading={loading}
      selectedForm={selectedForm}
      setSelectedForm={setSelectedForm}
    />
  );
};

export default AllFormsList;
