import * as Yup from "yup";

export const referralValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  // lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email format").nullable(),
  mobile: Yup.string().required("Mobile number is required"),
  dateOfBirth: Yup.date().nullable(),
  gender: Yup.string().required("Gender is required"),
  streetAddress: Yup.string().nullable(),
  height: Yup.string().nullable(),
  weight: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zipCode: Yup.string().nullable(),
  country: Yup.string().nullable(),
  symptoms: Yup.string().nullable(),
  urgencyOfReferral: Yup.string().nullable(),
  reasonForReferral: Yup.string().nullable(),
  currentMedications: Yup.string().nullable(),
  allergies: Yup.string().nullable(),
  relevantMedicalConditions: Yup.string().nullable(),
  previousTreatments: Yup.string().nullable(),
  referralDoctorFirstName: Yup.string().nullable(),
  referralDoctorLastName: Yup.string().nullable(),
  referralDoctorMobile: Yup.string().nullable(),
  referralDoctorEmail: Yup.string().nullable(),
  notes: Yup.string().nullable(),
});
