import React, { useCallback, useState } from "react";
import { deleteHospital } from "../../services/hospitalService";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditHospitalModal from "./AddEditHospitalModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Hospital } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { HospitalsListProps } from "../../types/hospitals";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import useAppStore from "../../store/appStore";
import { TableWrapper } from "../shared/TableWrapper";
import CardTableToggle from "../shared/CardTableToggle";
import HospitalsCard from "./HospitalsCard";
import useAuthStore from "../../store/authStore";
import { isClinicAdmin } from "../../utils/auth";
import ComponentFilter from "../shared/ComponentFilter";
import { debounce } from "lodash";
// import config from "../../config";

const HospitalsList: React.FC<HospitalsListProps> = ({
  hospitals,
  loading,
  loadHospitals,
}) => {
  // props & state values
  console.log("hospitals", hospitals);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteHospitalId, setDeleteHospitalId] = useState<string | null>(null);
  const [editHospital, setEditHospital] = useState<Hospital | null>(null);

  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("hospitalsView") || "table"
  );

  const { userRole } = useAuthStore();

  const { setFilter, clinicsFilter } = useAppStore();

  // callbacks & functions
  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    loadHospitals(filter.customStartDate, filter.customEndDate, value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string, filter: any) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, filter);
      // }
      handleInputChange(value, filter);
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("clinicsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, clinicsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all appointments
    //   handleInputChange("", clinicsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, clinicsFilter);
    // }
  };
  // search filter handlers end

  const handleAddNew = () => {
    setEditHospital(null);
    setDialogOpen(true);
  };

  const handleEdit = (hospital: Hospital) => {
    setEditHospital(hospital);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteHospitalId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteHospitalId) {
      try {
        await deleteHospital(deleteHospitalId);
        loadHospitals();
      } catch (error) {
        console.error("Error deleting hospital:", error);
      }
    }
    setDeleteHospitalId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadHospitals();
  };

  const fullHospitalAddess = (city: any, state: any, country: any): string => {
    // `${hospital.city} ${hospital.state} ${hospital.country}`
    let address = "";

    if (city && city !== "") {
      address = `${city},`;
    }

    if (state && state !== "") {
      address = `${address} ${state},`;
    }

    if (country && country !== "") {
      address = `${address} ${country}`;
    }

    return address || "";
  };

  const showContactNumber = (number: string | null | undefined) => {
    if (!number || number === "") {
      return "-";
    } else {
      if (number.includes("+")) {
        return number;
      } else {
        return `+91${number}`;
      }
    }
  };

  const updateClinicsRangeFilter = (value: string) => {
    setFilter("clinicsFilter", {
      range: value,
    });
  };

  const updateClinicsCustomDateFilter = (start: string, end: string) => {
    setFilter("clinicsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  // table columns
  const columns = [
    {
      id: "name",
      label: "Clinic Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.name || "";
        const bName = b?.name || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: Hospital) =>
        `${capitalizeFirstLetter(row?.name || "") || "-"}`,
    },
    {
      id: "address",
      label: "Address",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aAddress = a?.address || "";
        const bAddress = b?.address || "";

        // Move empty or null values to the end
        if (aAddress === "") return 1;
        if (bAddress === "") return -1;

        // Normal locale comparison for non-empty values
        return aAddress.localeCompare(bAddress);
      },
      render: (_: any, row: Hospital) =>
        `${fullHospitalAddess(row?.city, row?.state, row?.country) || "-"}`,
    },
    {
      id: "contactNumber",
      label: "Contact Number",
      render: (value: string) => `${showContactNumber(value || "") || "-"}`,
      sortable: true,
    },
    {
      id: "email",
      label: "Email",
      render: (value: string) => value || "-",
      sortable: true,
    },
    {
      id: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (value: string) => getFormattedDate(value || "") || "-",
    },
    {
      id: "actions",
      label: "Actions",
      render: (_: any, row: Hospital) => (
        <Box display="flex" justifyContent="space-around">
          <IconButton
            onClick={() => handleEdit(row)}
            color="secondary"
            disableRipple
            sx={{
              p: 0,
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row?.id)}
            color="primary"
            disableRipple
            sx={{
              p: 0,
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("hospitalsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <ComponentHeader title="Clinic List" />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            {!isClinicAdmin(userRole) && (
              <Button
                variant="outlined"
                color="secondary"
                className="outlined-secondary-button"
                startIcon={<AddIcon />}
                onClick={handleAddNew}
                sx={{
                  height: "2.25rem",
                }}
              >
                Add New
              </Button>
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: "1rem" }}>
          <ComponentFilter
            updateRange={updateClinicsRangeFilter}
            updateDates={updateClinicsCustomDateFilter}
            range={clinicsFilter.range}
            isEmpty
            viewComponent={
              <CardTableToggle view={view} setView={handleToggleView} />
            }
            hideStatusFilter
            handleStoreSearchValueChange={handleStoreSearchValueChange}
            searchValue={clinicsFilter.searchValue || ""}
            searchFilterPlaceholder="Name, Contact, Mobile, Email"
            searchFilterWidth="17.3rem"
          />
        </Box>

        {loading ? (
          <Box
            sx={{
              height: "calc(100% - 8rem)",
            }}
          >
            <LoadingScreen />
          </Box>
        ) : hospitals && hospitals.length > 0 ? (
          view === "card" ? (
            <Grid
              container
              spacing={2}
              sx={{
                maxHeight: "calc(100% - 7rem)",
                overflow: "auto",
              }}
            >
              {hospitals?.map((hospital) => (
                <Grid item xs={12} key={hospital.id}>
                  <HospitalsCard
                    hospital={hospital}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableWrapper
              columns={columns}
              rows={hospitals}
              stickyHeader={true}
              maxHeight="calc(100% - 8rem)"
            />
          )
        ) : (
          <Box
            sx={{
              height: "calc(100% - 8rem)",
            }}
          >
            <ContentCenter>
              <Typography className="no-data">No clinics found</Typography>
            </ContentCenter>
          </Box>
        )}

        <AddEditHospitalModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          hospital={editHospital}
          loadHospitals={loadHospitals}
        />
        <ConfirmDialog
          open={confirmOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this hospital?"
          onClose={handleConfirmClose}
        />
      </Box>
    </>
  );
};

export default HospitalsList;
