const CareNavigatorIcon = (props: any) => {
  const { color = "#000000" } = props;
  return (
    <svg
      fill={color}
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30px"
      height="30px"
      viewBox="0 0 97.301 97.302"
      xmlSpace="preserve"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M51.583,45.473c-10.849,2.372-16.271-2.68-16.553-2.95c-1.37-1.367-3.587-1.379-4.97-0.021 c-1.393,1.366-1.414,3.604-0.048,4.996c0.271,0.276,5.706,5.671,16.019,5.671c2.144,0,4.499-0.231,7.062-0.793 c1.906-0.417,3.112-2.302,2.695-4.206C55.372,46.263,53.488,45.058,51.583,45.473z" />
            <circle cx={19.542} cy={36.266} r={7.948} />
            <path d="M93.032,68.812l-14.885-6.012c-0.516-0.207-1.066-0.314-1.621-0.314h-3.793v-0.824c0-0.422-0.109-0.814-0.291-1.165 l14.032,3.829c0.371,0.101,0.744,0.15,1.111,0.15c1.85,0,3.545-1.23,4.056-3.102c0.612-2.242-0.709-4.557-2.951-5.167 l-18.484-5.046c-0.367-0.1-0.728-0.1-1.13-0.098l-12.601,0.012c-0.587,1.387-1.812,2.48-3.391,2.826 c-2.079,0.455-4.195,0.686-6.292,0.686c-10.229,0-17.198-5.469-17.489-5.702c-1.002-0.799-1.634-1.941-1.777-3.215 c-0.145-1.275,0.216-2.53,1.017-3.534c0.916-1.15,2.286-1.809,3.757-1.809c1.074,0,2.131,0.367,2.976,1.034 c0.142,0.11,2.877,2.199,7.226,3.151l-7.761-5.004c-2.736-1.766-6.12-0.547-8.106,1.332c0,0-0.664,0.567-1.13,1.326 c-0.446,0.726-0.794,1.631-0.794,1.631L24.7,43.819c-0.899,2.602-0.61,6.175,2.136,7.947l14.744,9.508 c0.175,0.113,0.354,0.215,0.532,0.305c0,0.027-0.008,0.055-0.008,0.082v0.824h-4.193l-19.614-13.61 c0.097-0.106,0.19-0.22,0.27-0.347c0.72-1.15,0.371-2.667-0.78-3.389l-6.677-4.182c-1.121-0.703-2.584-0.384-3.327,0.698 c-1.888-0.923-4.214-0.372-5.449,1.407c-1.363,1.964-0.876,4.662,1.089,6.025l30.665,21.285c0.724,0.504,1.586,0.772,2.468,0.772 h5.717c0.364,0.981,1.302,1.684,2.407,1.684h5.439V93.15H47.47c-1.146,0-2.074,0.93-2.074,2.076s0.929,2.075,2.074,2.075h20.232 c1.146,0,2.075-0.929,2.075-2.075s-0.93-2.076-2.075-2.076h-2.983V72.828h5.438c1.106,0,2.043-0.702,2.408-1.684h3.119 l14.105,5.697c0.53,0.215,1.079,0.316,1.618,0.316c1.716,0,3.336-1.024,4.017-2.709C96.32,72.23,95.25,69.708,93.032,68.812z" />
            <path d="M37.083,37.846c7.871,0,12.233-2.105,15.077-4.597v5.456c-0.254,1.096-0.72,3.132-1.27,5.574 c0.211-0.042,0.419-0.076,0.63-0.122c0.342-0.075,0.688-0.112,1.033-0.112c2.246,0,4.227,1.594,4.708,3.791 c0.14,0.643,0.144,1.283,0.032,1.893l11.989-0.06c0.527,0,0.989,0.062,1.475,0.192l1.756,0.48 c-0.998-4.874-2.133-9.672-2.601-11.613v-6.485c1.287,2.681,2.091,6.522,2.009,12.41c-0.026,1.858,1.46,3.387,3.318,3.413 c0.018,0,0.032,0,0.049,0c1.837,0,3.34-1.476,3.363-3.319c0.225-16.004-7.793-23.512-15.5-26.364 c-0.008-0.002-0.016-0.002-0.021-0.004l-2.097,2.199l-2.159-2.175c-1.202,0.295-2.378,0.833-3.404,1.565 c-0.029,0.016-2.987,2.183-5.058,5.072c-2.41,3.259-4.491,6.072-13.332,6.072c-1.858,0-3.366,1.508-3.366,3.367 C33.716,36.339,35.224,37.846,37.083,37.846z" />
            <path d="M61.164,17.722c4.487,0,8.128-3.638,8.128-8.127c0-1.877-0.651-3.592-1.725-4.965h-2.628V3.397h1.444 c-0.506-0.425-1.056-0.799-1.654-1.092C64.113,0.949,62.75,0,61.166,0c-1.582,0-2.941,0.947-3.562,2.302 c-0.601,0.293-1.152,0.669-1.659,1.095h1.65V4.63H54.76c-1.068,1.373-1.722,3.088-1.722,4.965 C53.04,14.084,56.676,17.722,61.164,17.722z M61.166,1.714c1.219,0,2.208,0.99,2.208,2.208S62.384,6.13,61.166,6.13 c-1.217,0-2.206-0.99-2.206-2.208S59.949,1.714,61.166,1.714z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default CareNavigatorIcon;
