import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import Input from "../ui/Input";

import useAppStore from "../../store/appStore";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { DoctorWorkExperienceFormInputs, doctorWorkExperienceFormValidationSchema } from "../../validations/doctorValidation";
import { addDoctorWorkExperience, updateDoctorWorkExperience } from "../../services/doctorService";
import {
    erroMessageStyles,
    inputLabelStyles,
} from "../../utils/styles";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "../ui/Checkbox";
import { capitalizeFirstLetter } from "../../utils/common";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";

const DoctorWorkExperienceForm = ({
    onClose,
    handleAfterChanges,
    id = "",
    selectedExperience = null,
}: {
    onClose: any;
    id?: string;
    selectedExperience?: any;
    handleAfterChanges?: any
}) => {
    // props & state values
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        control,
        watch,
    } = useForm<DoctorWorkExperienceFormInputs>({
        resolver: yupResolver(doctorWorkExperienceFormValidationSchema),
        defaultValues: {
            clinicName: "",
            location: "",
            clinicDetails: undefined,
            designation: "",
            specialization: "",
            startDate: selectedExperience ? selectedExperience?.startDate
                ? DateTime.fromISO(selectedExperience?.startDate).toJSDate()
                : undefined : undefined,
            endDate: selectedExperience ? selectedExperience?.endDate
                ? DateTime.fromISO(selectedExperience?.endDate).toJSDate()
                : undefined : undefined,
            isCurrent: false,
        },
    });

    const { showSnackbar, selectedDoctorInList } = useAppStore();

    const isCurrentChecked = watch("isCurrent");


    const breadcrumbItems = [
        { label: "Doctors", href: "/doctors" },
        {
            label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}`,
        },
        { label: "Work Experience" },
    ];

    const newBreadcrumbItem =
        !selectedExperience
            ? { label: "New" }
            : null;

    const updatedBreadcrumbItems = [
        ...breadcrumbItems.slice(0, 2), // get the first 2 items
        newBreadcrumbItem, // add the new item
        ...breadcrumbItems.slice(2), // get the rest of the items
    ].filter((item) => item !== null);

    // callbacks & functions
    useEffect(() => {
        if (selectedExperience) {
            console.log("selectedExperience in form", selectedExperience);
            const startDate: Date | undefined = selectedExperience?.startDate
                ? DateTime.fromISO(selectedExperience?.startDate).toJSDate()
                : undefined;
            const endDate: Date | undefined = selectedExperience?.endDate
                ? DateTime.fromISO(selectedExperience?.endDate).toJSDate()
                : undefined;
            const defaultValues = {
                clinicName: selectedExperience?.clinicName || "",
                location: selectedExperience?.location || "",
                clinicDetails: selectedExperience?.clinicDetails || "",
                designation: selectedExperience?.designation || "",
                specialization: selectedExperience?.specialization || "",
                startDate: startDate,
                endDate: endDate,
                isCurrent: selectedExperience?.isCurrent || false,
            };
            reset(defaultValues);
        }
    }, [selectedExperience, reset]);

    const onSubmit: SubmitHandler<DoctorWorkExperienceFormInputs> = async (
        values
    ) => {
        console.log("values on submit work experience", values);
        const data = {
            doctorId: id,
            ...values,
        };

        try {
            if (selectedExperience) {
                console.log("selectedExperience edit", selectedExperience);
                try {
                    await updateDoctorWorkExperience(selectedExperience?.id, data);
                    showSnackbar("Work Experience Updated Successfully", "success");
                    if (handleAfterChanges) {
                        handleAfterChanges();
                    }
                } catch (error: any) {
                    showSnackbar(`${error.response?.data.message}`, "error");
                    return;
                }
            } else {
                try {
                    await addDoctorWorkExperience(data);
                    showSnackbar("Work Experience Added Successfully", "success");
                    if (handleAfterChanges) {
                        handleAfterChanges();
                    }
                } catch (error: any) {
                    showSnackbar(`${error.response?.data.message}`, "error");
                    return;
                }
            }
        } catch (error) {
            const message = selectedExperience
                ? "Could not update work experiecne, please try again"
                : "Could not add work experience, please try again";
            showSnackbar(message, "error");
            console.error("Error saving hospital:", error);
        }
    };

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1.25rem",
                        alignItems: "center",
                    }}
                >
                    <CustomBreadcrumbs items={updatedBreadcrumbItems} />
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1.25rem",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="outlined-secondary-button"
                            onClick={onClose}
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            startIcon={<SaveOutlinedIcon />}
                            variant="contained"
                            className="success-button"
                            type="submit"
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: "1.75rem",
                        backgroundColor: "var(--neuro-white-text)",
                        borderRadius: "0.625rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                        maxHeight: "calc(100% - 3.25rem)",
                        overflow: "auto",
                    }}
                >
                    <Grid container spacing={2.5} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="Hospital *"
                                {...register("clinicName")}
                                error={!!errors.clinicName}
                                helperText={errors.clinicName?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Input
                                type="text"
                                className="input-primary"
                                label="Location"
                                {...register("location")}
                                error={!!errors.location}
                                helperText={errors.location?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="Designation *"
                                {...register("designation")}
                                error={!!errors.designation}
                                helperText={errors.designation?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="Specialization *"
                                {...register("specialization")}
                                error={!!errors.specialization}
                                helperText={errors.specialization?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.375rem",
                                    width: "100%",
                                }}
                            >
                                <Typography sx={inputLabelStyles}>Start Date</Typography>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    defaultValue={
                                        selectedExperience ? selectedExperience?.startDate
                                            ? DateTime.fromISO(selectedExperience?.startDate).toJSDate()
                                            : undefined : undefined
                                    }
                                    render={({ field, fieldState: { error } }) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.375rem",
                                                width: "100%",
                                            }}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                                <DatePicker
                                                    value={
                                                        field.value
                                                            ? DateTime.fromJSDate(field.value)
                                                            : null
                                                    }
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                    }}
                                                    format="dd/MM/yyyy"
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            placeholder: "From (DD/MM/YYYY)",
                                                            sx: {
                                                                "& .MuiOutlinedInput-root": {
                                                                    height: "2.5rem",
                                                                    borderRadius: "0.625rem",
                                                                    backgroundColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                    "& >fieldset": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >fieldset:hover": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >fieldset:active": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >fieldset:focus": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                        border:
                                                                            "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                        border:
                                                                            "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiFormControl-root": {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            {error && (
                                                <Typography
                                                    sx={erroMessageStyles}
                                                >
                                                    {error.message}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                />
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            {isCurrentChecked ?
                                <Input
                                    type="text"
                                    className="input-primary"
                                    placeholder="Present"
                                    label=""
                                    fullWidth
                                    disabled
                                />
                                :
                                <Controller
                                    name="endDate"
                                    control={control}
                                    defaultValue={
                                        selectedExperience ? selectedExperience?.endDate
                                            ? DateTime.fromISO(selectedExperience?.endDate).toJSDate()
                                            :
                                            undefined : undefined
                                    }
                                    render={({ field, fieldState: { error } }) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.375rem",
                                                width: "100%",
                                            }}
                                        >
                                            <Typography sx={inputLabelStyles}>End Date</Typography>

                                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                                <DatePicker
                                                    value={
                                                        field.value
                                                            ? DateTime.fromJSDate(field.value)
                                                            : null
                                                    }
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                    }}
                                                    format="dd/MM/yyyy"
                                                    slotProps={{
                                                        textField: {
                                                            placeholder: "To (DD/MM/YYYY)",
                                                            fullWidth: true,
                                                            sx: {
                                                                "& .MuiOutlinedInput-root": {
                                                                    height: "2.5rem",
                                                                    borderRadius: "0.625rem",
                                                                    backgroundColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                    "& >fieldset": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >fieldset:hover": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >fieldset:active": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >fieldset:focus": {
                                                                        borderColor:
                                                                            "var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                        border:
                                                                            "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                    "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                        border:
                                                                            "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    sx={{
                                                        "& .MuiFormControl-root": {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            {error && (
                                                <Typography
                                                    sx={erroMessageStyles}
                                                >
                                                    {error.message}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                />}

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Input
                                type="text"
                                label="Clinic/Hospital Details"
                                className="input-secondary-textarea"
                                rows={3}
                                multiline
                                sx={{
                                    "& textarea": {
                                        minHeight: "30px",
                                    },
                                }}
                                {...register("clinicDetails")}
                                error={!!errors.clinicDetails}
                                helperText={errors.clinicDetails?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Checkbox
                                label="I am currently working here."
                                error={!!errors.isCurrent}
                                helperText={errors?.isCurrent?.message}
                                defaultChecked={
                                    selectedExperience ? selectedExperience?.isCurrent || false : false
                                }
                                {...register("isCurrent")}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box >
    );
};

export default DoctorWorkExperienceForm;
