import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Logo from "../shared/Logo";
import Badge from "../ui/Badge";
import config from "../../config";
import HelpSupportModal from "../shared/HelpSupportModal";
import AssistantIcon from "../svg/AssistantIcon";

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authLayouStyles = {
    welcomeText: {
      fontWeight: "var(--neuro-font-weight-bold)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
      color: "var(--neuro-bg-darkblue-primary)",
      fontSize: "var(--neuro-font-size-large-medium)",
      lineHeight: "120%",
    },
  };

  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelowLaptop = useMediaQuery("(max-width:85.375rem)");

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        maxWidth: "1200px",
        marginInline: "auto",
        "@media (max-width: 85.375rem)": {
          width: "100%",
          maxWidth: "100%" /* Ensure it takes the entire width of the screen */,
          marginInline: 0 /* Remove the auto margin */,
        },
      }}
    >
      {!isMobile && (
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            // borderLeft: "60px solid var(--neuro-button-bg-success)",
            borderLeft: "1.5vw solid var(--neuro-button-bg-success)", // Relative width for scalable border
            position: "relative",
            overflow: "hidden",
          }}
          className="bg-primary h-screen"
        >
          <Badge
            label={config?.VERSION || ""}
            top="4.5rem" // Scalable top offset
            left="-2rem" // Scalable left offset
            width="9.375rem" // Scalable width
          />

          <Box
            sx={{
              paddingInline: isBelowLaptop ? "3.125rem" : "4.8rem", // Scalable padding
              paddingTop: isBelowLaptop ? "2.5rem" : "4.25rem",
              display: "flex",
              flexDirection: "column",
              gap: isBelowLaptop ? "3.125rem" : "6.8rem", // Scalable gap
              height: isBelowLaptop ? "auto" : "100%",
              width: "auto",
            }}
          >
            {/* <Logo width={172} height={122} /> */}
            <Logo width="10.75rem" height="7.625rem" />{" "}
            {/* Scalable logo size */}
            <Box
             className="form-background"
              sx={{
                // padding: "20px",
                padding: "1.25rem", // Scalable padding
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "auto",
                //background: "var(--neuro-white-text)",
               
                borderRadius: "0.625rem",
              }}
            >
              <AssistantIcon />
              <Typography
                sx={{
                  fontSize: "var(--neuro-font-size-large-medium)", // Scalable font size
                  fontWeight: "var(--neuro-font-weight-bold)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  textAlign: "center", // Changed to center for better alignment
                  marginTop: "1.2rem",
                  marginBottom: "1.4rem",
                  color: "var(--neuro-black-text)",
                }}
              >
                Need Assistance ?
              </Typography>
              <HelpSupportModal from="login" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={authLayouStyles.welcomeText}>
                Welcome to
              </Typography>
              <Typography sx={authLayouStyles.welcomeText}>
                22Neuro Clinic
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={7}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="form-background"
      >
        {!isMobile ? (
          <Container maxWidth="sm">{children}</Container>
        ) : (
          <Box sx={{ height: "100%", width: "100%" }}>{children}</Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
