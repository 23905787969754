// import { useEffect, useState } from "react";
// import {
//   Typography,
//   Box,
//   Grid,
//   Select,
//   MenuItem,
//   useMediaQuery,
//   TextField,
// } from "@mui/material";
// import Checkbox from "../../ui/Checkbox";
// import Input from "../../ui/Input";
// import { inputLabelStyles, selectStyles } from "../../../utils/styles";
// import { fetchFormRequestsApiFormid, submitFormResponseApisuryveputcall } from "../../../services/formService";
// import useAppStore from "../../../store/appStore";

// const AddNewFormCompFormid = ({ submitTrigger }: { submitTrigger: boolean }) => {
//   const id  = localStorage.getItem("formid")


//   const [name, setName] = useState<string>("");
//   const [zipCode, setZipCode] = useState<string>("");
//   const [formType, setFormType] = useState<string>("");  // Default value
//   const [filledBy, setFilledBy] = useState<string>("");
//   const [disorderId, setDisorderId] = useState<string>("");
//   const [isScoreBased, setIsScoreBased] = useState<boolean>(false);
//   const [isPublic, setisPublic] = useState<boolean>(false);    
//   const [notifyOnSubmit, setnotifyOnSubmit] = useState<boolean>(false);    
//   const [notificationEmail, setnotificationEmail] = useState<string>(""); 

//   const [status, setStatus] = useState(true);


//   const {
 
//     isMenuCollapsed,
//     setDrawerOpen,
//     setIsMenuCollapsed,
//     showSnackbar,
//     setShowFormSubmenus,
//   } = useAppStore();

//   const isBelow800 = useMediaQuery("(max-width:50rem)");


//   const [previousSidebarCollpased, _] = useState<boolean>(
//     isMenuCollapsed ? true : false
//   );
//   useEffect(() => {
//     setShowFormSubmenus(true);
//     if (isBelow800) {
//       setDrawerOpen(false);
//     } else {
//       setIsMenuCollapsed(true);
//     }

//     return () => {
//       setIsMenuCollapsed(previousSidebarCollpased);
//       setDrawerOpen(!previousSidebarCollpased);
//     };
//   }, []);
//   // Fetch initial data
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetchFormRequestsApiFormid(id || '');
//         if (response?.data) {
//           setName(response.data.data.name);
//           setZipCode(response.data.data.description);
//           setFormType(response.data.data.type);
//           setFilledBy(response.data.data.filledBy);
//           setDisorderId(response.data.data.disorderId);
//           setIsScoreBased(response.data.data.isScoreBased);
//           setStatus(response.data.data.status);
//           setisPublic(response.data.data.isPublic);
//           setnotifyOnSubmit(response.data.data.notifyOnSubmit);
//           setnotificationEmail(response.data.data.notificationEmail);
//         }
//       } catch (error) {
//         console.error("Error fetching form data:", error);
//       }
//     };

//     fetchData();
//   }, [id]);



//   const onSubmit = async () => {

 
//     const formData = {
//       name,
//       description:zipCode,
//       type:formType,
//       filledBy,
//       disorderId,
//       isScoreBased,
//       status,
//       isPublic,
//       notifyOnSubmit,
//       notificationEmail,
//     };

//     try {
//       const response = await submitFormResponseApisuryveputcall(id || '', formData);
//       console.log("response", response.data.data);
//        localStorage.setItem("isScoreBased" , response.data.data.isScoreBased)
//        window.dispatchEvent(new Event("storage"));
//        showSnackbar("Form details saved successfully", "success");
//       // navigate("/forms");
//     } catch (error) {
//       console.error("Error submitting form", error);
//       showSnackbar("Error submitting form" , "error")
//     }
//   };
  
 
//   useEffect(() => {
//     if (submitTrigger) {
//     if(id !== ""){
//         onSubmit()
//     }
     
//     }
//   }, [submitTrigger]);

//   return (
//     <Box sx={{ backgroundColor: "var(--neuro-bg-light-grey-secondary)", height: "100%", padding: "1rem" }}>
//     <Box sx={{ display: "flex", flexDirection: "column", gap: "0.688rem", height: "100%", width: "100%" }}>
//       <Box component="form" 
//            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }} 
//            onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
        
//         <Box sx={{
//             p: "1rem",
//             backgroundColor: "var(--neuro-white-text)",
//             borderRadius: "0.625rem",
//             width: '599px',
//             height: '432px',
//         }}>
//           <Grid container spacing={3.5}>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Input
//                   type="text"
//                   className="input-primary"
//                   label="Form Name *"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                 />
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography sx={inputLabelStyles}>Form Type *</Typography>
//                 <Select
//                   value={formType}
//                   onChange={(e) => setFormType(e.target.value)}
//                   fullWidth
//                   sx={selectStyles.withGreyBackground}
//                 >
//                   <MenuItem value="GENERIC">GENERIC</MenuItem>
//                   <MenuItem value="SPECIFIC">SPECIFIC</MenuItem>
//                   <MenuItem value="COMMON">COMMON</MenuItem>
//                 </Select>
//               </Box>
//             </Grid>
//           </Grid>
  
//           <Grid container spacing={3.5}>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography sx={inputLabelStyles}>Form filled By *</Typography>
//                 <Select
//                   value={filledBy}
//                   onChange={(e) => setFilledBy(e.target.value)}
//                   fullWidth
//                   sx={selectStyles.withGreyBackground}
//                 >
//                   <MenuItem value="DOCTOR">Doctor</MenuItem>
//                   <MenuItem value="PATIENT">Patient</MenuItem>
//                   <MenuItem value="BOTH">Both</MenuItem>
//                 </Select>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography sx={inputLabelStyles}>Disorder *</Typography>
//                 <Select
//                   value={disorderId}
//                   onChange={(e) => setDisorderId(e.target.value)}
//                   fullWidth
//                   sx={selectStyles.withGreyBackground}
//                 >
//                   <MenuItem value="cm06i4jj2001842domsdtgro8">Migraine</MenuItem>
//                   <MenuItem value="cm06i4jj2001842domrfff">Headache</MenuItem>
//                 </Select> 
//               </Box>
//             </Grid>
//           </Grid>
  
//           <Grid container spacing={3.5}>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Typography sx={inputLabelStyles}>Notify On Submit </Typography>
//                 <Select
//                   value={notifyOnSubmit}
//                   onChange={(e) => setnotifyOnSubmit(e.target.value)}
//                   fullWidth
//                   sx={selectStyles.withGreyBackground}
//                 >
//                   <MenuItem value="true">ture</MenuItem>
//                   <MenuItem value="false">false</MenuItem>
          
//                 </Select>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Input
//                   type="text"
//                   className="input-primary"
//                   label="Notification Email *"
//                   value={notificationEmail}
//                   onChange={(e) => setnotificationEmail(e.target.value)}
//                 />
//               </Box>
//             </Grid>
//           </Grid>
//           <Grid container spacing={3.5}>
//             <Grid item xs={12} md={6}>
//               {/* <Box sx={{ mt: 2 }}>
//                 <Input
//                   type="text"
//                   label="Form Description *"
//                   value={zipCode}
//                   onChange={(e) => setZipCode(e.target.value)}
//                   className="input-secondary-textarea"
//                   rows={8}
//                 />
//               </Box> */}
//               <Box sx={{ mt: 2 }}>
//   <TextField
//     label="Form Description *"
//     value={zipCode}
//     onChange={(e) => setZipCode(e.target.value)}
//     multiline
//     rows={5} // Specifies the number of visible lines
//     fullWidth
//     variant="outlined" // Optional: Choose variant (outlined, filled, standard)
//     className="input-secondary-textarea" // Retains your custom styling
//   />
// </Box>

//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box sx={{ mt: 2 }}>
//                 <Checkbox
//                   checked={isScoreBased}
//                   onChange={(e) => setIsScoreBased(e.target.checked)}
//                   label="Is Score Based?"
//                 />

// <Checkbox
//                   checked={isPublic}
//                   onChange={(e) => setisPublic(e.target.checked)}
//                   label="Is Public?"
//                 />
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     </Box>
//   </Box>
  
//   );
// };

// export default AddNewFormCompFormid;



import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  useMediaQuery,
  TextField,
} from "@mui/material";
import Checkbox from "../../ui/Checkbox";
import Input from "../../ui/Input";
import { inputLabelStyles, selectStyles } from "../../../utils/styles";
import { fetchFormRequestsApiFormid, submitFormResponseApisuryveputcall } from "../../../services/formService";
import useAppStore from "../../../store/appStore";

const AddNewFormCompFormid = ({ submitTrigger }: { submitTrigger: boolean }) => {
  const id = localStorage.getItem("formid");

  const [name, setName] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [formType, setFormType] = useState<string>("");
  const [filledBy, setFilledBy] = useState<string>("");
  const [disorderId, setDisorderId] = useState<string>("");
  const [isScoreBased, setIsScoreBased] = useState<boolean>(false);
  const [isPublic, setisPublic] = useState<boolean>(false);
  const [notifyOnSubmit, setnotifyOnSubmit] = useState<boolean>(false);
  const [notificationEmail, setnotificationEmail] = useState<string>("");
  const [status, setStatus] = useState(true);

  // Validation errors state
  const [errors, setErrors] = useState({
    name: "",
    zipCode: "",
    formType: "",
    filledBy: "",
    disorderId: "",
    notificationEmail: "",
  });

  const {
    isMenuCollapsed,
    setDrawerOpen,
    setIsMenuCollapsed,
    showSnackbar,
    setShowFormSubmenus,
  } = useAppStore();

  const isBelow800 = useMediaQuery("(max-width:50rem)");

  const [previousSidebarCollpased] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  useEffect(() => {
    setShowFormSubmenus(true);
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchFormRequestsApiFormid(id || "");
        if (response?.data) {
          setName(response.data.data.name);
          setZipCode(response.data.data.description);
          setFormType(response.data.data.type);
          setFilledBy(response.data.data.filledBy);
          setDisorderId(response.data.data.disorderId);
          setIsScoreBased(response.data.data.isScoreBased);
          setStatus(response.data.data.status);
          setisPublic(response.data.data.isPublic);
          setnotifyOnSubmit(response.data.data.notifyOnSubmit);
          setnotificationEmail(response.data.data.notificationEmail);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchData();
  }, [id]);

  const validateFields = () => {
    const newErrors: typeof errors = {
      name: name.trim() ? "" : "Form Name is required.",
      formType: formType ? "" : "Form Type is required.",
      filledBy: filledBy ? "" : "Form filled By is required.",
      disorderId: disorderId ? "" : "Disorder is required.",
      notificationEmail: notificationEmail.trim() ? "" : "Notification Email is required.",
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const onSubmit = async () => {
    if (!validateFields()) return;

    const formData = {
      name,
      description: zipCode,
      type: formType,
      filledBy,
      disorderId,
      isScoreBased,
      status,
      isPublic,
      notifyOnSubmit,
      notificationEmail,
    };

    try {
      const response = await submitFormResponseApisuryveputcall(id || "", formData);
      console.log("response", response.data.data);
      localStorage.setItem("isScoreBased", response.data.data.isScoreBased);
      window.dispatchEvent(new Event("storage"));
      showSnackbar("Form details saved successfully", "success");
    } catch (error) {
      console.error("Error submitting form", error);
      showSnackbar("Error submitting form", "error");
    }
  };

  useEffect(() => {
    if (submitTrigger) {
      if (id !== "") {
        onSubmit();
      }
    }
  }, [submitTrigger]);

  return (
  <>
  

<Box sx={{ backgroundColor: "var(--neuro-bg-light-grey-secondary)", height: "100%"}}>
<Box sx={{ display: "flex", flexDirection: "column", gap: "0.688rem", height: "100%", width: "100%" }}>
  <Box component="form" 
       sx={{ display: "flex", flexDirection: "column", gap: "1rem" }} 
       onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
    
    <Box sx={{
        p: "1rem",
        backgroundColor: "var(--neuro-white-text)",
        borderRadius: "0.625rem",
        width: '599px',
        height: '432px',
    }}>
      <Grid container spacing={3.5}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Input
              type="text"
              className="input-primary"     
              label="Form Name *"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!errors.name}     
              helperText={errors.name}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Typography sx={inputLabelStyles}>Form Type *</Typography>
            <Select
              value={formType}
              onChange={(e) => setFormType(e.target.value)}
              fullWidth
              sx={selectStyles.withGreyBackground}
              error={!!errors.formType}
              helperText={errors.formType}
            >
              <MenuItem value="GENERIC">GENERIC</MenuItem>
              <MenuItem value="SPECIFIC">SPECIFIC</MenuItem>
              <MenuItem value="COMMON">COMMON</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3.5}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Typography sx={inputLabelStyles}>Form filled By *</Typography>
            <Select
              value={filledBy}
              onChange={(e) => setFilledBy(e.target.value)}
              fullWidth
              sx={selectStyles.withGreyBackground}
              error={!!errors.filledBy}
              helperText={errors.filledBy}
            >
              <MenuItem value="DOCTOR">Doctor</MenuItem>
              <MenuItem value="PATIENT">Patient</MenuItem>
              <MenuItem value="BOTH">Both</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Typography sx={inputLabelStyles}>Disorder *</Typography>
            <Select
              value={disorderId}
              onChange={(e) => setDisorderId(e.target.value)}
              fullWidth
              sx={selectStyles.withGreyBackground}
              error={!!errors.disorderId}
              helperText={errors.disorderId}
            >
              <MenuItem value="cm06i4jj2001842domsdtgro8">Migraine</MenuItem>
              <MenuItem value="cm06i4jj2001842domrfff">Headache</MenuItem>
            </Select> 
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3.5}>
        {/* <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Typography sx={inputLabelStyles}>Notify On Submit </Typography>
            <Select
              value={notifyOnSubmit}
              onChange={(e) => setnotifyOnSubmit(e.target.value)}
              fullWidth
              sx={selectStyles.withGreyBackground}
          
            >
              <MenuItem value="true">ture</MenuItem>
              <MenuItem value="false">false</MenuItem>
      
            </Select>
          </Box>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Input
              type="text"
              className="input-primary"
              label="Notification Email *"
              value={notificationEmail}
              onChange={(e) => setnotificationEmail(e.target.value)}
              error={!!errors.notificationEmail}
              helperText={errors.notificationEmail}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3.5}>
        <Grid item xs={12} md={6}>
          {/* <Box sx={{ mt: 2 }}>
            <Input
              type="text"
              label="Form Description *"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              className="input-secondary-textarea"
              rows={8}
            />
          </Box> */}
          <Box sx={{ mt: 2 }}>
<TextField
label="Form Description"
value={zipCode}
onChange={(e) => setZipCode(e.target.value)}
multiline
rows={5} // Specifies the number of visible lines
fullWidth
variant="outlined" // Optional: Choose variant (outlined, filled, standard)
className="input-secondary-textarea" // Retains your custom styling
// error={!!errors.zipCode}
// helperText={errors.zipCode}
/>
</Box>

        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ mt: 2 }}>
            <Checkbox
              checked={isScoreBased}
              onChange={(e) => setIsScoreBased(e.target.checked)}
              label="Is Score Based?"
            />

<Checkbox
              checked={isPublic}
              onChange={(e) => setisPublic(e.target.checked)}
              label="Is Public?"
            />
            <Checkbox
              checked={notifyOnSubmit}
              onChange={(e) => setnotifyOnSubmit(e.target.checked)}
              label="Notify On Submit"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
</Box>
</Box>
</>
  );
};

export default AddNewFormCompFormid;
   