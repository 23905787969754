import React from "react";
import { LogoProps } from "../../types/common";

const NationalHealthAuthorityLogo: React.FC<LogoProps> = ({
  height,
  width,
  ...props
}) => {
  return (
    <img
      src="/National-Health-Authority.png"
      alt="National-Health-Authority"
      style={{
        width: width ? width : "initial",
        height: height ? height : "initial",
        objectFit: "cover",
      }}
      {...props}
    />
  );
};

export default NationalHealthAuthorityLogo;
