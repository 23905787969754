import { Box, Typography } from "@mui/material";
import useAuthStore from "../../store/authStore";
import PatientDashboard from "./PatientDashboard";
import DoctorDashboard from "./DoctorDashboard";
// import AdminDashboard from "./AdminDashboard";
import NetworkDashboard from "./NetworkDashboard";
import useAppStore from "../../store/appStore";
import {
  isClinicAdminOrAdmin,
  isDoctor,
  isNetwork,
  isPatient,
} from "../../utils/auth";
import AdminDashboardcopy from "./AdminDashboardcopy";
import ComponentFilter from "../shared/ComponentFilter";

const PatientMonitor = () => {
  // props & state value
  const { user, userRole } = useAuthStore();
  const { dashboardFilter, setFilter } = useAppStore();

  // callbacks & functions
  const updateDashboardRangeFilter = (value: string) => {
    setFilter("dashboardFilter", {
      range: value,
    });
  };

  const updateDashboardCustomDateFilter = (start: string, end: string) => {
    setFilter("dashboardFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        // height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      {isPatient(userRole) && (
        <Typography sx={{ mb: "1rem" }} className="page-heading">
          Welcome, {user?.name}!
        </Typography>
      )}

      {!isPatient(userRole) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography className="page-heading">
            Welcome, {user?.name}!
          </Typography>
        </Box>
      )}

      {!isPatient(userRole) && (
        <Box sx={{ marginBottom: "1rem" }}>
          <ComponentFilter
            updateRange={updateDashboardRangeFilter}
            updateDates={updateDashboardCustomDateFilter}
            range={dashboardFilter.range}
            hideStatusFilter
            hideSearchFilter
          />
        </Box>
      )}

      {isPatient(userRole) && <PatientDashboard />}
      {isDoctor(userRole) && <DoctorDashboard range={dashboardFilter.range} />}
      {/* {isClinicAdminOrAdmin(userRole) && (
        <AdminDashboard range={dashboardFilter.range} />
      )} */}
      {isClinicAdminOrAdmin(userRole) && (
        <AdminDashboardcopy range={dashboardFilter.range} />
      )}
      {isNetwork(userRole) && (
        <NetworkDashboard range={dashboardFilter.range} />
      )}
    </Box>
  );
};

export default PatientMonitor;
