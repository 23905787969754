import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { RoleBasedRouteProps } from "../../types/auth";

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
  element,
  allowedRoles,
}) => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuthStore();

  if (!isAuthenticated || !user) {
    return <Navigate to="/" state={{ from: location }} />;
  } else if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/not-authorized" state={{ from: location }} />;
  }

  return element;
};

export default RoleBasedRoute;
