import React from "react";
import Button from "../../ui/Button";
import { EditMeetingInfoButtonProps } from "../../../types/appointments";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// import Drawer from "../../shared/Drawer";
// import Meet from "../Meet/Meet";
import { useNavigate } from "react-router-dom";

const EditMeetingInfoButton: React.FC<EditMeetingInfoButtonProps> = ({
  id,
  // loadAppointmentDetails,
  appointmentMode
}) => {
  // props & state values
  // const [showMeetingEditDrawer, setShowMeetingEditDrawer] =
  //   useState<boolean>(false);

  const navigate = useNavigate();

  // callbacks & functions
  // const closeButtonHandler = () => {
  //   if (loadAppointmentDetails) {
  //     loadAppointmentDetails(id);
  //   }
  //   setShowMeetingEditDrawer(false);
  // };

  return (
    <>
      <Button
        startIcon={<ModeEditOutlineOutlinedIcon />}
        color="primary"
        variant="contained"
        className="primary-button"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          // setShowMeetingEditDrawer((prev) => !prev);
          localStorage.setItem('appointmentModeForMeet', appointmentMode);
          localStorage.setItem('hideConference', "true");
          localStorage.setItem('hideGeneralDetails', "true");
          localStorage.setItem('hidePatientHeader', "true");
          localStorage.setItem('showCloseButton', "true");
          localStorage.setItem('toMeetFrom', "edit-meeting-info");
          navigate(`/meet/${id}/doctor-notes`);
        }}
        sx={{
          height: "2.25rem",
        }}
      >
        Edit Meeting Info
      </Button>
      {/* <Drawer
        open={showMeetingEditDrawer}
        onClose={closeButtonHandler}
        headerText="Edit Meeting Info"
      >
        <Meet
          id={id}
          hideConference
          hideGeneralDetails
          hidePatientHeader
          showCloseButton
          closeButtonHandler={closeButtonHandler}
          height="100%"
        />
      </Drawer> */}
    </>
  );
};

export default EditMeetingInfoButton;
