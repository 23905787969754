import { Box, Typography } from "@mui/material";
import Alert from "../ui/Alert";

const AlertContent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1.25rem",
      }}
    >
      {/* <Typography
        sx={{
          textAlign: "center",
          fontWeight: "var(--neuro-font-weight-bold)",
          marginBottom: "1rem",
        }}
      >
        Welcome to 22Neuro Clinic!
      </Typography> */}

      <Typography
        sx={{
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        Thank you for registering with us. Our team is here to assist you in
        booking your first appointment. You will receive a follow-up from our
        help desk team shortly to guide you through the booking process.
        <br />
        If you need immediate assistance, please feel free to use the{" "}
        <span className="bold-text">"Need Assistance"</span> button in the side
        menu.
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
        }}
      >
        We appreciate your patience and look forward to providing you with
        excellent care!
      </Typography>
    </Box>
  );
};

const PatientDashboardAlert = () => {
  return (
    <Box
      sx={{
        border: "0.063rem solid var(--neuro-secondary_border)",
        borderRadius: "0.625rem",
        // padding: "0.625rem",
        height: "100%",
      }}
    >
      <Alert
        message={<AlertContent />}
        type="info"
        sx={{
          // height: "4.375rem",
          display: "flex",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            fontSize: "3.125rem",
          },
        }}
      />
    </Box>
  );
};

export default PatientDashboardAlert;
