import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { getPrescriptions } from "../../services/prescriptionService";
import PrescriptionsList from "./PrescriptionsList";
import useAuthStore from "../../store/authStore";
import { PrescriptionsPropsTypes } from "../../types/prescriptions";

const Prescriptions: React.FC<PrescriptionsPropsTypes> = ({ patientId, stateUpdaterForParent, countUpdaterForParent }) => {
    // props & state values
    const [prescriptions, setPrescriptions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const { user } = useAuthStore();

    // callbacks & functions
    const loadPrescriptions = async (id: string) => {
        try {
            setLoading(true);
            const response = await getPrescriptions(id || "");

            setPrescriptions(response?.data || []);
            setLoading(false);
            if (countUpdaterForParent) {
                countUpdaterForParent(response?.data?.length || 0);
            }
        } catch (error) {
            console.error("Error fetching hospitals:", error);
            setPrescriptions([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (patientId) {
            loadPrescriptions(patientId || "");
        }
        else {
            loadPrescriptions(user?.patientId || "");
        }
    }, [patientId]);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    height: "100%",
                    paddingBlock: patientId ? 0 : "1rem",
                    paddingInline: patientId ? 0 : "0.875rem",
                    overflow: "auto",
                }}
            >
                <PrescriptionsList patientId={patientId} loading={loading} prescriptions={prescriptions} stateUpdaterForParent={stateUpdaterForParent} />
            </Box>
        </>
    );
};

export default Prescriptions;
