import React, { ReactNode } from "react";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NewDoctorsCardProps {
  count: number;
  icon: React.ReactElement | ReactNode;
  text: string;
  total: number;
  navigateTo: string;
  action: any;
}

const DashboardCard: React.FC<NewDoctorsCardProps> = ({
  count,
  text,
  icon,
  total,
  navigateTo,
  action,
}) => {
  // props & state values
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
        backgroundColor: "var(--neuro-white-text)",
        padding: "1.813rem 2.5rem 1.5rem 2rem",
        display: "flex",
        gap: "1.25rem",
        alignItems: "center",
        height: "9.375rem",
        cursor: "pointer",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.05)", // scale hover effect
        },
        // padding: { xs: "1.25rem", md: "2.4375rem 4rem 2.4375rem 1.875rem" },
      }}
      onClick={() => {
        if (action) {
          action();
        }
        navigate(navigateTo);
      }}
    >
      <Box
        sx={{
          //   width: { xs: "100%", md: "35%" },
          marginLeft: 0,
          width: "35%",
        }}
      >
        {icon}
      </Box>

      <Box
        sx={{
          width: "65%",
          //   width: { xs: "100%", md: "65%" },
          //   marginLeft: { xs: 0, md: "1.25rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            textAlign: "center",
            // marginTop: { xs: "2.5rem", md: 0 },
          }}
        >
          <Typography
            sx={{
              fontWeight: "var(--neuro-font-weight-bold)",
              color: "var(--neuro-black-text)",
              fontSize: "var(--neuro-font-size-medium-smallest)",
              textAlign: "center",
              lineHeight: "120%",
            }}
          >
            {text || ""}
          </Typography>

          <Typography
            sx={{
              color: "var(--neuro-bg-primary-seagreen)",
              alignSelf: "center",
              fontSize: "var(--neuro-font-size-medium)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              fontWeight: "var(--neuro-font-weight-bold)",
              textAlign: "center",
              lineHeight: "120%",
            }}
          >
            {count + `${total !== 0 ? "/" + total : ""}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCard;
