import React, { useEffect, useState } from 'react';
// import './App2.css';

import SurveyComponent from './SurveyComponents/SurveyComponent';

import DynamicTextField from './DynamicTextField/DynamicTextField';
import DynamicRadioGroup from './DynamicRadioGroup/DynamicRadioGroup';
import DynamicCheckboxGroup from './DynamicCheckboxGroup';
import DynamicSelect from './DynamicSelect';

import DynamicRatingGroup from './DynamicRatingGroup';
import FileInput from './FileInput';
import DynamicRanking from './DynamicRanking';
import DynamicBooleanSwitchGroup from './DynamicBooleanSwitchGroup';
import DynamicImagePicker from './DynamicImage';

import { Box, useMediaQuery } from '@mui/system';
import { Button, List } from '@mui/material';

import useAppStore from '../../store/appStore';

import { useLocation, useNavigate } from 'react-router-dom';
import { submitFormResponseApisuryvegetcall, submitFormResponseApisuryveputcall } from '../../services/formService';


const FormviewComponent: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<string>('Designer');

    const [isonclick, setIsOnClick] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { isMenuCollapsed, setIsMenuCollapsed, setDrawerOpen, setShowFormSubmenus } =
        useAppStore();

    const [apiresponse, setApiResponse] = useState<any>({ name: '', id: '', questions: { pages: [] } });

    const [questionData, setQuestionData] = useState<any[]>([]);
    const userId = localStorage.getItem("formid");
    const [jsonviewobject, setJsonViewObject] = useState<any>({
        logoPosition: "right",
        pages: [
            {
                name: "page1",
                elements: [
                    {
                        type: "rating",
                        name: "question1"
                    }
                ]
            },


        ]
    });



    const [previousSidebarCollpased, _] = useState<boolean>(
        isMenuCollapsed ? true : false
    );


    const isBelow800 = useMediaQuery("(max-width:50rem)");
    useEffect(() => {
        setShowFormSubmenus(true);    /// when load it open form side bar
        if (isBelow800) {
            setDrawerOpen(false);
        } else {
            setIsMenuCollapsed(true);
        }

        return () => {
            setIsMenuCollapsed(previousSidebarCollpased);
            setDrawerOpen(!previousSidebarCollpased);

        };
    }, []);



    // useEffect(() => {
    //     setAllData((prev: any) => {
    //         const updatedPages = [...prev.pages];
    //         updatedPages[currentPage - 1].elements = questionData; // Update the current page's elements
    //         return { ...prev, pages: updatedPages };
    //     });
    // }, [questionData, currentPage]);



    useEffect(() => {
        setAllData((prev: any) => {
            // Initialize pages if it doesn't exist or isn't an array
            const updatedPages = Array.isArray(prev.pages) ? [...prev.pages] : [];
    
            // Ensure the current page exists
            while (updatedPages.length < currentPage) {
                updatedPages.push({ elements: [] }); // Initialize missing pages
            }
    
            // Safely update the current page's elements
            if (currentPage - 1 >= 0 && updatedPages[currentPage - 1]) {
                updatedPages[currentPage - 1].elements = questionData;
            } else {
                console.error(`Invalid currentPage: ${currentPage} or page doesn't exist`);
            }
    
            return { ...prev, pages: updatedPages };
        });
    }, [questionData, currentPage]);
    


    useEffect(() => {
        if (questionData.length > 0) {
            // Save updated questionData to localStorage whenever it changes
            localStorage.setItem("questionData", JSON.stringify(questionData));
        }
    }, [questionData]);

    const [allData, setAllData] = useState<any>({
        pages: [
            {
                name: "page1",
                elements: [
                    [],
                ],
            },
        ],
    });






    const handlePageChange = (pageIndex: number) => {
        // Save the current questionData back to the allData structure
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages];
            updatedPages[currentPage - 1].elements = questionData; // Save current questionData
            return { ...prev, pages: updatedPages };
        });

        // Load the new page's data into questionData
        setQuestionData(allData.pages[pageIndex - 1]?.elements || []);
        setCurrentPage(pageIndex);
    };

    // Adds a new page to allData
    const addPage = () => {
        setAllData((prev: any) => {
            const newPage = {
                name: `page${prev.pages.length + 1}`,
                elements: [[]], // Initialize with empty elements
            };
            return { ...prev, pages: [...prev.pages, newPage] };
        });

        // Automatically navigate to the new page after adding it
        handlePageChange(allData.pages.length + 1);
    };


    // const handlePageChange = (pageIndex: number) => {
    //     // Save current page's data back to allData
    //     setAllData((prev: any) => {
    //         const updatedPages = [...prev.pages];
    //         updatedPages[currentPage - 1].elements = [questionData]; // Save current page data to allData
    //         return { ...prev, pages: updatedPages };
    //     });

    //     // Load the new page's data into questionData
    //     setQuestionData(allData.pages[pageIndex - 1]?.elements[0] || []);
    //     setCurrentPage(pageIndex);
    // };

    // const addPage = () => {
    //     setAllData((prev: any) => {
    //         const newPage = {
    //             name: `page${prev.pages.length + 1}`,
    //             elements: [], // Start with an empty questionData array
    //         };
    //         return { ...prev, pages: [...prev.pages, newPage] };
    //     });

    //     setCurrentPage((prevPage) => prevPage + 1);
    //     setQuestionData([]); // Set questionData to empty for the new page
    // };











    // Update function for the question
    const updateQuestion = (index: number, updatedQuestion: any) => {
        const updatedQuestionData = [...questionData];
        updatedQuestionData[index] = updatedQuestion;
        setQuestionData(updatedQuestionData);
    };

    // Toggle the 'isRequired' field for the question
    const toggleRequired = (index: number) => {
        const updatedQuestionData = [...questionData];
        updatedQuestionData[index].isRequired = !updatedQuestionData[index].isRequired;
        setQuestionData(updatedQuestionData);
    };




    const updateQuestionName = (index: number, updatedName: string) => {
        setQuestionData((prev) => {
            const updatedQuestions = [...prev];
            updatedQuestions[index] = { ...updatedQuestions[index], name: updatedName };
            return updatedQuestions;
        });
    };

    const generateQuestionName = (index: number) => `${index + 1}`;

    // const addCheckboxQuestion = () => {
    //   const newCheckboxQuestion = {
    //     type: 'checkbox',
    //     name: generateQuestionName(questionData.length),
    //     choices: ["Item 22", "Item 2", "Item 3", "Item 4"],
    //     marks: {
    //       "Item 1": 2,
    //       "Item 2": 4,
    //       "Item 3": 2,
    //       "Item 4": 4,
    //     },
    //     required: false,
    //   };
    //   setQuestionData([...questionData, newCheckboxQuestion]);
    // };



    const location = useLocation(); // Get the location object from React Router

    // useEffect(() => {
    //     // Create a URLSearchParams object to parse query parameters
    //     const urlParams = new URLSearchParams(location.search);
    //     console.log(urlParams); // Log the URLSearchParams object for debugging

    //     const radio = urlParams.get("radio"); // Get the "radio" query parameter

    //     // Debugging: Log the value of radio
    //     console.log("radio:", radio); // Log the radio parameter value

    //     // Check the value of the radio parameter and call the relevant function
    //     urlParams.set("radio:", "");
    //     if (radio === "Checkboxes") {
    //         addCheckboxQuestion();
    //     } else if (radio === "RatingScale") {
    //         addRatingScale();

    //     } else if (radio === "Multi-SelectDropdown") {
    //         DynamicSelectSelect();

    //     } else if (radio === "FileUpload") {
    //         Fileuploadmodel();

    //     } else if (radio === "ImagePicker") {
    //         imagepicker();

    //     } else if (radio === "RadioButtonGroup") {
    //         addRadioQuestion();
    //     } else if (radio === "SelectDropdown") {
    //         DropdownSelectSelect();

    //     } else if (radio === "StarRatings") {
    //         addSwitchranking()
    //     }
    //     else if (radio === "Yes/No(Boolean)") {
    //         addSwitchboolean()
    //     } else if (radio === "TextFiled") {
    //         addTestfiled()
    //     } else if (radio === "Radiogroup11") {
    //         Radiogroup11()
    //     }
    //     else if (radio === "StarRatings") {
    //         addSwitchranking()
    //     } else {


    //     }

    // }, [location.search]);




    const [processedRadio, setProcessedRadio] = useState(false); // Track if the radio has been processed


    const navigate = useNavigate();


    useEffect(() => {
        // Create a URLSearchParams object to parse query parameters
        const urlParams = new URLSearchParams(location.search);
        console.log(urlParams); // Log the URLSearchParams object for debugging

        const radio = urlParams.get("radio"); // Get the "radio" query parameter

        // Debugging: Log the value of radio
        console.log("radio:", radio); // Log the radio parameter value
        navigate(`/forms/${userId}/create?radio=${''}`);
        if (radio) {
            // Check the value of the radio parameter and call the relevant function
            if (radio === "Checkboxes") {
                addCheckboxQuestion();
            } else if (radio === "RatingScale") {
                addRatingScale();
            } else if (radio === "MultiDropdown") {
                DynamicSelectSelect();
            } else if (radio === "FileUpload") {
                Fileuploadmodel();
            } else if (radio === "ImagePicker") {
                imagepicker();
            } else if (radio === "RadioButtonGroup") {
                addRadioQuestion();
            } else if (radio === "SelectDropdown") {
                DropdownSelectSelect();
            } else if (radio === "StarRatings") {
                addSwitchranking();
            } else if (radio === "Yes/No(Boolean)") {
                addSwitchboolean();
            } else if (radio === "TextFiled") {
                addTestfiled();
            } else if (radio === "Radiogroup") {
                Radiogroup11();
            } else {
                // Handle other cases if necessary
            }

            // Mark the radio as processed
            setProcessedRadio(true);

            // After using the radio parameter, clear it from the URL
            urlParams.delete("radio"); // Remove the radio parameter from the URL
            window.history.replaceState({}, "", "?" + urlParams.toString()); // Update the URL without reloading the page

            // Force the effect to reset and re-trigger it on the same query click
            setTimeout(() => {
                setProcessedRadio(false); // Reset processed radio after a short delay to allow the next click to trigger
            }, 100); // Small delay to reset the state for the next interaction
        }

    }, [location.search]); // Trigger when location.search changes

    // If the radio has been processed, set processedRadio to true to prevent re-running the useEffect
    useEffect(() => {
        if (processedRadio) {
            // If needed, handle additional UI state updates after processing the radio parameter
            console.log("Radio processed, UI updated.");


        }
    }, [processedRadio]);






    const addCheckboxQuestion = () => {
        const newCheckboxQuestion = {
            type: "checkbox",
            name: generateQuestionName(questionData.length), // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            choices: [
                { value: "feature1", text: "Feature 1" },
                { value: "feature2", text: "Feature 2" },
                { value: "feature3", text: "Feature 3" },
                { value: "feature4", text: "Feature 4" },
            ],
            correctAnswer: ["feature1", "feature2"], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    };


    const handleOnClickPage = () => {
        setIsOnClick(!isonclick)
    }

    // Handle the switch to the JSON editor tab
    const handleTabChange = () => {
        // Create a new updated JSON structure, ensuring only the elements are updated for each page
        const updatedJsonView = {
            ...jsonviewobject,  // Copy the existing jsonviewobject state
            pages: jsonviewobject.pages.map((page: any) => ({
                ...page,  // Copy the current page data
                elements: page.elements.map((element: any) => {
                    // You can define logic here to decide how to update the elements
                    if (questionData.length > 0) {
                        return questionData;  // Assign the current questionData to the elements
                    }
                    return element;  // If no questionData is available, keep the original element
                })
            }))
        };

        // Update the jsonviewobject state with the new structure
        setJsonViewObject(updatedJsonView);
    };



    const DynamicSelectSelect = () => {
        const newCheckboxQuestion = {
            type: "tagbox",
            name: generateQuestionName(questionData.length), // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            choices: [
                { value: "feature1", text: "Feature 1" },
                { value: "feature2", text: "Feature 2" },
                { value: "feature3", text: "Feature 3" },
                { value: "feature4", text: "Feature 4" },
            ],
            correctAnswer: ["feature2"], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    };

    const addRatingScale = () => {
        const newQuestion = {
            type: "rating",
            name: generateQuestionName(questionData.length), // Generate question name
            title: "What features did you like the most?",
            rateValues: [1, 2, { value: 3, text: "2" }, 4, 5],
            required: false,
            autoGenerate: false,
            correctAnswer: ["feature1", "feature2"],
        };
        setQuestionData([...questionData, newQuestion]);
    };

    // Function to add a radio question
    const addRadioQuestion = () => {
        const newQuestion = {
            "type": "radiogroup",
            "name": generateQuestionName(questionData.length),
            "title": "What features did you like the most?",
            "isRequired": true,
            "choices": [
                {
                    "value": "feature1",
                    "text": "Feature 1"
                },
                {
                    "value": "feature2",
                    "text": "Feature 2"
                },
                {
                    "value": "feature3",
                    "text": "Feature 3"
                },
                {
                    "value": "feature4",
                    "text": "Feature 4"
                }
            ],
            "correctAnswer": [
                "feature1",
            ]
        }
        setQuestionData((prevData) => [...prevData, newQuestion]);

    };

    // Function to remove a question
    const handleRemoveQuestion = (index: number) => {
        const updatedData = questionData.filter((_, i) => i !== index);
        setQuestionData(
            updatedData.map((question) => ({
                ...question,
                name: generateQuestionName(questionData.length),
            }))
        );
    };




    const DropdownSelectSelect = () => {
        const newCheckboxQuestion = {
            type: "dropdown",
            name: generateQuestionName(questionData.length), // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            choices: [
                { value: "feature1", text: "Feature 1" },
                { value: "feature2", text: "Feature 2" },
                { value: "feature3", text: "Feature 3" },
                { value: "feature4", text: "Feature 4" },
            ],
            correctAnswer: ["feature1"], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    }


    const addSwitchranking = () => {
        const newRankingQuestion = {
            type: "ranking",
            name: generateQuestionName(questionData.length),
            title: "Rank the following features based on importance:",
            isRequired: false,
            choices: [
                "Feature A",
                "Feature B",
                "Feature C",
                "Feature D"
            ],
            correctAnswer: ["Feature A", "Feature B", "Feature C", "Feature D"] // Full ranking order
        };

        // Update question data immutably
        setQuestionData((prevData) => [...prevData, newRankingQuestion]);
    };


    const imagepicker = () => {
        const newRadioQuestion = {
            type: "imagepicker",
            name: generateQuestionName(questionData.length),  // Ensure unique name based on the current length
            title: "Which animal design do you prefer?", // Title for the question
            isRequired: true,  // Mark the question as required
            choices: [
                {
                    value: "Image 1",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg"
                },
                {
                    value: "Image 2",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/giraffe.jpg"
                },
                {
                    value: "Image 3",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/panda.jpg"
                },
                {
                    value: "Image 4",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/camel.jpg"
                }
            ],
            imageFit: "cover", // How the images should be displayed
            correctAnswer: "Image 2"  // Example of the correct answer (you can change this)
        };

        // Add the new question to the question data
        setQuestionData([...questionData, newRadioQuestion]);
    };



    const handleDuplicateQuestion = (index: number) => {
        const duplicatedQuestion = { ...questionData[index], name: generateQuestionName(questionData.length), };
        setQuestionData([...questionData, duplicatedQuestion]);
    };


    const addTestfiled = () => {
        // Generate a unique name based on the current length of questionData
        const questionIndex = questionData.length; // Use the current length of questionData for indexing
        const newCheckboxQuestion = {
            "type": "text",
            "name": generateQuestionName(questionData.length), // Assuming generateQuestionName is a function that generates unique names
            "title": `You miss work or school because of your headaches? ${generateQuestionName(questionData.length)}`, // Adding unique identifier to the title
            ...(questionIndex > 0 ? { "visibleIf": `{${generateQuestionName(questionIndex - 1)}} notempty` } : {}),
            "inputType": "number",
            "placeholder": "23",
            "isRequired": true,
            "validators": [
                {
                    "type": "numeric",
                    "text": "The value must be between 0 and 90.",
                    "minValue": 0,
                    "maxValue": 90
                }
            ]
        };

        // Add the new question to the existing questionData
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
    };


    const addSwitchboolean = () => {

        const newCheckboxQuestion = {
            type: "boolean",
            name: generateQuestionName(questionData.length), // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            labelTrue: "Yes",
            labelFalse: "No",
            isRequired: true, // Make the question required
            correctAnswer: ["feature1"], // Set the correct answers
        };
        setQuestionData([...questionData, newCheckboxQuestion]);
    };



    // const onSubmit = async () => {
    //     const formData = {
    //       name,
    //       description:zipCode,
    //       type:formType,
    //       filledBy,
    //       disorderId,
    //       isScoreBased,
    //       status,
    //     };

    //     try {
    //       const response = await submitFormResponseApisuryveputcall(id, formData);
    //       setSuccessMessage("Form updated successfully!");
    //       setOpenSnackbar(true);
    //       navigate("/forms");
    //     } catch (error) {
    //       console.error("Error submitting form", error);
    //     }
    //   };



    const Radiogroup11 = () => {
        // Generate a unique name based on the current length of questionData
        const questionIndex = questionData.length; // Use the current length of questionData for indexing
        const questionName = generateQuestionName(questionIndex); // This generates a unique name for the question

        // Define the new question with conditional visibility
        const newCheckboxQuestion = {
            "type": "radiogroup",
            "name": questionName,
            "title": "When you have headaches, how often is the pain severe?",
            "isRequired": true,
            // Conditionally add the visibleIf property, incrementing based on question index
            ...(questionIndex > 0 ? { "visibleIf": `{${generateQuestionName(questionIndex - 1)}} notempty` } : {}),
            "choices": [
                { "value": "0", "text": "Never", "score": 0 },
                { "value": "2", "text": "Rarely", "score": 2 },
                { "value": "5", "text": "Sometimes", "score": 5 },
                { "value": "8", "text": "Very Often", "score": 8 },
                { "value": "10", "text": "Always", "score": 10 }
            ]
        }

        // Add the new question to the existing questionData
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
    };


    //     const location = useLocation();

    //     useEffect(() => {
    //       // Parse the URL parameters
    //       const urlParams = new URLSearchParams(location.search);
    //       const radio = urlParams.get('radio');
    //       const checkbox = urlParams.get('checkbox');
    //       console.log("radio", radio); // For debugging
    //   alert(radio)
    //   Radiogroup11();
    //       // Conditionally render based on URL parameters
    //       if (radio === 'true') {
    //         Radiogroup11();
    //       }

    //       if (checkbox === 'true') {
    //         // Handle checkbox logic here
    //         console.log('Render checkbox question');
    //       }
    //     }, [location.search]); 


    const Fileuploadmodel = () => {

        const newCheckboxQuestion = {
            type: "file",
            name: generateQuestionName(questionData.length),
            title: "Upload a picture of your purchase receipt.",
            isRequired: false,
            storeDataAsText: false,
            maxSize: 102400
        };
        setQuestionData([...questionData, newCheckboxQuestion]);
    }



    useEffect(() => {
        if (!userId) return;

        const fetchData = async () => {

            try {
                // Make the API call
                const result = await submitFormResponseApisuryvegetcall(userId);
                console.log("result", result.data.data)
                setApiResponse(result.data.data)

            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [userId]);




    const handleSubmit = async () => {
        if (!apiresponse.id) {
            console.error("API response does not have an ID");
            return;
        }

        // Update the state with new questions
        const updatedApiResponse = {
            ...apiresponse,
            questions: allData, // Add or update questions
        };

        setApiResponse(updatedApiResponse);

        try {
            // Make the PUT API call
            const response = await submitFormResponseApisuryveputcall(apiresponse.id, updatedApiResponse);
            console.log("PUT response", response);
            //   message
            // Optionally update the state with the response
            setApiResponse(response.data);
            // showSnackbar(response.data.message, "success")
        } catch (err) {
            console.error("Error updating questions", err);
        }
    };

    useEffect(() => {
        console.log("apiresponse structure:", apiresponse);

        // Delay setting data to simulate asynchronous behavior
        const timeoutId = setTimeout(() => {
            if (apiresponse && apiresponse?.questions?.pages?.[0]?.elements) {
                console.log("Setting questionData:", apiresponse.questions.pages[0].elements);
                setQuestionData(apiresponse.questions.pages[0].elements);
            } else {
                console.log("questions.pages[0].elements is undefined or null");
                setQuestionData([]); // Fallback to an empty array
            }
        }, 1000);

        // Cleanup timeout on component unmount or apiresponse change
        return () => clearTimeout(timeoutId);
    }, [apiresponse]);




    const [isSidebarOpen] = useState(false);

    const [activeIndex] = useState<number | null>(null);


    // Function to handle changes for a specific choice in the choices array
    const handleChoiceChange = (index: number, choiceIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedData = [...questionData];
        updatedData[index].choices[choiceIndex] = event.target.value; // Update the specific choice
        setQuestionData(updatedData);
    };

    // Function to add a new choice to the choices array
    const handleAddChoice = (index: number) => {
        const updatedData = [...questionData];
        updatedData[index].choices.push(""); // Add a new empty choice
        setQuestionData(updatedData);
    };


    const handleRemoveChoice = (index: number, choiceIndex: number) => {
        const updatedData = [...questionData];
        updatedData[index].choices.splice(choiceIndex, 1);
        setQuestionData(updatedData);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        if (activeIndex !== null) {
            const updatedData = [...questionData];
            updatedData[activeIndex] = {
                ...updatedData[activeIndex],
                [field]: event.target.value,
            };
            setQuestionData(updatedData);
        }
    };


    const [menuOpen, setMenuOpen] = useState(false);

    const tabs = ['Designer', 'Preview', 'Themes', 'Logic', 'JSON Editor', 'Form Viewer'];





    const handleTabClick = (tabName: string) => {
        setSelectedTab(tabName);
    };



    const renderTabContent = () => {
        if (selectedTab === 'Designer') {
            return (
                <div>
                    {/* <h2>Page 1  </h2>      */}
                    <div className="form-container">

                        {/* <span onClick={addPage} style={{ cursor: "pointer", color: "blue" }}>
          +
        </span> */}
                        {/* <p>Description</p> */}


                        <div className="app-container" style={{ width: '90%' }}>

                            <h3 style={{ margin: '10px' }}>Welcome {apiresponse.name}!</h3>
                            {/* <h2>Page {currentPage}</h2> */}
                            {/* <input type='text' placeholder='Survey Title...' className='surveytitle' />
                            <input type='text' placeholder='Description...' className='Description' />

                            <div className='linepage'></div> */}
                            {/* <div style={{ marginBottom: "20px" }}>
                                {allData.pages.map((page: any, index: any) => (
                                    <button
                                        key={page.name}
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{
                                            margin: "5px",
                                            padding: "10px",
                                            backgroundColor: currentPage === index + 1 ? "#21b9a9" : "white",
                                            color: currentPage === index + 1 ? "white" : "black",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {page.name}
                                    </button>
                                ))}


                            </div>
                            <button
                      onClick={() => {
                        addPage();
                        handlePageChange(index + 1);
                      }}
                                style={{
                                    margin: "5px",
                                    padding: "13px",
                                    backgroundColor: "white",
                                    color: "rgb(33, 185, 169)",
                                    border: "none",
                                    borderRadius: "5px",
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    cursor: "pointer",
                                    marginBottom: '2rem',
                                    marginTop: '1rem',
                                    width: '80%',
                                    marginLeft: '3rem',
                                    boxShadow: '1px 0px 2px 2px rgb(210, 210, 210)'
                                }}
                            >
                                + Add Page
                            </button> */}

                            <div style={{ marginBottom: "20px" }}>
                                {allData.pages.map((page: any, index: number) => (
                                    <button
                                        key={page.name}
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{
                                            margin: "5px",
                                            padding: "10px",
                                            backgroundColor: currentPage === index + 1 ? "#21b9a9" : "white",
                                            color: currentPage === index + 1 ? "white" : "black",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {page.name}
                                    </button>
                                ))}
                            </div>

                            <button
                                onClick={() => {
                                    addPage(); // Adds a new page to your data structure
                                    handlePageChange(allData.pages.length + 1); // Navigates to the new page
                                }}
                                style={{
                                    margin: "5px",
                                    padding: "13px",
                                    backgroundColor: "white",
                                    color: "rgb(33, 185, 169)",
                                    border: "none",
                                    borderRadius: "5px",
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    cursor: "pointer",
                                    marginBottom: '1rem',
                                    marginTop: '0rem',
                                    width: 'auto',
                                    marginLeft: '0rem',
                                    boxShadow: '0px 0px 1px 2px rgb(228, 228, 228)'
                                }}
                            >
                                + Add Page
                            </button>


                            <h2 style={{ fontSize: '1.3rem', fontWeight: '500' }}>Page {currentPage}</h2>
                            <div className={`${isonclick ? 'formpage' : 'formpage'}`} onClick={handleOnClickPage}>
                                <div className={`${isonclick ? 'formpage' : 'formpage'}`} >
                                    {/* <div className="question-controls" style={{ position: 'relative', right: '1rem' }}> */}
                                    {allData.pages.length >= 1 && (
                                        <div>

                                            {/* <button
                                                className="delete-button button-spacing red-bg required-button"
                                                style={{ backgroundColor: 'transparent', position: 'relative', right: '2rem', top: '-1rem' }}
                                                onClick={deletePage}

                                            >
                                                <FaTrash style={{ color: 'orange' }} /> Delete
                                            </button> */}
                                        </div>
                                    )}


                                </div>
                                {questionData.map((question, index) => (
                                    <div key={index} >
                                        <p></p>



                                        {question.type === 'dropdown' && (
                                            <DynamicSelect
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                            />
                                        )}

                                        {/* {question.type === 'tagbox' && (
                                            <DynamicSelectMulti
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                            />
                                        )} */}

                                        {question.type === 'imagepicker' && (

                                            <DynamicImagePicker
                                                key={index}
                                                initialQuestion={question}
                                                index={index}
                                                toggleRequired={toggleRequired}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                            />

                                        )}

                                        {question.type === 'boolean' && (
                                            <DynamicBooleanSwitchGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                toggleRequired={toggleRequired}  // Add the toggleRequired function here
                                            />
                                        )}


                                        {question.type === 'ranking' && (


                                            <DynamicRanking
                                                key={index}
                                                question={question}
                                                index={index}
                                                updateQuestion={(index, updatedQuestion) =>
                                                    updateQuestion(index, updatedQuestion)
                                                }
                                                // updateQuestion={updateQuestion}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                            />
                                        )}



                                        {question.type === 'file' && (
                                            <FileInput
                                                key={index}
                                                index={index}
                                                question={question}
                                                updateQuestion={updateQuestion}
                                                toggleRequired={toggleRequired}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestionTitle={(updatedTitle: string) => updateQuestion(index, { ...question, title: updatedTitle })} // Optional, if you need a specific title update function
                                            />
                                        )}


                                        {
                                            question.type === 'rating' && (
                                                <DynamicRatingGroup
                                                    key={index}
                                                    question={question}
                                                    index={index}
                                                    toggleRequired={toggleRequired}
                                                    handleDuplicateQuestion={handleDuplicateQuestion}
                                                    handleRemoveQuestion={handleRemoveQuestion}
                                                    updateQuestionName={(updatedName) =>
                                                        updateQuestionName(index, updatedName)
                                                    }
                                                />
                                            )
                                        }


                                        {question.type === 'checkbox' && (
                                            <DynamicCheckboxGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                updateQuestion={(index, updatedQuestion) =>
                                                    updateQuestion(index, updatedQuestion)
                                                }
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                            />
                                        )}
                                        {question.type === 'text' && (
                                            <DynamicTextField
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                            />
                                        )
                                        }

                                        {question.type === 'radiogroup' && (
                                            <DynamicRadioGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                            />
                                        )}







                                    </div>
                                ))}

                                <pre style={{ fontSize: '6px', color: 'transparent', display: 'hidden' }}>{JSON.stringify(allData, null, 2)}</pre>
                            </div>

                        </div>

                        {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}

                    </div>
                    {/* {pages.map((pageNumber) => (
          <div key={pageNumber}>
            <h2>Page {pageNumber}</h2>
   
          </div>
        ))} */}
                </div>
            );
        } else if (selectedTab === 'JSON Editor') {
            return (
                <>
                    {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}

                    <pre>{JSON.stringify(allData, null, 2)}</pre>

                    {/* <pre>{JSON.stringify(jsonviewobject, null, 2)}</pre> */}

                    {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}

                </>
            )
        }
        else if (selectedTab === 'Form Viewer') {
            return (
                <>
                    {/* <pre>{JSON.stringify(questionData, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(jsonviewobject, null, 2)}</pre> */}
                    <div className="app-container">

                        {/* <SurveyComponent questionData={questionData} /> */}

                        <>
                            <SurveyComponent questionData={allData} />
                            <pre style={{ fontSize: '6px', color: 'transparent' }}>{JSON.stringify(allData, null, 2)}</pre>
                        </>

                        {/* {questionData[0].type === 'text' && (
                            <>
                                <SurveyComponenttext questionData={questionData} />
                            </>)}
                        {questionData[0].type !== 'text' && questionData[0].type !== 'radiogroup' && (
                            <>
                                <SurveyComponentcomman questionData={questionData} />
                            </>
                        )} */}





                    </div>
                </>
            )
        } else {
            return null;  // For all other tabs, render nothing
        }
    };

    return (
        //     <div className="app-container">
        //         <div  style={{display:'flex', flexDirection:'row'}}>
        //         <FormSidebar  />
        //         <header className="header">
        //             {/* Hamburger Menu Icon */}
        //             <div className="header__hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //             </div>

        //             {/* Tabbed Menu */}
        //             <div className={`svc-tabbed-menu ${menuOpen ? 'svc-tabbed-menu--open' : ''}`}>
        //                 {tabs.map((tab) => (
        //                     <span
        //                         className={`svc-tabbed-menu-item-container ${selectedTab === tab ? 'svc-tabbed-menu-item--selected' : ''
        //                             }`}
        //                         key={tab}
        //                         onClick={() => {
        //                             handleTabClick(tab); // Change selected tab
        //                             handleTabChange(); // Handle actions when tab is clicked
        //                             setMenuOpen(false); // Close menu on selection
        //                         }}
        //                     >
        //                         <span className="svc-tabbed-menu-item__text">{tab}</span>
        //                     </span>
        //                 ))}
        //             </div>
        //         </header>
        //         </div>
        //         <div className="content-container">
        //             {/* <aside className={`sidebar ${isExpanded ? 'expanded' : ''}`}>
        //                 <button onClick={() => setIsExpanded(!isExpanded)} className="hamburger-btn" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //                     {isExpanded ? (
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        //                             <path d="M6 18L18 6M6 6l12 12" />
        //                         </svg>
        //                     ) : (
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        //                             <path d="M3 12h18M3 6h18M3 18h18" />
        //                         </svg>
        //                     )}
        //                 </button>

        //                 <ul>

        //                     {items.map((item, index) => (
        //                         <li
        //                             key={index}
        //                             onClick={() => handleConditionalClick(item)}
        //                             className={selectedItem === item ? "selected" : ""}
        //                         >
        //                             <span
        //                                 className="icon"
        //                                 dangerouslySetInnerHTML={{ __html: item.icon }}
        //                             />
        //                             {isExpanded && <span className="name">{item.name}</span>}
        //                         </li>
        //                     ))}
        //                 </ul>


        //             </aside> */}


        //             <main className="main-content">
        //                 {/* Render the content based on selected tab */}
        //                 {renderTabContent()}
        //             </main>

        //             <button className="hamburger-menu" onClick={toggleSidebar}>
        //                 ☰
        //             </button>

        //             {/* Sidebar */}

        //             <aside className={`right-sidebar ${isSidebarOpen ? "open" : ""}`}>
        //                 <input type="text" className="search-bar" placeholder="Type to search..." />
        //                 <h3>General</h3>

        //                 {/* Survey title input */}
        //                 <label>Survey title</label>
        //                 <input
        //                     type="text"
        //                     value={activeIndex !== null ? questionData[activeIndex].name : ""}
        //                     onChange={(e) => handleInputChange(e, 'name')} // Update the name field
        //                 />

        //                 {/* Survey description input */}
        //                 {/* <label>Survey description</label>
        //     <input
        //       type="text"
        //       value={activeIndex !== null ? questionData[activeIndex].description || "" : ""}
        //       onChange={(e) => handleInputChange(e, 'description')} // Update the description field
        //     /> */}

        //                 {/* Choices input fields */}
        //                 <label>Choices</label>
        //                 <div>
        //                     {activeIndex !== null && questionData[activeIndex].choices.map((choice: string, choiceIndex: number) => (
        //                         <div key={choiceIndex} className="choice-container">
        //                             <input
        //                                 type="text"
        //                                 value={choice}
        //                                 onChange={(e) => handleChoiceChange(activeIndex, choiceIndex, e)} // Handle choice change
        //                             />
        //                             <button onClick={() => handleRemoveChoice(activeIndex, choiceIndex)}>Remove</button>
        //                         </div>
        //                     ))}
        //                 </div>

        //                 {/* Button to add a new choice */}
        //                 <button onClick={() => activeIndex !== null && handleAddChoice(activeIndex)} disabled={activeIndex === null}>
        //                     Add Choice
        //                 </button>

        //                 {/* Checkbox to make the title and description visible */}
        //                 <div className="checkbox-group">
        //                     <input type="checkbox" id="titleVisible" />
        //                     <label htmlFor="titleVisible">Make the title and description visible</label>
        //                 </div>

        //                 {/* Select survey language input */}
        //                 <label>Select a survey language</label>
        //                 <input type='text' />

        //                 {/* Survey display mode toggle */}
        //                 <label>Survey display mode</label>
        //                 <div className="toggle-buttons">
        //                     <div className="toggle-button active">Editable</div>
        //                     <div className="toggle-button">Read-only</div>
        //                 </div>
        //             </aside>
        //             {/* <aside className={`right-sidebar ${isSidebarOpen ? "open" : ""}`}>
        //     <input type="text" className="search-bar" placeholder="Type to search..." />
        //     <h3>General</h3>

        //     <label>Survey title</label>
        //     <input type="text" placeholder="Survey title" />

        //     <label>Choices</label>
        //     <div>
        //       <input type="text" placeholder="Choice 1" />
        //       <button>Remove</button>
        //     </div>
        //     <button>Add Choice</button>

        //     <div className="checkbox-group">
        //       <input type="checkbox" id="titleVisible" />
        //       <label htmlFor="titleVisible">Make the title and description visible</label>
        //     </div>

        //     <label>Select a survey language</label>
        //     <input type="text" />

        //     <label>Survey display mode</label>
        //     <div className="toggle-buttons">
        //       <div className="toggle-button active">Editable</div>
        //       <div className="toggle-button">Read-only</div>
        //     </div>
        //   </aside> */}


        //         </div>
        //     </div>
        <div className="app-container" style={{ overflow: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* Sidebar */}
                {/* <FormSidebar /> */}


                <Box

                >
                    <Box
                        sx={{
                            height: true
                                ? "auto"
                                : "calc(100% - 11.25rem)",
                        }}
                    >
                        <List
                            sx={{
                                padding: "0rem",
                                height: "auto",
                                overflow: "none",
                            }}
                        >


                            {/* <aside className={`sidebar ${isExpanded ? 'expanded' : ''}`}>

                                <IconButton
                                    disableRipple
                                    sx={{
                                        // marginRight: isMobile ? "0.625rem" : "0.875rem",
                                        padding: 0,
                                        backgroundColor: "transparent",
                                        height: "1.25rem",
                                        width: "1.25rem",
                                        marginLeft: '1.8rem',
                                        "&:hover": { backgroundColor: "transparent" },
                                    }}
                                    onClick={() => {
                                        setIsExpanded(!isExpanded);
                                        handleMenuIconClick();
                                    }}
                                >
                                    <MenuIcon
                                        color={
                                            "var(--neuro-black-text)"
                                            // isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)"
                                        }
                                    />
                                </IconButton>

                                <ul>
                                    {items.map((item, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleConditionalClick(item)}
                                            className={selectedItem === item ? "selected" : ""}
                                        >
                                            <span
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: item.icon }}
                                            />
                                            {isExpanded && <span className="name">{item.name}</span>}
                                        </li>
                                    ))}
                                </ul>
                            </aside> */}

                            {/* <FormViewSidebar
        items={items}
        selectedItem={selectedItem}
        onMenuToggle={handleMenuToggle}
        onItemSelect={handleItemSelect}
      /> */}

                            {/* <FormViewSidebar
        onMenuToggle={handleMenuToggle}
        onItemSelect={handleItemSelect}
        selectedItem={selectedItem}
      /> */}

                        </List>
                    </Box>
                </Box>
                {/* Main Content Area */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <header className="header">
                        {/* Hamburger Menu Icon */}
                        <div className="header__hamburger" onClick={() => setMenuOpen(!menuOpen)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        {/* Tabbed Menu */}
                        <div className={`svc-tabbed-menu ${menuOpen ? 'svc-tabbed-menu--open' : ''}`}>
                            {tabs.map((tab) => (
                                <span
                                    className={`svc-tabbed-menu-item-container ${selectedTab === tab ? 'svc-tabbed-menu-item--selected' : ''}`}
                                    key={tab}
                                    onClick={() => {
                                        handleTabClick(tab); // Change selected tab
                                        handleTabChange(); // Handle actions when tab is clicked
                                        setMenuOpen(false); // Close menu on selection
                                    }}
                                >
                                    <span className="svc-tabbed-menu-item__text">{tab}</span>
                                </span>
                            ))}

                            {/* <button onClick={handleSubmit}  className='btn btn'>Form Submit</button> */}
                            <Button

                                color="secondary"
                                className="success-button"

                                onClick={handleSubmit}

                                style={{ borderStyle: 'none' }}
                            >
                                Submit Form
                            </Button>
                        </div>
                    </header>

                    <div className="content-container" style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        {/* Main Content */}
                        <main className="main-content" style={{ flex: 1 }}>
                            {renderTabContent()}
                        </main>

                        {/* Right Sidebar */}
                        <aside className={`right-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                            <input type="text" className="search-bar" placeholder="Type to search..." />
                            <h3>General</h3>

                            {/* Survey title input */}
                            <label>Survey title</label>
                            <input
                                type="text"
                                value={activeIndex !== null ? questionData[activeIndex].name : ""}
                                onChange={(e) => handleInputChange(e, 'name')}
                            />

                            <label>Choices</label>
                            <div>
                                {activeIndex !== null && questionData[activeIndex].choices.map((choice: any, choiceIndex: any) => (
                                    <div key={choiceIndex} className="choice-container">
                                        <input
                                            type="text"
                                            value={choice}
                                            onChange={(e) => handleChoiceChange(activeIndex, choiceIndex, e)}
                                        />
                                        <button onClick={() => handleRemoveChoice(activeIndex, choiceIndex)}>Remove</button>
                                    </div>
                                ))}
                            </div>

                            <button onClick={() => activeIndex !== null && handleAddChoice(activeIndex)} disabled={activeIndex === null}>
                                Add Choice
                            </button>

                            {/* Checkbox to make the title and description visible */}
                            <div className="checkbox-group">
                                <input type="checkbox" id="titleVisible" />
                                <label htmlFor="titleVisible">Make the title and description visible</label>
                            </div>

                            {/* Select survey language input */}
                            <label>Select a survey language</label>
                            <input type='text' />

                            {/* Survey display mode toggle */}
                            <label>Survey display mode</label>
                            <div className="toggle-buttons">
                                <div className="toggle-button active">Editable</div>
                                <div className="toggle-button">Read-only</div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FormviewComponent;
