import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { getPayments } from "../../services/paymentService";
import useAuthStore from "../../store/authStore";
import PaymentsList from "./PaymentsList";
import { PaymentsPropsTypes } from "../../types/payments";

const Payments: React.FC<PaymentsPropsTypes> = ({ patientId }) => {
    // props & state values
    const [payments, setPayments] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useAuthStore();

    // callbacks & functions
    const loadPayments = async (id: string) => {
        try {
            setLoading(true);
            const response = await getPayments(id);

            console.log("payemnts response", response);
            setPayments(response.data.payments || []);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching hospitals:", error);
            setPayments([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (patientId) {
            loadPayments(patientId || "");
        }
        else {
            loadPayments(user?.patientId || "");
        }
    }, [patientId]);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    height: "100%",
                    paddingBlock: patientId ? 0 : "1rem",
                    paddingInline: patientId ? 0 : "0.875rem",
                    overflow: "auto",
                }}
            >
                <PaymentsList loading={loading} payments={payments} patientId={patientId} />
            </Box>
        </>
    );
};

export default Payments;
