import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import Tooltip from "../ui/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ComponentHeaderProps } from "../../types/common";

const ComponentHeader: React.FC<ComponentHeaderProps> = ({
  title,
  alert,
  children,
  handleBack,
  page,
  sx,
  // isBreadcrumb = false,
}) => {
  const getFlexValue = () => {
    if (!alert && !children) {
      return 1;
    }
    if (!alert && children) {
      return 0.5;
    }
    if (alert && !children) {
      return 0.5;
    } else {
      return 0.3;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "1rem",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          flex: getFlexValue(),
        }}
      >
        {page && (
          <Tooltip title={`Back to ${page}`} placement="top">
            <IconButton onClick={handleBack} size="small">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}

        <Typography className="page-heading">{title}</Typography>
      </Box>

      {alert && (
        <Box sx={{ flex: !children ? 0.5 : 0.4, textAlign: "center" }}>
          {alert}
        </Box>
      )}

      {children && (
        <Box
          sx={{
            flex: !alert ? 0.5 : 0.3,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default ComponentHeader;
