import React from "react";
import { Alert as MuiAlert } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import { AlertProps } from "../../types/ui";

const Alert: React.FC<AlertProps> = ({
  title,
  message,
  type,
  sx,
  ...props
}) => {
  return (
    <MuiAlert severity={type} sx={{ ...sx }} {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </MuiAlert>
  );
};

export default Alert;
