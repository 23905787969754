import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Mp3PlayerProps } from "../../../types/documents";
import { Box } from "@mui/material";

const Mp3Player: React.FC<Mp3PlayerProps> = ({ filePath }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <AudioPlayer
        // autoPlay
        src={filePath}
        onPlay={() => console.log("onPlay")}
        // other props here as needed
      />
    </Box>
  );
};

export default Mp3Player;
