import PatientFormsList from "../patients/PatientFormsList";
import React from "react";
import { Box } from "@mui/material";
import useAuthStore from "../../store/authStore";
import { capitalizeWordsPreservingSpaces } from "../../utils/common";
import ComponentHeader from "../shared/ComponentHeader";

const MyForms: React.FC = () => {
    // props & state values
    const { user } = useAuthStore();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    height: "100%",
                    paddingBlock: "1rem",
                    paddingInline: "0.875rem",
                    overflow: "auto",
                    gap: "1rem"
                }}
            >
                <ComponentHeader title="Forms" />

                <Box sx={{ height: "calc(100% - 1.5rem)" }}>
                    <PatientFormsList
                        patientId={user?.patientId}
                        patientName={capitalizeWordsPreservingSpaces(user?.name || "")}
                    />
                </Box>
            </Box>
        </>
    );
};

export default MyForms;
