import React from "react";
import { Box, Grid } from "@mui/material";
import ViewerReportCard from "../view/ViewerReportCard";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentGridProps } from "../../../types/documents";

const DocumentGrid: React.FC<DocumentGridProps> = ({
  documents,
  isMenuCollapsed,
  patientId,
  isFromMeet = false,
}) => {
  const { setSelectedDocumentId } = useDocumentStore();
  console.log("documents", documents);

  return (
    <Box
      sx={{
        maxHeight: "calc(100% - 6.25rem)",
        overflow: "auto",
      }}
    >
      <Grid container spacing={2.5}>
        {documents?.map((document) => (
          <Grid
            item
            xs={12}
            md={isMenuCollapsed ? (isFromMeet ? 6 : 3) : isFromMeet ? 6 : 4}
            xl={isFromMeet ? 6 : 3}
            key={document.id}
          >
            <ViewerReportCard
              {...document}
              from="list"
              setSelectedDocumentId={setSelectedDocumentId}
              patientId={patientId}
              isFromMeet={isFromMeet}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DocumentGrid;
