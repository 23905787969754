const AfternoonIcon = (props: any) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {/* <ellipse cx={14} cy={14} rx={14} ry={14} fill="white" /> */}
    <path
      d="M13.8635 14.0001C13.8635 16.5974 13.8635 17.3705 13.863 18.7029C11.2657 18.7029 9.16016 16.5974 9.16016 14.0001C9.16016 11.4028 11.2657 9.29724 13.863 9.29724C13.863 10.068 13.8635 11.4028 13.8635 14.0001Z"
      fill="#FF650F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6537 3.88892C14.0433 3.88892 14.3591 4.20475 14.3591 4.59434V6.47548C14.3591 6.86507 14.0433 7.18091 13.6537 7.18091C13.2641 7.18091 12.9482 6.86507 12.9482 6.47548V4.59434C12.9482 4.20475 13.2641 3.88892 13.6537 3.88892ZM4.66602 14C4.66602 13.6104 4.98185 13.2946 5.37144 13.2946H7.25258C7.64217 13.2946 7.958 13.6104 7.958 14C7.958 14.3896 7.64217 14.7055 7.25258 14.7055H5.37144C4.98185 14.7055 4.66602 14.3896 4.66602 14ZM13.6537 20.8191C14.0433 20.8191 14.3591 21.135 14.3591 21.5246V23.4057C14.3591 23.7953 14.0433 24.1111 13.6537 24.1111C13.2641 24.1111 12.9482 23.7953 12.9482 23.4057V21.5246C12.9482 21.135 13.2641 20.8191 13.6537 20.8191Z"
      fill="#FF650F"
    />
    <g opacity={0.5}>
      <path
        d="M6.94262 6.20794C7.20551 5.92041 7.65171 5.90044 7.93925 6.16333L10.0292 8.07417C10.3167 8.33706 10.3367 8.78326 10.0738 9.07079C9.81093 9.35833 9.36473 9.3783 9.0772 9.11541L6.98724 7.20457C6.69971 6.94168 6.67973 6.49547 6.94262 6.20794Z"
        fill="#FF650F"
      />
      <path
        d="M10.0522 18.7263C10.3277 19.0017 10.3277 19.4484 10.0522 19.7239L7.96206 21.814C7.68656 22.0895 7.23992 22.0895 6.96443 21.814C6.68894 21.5385 6.68894 21.0919 6.96443 20.8164L9.05458 18.7262C9.33007 18.4508 9.77672 18.4508 10.0522 18.7263Z"
        fill="#FF650F"
      />
    </g>
  </svg>
);
export default AfternoonIcon;
