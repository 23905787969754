import { create } from "zustand";
import { Filters, FilterState } from "../types/common";

// Define the interface for the combined store
interface AppStore extends Filters {
  // States for loading
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;

  // states for appointnts pages in conference
  appointmentConferenceView: string;
  setAppointmentConferenceView: (value: string) => void;
  appointmentMeetId: any;
  setAppointmentMeetId: (value: any) => void;
  documentsConferenceView: string;
  setDocumentsConferenceView: (value: string) => void;

  // States for layout
  isMenuCollapsed: boolean;
  setIsMenuCollapsed: (value: boolean) => void;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
  showHeader: boolean;
  setShowHeader: (value: boolean) => void;

  // States for modal and redirection
  isHelpModalOpen: boolean;
  setHelpModalOpen: (value: boolean) => void;
  isRedirectedFromSignUp: boolean;
  setIsRedirectedFromSignUp: (value: boolean) => void;

  // States for snackbar
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarSeverity: "success" | "info" | "warning" | "error";
  showSnackbar: (
    message: string,
    severity?: "success" | "info" | "warning" | "error"
  ) => void;
  closeSnackbar: () => void;

  // states for selected patient for doctor view
  selectedPatient: any;
  setSelectedPatient: (value: any) => void;

  // states for filters
  setFilter: (key: keyof Filters, value: Partial<FilterState>) => void;
}

// Create the Zustand store with all logic
const useAppStore = create<AppStore>((set) => ({
  // Initial states for app
  isLoading: false,
  setIsLoading: (value: boolean) => set({ isLoading: value }),

  //Initial states for appointnts pages in conference
  appointmentConferenceView: "List",
  setAppointmentConferenceView: (value: string) =>
    set({ appointmentConferenceView: value }),
  appointmentMeetId: null,
  setAppointmentMeetId: (value: any) => set({ appointmentMeetId: value }),
  documentsConferenceView: "List",
  setDocumentsConferenceView: (value: string) =>
    set({ documentsConferenceView: value }),

  // Initial states for layout
  isMenuCollapsed: false,
  setIsMenuCollapsed: (value: boolean) => set({ isMenuCollapsed: value }),
  drawerOpen: false,
  setDrawerOpen: (value: boolean) => set({ drawerOpen: value }),
  showSidebar: true,
  setShowSidebar: (value: boolean) => set({ showSidebar: value }),
  showHeader: true,
  setShowHeader: (value: boolean) => set({ showHeader: value }),

  // Initial states for help modal and redirection
  isHelpModalOpen: true,
  isRedirectedFromSignUp: false,
  setHelpModalOpen: (value: boolean) => set({ isHelpModalOpen: value }),
  setIsRedirectedFromSignUp: (value: boolean) =>
    set({ isRedirectedFromSignUp: value }),

  // Initial states for snackbar
  snackbarMessage: "",
  snackbarOpen: false,
  snackbarSeverity: "info",
  showSnackbar: (
    message: string,
    severity: "success" | "info" | "warning" | "error" = "info"
  ) =>
    set({
      snackbarMessage: message,
      snackbarOpen: true,
      snackbarSeverity: severity,
    }),
  closeSnackbar: () => set({ snackbarOpen: false }),

  selectedPatient: null,
  setSelectedPatient: (value: any) =>
    set({
      selectedPatient: value,
    }),

  // Initial states for filters
  appointmentsFilter: {
    range: "",
    customStartDate: null,
    customEndDate: null,
    status: "All",
    searchValue: "",
  },
  dashboardFilter: {
    range: "TODAY",
    customStartDate: null,
    customEndDate: null,
    status: "",
  },
  doctorsFilter: {
    range: "",
    customStartDate: null,
    customEndDate: null,
    status: "",
    searchValue: "",
  },
  patientsFilter: {
    range: "",
    customStartDate: null,
    customEndDate: null,
    status: "",
    searchValue: "",
  },
  clinicsFilter: {
    range: "",
    customStartDate: null,
    customEndDate: null,
    status: "",
    searchValue: "",
  },
  referralsFilter: {
    range: "",
    customStartDate: null,
    customEndDate: null,
    status: "All",
    searchValue: "",
  },
  documentsFilter: {
    range: "",
    customStartDate: null,
    customEndDate: null,
    searchValue: "",
  },

  setFilter: (key: keyof Filters, value: Partial<FilterState>) =>
    set((state) => ({
      [key]: {
        ...state[key],
        ...value,
      },
    })),
}));

export default useAppStore;
