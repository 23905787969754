import React, { useEffect } from "react";

// third-party imports
import { Box, CssBaseline, useMediaQuery } from "@mui/material";

// project imports
import Header from "./Header";
// import NewSidebar from "../sidebar/NewSidebar";
import { Outlet, useLocation } from "react-router-dom";
import useAppStore from "../../store/appStore";
import LoadingScreen from "../shared/LoadingScreen";
import SidebarNav from "../sidebar/SidebarNav";
import { isDoctorOrNetwork } from "../../utils/auth";
import useAuthStore from "../../store/authStore";

const MainLayout: React.FC<{
  children?: React.ReactNode;
  backgroundColor?: string;
}> = ({ backgroundColor }) => {
  // props & state values
  const {
    // isMenuCollapsed,
    showHeader,
    showSidebar,
    isLoading,
    setSelectedPatientInList,
    setSelectedDoctorInList,
    setShowDoctorSubmenus,
    setShowDoctorMeetSubmenus,
    setAppointmentIdForMeet,
    setShowPatientsSubmenus
  } = useAppStore();

  const location = useLocation();

  const { userRole } = useAuthStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  // callbacks & functions
  useEffect(() => {
    if (!location.pathname.includes("patients")) {
      setSelectedPatientInList(null);
    }
    if (location.pathname !== "/patients/new/profile") {
      setShowPatientsSubmenus(false);
    }
    if ((!location.pathname.includes("doctors") && !isDoctorOrNetwork(userRole)) || (!location.pathname.includes("settings") && isDoctorOrNetwork(userRole))) {
      setSelectedDoctorInList(null);
      setShowDoctorSubmenus(false);
    }
    if (!location.pathname.includes("meet")) {
      setAppointmentIdForMeet(null);
      setShowDoctorMeetSubmenus(false);
    }
  }, [location?.pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: backgroundColor
          ? backgroundColor
          : "var(--neuro-bg-light-grey-secondary)",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      {showHeader && <Header />}

      <Box
        sx={{
          display: "flex",
          backgroundColor: backgroundColor
            ? backgroundColor
            : "var(--neuro-bg-light-grey-secondary)",
          overflow: "hidden",
          maxWidth: "1200px",
          width: "100%",
          marginInline: "auto",
          position: "relative",
          height: isMobile
            ? showHeader
              ? "calc(100% - 3.75rem)"
              : "100%"
            : showHeader
              ? "calc(100% - 5rem)"
              : "100%",
          "@media (max-width: 85.375rem)": {
            width: "100%",
            maxWidth:
              "100%",
            marginInline: 0
          },
        }}
      >
        <CssBaseline />
        {/* {showSidebar && <NewSidebar />} */}
        {showSidebar && <SidebarNav />}

        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
              // position: "relative",
            }}
          >
            <Outlet />
            {isLoading && (
              <LoadingScreen
                sx={{ position: "absolue", top: "50%", left: "50%" }}
              />
            )}
            {/* {children} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
