const TabletIcon = (props: any) => {
  const { height = 36, width = 36 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0657 16.7072C6.24372 12.8852 6.24372 6.68851 10.0657 2.86651C13.8877 -0.955504 20.0844 -0.955504 23.9064 2.86651L27.367 6.32707L27.1951 7.21896L27.1917 7.23439C27.1877 7.25261 27.1802 7.28549 27.1683 7.33221C27.1447 7.42561 27.1044 7.57421 27.0414 7.77117C26.9156 8.16511 26.7001 8.75209 26.3502 9.47803C25.6512 10.9284 24.4137 12.9384 22.275 15.0769C20.1366 17.2155 18.1266 18.4529 16.6765 19.1517C15.9507 19.5016 15.3639 19.7169 14.9699 19.8427C14.7731 19.9055 14.6244 19.946 14.531 19.9696C14.4844 19.9813 14.4514 19.9889 14.4333 19.993L14.418 19.9962L13.5265 20.168L10.0657 16.7072ZM20.7542 4.44553C20.2269 3.91832 19.3723 3.91832 18.845 4.44553C18.3178 4.97275 18.3178 5.82756 18.845 6.35478L21.5451 9.05486C22.0723 9.58207 22.927 9.58207 23.4542 9.05486C23.9815 8.52764 23.9815 7.67283 23.4542 7.14561L20.7542 4.44553Z"
        fill="#54B895"
      />
      <path
        d="M29.6131 8.59288L29.6178 8.57837L33.1331 12.0938C36.9551 15.9159 36.9551 22.1126 33.1331 25.9344C29.3112 29.7565 23.1145 29.7565 19.2925 25.9344L15.7773 22.4193L15.7908 22.415C16.2954 22.2539 17.0003 21.9929 17.8483 21.5842C19.5458 20.7663 21.8113 19.359 24.1839 16.9864C26.5566 14.6137 27.9639 12.3481 28.7822 10.6505C29.1908 9.80248 29.452 9.09745 29.6131 8.59288Z"
        fill="#54B895"
      />
      <path
        d="M5.91431 15.5659C2.40467 17.3492 0 20.994 0 25.2003C0 31.1652 4.83546 36.0006 10.8003 36.0006C15.0067 36.0006 18.6514 33.596 20.4347 30.0864C19.3371 29.5129 18.3067 28.7653 17.3845 27.8432L8.15748 18.6161C7.2354 17.6939 6.48768 16.6636 5.91431 15.5659Z"
        fill="#54B895"
      />
    </svg>
  );
};
export default TabletIcon;
