import { Box, Typography } from "@mui/material";
import React from "react";
import { PatientHeaderProps } from "../../../types/appointments";

const PatientHeader: React.FC<PatientHeaderProps> = ({ name }) => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-primary-seagreen)",
        height: "5rem",
        display: "flex",
        alignItems: "center",
        paddingLeft: "2.125rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "var(--neuro-font-size-tiny)",
          fontWeight: "var(--neuro-font-weight-bold)",
          lineHeight: "120%",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
          color: "var(--neuro-white-text)",
        }}
      >
        Patient Name: {name || ""}
      </Typography>
    </Box>
  );
};

export default PatientHeader;
