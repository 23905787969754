import { useCallback, useEffect, useState } from "react";
import RemirrorEditor from "./RemirrorEditor";

const PatientNotes = ({ notes, id }: { notes: any; id: string }) => {
  const [notesHtmlString, setNotesHtmlString] = useState<string>(notes);
  const [notesContent, setNotesContent] = useState<any>(null);

  const extractBodyContent = useCallback((htmlString: string) => {
    const bodyContentMatch = htmlString?.match(
      /<body[^>]*>((.|[\n\r])*)<\/body>/im
    );
    return bodyContentMatch ? bodyContentMatch[1].trim() : "";
  }, []);

  useEffect(() => {
    setNotesHtmlString(notes);
  }, [notes]);

  useEffect(() => {
    if (notesHtmlString && notesHtmlString !== "") {
      const innerDoctorSummaryContent = extractBodyContent(notesHtmlString);
      setNotesContent(innerDoctorSummaryContent);
    } else if (!notesHtmlString || notesHtmlString === "") {
      const innerDoctorSummaryContent = "<body><p>No Patient Notes</p></body>";
      setNotesContent(innerDoctorSummaryContent);
    }
  }, [notesHtmlString]);

  return (
    <>
      {notesContent && (
        <RemirrorEditor
          htmlContent={notesContent}
          summaryType="patientNotes"
          setNotesHtmlString={setNotesHtmlString}
          documentId={id}
        />
      )}
    </>
  );
};

export default PatientNotes;
