import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Button";

const NotFound: React.FC = () => {
  // props & state values
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
      }}
    >
      <Typography
        sx={{
          fontSize: "var(--neuro-font-size-large-small)",
          lineHeight: "120%",
          color: "var(--neuro-black-text)",
          marginBottom: "0.5rem",
        }}
      >
        Page Not Found
      </Typography>
      <Typography
        sx={{
          fontSize: "var(--neuro-font-size-smaller-plus)",
          lineHeight: "120%",
          color: "var(--neuro-black-text)",
          marginBottom: "1.25rem",
        }}
      >
        The page you are looking for does not exist.
      </Typography>
      <Button variant="contained" className="primary-button" onClick={() => navigate('/dashboard')}>
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default NotFound;
