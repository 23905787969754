import React from "react";
import { Box, Typography, Stack, useMediaQuery } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VideocamIcon from "@mui/icons-material/Videocam";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Button from "../../ui/Button";
import BorderIcon from "../../svg/BorderIcon";
import { capitalizeFirstLetter } from "../../../utils/common";
import { specializationMapping } from "../../../utils/appointments";
import UserAvatar from "../../shared/UserAvatar";
import useAppointmentStore from "../../../store/appointmentsStore";
import { DoctorCardProps } from "../../../types/appointments";

const DoctorCard: React.FC<DoctorCardProps> = ({ doctor }) => {
  // props & state values
  const {
    setSelectedMeetingType,
    setSelectedDoctor,
    setAppointmentDrawer,
    // yourAvailabilitySelected,
    // selectedDate,
  } = useAppointmentStore();

  console.log("doctor", doctor);

  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelow400 = useMediaQuery("(max-width:25rem)");

  const handleClick = (doctor: any) => {
    setSelectedDoctor(doctor);
    setSelectedMeetingType("ONLINE");
    setAppointmentDrawer(true);
  };

  const handleOnlineVisit = (
    event: React.MouseEvent<HTMLButtonElement>,
    doctor: any
  ) => {
    event.stopPropagation();
    setSelectedDoctor(doctor);
    setSelectedMeetingType("ONLINE");
    setAppointmentDrawer(true);
  };

  const handleHospitalVisit = (
    event: React.MouseEvent<HTMLButtonElement>,
    doctor: any
  ) => {
    event.stopPropagation();
    setSelectedDoctor(doctor);
    setSelectedMeetingType("IN_PERSON");
    setAppointmentDrawer(true);
  };

  return (
    <Box
      onClick={() => handleClick(doctor)}
      sx={{
        // maxWidth: 400,
        borderRadius: "0.75rem",
        border: "1px solid var(--neuro-secondary_border)",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        cursor: "pointer",
      }}
    >
      {/* relative border   */}
      <Box sx={{ position: "absolute", top: 0, left: 0 }}>
        <BorderIcon />
      </Box>

      <Box sx={{ display: "flex", gap: "0.75rem" }}>
        <UserAvatar
          src={doctor?.profileUrl || null}
          width="5rem"
          height="5rem"
          alt={
            `${capitalizeFirstLetter(doctor?.firstName || "") || ""}` ||
            "Doctor-profile"
          }
          sx={{ marginTop: "1.5rem", marginLeft: "1.438rem" }}
        />

        <Box sx={{ flex: 1, pb: "1rem", pr: "1rem", pt: "1.375rem" }}>
          <Typography
            sx={{
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-bg-darkblue-primary)",
              lineHeight: "120%",
            }}
          >
            Dr. {capitalizeFirstLetter(doctor?.firstName || "") || ""}{" "}
            {/* {capitalizeFirstLetter(doctor?.lastName || "") || ""} */}
          </Typography>

          <Typography
            sx={{
              fontFamily: "var(--neuro-font-family-roboto-slab)",
              fontSize: "var(--neuro-font-size-tiniest)",
              color: "var(--neuro-button-bg-primary)",
              marginTop: "0.188rem",
            }}
          >
            {doctor?.specializations && doctor?.specializations.length > 0
              ? doctor?.specializations
                  .map(
                    (specialization: string) =>
                      specializationMapping[specialization] || specialization
                  )
                  .join(", ")
              : ""}
          </Typography>

          <Typography
            sx={{
              fontWeight: "var(--neuro-font-weight-medium)",
              marginTop: "0.313rem",
              fontSize: "var(--neuro-font-size-smallest-x)",
              color: "var(--neuro-button-bg-primary)",
            }}
          >
            {doctor?.yearsOfExperience} YER EXP.
          </Typography>

          <Typography
            sx={{
              color: "var(--neuro-darkgrey_border)",
              fontSize: "var(--neuro-font-size-smallest-x)",
            }}
          >
            {doctor?.qualifications || ""}
          </Typography>

          <Typography
            sx={{
              fontWeight: "var(--neuro-font-weight-medium)",
              marginTop: "0.125rem",
              fontSize: "var(--neuro-font-size-smallest-x)",
              color: "var(--neuro-bg-darkblue-primary)",
            }}
          >
            Starts at{" "}
            {doctor?.onlineConsultationFee && doctor?.inPersonConsultationFee
              ? Math.min(
                  doctor?.onlineConsultationFee,
                  doctor?.inPersonConsultationFee
                )
              : doctor?.onlineConsultationFee
                ? doctor?.onlineConsultationFee
                : doctor?.inPersonConsultationFee
                  ? doctor?.inPersonConsultationFee
                  : ""}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          paddingInline: isMobile ? "0.938rem" : "1rem",
          paddingBottom: isMobile ? "1.063rem" : "1.125rem",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.75}
          sx={{ mt: "0.75rem" }}
        >
          <MicIcon
            sx={{
              fontSize: "var(--neuro-font-size-extra-small)",
              color: "var(--neuro-bg-darkblue-primary)",
            }}
          />
          {doctor?.spokenLanguages &&
            doctor?.spokenLanguages.length > 0 &&
            doctor?.spokenLanguages.map((language: string, index: number) => (
              <Typography
                key={index}
                sx={{
                  color: "var(--neuro-bg-darkblue-primary)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                }}
              >
                {language}
                {index !== doctor?.spokenLanguages.length - 1 ? "," : null}
              </Typography>
            ))}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ mt: "0.375rem" }}
        >
          <LocationOnIcon
            sx={{
              fontSize: "var(--neuro-font-size-extra-small)",
              color: "var(--neuro-bg-darkblue-primary)",
            }}
          />
          <Typography
            sx={{
              color: "var(--neuro-bg-darkblue-primary)",
              fontSize: "var(--neuro-font-size-smallest-x)",
              lineHeight: "120%",
            }}
          >
            {doctor?.hospital?.streetAddress || ""},{" "}
            {doctor?.hospital?.city || ""}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          flexWrap={isBelow400 ? "wrap" : "initial"}
          spacing={isBelow400 ? 0 : 1.25}
          sx={{ mt: "1.125rem" }}
        >
          <Button
            variant="contained"
            className="success-gradient-button"
            startIcon={<VideocamIcon />}
            // fullWidth={isMobile ? false : true}
            fullWidth
            sx={{
              textTransform: "none",
              padding: "0.375rem 0.625rem !important",
              marginBottom: isBelow400 ? "0.938rem !important" : 0,
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleOnlineVisit(event, doctor)
            }
          >
            Book online meet
          </Button>
          <Button
            variant="contained"
            className="warning-button"
            startIcon={<LocalHospitalIcon />}
            fullWidth
            // fullWidth={isMobile ? false : true}
            sx={{
              textTransform: "none",
              padding: "0.375rem 0.625rem !important",
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleHospitalVisit(event, doctor)
            }
          >
            Book clinic visit
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default DoctorCard;
