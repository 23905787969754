import { useCallback, useEffect, useState } from "react";
import RemirrorEditor from "./RemirrorEditor";

const SymptomAssessment = ({
  assessments,
  id,
}: {
  assessments: any;
  id: string;
}) => {
  const [assessmentHtmlString, setAssessmentHtmlString] =
    useState<string>(assessments);
  const [assessmentContent, setAssessmentContent] = useState<any>(null);

  const extractBodyContent = useCallback((htmlString: string) => {
    const bodyContentMatch = htmlString?.match(
      /<body[^>]*>((.|[\n\r])*)<\/body>/im
    );
    return bodyContentMatch ? bodyContentMatch[1].trim() : "";
  }, []);

  useEffect(() => {
    setAssessmentHtmlString(assessments);
  }, [assessments]);

  useEffect(() => {
    if (assessmentHtmlString && assessmentHtmlString !== "") {
      const innerDoctorSummaryContent =
        extractBodyContent(assessmentHtmlString);
      setAssessmentContent(innerDoctorSummaryContent);
    } else if (!assessmentHtmlString || assessmentHtmlString === "") {
      const innerDoctorSummaryContent =
        "<body><p>No Symptom Assessments</p></body>";
      setAssessmentContent(innerDoctorSummaryContent);
    }
  }, [assessmentHtmlString]);

  return (
    <>
      {assessmentContent && (
        <RemirrorEditor
          htmlContent={assessmentContent}
          summaryType="symptomAssessment"
          setAssessmentHtmlString={setAssessmentHtmlString}
          documentId={id}
        />
      )}
    </>
  );
};

export default SymptomAssessment;
