import * as Yup from "yup";

export const hospitalValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name must be at most 100 characters"),
  streetAddress: Yup.string().required("Street Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string()
    .required("Zip Code is required")
    .matches(/^\d{6}$/, "Zip Code must be exactly 6 digits"),
  country: Yup.string().required("Country is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required")
    .matches(/^[0-9]+$/, "Contact Number must be numeric")
    .min(10, "Contact Number must be exactly 10 digits")
    .max(10, "Contact Number must be exactly 10 digits"),
  operatingHours: Yup.string().optional(),
  latitude: Yup.number().required("Latitude is required"),
  longitude: Yup.number().required("Longitude is required"),
  timezone: Yup.string().required("Timezone is required"),
  currency: Yup.string().required("Currency is required"),
  website: Yup.string().url("Website URL is not valid").optional(), // Make website optional
  email: Yup.string().email("Email is not valid").required("Email is required"), // Make email optional
});
