import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { isClinicAdminOrAdmin, isDoctor } from "../../../utils/auth";
import {
  getAppointmentStatus,
  getFullHospitalAddress,
  getTimeDiffereForOngoing,
  handleOpenMap,
} from "../../../utils/appointments";
import Button from "../../ui/Button";
import LocationIcon from "../../svg/LocationIcon";
import VideoCallIcon from "../../svg/VideoCallIcon";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import PrescriptionButton from "./PrescriptionButton";
import {
  CANCELLED,
  COMPLETED,
  IN_PERSON,
  ONGOING,
  UPCOMING,
} from "../../../utils/constants";
import { NewAppointmentCardIconsProps } from "../../../types/appointments";
import Tooltip from "../../ui/Tooltip";
import { formatMinutesToHHMM } from "../../../utils/common";

const NewAppointmentCardButtons: React.FC<NewAppointmentCardIconsProps> = ({
  appointmentCode,
  id,
  userRole,
  appointmentMode,
  status,
  availableSlot,
  paymentStatus,
  doctor,
  user,
  prescription,
}) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const styles = {
    meetButtonStyles: {
      fontSize: "var(--neuro-font-size-smaller-plus)",
      textTransform: "none",
      height: "2.25rem",
      fontFamily: "var(--neuro-font-family-roboto)",
    },
  };

  const navigate = useNavigate();

  const dateString = availableSlot?.startTime;

  const meetButtonText = isDoctor(userRole)
    ? "Meet with patient"
    : "Meet with doctor";

  const isOngoingSoon = useMemo(
    () => getTimeDiffereForOngoing(dateString) <= config?.MEETING_ENABLE_TIME,
    [dateString]
  );

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const showDirectionsButton =
    appointmentMode === IN_PERSON &&
    (appointmentStatus === ONGOING || appointmentStatus === UPCOMING) &&
    status !== CANCELLED;

  const showMeetButton = () => {
    if (appointmentMode === IN_PERSON) {
      if (
        (appointmentStatus === UPCOMING || appointmentStatus === ONGOING) &&
        isDoctor(userRole) &&
        status !== CANCELLED &&
        status !== COMPLETED
      ) {
        return true;
      }
      return false;
    } else {
      if (
        (appointmentStatus === UPCOMING || appointmentStatus === ONGOING) &&
        status !== CANCELLED &&
        status !== COMPLETED
      ) {
        return true;
      }
      return false;
    }
  };

  // callbacks & functions
  const handleViewMap = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, doctor: any) => {
      event.stopPropagation();
      handleOpenMap(getFullHospitalAddress(doctor?.defaultClinic));
    },
    []
  );

  const handleMeetingJoin = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLElement>,
      id: string
    ) => {
      event.stopPropagation();
      navigate(`/meet/${id}`, {
        state: { appointmentMode: appointmentMode },
      });
    },
    [navigate]
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        width: "max-content",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showMeetButton() &&
        (isMobile ? (
          <>
            {!isOngoingSoon ? (
              paymentStatus === "PENDING" ? (
                <Tooltip
                  title={
                    isDoctor(userRole) && doctor?.id === user?.doctorId
                      ? "Payment not completed by patient"
                      : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                        ? "You are not a participant in this meet"
                        : "Please complete the payment to meet with doctor"
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <IconButton
                      disableRipple
                      sx={{
                        padding: 0,
                        backgroundColor: "transparent",
                        "& :hover": {
                          padding: 0,
                          backgroundColor: "transparent",
                        },
                      }}
                      disabled={true}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <VideoCallIcon height="1.5rem" />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    isDoctor(userRole) && doctor?.id === user?.doctorId
                      ? `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                      : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                        ? "You are not a participant in this meet"
                        : `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <IconButton
                      disableRipple
                      sx={{
                        padding: 0,
                        backgroundColor: "transparent",
                        "& :hover": {
                          padding: 0,
                          backgroundColor: "transparent",
                        },
                      }}
                      disabled={true}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <VideoCallIcon height="1.5rem" />
                    </IconButton>
                  </Box>
                </Tooltip>
              )
            ) : paymentStatus === "PENDING" ? (
              <Tooltip
                title={
                  isDoctor(userRole) && doctor?.id === user?.doctorId
                    ? "Payment not completed by patient"
                    : isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ? "You are not a participant in this meet"
                      : "Please complete the payment to meet with doctor"
                }
              >
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span
                    style={{ width: 0 }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  ></span>
                  <IconButton
                    disableRipple
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      "& :hover": {
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                    }}
                    disabled={true}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                    }}
                  >
                    <VideoCallIcon height="1.5rem" />
                  </IconButton>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  isClinicAdminOrAdmin(userRole) ||
                  (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ? "You are not a participant in this meet"
                    : ""
                }
              >
                <Box
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (
                      isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ) {
                      event.stopPropagation();
                    } else {
                      handleMeetingJoin(event, appointmentCode);
                    }
                  }}
                >
                  {(isClinicAdminOrAdmin(userRole) ||
                    (isDoctor(userRole) && doctor?.id !== user?.doctorId)) && (
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                  )}
                  <IconButton
                    disableRipple
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      "& :hover": {
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                    }}
                    disabled={
                      isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    }
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (
                        isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ) {
                        event.stopPropagation();
                      } else {
                        handleMeetingJoin(event, appointmentCode);
                      }
                    }}
                  >
                    <VideoCallIcon height="1.5rem" />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {!isOngoingSoon ? (
              paymentStatus === "PENDING" ? (
                <Tooltip
                  title={
                    isDoctor(userRole) && doctor?.id === user?.doctorId
                      ? "Payment not completed by patient"
                      : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                        ? "You are not a participant in this meet"
                        : "Please complete the payment to meet with doctor"
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={true}
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    isDoctor(userRole) && doctor?.id === user?.doctorId
                      ? `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                      : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                        ? "You are not a participant in this meet"
                        : `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={true}
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Box>
                </Tooltip>
              )
            ) : paymentStatus === "PENDING" ? (
              <Tooltip
                title={
                  isDoctor(userRole) && doctor?.id === user?.doctorId
                    ? "Payment not completed by patient"
                    : isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ? "You are not a participant in this meet"
                      : "Please complete the payment to meet with doctor"
                }
              >
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span
                    style={{ width: 0 }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  ></span>
                  <Button
                    startIcon={<VideoCallIcon />}
                    disabled={true}
                    color="secondary"
                    variant="outlined"
                    className="outlined-secondary-button"
                    sx={styles.meetButtonStyles}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                    }}
                  >
                    {meetButtonText}
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  isClinicAdminOrAdmin(userRole) ||
                  (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ? "You are not a participant in this meet"
                    : ""
                }
              >
                <Box
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (
                      isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ) {
                      event.stopPropagation();
                    } else {
                      handleMeetingJoin(event, appointmentCode);
                    }
                  }}
                >
                  {(isClinicAdminOrAdmin(userRole) ||
                    (isDoctor(userRole) && doctor?.id !== user?.doctorId)) && (
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                  )}
                  <Button
                    startIcon={<VideoCallIcon />}
                    disabled={
                      isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    }
                    color="secondary"
                    variant="outlined"
                    className="outlined-secondary-button"
                    sx={styles.meetButtonStyles}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (
                        isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ) {
                        event.stopPropagation();
                      } else {
                        handleMeetingJoin(event, appointmentCode);
                      }
                    }}
                  >
                    {meetButtonText}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </>
        ))}

      {showDirectionsButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleViewMap(event, doctor)
            }
          >
            <LocationIcon />
          </IconButton>
        ) : (
          <Button
            startIcon={<LocationIcon />}
            color="secondary"
            variant="outlined"
            className="outlined-secondary-button"
            sx={{
              fontSize: "var(--neuro-font-size-smaller-plus)",
              textTransform: "none",
              // width: 130,
              height: "2.25rem",
              fontFamily: "var(--neuro-font-family-roboto)",
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleViewMap(event, doctor)
            }
          >
            Directions
          </Button>
        ))}

      <PrescriptionButton key={id} id={id} prescription={prescription} />
    </Box>
  );
};

export default NewAppointmentCardButtons;
