const Empolyee = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 50 50"
    fill="none"
    {...props}
  >
    <g id="Group 2311">
      <g id="Group 2312">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9396 21.0569C9.05 21.5814 6.36458 22.9738 4.25833 25.078C1.53125 27.8025 0 31.4969 0 35.3495V43.756C0 47.2048 2.79792 50 6.25 50C14.9667 50 35.0333 50 43.75 50C47.2021 50 50 47.2048 50 43.756V35.3495C50 31.4969 48.4688 27.8025 45.7417 25.078C43.6354 22.9738 40.95 21.5814 38.0604 21.0569C39.0354 19.1025 39.5833 16.9005 39.5833 14.5694C39.5833 6.52916 33.0479 0 25 0C16.9521 0 10.4167 6.52916 10.4167 14.5694C10.4167 16.9005 10.9646 19.1025 11.9396 21.0569ZM35.2083 24.9698C32.5792 27.5486 28.9729 29.1387 25 29.1387C21.0271 29.1387 17.4208 27.5486 14.7917 24.9698C14.7083 24.9802 14.625 24.9844 14.5417 24.9844C11.7896 24.9844 9.15 26.0771 7.20625 28.0211C5.26042 29.965 4.16667 32.6021 4.16667 35.3495V43.756C4.16667 44.9049 5.1 45.8373 6.25 45.8373H43.75C44.9 45.8373 45.8333 44.9049 45.8333 43.756V35.3495C45.8333 32.6021 44.7396 29.965 42.7938 28.0211C40.85 26.0771 38.2104 24.9844 35.4583 24.9844C35.375 24.9844 35.2917 24.9802 35.2083 24.9698ZM25.0417 4.16268C30.7917 4.16268 35.4583 8.82696 35.4583 14.5694C35.4583 20.3139 30.7917 24.9761 25.0417 24.9761C19.2917 24.9761 14.625 20.3139 14.625 14.5694C14.625 8.82696 19.2917 4.16268 25.0417 4.16268Z"
          fill="#868686"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3332 33.4058H31.2498C30.0998 33.4058 29.1665 34.3383 29.1665 35.4872C29.1665 36.6361 30.0998 37.5685 31.2498 37.5685H33.3332V39.6499C33.3332 40.7988 34.2665 41.7312 35.4165 41.7312C36.5665 41.7312 37.4998 40.7988 37.4998 39.6499V37.5685H39.5832C40.7332 37.5685 41.6665 36.6361 41.6665 35.4872C41.6665 34.3383 40.7332 33.4058 39.5832 33.4058H37.4998V31.3245C37.4998 30.1756 36.5665 29.2432 35.4165 29.2432C34.2665 29.2432 33.3332 30.1756 33.3332 31.3245V33.4058Z"
          fill="#868686"
        />
      </g>
    </g>
  </svg>
);
export default Empolyee;
