import { Box, useMediaQuery } from "@mui/material";
import DoctorPersonalInfoForm from "./DoctorPersonalInfoForm";
import useAppStore from "../../store/appStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { isDoctor, isDoctorOrNetwork } from "../../utils/auth";
import { getProfileApi } from "../../services/profileService";
import useProfileStore from "../../store/profileStore";
import LoadingScreen from "../shared/LoadingScreen";

const DoctorPersonalInfoFormPage = () => {
  // props & state values
  const {
    selectedDoctorInList,
    isMenuCollapsed,
    setDrawerOpen,
    setIsMenuCollapsed,
    showDoctorSubmenus,
    setSelectedDoctorInList,
    setShowDoctorSubmenus,
  } = useAppStore();
  const isBelow800 = useMediaQuery("(max-width:50rem)");
  const navigate = useNavigate();

  const {
    profileDetails,
  } = useProfileStore();

  const { userRole, user } = useAuthStore();

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  // callbacks & functions
  useEffect(() => {
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    const getProifileInfo = async () => {
      const doctorId = user?.doctorId;

      if (doctorId && isDoctor(userRole)) {
        try {
          const response = await getProfileApi(doctorId, "DOCTOR");
          console.log("profileDetails response inpersonal profile page for doctor", response);

          if (response?.data) {
            setShowDoctorSubmenus(true);
            setSelectedDoctorInList(response.data);
          }
        }
        catch (error) {
          navigate(`/dashboard`);

        }
      }
      else {
        try {
          const response = await getProfileApi();
          console.log("profileDetails response inpersonal profile page for network", response);

          if (response?.data) {
            setShowDoctorSubmenus(true);
            setSelectedDoctorInList(response.data);
          }
        }
        catch (error) {
          navigate(`/dashboard`);

        }
      }
    }

    if (!showDoctorSubmenus) {
      if (isDoctorOrNetwork(userRole)) {
        if (profileDetails) {
          console.log("profileDetails inpersonal profile page", profileDetails);
          setShowDoctorSubmenus(true);
          setSelectedDoctorInList(profileDetails);
        }
        else {
          getProifileInfo();
        }
      }
      else {
        navigate(`/doctors`);
      }
    }
  }, [showDoctorSubmenus]);

  console.log("selectedDoctorInList in personal profile", selectedDoctorInList);

  const hanleClose = () => {
    setSelectedDoctorInList(null);
    setShowDoctorSubmenus(false);
    if (isDoctorOrNetwork(userRole)) {
      navigate(`/dashboard`);
    }
    else {
      navigate(`/doctors`);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      {isDoctorOrNetwork(userRole) ?
        <>
          {!selectedDoctorInList ? <LoadingScreen /> :
            <DoctorPersonalInfoForm
              doctor={selectedDoctorInList}
              onClose={hanleClose}
            />
          }
        </>
        :
        <DoctorPersonalInfoForm
          doctor={selectedDoctorInList}
          onClose={hanleClose}
        />}
    </Box>
  );
};

export default DoctorPersonalInfoFormPage;
