import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import Modal from "../ui/Modal";
import Input from "../ui/Input";
import Spinner from "../ui/Spinner";
import AadharOtpModal from "./AadharOtpModal";
import { sendAadharOtp } from "../../services/authService";
import useAppStore from "../../store/appStore";

// Define validation schema for Yup
const schema = yup.object().shape({
  aadharNumber: yup.string().required("Aadhaar number is required"),
});

interface FormInputs {
  aadharNumber: string;
}

const AadharModal: React.FC<{
  open: boolean;
  onClose: () => void;
  userId?: string;
}> = ({ open, onClose, userId, ...props }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const { showSnackbar } = useAppStore();
  const [loading, setLoading] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otpToken, setOtpToken] = useState(null);
  const [aadharNumber, setAadharNumber] = useState<string | null>(null);
  const [message, setMessage] = useState("");

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoading(true);
    // Form submission logic here
    console.log("Aadhaar Number:", data.aadharNumber);
    setAadharNumber(data.aadharNumber);
    try {
      const result = await sendAadharOtp(data.aadharNumber || "");
      console.log("result in send otp aadhaar", result);
      setMessage(result?.message || "");
      setOtpToken(result?.txnId || "");
      setOtpModalOpen(true);
      onClose();
      console.log("result in send aahar otp", result);
    } catch (error: any) {
      console.log("error", error);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not send otp, please try again",
        "error"
      );
      console.error("Could not send otp");
    }
    setLoading(false);
  };

  const handleOtpModalClose = () => {
    setOtpModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
        width={isMobile ? "20rem" : "31.25rem"} // Adjust width
        //   height={isMobile ? undefined : 450} // Adjust height
        {...props}
      >
        <Box
          sx={{
            paddingInline: "1.25rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mb: "2rem",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontSize: "var(--neuro-font-size-large-medium)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              lineHeight: "120%",
            }}
          >
            Aadhaar
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%" }}
          >
            <Input
              type="text"
              // className="input-secondary"
              className="input-primary"
              // placeholder="XXXX - XXXX - XXXX"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
              label="Enter Aadhaar Number*"
              {...register("aadharNumber")}
              error={!!errors.aadharNumber}
              helperText={errors.aadharNumber?.message}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "2rem",
                mb: "1rem",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                className="success-button"
                color="success"
                disabled={loading}
                sx={{
                  minWidth: "9.375rem",
                  height: "2.5rem",

                  textTransform: "none",
                }}
              >
                {loading ? <Spinner /> : "Get OTP"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <AadharOtpModal
        open={otpModalOpen}
        onClose={handleOtpModalClose}
        aadharNumber={aadharNumber || ""}
        otpToken={otpToken || ""}
        setOtpToken={setOtpToken}
        message={message}
        setMessage={setMessage}
        userId={userId}
      />
    </>
  );
};

export default AadharModal;
