import api from "./apiService"; // Assuming `api` is your Axios instance

export const fetchHospitals = (
  start?: string | null,
  end?: string | null,
  search?: string
) => {
  let params;
  if (start && end) {
    params = {
      start,
      end,
      search: search || "",
    };
  }

  params = {
    ...params,
    search: search || "",
  };
  return api.get(`/api/hospitals`, { params });
};

export const fetchHospitalById = (id: string) => {
  return api.get(`/api/hospitals/${id}`);
};

export const addHospital = (hospitalData: any) => {
  return api.post(`/api/hospitals`, hospitalData);
};

export const updateHospital = (id: string, hospitalData: any) => {
  return api.put(`/api/hospitals/${id}`, hospitalData);
};

export const deleteHospital = (id: string) => {
  return api.delete(`/api/hospitals/${id}`);
};
