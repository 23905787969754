import { Box, Link } from "@mui/material";
import React, { useState } from "react";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import { GeneralDetailsProps } from "../../../types/appointments";
import HorizontalTabs from "../../profile/patient/HorizontalTabs";
import PatientTabs from "../../patients/PatientTabs";
import useAppStore from "../../../store/appStore";

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ patientDetails }) => {
  // Props & state values
  const tabs = [
    "Profile",
    "Documents",
    "Appointments",
    "Forms",
    "ABHA Details",
  ];

  const [selectedTab, setSelectedTab] = useState<string>("Profile");
  const { setAppointmentConferenceView, setDocumentsConferenceView } =
    useAppStore();
  const [showMore, setShowMore] = useState(false);

  const selectedPatient = patientDetails;

  console.log("patientDetails", patientDetails);
  // callbacks & functions
  const onTabClick = (tab: string) => {
    if (tab === "Appointments") {
      setAppointmentConferenceView("List");
    }
    if (tab === "Documents") {
      setDocumentsConferenceView("List");
    }
    setSelectedTab(tab);
  };

  return (
    <Box
      sx={{
        marginBottom: "1.188rem",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        height: showMore ? "calc(100% + 6.25rem)" : "90%",
      }}
    >
      <Box
        sx={{
          paddingBottom: "1.188rem",
          borderBottom: "1px dashed var(--neuro-light-grey_border)",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--neuro-bg-light-grey-secondary)",
            height: "100%",
            paddingInline: "0.625rem",
            paddingBlock: "0.938rem",
            display: "flex",
            flexDirection: "column",
            // gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "0.625rem",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <HorizontalTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onClick={onTabClick}
              from="meet"
            />
          </Box>

          <PatientTabs
            selectedPatient={selectedPatient}
            selectedTab={selectedTab}
            patientName={`${
              capitalizeWordsPreservingSpaces(patientDetails?.name || "") || ""
            }`}
            patientId={patientDetails?.id || ""}
            userId={patientDetails?.userId || ""}
            patientContact={patientDetails?.mobile || ""}
            patientEmail={patientDetails?.email || ""}
            from="meet"
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Link
          sx={{
            width: "max-content",
          }}
          className="universal-link"
          onClick={() => setShowMore((prev: boolean) => !prev)}
        >
          {showMore ? "Show less" : "Show more"}
        </Link>
      </Box>
    </Box>
  );
};

export default GeneralDetails;
