import React from "react";
import { Box, Typography } from "@mui/material";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import { useNavigate } from "react-router-dom";
import { DoYouKnowDisorderModalProps } from "../../../types/appointments";

const DoYouKnowDisorderModal: React.FC<DoYouKnowDisorderModalProps> = ({
  open,
  onClose,
  width,
  closeIcon,
  ...props
}) => {
  // props & state values
  const navigate = useNavigate();

  // callbacks & functions
  const handleYes = () => {
    navigate("/appointments/new/patient-disorder");
  };

  const handleNo = () => {
    navigate("/appointments/new/forms/general-form");
  };

  return (
    <Modal open={open} onClose={onClose} width={width ? width : 600} {...props}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "40px",
          paddingBlock: "50px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "40px",
            lineHeight: "120%",
            fontFamily: "Inter",
            color: "var(--neuro-black-text)",
            textAlign: "center",
          }}
        >
          Do you know your disorder?
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: "120%",
            fontFamily: "Inter",
            color: "var(--neuro-black-text)",
            textAlign: "center",
            marginTop: "12px",
            paddingInline: "60px",
          }}
        >
          Patient knows disorder click YES. If patient doesn’t know disorder
          click NO.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "24px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            className="secondary-button"
            sx={{ width: 100, fontSize: "22px", fontWeight: 600 }}
            onClick={handleYes}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            className="secondary-button"
            sx={{ width: 100, fontSize: "22px", fontWeight: 600 }}
            onClick={handleNo}
          >
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DoYouKnowDisorderModal;
