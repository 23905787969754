import React from "react";

// third-party imports
import { Box, Typography } from "@mui/material";

// project imports
import Logo from "../../shared/Logo";
import { AppointmentCheckoutDetailsProps } from "../../../types/appointments";

const AppointmentDetails: React.FC<AppointmentCheckoutDetailsProps> = ({
  appointmentType,
  appointmentDate,
  doctorName,
  doctorSpeciality,
  doctorExperience,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "1px solid var(--neuro-secondary_border)",
        backgroundColor: "var(--neuro-white-text)",
        padding: "25px 20px 11px",
        marginTop: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            backgroundColor: "var(--neuro-bg-light-grey-secondary)",
            border: "2px solid var(--neuro-button-bg-success)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          profile
        </Box>
        <Box sx={{ flexGrow: 1, marginLeft: "14px" }}>
          <Typography
            sx={{
              color: "var(--neuro-bg-darkblue-primary)",
              font: "600 18px Inter, sans-serif",
              margin: 0,
            }}
          >
            {doctorName}
          </Typography>
          <Typography
            sx={{
              color: "var(--neuro-button-bg-primary)",
              fontFamily: "Roboto Slab, sans-serif",
              fontSize: "13px",
              lineHeight: "120%",
            }}
          >
            {doctorSpeciality}
          </Typography>
          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              fontFamily: "Roboto Slab, sans-serif",
              marginTop: "12px",
              fontSize: "13px",
            }}
          >
            {doctorExperience}
          </Typography>
        </Box>
        <Logo height={63} width={89} />
      </Box>
      <Box
        sx={{
          borderTop: "1px solid var(--neuro-secondary_border)",
          marginTop: "23px",
          paddingTop: "16px",
          display: "flex",
        }}
      >
        <Box sx={{ paddingTop: "18px", paddingLeft: "14px", flex: 0.5 }}>
          <Typography
            sx={{ color: "var(--neuro-black-text)", lineHeight: "120%" }}
          >
            Type:
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "120%",
              color: "var(--neuro-black-text)",
            }}
          >
            {appointmentType === "ONLINE" ? "Online Meet" : "Offline Meet"}
          </Typography>
        </Box>
        <Box
          sx={{ flex: 0.5, display: "flex", gap: "28px", alignItems: "center" }}
        >
          <Box
            sx={{
              borderLeft: "1px solid var(--neuro-secondary_border)",
              height: "80px",
            }}
          />
          <Box>
            <Typography
              sx={{ color: "var(--neuro-black-text)", lineHeight: "120%" }}
            >
              Appointment Date:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto Condensed, sans-serif",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "120%",
                color: "var(--neuro-black-text)",
              }}
            >
              {appointmentDate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentDetails;
