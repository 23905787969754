import React from "react";
import {
  Box,
  Typography,
  Divider,
  //  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface AppointmentData {
  name: string;
  icon: React.ReactNode;
  text?: string;
  count?: string | number; // Added count property type
  navigateTo: string;
  appointmentsTotalCount?: string | number | undefined;
  action: () => void;
}

interface DashboardCardcopyProps {
  appointmentData: AppointmentData[];
}

const DashboardCardcopy1: React.FC<DashboardCardcopyProps> = ({
  appointmentData,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "16px",
        padding: "0.5rem",
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        // alignItems: 'center',
        width: "100%",
        maxWidth: "312px",
      }}
    >
      <Box
        sx={{
          borderRadius: "12px",
          bgcolor: "var(--Primary-Neuro_green, #62B55A);",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "8px 58px",
          fontWeight: 400,
          color: "common.white",
          borderColor: "primary.main",
          border: "1px solid",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            WebkitTextStroke: "1px #62b55a",
          }}
        >
          Appointments
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontSize: "40px",
            marginTop: "13px",
          }}
          onClick={() => {
            appointmentData[3]?.action(); // Trigger action without parameters
            navigate(appointmentData[3]?.navigateTo); // Navigate to the specified route
          }}
        >
          {appointmentData[3]?.appointmentsTotalCount}
        </Typography>
      </Box>

      {appointmentData.map(
        (appointment, index) =>
          index !== 3 && ( // Exclude index 3 from rendering
            <React.Fragment key={appointment.name}>
              <Box
                onClick={() => {
                  appointment.action(); // Trigger action function
                  navigate(appointment.navigateTo); // Navigate to the specified route
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "266px",
                  gap: "0.2rem",
                  marginTop: "0.2rem",
                  padding: "11px 11px 20px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: 1.2,
                  color: "text.primary",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "12px",
                  }}
                >
                  {appointment.text}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 300,
                    lineHeight: 1.2,
                    textAlign: "right",
                    color: "text.primary",
                  }}
                >
                  {appointment.count}
                </Typography>
              </Box>
              {index < appointmentData.length - 2 && (
                <Divider
                  sx={{
                    bgcolor: "divider",
                    marginTop: "18px",
                    width: "266px",
                    maxWidth: "100%",
                    height: "1px",
                    border: "1px solid",
                    borderColor: "var(--Primary-Neuro_green, #62B55A);",
                  }}
                />
              )}
            </React.Fragment>
          )
      )}

      {/* <Box
        sx={{
          display: 'flex',
          marginTop: '25px',
          width: '100%',
          maxWidth: '266px',
          gap: '20px',
          justifyContent: 'space-between',
          fontSize: '16px',
          color: 'primary.main',
          fontWeight: 400,
          lineHeight: 1.2,
        }}
      >
        <Button
          variant="text"
          sx={{
            color: 'primary.main',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 400,
          }}
          onClick={() => navigate('/appointments')} // Example: View all appointments route
        >
          View all appointments
        </Button>
      </Box> */}
    </Box>
  );
};

export default DashboardCardcopy1;
