import { isClinicAdminOrAdmin, isPatient } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import { useState } from "react";

const DoctorName = ({
  id,
  name,
  from = "",
}: {
  id: string;
  name: string;
  from?: string;
  noMarginTop?: boolean;
}) => {
  // props & state value
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  const [isHovered, setIsHovered] = useState(false);

  const spanStyle = {
    color: isHovered
      ? "var(--neuro-bg-primary-seagreen)"
      : "var(--neuro-black-text)",
    cursor: "pointer",
    alignSelf: "stretch",
  };

  const isPatientOrAdmin =
    isPatient(userRole) || isClinicAdminOrAdmin(userRole);

  return (
    <span
      style={spanStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(event) => {
        if (from === "meet") {
          return;
        } else {
          event.stopPropagation();
          if (isPatientOrAdmin) {
            navigate(`/doctors/${id || ""}/details`);
          }
        }
      }}
    >
      {name}
    </span>
  );
};

export default DoctorName;
