import { Box } from "@mui/material";

const CustomDrawer = ({ open, children }: { open: boolean; children: any }) => {
  return (
    <Box>
      {open && (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            width: "13.75rem",
            height: "calc(100% - 3.75rem)",
            backgroundColor: "white",
            zIndex: 100,
            transition: "transform 0.3s ease-in-out",
            overflow: "hidden",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default CustomDrawer;
