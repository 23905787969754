const MorningIcon = (props: any) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7045 11C15.7045 13.5972 13.599 15.7028 11.0017 15.7028C8.40437 15.7028 6.29883 13.5972 6.29883 11C6.29883 8.40266 8.40437 6.29712 11.0017 6.29712C13.599 6.29712 15.7045 8.40266 15.7045 11Z"
      fill="#FAC43A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0017 0.888916C11.3913 0.888916 11.7072 1.20475 11.7072 1.59434V3.47548C11.7072 3.86507 11.3913 4.18091 11.0017 4.18091C10.6122 4.18091 10.2963 3.86507 10.2963 3.47548V1.59434C10.2963 1.20475 10.6122 0.888916 11.0017 0.888916ZM0.890625 11C0.890625 10.6104 1.20646 10.2946 1.59605 10.2946H3.47719C3.86678 10.2946 4.18261 10.6104 4.18261 11C4.18261 11.3896 3.86678 11.7055 3.47719 11.7055H1.59605C1.20646 11.7055 0.890625 11.3896 0.890625 11ZM17.8209 11C17.8209 10.6104 18.1367 10.2946 18.5263 10.2946H20.4074C20.797 10.2946 21.1128 10.6104 21.1128 11C21.1128 11.3896 20.797 11.7055 20.4074 11.7055H18.5263C18.1367 11.7055 17.8209 11.3896 17.8209 11ZM11.0017 17.8191C11.3913 17.8191 11.7072 18.135 11.7072 18.5246V20.4057C11.7072 20.7953 11.3913 21.1111 11.0017 21.1111C10.6122 21.1111 10.2963 20.7953 10.2963 20.4057V18.5246C10.2963 18.135 10.6122 17.8191 11.0017 17.8191Z"
      fill="#FAC43A"
    />
    <g opacity={0.5}>
      <path
        d="M3.16528 3.20794C3.42817 2.92041 3.87437 2.90044 4.1619 3.16333L6.25187 5.07417C6.5394 5.33706 6.55937 5.78326 6.29648 6.07079C6.03359 6.35833 5.58738 6.3783 5.29986 6.11541L3.2099 4.20457C2.92237 3.94168 2.90239 3.49547 3.16528 3.20794Z"
        fill="#FAC43A"
      />
      <path
        d="M18.8375 3.20794C19.1004 3.49547 19.0805 3.94168 18.7929 4.20457L16.7029 6.11541C16.4154 6.3783 15.9692 6.35833 15.7063 6.07079C15.4434 5.78326 15.4634 5.33706 15.7509 5.07417L17.8409 3.16333C18.1284 2.90044 18.5746 2.92041 18.8375 3.20794Z"
        fill="#FAC43A"
      />
      <path
        d="M15.7282 15.7261C16.0036 15.4506 16.4502 15.4507 16.7257 15.7262L18.8157 17.8164C19.0912 18.0919 19.0912 18.5385 18.8157 18.814C18.5402 19.0895 18.0935 19.0895 17.818 18.8139L15.7281 16.7238C15.4526 16.4482 15.4527 16.0016 15.7282 15.7261Z"
        fill="#FAC43A"
      />
      <path
        d="M6.27486 15.7262C6.55035 16.0016 6.55035 16.4482 6.27486 16.7237L4.18471 18.8139C3.90922 19.0894 3.46257 19.0894 3.18709 18.8139C2.9116 18.5384 2.9116 18.0918 3.18709 17.8163L5.27724 15.7261C5.55272 15.4507 5.99938 15.4507 6.27486 15.7262Z"
        fill="#FAC43A"
      />
    </g>
  </svg>
);
export default MorningIcon;
