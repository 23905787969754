import { useCallback, useEffect, useState } from "react";
import RemirrorEditor from "./RemirrorEditor";

const PatientSummary = ({
  summary,
  id,
  isPatient = false,
}: {
  summary: any;
  id: string;
  isPatient?: boolean;
}) => {
  const [patientSummaryHtmlString, setPatientSummaryHtmlString] =
    useState<string>(summary);
  const [patientSummaryContent, setPatientSummaryContent] = useState<any>(null);

  const extractBodyContent = useCallback((htmlString: string) => {
    const bodyContentMatch = htmlString?.match(
      /<body[^>]*>((.|[\n\r])*)<\/body>/im
    );
    return bodyContentMatch
      ? bodyContentMatch[1].trim()
      : "<body><p>No Patient Summary</p></body>";
  }, []);

  useEffect(() => {
    setPatientSummaryHtmlString(summary);
  }, [summary]);

  useEffect(() => {
    if (patientSummaryHtmlString && patientSummaryHtmlString !== "") {
      const innerPatientSummaryContent = extractBodyContent(
        patientSummaryHtmlString
      );
      setPatientSummaryContent(innerPatientSummaryContent);
    } else if (!patientSummaryHtmlString || patientSummaryHtmlString === "") {
      const innerPatientSummaryContent =
        "<body><p>No Patient Summary</p></body>";
      setPatientSummaryContent(innerPatientSummaryContent);
    }
  }, [patientSummaryHtmlString]);

  return (
    <>
      {patientSummaryContent && (
        <RemirrorEditor
          htmlContent={patientSummaryContent}
          summaryType="patient"
          setPatientSummaryHtmlString={setPatientSummaryHtmlString}
          documentId={id}
          isEditable={isPatient ? false : true}
        />
      )}
    </>
  );
};

export default PatientSummary;
