import React from "react";
import { Typography, Card, CardContent, Box } from "@mui/material";
import { getFormattedDateTime } from "../../../utils/appointments";
import PdfViewer from "./PdfViewer";
import DicomViewer from "./DicomViewer";
import AudioThumbnail from "./AudioThumbnail";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import useDocumentStore from "../../../store/documentsStore";
import useAppStore from "../../../store/appStore";
import useAuthStore from "../../../store/authStore";
import { isClinicAdminOrAdmin } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";

interface DocumentThumbnailCardProps {
  id: string;
  name: string;
  reportDate: any;
  createdAt: any;
  fileType: string;
  filePath: any;
  patientId: any;
  isFromMeet?: boolean;
  from?: string;
}

const DocumentThumbnailView: React.FC<DocumentThumbnailCardProps> = ({
  id,
  name,
  reportDate,
  fileType,
  createdAt,
  filePath,
  patientId,
  isFromMeet = false,
  from = "",
}) => {
  // props & state values
  const { setSelectedDocumentId } = useDocumentStore();
  const { setDocumentsConferenceView } = useAppStore();
  const { userRole } = useAuthStore();
  const navigate = useNavigate();

  const reportDateObj = getFormattedDateTime(reportDate);
  const createdAtObj = getFormattedDateTime(createdAt);

  const styles = {
    reportDateStyles: {
      fontSize: "var(--neuro-font-size-x-small)",
      lineHeight: "120%",
      fontFamily: "var(--neuro-font-family-roboto-slab)",
    },
    fileName: {
      // fontWeight: "var(--neuro-font-weight-bold)",
      fontSize: "var(--neuro-font-size-smaller-plus)",
      lineHeight: "120%",
      color: "var(--neuro-bg-darkblue-primary)",
      fontFamily: "var(--neuro-font-family-roboto-slab)",
    },
  };

  // callbacks & functions
  const FileTypeThumbnail: React.FC<{ fileType: string; url: any }> = ({
    fileType,
    url,
  }) => {
    const iconStyles = {
      width: "9.375rem",
      height: "9.375rem",
      fill: "var(--neuro-svg-error)",
      color: "var(--neuro-svg-error)",
      display: "block",
    };

    const wrapperStyles = {
      width: "9.375rem",
      height: "9.375rem",
      overflow: "hidden",
    };

    const fileTypeIconMap: { [key: string]: JSX.Element } = {
      PDF: <PdfViewer pdfUrl={url} isThumbnail />,
      DICOM: <DicomViewer dicomUrl={url} isThumbnail />,
      AUDIO: <AudioThumbnail audioUrl={url} />,
      ZIP: <FolderZipIcon sx={iconStyles} />,
    };

    return (
      fileTypeIconMap[fileType] || (
        <Box sx={wrapperStyles}>
          <img
            src={url}
            alt="document"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensures the image covers the thumbnail area
            }}
          />
        </Box>
      )
    );
  };

  const handleCardClick = (id: string) => {
    setSelectedDocumentId(id);
    if (from === "list" && !patientId) {
      navigate("/documents/view");
    }
    if (patientId) {
      if (isFromMeet) {
        setDocumentsConferenceView("Details");
      } else {
        if (isClinicAdminOrAdmin(userRole)) {
          navigate(`/patients/${patientId}/documents/view`);
        } else {
          navigate(`/appointments/patient/${patientId}/documents/view`);
        }
      }
    }
    // if (from === "conference" && onClick) {
    //   onClick();
    // }
  };

  return (
    <Card
      sx={{
        borderRadius: "0.625rem",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: "0.5rem",
        boxShadow: "none",
        cursor: "pointer",
      }}
      onClick={() => handleCardClick(id)}
    >
      <FileTypeThumbnail fileType={fileType} url={filePath} />
      <CardContent sx={{ p: "0rem !important" }}>
        <Typography sx={styles.fileName}>{name}</Typography>
        <Typography sx={styles.reportDateStyles}>
          Date:{" "}
          {reportDateObj
            ? `${reportDateObj.day}-${reportDateObj.monthNumber}-${reportDateObj.year}`
            : ""}
        </Typography>
        <Typography sx={styles.reportDateStyles}>
          Upload At:{" "}
          {createdAtObj
            ? `${createdAtObj.day}-${createdAtObj.monthNumber}-${createdAtObj.year}`
            : ""}
        </Typography>
        <Typography sx={styles.reportDateStyles}>
          Type: {fileType.toUpperCase()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DocumentThumbnailView;
