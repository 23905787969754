import React, { useCallback, useEffect, useState } from "react";

// third-party imports
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// project imports
import DisorderInfo from "./DisorderInfo";
import DisorderList from "./DisorderList";
import SearchInput from "../../ui/SearchInput";
import { Disorder } from "../../../types/appointments";

const PatientDisorder = () => {
  // props & state values
  const theme = useTheme();
  const navigate = useNavigate();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownXl = useMediaQuery(theme.breakpoints.down("xl"));

  const disorders: Disorder[] = [
    { disorderName: "Headache", disorderInfo: "Headache Info" },
    { disorderName: "Stroke", disorderInfo: "Stroke Info" },
    { disorderName: "Ataxia", disorderInfo: "Ataxia Info" },
    {
      disorderName: "Multiple sclerosis",
      disorderInfo: "Multiple sclerosis Info",
    },
    { disorderName: "Epilepsy", disorderInfo: "Epilepsy Info" },
    { disorderName: "Brain tumor", disorderInfo: "Brain tumor Info" },
    {
      disorderName: "Parkinson's disease",
      disorderInfo: "Parkinson's disease Info",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDisorder, setSelectedDisorder] = useState<Disorder>({
    disorderName: "",
    disorderInfo: "",
  });
  const [filteredDiscoreder, setFilteredDisorder] = useState(disorders);

  // callbacks & functions
  useEffect(() => {
    if (selectedDisorder.disorderName !== "") {
      const data = {
        disorderName: selectedDisorder.disorderName,
        disorderInfo: selectedDisorder.disorderInfo,
      };
      navigate(`/appointments/new/forms/${selectedDisorder.disorderName}`, {
        state: data,
      });
    }
  }, [selectedDisorder]);

  // Function to handle the search logic
  const performSearch = (query: string) => {
    const requiredDisorders = disorders.filter((disorder) =>
      disorder.disorderName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredDisorder(requiredDisorders);
  };

  // Create a debounced version of the performSearch function
  const debouncedSearch = useCallback(
    debounce((query: string) => performSearch(query), 300),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  return (
    <Box
      sx={{
        bgcolor: "var(--neuro-bg-light-grey-secondary)",
        p: isDownXl ? "60px" : "60px 95px 60px 60px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isDownLg ? "column" : "row",
      }}
    >
      <Box sx={{ width: isDownLg ? "100%" : "50%" }}>
        <Box>
          <Typography
            sx={{
              marginBottom: "28px",
              fontWeight: 500,
              fontSize: "22px",
              lineHeight: "120%",
            }}
          >
            Select your disorder?
          </Typography>
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search disorder"
            endIcon={<SearchIcon />}
            fullWidth
            sx={{ marginBottom: "28px" }}
          />
          {filteredDiscoreder.length > 0 && (
            <DisorderList
              disorders={filteredDiscoreder}
              selectedDisorder={selectedDisorder}
              onSelectDisorder={setSelectedDisorder}
            />
          )}
          {filteredDiscoreder.length === 0 && (
            <h5>No disorders for given input</h5>
          )}
        </Box>
      </Box>
      {selectedDisorder.disorderName !== "" && (
        <Box
          sx={{
            width: isDownLg ? "100%" : "40%",
            marginTop: isDownLg ? "20px" : "60px",
          }}
        >
          <DisorderInfo selectedDisorder={selectedDisorder} />
        </Box>
      )}
    </Box>
  );
};

export default PatientDisorder;
