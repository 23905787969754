import { Box, Grid, Typography } from "@mui/material"
import ComponentHeader from "../shared/ComponentHeader"
import { PrescriptionsListProps } from "../../types/prescriptions"
import LoadingScreen from "../shared/LoadingScreen"
import ContentCenter from "../shared/ContentCenter"
import { PrescriptionCard } from "./PrescriptionCard"
import { useEffect, useState } from "react"
import MedicationCard from "./MedicationCard"
import MorningIcon from "../svg/MorningIcon"
import AfternoonIcon from "../svg/AfternoonIcon"
import NightIcon from "../svg/NightIcon"
import Brightness6Icon from "@mui/icons-material/Brightness6";
import Button from "../ui/Button"
import useAppStore from "../../store/appStore"
import { downloadPrescription } from "../../services/documentsService"
import Spinner from "../ui/Spinner"

const PrescriptionsList: React.FC<PrescriptionsListProps> = ({
    prescriptions,
    loading,
    patientId,
    stateUpdaterForParent,
    // loadPrescriptions 
}) => {
    // const props & state values
    const { showSnackbar } = useAppStore();
    const [selectedPrescription, setSelectedPrescription] = useState<any>(null);

    const [downloadLoading, setDownloadLoading] = useState(false);

    const styles = {
        iconsWrapper: {
            display: "flex",
            alignItems: "center",
            gap: "0.25rem"
        },
        time: {
            color: "var(--neuro-darkgrey_border)",
            fontFamily: "var(--neuro-font-family-inter)",
            lineHeight: "normal",
        }
    }

    // callbacks & functions
    useEffect(() => {
        if (prescriptions && prescriptions.length > 0 && !selectedPrescription) {
            setSelectedPrescription(prescriptions[0]);
            if (stateUpdaterForParent) {
                stateUpdaterForParent(prescriptions[0]);
            }
        }
    }, [prescriptions])

    const handleCardClick = (prescription: any) => {
        setSelectedPrescription(prescription);
        if (stateUpdaterForParent) {
            stateUpdaterForParent(prescription);
        }
    }

    const downloadPrescriptionHandler = async () => {
        setDownloadLoading(true);
        showSnackbar("Prescription is downloading, please wait", "info");
        try {
            const response = await downloadPrescription(selectedPrescription?.appointmentId || "");
            const contentDisposition = response?.headers["content-disposition"];
            const blobData = response?.data;
            const fileName = contentDisposition;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blobData);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
            showSnackbar("Prescription is downloaded", "success");
        } catch (error) {
            showSnackbar(
                "Could not download prescription, please try again",
                "error"
            );
        } finally {
            setDownloadLoading(false);
        }
    };


    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                height: "100%",
            }}
        >
            {!patientId && <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <ComponentHeader title="Prescriptions" />

                <Button
                    variant="outlined"
                    color="secondary"
                    className="outlined-secondary-button"
                    onClick={downloadPrescriptionHandler}
                    disabled={!selectedPrescription || downloadLoading}
                    sx={{ height: "2.25rem", width: "6rem" }}
                >
                    {downloadLoading ? <Spinner /> : "Download"}
                </Button>
            </Box>}


            {loading ? (
                <Box
                    sx={{
                        height: "calc(100% - 2.25rem)",
                    }}
                >
                    <LoadingScreen />
                </Box>
            ) : prescriptions && prescriptions.length > 0 ? (
                <Box sx={{ maxHeight: "calc(100% - 2.25rem)", overflowY: "hidden" }}>
                    <Grid container spacing={2} sx={{ height: patientId ? "calc(100% + 1rem)" : "100%", overflowY: "auto" }}>
                        <Grid item container spacing={2} xs={12} md={6.5}
                            sx={{
                                height: "calc(100% + 1rem)",
                                overflowY: "auto",
                            }}
                        >
                            {prescriptions?.map((prescription) => (
                                <Grid item xs={12} key={prescription.appointmentId}>
                                    <PrescriptionCard
                                        prescription={prescription}
                                        onClick={handleCardClick}
                                        selectedPrescription={selectedPrescription}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        {selectedPrescription && <Grid item xs={12} md={5.5} sx={{
                            height: "100%",
                            overflowY: "hidden",
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "column",
                                gap: "1rem",
                                borderRadius: '0.75rem',
                                padding: "1rem",
                                backgroundColor: "var(--neuro-white-text)",
                                height: "100%",
                                overflowY: "hidden",
                            }}>
                                <Box sx={{
                                    height: "calc(100% - 2rem)",
                                    overflowY: "auto",
                                    display: 'flex',
                                    flexDirection: "column",
                                    gap: "0.875rem",
                                }}>
                                    {selectedPrescription && selectedPrescription?.prescriptions && selectedPrescription?.prescriptions.map((prescription: any) => (
                                        <MedicationCard data={prescription} key={prescription?.prescriptionId} />
                                    ))}
                                </Box>

                                <Box sx={{ paddingInline: "1.25rem", paddingTop: "0.875rem", display: "flex", alignItems: "center", justifyContent: "space-between", height: "2rem", borderTop: "1px solid var(--neuro-secondary_border)", marginInline: "-1rem" }}>
                                    <Box sx={styles.iconsWrapper}>
                                        <MorningIcon />
                                        <Typography sx={styles.time}>
                                            Morning
                                        </Typography>
                                    </Box>
                                    <Box sx={styles.iconsWrapper}>
                                        <AfternoonIcon />
                                        <Typography sx={styles.time}>
                                            Afternoon
                                        </Typography>
                                    </Box>
                                    <Box sx={styles.iconsWrapper}>
                                        <Brightness6Icon
                                            sx={{
                                                fontSize: "var(--neuro-font-size-smallest)",
                                                color: "var(--neuro-button-bg-success)",
                                            }}
                                        />
                                        <Typography sx={styles.time}>
                                            Evening
                                        </Typography>
                                    </Box>
                                    <Box sx={styles.iconsWrapper}>
                                        <NightIcon />
                                        <Typography sx={styles.time}>
                                            Night
                                        </Typography>

                                    </Box>
                                </Box>
                            </Box>
                        </Grid>}

                    </Grid>
                </Box>

            ) : (
                <Box
                    sx={{
                        height: "calc(100% - 2.25rem)",
                    }}
                >
                    <ContentCenter>
                        <Typography className="no-data">No prescriptions found</Typography>
                    </ContentCenter>
                </Box>
            )}

        </Box>
    )
}

export default PrescriptionsList;