import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
    Typography,
    Box,
    Grid,
    Select,
    MenuItem,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import Checkbox from "../ui/Checkbox";
import Input from "../ui/Input";
import { AddFormInputs } from "../../types/form";
import { useNavigate, useParams } from "react-router-dom";
import { inputLabelStyles, selectStyles } from "../../utils/styles";
import { fetchFormRequestsApiFormid, submitFormResponseApisuryve } from "../../services/formService";
import useAppStore from "../../store/appStore";



const AddNewFormComp = ({ submitTrigger }: { submitTrigger: boolean }) => {

    const { id } = useParams();  // Extract the 'id' from the URL
    const navigate = useNavigate();

    // Log the id to the console if it's available in the URL
    useEffect(() => {
        if (id) {
            console.log("Form ID from URL: ", id);

            const fetchFormData = async () => {

                try {
                    const response = await fetchFormRequestsApiFormid(id); // Pass the formId to the API call
                    if (response?.data) {
                        console.log("Form Data Response:", response.data);

                    }
                } catch (error) {

                    console.error("Error fetching form data:", error);
                } finally {

                }
            };

            fetchFormData(); // Call the fetch function
        } else {
            console.log("No ID in URL");
        }
    }, [id]);

    // props & state values
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm<AddFormInputs>({});




    // Track local state for dynamic form values
    const [filledBy, setFilledBy] = useState<string>(""); // State for "Form filled by"
    const [disorderId, setDisorderId] = useState<string>(""); // State for disorderId
    const [isScoreBased, setIsScoreBased] = useState<boolean>(false); // State for "Score Based"
    const [isPublic, setisPublic] = useState<boolean>(false);    
     const [notifyOnSubmit, setnotifyOnSubmit] = useState<boolean>(false);    
     const [notificationEmail, setnotificationEmail] = useState<string>(""); 
    // isPublic:true,
    // notifyOnSubmit:true,      
    //  notificationEmail:"aaaaaa@gmmail.com",
    const {
        showSnackbar,
      } = useAppStore();

    const handleFilledByChange = (event: SelectChangeEvent) => {
        setFilledBy(event.target.value);
    };

    const handleDisorderChange = (event: SelectChangeEvent) => {
        setDisorderId(event.target.value);
    };
    const handleNotfiyChange = (event: SelectChangeEvent<string>) => {
        setnotifyOnSubmit(event.target.value);
    };

    const handlesetnotificationEmail = (event: SelectChangeEvent) => {
        setnotificationEmail(event.target.value);
    };
    

    const handleScoreBasedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsScoreBased(event.target.checked);
    };

    const handlepublicBasedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setisPublic(event.target.checked);
    };
    const handlenotifyOnSubmitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setnotifyOnSubmit(event.target.checked);
    };
    const [fieldErrors, setFieldErrors] = useState<{
        name?: string;
        zipCode?: string;
        disorderId?: string;
        formType?: string;
        filledBy?: string;
        notifyOnSubmit?: string;
        notificationEmail?: string;
    }>({});

    const validateFields = (values: AddFormInputs) => {
        const errors: typeof fieldErrors = {};

        if (!values.name) {
            errors.name = "Form Name is required.";
        }

        // if (!values.zipCode) {
        //     errors.zipCode = "Zip Code is required.";
        // }

        if (!disorderId) {
            errors.disorderId = "Please select a disorder.";
        }

        if (!values.formType) {
            errors.formType = "Form Type is required.";
        }

        if (!filledBy) {

            errors.filledBy = "Please specify who filled the form.";
        }

   

        // if (!notifyOnSubmit) {
        //     errors.notifyOnSubmit = "Notify OnSubmit  is required.";
        // }

        // if (!notificationEmail) {

        //     errors.notificationEmail = "Please specify Notification Email.";
        // }


        return errors;
    };


    const UserId = localStorage.getItem("formid");
    const onSubmit: SubmitHandler<AddFormInputs> = async (values) => {
   
        const errors = validateFields(values);

        if (Object.keys(errors).length > 0) {
            console.error("Validation failed:", errors);
            setFieldErrors(errors); // Set specific field errors
            return; // Stop further execution if validation fails
        }

        setFieldErrors({}); // Clear field errors if validation passes

        const formData = {
            name: values.name,
            description: values.zipCode,
            disorderId: disorderId,
            type: values.formType,
            isScoreBased: isScoreBased,
            filledBy: filledBy,
            isPublic:isPublic,
           notifyOnSubmit:notifyOnSubmit,      
            notificationEmail:notificationEmail,
            
        };

        try {
            const response = await submitFormResponseApisuryve(formData);
            console.log("Form submitted successfully", response);
            localStorage.setItem("isScoreBased", response.data.data.isScoreBased);
            localStorage.setItem("questions", response.data.data.questions);
            localStorage.setItem("formname", response.data.data.name);
            localStorage.setItem("formid", response.data.data.id);
            localStorage.setItem("isDisabled", "false");
            showSnackbar("Form details saved successfully", "success");
            // Trigger a synthetic storage change event to notify other components
            window.dispatchEvent(new Event("storage"));
            if (UserId && UserId.trim() !== "") {
                navigate("/forms");
              } else {
                // alert("Invalid or missing UserId11!");
              }
          
        } catch (error) {
            console.error("Error submitting form", error);
            showSnackbar("Error submitting form" , "error")
        }
    };

    useEffect(() => {
        if (submitTrigger) {
        
          handleSubmit(onSubmit)(); // Trigger form submission
        }
      }, [submitTrigger, handleSubmit]);

    
    return (

        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1.25rem",
                    alignItems: "center",
                }}
            >

                <Box
                    sx={{
                        display: "flex",
                        gap: "1.25rem",
                        alignItems: "center",
                    }}
                >
                    {/* <Button
                        variant="outlined"
                        color="secondary"
                        className="outlined-secondary-button"
                        sx={{
                            height: "2.25rem",
                        }}

                        onClick={handleSubmit(onSubmit)}
                    >
                        Save
                    </Button> */}

                    {/* <Button
                        // startIcon={<SaveOutlinedIcon />}
                        variant="contained"
                        className="success-button"
                        type="submit"
                        sx={{
                            height: "2.25rem",
                        }}

                        onClick={handleSubmit(onSubmittable)}

                    >
                        Save Next
                    </Button> */}

                </Box>
            </Box>
            <Box
                sx={{
                    p: "1rem",
                    backgroundColor: "var(--neuro-white-text)",
                    borderRadius: "0.625rem",
                    width: '599px',
                    height: '432px',
             
                }}
            >
                <Grid container spacing={3.5}>
                    {/* Form Name */}
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="Form Name *"
                                {...register("name", { required: "Form Name is required" })}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        </Grid>

                        {/* Form Type */}
                        <Grid item xs={12}>
                            <Controller
                                name="formType"
                                control={control}
                                rules={{ required: "Form Type is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.375rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography sx={inputLabelStyles}>Form Type *</Typography>
                                        <Select
                                            {...field}
                                            fullWidth
                                            sx={selectStyles.withGreyBackground}
                                            margin="dense"
                                            error={!!error}
                                        >
                                            <MenuItem value="GENERIC">GENERIC</MenuItem>
                                            <MenuItem value="SPECIFIC">SPECIFIC</MenuItem>
                                            <MenuItem value="COMMON">COMMON</MenuItem>
                                        </Select>
                                        {error && (
                                            <Typography color="error" variant="body2">
                                                {error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Disorder and Filled By */}
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12}>
                            <Controller
                                name="disorderId"
                                control={control}
                                rules={{ required: "Disorder is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.375rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography sx={inputLabelStyles}>Disorder *</Typography>
                                        <Select
                                            {...field}
                                            fullWidth
                                            sx={selectStyles.withGreyBackground}
                                            margin="dense"
                                            error={!!error}
                                            value={disorderId}
                                            onChange={(event) => {
                                                field.onChange(event);
                                                handleDisorderChange(event);
                                            }}
                                        >
                                            <MenuItem value="cm06i4jj2001842domsdtgro8">Migraine</MenuItem>
                                            <MenuItem value="cm06i4jj2001842doms">Headache</MenuItem>
                                        </Select>
                                        {error && (
                                            <Typography color="error" variant="body2">
                                                {error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="filledBy"
                                control={control}
                                // rules={{ required: "Filled By is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "1rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography sx={inputLabelStyles}>Form Filled By *</Typography>
                                        <Select
                                            {...field}
                                            fullWidth
                                            sx={selectStyles.withGreyBackground}
                                            margin="dense"
                                            error={!!error}
                                            value={filledBy}
                                            onChange={handleFilledByChange}
                                        >
                                            <MenuItem value="DOCTOR">Doctor</MenuItem>
                                            <MenuItem value="PATIENT">Patient</MenuItem>
                                            <MenuItem value="BOTH">Both</MenuItem>
                                        </Select>
                                        {error && (
                                            <Typography color="error" variant="body2">
                                                {error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                        </Grid>
                    </Grid>


   
                    {/* <Grid item xs={12} md={6}>
    <Controller
        name="notifyOnSubmit"
        control={control}
        // rules={{ required: "Notify On Submit is required" }}
        render={({ field, fieldState: { error } }) => (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.375rem",
                    width: "100%",
                }}
            >
                <Typography sx={inputLabelStyles}>Notify On Submit *</Typography>
                <Select
                    {...field}
                    fullWidth
                    sx={selectStyles.withGreyBackground}
                    margin="dense"
                    error={!!error}
                    value={field.value}
                    onChange={(event) => {
                        field.onChange(event.target.value === "true"); // Convert string to Boolean
                        handleNotfiyChange(event);
                    }}
                >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                </Select>
                {error && (
                    <Typography color="error" variant="body2">
                        {error.message}
                    </Typography>
                )}
            </Box>
        )}
    />
</Grid> */}


                        <Grid item xs={12}  md={6}>
                        <Input
                                type="text"
                                className="input-primary"
                                label="NotificationEmail *"
                                // onChange={handlesetnotificationEmail(event)}
                                onChange={(event) => {
                                    // field.onChange(event);
                                    handlesetnotificationEmail(event);
                                }}
                                // {...register("name", { required: "Notification Email is required" })}
                                error={!!errors.notificationEmail}
                                helperText={errors.name?.message}
                            />
                        </Grid>
             
                    {/* Description and Score Based */}
                    <Grid item container xs={12} md={6}>
  <Grid item xs={12}>
    <TextField
      type="text"
      label="Description"
      {...register("zipCode")}
    //   error={!!errors.zipCode}
    //   helperText={errors.zipCode?.message}
      className="input-secondary-textarea"
      rows={8}
      multiline // Ensure multiline is set for the `rows` to work
      fullWidth
    />
  </Grid>
  
  {/* <Grid item xs={12}  md={6}>
    <Box sx={{ gap: "0.5rem" }}>
      <Checkbox
        label="Score Based"
        checked={isScoreBased}
        onChange={handleScoreBasedChange}
      />
    </Box>
  </Grid> */}



</Grid>

<Grid item xs={12}  style={{display:'flex', flexDirection:'row',}}>
<Grid item xs={12} md={4} >
    <Box sx={{ gap: "0.5rem" }}>
      <Checkbox
        label="Notify On Submit"
        checked={notifyOnSubmit}
        onChange={handlenotifyOnSubmitChange}
      />
    </Box>
  </Grid>
  <Grid item xs={12}  md={4}>
    <Box sx={{ gap: "0.5rem" }}>
      <Checkbox
        label="Is Public"
        checked={isPublic}
        onChange={handlepublicBasedChange}
      />
    </Box>
  </Grid>
  <Grid item xs={12}  md={4}>
    <Box sx={{ gap: "0.5rem" }}>
      <Checkbox
        label="Score Based"
        checked={isScoreBased}
        onChange={handleScoreBasedChange}
      />
    </Box>
  </Grid>
  </Grid>



                </Grid>
        


                {/* Global Error Message */}
               
            </Box>
        </>



    );
};

export default AddNewFormComp;
