import React from "react";
import { ErrorBoundary } from "react-error-boundary";

// A fallback component that will be shown when an error occurs
const ErrorFallback: React.FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

// The reusable error boundary component
const CustomErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset the state of your application here, if needed
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
