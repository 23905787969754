import React from "react";
import { LogoProps } from "../../types/common";

const Logo: React.FC<LogoProps> = ({ height, width, ...props }) => {
  return (
    <img
      src="/22neuro-sidebar-logo.png"
      alt="22Neuro Clinic"
      style={{
        width: width ? width : "initial",
        height: height ? height : "initial",
        objectFit: "contain",
      }}
      {...props}
    />
  );
};

export default Logo;
