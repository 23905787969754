import React, { useCallback, useState } from "react";
import { Box, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import CardViewItem from "../shared/CardViewItem";
import LocationIcon from "../svg/LocationIcon";
import Button from "../ui/Button";
import {
  getFullHospitalAddress,
  handleOpenMap,
} from "../../utils/appointments";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardViewWrapper from "../shared/CardViewWrapper";
import { CardViewDoctorCardProps } from "../../types/doctors";

const CardViewDoctorCard: React.FC<CardViewDoctorCardProps> = ({
  doctor,
  handleEdit,
  handleDelete,
}) => {
  // props & state values
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // callbacks & functions
  const handleViewMap = useCallback((address: any) => {
    handleOpenMap(getFullHospitalAddress(address));
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditCard = (doctor: any) => {
    handleEdit(doctor);
    handleMenuClose();
  };

  const handleDeleteCard = (id: string) => {
    handleDelete(id);
    handleMenuClose();
  };

  return (
    <CardViewWrapper>
      <Grid container spacing={2.5} alignItems="center">
        <Grid
          item
          container
          alignItems="baseline"
          sx={{ lineHeight: "120%" }}
          spacing={5}
          xs={12}
          lg={10}
        >
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Doctor Name"
              value={`${capitalizeFirstLetter(doctor?.firstName || "")}`}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Specializations"
              value={doctor?.specializations?.join(", ") || "-"}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Years of Experience"
              value={doctor?.yearsOfExperience || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Country"
              value={doctor?.hospital?.country || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem label="Email" value={doctor?.email || "-"} />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Language"
              value={doctor?.spokenLanguages?.join(", ") || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Reg. Date"
              value={getFormattedDate(doctor?.createdAt || "") || "-"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.25rem",
            }}
          >
            <Button
              startIcon={<LocationIcon />}
              color="secondary"
              variant="outlined"
              className="outlined-secondary-button"
              sx={{
                fontSize: "var(--neuro-font-size-smaller-plus)",
                textTransform: "none",
                height: "2.25rem",
                fontFamily: "var(--neuro-font-family-roboto)",
              }}
              onClick={() => {
                const address = {
                  streetAddress: doctor?.hospital?.streetAddress || "",
                  city: doctor?.hospital?.city || "",
                  state: doctor?.hospital?.state || "",
                  zipCode: doctor?.hospital?.zipCode || "",
                  country: doctor?.hospital?.country || "",
                };
                handleViewMap(address);
              }}
            >
              Directions
            </Button>

            <IconButton
              onClick={handleMenuOpen}
              disableRipple
              size="small"
              sx={{
                p: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => handleEditCard(doctor)}>Edit</MenuItem>
              <MenuItem onClick={() => handleDeleteCard(doctor?.id)}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </CardViewWrapper>
  );
};

export default CardViewDoctorCard;
