const LocationIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#54B895"
      d="M8.4 0A8.394 8.394 0 0 0 0 8.4C0 14.7 8.4 24 8.4 24s8.4-9.3 8.4-15.6c0-4.644-3.756-8.4-8.4-8.4Zm0 11.4a3.001 3.001 0 0 1 0-6 3.001 3.001 0 0 1 0 6Z"
    />
  </svg>
);
export default LocationIcon;
