import { useNavigate } from "react-router-dom";
import {
  Search as SearchIcon,
  Star as StarIcon,
  List as ListIcon,
  CheckCircle as CheckCircleIcon,
  Upload as UploadIcon,
  Settings as SettingsIcon,
  Image as ImageIcon,
  StarHalf as StarHalfIcon,
  TextFields as TextFieldsIcon,
  RadioButtonChecked as RadioButtonCheckedIcon
} from "@mui/icons-material";
import './fullscreenviewfile.css';

const FormSidebarcomp = ({ isDisabled }: { isDisabled: boolean }) => {
  const navigate = useNavigate();

  const handleItemClick = (radioValue: string) => {
    console.log("isDisabled", isDisabled);
    if (!isDisabled) { // If not disabled, navigate
      navigate(`?radio=${radioValue}`);
  
    }
  };

  return (
    <div>
      <aside className="fullscreen-sidebar left-sidebar">
        <ul>
          <li style={{ borderBottom: "1px solid #DCDCDC", width: "110%" }}>
            <SearchIcon style={{ marginRight: '10px' }} /> Search
          </li>
     
          <li
            onClick={() => handleItemClick("Yes/No(Boolean)")}
            className={isDisabled ? "disabled" : ""}
          >
            <CheckCircleIcon style={{ marginRight: '10px' }} /> Yes/No (Boolean)
          </li>
          <li
            onClick={() => handleItemClick("FileUpload")}
            className={isDisabled ? "disabled" : ""}
          >
            <UploadIcon style={{ marginRight: '10px' }} /> FileUpload
          </li>
          <li
            onClick={() => handleItemClick("MultiDropdown")}
            className={isDisabled ? "disabled" : ""}
          >
            <SettingsIcon style={{ marginRight: '10px' }} /> Multi-Dropdown
          </li>
          <li
            onClick={() => handleItemClick("ImagePicker")}
            className={isDisabled ? "disabled" : ""}
          >
            <ImageIcon style={{ marginRight: '10px' }} /> ImagePicker
          </li>
          <li
            onClick={() => handleItemClick("StarRatings")}
            className={isDisabled ? "disabled" : ""}
          >
            <StarHalfIcon style={{ marginRight: '10px' }} /> StarRatings
          </li>
         
         
          {/* <li
            onClick={() => handleItemClick("Checkboxes")}
            className={isDisabled ? "disabled" : ""}
          >
            <StarIcon style={{ marginRight: '10px' }} /> Checkboxes
          </li> */}
           <li
            onClick={() => {handleItemClick("TextFiled");localStorage.setItem("Headache", "MIDAS");}}
            className={isDisabled ? "disabled" : ""}
          >
            <TextFieldsIcon style={{ marginRight: '10px' }} /> TextFiled
          </li>
          <li
  onClick={() => {
    handleItemClick("RadioButtonGroup");
    localStorage.setItem("Headache", "Headache");
  }}

            className={isDisabled ? "disabled" : ""}
          >
            <RadioButtonCheckedIcon style={{ marginRight: '10px' }} /> Radio group
          </li>
          <li
            onClick={() => handleItemClick("RatingScale")}
            className={isDisabled ? "disabled" : ""}
          >
            <StarIcon style={{ marginRight: '10px' }} /> RatingScale
          </li>
          <li
            onClick={() => handleItemClick("SelectDropdown")}
            className={isDisabled ? "disabled" : ""}
          >
            <ListIcon style={{ marginRight: '10px' }} /> SelectDropdown
          </li>
      
        </ul>
      </aside>
    </div>
  );
};

export default FormSidebarcomp;
