const UploadDocumentIcon = () => (
  <svg
    width="79"
    height="82"
    viewBox="0 0 79 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.5 38C45.675 38 36 47.9 36 60C36 72.1 45.675 82 57.5 82C69.325 82 79 72.1 79 60C79 47.9 69.325 38 57.5 38ZM67.4438 62.2C66.9063 62.75 66.3687 63.025 65.5625 63.025C64.7563 63.025 64.2187 62.75 63.6812 62.2L60.1875 58.625V71C60.1875 72.65 59.1125 73.75 57.5 73.75C55.8875 73.75 54.8125 72.65 54.8125 71V58.625L51.3188 62.2C50.2438 63.3 48.6312 63.3 47.5562 62.2C46.4812 61.1 46.4812 59.45 47.5562 58.35L55.6187 49.825C56.1562 49.275 56.6938 49 57.5 49C58.3062 49 58.8438 49.275 59.3813 49.825L67.4438 58.35C68.5188 59.45 68.5188 61.1 67.4438 62.2Z"
      fill="#62B55A"
    />
    <path
      d="M38.2666 1.64014V16.4001H53.0266L38.2666 1.64014Z"
      fill="#292C67"
    />
    <path
      d="M47.8333 21.8667H35.5333C33.8933 21.8667 32.8 20.7733 32.8 19.1333V0H2.73333C1.09333 0 0 1.09333 0 2.73333V73.8C0 75.44 1.09333 76.5333 2.73333 76.5333H35.5333C31.98 71.8867 30.0667 66.42 30.0667 60.1333C30.0667 45.92 41 34.44 54.6667 33.0733V28.7C54.6667 24.8733 51.66 21.8667 47.8333 21.8667Z"
      fill="#292C67"
    />
  </svg>
);
export default UploadDocumentIcon;
