import React, { useState, useEffect } from "react";
import { fetchReferrals } from "../../services/referralService";
import { Box } from "@mui/material";
import { Referral } from "../../types/common";
import ReferralsList from "./ReferralsList";
import useAppStore from "../../store/appStore";

const Referrals: React.FC = () => {
  // props & state values
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { referralsFilter } = useAppStore();

  console.log("referralsFilter in refere", referralsFilter);

  // callbacks & functions
  const loadReferrals = async (
    filterBy: string,
    start: string | null,
    end: string | null,
    search: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchReferrals(filterBy, start, end, search);
      setReferrals(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadReferrals(
      referralsFilter.status || "",
      referralsFilter.customStartDate,
      referralsFilter.customEndDate,
      referralsFilter?.searchValue || ""
    );
  }, [
    referralsFilter.customStartDate,
    referralsFilter.customEndDate,
    referralsFilter.status,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        // minHeight: "100%",
        // paddingInline: "20px",
        // mb: 2,
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      <ReferralsList
        referrals={referrals}
        loading={loading}
        loadReferrals={loadReferrals}
      />
    </Box>
  );
};

export default Referrals;
