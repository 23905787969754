/* eslint-disable quote-props */
/* eslint max-len:0 */

/**
 * DICOM tag dictionary 2022a.
 * Generated using xml standard conversion from {@link https://github.com/ivmartel/dcmStdToJs} v0.1.0.
 *
 * Conversion changes:
 * - (vr) 'See Note' -> 'NONE',
 * - (vr) 'OB or OW' -> 'ox',
 * - (vr) 'US or SS' -> 'xs',
 * - (vr) 'US or OW' -> 'xx',
 * - (vr) 'US or SS or OW' -> 'xs',
 * - added 'GenericGroupLength' element to each group.
 *
 * Local changes:
 * - tag numbers with 'xx' were replaced with '00', 'xxx' with '001' and
 *  'xxxx' with '0004'.
 *
 * @type {Object<string, Object<string, string[]>>}
 */
export const dictionary = {
  '0000': {
    '0000': ['UL', '1', 'CommandGroupLength'],
    '0001': ['UL', '1', 'CommandLengthToEnd'],
    '0002': ['UI', '1', 'AffectedSOPClassUID'],
    '0003': ['UI', '1', 'RequestedSOPClassUID'],
    '0010': ['SH', '1', 'CommandRecognitionCode'],
    '0100': ['US', '1', 'CommandField'],
    '0110': ['US', '1', 'MessageID'],
    '0120': ['US', '1', 'MessageIDBeingRespondedTo'],
    '0200': ['AE', '1', 'Initiator'],
    '0300': ['AE', '1', 'Receiver'],
    '0400': ['AE', '1', 'FindLocation'],
    '0600': ['AE', '1', 'MoveDestination'],
    '0700': ['US', '1', 'Priority'],
    '0800': ['US', '1', 'CommandDataSetType'],
    '0850': ['US', '1', 'NumberOfMatches'],
    '0860': ['US', '1', 'ResponseSequenceNumber'],
    '0900': ['US', '1', 'Status'],
    '0901': ['AT', '1-n', 'OffendingElement'],
    '0902': ['LO', '1', 'ErrorComment'],
    '0903': ['US', '1', 'ErrorID'],
    '1000': ['UI', '1', 'AffectedSOPInstanceUID'],
    '1001': ['UI', '1', 'RequestedSOPInstanceUID'],
    '1002': ['US', '1', 'EventTypeID'],
    '1005': ['AT', '1-n', 'AttributeIdentifierList'],
    '1008': ['US', '1', 'ActionTypeID'],
    '1020': ['US', '1', 'NumberOfRemainingSuboperations'],
    '1021': ['US', '1', 'NumberOfCompletedSuboperations'],
    '1022': ['US', '1', 'NumberOfFailedSuboperations'],
    '1023': ['US', '1', 'NumberOfWarningSuboperations'],
    '1030': ['AE', '1', 'MoveOriginatorApplicationEntityTitle'],
    '1031': ['US', '1', 'MoveOriginatorMessageID'],
    '4000': ['LT', '1', 'DialogReceiver'],
    '4010': ['LT', '1', 'TerminalType'],
    '5010': ['SH', '1', 'MessageSetID'],
    '5020': ['SH', '1', 'EndMessageID'],
    '5110': ['LT', '1', 'DisplayFormat'],
    '5120': ['LT', '1', 'PagePositionID'],
    '5130': ['CS', '1', 'TextFormatID'],
    '5140': ['CS', '1', 'NormalReverse'],
    '5150': ['CS', '1', 'AddGrayScale'],
    '5160': ['CS', '1', 'Borders'],
    '5170': ['IS', '1', 'Copies'],
    '5180': ['CS', '1', 'CommandMagnificationType'],
    '5190': ['CS', '1', 'Erase'],
    '51A0': ['CS', '1', 'Print'],
    '51B0': ['US', '1-n', 'Overlays']
  },
  '0002': {
    '0000': ['UL', '1', 'FileMetaInformationGroupLength'],
    '0001': ['OB', '1', 'FileMetaInformationVersion'],
    '0002': ['UI', '1', 'MediaStorageSOPClassUID'],
    '0003': ['UI', '1', 'MediaStorageSOPInstanceUID'],
    '0010': ['UI', '1', 'TransferSyntaxUID'],
    '0012': ['UI', '1', 'ImplementationClassUID'],
    '0013': ['SH', '1', 'ImplementationVersionName'],
    '0016': ['AE', '1', 'SourceApplicationEntityTitle'],
    '0017': ['AE', '1', 'SendingApplicationEntityTitle'],
    '0018': ['AE', '1', 'ReceivingApplicationEntityTitle'],
    '0026': ['UR', '1', 'SourcePresentationAddress'],
    '0027': ['UR', '1', 'SendingPresentationAddress'],
    '0028': ['UR', '1', 'ReceivingPresentationAddress'],
    '0031': ['OB', '1', 'RTVMetaInformationVersion'],
    '0032': ['UI', '1', 'RTVCommunicationSOPClassUID'],
    '0033': ['UI', '1', 'RTVCommunicationSOPInstanceUID'],
    '0035': ['OB', '1', 'RTVSourceIdentifier'],
    '0036': ['OB', '1', 'RTVFlowIdentifier'],
    '0037': ['UL', '1', 'RTVFlowRTPSamplingRate'],
    '0038': ['FD', '1', 'RTVFlowActualFrameDuration'],
    '0100': ['UI', '1', 'PrivateInformationCreatorUID'],
    '0102': ['OB', '1', 'PrivateInformation']
  },
  '0004': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '1130': ['CS', '1', 'FileSetID'],
    '1141': ['CS', '1-8', 'FileSetDescriptorFileID'],
    '1142': ['CS', '1', 'SpecificCharacterSetOfFileSetDescriptorFile'],
    '1200': ['UL', '1', 'OffsetOfTheFirstDirectoryRecordOfTheRootDirectoryEntity'],
    '1202': ['UL', '1', 'OffsetOfTheLastDirectoryRecordOfTheRootDirectoryEntity'],
    '1212': ['US', '1', 'FileSetConsistencyFlag'],
    '1220': ['SQ', '1', 'DirectoryRecordSequence'],
    '1400': ['UL', '1', 'OffsetOfTheNextDirectoryRecord'],
    '1410': ['US', '1', 'RecordInUseFlag'],
    '1420': ['UL', '1', 'OffsetOfReferencedLowerLevelDirectoryEntity'],
    '1430': ['CS', '1', 'DirectoryRecordType'],
    '1432': ['UI', '1', 'PrivateRecordUID'],
    '1500': ['CS', '1-8', 'ReferencedFileID'],
    '1504': ['UL', '1', 'MRDRDirectoryRecordOffset'],
    '1510': ['UI', '1', 'ReferencedSOPClassUIDInFile'],
    '1511': ['UI', '1', 'ReferencedSOPInstanceUIDInFile'],
    '1512': ['UI', '1', 'ReferencedTransferSyntaxUIDInFile'],
    '151A': ['UI', '1-n', 'ReferencedRelatedGeneralSOPClassUIDInFile'],
    '1600': ['UL', '1', 'NumberOfReferences']
  },
  '0008': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['UL', '1', 'LengthToEnd'],
    '0005': ['CS', '1-n', 'SpecificCharacterSet'],
    '0006': ['SQ', '1', 'LanguageCodeSequence'],
    '0008': ['CS', '2-n', 'ImageType'],
    '0010': ['SH', '1', 'RecognitionCode'],
    '0012': ['DA', '1', 'InstanceCreationDate'],
    '0013': ['TM', '1', 'InstanceCreationTime'],
    '0014': ['UI', '1', 'InstanceCreatorUID'],
    '0015': ['DT', '1', 'InstanceCoercionDateTime'],
    '0016': ['UI', '1', 'SOPClassUID'],
    '0018': ['UI', '1', 'SOPInstanceUID'],
    '001A': ['UI', '1-n', 'RelatedGeneralSOPClassUID'],
    '001B': ['UI', '1', 'OriginalSpecializedSOPClassUID'],
    '0020': ['DA', '1', 'StudyDate'],
    '0021': ['DA', '1', 'SeriesDate'],
    '0022': ['DA', '1', 'AcquisitionDate'],
    '0023': ['DA', '1', 'ContentDate'],
    '0024': ['DA', '1', 'OverlayDate'],
    '0025': ['DA', '1', 'CurveDate'],
    '002A': ['DT', '1', 'AcquisitionDateTime'],
    '0030': ['TM', '1', 'StudyTime'],
    '0031': ['TM', '1', 'SeriesTime'],
    '0032': ['TM', '1', 'AcquisitionTime'],
    '0033': ['TM', '1', 'ContentTime'],
    '0034': ['TM', '1', 'OverlayTime'],
    '0035': ['TM', '1', 'CurveTime'],
    '0040': ['US', '1', 'DataSetType'],
    '0041': ['LO', '1', 'DataSetSubtype'],
    '0042': ['CS', '1', 'NuclearMedicineSeriesType'],
    '0050': ['SH', '1', 'AccessionNumber'],
    '0051': ['SQ', '1', 'IssuerOfAccessionNumberSequence'],
    '0052': ['CS', '1', 'QueryRetrieveLevel'],
    '0053': ['CS', '1', 'QueryRetrieveView'],
    '0054': ['AE', '1-n', 'RetrieveAETitle'],
    '0055': ['AE', '1', 'StationAETitle'],
    '0056': ['CS', '1', 'InstanceAvailability'],
    '0058': ['UI', '1-n', 'FailedSOPInstanceUIDList'],
    '0060': ['CS', '1', 'Modality'],
    '0061': ['CS', '1-n', 'ModalitiesInStudy'],
    '0062': ['UI', '1-n', 'SOPClassesInStudy'],
    '0063': ['SQ', '1', 'AnatomicRegionsInStudyCodeSequence'],
    '0064': ['CS', '1', 'ConversionType'],
    '0068': ['CS', '1', 'PresentationIntentType'],
    '0070': ['LO', '1', 'Manufacturer'],
    '0080': ['LO', '1', 'InstitutionName'],
    '0081': ['ST', '1', 'InstitutionAddress'],
    '0082': ['SQ', '1', 'InstitutionCodeSequence'],
    '0090': ['PN', '1', 'ReferringPhysicianName'],
    '0092': ['ST', '1', 'ReferringPhysicianAddress'],
    '0094': ['SH', '1-n', 'ReferringPhysicianTelephoneNumbers'],
    '0096': ['SQ', '1', 'ReferringPhysicianIdentificationSequence'],
    '009C': ['PN', '1-n', 'ConsultingPhysicianName'],
    '009D': ['SQ', '1', 'ConsultingPhysicianIdentificationSequence'],
    '0100': ['SH', '1', 'CodeValue'],
    '0101': ['LO', '1', 'ExtendedCodeValue'],
    '0102': ['SH', '1', 'CodingSchemeDesignator'],
    '0103': ['SH', '1', 'CodingSchemeVersion'],
    '0104': ['LO', '1', 'CodeMeaning'],
    '0105': ['CS', '1', 'MappingResource'],
    '0106': ['DT', '1', 'ContextGroupVersion'],
    '0107': ['DT', '1', 'ContextGroupLocalVersion'],
    '0108': ['LT', '1', 'ExtendedCodeMeaning'],
    '0109': ['SQ', '1', 'CodingSchemeResourcesSequence'],
    '010A': ['CS', '1', 'CodingSchemeURLType'],
    '010B': ['CS', '1', 'ContextGroupExtensionFlag'],
    '010C': ['UI', '1', 'CodingSchemeUID'],
    '010D': ['UI', '1', 'ContextGroupExtensionCreatorUID'],
    '010E': ['UR', '1', 'CodingSchemeURL'],
    '010F': ['CS', '1', 'ContextIdentifier'],
    '0110': ['SQ', '1', 'CodingSchemeIdentificationSequence'],
    '0112': ['LO', '1', 'CodingSchemeRegistry'],
    '0114': ['ST', '1', 'CodingSchemeExternalID'],
    '0115': ['ST', '1', 'CodingSchemeName'],
    '0116': ['ST', '1', 'CodingSchemeResponsibleOrganization'],
    '0117': ['UI', '1', 'ContextUID'],
    '0118': ['UI', '1', 'MappingResourceUID'],
    '0119': ['UC', '1', 'LongCodeValue'],
    '0120': ['UR', '1', 'URNCodeValue'],
    '0121': ['SQ', '1', 'EquivalentCodeSequence'],
    '0122': ['LO', '1', 'MappingResourceName'],
    '0123': ['SQ', '1', 'ContextGroupIdentificationSequence'],
    '0124': ['SQ', '1', 'MappingResourceIdentificationSequence'],
    '0201': ['SH', '1', 'TimezoneOffsetFromUTC'],
    '0202': ['', '', ''],
    '0220': ['SQ', '1', 'ResponsibleGroupCodeSequence'],
    '0221': ['CS', '1', 'EquipmentModality'],
    '0222': ['LO', '1', 'ManufacturerRelatedModelGroup'],
    '0300': ['SQ', '1', 'PrivateDataElementCharacteristicsSequence'],
    '0301': ['US', '1', 'PrivateGroupReference'],
    '0302': ['LO', '1', 'PrivateCreatorReference'],
    '0303': ['CS', '1', 'BlockIdentifyingInformationStatus'],
    '0304': ['US', '1-n', 'NonidentifyingPrivateElements'],
    '0305': ['SQ', '1', 'DeidentificationActionSequence'],
    '0306': ['US', '1-n', 'IdentifyingPrivateElements'],
    '0307': ['CS', '1', 'DeidentificationAction'],
    '0308': ['US', '1', 'PrivateDataElement'],
    '0309': ['UL', '1-3', 'PrivateDataElementValueMultiplicity'],
    '030A': ['CS', '1', 'PrivateDataElementValueRepresentation'],
    '030B': ['UL', '1-2', 'PrivateDataElementNumberOfItems'],
    '030C': ['UC', '1', 'PrivateDataElementName'],
    '030D': ['UC', '1', 'PrivateDataElementKeyword'],
    '030E': ['UT', '1', 'PrivateDataElementDescription'],
    '030F': ['UT', '1', 'PrivateDataElementEncoding'],
    '0310': ['SQ', '1', 'PrivateDataElementDefinitionSequence'],
    '1000': ['AE', '1', 'NetworkID'],
    '1010': ['SH', '1', 'StationName'],
    '1030': ['LO', '1', 'StudyDescription'],
    '1032': ['SQ', '1', 'ProcedureCodeSequence'],
    '103E': ['LO', '1', 'SeriesDescription'],
    '103F': ['SQ', '1', 'SeriesDescriptionCodeSequence'],
    '1040': ['LO', '1', 'InstitutionalDepartmentName'],
    '1041': ['SQ', '1', 'InstitutionalDepartmentTypeCodeSequence'],
    '1048': ['PN', '1-n', 'PhysiciansOfRecord'],
    '1049': ['SQ', '1', 'PhysiciansOfRecordIdentificationSequence'],
    '1050': ['PN', '1-n', 'PerformingPhysicianName'],
    '1052': ['SQ', '1', 'PerformingPhysicianIdentificationSequence'],
    '1060': ['PN', '1-n', 'NameOfPhysiciansReadingStudy'],
    '1062': ['SQ', '1', 'PhysiciansReadingStudyIdentificationSequence'],
    '1070': ['PN', '1-n', 'OperatorsName'],
    '1072': ['SQ', '1', 'OperatorIdentificationSequence'],
    '1080': ['LO', '1-n', 'AdmittingDiagnosesDescription'],
    '1084': ['SQ', '1', 'AdmittingDiagnosesCodeSequence'],
    '1090': ['LO', '1', 'ManufacturerModelName'],
    '1100': ['SQ', '1', 'ReferencedResultsSequence'],
    '1110': ['SQ', '1', 'ReferencedStudySequence'],
    '1111': ['SQ', '1', 'ReferencedPerformedProcedureStepSequence'],
    '1115': ['SQ', '1', 'ReferencedSeriesSequence'],
    '1120': ['SQ', '1', 'ReferencedPatientSequence'],
    '1125': ['SQ', '1', 'ReferencedVisitSequence'],
    '1130': ['SQ', '1', 'ReferencedOverlaySequence'],
    '1134': ['SQ', '1', 'ReferencedStereometricInstanceSequence'],
    '113A': ['SQ', '1', 'ReferencedWaveformSequence'],
    '1140': ['SQ', '1', 'ReferencedImageSequence'],
    '1145': ['SQ', '1', 'ReferencedCurveSequence'],
    '114A': ['SQ', '1', 'ReferencedInstanceSequence'],
    '114B': ['SQ', '1', 'ReferencedRealWorldValueMappingInstanceSequence'],
    '1150': ['UI', '1', 'ReferencedSOPClassUID'],
    '1155': ['UI', '1', 'ReferencedSOPInstanceUID'],
    '1156': ['SQ', '1', 'DefinitionSourceSequence'],
    '115A': ['UI', '1-n', 'SOPClassesSupported'],
    '1160': ['IS', '1-n', 'ReferencedFrameNumber'],
    '1161': ['UL', '1-n', 'SimpleFrameList'],
    '1162': ['UL', '3-3n', 'CalculatedFrameList'],
    '1163': ['FD', '2', 'TimeRange'],
    '1164': ['SQ', '1', 'FrameExtractionSequence'],
    '1167': ['UI', '1', 'MultiFrameSourceSOPInstanceUID'],
    '1190': ['UR', '1', 'RetrieveURL'],
    '1195': ['UI', '1', 'TransactionUID'],
    '1196': ['US', '1', 'WarningReason'],
    '1197': ['US', '1', 'FailureReason'],
    '1198': ['SQ', '1', 'FailedSOPSequence'],
    '1199': ['SQ', '1', 'ReferencedSOPSequence'],
    '119A': ['SQ', '1', 'OtherFailuresSequence'],
    '1200': ['SQ', '1', 'StudiesContainingOtherReferencedInstancesSequence'],
    '1250': ['SQ', '1', 'RelatedSeriesSequence'],
    '2110': ['CS', '1', 'LossyImageCompressionRetired'],
    '2111': ['ST', '1', 'DerivationDescription'],
    '2112': ['SQ', '1', 'SourceImageSequence'],
    '2120': ['SH', '1', 'StageName'],
    '2122': ['IS', '1', 'StageNumber'],
    '2124': ['IS', '1', 'NumberOfStages'],
    '2127': ['SH', '1', 'ViewName'],
    '2128': ['IS', '1', 'ViewNumber'],
    '2129': ['IS', '1', 'NumberOfEventTimers'],
    '212A': ['IS', '1', 'NumberOfViewsInStage'],
    '2130': ['DS', '1-n', 'EventElapsedTimes'],
    '2132': ['LO', '1-n', 'EventTimerNames'],
    '2133': ['SQ', '1', 'EventTimerSequence'],
    '2134': ['FD', '1', 'EventTimeOffset'],
    '2135': ['SQ', '1', 'EventCodeSequence'],
    '2142': ['IS', '1', 'StartTrim'],
    '2143': ['IS', '1', 'StopTrim'],
    '2144': ['IS', '1', 'RecommendedDisplayFrameRate'],
    '2200': ['CS', '1', 'TransducerPosition'],
    '2204': ['CS', '1', 'TransducerOrientation'],
    '2208': ['CS', '1', 'AnatomicStructure'],
    '2218': ['SQ', '1', 'AnatomicRegionSequence'],
    '2220': ['SQ', '1', 'AnatomicRegionModifierSequence'],
    '2228': ['SQ', '1', 'PrimaryAnatomicStructureSequence'],
    '2229': ['SQ', '1', 'AnatomicStructureSpaceOrRegionSequence'],
    '2230': ['SQ', '1', 'PrimaryAnatomicStructureModifierSequence'],
    '2240': ['SQ', '1', 'TransducerPositionSequence'],
    '2242': ['SQ', '1', 'TransducerPositionModifierSequence'],
    '2244': ['SQ', '1', 'TransducerOrientationSequence'],
    '2246': ['SQ', '1', 'TransducerOrientationModifierSequence'],
    '2251': ['SQ', '1', 'AnatomicStructureSpaceOrRegionCodeSequenceTrial'],
    '2253': ['SQ', '1', 'AnatomicPortalOfEntranceCodeSequenceTrial'],
    '2255': ['SQ', '1', 'AnatomicApproachDirectionCodeSequenceTrial'],
    '2256': ['ST', '1', 'AnatomicPerspectiveDescriptionTrial'],
    '2257': ['SQ', '1', 'AnatomicPerspectiveCodeSequenceTrial'],
    '2258': ['ST', '1', 'AnatomicLocationOfExaminingInstrumentDescriptionTrial'],
    '2259': ['SQ', '1', 'AnatomicLocationOfExaminingInstrumentCodeSequenceTrial'],
    '225A': ['SQ', '1', 'AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial'],
    '225C': ['SQ', '1', 'OnAxisBackgroundAnatomicStructureCodeSequenceTrial'],
    '3001': ['SQ', '1', 'AlternateRepresentationSequence'],
    '3002': ['UI', '1-n', 'AvailableTransferSyntaxUID'],
    '3010': ['UI', '1-n', 'IrradiationEventUID'],
    '3011': ['SQ', '1', 'SourceIrradiationEventSequence'],
    '3012': ['UI', '1', 'RadiopharmaceuticalAdministrationEventUID'],
    '4000': ['LT', '1', 'IdentifyingComments'],
    '9007': ['CS', '4', 'FrameType'],
    '9092': ['SQ', '1', 'ReferencedImageEvidenceSequence'],
    '9121': ['SQ', '1', 'ReferencedRawDataSequence'],
    '9123': ['UI', '1', 'CreatorVersionUID'],
    '9124': ['SQ', '1', 'DerivationImageSequence'],
    '9154': ['SQ', '1', 'SourceImageEvidenceSequence'],
    '9205': ['CS', '1', 'PixelPresentation'],
    '9206': ['CS', '1', 'VolumetricProperties'],
    '9207': ['CS', '1', 'VolumeBasedCalculationTechnique'],
    '9208': ['CS', '1', 'ComplexImageComponent'],
    '9209': ['CS', '1', 'AcquisitionContrast'],
    '9215': ['SQ', '1', 'DerivationCodeSequence'],
    '9237': ['SQ', '1', 'ReferencedPresentationStateSequence'],
    '9410': ['SQ', '1', 'ReferencedOtherPlaneSequence'],
    '9458': ['SQ', '1', 'FrameDisplaySequence'],
    '9459': ['FL', '1', 'RecommendedDisplayFrameRateInFloat'],
    '9460': ['CS', '1', 'SkipFrameRangeFlag']
  },
  '0010': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['PN', '1', 'PatientName'],
    '0020': ['LO', '1', 'PatientID'],
    '0021': ['LO', '1', 'IssuerOfPatientID'],
    '0022': ['CS', '1', 'TypeOfPatientID'],
    '0024': ['SQ', '1', 'IssuerOfPatientIDQualifiersSequence'],
    '0026': ['SQ', '1', 'SourcePatientGroupIdentificationSequence'],
    '0027': ['SQ', '1', 'GroupOfPatientsIdentificationSequence'],
    '0028': ['US', '3', 'SubjectRelativePositionInImage'],
    '0030': ['DA', '1', 'PatientBirthDate'],
    '0032': ['TM', '1', 'PatientBirthTime'],
    '0033': ['LO', '1', 'PatientBirthDateInAlternativeCalendar'],
    '0034': ['LO', '1', 'PatientDeathDateInAlternativeCalendar'],
    '0035': ['CS', '1', 'PatientAlternativeCalendar'],
    '0040': ['CS', '1', 'PatientSex'],
    '0050': ['SQ', '1', 'PatientInsurancePlanCodeSequence'],
    '0101': ['SQ', '1', 'PatientPrimaryLanguageCodeSequence'],
    '0102': ['SQ', '1', 'PatientPrimaryLanguageModifierCodeSequence'],
    '0200': ['CS', '1', 'QualityControlSubject'],
    '0201': ['SQ', '1', 'QualityControlSubjectTypeCodeSequence'],
    '0212': ['UC', '1', 'StrainDescription'],
    '0213': ['LO', '1', 'StrainNomenclature'],
    '0214': ['LO', '1', 'StrainStockNumber'],
    '0215': ['SQ', '1', 'StrainSourceRegistryCodeSequence'],
    '0216': ['SQ', '1', 'StrainStockSequence'],
    '0217': ['LO', '1', 'StrainSource'],
    '0218': ['UT', '1', 'StrainAdditionalInformation'],
    '0219': ['SQ', '1', 'StrainCodeSequence'],
    '0221': ['SQ', '1', 'GeneticModificationsSequence'],
    '0222': ['UC', '1', 'GeneticModificationsDescription'],
    '0223': ['LO', '1', 'GeneticModificationsNomenclature'],
    '0229': ['SQ', '1', 'GeneticModificationsCodeSequence'],
    '1000': ['LO', '1-n', 'OtherPatientIDs'],
    '1001': ['PN', '1-n', 'OtherPatientNames'],
    '1002': ['SQ', '1', 'OtherPatientIDsSequence'],
    '1005': ['PN', '1', 'PatientBirthName'],
    '1010': ['AS', '1', 'PatientAge'],
    '1020': ['DS', '1', 'PatientSize'],
    '1021': ['SQ', '1', 'PatientSizeCodeSequence'],
    '1022': ['DS', '1', 'PatientBodyMassIndex'],
    '1023': ['DS', '1', 'MeasuredAPDimension'],
    '1024': ['DS', '1', 'MeasuredLateralDimension'],
    '1030': ['DS', '1', 'PatientWeight'],
    '1040': ['LO', '1', 'PatientAddress'],
    '1050': ['LO', '1-n', 'InsurancePlanIdentification'],
    '1060': ['PN', '1', 'PatientMotherBirthName'],
    '1080': ['LO', '1', 'MilitaryRank'],
    '1081': ['LO', '1', 'BranchOfService'],
    '1090': ['LO', '1', 'MedicalRecordLocator'],
    '1100': ['SQ', '1', 'ReferencedPatientPhotoSequence'],
    '2000': ['LO', '1-n', 'MedicalAlerts'],
    '2110': ['LO', '1-n', 'Allergies'],
    '2150': ['LO', '1', 'CountryOfResidence'],
    '2152': ['LO', '1', 'RegionOfResidence'],
    '2154': ['SH', '1-n', 'PatientTelephoneNumbers'],
    '2155': ['LT', '1', 'PatientTelecomInformation'],
    '2160': ['SH', '1', 'EthnicGroup'],
    '2180': ['SH', '1', 'Occupation'],
    '21A0': ['CS', '1', 'SmokingStatus'],
    '21B0': ['LT', '1', 'AdditionalPatientHistory'],
    '21C0': ['US', '1', 'PregnancyStatus'],
    '21D0': ['DA', '1', 'LastMenstrualDate'],
    '21F0': ['LO', '1', 'PatientReligiousPreference'],
    '2201': ['LO', '1', 'PatientSpeciesDescription'],
    '2202': ['SQ', '1', 'PatientSpeciesCodeSequence'],
    '2203': ['CS', '1', 'PatientSexNeutered'],
    '2210': ['CS', '1', 'AnatomicalOrientationType'],
    '2292': ['LO', '1', 'PatientBreedDescription'],
    '2293': ['SQ', '1', 'PatientBreedCodeSequence'],
    '2294': ['SQ', '1', 'BreedRegistrationSequence'],
    '2295': ['LO', '1', 'BreedRegistrationNumber'],
    '2296': ['SQ', '1', 'BreedRegistryCodeSequence'],
    '2297': ['PN', '1', 'ResponsiblePerson'],
    '2298': ['CS', '1', 'ResponsiblePersonRole'],
    '2299': ['LO', '1', 'ResponsibleOrganization'],
    '4000': ['LT', '1', 'PatientComments'],
    '9431': ['FL', '1', 'ExaminedBodyThickness']
  },
  '0012': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['LO', '1', 'ClinicalTrialSponsorName'],
    '0020': ['LO', '1', 'ClinicalTrialProtocolID'],
    '0021': ['LO', '1', 'ClinicalTrialProtocolName'],
    '0030': ['LO', '1', 'ClinicalTrialSiteID'],
    '0031': ['LO', '1', 'ClinicalTrialSiteName'],
    '0040': ['LO', '1', 'ClinicalTrialSubjectID'],
    '0042': ['LO', '1', 'ClinicalTrialSubjectReadingID'],
    '0050': ['LO', '1', 'ClinicalTrialTimePointID'],
    '0051': ['ST', '1', 'ClinicalTrialTimePointDescription'],
    '0052': ['FD', '1', 'LongitudinalTemporalOffsetFromEvent'],
    '0053': ['CS', '1', 'LongitudinalTemporalEventType'],
    '0060': ['LO', '1', 'ClinicalTrialCoordinatingCenterName'],
    '0062': ['CS', '1', 'PatientIdentityRemoved'],
    '0063': ['LO', '1-n', 'DeidentificationMethod'],
    '0064': ['SQ', '1', 'DeidentificationMethodCodeSequence'],
    '0071': ['LO', '1', 'ClinicalTrialSeriesID'],
    '0072': ['LO', '1', 'ClinicalTrialSeriesDescription'],
    '0081': ['LO', '1', 'ClinicalTrialProtocolEthicsCommitteeName'],
    '0082': ['LO', '1', 'ClinicalTrialProtocolEthicsCommitteeApprovalNumber'],
    '0083': ['SQ', '1', 'ConsentForClinicalTrialUseSequence'],
    '0084': ['CS', '1', 'DistributionType'],
    '0085': ['CS', '1', 'ConsentForDistributionFlag'],
    '0086': ['DA', '1', 'EthicsCommitteeApprovalEffectivenessStartDate'],
    '0087': ['DA', '1', 'EthicsCommitteeApprovalEffectivenessEndDate']
  },
  '0014': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0023': ['ST', '1', 'CADFileFormat'],
    '0024': ['ST', '1', 'ComponentReferenceSystem'],
    '0025': ['ST', '1', 'ComponentManufacturingProcedure'],
    '0028': ['ST', '1', 'ComponentManufacturer'],
    '0030': ['DS', '1-n', 'MaterialThickness'],
    '0032': ['DS', '1-n', 'MaterialPipeDiameter'],
    '0034': ['DS', '1-n', 'MaterialIsolationDiameter'],
    '0042': ['ST', '1', 'MaterialGrade'],
    '0044': ['ST', '1', 'MaterialPropertiesDescription'],
    '0045': ['ST', '1', 'MaterialPropertiesFileFormatRetired'],
    '0046': ['LT', '1', 'MaterialNotes'],
    '0050': ['CS', '1', 'ComponentShape'],
    '0052': ['CS', '1', 'CurvatureType'],
    '0054': ['DS', '1', 'OuterDiameter'],
    '0056': ['DS', '1', 'InnerDiameter'],
    '0100': ['LO', '1-n', 'ComponentWelderIDs'],
    '0101': ['CS', '1', 'SecondaryApprovalStatus'],
    '0102': ['DA', '1', 'SecondaryReviewDate'],
    '0103': ['TM', '1', 'SecondaryReviewTime'],
    '0104': ['PN', '1', 'SecondaryReviewerName'],
    '0105': ['ST', '1', 'RepairID'],
    '0106': ['SQ', '1', 'MultipleComponentApprovalSequence'],
    '0107': ['CS', '1-n', 'OtherApprovalStatus'],
    '0108': ['CS', '1-n', 'OtherSecondaryApprovalStatus'],
    '1010': ['ST', '1', 'ActualEnvironmentalConditions'],
    '1020': ['DA', '1', 'ExpiryDate'],
    '1040': ['ST', '1', 'EnvironmentalConditions'],
    '2002': ['SQ', '1', 'EvaluatorSequence'],
    '2004': ['IS', '1', 'EvaluatorNumber'],
    '2006': ['PN', '1', 'EvaluatorName'],
    '2008': ['IS', '1', 'EvaluationAttempt'],
    '2012': ['SQ', '1', 'IndicationSequence'],
    '2014': ['IS', '1', 'IndicationNumber'],
    '2016': ['SH', '1', 'IndicationLabel'],
    '2018': ['ST', '1', 'IndicationDescription'],
    '201A': ['CS', '1-n', 'IndicationType'],
    '201C': ['CS', '1', 'IndicationDisposition'],
    '201E': ['SQ', '1', 'IndicationROISequence'],
    '2030': ['SQ', '1', 'IndicationPhysicalPropertySequence'],
    '2032': ['SH', '1', 'PropertyLabel'],
    '2202': ['IS', '1', 'CoordinateSystemNumberOfAxes'],
    '2204': ['SQ', '1', 'CoordinateSystemAxesSequence'],
    '2206': ['ST', '1', 'CoordinateSystemAxisDescription'],
    '2208': ['CS', '1', 'CoordinateSystemDataSetMapping'],
    '220A': ['IS', '1', 'CoordinateSystemAxisNumber'],
    '220C': ['CS', '1', 'CoordinateSystemAxisType'],
    '220E': ['CS', '1', 'CoordinateSystemAxisUnits'],
    '2210': ['OB', '1', 'CoordinateSystemAxisValues'],
    '2220': ['SQ', '1', 'CoordinateSystemTransformSequence'],
    '2222': ['ST', '1', 'TransformDescription'],
    '2224': ['IS', '1', 'TransformNumberOfAxes'],
    '2226': ['IS', '1-n', 'TransformOrderOfAxes'],
    '2228': ['CS', '1', 'TransformedAxisUnits'],
    '222A': ['DS', '1-n', 'CoordinateSystemTransformRotationAndScaleMatrix'],
    '222C': ['DS', '1-n', 'CoordinateSystemTransformTranslationMatrix'],
    '3011': ['DS', '1', 'InternalDetectorFrameTime'],
    '3012': ['DS', '1', 'NumberOfFramesIntegrated'],
    '3020': ['SQ', '1', 'DetectorTemperatureSequence'],
    '3022': ['ST', '1', 'SensorName'],
    '3024': ['DS', '1', 'HorizontalOffsetOfSensor'],
    '3026': ['DS', '1', 'VerticalOffsetOfSensor'],
    '3028': ['DS', '1', 'SensorTemperature'],
    '3040': ['SQ', '1', 'DarkCurrentSequence'],
    '3050': ['ox', '1', 'DarkCurrentCounts'],
    '3060': ['SQ', '1', 'GainCorrectionReferenceSequence'],
    '3070': ['ox', '1', 'AirCounts'],
    '3071': ['DS', '1', 'KVUsedInGainCalibration'],
    '3072': ['DS', '1', 'MAUsedInGainCalibration'],
    '3073': ['DS', '1', 'NumberOfFramesUsedForIntegration'],
    '3074': ['LO', '1', 'FilterMaterialUsedInGainCalibration'],
    '3075': ['DS', '1', 'FilterThicknessUsedInGainCalibration'],
    '3076': ['DA', '1', 'DateOfGainCalibration'],
    '3077': ['TM', '1', 'TimeOfGainCalibration'],
    '3080': ['OB', '1', 'BadPixelImage'],
    '3099': ['LT', '1', 'CalibrationNotes'],
    '3100': ['LT', '1', 'LinearityCorrectionTechnique'],
    '3101': ['LT', '1', 'BeamHardeningCorrectionTechnique'],
    '4002': ['SQ', '1', 'PulserEquipmentSequence'],
    '4004': ['CS', '1', 'PulserType'],
    '4006': ['LT', '1', 'PulserNotes'],
    '4008': ['SQ', '1', 'ReceiverEquipmentSequence'],
    '400A': ['CS', '1', 'AmplifierType'],
    '400C': ['LT', '1', 'ReceiverNotes'],
    '400E': ['SQ', '1', 'PreAmplifierEquipmentSequence'],
    '400F': ['LT', '1', 'PreAmplifierNotes'],
    '4010': ['SQ', '1', 'TransmitTransducerSequence'],
    '4011': ['SQ', '1', 'ReceiveTransducerSequence'],
    '4012': ['US', '1', 'NumberOfElements'],
    '4013': ['CS', '1', 'ElementShape'],
    '4014': ['DS', '1', 'ElementDimensionA'],
    '4015': ['DS', '1', 'ElementDimensionB'],
    '4016': ['DS', '1', 'ElementPitchA'],
    '4017': ['DS', '1', 'MeasuredBeamDimensionA'],
    '4018': ['DS', '1', 'MeasuredBeamDimensionB'],
    '4019': ['DS', '1', 'LocationOfMeasuredBeamDiameter'],
    '401A': ['DS', '1', 'NominalFrequency'],
    '401B': ['DS', '1', 'MeasuredCenterFrequency'],
    '401C': ['DS', '1', 'MeasuredBandwidth'],
    '401D': ['DS', '1', 'ElementPitchB'],
    '4020': ['SQ', '1', 'PulserSettingsSequence'],
    '4022': ['DS', '1', 'PulseWidth'],
    '4024': ['DS', '1', 'ExcitationFrequency'],
    '4026': ['CS', '1', 'ModulationType'],
    '4028': ['DS', '1', 'Damping'],
    '4030': ['SQ', '1', 'ReceiverSettingsSequence'],
    '4031': ['DS', '1', 'AcquiredSoundpathLength'],
    '4032': ['CS', '1', 'AcquisitionCompressionType'],
    '4033': ['IS', '1', 'AcquisitionSampleSize'],
    '4034': ['DS', '1', 'RectifierSmoothing'],
    '4035': ['SQ', '1', 'DACSequence'],
    '4036': ['CS', '1', 'DACType'],
    '4038': ['DS', '1-n', 'DACGainPoints'],
    '403A': ['DS', '1-n', 'DACTimePoints'],
    '403C': ['DS', '1-n', 'DACAmplitude'],
    '4040': ['SQ', '1', 'PreAmplifierSettingsSequence'],
    '4050': ['SQ', '1', 'TransmitTransducerSettingsSequence'],
    '4051': ['SQ', '1', 'ReceiveTransducerSettingsSequence'],
    '4052': ['DS', '1', 'IncidentAngle'],
    '4054': ['ST', '1', 'CouplingTechnique'],
    '4056': ['ST', '1', 'CouplingMedium'],
    '4057': ['DS', '1', 'CouplingVelocity'],
    '4058': ['DS', '1', 'ProbeCenterLocationX'],
    '4059': ['DS', '1', 'ProbeCenterLocationZ'],
    '405A': ['DS', '1', 'SoundPathLength'],
    '405C': ['ST', '1', 'DelayLawIdentifier'],
    '4060': ['SQ', '1', 'GateSettingsSequence'],
    '4062': ['DS', '1', 'GateThreshold'],
    '4064': ['DS', '1', 'VelocityOfSound'],
    '4070': ['SQ', '1', 'CalibrationSettingsSequence'],
    '4072': ['ST', '1', 'CalibrationProcedure'],
    '4074': ['SH', '1', 'ProcedureVersion'],
    '4076': ['DA', '1', 'ProcedureCreationDate'],
    '4078': ['DA', '1', 'ProcedureExpirationDate'],
    '407A': ['DA', '1', 'ProcedureLastModifiedDate'],
    '407C': ['TM', '1-n', 'CalibrationTime'],
    '407E': ['DA', '1-n', 'CalibrationDate'],
    '4080': ['SQ', '1', 'ProbeDriveEquipmentSequence'],
    '4081': ['CS', '1', 'DriveType'],
    '4082': ['LT', '1', 'ProbeDriveNotes'],
    '4083': ['SQ', '1', 'DriveProbeSequence'],
    '4084': ['DS', '1', 'ProbeInductance'],
    '4085': ['DS', '1', 'ProbeResistance'],
    '4086': ['SQ', '1', 'ReceiveProbeSequence'],
    '4087': ['SQ', '1', 'ProbeDriveSettingsSequence'],
    '4088': ['DS', '1', 'BridgeResistors'],
    '4089': ['DS', '1', 'ProbeOrientationAngle'],
    '408B': ['DS', '1', 'UserSelectedGainY'],
    '408C': ['DS', '1', 'UserSelectedPhase'],
    '408D': ['DS', '1', 'UserSelectedOffsetX'],
    '408E': ['DS', '1', 'UserSelectedOffsetY'],
    '4091': ['SQ', '1', 'ChannelSettingsSequence'],
    '4092': ['DS', '1', 'ChannelThreshold'],
    '409A': ['SQ', '1', 'ScannerSettingsSequence'],
    '409B': ['ST', '1', 'ScanProcedure'],
    '409C': ['DS', '1', 'TranslationRateX'],
    '409D': ['DS', '1', 'TranslationRateY'],
    '409F': ['DS', '1', 'ChannelOverlap'],
    '40A0': ['LO', '1-n', 'ImageQualityIndicatorType'],
    '40A1': ['LO', '1-n', 'ImageQualityIndicatorMaterial'],
    '40A2': ['LO', '1-n', 'ImageQualityIndicatorSize'],
    '5002': ['IS', '1', 'LINACEnergy'],
    '5004': ['IS', '1', 'LINACOutput'],
    '5100': ['US', '1', 'ActiveAperture'],
    '5101': ['DS', '1', 'TotalAperture'],
    '5102': ['DS', '1', 'ApertureElevation'],
    '5103': ['DS', '1', 'MainLobeAngle'],
    '5104': ['DS', '1', 'MainRoofAngle'],
    '5105': ['CS', '1', 'ConnectorType'],
    '5106': ['SH', '1', 'WedgeModelNumber'],
    '5107': ['DS', '1', 'WedgeAngleFloat'],
    '5108': ['DS', '1', 'WedgeRoofAngle'],
    '5109': ['CS', '1', 'WedgeElement1Position'],
    '510A': ['DS', '1', 'WedgeMaterialVelocity'],
    '510B': ['SH', '1', 'WedgeMaterial'],
    '510C': ['DS', '1', 'WedgeOffsetZ'],
    '510D': ['DS', '1', 'WedgeOriginOffsetX'],
    '510E': ['DS', '1', 'WedgeTimeDelay'],
    '510F': ['SH', '1', 'WedgeName'],
    '5110': ['SH', '1', 'WedgeManufacturerName'],
    '5111': ['LO', '1', 'WedgeDescription'],
    '5112': ['DS', '1', 'NominalBeamAngle'],
    '5113': ['DS', '1', 'WedgeOffsetX'],
    '5114': ['DS', '1', 'WedgeOffsetY'],
    '5115': ['DS', '1', 'WedgeTotalLength'],
    '5116': ['DS', '1', 'WedgeInContactLength'],
    '5117': ['DS', '1', 'WedgeFrontGap'],
    '5118': ['DS', '1', 'WedgeTotalHeight'],
    '5119': ['DS', '1', 'WedgeFrontHeight'],
    '511A': ['DS', '1', 'WedgeRearHeight'],
    '511B': ['DS', '1', 'WedgeTotalWidth'],
    '511C': ['DS', '1', 'WedgeInContactWidth'],
    '511D': ['DS', '1', 'WedgeChamferHeight'],
    '511E': ['CS', '1', 'WedgeCurve'],
    '511F': ['DS', '1', 'RadiusAlongWedge']
  },
  '0016': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['DS', '1', 'WhitePoint'],
    '0002': ['DS', '3', 'PrimaryChromaticities'],
    '0003': ['UT', '1', 'BatteryLevel'],
    '0004': ['DS', '1', 'ExposureTimeInSeconds'],
    '0005': ['DS', '1', 'FNumber'],
    '0006': ['IS', '1', 'OECFRows'],
    '0007': ['IS', '1', 'OECFColumns'],
    '0008': ['UC', '1-n', 'OECFColumnNames'],
    '0009': ['DS', '1-n', 'OECFValues'],
    '000A': ['IS', '1', 'SpatialFrequencyResponseRows'],
    '000B': ['IS', '1', 'SpatialFrequencyResponseColumns'],
    '000C': ['UC', '1-n', 'SpatialFrequencyResponseColumnNames'],
    '000D': ['DS', '1-n', 'SpatialFrequencyResponseValues'],
    '000E': ['IS', '1', 'ColorFilterArrayPatternRows'],
    '000F': ['IS', '1', 'ColorFilterArrayPatternColumns'],
    '0010': ['DS', '1-n', 'ColorFilterArrayPatternValues'],
    '0011': ['US', '1', 'FlashFiringStatus'],
    '0012': ['US', '1', 'FlashReturnStatus'],
    '0013': ['US', '1', 'FlashMode'],
    '0014': ['US', '1', 'FlashFunctionPresent'],
    '0015': ['US', '1', 'FlashRedEyeMode'],
    '0016': ['US', '1', 'ExposureProgram'],
    '0017': ['UT', '1', 'SpectralSensitivity'],
    '0018': ['IS', '1', 'PhotographicSensitivity'],
    '0019': ['IS', '1', 'SelfTimerMode'],
    '001A': ['US', '1', 'SensitivityType'],
    '001B': ['IS', '1', 'StandardOutputSensitivity'],
    '001C': ['IS', '1', 'RecommendedExposureIndex'],
    '001D': ['IS', '1', 'ISOSpeed'],
    '001E': ['IS', '1', 'ISOSpeedLatitudeyyy'],
    '001F': ['IS', '1', 'ISOSpeedLatitudezzz'],
    '0020': ['UT', '1', 'EXIFVersion'],
    '0021': ['DS', '1', 'ShutterSpeedValue'],
    '0022': ['DS', '1', 'ApertureValue'],
    '0023': ['DS', '1', 'BrightnessValue'],
    '0024': ['DS', '1', 'ExposureBiasValue'],
    '0025': ['DS', '1', 'MaxApertureValue'],
    '0026': ['DS', '1', 'SubjectDistance'],
    '0027': ['US', '1', 'MeteringMode'],
    '0028': ['US', '1', 'LightSource'],
    '0029': ['DS', '1', 'FocalLength'],
    '002A': ['IS', '2-4', 'SubjectArea'],
    '002B': ['OB', '1', 'MakerNote'],
    '0030': ['DS', '1', 'Temperature'],
    '0031': ['DS', '1', 'Humidity'],
    '0032': ['DS', '1', 'Pressure'],
    '0033': ['DS', '1', 'WaterDepth'],
    '0034': ['DS', '1', 'Acceleration'],
    '0035': ['DS', '1', 'CameraElevationAngle'],
    '0036': ['DS', '1-2', 'FlashEnergy'],
    '0037': ['IS', '2', 'SubjectLocation'],
    '0038': ['DS', '1', 'PhotographicExposureIndex'],
    '0039': ['US', '1', 'SensingMethod'],
    '003A': ['US', '1', 'FileSource'],
    '003B': ['US', '1', 'SceneType'],
    '0041': ['US', '1', 'CustomRendered'],
    '0042': ['US', '1', 'ExposureMode'],
    '0043': ['US', '1', 'WhiteBalance'],
    '0044': ['DS', '1', 'DigitalZoomRatio'],
    '0045': ['IS', '1', 'FocalLengthIn35mmFilm'],
    '0046': ['US', '1', 'SceneCaptureType'],
    '0047': ['US', '1', 'GainControl'],
    '0048': ['US', '1', 'Contrast'],
    '0049': ['US', '1', 'Saturation'],
    '004A': ['US', '1', 'Sharpness'],
    '004B': ['OB', '1', 'DeviceSettingDescription'],
    '004C': ['US', '1', 'SubjectDistanceRange'],
    '004D': ['UT', '1', 'CameraOwnerName'],
    '004E': ['DS', '4', 'LensSpecification'],
    '004F': ['UT', '1', 'LensMake'],
    '0050': ['UT', '1', 'LensModel'],
    '0051': ['UT', '1', 'LensSerialNumber'],
    '0061': ['CS', '1', 'InteroperabilityIndex'],
    '0062': ['OB', '1', 'InteroperabilityVersion'],
    '0070': ['OB', '1', 'GPSVersionID'],
    '0071': ['CS', '1', 'GPSLatitudeRef'],
    '0072': ['DS', '3', 'GPSLatitude'],
    '0073': ['CS', '1', 'GPSLongitudeRef'],
    '0074': ['DS', '3', 'GPSLongitude'],
    '0075': ['US', '1', 'GPSAltitudeRef'],
    '0076': ['DS', '1', 'GPSAltitude'],
    '0077': ['DT', '1', 'GPSTimeStamp'],
    '0078': ['UT', '1', 'GPSSatellites'],
    '0079': ['CS', '1', 'GPSStatus'],
    '007A': ['CS', '1', 'GPSMeasureMode'],
    '007B': ['DS', '1', 'GPSDOP'],
    '007C': ['CS', '1', 'GPSSpeedRef'],
    '007D': ['DS', '1', 'GPSSpeed'],
    '007E': ['CS', '1', 'GPSTrackRef'],
    '007F': ['DS', '1', 'GPSTrack'],
    '0080': ['CS', '1', 'GPSImgDirectionRef'],
    '0081': ['DS', '1', 'GPSImgDirection'],
    '0082': ['UT', '1', 'GPSMapDatum'],
    '0083': ['CS', '1', 'GPSDestLatitudeRef'],
    '0084': ['DS', '3', 'GPSDestLatitude'],
    '0085': ['CS', '1', 'GPSDestLongitudeRef'],
    '0086': ['DS', '3', 'GPSDestLongitude'],
    '0087': ['CS', '1', 'GPSDestBearingRef'],
    '0088': ['DS', '1', 'GPSDestBearing'],
    '0089': ['CS', '1', 'GPSDestDistanceRef'],
    '008A': ['DS', '1', 'GPSDestDistance'],
    '008B': ['OB', '1', 'GPSProcessingMethod'],
    '008C': ['OB', '1', 'GPSAreaInformation'],
    '008D': ['DT', '1', 'GPSDateStamp'],
    '008E': ['IS', '1', 'GPSDifferential'],
    '1001': ['CS', '1', 'LightSourcePolarization'],
    '1002': ['DS', '1', 'EmitterColorTemperature'],
    '1003': ['CS', '1', 'ContactMethod'],
    '1004': ['CS', '1-n', 'ImmersionMedia'],
    '1005': ['DS', '1', 'OpticalMagnificationFactor']
  },
  '0018': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['LO', '1', 'ContrastBolusAgent'],
    '0012': ['SQ', '1', 'ContrastBolusAgentSequence'],
    '0013': ['FL', '1', 'ContrastBolusT1Relaxivity'],
    '0014': ['SQ', '1', 'ContrastBolusAdministrationRouteSequence'],
    '0015': ['CS', '1', 'BodyPartExamined'],
    '0020': ['CS', '1-n', 'ScanningSequence'],
    '0021': ['CS', '1-n', 'SequenceVariant'],
    '0022': ['CS', '1-n', 'ScanOptions'],
    '0023': ['CS', '1', 'MRAcquisitionType'],
    '0024': ['SH', '1', 'SequenceName'],
    '0025': ['CS', '1', 'AngioFlag'],
    '0026': ['SQ', '1', 'InterventionDrugInformationSequence'],
    '0027': ['TM', '1', 'InterventionDrugStopTime'],
    '0028': ['DS', '1', 'InterventionDrugDose'],
    '0029': ['SQ', '1', 'InterventionDrugCodeSequence'],
    '002A': ['SQ', '1', 'AdditionalDrugSequence'],
    '0030': ['LO', '1-n', 'Radionuclide'],
    '0031': ['LO', '1', 'Radiopharmaceutical'],
    '0032': ['DS', '1', 'EnergyWindowCenterline'],
    '0033': ['DS', '1-n', 'EnergyWindowTotalWidth'],
    '0034': ['LO', '1', 'InterventionDrugName'],
    '0035': ['TM', '1', 'InterventionDrugStartTime'],
    '0036': ['SQ', '1', 'InterventionSequence'],
    '0037': ['CS', '1', 'TherapyType'],
    '0038': ['CS', '1', 'InterventionStatus'],
    '0039': ['CS', '1', 'TherapyDescription'],
    '003A': ['ST', '1', 'InterventionDescription'],
    '0040': ['IS', '1', 'CineRate'],
    '0042': ['CS', '1', 'InitialCineRunState'],
    '0050': ['DS', '1', 'SliceThickness'],
    '0060': ['DS', '1', 'KVP'],
    '0061': ['DS', '1', ''],
    '0070': ['IS', '1', 'CountsAccumulated'],
    '0071': ['CS', '1', 'AcquisitionTerminationCondition'],
    '0072': ['DS', '1', 'EffectiveDuration'],
    '0073': ['CS', '1', 'AcquisitionStartCondition'],
    '0074': ['IS', '1', 'AcquisitionStartConditionData'],
    '0075': ['IS', '1', 'AcquisitionTerminationConditionData'],
    '0080': ['DS', '1', 'RepetitionTime'],
    '0081': ['DS', '1', 'EchoTime'],
    '0082': ['DS', '1', 'InversionTime'],
    '0083': ['DS', '1', 'NumberOfAverages'],
    '0084': ['DS', '1', 'ImagingFrequency'],
    '0085': ['SH', '1', 'ImagedNucleus'],
    '0086': ['IS', '1-n', 'EchoNumbers'],
    '0087': ['DS', '1', 'MagneticFieldStrength'],
    '0088': ['DS', '1', 'SpacingBetweenSlices'],
    '0089': ['IS', '1', 'NumberOfPhaseEncodingSteps'],
    '0090': ['DS', '1', 'DataCollectionDiameter'],
    '0091': ['IS', '1', 'EchoTrainLength'],
    '0093': ['DS', '1', 'PercentSampling'],
    '0094': ['DS', '1', 'PercentPhaseFieldOfView'],
    '0095': ['DS', '1', 'PixelBandwidth'],
    '1000': ['LO', '1', 'DeviceSerialNumber'],
    '1002': ['UI', '1', 'DeviceUID'],
    '1003': ['LO', '1', 'DeviceID'],
    '1004': ['LO', '1', 'PlateID'],
    '1005': ['LO', '1', 'GeneratorID'],
    '1006': ['LO', '1', 'GridID'],
    '1007': ['LO', '1', 'CassetteID'],
    '1008': ['LO', '1', 'GantryID'],
    '1009': ['UT', '1', 'UniqueDeviceIdentifier'],
    '100A': ['SQ', '1', 'UDISequence'],
    '100B': ['UI', '1-n', 'ManufacturerDeviceClassUID'],
    '1010': ['LO', '1', 'SecondaryCaptureDeviceID'],
    '1011': ['LO', '1', 'HardcopyCreationDeviceID'],
    '1012': ['DA', '1', 'DateOfSecondaryCapture'],
    '1014': ['TM', '1', 'TimeOfSecondaryCapture'],
    '1016': ['LO', '1', 'SecondaryCaptureDeviceManufacturer'],
    '1017': ['LO', '1', 'HardcopyDeviceManufacturer'],
    '1018': ['LO', '1', 'SecondaryCaptureDeviceManufacturerModelName'],
    '1019': ['LO', '1-n', 'SecondaryCaptureDeviceSoftwareVersions'],
    '101A': ['LO', '1-n', 'HardcopyDeviceSoftwareVersion'],
    '101B': ['LO', '1', 'HardcopyDeviceManufacturerModelName'],
    '1020': ['LO', '1-n', 'SoftwareVersions'],
    '1022': ['SH', '1', 'VideoImageFormatAcquired'],
    '1023': ['LO', '1', 'DigitalImageFormatAcquired'],
    '1030': ['LO', '1', 'ProtocolName'],
    '1040': ['LO', '1', 'ContrastBolusRoute'],
    '1041': ['DS', '1', 'ContrastBolusVolume'],
    '1042': ['TM', '1', 'ContrastBolusStartTime'],
    '1043': ['TM', '1', 'ContrastBolusStopTime'],
    '1044': ['DS', '1', 'ContrastBolusTotalDose'],
    '1045': ['IS', '1', 'SyringeCounts'],
    '1046': ['DS', '1-n', 'ContrastFlowRate'],
    '1047': ['DS', '1-n', 'ContrastFlowDuration'],
    '1048': ['CS', '1', 'ContrastBolusIngredient'],
    '1049': ['DS', '1', 'ContrastBolusIngredientConcentration'],
    '1050': ['DS', '1', 'SpatialResolution'],
    '1060': ['DS', '1', 'TriggerTime'],
    '1061': ['LO', '1', 'TriggerSourceOrType'],
    '1062': ['IS', '1', 'NominalInterval'],
    '1063': ['DS', '1', 'FrameTime'],
    '1064': ['LO', '1', 'CardiacFramingType'],
    '1065': ['DS', '1-n', 'FrameTimeVector'],
    '1066': ['DS', '1', 'FrameDelay'],
    '1067': ['DS', '1', 'ImageTriggerDelay'],
    '1068': ['DS', '1', 'MultiplexGroupTimeOffset'],
    '1069': ['DS', '1', 'TriggerTimeOffset'],
    '106A': ['CS', '1', 'SynchronizationTrigger'],
    '106C': ['US', '2', 'SynchronizationChannel'],
    '106E': ['UL', '1', 'TriggerSamplePosition'],
    '1070': ['LO', '1', 'RadiopharmaceuticalRoute'],
    '1071': ['DS', '1', 'RadiopharmaceuticalVolume'],
    '1072': ['TM', '1', 'RadiopharmaceuticalStartTime'],
    '1073': ['TM', '1', 'RadiopharmaceuticalStopTime'],
    '1074': ['DS', '1', 'RadionuclideTotalDose'],
    '1075': ['DS', '1', 'RadionuclideHalfLife'],
    '1076': ['DS', '1', 'RadionuclidePositronFraction'],
    '1077': ['DS', '1', 'RadiopharmaceuticalSpecificActivity'],
    '1078': ['DT', '1', 'RadiopharmaceuticalStartDateTime'],
    '1079': ['DT', '1', 'RadiopharmaceuticalStopDateTime'],
    '1080': ['CS', '1', 'BeatRejectionFlag'],
    '1081': ['IS', '1', 'LowRRValue'],
    '1082': ['IS', '1', 'HighRRValue'],
    '1083': ['IS', '1', 'IntervalsAcquired'],
    '1084': ['IS', '1', 'IntervalsRejected'],
    '1085': ['LO', '1', 'PVCRejection'],
    '1086': ['IS', '1', 'SkipBeats'],
    '1088': ['IS', '1', 'HeartRate'],
    '1090': ['IS', '1', 'CardiacNumberOfImages'],
    '1094': ['IS', '1', 'TriggerWindow'],
    '1100': ['DS', '1', 'ReconstructionDiameter'],
    '1110': ['DS', '1', 'DistanceSourceToDetector'],
    '1111': ['DS', '1', 'DistanceSourceToPatient'],
    '1114': ['DS', '1', 'EstimatedRadiographicMagnificationFactor'],
    '1120': ['DS', '1', 'GantryDetectorTilt'],
    '1121': ['DS', '1', 'GantryDetectorSlew'],
    '1130': ['DS', '1', 'TableHeight'],
    '1131': ['DS', '1', 'TableTraverse'],
    '1134': ['CS', '1', 'TableMotion'],
    '1135': ['DS', '1-n', 'TableVerticalIncrement'],
    '1136': ['DS', '1-n', 'TableLateralIncrement'],
    '1137': ['DS', '1-n', 'TableLongitudinalIncrement'],
    '1138': ['DS', '1', 'TableAngle'],
    '113A': ['CS', '1', 'TableType'],
    '1140': ['CS', '1', 'RotationDirection'],
    '1141': ['DS', '1', 'AngularPosition'],
    '1142': ['DS', '1-n', 'RadialPosition'],
    '1143': ['DS', '1', 'ScanArc'],
    '1144': ['DS', '1', 'AngularStep'],
    '1145': ['DS', '1', 'CenterOfRotationOffset'],
    '1146': ['DS', '1-n', 'RotationOffset'],
    '1147': ['CS', '1', 'FieldOfViewShape'],
    '1149': ['IS', '1-2', 'FieldOfViewDimensions'],
    '1150': ['IS', '1', 'ExposureTime'],
    '1151': ['IS', '1', 'XRayTubeCurrent'],
    '1152': ['IS', '1', 'Exposure'],
    '1153': ['IS', '1', 'ExposureInuAs'],
    '1154': ['DS', '1', 'AveragePulseWidth'],
    '1155': ['CS', '1', 'RadiationSetting'],
    '1156': ['CS', '1', 'RectificationType'],
    '115A': ['CS', '1', 'RadiationMode'],
    '115E': ['DS', '1', 'ImageAndFluoroscopyAreaDoseProduct'],
    '1160': ['SH', '1', 'FilterType'],
    '1161': ['LO', '1-n', 'TypeOfFilters'],
    '1162': ['DS', '1', 'IntensifierSize'],
    '1164': ['DS', '2', 'ImagerPixelSpacing'],
    '1166': ['CS', '1-n', 'Grid'],
    '1170': ['IS', '1', 'GeneratorPower'],
    '1180': ['SH', '1', 'CollimatorGridName'],
    '1181': ['CS', '1', 'CollimatorType'],
    '1182': ['IS', '1-2', 'FocalDistance'],
    '1183': ['DS', '1-2', 'XFocusCenter'],
    '1184': ['DS', '1-2', 'YFocusCenter'],
    '1190': ['DS', '1-n', 'FocalSpots'],
    '1191': ['CS', '1', 'AnodeTargetMaterial'],
    '11A0': ['DS', '1', 'BodyPartThickness'],
    '11A2': ['DS', '1', 'CompressionForce'],
    '11A3': ['DS', '1', 'CompressionPressure'],
    '11A4': ['LO', '1', 'PaddleDescription'],
    '11A5': ['DS', '1', 'CompressionContactArea'],
    '11B0': ['LO', '1', 'AcquisitionMode'],
    '11B1': ['LO', '1', 'DoseModeName'],
    '11B2': ['CS', '1', 'AcquiredSubtractionMaskFlag'],
    '11B3': ['CS', '1', 'FluoroscopyPersistenceFlag'],
    '11B4': ['CS', '1', 'FluoroscopyLastImageHoldPersistenceFlag'],
    '11B5': ['IS', '1', 'UpperLimitNumberOfPersistentFluoroscopyFrames'],
    '11B6': ['CS', '1', 'ContrastBolusAutoInjectionTriggerFlag'],
    '11B7': ['FD', '1', 'ContrastBolusInjectionDelay'],
    '11B8': ['SQ', '1', 'XAAcquisitionPhaseDetailsSequence'],
    '11B9': ['FD', '1', 'XAAcquisitionFrameRate'],
    '11BA': ['SQ', '1', 'XAPlaneDetailsSequence'],
    '11BB': ['LO', '1', 'AcquisitionFieldOfViewLabel'],
    '11BC': ['SQ', '1', 'XRayFilterDetailsSequence'],
    '11BD': ['FD', '1', 'XAAcquisitionDuration'],
    '11BE': ['CS', '1', 'ReconstructionPipelineType'],
    '11BF': ['SQ', '1', 'ImageFilterDetailsSequence'],
    '11C0': ['CS', '1', 'AppliedMaskSubtractionFlag'],
    '11C1': ['SQ', '1', 'RequestedSeriesDescriptionCodeSequence'],
    '1200': ['DA', '1-n', 'DateOfLastCalibration'],
    '1201': ['TM', '1-n', 'TimeOfLastCalibration'],
    '1202': ['DT', '1', 'DateTimeOfLastCalibration'],
    '1203': ['DT', '1', 'CalibrationDateTime'],
    '1210': ['SH', '1-n', 'ConvolutionKernel'],
    '1240': ['IS', '1-n', 'UpperLowerPixelValues'],
    '1242': ['IS', '1', 'ActualFrameDuration'],
    '1243': ['IS', '1', 'CountRate'],
    '1244': ['US', '1', 'PreferredPlaybackSequencing'],
    '1250': ['SH', '1', 'ReceiveCoilName'],
    '1251': ['SH', '1', 'TransmitCoilName'],
    '1260': ['SH', '1', 'PlateType'],
    '1261': ['LO', '1', 'PhosphorType'],
    '1271': ['FD', '1', 'WaterEquivalentDiameter'],
    '1272': ['SQ', '1', 'WaterEquivalentDiameterCalculationMethodCodeSequence'],
    '1300': ['DS', '1', 'ScanVelocity'],
    '1301': ['CS', '1-n', 'WholeBodyTechnique'],
    '1302': ['IS', '1', 'ScanLength'],
    '1310': ['US', '4', 'AcquisitionMatrix'],
    '1312': ['CS', '1', 'InPlanePhaseEncodingDirection'],
    '1314': ['DS', '1', 'FlipAngle'],
    '1315': ['CS', '1', 'VariableFlipAngleFlag'],
    '1316': ['DS', '1', 'SAR'],
    '1318': ['DS', '1', 'dBdt'],
    '1320': ['FL', '1', 'B1rms'],
    '1400': ['LO', '1', 'AcquisitionDeviceProcessingDescription'],
    '1401': ['LO', '1', 'AcquisitionDeviceProcessingCode'],
    '1402': ['CS', '1', 'CassetteOrientation'],
    '1403': ['CS', '1', 'CassetteSize'],
    '1404': ['US', '1', 'ExposuresOnPlate'],
    '1405': ['IS', '1', 'RelativeXRayExposure'],
    '1411': ['DS', '1', 'ExposureIndex'],
    '1412': ['DS', '1', 'TargetExposureIndex'],
    '1413': ['DS', '1', 'DeviationIndex'],
    '1450': ['DS', '1', 'ColumnAngulation'],
    '1460': ['DS', '1', 'TomoLayerHeight'],
    '1470': ['DS', '1', 'TomoAngle'],
    '1480': ['DS', '1', 'TomoTime'],
    '1490': ['CS', '1', 'TomoType'],
    '1491': ['CS', '1', 'TomoClass'],
    '1495': ['IS', '1', 'NumberOfTomosynthesisSourceImages'],
    '1500': ['CS', '1', 'PositionerMotion'],
    '1508': ['CS', '1', 'PositionerType'],
    '1510': ['DS', '1', 'PositionerPrimaryAngle'],
    '1511': ['DS', '1', 'PositionerSecondaryAngle'],
    '1520': ['DS', '1-n', 'PositionerPrimaryAngleIncrement'],
    '1521': ['DS', '1-n', 'PositionerSecondaryAngleIncrement'],
    '1530': ['DS', '1', 'DetectorPrimaryAngle'],
    '1531': ['DS', '1', 'DetectorSecondaryAngle'],
    '1600': ['CS', '1-3', 'ShutterShape'],
    '1602': ['IS', '1', 'ShutterLeftVerticalEdge'],
    '1604': ['IS', '1', 'ShutterRightVerticalEdge'],
    '1606': ['IS', '1', 'ShutterUpperHorizontalEdge'],
    '1608': ['IS', '1', 'ShutterLowerHorizontalEdge'],
    '1610': ['IS', '2', 'CenterOfCircularShutter'],
    '1612': ['IS', '1', 'RadiusOfCircularShutter'],
    '1620': ['IS', '2-2n', 'VerticesOfThePolygonalShutter'],
    '1622': ['US', '1', 'ShutterPresentationValue'],
    '1623': ['US', '1', 'ShutterOverlayGroup'],
    '1624': ['US', '3', 'ShutterPresentationColorCIELabValue'],
    '1630': ['CS', '1', 'OutlineShapeType'],
    '1631': ['FD', '1', 'OutlineLeftVerticalEdge'],
    '1632': ['FD', '1', 'OutlineRightVerticalEdge'],
    '1633': ['FD', '1', 'OutlineUpperHorizontalEdge'],
    '1634': ['FD', '1', 'OutlineLowerHorizontalEdge'],
    '1635': ['FD', '2', 'CenterOfCircularOutline'],
    '1636': ['FD', '1', 'DiameterOfCircularOutline'],
    '1637': ['UL', '1', 'NumberOfPolygonalVertices'],
    '1638': ['OF', '1', 'VerticesOfThePolygonalOutline'],
    '1700': ['CS', '1-3', 'CollimatorShape'],
    '1702': ['IS', '1', 'CollimatorLeftVerticalEdge'],
    '1704': ['IS', '1', 'CollimatorRightVerticalEdge'],
    '1706': ['IS', '1', 'CollimatorUpperHorizontalEdge'],
    '1708': ['IS', '1', 'CollimatorLowerHorizontalEdge'],
    '1710': ['IS', '2', 'CenterOfCircularCollimator'],
    '1712': ['IS', '1', 'RadiusOfCircularCollimator'],
    '1720': ['IS', '2-2n', 'VerticesOfThePolygonalCollimator'],
    '1800': ['CS', '1', 'AcquisitionTimeSynchronized'],
    '1801': ['SH', '1', 'TimeSource'],
    '1802': ['CS', '1', 'TimeDistributionProtocol'],
    '1803': ['LO', '1', 'NTPSourceAddress'],
    '2001': ['IS', '1-n', 'PageNumberVector'],
    '2002': ['SH', '1-n', 'FrameLabelVector'],
    '2003': ['DS', '1-n', 'FramePrimaryAngleVector'],
    '2004': ['DS', '1-n', 'FrameSecondaryAngleVector'],
    '2005': ['DS', '1-n', 'SliceLocationVector'],
    '2006': ['SH', '1-n', 'DisplayWindowLabelVector'],
    '2010': ['DS', '2', 'NominalScannedPixelSpacing'],
    '2020': ['CS', '1', 'DigitizingDeviceTransportDirection'],
    '2030': ['DS', '1', 'RotationOfScannedFilm'],
    '2041': ['SQ', '1', 'BiopsyTargetSequence'],
    '2042': ['UI', '1', 'TargetUID'],
    '2043': ['FL', '2', 'LocalizingCursorPosition'],
    '2044': ['FL', '3', 'CalculatedTargetPosition'],
    '2045': ['SH', '1', 'TargetLabel'],
    '2046': ['FL', '1', 'DisplayedZValue'],
    '3100': ['CS', '1', 'IVUSAcquisition'],
    '3101': ['DS', '1', 'IVUSPullbackRate'],
    '3102': ['DS', '1', 'IVUSGatedRate'],
    '3103': ['IS', '1', 'IVUSPullbackStartFrameNumber'],
    '3104': ['IS', '1', 'IVUSPullbackStopFrameNumber'],
    '3105': ['IS', '1-n', 'LesionNumber'],
    '4000': ['LT', '1', 'AcquisitionComments'],
    '5000': ['SH', '1-n', 'OutputPower'],
    '5010': ['LO', '1-n', 'TransducerData'],
    '5011': ['SQ', '1', 'TransducerIdentificationSequence'],
    '5012': ['DS', '1', 'FocusDepth'],
    '5020': ['LO', '1', 'ProcessingFunction'],
    '5021': ['LO', '1', 'PostprocessingFunction'],
    '5022': ['DS', '1', 'MechanicalIndex'],
    '5024': ['DS', '1', 'BoneThermalIndex'],
    '5026': ['DS', '1', 'CranialThermalIndex'],
    '5027': ['DS', '1', 'SoftTissueThermalIndex'],
    '5028': ['DS', '1', 'SoftTissueFocusThermalIndex'],
    '5029': ['DS', '1', 'SoftTissueSurfaceThermalIndex'],
    '5030': ['DS', '1', 'DynamicRange'],
    '5040': ['DS', '1', 'TotalGain'],
    '5050': ['IS', '1', 'DepthOfScanField'],
    '5100': ['CS', '1', 'PatientPosition'],
    '5101': ['CS', '1', 'ViewPosition'],
    '5104': ['SQ', '1', 'ProjectionEponymousNameCodeSequence'],
    '5210': ['DS', '6', 'ImageTransformationMatrix'],
    '5212': ['DS', '3', 'ImageTranslationVector'],
    '6000': ['DS', '1', 'Sensitivity'],
    '6011': ['SQ', '1', 'SequenceOfUltrasoundRegions'],
    '6012': ['US', '1', 'RegionSpatialFormat'],
    '6014': ['US', '1', 'RegionDataType'],
    '6016': ['UL', '1', 'RegionFlags'],
    '6018': ['UL', '1', 'RegionLocationMinX0'],
    '601A': ['UL', '1', 'RegionLocationMinY0'],
    '601C': ['UL', '1', 'RegionLocationMaxX1'],
    '601E': ['UL', '1', 'RegionLocationMaxY1'],
    '6020': ['SL', '1', 'ReferencePixelX0'],
    '6022': ['SL', '1', 'ReferencePixelY0'],
    '6024': ['US', '1', 'PhysicalUnitsXDirection'],
    '6026': ['US', '1', 'PhysicalUnitsYDirection'],
    '6028': ['FD', '1', 'ReferencePixelPhysicalValueX'],
    '602A': ['FD', '1', 'ReferencePixelPhysicalValueY'],
    '602C': ['FD', '1', 'PhysicalDeltaX'],
    '602E': ['FD', '1', 'PhysicalDeltaY'],
    '6030': ['UL', '1', 'TransducerFrequency'],
    '6031': ['CS', '1', 'TransducerType'],
    '6032': ['UL', '1', 'PulseRepetitionFrequency'],
    '6034': ['FD', '1', 'DopplerCorrectionAngle'],
    '6036': ['FD', '1', 'SteeringAngle'],
    '6038': ['UL', '1', 'DopplerSampleVolumeXPositionRetired'],
    '6039': ['SL', '1', 'DopplerSampleVolumeXPosition'],
    '603A': ['UL', '1', 'DopplerSampleVolumeYPositionRetired'],
    '603B': ['SL', '1', 'DopplerSampleVolumeYPosition'],
    '603C': ['UL', '1', 'TMLinePositionX0Retired'],
    '603D': ['SL', '1', 'TMLinePositionX0'],
    '603E': ['UL', '1', 'TMLinePositionY0Retired'],
    '603F': ['SL', '1', 'TMLinePositionY0'],
    '6040': ['UL', '1', 'TMLinePositionX1Retired'],
    '6041': ['SL', '1', 'TMLinePositionX1'],
    '6042': ['UL', '1', 'TMLinePositionY1Retired'],
    '6043': ['SL', '1', 'TMLinePositionY1'],
    '6044': ['US', '1', 'PixelComponentOrganization'],
    '6046': ['UL', '1', 'PixelComponentMask'],
    '6048': ['UL', '1', 'PixelComponentRangeStart'],
    '604A': ['UL', '1', 'PixelComponentRangeStop'],
    '604C': ['US', '1', 'PixelComponentPhysicalUnits'],
    '604E': ['US', '1', 'PixelComponentDataType'],
    '6050': ['UL', '1', 'NumberOfTableBreakPoints'],
    '6052': ['UL', '1-n', 'TableOfXBreakPoints'],
    '6054': ['FD', '1-n', 'TableOfYBreakPoints'],
    '6056': ['UL', '1', 'NumberOfTableEntries'],
    '6058': ['UL', '1-n', 'TableOfPixelValues'],
    '605A': ['FL', '1-n', 'TableOfParameterValues'],
    '6060': ['FL', '1-n', 'RWaveTimeVector'],
    '6070': ['US', '1', 'ActiveImageAreaOverlayGroup'],
    '7000': ['CS', '1', 'DetectorConditionsNominalFlag'],
    '7001': ['DS', '1', 'DetectorTemperature'],
    '7004': ['CS', '1', 'DetectorType'],
    '7005': ['CS', '1', 'DetectorConfiguration'],
    '7006': ['LT', '1', 'DetectorDescription'],
    '7008': ['LT', '1', 'DetectorMode'],
    '700A': ['SH', '1', 'DetectorID'],
    '700C': ['DA', '1', 'DateOfLastDetectorCalibration'],
    '700E': ['TM', '1', 'TimeOfLastDetectorCalibration'],
    '7010': ['IS', '1', 'ExposuresOnDetectorSinceLastCalibration'],
    '7011': ['IS', '1', 'ExposuresOnDetectorSinceManufactured'],
    '7012': ['DS', '1', 'DetectorTimeSinceLastExposure'],
    '7014': ['DS', '1', 'DetectorActiveTime'],
    '7016': ['DS', '1', 'DetectorActivationOffsetFromExposure'],
    '701A': ['DS', '2', 'DetectorBinning'],
    '7020': ['DS', '2', 'DetectorElementPhysicalSize'],
    '7022': ['DS', '2', 'DetectorElementSpacing'],
    '7024': ['CS', '1', 'DetectorActiveShape'],
    '7026': ['DS', '1-2', 'DetectorActiveDimensions'],
    '7028': ['DS', '2', 'DetectorActiveOrigin'],
    '702A': ['LO', '1', 'DetectorManufacturerName'],
    '702B': ['LO', '1', 'DetectorManufacturerModelName'],
    '7030': ['DS', '2', 'FieldOfViewOrigin'],
    '7032': ['DS', '1', 'FieldOfViewRotation'],
    '7034': ['CS', '1', 'FieldOfViewHorizontalFlip'],
    '7036': ['FL', '2', 'PixelDataAreaOriginRelativeToFOV'],
    '7038': ['FL', '1', 'PixelDataAreaRotationAngleRelativeToFOV'],
    '7040': ['LT', '1', 'GridAbsorbingMaterial'],
    '7041': ['LT', '1', 'GridSpacingMaterial'],
    '7042': ['DS', '1', 'GridThickness'],
    '7044': ['DS', '1', 'GridPitch'],
    '7046': ['IS', '2', 'GridAspectRatio'],
    '7048': ['DS', '1', 'GridPeriod'],
    '704C': ['DS', '1', 'GridFocalDistance'],
    '7050': ['CS', '1-n', 'FilterMaterial'],
    '7052': ['DS', '1-n', 'FilterThicknessMinimum'],
    '7054': ['DS', '1-n', 'FilterThicknessMaximum'],
    '7056': ['FL', '1-n', 'FilterBeamPathLengthMinimum'],
    '7058': ['FL', '1-n', 'FilterBeamPathLengthMaximum'],
    '7060': ['CS', '1', 'ExposureControlMode'],
    '7062': ['LT', '1', 'ExposureControlModeDescription'],
    '7064': ['CS', '1', 'ExposureStatus'],
    '7065': ['DS', '1', 'PhototimerSetting'],
    '8150': ['DS', '1', 'ExposureTimeInuS'],
    '8151': ['DS', '1', 'XRayTubeCurrentInuA'],
    '9004': ['CS', '1', 'ContentQualification'],
    '9005': ['SH', '1', 'PulseSequenceName'],
    '9006': ['SQ', '1', 'MRImagingModifierSequence'],
    '9008': ['CS', '1', 'EchoPulseSequence'],
    '9009': ['CS', '1', 'InversionRecovery'],
    '9010': ['CS', '1', 'FlowCompensation'],
    '9011': ['CS', '1', 'MultipleSpinEcho'],
    '9012': ['CS', '1', 'MultiPlanarExcitation'],
    '9014': ['CS', '1', 'PhaseContrast'],
    '9015': ['CS', '1', 'TimeOfFlightContrast'],
    '9016': ['CS', '1', 'Spoiling'],
    '9017': ['CS', '1', 'SteadyStatePulseSequence'],
    '9018': ['CS', '1', 'EchoPlanarPulseSequence'],
    '9019': ['FD', '1', 'TagAngleFirstAxis'],
    '9020': ['CS', '1', 'MagnetizationTransfer'],
    '9021': ['CS', '1', 'T2Preparation'],
    '9022': ['CS', '1', 'BloodSignalNulling'],
    '9024': ['CS', '1', 'SaturationRecovery'],
    '9025': ['CS', '1', 'SpectrallySelectedSuppression'],
    '9026': ['CS', '1', 'SpectrallySelectedExcitation'],
    '9027': ['CS', '1', 'SpatialPresaturation'],
    '9028': ['CS', '1', 'Tagging'],
    '9029': ['CS', '1', 'OversamplingPhase'],
    '9030': ['FD', '1', 'TagSpacingFirstDimension'],
    '9032': ['CS', '1', 'GeometryOfKSpaceTraversal'],
    '9033': ['CS', '1', 'SegmentedKSpaceTraversal'],
    '9034': ['CS', '1', 'RectilinearPhaseEncodeReordering'],
    '9035': ['FD', '1', 'TagThickness'],
    '9036': ['CS', '1', 'PartialFourierDirection'],
    '9037': ['CS', '1', 'CardiacSynchronizationTechnique'],
    '9041': ['LO', '1', 'ReceiveCoilManufacturerName'],
    '9042': ['SQ', '1', 'MRReceiveCoilSequence'],
    '9043': ['CS', '1', 'ReceiveCoilType'],
    '9044': ['CS', '1', 'QuadratureReceiveCoil'],
    '9045': ['SQ', '1', 'MultiCoilDefinitionSequence'],
    '9046': ['LO', '1', 'MultiCoilConfiguration'],
    '9047': ['SH', '1', 'MultiCoilElementName'],
    '9048': ['CS', '1', 'MultiCoilElementUsed'],
    '9049': ['SQ', '1', 'MRTransmitCoilSequence'],
    '9050': ['LO', '1', 'TransmitCoilManufacturerName'],
    '9051': ['CS', '1', 'TransmitCoilType'],
    '9052': ['FD', '1-2', 'SpectralWidth'],
    '9053': ['FD', '1-2', 'ChemicalShiftReference'],
    '9054': ['CS', '1', 'VolumeLocalizationTechnique'],
    '9058': ['US', '1', 'MRAcquisitionFrequencyEncodingSteps'],
    '9059': ['CS', '1', 'Decoupling'],
    '9060': ['CS', '1-2', 'DecoupledNucleus'],
    '9061': ['FD', '1-2', 'DecouplingFrequency'],
    '9062': ['CS', '1', 'DecouplingMethod'],
    '9063': ['FD', '1-2', 'DecouplingChemicalShiftReference'],
    '9064': ['CS', '1', 'KSpaceFiltering'],
    '9065': ['CS', '1-2', 'TimeDomainFiltering'],
    '9066': ['US', '1-2', 'NumberOfZeroFills'],
    '9067': ['CS', '1', 'BaselineCorrection'],
    '9069': ['FD', '1', 'ParallelReductionFactorInPlane'],
    '9070': ['FD', '1', 'CardiacRRIntervalSpecified'],
    '9073': ['FD', '1', 'AcquisitionDuration'],
    '9074': ['DT', '1', 'FrameAcquisitionDateTime'],
    '9075': ['CS', '1', 'DiffusionDirectionality'],
    '9076': ['SQ', '1', 'DiffusionGradientDirectionSequence'],
    '9077': ['CS', '1', 'ParallelAcquisition'],
    '9078': ['CS', '1', 'ParallelAcquisitionTechnique'],
    '9079': ['FD', '1-n', 'InversionTimes'],
    '9080': ['ST', '1', 'MetaboliteMapDescription'],
    '9081': ['CS', '1', 'PartialFourier'],
    '9082': ['FD', '1', 'EffectiveEchoTime'],
    '9083': ['SQ', '1', 'MetaboliteMapCodeSequence'],
    '9084': ['SQ', '1', 'ChemicalShiftSequence'],
    '9085': ['CS', '1', 'CardiacSignalSource'],
    '9087': ['FD', '1', 'DiffusionBValue'],
    '9089': ['FD', '3', 'DiffusionGradientOrientation'],
    '9090': ['FD', '3', 'VelocityEncodingDirection'],
    '9091': ['FD', '1', 'VelocityEncodingMinimumValue'],
    '9092': ['SQ', '1', 'VelocityEncodingAcquisitionSequence'],
    '9093': ['US', '1', 'NumberOfKSpaceTrajectories'],
    '9094': ['CS', '1', 'CoverageOfKSpace'],
    '9095': ['UL', '1', 'SpectroscopyAcquisitionPhaseRows'],
    '9096': ['FD', '1', 'ParallelReductionFactorInPlaneRetired'],
    '9098': ['FD', '1-2', 'TransmitterFrequency'],
    '9100': ['CS', '1-2', 'ResonantNucleus'],
    '9101': ['CS', '1', 'FrequencyCorrection'],
    '9103': ['SQ', '1', 'MRSpectroscopyFOVGeometrySequence'],
    '9104': ['FD', '1', 'SlabThickness'],
    '9105': ['FD', '3', 'SlabOrientation'],
    '9106': ['FD', '3', 'MidSlabPosition'],
    '9107': ['SQ', '1', 'MRSpatialSaturationSequence'],
    '9112': ['SQ', '1', 'MRTimingAndRelatedParametersSequence'],
    '9114': ['SQ', '1', 'MREchoSequence'],
    '9115': ['SQ', '1', 'MRModifierSequence'],
    '9117': ['SQ', '1', 'MRDiffusionSequence'],
    '9118': ['SQ', '1', 'CardiacSynchronizationSequence'],
    '9119': ['SQ', '1', 'MRAveragesSequence'],
    '9125': ['SQ', '1', 'MRFOVGeometrySequence'],
    '9126': ['SQ', '1', 'VolumeLocalizationSequence'],
    '9127': ['UL', '1', 'SpectroscopyAcquisitionDataColumns'],
    '9147': ['CS', '1', 'DiffusionAnisotropyType'],
    '9151': ['DT', '1', 'FrameReferenceDateTime'],
    '9152': ['SQ', '1', 'MRMetaboliteMapSequence'],
    '9155': ['FD', '1', 'ParallelReductionFactorOutOfPlane'],
    '9159': ['UL', '1', 'SpectroscopyAcquisitionOutOfPlanePhaseSteps'],
    '9166': ['CS', '1', 'BulkMotionStatus'],
    '9168': ['FD', '1', 'ParallelReductionFactorSecondInPlane'],
    '9169': ['CS', '1', 'CardiacBeatRejectionTechnique'],
    '9170': ['CS', '1', 'RespiratoryMotionCompensationTechnique'],
    '9171': ['CS', '1', 'RespiratorySignalSource'],
    '9172': ['CS', '1', 'BulkMotionCompensationTechnique'],
    '9173': ['CS', '1', 'BulkMotionSignalSource'],
    '9174': ['CS', '1', 'ApplicableSafetyStandardAgency'],
    '9175': ['LO', '1', 'ApplicableSafetyStandardDescription'],
    '9176': ['SQ', '1', 'OperatingModeSequence'],
    '9177': ['CS', '1', 'OperatingModeType'],
    '9178': ['CS', '1', 'OperatingMode'],
    '9179': ['CS', '1', 'SpecificAbsorptionRateDefinition'],
    '9180': ['CS', '1', 'GradientOutputType'],
    '9181': ['FD', '1', 'SpecificAbsorptionRateValue'],
    '9182': ['FD', '1', 'GradientOutput'],
    '9183': ['CS', '1', 'FlowCompensationDirection'],
    '9184': ['FD', '1', 'TaggingDelay'],
    '9185': ['ST', '1', 'RespiratoryMotionCompensationTechniqueDescription'],
    '9186': ['SH', '1', 'RespiratorySignalSourceID'],
    '9195': ['FD', '1', 'ChemicalShiftMinimumIntegrationLimitInHz'],
    '9196': ['FD', '1', 'ChemicalShiftMaximumIntegrationLimitInHz'],
    '9197': ['SQ', '1', 'MRVelocityEncodingSequence'],
    '9198': ['CS', '1', 'FirstOrderPhaseCorrection'],
    '9199': ['CS', '1', 'WaterReferencedPhaseCorrection'],
    '9200': ['CS', '1', 'MRSpectroscopyAcquisitionType'],
    '9214': ['CS', '1', 'RespiratoryCyclePosition'],
    '9217': ['FD', '1', 'VelocityEncodingMaximumValue'],
    '9218': ['FD', '1', 'TagSpacingSecondDimension'],
    '9219': ['SS', '1', 'TagAngleSecondAxis'],
    '9220': ['FD', '1', 'FrameAcquisitionDuration'],
    '9226': ['SQ', '1', 'MRImageFrameTypeSequence'],
    '9227': ['SQ', '1', 'MRSpectroscopyFrameTypeSequence'],
    '9231': ['US', '1', 'MRAcquisitionPhaseEncodingStepsInPlane'],
    '9232': ['US', '1', 'MRAcquisitionPhaseEncodingStepsOutOfPlane'],
    '9234': ['UL', '1', 'SpectroscopyAcquisitionPhaseColumns'],
    '9236': ['CS', '1', 'CardiacCyclePosition'],
    '9239': ['SQ', '1', 'SpecificAbsorptionRateSequence'],
    '9240': ['US', '1', 'RFEchoTrainLength'],
    '9241': ['US', '1', 'GradientEchoTrainLength'],
    '9250': ['CS', '1', 'ArterialSpinLabelingContrast'],
    '9251': ['SQ', '1', 'MRArterialSpinLabelingSequence'],
    '9252': ['LO', '1', 'ASLTechniqueDescription'],
    '9253': ['US', '1', 'ASLSlabNumber'],
    '9254': ['FD', '1', 'ASLSlabThickness'],
    '9255': ['FD', '3', 'ASLSlabOrientation'],
    '9256': ['FD', '3', 'ASLMidSlabPosition'],
    '9257': ['CS', '1', 'ASLContext'],
    '9258': ['UL', '1', 'ASLPulseTrainDuration'],
    '9259': ['CS', '1', 'ASLCrusherFlag'],
    '925A': ['FD', '1', 'ASLCrusherFlowLimit'],
    '925B': ['LO', '1', 'ASLCrusherDescription'],
    '925C': ['CS', '1', 'ASLBolusCutoffFlag'],
    '925D': ['SQ', '1', 'ASLBolusCutoffTimingSequence'],
    '925E': ['LO', '1', 'ASLBolusCutoffTechnique'],
    '925F': ['UL', '1', 'ASLBolusCutoffDelayTime'],
    '9260': ['SQ', '1', 'ASLSlabSequence'],
    '9295': ['FD', '1', 'ChemicalShiftMinimumIntegrationLimitInppm'],
    '9296': ['FD', '1', 'ChemicalShiftMaximumIntegrationLimitInppm'],
    '9297': ['CS', '1', 'WaterReferenceAcquisition'],
    '9298': ['IS', '1', 'EchoPeakPosition'],
    '9301': ['SQ', '1', 'CTAcquisitionTypeSequence'],
    '9302': ['CS', '1', 'AcquisitionType'],
    '9303': ['FD', '1', 'TubeAngle'],
    '9304': ['SQ', '1', 'CTAcquisitionDetailsSequence'],
    '9305': ['FD', '1', 'RevolutionTime'],
    '9306': ['FD', '1', 'SingleCollimationWidth'],
    '9307': ['FD', '1', 'TotalCollimationWidth'],
    '9308': ['SQ', '1', 'CTTableDynamicsSequence'],
    '9309': ['FD', '1', 'TableSpeed'],
    '9310': ['FD', '1', 'TableFeedPerRotation'],
    '9311': ['FD', '1', 'SpiralPitchFactor'],
    '9312': ['SQ', '1', 'CTGeometrySequence'],
    '9313': ['FD', '3', 'DataCollectionCenterPatient'],
    '9314': ['SQ', '1', 'CTReconstructionSequence'],
    '9315': ['CS', '1', 'ReconstructionAlgorithm'],
    '9316': ['CS', '1', 'ConvolutionKernelGroup'],
    '9317': ['FD', '2', 'ReconstructionFieldOfView'],
    '9318': ['FD', '3', 'ReconstructionTargetCenterPatient'],
    '9319': ['FD', '1', 'ReconstructionAngle'],
    '9320': ['SH', '1', 'ImageFilter'],
    '9321': ['SQ', '1', 'CTExposureSequence'],
    '9322': ['FD', '2', 'ReconstructionPixelSpacing'],
    '9323': ['CS', '1-n', 'ExposureModulationType'],
    '9324': ['FD', '1', 'EstimatedDoseSaving'],
    '9325': ['SQ', '1', 'CTXRayDetailsSequence'],
    '9326': ['SQ', '1', 'CTPositionSequence'],
    '9327': ['FD', '1', 'TablePosition'],
    '9328': ['FD', '1', 'ExposureTimeInms'],
    '9329': ['SQ', '1', 'CTImageFrameTypeSequence'],
    '9330': ['FD', '1', 'XRayTubeCurrentInmA'],
    '9332': ['FD', '1', 'ExposureInmAs'],
    '9333': ['CS', '1', 'ConstantVolumeFlag'],
    '9334': ['CS', '1', 'FluoroscopyFlag'],
    '9335': ['FD', '1', 'DistanceSourceToDataCollectionCenter'],
    '9337': ['US', '1', 'ContrastBolusAgentNumber'],
    '9338': ['SQ', '1', 'ContrastBolusIngredientCodeSequence'],
    '9340': ['SQ', '1', 'ContrastAdministrationProfileSequence'],
    '9341': ['SQ', '1', 'ContrastBolusUsageSequence'],
    '9342': ['CS', '1', 'ContrastBolusAgentAdministered'],
    '9343': ['CS', '1', 'ContrastBolusAgentDetected'],
    '9344': ['CS', '1', 'ContrastBolusAgentPhase'],
    '9345': ['FD', '1', 'CTDIvol'],
    '9346': ['SQ', '1', 'CTDIPhantomTypeCodeSequence'],
    '9351': ['FL', '1', 'CalciumScoringMassFactorPatient'],
    '9352': ['FL', '3', 'CalciumScoringMassFactorDevice'],
    '9353': ['FL', '1', 'EnergyWeightingFactor'],
    '9360': ['SQ', '1', 'CTAdditionalXRaySourceSequence'],
    '9361': ['CS', '1', 'MultienergyCTAcquisition'],
    '9362': ['SQ', '1', 'MultienergyCTAcquisitionSequence'],
    '9363': ['SQ', '1', 'MultienergyCTProcessingSequence'],
    '9364': ['SQ', '1', 'MultienergyCTCharacteristicsSequence'],
    '9365': ['SQ', '1', 'MultienergyCTXRaySourceSequence'],
    '9366': ['US', '1', 'XRaySourceIndex'],
    '9367': ['UC', '1', 'XRaySourceID'],
    '9368': ['CS', '1', 'MultienergySourceTechnique'],
    '9369': ['DT', '1', 'SourceStartDateTime'],
    '936A': ['DT', '1', 'SourceEndDateTime'],
    '936B': ['US', '1', 'SwitchingPhaseNumber'],
    '936C': ['DS', '1', 'SwitchingPhaseNominalDuration'],
    '936D': ['DS', '1', 'SwitchingPhaseTransitionDuration'],
    '936E': ['DS', '1', 'EffectiveBinEnergy'],
    '936F': ['SQ', '1', 'MultienergyCTXRayDetectorSequence'],
    '9370': ['US', '1', 'XRayDetectorIndex'],
    '9371': ['UC', '1', 'XRayDetectorID'],
    '9372': ['CS', '1', 'MultienergyDetectorType'],
    '9373': ['ST', '1', 'XRayDetectorLabel'],
    '9374': ['DS', '1', 'NominalMaxEnergy'],
    '9375': ['DS', '1', 'NominalMinEnergy'],
    '9376': ['US', '1-n', 'ReferencedXRayDetectorIndex'],
    '9377': ['US', '1-n', 'ReferencedXRaySourceIndex'],
    '9378': ['US', '1-n', 'ReferencedPathIndex'],
    '9379': ['SQ', '1', 'MultienergyCTPathSequence'],
    '937A': ['US', '1', 'MultienergyCTPathIndex'],
    '937B': ['UT', '1', 'MultienergyAcquisitionDescription'],
    '937C': ['FD', '1', 'MonoenergeticEnergyEquivalent'],
    '937D': ['SQ', '1', 'MaterialCodeSequence'],
    '937E': ['CS', '1', 'DecompositionMethod'],
    '937F': ['UT', '1', 'DecompositionDescription'],
    '9380': ['SQ', '1', 'DecompositionAlgorithmIdentificationSequence'],
    '9381': ['SQ', '1', 'DecompositionMaterialSequence'],
    '9382': ['SQ', '1', 'MaterialAttenuationSequence'],
    '9383': ['DS', '1', 'PhotonEnergy'],
    '9384': ['DS', '1', 'XRayMassAttenuationCoefficient'],
    '9401': ['SQ', '1', 'ProjectionPixelCalibrationSequence'],
    '9402': ['FL', '1', 'DistanceSourceToIsocenter'],
    '9403': ['FL', '1', 'DistanceObjectToTableTop'],
    '9404': ['FL', '2', 'ObjectPixelSpacingInCenterOfBeam'],
    '9405': ['SQ', '1', 'PositionerPositionSequence'],
    '9406': ['SQ', '1', 'TablePositionSequence'],
    '9407': ['SQ', '1', 'CollimatorShapeSequence'],
    '9410': ['CS', '1', 'PlanesInAcquisition'],
    '9412': ['SQ', '1', 'XAXRFFrameCharacteristicsSequence'],
    '9417': ['SQ', '1', 'FrameAcquisitionSequence'],
    '9420': ['CS', '1', 'XRayReceptorType'],
    '9423': ['LO', '1', 'AcquisitionProtocolName'],
    '9424': ['LT', '1', 'AcquisitionProtocolDescription'],
    '9425': ['CS', '1', 'ContrastBolusIngredientOpaque'],
    '9426': ['FL', '1', 'DistanceReceptorPlaneToDetectorHousing'],
    '9427': ['CS', '1', 'IntensifierActiveShape'],
    '9428': ['FL', '1-2', 'IntensifierActiveDimensions'],
    '9429': ['FL', '2', 'PhysicalDetectorSize'],
    '9430': ['FL', '2', 'PositionOfIsocenterProjection'],
    '9432': ['SQ', '1', 'FieldOfViewSequence'],
    '9433': ['LO', '1', 'FieldOfViewDescription'],
    '9434': ['SQ', '1', 'ExposureControlSensingRegionsSequence'],
    '9435': ['CS', '1', 'ExposureControlSensingRegionShape'],
    '9436': ['SS', '1', 'ExposureControlSensingRegionLeftVerticalEdge'],
    '9437': ['SS', '1', 'ExposureControlSensingRegionRightVerticalEdge'],
    '9438': ['SS', '1', 'ExposureControlSensingRegionUpperHorizontalEdge'],
    '9439': ['SS', '1', 'ExposureControlSensingRegionLowerHorizontalEdge'],
    '9440': ['SS', '2', 'CenterOfCircularExposureControlSensingRegion'],
    '9441': ['US', '1', 'RadiusOfCircularExposureControlSensingRegion'],
    '9442': ['SS', '2-n', 'VerticesOfThePolygonalExposureControlSensingRegion'],
    '9445': ['', '', ''],
    '9447': ['FL', '1', 'ColumnAngulationPatient'],
    '9449': ['FL', '1', 'BeamAngle'],
    '9451': ['SQ', '1', 'FrameDetectorParametersSequence'],
    '9452': ['FL', '1', 'CalculatedAnatomyThickness'],
    '9455': ['SQ', '1', 'CalibrationSequence'],
    '9456': ['SQ', '1', 'ObjectThicknessSequence'],
    '9457': ['CS', '1', 'PlaneIdentification'],
    '9461': ['FL', '1-2', 'FieldOfViewDimensionsInFloat'],
    '9462': ['SQ', '1', 'IsocenterReferenceSystemSequence'],
    '9463': ['FL', '1', 'PositionerIsocenterPrimaryAngle'],
    '9464': ['FL', '1', 'PositionerIsocenterSecondaryAngle'],
    '9465': ['FL', '1', 'PositionerIsocenterDetectorRotationAngle'],
    '9466': ['FL', '1', 'TableXPositionToIsocenter'],
    '9467': ['FL', '1', 'TableYPositionToIsocenter'],
    '9468': ['FL', '1', 'TableZPositionToIsocenter'],
    '9469': ['FL', '1', 'TableHorizontalRotationAngle'],
    '9470': ['FL', '1', 'TableHeadTiltAngle'],
    '9471': ['FL', '1', 'TableCradleTiltAngle'],
    '9472': ['SQ', '1', 'FrameDisplayShutterSequence'],
    '9473': ['FL', '1', 'AcquiredImageAreaDoseProduct'],
    '9474': ['CS', '1', 'CArmPositionerTabletopRelationship'],
    '9476': ['SQ', '1', 'XRayGeometrySequence'],
    '9477': ['SQ', '1', 'IrradiationEventIdentificationSequence'],
    '9504': ['SQ', '1', 'XRay3DFrameTypeSequence'],
    '9506': ['SQ', '1', 'ContributingSourcesSequence'],
    '9507': ['SQ', '1', 'XRay3DAcquisitionSequence'],
    '9508': ['FL', '1', 'PrimaryPositionerScanArc'],
    '9509': ['FL', '1', 'SecondaryPositionerScanArc'],
    '9510': ['FL', '1', 'PrimaryPositionerScanStartAngle'],
    '9511': ['FL', '1', 'SecondaryPositionerScanStartAngle'],
    '9514': ['FL', '1', 'PrimaryPositionerIncrement'],
    '9515': ['FL', '1', 'SecondaryPositionerIncrement'],
    '9516': ['DT', '1', 'StartAcquisitionDateTime'],
    '9517': ['DT', '1', 'EndAcquisitionDateTime'],
    '9518': ['SS', '1', 'PrimaryPositionerIncrementSign'],
    '9519': ['SS', '1', 'SecondaryPositionerIncrementSign'],
    '9524': ['LO', '1', 'ApplicationName'],
    '9525': ['LO', '1', 'ApplicationVersion'],
    '9526': ['LO', '1', 'ApplicationManufacturer'],
    '9527': ['CS', '1', 'AlgorithmType'],
    '9528': ['LO', '1', 'AlgorithmDescription'],
    '9530': ['SQ', '1', 'XRay3DReconstructionSequence'],
    '9531': ['LO', '1', 'ReconstructionDescription'],
    '9538': ['SQ', '1', 'PerProjectionAcquisitionSequence'],
    '9541': ['SQ', '1', 'DetectorPositionSequence'],
    '9542': ['SQ', '1', 'XRayAcquisitionDoseSequence'],
    '9543': ['FD', '1', 'XRaySourceIsocenterPrimaryAngle'],
    '9544': ['FD', '1', 'XRaySourceIsocenterSecondaryAngle'],
    '9545': ['FD', '1', 'BreastSupportIsocenterPrimaryAngle'],
    '9546': ['FD', '1', 'BreastSupportIsocenterSecondaryAngle'],
    '9547': ['FD', '1', 'BreastSupportXPositionToIsocenter'],
    '9548': ['FD', '1', 'BreastSupportYPositionToIsocenter'],
    '9549': ['FD', '1', 'BreastSupportZPositionToIsocenter'],
    '9550': ['FD', '1', 'DetectorIsocenterPrimaryAngle'],
    '9551': ['FD', '1', 'DetectorIsocenterSecondaryAngle'],
    '9552': ['FD', '1', 'DetectorXPositionToIsocenter'],
    '9553': ['FD', '1', 'DetectorYPositionToIsocenter'],
    '9554': ['FD', '1', 'DetectorZPositionToIsocenter'],
    '9555': ['SQ', '1', 'XRayGridSequence'],
    '9556': ['SQ', '1', 'XRayFilterSequence'],
    '9557': ['FD', '3', 'DetectorActiveAreaTLHCPosition'],
    '9558': ['FD', '6', 'DetectorActiveAreaOrientation'],
    '9559': ['CS', '1', 'PositionerPrimaryAngleDirection'],
    '9601': ['SQ', '1', 'DiffusionBMatrixSequence'],
    '9602': ['FD', '1', 'DiffusionBValueXX'],
    '9603': ['FD', '1', 'DiffusionBValueXY'],
    '9604': ['FD', '1', 'DiffusionBValueXZ'],
    '9605': ['FD', '1', 'DiffusionBValueYY'],
    '9606': ['FD', '1', 'DiffusionBValueYZ'],
    '9607': ['FD', '1', 'DiffusionBValueZZ'],
    '9621': ['SQ', '1', 'FunctionalMRSequence'],
    '9622': ['CS', '1', 'FunctionalSettlingPhaseFramesPresent'],
    '9623': ['DT', '1', 'FunctionalSyncPulse'],
    '9624': ['CS', '1', 'SettlingPhaseFrame'],
    '9701': ['DT', '1', 'DecayCorrectionDateTime'],
    '9715': ['FD', '1', 'StartDensityThreshold'],
    '9716': ['FD', '1', 'StartRelativeDensityDifferenceThreshold'],
    '9717': ['FD', '1', 'StartCardiacTriggerCountThreshold'],
    '9718': ['FD', '1', 'StartRespiratoryTriggerCountThreshold'],
    '9719': ['FD', '1', 'TerminationCountsThreshold'],
    '9720': ['FD', '1', 'TerminationDensityThreshold'],
    '9721': ['FD', '1', 'TerminationRelativeDensityThreshold'],
    '9722': ['FD', '1', 'TerminationTimeThreshold'],
    '9723': ['FD', '1', 'TerminationCardiacTriggerCountThreshold'],
    '9724': ['FD', '1', 'TerminationRespiratoryTriggerCountThreshold'],
    '9725': ['CS', '1', 'DetectorGeometry'],
    '9726': ['FD', '1', 'TransverseDetectorSeparation'],
    '9727': ['FD', '1', 'AxialDetectorDimension'],
    '9729': ['US', '1', 'RadiopharmaceuticalAgentNumber'],
    '9732': ['SQ', '1', 'PETFrameAcquisitionSequence'],
    '9733': ['SQ', '1', 'PETDetectorMotionDetailsSequence'],
    '9734': ['SQ', '1', 'PETTableDynamicsSequence'],
    '9735': ['SQ', '1', 'PETPositionSequence'],
    '9736': ['SQ', '1', 'PETFrameCorrectionFactorsSequence'],
    '9737': ['SQ', '1', 'RadiopharmaceuticalUsageSequence'],
    '9738': ['CS', '1', 'AttenuationCorrectionSource'],
    '9739': ['US', '1', 'NumberOfIterations'],
    '9740': ['US', '1', 'NumberOfSubsets'],
    '9749': ['SQ', '1', 'PETReconstructionSequence'],
    '9751': ['SQ', '1', 'PETFrameTypeSequence'],
    '9755': ['CS', '1', 'TimeOfFlightInformationUsed'],
    '9756': ['CS', '1', 'ReconstructionType'],
    '9758': ['CS', '1', 'DecayCorrected'],
    '9759': ['CS', '1', 'AttenuationCorrected'],
    '9760': ['CS', '1', 'ScatterCorrected'],
    '9761': ['CS', '1', 'DeadTimeCorrected'],
    '9762': ['CS', '1', 'GantryMotionCorrected'],
    '9763': ['CS', '1', 'PatientMotionCorrected'],
    '9764': ['CS', '1', 'CountLossNormalizationCorrected'],
    '9765': ['CS', '1', 'RandomsCorrected'],
    '9766': ['CS', '1', 'NonUniformRadialSamplingCorrected'],
    '9767': ['CS', '1', 'SensitivityCalibrated'],
    '9768': ['CS', '1', 'DetectorNormalizationCorrection'],
    '9769': ['CS', '1', 'IterativeReconstructionMethod'],
    '9770': ['CS', '1', 'AttenuationCorrectionTemporalRelationship'],
    '9771': ['SQ', '1', 'PatientPhysiologicalStateSequence'],
    '9772': ['SQ', '1', 'PatientPhysiologicalStateCodeSequence'],
    '9801': ['FD', '1-n', 'DepthsOfFocus'],
    '9803': ['SQ', '1', 'ExcludedIntervalsSequence'],
    '9804': ['DT', '1', 'ExclusionStartDateTime'],
    '9805': ['FD', '1', 'ExclusionDuration'],
    '9806': ['SQ', '1', 'USImageDescriptionSequence'],
    '9807': ['SQ', '1', 'ImageDataTypeSequence'],
    '9808': ['CS', '1', 'DataType'],
    '9809': ['SQ', '1', 'TransducerScanPatternCodeSequence'],
    '980B': ['CS', '1', 'AliasedDataType'],
    '980C': ['CS', '1', 'PositionMeasuringDeviceUsed'],
    '980D': ['SQ', '1', 'TransducerGeometryCodeSequence'],
    '980E': ['SQ', '1', 'TransducerBeamSteeringCodeSequence'],
    '980F': ['SQ', '1', 'TransducerApplicationCodeSequence'],
    '9810': ['xs', '1', 'ZeroVelocityPixelValue'],
    '9900': ['LO', '1', 'ReferenceLocationLabel'],
    '9901': ['UT', '1', 'ReferenceLocationDescription'],
    '9902': ['SQ', '1', 'ReferenceBasisCodeSequence'],
    '9903': ['SQ', '1', 'ReferenceGeometryCodeSequence'],
    '9904': ['DS', '1', 'OffsetDistance'],
    '9905': ['CS', '1', 'OffsetDirection'],
    '9906': ['SQ', '1', 'PotentialScheduledProtocolCodeSequence'],
    '9907': ['SQ', '1', 'PotentialRequestedProcedureCodeSequence'],
    '9908': ['UC', '1-n', 'PotentialReasonsForProcedure'],
    '9909': ['SQ', '1', 'PotentialReasonsForProcedureCodeSequence'],
    '990A': ['UC', '1-n', 'PotentialDiagnosticTasks'],
    '990B': ['SQ', '1', 'ContraindicationsCodeSequence'],
    '990C': ['SQ', '1', 'ReferencedDefinedProtocolSequence'],
    '990D': ['SQ', '1', 'ReferencedPerformedProtocolSequence'],
    '990E': ['SQ', '1', 'PredecessorProtocolSequence'],
    '990F': ['UT', '1', 'ProtocolPlanningInformation'],
    '9910': ['UT', '1', 'ProtocolDesignRationale'],
    '9911': ['SQ', '1', 'PatientSpecificationSequence'],
    '9912': ['SQ', '1', 'ModelSpecificationSequence'],
    '9913': ['SQ', '1', 'ParametersSpecificationSequence'],
    '9914': ['SQ', '1', 'InstructionSequence'],
    '9915': ['US', '1', 'InstructionIndex'],
    '9916': ['LO', '1', 'InstructionText'],
    '9917': ['UT', '1', 'InstructionDescription'],
    '9918': ['CS', '1', 'InstructionPerformedFlag'],
    '9919': ['DT', '1', 'InstructionPerformedDateTime'],
    '991A': ['UT', '1', 'InstructionPerformanceComment'],
    '991B': ['SQ', '1', 'PatientPositioningInstructionSequence'],
    '991C': ['SQ', '1', 'PositioningMethodCodeSequence'],
    '991D': ['SQ', '1', 'PositioningLandmarkSequence'],
    '991E': ['UI', '1', 'TargetFrameOfReferenceUID'],
    '991F': ['SQ', '1', 'AcquisitionProtocolElementSpecificationSequence'],
    '9920': ['SQ', '1', 'AcquisitionProtocolElementSequence'],
    '9921': ['US', '1', 'ProtocolElementNumber'],
    '9922': ['LO', '1', 'ProtocolElementName'],
    '9923': ['UT', '1', 'ProtocolElementCharacteristicsSummary'],
    '9924': ['UT', '1', 'ProtocolElementPurpose'],
    '9930': ['CS', '1', 'AcquisitionMotion'],
    '9931': ['SQ', '1', 'AcquisitionStartLocationSequence'],
    '9932': ['SQ', '1', 'AcquisitionEndLocationSequence'],
    '9933': ['SQ', '1', 'ReconstructionProtocolElementSpecificationSequence'],
    '9934': ['SQ', '1', 'ReconstructionProtocolElementSequence'],
    '9935': ['SQ', '1', 'StorageProtocolElementSpecificationSequence'],
    '9936': ['SQ', '1', 'StorageProtocolElementSequence'],
    '9937': ['LO', '1', 'RequestedSeriesDescription'],
    '9938': ['US', '1-n', 'SourceAcquisitionProtocolElementNumber'],
    '9939': ['US', '1-n', 'SourceAcquisitionBeamNumber'],
    '993A': ['US', '1-n', 'SourceReconstructionProtocolElementNumber'],
    '993B': ['SQ', '1', 'ReconstructionStartLocationSequence'],
    '993C': ['SQ', '1', 'ReconstructionEndLocationSequence'],
    '993D': ['SQ', '1', 'ReconstructionAlgorithmSequence'],
    '993E': ['SQ', '1', 'ReconstructionTargetCenterLocationSequence'],
    '9941': ['UT', '1', 'ImageFilterDescription'],
    '9942': ['FD', '1', 'CTDIvolNotificationTrigger'],
    '9943': ['FD', '1', 'DLPNotificationTrigger'],
    '9944': ['CS', '1', 'AutoKVPSelectionType'],
    '9945': ['FD', '1', 'AutoKVPUpperBound'],
    '9946': ['FD', '1', 'AutoKVPLowerBound'],
    '9947': ['CS', '1', 'ProtocolDefinedPatientPosition'],
    'A001': ['SQ', '1', 'ContributingEquipmentSequence'],
    'A002': ['DT', '1', 'ContributionDateTime'],
    'A003': ['ST', '1', 'ContributionDescription']
  },
  '0020': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '000D': ['UI', '1', 'StudyInstanceUID'],
    '000E': ['UI', '1', 'SeriesInstanceUID'],
    '0010': ['SH', '1', 'StudyID'],
    '0011': ['IS', '1', 'SeriesNumber'],
    '0012': ['IS', '1', 'AcquisitionNumber'],
    '0013': ['IS', '1', 'InstanceNumber'],
    '0014': ['IS', '1', 'IsotopeNumber'],
    '0015': ['IS', '1', 'PhaseNumber'],
    '0016': ['IS', '1', 'IntervalNumber'],
    '0017': ['IS', '1', 'TimeSlotNumber'],
    '0018': ['IS', '1', 'AngleNumber'],
    '0019': ['IS', '1', 'ItemNumber'],
    '0020': ['CS', '2', 'PatientOrientation'],
    '0022': ['IS', '1', 'OverlayNumber'],
    '0024': ['IS', '1', 'CurveNumber'],
    '0026': ['IS', '1', 'LUTNumber'],
    '0030': ['DS', '3', 'ImagePosition'],
    '0032': ['DS', '3', 'ImagePositionPatient'],
    '0035': ['DS', '6', 'ImageOrientation'],
    '0037': ['DS', '6', 'ImageOrientationPatient'],
    '0050': ['DS', '1', 'Location'],
    '0052': ['UI', '1', 'FrameOfReferenceUID'],
    '0060': ['CS', '1', 'Laterality'],
    '0062': ['CS', '1', 'ImageLaterality'],
    '0070': ['LO', '1', 'ImageGeometryType'],
    '0080': ['CS', '1-n', 'MaskingImage'],
    '00AA': ['IS', '1', 'ReportNumber'],
    '0100': ['IS', '1', 'TemporalPositionIdentifier'],
    '0105': ['IS', '1', 'NumberOfTemporalPositions'],
    '0110': ['DS', '1', 'TemporalResolution'],
    '0200': ['UI', '1', 'SynchronizationFrameOfReferenceUID'],
    '0242': ['UI', '1', 'SOPInstanceUIDOfConcatenationSource'],
    '1000': ['IS', '1', 'SeriesInStudy'],
    '1001': ['IS', '1', 'AcquisitionsInSeries'],
    '1002': ['IS', '1', 'ImagesInAcquisition'],
    '1003': ['IS', '1', 'ImagesInSeries'],
    '1004': ['IS', '1', 'AcquisitionsInStudy'],
    '1005': ['IS', '1', 'ImagesInStudy'],
    '1020': ['LO', '1-n', 'Reference'],
    '103F': ['LO', '1', 'TargetPositionReferenceIndicator'],
    '1040': ['LO', '1', 'PositionReferenceIndicator'],
    '1041': ['DS', '1', 'SliceLocation'],
    '1070': ['IS', '1-n', 'OtherStudyNumbers'],
    '1200': ['IS', '1', 'NumberOfPatientRelatedStudies'],
    '1202': ['IS', '1', 'NumberOfPatientRelatedSeries'],
    '1204': ['IS', '1', 'NumberOfPatientRelatedInstances'],
    '1206': ['IS', '1', 'NumberOfStudyRelatedSeries'],
    '1208': ['IS', '1', 'NumberOfStudyRelatedInstances'],
    '1209': ['IS', '1', 'NumberOfSeriesRelatedInstances'],
    '3100': ['CS', '1-n', 'SourceImageIDs'],
    '3401': ['CS', '1', 'ModifyingDeviceID'],
    '3402': ['CS', '1', 'ModifiedImageID'],
    '3403': ['DA', '1', 'ModifiedImageDate'],
    '3404': ['LO', '1', 'ModifyingDeviceManufacturer'],
    '3405': ['TM', '1', 'ModifiedImageTime'],
    '3406': ['LO', '1', 'ModifiedImageDescription'],
    '4000': ['LT', '1', 'ImageComments'],
    '5000': ['AT', '1-n', 'OriginalImageIdentification'],
    '5002': ['LO', '1-n', 'OriginalImageIdentificationNomenclature'],
    '9056': ['SH', '1', 'StackID'],
    '9057': ['UL', '1', 'InStackPositionNumber'],
    '9071': ['SQ', '1', 'FrameAnatomySequence'],
    '9072': ['CS', '1', 'FrameLaterality'],
    '9111': ['SQ', '1', 'FrameContentSequence'],
    '9113': ['SQ', '1', 'PlanePositionSequence'],
    '9116': ['SQ', '1', 'PlaneOrientationSequence'],
    '9128': ['UL', '1', 'TemporalPositionIndex'],
    '9153': ['FD', '1', 'NominalCardiacTriggerDelayTime'],
    '9154': ['FL', '1', 'NominalCardiacTriggerTimePriorToRPeak'],
    '9155': ['FL', '1', 'ActualCardiacTriggerTimePriorToRPeak'],
    '9156': ['US', '1', 'FrameAcquisitionNumber'],
    '9157': ['UL', '1-n', 'DimensionIndexValues'],
    '9158': ['LT', '1', 'FrameComments'],
    '9161': ['UI', '1', 'ConcatenationUID'],
    '9162': ['US', '1', 'InConcatenationNumber'],
    '9163': ['US', '1', 'InConcatenationTotalNumber'],
    '9164': ['UI', '1', 'DimensionOrganizationUID'],
    '9165': ['AT', '1', 'DimensionIndexPointer'],
    '9167': ['AT', '1', 'FunctionalGroupPointer'],
    '9170': ['SQ', '1', 'UnassignedSharedConvertedAttributesSequence'],
    '9171': ['SQ', '1', 'UnassignedPerFrameConvertedAttributesSequence'],
    '9172': ['SQ', '1', 'ConversionSourceAttributesSequence'],
    '9213': ['LO', '1', 'DimensionIndexPrivateCreator'],
    '9221': ['SQ', '1', 'DimensionOrganizationSequence'],
    '9222': ['SQ', '1', 'DimensionIndexSequence'],
    '9228': ['UL', '1', 'ConcatenationFrameOffsetNumber'],
    '9238': ['LO', '1', 'FunctionalGroupPrivateCreator'],
    '9241': ['FL', '1', 'NominalPercentageOfCardiacPhase'],
    '9245': ['FL', '1', 'NominalPercentageOfRespiratoryPhase'],
    '9246': ['FL', '1', 'StartingRespiratoryAmplitude'],
    '9247': ['CS', '1', 'StartingRespiratoryPhase'],
    '9248': ['FL', '1', 'EndingRespiratoryAmplitude'],
    '9249': ['CS', '1', 'EndingRespiratoryPhase'],
    '9250': ['CS', '1', 'RespiratoryTriggerType'],
    '9251': ['FD', '1', 'RRIntervalTimeNominal'],
    '9252': ['FD', '1', 'ActualCardiacTriggerDelayTime'],
    '9253': ['SQ', '1', 'RespiratorySynchronizationSequence'],
    '9254': ['FD', '1', 'RespiratoryIntervalTime'],
    '9255': ['FD', '1', 'NominalRespiratoryTriggerDelayTime'],
    '9256': ['FD', '1', 'RespiratoryTriggerDelayThreshold'],
    '9257': ['FD', '1', 'ActualRespiratoryTriggerDelayTime'],
    '9301': ['FD', '3', 'ImagePositionVolume'],
    '9302': ['FD', '6', 'ImageOrientationVolume'],
    '9307': ['CS', '1', 'UltrasoundAcquisitionGeometry'],
    '9308': ['FD', '3', 'ApexPosition'],
    '9309': ['FD', '16', 'VolumeToTransducerMappingMatrix'],
    '930A': ['FD', '16', 'VolumeToTableMappingMatrix'],
    '930B': ['CS', '1', 'VolumeToTransducerRelationship'],
    '930C': ['CS', '1', 'PatientFrameOfReferenceSource'],
    '930D': ['FD', '1', 'TemporalPositionTimeOffset'],
    '930E': ['SQ', '1', 'PlanePositionVolumeSequence'],
    '930F': ['SQ', '1', 'PlaneOrientationVolumeSequence'],
    '9310': ['SQ', '1', 'TemporalPositionSequence'],
    '9311': ['CS', '1', 'DimensionOrganizationType'],
    '9312': ['UI', '1', 'VolumeFrameOfReferenceUID'],
    '9313': ['UI', '1', 'TableFrameOfReferenceUID'],
    '9421': ['LO', '1', 'DimensionDescriptionLabel'],
    '9450': ['SQ', '1', 'PatientOrientationInFrameSequence'],
    '9453': ['LO', '1', 'FrameLabel'],
    '9518': ['US', '1-n', 'AcquisitionIndex'],
    '9529': ['SQ', '1', 'ContributingSOPInstancesReferenceSequence'],
    '9536': ['US', '1', 'ReconstructionIndex']
  },
  '0022': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['US', '1', 'LightPathFilterPassThroughWavelength'],
    '0002': ['US', '2', 'LightPathFilterPassBand'],
    '0003': ['US', '1', 'ImagePathFilterPassThroughWavelength'],
    '0004': ['US', '2', 'ImagePathFilterPassBand'],
    '0005': ['CS', '1', 'PatientEyeMovementCommanded'],
    '0006': ['SQ', '1', 'PatientEyeMovementCommandCodeSequence'],
    '0007': ['FL', '1', 'SphericalLensPower'],
    '0008': ['FL', '1', 'CylinderLensPower'],
    '0009': ['FL', '1', 'CylinderAxis'],
    '000A': ['FL', '1', 'EmmetropicMagnification'],
    '000B': ['FL', '1', 'IntraOcularPressure'],
    '000C': ['FL', '1', 'HorizontalFieldOfView'],
    '000D': ['CS', '1', 'PupilDilated'],
    '000E': ['FL', '1', 'DegreeOfDilation'],
    '0010': ['FL', '1', 'StereoBaselineAngle'],
    '0011': ['FL', '1', 'StereoBaselineDisplacement'],
    '0012': ['FL', '1', 'StereoHorizontalPixelOffset'],
    '0013': ['FL', '1', 'StereoVerticalPixelOffset'],
    '0014': ['FL', '1', 'StereoRotation'],
    '0015': ['SQ', '1', 'AcquisitionDeviceTypeCodeSequence'],
    '0016': ['SQ', '1', 'IlluminationTypeCodeSequence'],
    '0017': ['SQ', '1', 'LightPathFilterTypeStackCodeSequence'],
    '0018': ['SQ', '1', 'ImagePathFilterTypeStackCodeSequence'],
    '0019': ['SQ', '1', 'LensesCodeSequence'],
    '001A': ['SQ', '1', 'ChannelDescriptionCodeSequence'],
    '001B': ['SQ', '1', 'RefractiveStateSequence'],
    '001C': ['SQ', '1', 'MydriaticAgentCodeSequence'],
    '001D': ['SQ', '1', 'RelativeImagePositionCodeSequence'],
    '001E': ['FL', '1', 'CameraAngleOfView'],
    '0020': ['SQ', '1', 'StereoPairsSequence'],
    '0021': ['SQ', '1', 'LeftImageSequence'],
    '0022': ['SQ', '1', 'RightImageSequence'],
    '0028': ['CS', '1', 'StereoPairsPresent'],
    '0030': ['FL', '1', 'AxialLengthOfTheEye'],
    '0031': ['SQ', '1', 'OphthalmicFrameLocationSequence'],
    '0032': ['FL', '2-2n', 'ReferenceCoordinates'],
    '0035': ['FL', '1', 'DepthSpatialResolution'],
    '0036': ['FL', '1', 'MaximumDepthDistortion'],
    '0037': ['FL', '1', 'AlongScanSpatialResolution'],
    '0038': ['FL', '1', 'MaximumAlongScanDistortion'],
    '0039': ['CS', '1', 'OphthalmicImageOrientation'],
    '0041': ['FL', '1', 'DepthOfTransverseImage'],
    '0042': ['SQ', '1', 'MydriaticAgentConcentrationUnitsSequence'],
    '0048': ['FL', '1', 'AcrossScanSpatialResolution'],
    '0049': ['FL', '1', 'MaximumAcrossScanDistortion'],
    '004E': ['DS', '1', 'MydriaticAgentConcentration'],
    '0055': ['FL', '1', 'IlluminationWaveLength'],
    '0056': ['FL', '1', 'IlluminationPower'],
    '0057': ['FL', '1', 'IlluminationBandwidth'],
    '0058': ['SQ', '1', 'MydriaticAgentSequence'],
    '1007': ['SQ', '1', 'OphthalmicAxialMeasurementsRightEyeSequence'],
    '1008': ['SQ', '1', 'OphthalmicAxialMeasurementsLeftEyeSequence'],
    '1009': ['CS', '1', 'OphthalmicAxialMeasurementsDeviceType'],
    '1010': ['CS', '1', 'OphthalmicAxialLengthMeasurementsType'],
    '1012': ['SQ', '1', 'OphthalmicAxialLengthSequence'],
    '1019': ['FL', '1', 'OphthalmicAxialLength'],
    '1024': ['SQ', '1', 'LensStatusCodeSequence'],
    '1025': ['SQ', '1', 'VitreousStatusCodeSequence'],
    '1028': ['SQ', '1', 'IOLFormulaCodeSequence'],
    '1029': ['LO', '1', 'IOLFormulaDetail'],
    '1033': ['FL', '1', 'KeratometerIndex'],
    '1035': ['SQ', '1', 'SourceOfOphthalmicAxialLengthCodeSequence'],
    '1036': ['SQ', '1', 'SourceOfCornealSizeDataCodeSequence'],
    '1037': ['FL', '1', 'TargetRefraction'],
    '1039': ['CS', '1', 'RefractiveProcedureOccurred'],
    '1040': ['SQ', '1', 'RefractiveSurgeryTypeCodeSequence'],
    '1044': ['SQ', '1', 'OphthalmicUltrasoundMethodCodeSequence'],
    '1045': ['SQ', '1', 'SurgicallyInducedAstigmatismSequence'],
    '1046': ['CS', '1', 'TypeOfOpticalCorrection'],
    '1047': ['SQ', '1', 'ToricIOLPowerSequence'],
    '1048': ['SQ', '1', 'PredictedToricErrorSequence'],
    '1049': ['CS', '1', 'PreSelectedForImplantation'],
    '104A': ['SQ', '1', 'ToricIOLPowerForExactEmmetropiaSequence'],
    '104B': ['SQ', '1', 'ToricIOLPowerForExactTargetRefractionSequence'],
    '1050': ['SQ', '1', 'OphthalmicAxialLengthMeasurementsSequence'],
    '1053': ['FL', '1', 'IOLPower'],
    '1054': ['FL', '1', 'PredictedRefractiveError'],
    '1059': ['FL', '1', 'OphthalmicAxialLengthVelocity'],
    '1065': ['LO', '1', 'LensStatusDescription'],
    '1066': ['LO', '1', 'VitreousStatusDescription'],
    '1090': ['SQ', '1', 'IOLPowerSequence'],
    '1092': ['SQ', '1', 'LensConstantSequence'],
    '1093': ['LO', '1', 'IOLManufacturer'],
    '1094': ['LO', '1', 'LensConstantDescription'],
    '1095': ['LO', '1', 'ImplantName'],
    '1096': ['SQ', '1', 'KeratometryMeasurementTypeCodeSequence'],
    '1097': ['LO', '1', 'ImplantPartNumber'],
    '1100': ['SQ', '1', 'ReferencedOphthalmicAxialMeasurementsSequence'],
    '1101': ['SQ', '1', 'OphthalmicAxialLengthMeasurementsSegmentNameCodeSequence'],
    '1103': ['SQ', '1', 'RefractiveErrorBeforeRefractiveSurgeryCodeSequence'],
    '1121': ['FL', '1', 'IOLPowerForExactEmmetropia'],
    '1122': ['FL', '1', 'IOLPowerForExactTargetRefraction'],
    '1125': ['SQ', '1', 'AnteriorChamberDepthDefinitionCodeSequence'],
    '1127': ['SQ', '1', 'LensThicknessSequence'],
    '1128': ['SQ', '1', 'AnteriorChamberDepthSequence'],
    '112A': ['SQ', '1', 'CalculationCommentSequence'],
    '112B': ['CS', '1', 'CalculationCommentType'],
    '112C': ['LT', '1', 'CalculationComment'],
    '1130': ['FL', '1', 'LensThickness'],
    '1131': ['FL', '1', 'AnteriorChamberDepth'],
    '1132': ['SQ', '1', 'SourceOfLensThicknessDataCodeSequence'],
    '1133': ['SQ', '1', 'SourceOfAnteriorChamberDepthDataCodeSequence'],
    '1134': ['SQ', '1', 'SourceOfRefractiveMeasurementsSequence'],
    '1135': ['SQ', '1', 'SourceOfRefractiveMeasurementsCodeSequence'],
    '1140': ['CS', '1', 'OphthalmicAxialLengthMeasurementModified'],
    '1150': ['SQ', '1', 'OphthalmicAxialLengthDataSourceCodeSequence'],
    '1153': ['SQ', '1', 'OphthalmicAxialLengthAcquisitionMethodCodeSequence'],
    '1155': ['FL', '1', 'SignalToNoiseRatio'],
    '1159': ['LO', '1', 'OphthalmicAxialLengthDataSourceDescription'],
    '1210': ['SQ', '1', 'OphthalmicAxialLengthMeasurementsTotalLengthSequence'],
    '1211': ['SQ', '1', 'OphthalmicAxialLengthMeasurementsSegmentalLengthSequence'],
    '1212': ['SQ', '1', 'OphthalmicAxialLengthMeasurementsLengthSummationSequence'],
    '1220': ['SQ', '1', 'UltrasoundOphthalmicAxialLengthMeasurementsSequence'],
    '1225': ['SQ', '1', 'OpticalOphthalmicAxialLengthMeasurementsSequence'],
    '1230': ['SQ', '1', 'UltrasoundSelectedOphthalmicAxialLengthSequence'],
    '1250': ['SQ', '1', 'OphthalmicAxialLengthSelectionMethodCodeSequence'],
    '1255': ['SQ', '1', 'OpticalSelectedOphthalmicAxialLengthSequence'],
    '1257': ['SQ', '1', 'SelectedSegmentalOphthalmicAxialLengthSequence'],
    '1260': ['SQ', '1', 'SelectedTotalOphthalmicAxialLengthSequence'],
    '1262': ['SQ', '1', 'OphthalmicAxialLengthQualityMetricSequence'],
    '1265': ['SQ', '1', 'OphthalmicAxialLengthQualityMetricTypeCodeSequence'],
    '1273': ['LO', '1', 'OphthalmicAxialLengthQualityMetricTypeDescription'],
    '1300': ['SQ', '1', 'IntraocularLensCalculationsRightEyeSequence'],
    '1310': ['SQ', '1', 'IntraocularLensCalculationsLeftEyeSequence'],
    '1330': ['SQ', '1', 'ReferencedOphthalmicAxialLengthMeasurementQCImageSequence'],
    '1415': ['CS', '1', 'OphthalmicMappingDeviceType'],
    '1420': ['SQ', '1', 'AcquisitionMethodCodeSequence'],
    '1423': ['SQ', '1', 'AcquisitionMethodAlgorithmSequence'],
    '1436': ['SQ', '1', 'OphthalmicThicknessMapTypeCodeSequence'],
    '1443': ['SQ', '1', 'OphthalmicThicknessMappingNormalsSequence'],
    '1445': ['SQ', '1', 'RetinalThicknessDefinitionCodeSequence'],
    '1450': ['SQ', '1', 'PixelValueMappingToCodedConceptSequence'],
    '1452': ['xs', '1', 'MappedPixelValue'],
    '1454': ['LO', '1', 'PixelValueMappingExplanation'],
    '1458': ['SQ', '1', 'OphthalmicThicknessMapQualityThresholdSequence'],
    '1460': ['FL', '1', 'OphthalmicThicknessMapThresholdQualityRating'],
    '1463': ['FL', '2', 'AnatomicStructureReferencePoint'],
    '1465': ['SQ', '1', 'RegistrationToLocalizerSequence'],
    '1466': ['CS', '1', 'RegisteredLocalizerUnits'],
    '1467': ['FL', '2', 'RegisteredLocalizerTopLeftHandCorner'],
    '1468': ['FL', '2', 'RegisteredLocalizerBottomRightHandCorner'],
    '1470': ['SQ', '1', 'OphthalmicThicknessMapQualityRatingSequence'],
    '1472': ['SQ', '1', 'RelevantOPTAttributesSequence'],
    '1512': ['SQ', '1', 'TransformationMethodCodeSequence'],
    '1513': ['SQ', '1', 'TransformationAlgorithmSequence'],
    '1515': ['CS', '1', 'OphthalmicAxialLengthMethod'],
    '1517': ['FL', '1', 'OphthalmicFOV'],
    '1518': ['SQ', '1', 'TwoDimensionalToThreeDimensionalMapSequence'],
    '1525': ['SQ', '1', 'WideFieldOphthalmicPhotographyQualityRatingSequence'],
    '1526': ['SQ', '1', 'WideFieldOphthalmicPhotographyQualityThresholdSequence'],
    '1527': ['FL', '1', 'WideFieldOphthalmicPhotographyThresholdQualityRating'],
    '1528': ['FL', '1', 'XCoordinatesCenterPixelViewAngle'],
    '1529': ['FL', '1', 'YCoordinatesCenterPixelViewAngle'],
    '1530': ['UL', '1', 'NumberOfMapPoints'],
    '1531': ['OF', '1', 'TwoDimensionalToThreeDimensionalMapData'],
    '1612': ['SQ', '1', 'DerivationAlgorithmSequence'],
    '1615': ['SQ', '1', 'OphthalmicImageTypeCodeSequence'],
    '1616': ['LO', '1', 'OphthalmicImageTypeDescription'],
    '1618': ['SQ', '1', 'ScanPatternTypeCodeSequence'],
    '1620': ['SQ', '1', 'ReferencedSurfaceMeshIdentificationSequence'],
    '1622': ['CS', '1', 'OphthalmicVolumetricPropertiesFlag'],
    '1624': ['FL', '1', 'OphthalmicAnatomicReferencePointXCoordinate'],
    '1626': ['FL', '1', 'OphthalmicAnatomicReferencePointYCoordinate'],
    '1628': ['SQ', '1', 'OphthalmicEnFaceImageQualityRatingSequence'],
    '1630': ['DS', '1', 'QualityThreshold'],
    '1640': ['SQ', '1', 'OCTBscanAnalysisAcquisitionParametersSequence'],
    '1642': ['UL', '1', 'NumberOfBscansPerFrame'],
    '1643': ['FL', '1', 'BscanSlabThickness'],
    '1644': ['FL', '1', 'DistanceBetweenBscanSlabs'],
    '1645': ['FL', '1', 'BscanCycleTime'],
    '1646': ['FL', '1-n', 'BscanCycleTimeVector'],
    '1649': ['FL', '1', 'AscanRate'],
    '1650': ['FL', '1', 'BscanRate'],
    '1658': ['UL', '1', 'SurfaceMeshZPixelOffset']
  },
  '0024': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['FL', '1', 'VisualFieldHorizontalExtent'],
    '0011': ['FL', '1', 'VisualFieldVerticalExtent'],
    '0012': ['CS', '1', 'VisualFieldShape'],
    '0016': ['SQ', '1', 'ScreeningTestModeCodeSequence'],
    '0018': ['FL', '1', 'MaximumStimulusLuminance'],
    '0020': ['FL', '1', 'BackgroundLuminance'],
    '0021': ['SQ', '1', 'StimulusColorCodeSequence'],
    '0024': ['SQ', '1', 'BackgroundIlluminationColorCodeSequence'],
    '0025': ['FL', '1', 'StimulusArea'],
    '0028': ['FL', '1', 'StimulusPresentationTime'],
    '0032': ['SQ', '1', 'FixationSequence'],
    '0033': ['SQ', '1', 'FixationMonitoringCodeSequence'],
    '0034': ['SQ', '1', 'VisualFieldCatchTrialSequence'],
    '0035': ['US', '1', 'FixationCheckedQuantity'],
    '0036': ['US', '1', 'PatientNotProperlyFixatedQuantity'],
    '0037': ['CS', '1', 'PresentedVisualStimuliDataFlag'],
    '0038': ['US', '1', 'NumberOfVisualStimuli'],
    '0039': ['CS', '1', 'ExcessiveFixationLossesDataFlag'],
    '0040': ['CS', '1', 'ExcessiveFixationLosses'],
    '0042': ['US', '1', 'StimuliRetestingQuantity'],
    '0044': ['LT', '1', 'CommentsOnPatientPerformanceOfVisualField'],
    '0045': ['CS', '1', 'FalseNegativesEstimateFlag'],
    '0046': ['FL', '1', 'FalseNegativesEstimate'],
    '0048': ['US', '1', 'NegativeCatchTrialsQuantity'],
    '0050': ['US', '1', 'FalseNegativesQuantity'],
    '0051': ['CS', '1', 'ExcessiveFalseNegativesDataFlag'],
    '0052': ['CS', '1', 'ExcessiveFalseNegatives'],
    '0053': ['CS', '1', 'FalsePositivesEstimateFlag'],
    '0054': ['FL', '1', 'FalsePositivesEstimate'],
    '0055': ['CS', '1', 'CatchTrialsDataFlag'],
    '0056': ['US', '1', 'PositiveCatchTrialsQuantity'],
    '0057': ['CS', '1', 'TestPointNormalsDataFlag'],
    '0058': ['SQ', '1', 'TestPointNormalsSequence'],
    '0059': ['CS', '1', 'GlobalDeviationProbabilityNormalsFlag'],
    '0060': ['US', '1', 'FalsePositivesQuantity'],
    '0061': ['CS', '1', 'ExcessiveFalsePositivesDataFlag'],
    '0062': ['CS', '1', 'ExcessiveFalsePositives'],
    '0063': ['CS', '1', 'VisualFieldTestNormalsFlag'],
    '0064': ['SQ', '1', 'ResultsNormalsSequence'],
    '0065': ['SQ', '1', 'AgeCorrectedSensitivityDeviationAlgorithmSequence'],
    '0066': ['FL', '1', 'GlobalDeviationFromNormal'],
    '0067': ['SQ', '1', 'GeneralizedDefectSensitivityDeviationAlgorithmSequence'],
    '0068': ['FL', '1', 'LocalizedDeviationFromNormal'],
    '0069': ['LO', '1', 'PatientReliabilityIndicator'],
    '0070': ['FL', '1', 'VisualFieldMeanSensitivity'],
    '0071': ['FL', '1', 'GlobalDeviationProbability'],
    '0072': ['CS', '1', 'LocalDeviationProbabilityNormalsFlag'],
    '0073': ['FL', '1', 'LocalizedDeviationProbability'],
    '0074': ['CS', '1', 'ShortTermFluctuationCalculated'],
    '0075': ['FL', '1', 'ShortTermFluctuation'],
    '0076': ['CS', '1', 'ShortTermFluctuationProbabilityCalculated'],
    '0077': ['FL', '1', 'ShortTermFluctuationProbability'],
    '0078': ['CS', '1', 'CorrectedLocalizedDeviationFromNormalCalculated'],
    '0079': ['FL', '1', 'CorrectedLocalizedDeviationFromNormal'],
    '0080': ['CS', '1', 'CorrectedLocalizedDeviationFromNormalProbabilityCalculated'],
    '0081': ['FL', '1', 'CorrectedLocalizedDeviationFromNormalProbability'],
    '0083': ['SQ', '1', 'GlobalDeviationProbabilitySequence'],
    '0085': ['SQ', '1', 'LocalizedDeviationProbabilitySequence'],
    '0086': ['CS', '1', 'FovealSensitivityMeasured'],
    '0087': ['FL', '1', 'FovealSensitivity'],
    '0088': ['FL', '1', 'VisualFieldTestDuration'],
    '0089': ['SQ', '1', 'VisualFieldTestPointSequence'],
    '0090': ['FL', '1', 'VisualFieldTestPointXCoordinate'],
    '0091': ['FL', '1', 'VisualFieldTestPointYCoordinate'],
    '0092': ['FL', '1', 'AgeCorrectedSensitivityDeviationValue'],
    '0093': ['CS', '1', 'StimulusResults'],
    '0094': ['FL', '1', 'SensitivityValue'],
    '0095': ['CS', '1', 'RetestStimulusSeen'],
    '0096': ['FL', '1', 'RetestSensitivityValue'],
    '0097': ['SQ', '1', 'VisualFieldTestPointNormalsSequence'],
    '0098': ['FL', '1', 'QuantifiedDefect'],
    '0100': ['FL', '1', 'AgeCorrectedSensitivityDeviationProbabilityValue'],
    '0102': ['CS', '1', 'GeneralizedDefectCorrectedSensitivityDeviationFlag'],
    '0103': ['FL', '1', 'GeneralizedDefectCorrectedSensitivityDeviationValue'],
    '0104': ['FL', '1', 'GeneralizedDefectCorrectedSensitivityDeviationProbabilityValue'],
    '0105': ['FL', '1', 'MinimumSensitivityValue'],
    '0106': ['CS', '1', 'BlindSpotLocalized'],
    '0107': ['FL', '1', 'BlindSpotXCoordinate'],
    '0108': ['FL', '1', 'BlindSpotYCoordinate'],
    '0110': ['SQ', '1', 'VisualAcuityMeasurementSequence'],
    '0112': ['SQ', '1', 'RefractiveParametersUsedOnPatientSequence'],
    '0113': ['CS', '1', 'MeasurementLaterality'],
    '0114': ['SQ', '1', 'OphthalmicPatientClinicalInformationLeftEyeSequence'],
    '0115': ['SQ', '1', 'OphthalmicPatientClinicalInformationRightEyeSequence'],
    '0117': ['CS', '1', 'FovealPointNormativeDataFlag'],
    '0118': ['FL', '1', 'FovealPointProbabilityValue'],
    '0120': ['CS', '1', 'ScreeningBaselineMeasured'],
    '0122': ['SQ', '1', 'ScreeningBaselineMeasuredSequence'],
    '0124': ['CS', '1', 'ScreeningBaselineType'],
    '0126': ['FL', '1', 'ScreeningBaselineValue'],
    '0202': ['LO', '1', 'AlgorithmSource'],
    '0306': ['LO', '1', 'DataSetName'],
    '0307': ['LO', '1', 'DataSetVersion'],
    '0308': ['LO', '1', 'DataSetSource'],
    '0309': ['LO', '1', 'DataSetDescription'],
    '0317': ['SQ', '1', 'VisualFieldTestReliabilityGlobalIndexSequence'],
    '0320': ['SQ', '1', 'VisualFieldGlobalResultsIndexSequence'],
    '0325': ['SQ', '1', 'DataObservationSequence'],
    '0338': ['CS', '1', 'IndexNormalsFlag'],
    '0341': ['FL', '1', 'IndexProbability'],
    '0344': ['SQ', '1', 'IndexProbabilitySequence']
  },
  '0028': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['US', '1', 'SamplesPerPixel'],
    '0003': ['US', '1', 'SamplesPerPixelUsed'],
    '0004': ['CS', '1', 'PhotometricInterpretation'],
    '0005': ['US', '1', 'ImageDimensions'],
    '0006': ['US', '1', 'PlanarConfiguration'],
    '0008': ['IS', '1', 'NumberOfFrames'],
    '0009': ['AT', '1-n', 'FrameIncrementPointer'],
    '000A': ['AT', '1-n', 'FrameDimensionPointer'],
    '0010': ['US', '1', 'Rows'],
    '0011': ['US', '1', 'Columns'],
    '0012': ['US', '1', 'Planes'],
    '0014': ['US', '1', 'UltrasoundColorDataPresent'],
    '0020': ['', '', ''],
    '0030': ['DS', '2', 'PixelSpacing'],
    '0031': ['DS', '2', 'ZoomFactor'],
    '0032': ['DS', '2', 'ZoomCenter'],
    '0034': ['IS', '2', 'PixelAspectRatio'],
    '0040': ['CS', '1', 'ImageFormat'],
    '0050': ['LO', '1-n', 'ManipulatedImage'],
    '0051': ['CS', '1-n', 'CorrectedImage'],
    '005F': ['LO', '1', 'CompressionRecognitionCode'],
    '0060': ['CS', '1', 'CompressionCode'],
    '0061': ['SH', '1', 'CompressionOriginator'],
    '0062': ['LO', '1', 'CompressionLabel'],
    '0063': ['SH', '1', 'CompressionDescription'],
    '0065': ['CS', '1-n', 'CompressionSequence'],
    '0066': ['AT', '1-n', 'CompressionStepPointers'],
    '0068': ['US', '1', 'RepeatInterval'],
    '0069': ['US', '1', 'BitsGrouped'],
    '0070': ['US', '1-n', 'PerimeterTable'],
    '0071': ['xs', '1', 'PerimeterValue'],
    '0080': ['US', '1', 'PredictorRows'],
    '0081': ['US', '1', 'PredictorColumns'],
    '0082': ['US', '1-n', 'PredictorConstants'],
    '0090': ['CS', '1', 'BlockedPixels'],
    '0091': ['US', '1', 'BlockRows'],
    '0092': ['US', '1', 'BlockColumns'],
    '0093': ['US', '1', 'RowOverlap'],
    '0094': ['US', '1', 'ColumnOverlap'],
    '0100': ['US', '1', 'BitsAllocated'],
    '0101': ['US', '1', 'BitsStored'],
    '0102': ['US', '1', 'HighBit'],
    '0103': ['US', '1', 'PixelRepresentation'],
    '0104': ['xs', '1', 'SmallestValidPixelValue'],
    '0105': ['xs', '1', 'LargestValidPixelValue'],
    '0106': ['xs', '1', 'SmallestImagePixelValue'],
    '0107': ['xs', '1', 'LargestImagePixelValue'],
    '0108': ['xs', '1', 'SmallestPixelValueInSeries'],
    '0109': ['xs', '1', 'LargestPixelValueInSeries'],
    '0110': ['xs', '1', 'SmallestImagePixelValueInPlane'],
    '0111': ['xs', '1', 'LargestImagePixelValueInPlane'],
    '0120': ['xs', '1', 'PixelPaddingValue'],
    '0121': ['xs', '1', 'PixelPaddingRangeLimit'],
    '0122': ['FL', '1', 'FloatPixelPaddingValue'],
    '0123': ['FD', '1', 'DoubleFloatPixelPaddingValue'],
    '0124': ['FL', '1', 'FloatPixelPaddingRangeLimit'],
    '0125': ['FD', '1', 'DoubleFloatPixelPaddingRangeLimit'],
    '0200': ['US', '1', 'ImageLocation'],
    '0300': ['CS', '1', 'QualityControlImage'],
    '0301': ['CS', '1', 'BurnedInAnnotation'],
    '0302': ['CS', '1', 'RecognizableVisualFeatures'],
    '0303': ['CS', '1', 'LongitudinalTemporalInformationModified'],
    '0304': ['UI', '1', 'ReferencedColorPaletteInstanceUID'],
    '0400': ['LO', '1', 'TransformLabel'],
    '0401': ['LO', '1', 'TransformVersionNumber'],
    '0402': ['US', '1', 'NumberOfTransformSteps'],
    '0403': ['LO', '1-n', 'SequenceOfCompressedData'],
    '0404': ['AT', '1-n', 'DetailsOfCoefficients'],
    '04x0': ['US', '1', 'RowsForNthOrderCoefficients'],
    '04x1': ['US', '1', 'ColumnsForNthOrderCoefficients'],
    '04x2': ['LO', '1-n', 'CoefficientCoding'],
    '04x3': ['AT', '1-n', 'CoefficientCodingPointers'],
    '0700': ['LO', '1', 'DCTLabel'],
    '0701': ['CS', '1-n', 'DataBlockDescription'],
    '0702': ['AT', '1-n', 'DataBlock'],
    '0710': ['US', '1', 'NormalizationFactorFormat'],
    '0720': ['US', '1', 'ZonalMapNumberFormat'],
    '0721': ['AT', '1-n', 'ZonalMapLocation'],
    '0722': ['US', '1', 'ZonalMapFormat'],
    '0730': ['US', '1', 'AdaptiveMapFormat'],
    '0740': ['US', '1', 'CodeNumberFormat'],
    '08x0': ['CS', '1-n', 'CodeLabel'],
    '08x2': ['US', '1', 'NumberOfTables'],
    '08x3': ['AT', '1-n', 'CodeTableLocation'],
    '08x4': ['US', '1', 'BitsForCodeWord'],
    '08x8': ['AT', '1-n', 'ImageDataLocation'],
    '0A02': ['CS', '1', 'PixelSpacingCalibrationType'],
    '0A04': ['LO', '1', 'PixelSpacingCalibrationDescription'],
    '1040': ['CS', '1', 'PixelIntensityRelationship'],
    '1041': ['SS', '1', 'PixelIntensityRelationshipSign'],
    '1050': ['DS', '1-n', 'WindowCenter'],
    '1051': ['DS', '1-n', 'WindowWidth'],
    '1052': ['DS', '1', 'RescaleIntercept'],
    '1053': ['DS', '1', 'RescaleSlope'],
    '1054': ['LO', '1', 'RescaleType'],
    '1055': ['LO', '1-n', 'WindowCenterWidthExplanation'],
    '1056': ['CS', '1', 'VOILUTFunction'],
    '1080': ['CS', '1', 'GrayScale'],
    '1090': ['CS', '1', 'RecommendedViewingMode'],
    '1100': ['xs', '3', 'GrayLookupTableDescriptor'],
    '1101': ['xs', '3', 'RedPaletteColorLookupTableDescriptor'],
    '1102': ['xs', '3', 'GreenPaletteColorLookupTableDescriptor'],
    '1103': ['xs', '3', 'BluePaletteColorLookupTableDescriptor'],
    '1104': ['US', '3', 'AlphaPaletteColorLookupTableDescriptor'],
    '1111': ['xs', '4', 'LargeRedPaletteColorLookupTableDescriptor'],
    '1112': ['xs', '4', 'LargeGreenPaletteColorLookupTableDescriptor'],
    '1113': ['xs', '4', 'LargeBluePaletteColorLookupTableDescriptor'],
    '1199': ['UI', '1', 'PaletteColorLookupTableUID'],
    '1200': ['xs', '1-n or 1', 'GrayLookupTableData'],
    '1201': ['OW', '1', 'RedPaletteColorLookupTableData'],
    '1202': ['OW', '1', 'GreenPaletteColorLookupTableData'],
    '1203': ['OW', '1', 'BluePaletteColorLookupTableData'],
    '1204': ['OW', '1', 'AlphaPaletteColorLookupTableData'],
    '1211': ['OW', '1', 'LargeRedPaletteColorLookupTableData'],
    '1212': ['OW', '1', 'LargeGreenPaletteColorLookupTableData'],
    '1213': ['OW', '1', 'LargeBluePaletteColorLookupTableData'],
    '1214': ['UI', '1', 'LargePaletteColorLookupTableUID'],
    '1221': ['OW', '1', 'SegmentedRedPaletteColorLookupTableData'],
    '1222': ['OW', '1', 'SegmentedGreenPaletteColorLookupTableData'],
    '1223': ['OW', '1', 'SegmentedBluePaletteColorLookupTableData'],
    '1224': ['OW', '1', 'SegmentedAlphaPaletteColorLookupTableData'],
    '1230': ['SQ', '1', 'StoredValueColorRangeSequence'],
    '1231': ['FD', '1', 'MinimumStoredValueMapped'],
    '1232': ['FD', '1', 'MaximumStoredValueMapped'],
    '1300': ['CS', '1', 'BreastImplantPresent'],
    '1350': ['CS', '1', 'PartialView'],
    '1351': ['ST', '1', 'PartialViewDescription'],
    '1352': ['SQ', '1', 'PartialViewCodeSequence'],
    '135A': ['CS', '1', 'SpatialLocationsPreserved'],
    '1401': ['SQ', '1', 'DataFrameAssignmentSequence'],
    '1402': ['CS', '1', 'DataPathAssignment'],
    '1403': ['US', '1', 'BitsMappedToColorLookupTable'],
    '1404': ['SQ', '1', 'BlendingLUT1Sequence'],
    '1405': ['CS', '1', 'BlendingLUT1TransferFunction'],
    '1406': ['FD', '1', 'BlendingWeightConstant'],
    '1407': ['US', '3', 'BlendingLookupTableDescriptor'],
    '1408': ['OW', '1', 'BlendingLookupTableData'],
    '140B': ['SQ', '1', 'EnhancedPaletteColorLookupTableSequence'],
    '140C': ['SQ', '1', 'BlendingLUT2Sequence'],
    '140D': ['CS', '1', 'BlendingLUT2TransferFunction'],
    '140E': ['CS', '1', 'DataPathID'],
    '140F': ['CS', '1', 'RGBLUTTransferFunction'],
    '1410': ['CS', '1', 'AlphaLUTTransferFunction'],
    '2000': ['OB', '1', 'ICCProfile'],
    '2002': ['CS', '1', 'ColorSpace'],
    '2110': ['CS', '1', 'LossyImageCompression'],
    '2112': ['DS', '1-n', 'LossyImageCompressionRatio'],
    '2114': ['CS', '1-n', 'LossyImageCompressionMethod'],
    '3000': ['SQ', '1', 'ModalityLUTSequence'],
    '3002': ['xs', '3', 'LUTDescriptor'],
    '3003': ['LO', '1', 'LUTExplanation'],
    '3004': ['LO', '1', 'ModalityLUTType'],
    '3006': ['xx', '1-n or 1', 'LUTData'],
    '3010': ['SQ', '1', 'VOILUTSequence'],
    '3110': ['SQ', '1', 'SoftcopyVOILUTSequence'],
    '4000': ['LT', '1', 'ImagePresentationComments'],
    '5000': ['SQ', '1', 'BiPlaneAcquisitionSequence'],
    '6010': ['US', '1', 'RepresentativeFrameNumber'],
    '6020': ['US', '1-n', 'FrameNumbersOfInterest'],
    '6022': ['LO', '1-n', 'FrameOfInterestDescription'],
    '6023': ['CS', '1-n', 'FrameOfInterestType'],
    '6030': ['US', '1-n', 'MaskPointers'],
    '6040': ['US', '1-n', 'RWavePointer'],
    '6100': ['SQ', '1', 'MaskSubtractionSequence'],
    '6101': ['CS', '1', 'MaskOperation'],
    '6102': ['US', '2-2n', 'ApplicableFrameRange'],
    '6110': ['US', '1-n', 'MaskFrameNumbers'],
    '6112': ['US', '1', 'ContrastFrameAveraging'],
    '6114': ['FL', '2', 'MaskSubPixelShift'],
    '6120': ['SS', '1', 'TIDOffset'],
    '6190': ['ST', '1', 'MaskOperationExplanation'],
    '7000': ['SQ', '1', 'EquipmentAdministratorSequence'],
    '7001': ['US', '1', 'NumberOfDisplaySubsystems'],
    '7002': ['US', '1', 'CurrentConfigurationID'],
    '7003': ['US', '1', 'DisplaySubsystemID'],
    '7004': ['SH', '1', 'DisplaySubsystemName'],
    '7005': ['LO', '1', 'DisplaySubsystemDescription'],
    '7006': ['CS', '1', 'SystemStatus'],
    '7007': ['LO', '1', 'SystemStatusComment'],
    '7008': ['SQ', '1', 'TargetLuminanceCharacteristicsSequence'],
    '7009': ['US', '1', 'LuminanceCharacteristicsID'],
    '700A': ['SQ', '1', 'DisplaySubsystemConfigurationSequence'],
    '700B': ['US', '1', 'ConfigurationID'],
    '700C': ['SH', '1', 'ConfigurationName'],
    '700D': ['LO', '1', 'ConfigurationDescription'],
    '700E': ['US', '1', 'ReferencedTargetLuminanceCharacteristicsID'],
    '700F': ['SQ', '1', 'QAResultsSequence'],
    '7010': ['SQ', '1', 'DisplaySubsystemQAResultsSequence'],
    '7011': ['SQ', '1', 'ConfigurationQAResultsSequence'],
    '7012': ['SQ', '1', 'MeasurementEquipmentSequence'],
    '7013': ['CS', '1-n', 'MeasurementFunctions'],
    '7014': ['CS', '1', 'MeasurementEquipmentType'],
    '7015': ['SQ', '1', 'VisualEvaluationResultSequence'],
    '7016': ['SQ', '1', 'DisplayCalibrationResultSequence'],
    '7017': ['US', '1', 'DDLValue'],
    '7018': ['FL', '2', 'CIExyWhitePoint'],
    '7019': ['CS', '1', 'DisplayFunctionType'],
    '701A': ['FL', '1', 'GammaValue'],
    '701B': ['US', '1', 'NumberOfLuminancePoints'],
    '701C': ['SQ', '1', 'LuminanceResponseSequence'],
    '701D': ['FL', '1', 'TargetMinimumLuminance'],
    '701E': ['FL', '1', 'TargetMaximumLuminance'],
    '701F': ['FL', '1', 'LuminanceValue'],
    '7020': ['LO', '1', 'LuminanceResponseDescription'],
    '7021': ['CS', '1', 'WhitePointFlag'],
    '7022': ['SQ', '1', 'DisplayDeviceTypeCodeSequence'],
    '7023': ['SQ', '1', 'DisplaySubsystemSequence'],
    '7024': ['SQ', '1', 'LuminanceResultSequence'],
    '7025': ['CS', '1', 'AmbientLightValueSource'],
    '7026': ['CS', '1-n', 'MeasuredCharacteristics'],
    '7027': ['SQ', '1', 'LuminanceUniformityResultSequence'],
    '7028': ['SQ', '1', 'VisualEvaluationTestSequence'],
    '7029': ['CS', '1', 'TestResult'],
    '702A': ['LO', '1', 'TestResultComment'],
    '702B': ['CS', '1', 'TestImageValidation'],
    '702C': ['SQ', '1', 'TestPatternCodeSequence'],
    '702D': ['SQ', '1', 'MeasurementPatternCodeSequence'],
    '702E': ['SQ', '1', 'VisualEvaluationMethodCodeSequence'],
    '7FE0': ['UR', '1', 'PixelDataProviderURL'],
    '9001': ['UL', '1', 'DataPointRows'],
    '9002': ['UL', '1', 'DataPointColumns'],
    '9003': ['CS', '1', 'SignalDomainColumns'],
    '9099': ['US', '1', 'LargestMonochromePixelValue'],
    '9108': ['CS', '1', 'DataRepresentation'],
    '9110': ['SQ', '1', 'PixelMeasuresSequence'],
    '9132': ['SQ', '1', 'FrameVOILUTSequence'],
    '9145': ['SQ', '1', 'PixelValueTransformationSequence'],
    '9235': ['CS', '1', 'SignalDomainRows'],
    '9411': ['FL', '1', 'DisplayFilterPercentage'],
    '9415': ['SQ', '1', 'FramePixelShiftSequence'],
    '9416': ['US', '1', 'SubtractionItemID'],
    '9422': ['SQ', '1', 'PixelIntensityRelationshipLUTSequence'],
    '9443': ['SQ', '1', 'FramePixelDataPropertiesSequence'],
    '9444': ['CS', '1', 'GeometricalProperties'],
    '9445': ['FL', '1', 'GeometricMaximumDistortion'],
    '9446': ['CS', '1-n', 'ImageProcessingApplied'],
    '9454': ['CS', '1', 'MaskSelectionMode'],
    '9474': ['CS', '1', 'LUTFunction'],
    '9478': ['FL', '1', 'MaskVisibilityPercentage'],
    '9501': ['SQ', '1', 'PixelShiftSequence'],
    '9502': ['SQ', '1', 'RegionPixelShiftSequence'],
    '9503': ['SS', '2-2n', 'VerticesOfTheRegion'],
    '9505': ['SQ', '1', 'MultiFramePresentationSequence'],
    '9506': ['US', '2-2n', 'PixelShiftFrameRange'],
    '9507': ['US', '2-2n', 'LUTFrameRange'],
    '9520': ['DS', '16', 'ImageToEquipmentMappingMatrix'],
    '9537': ['CS', '1', 'EquipmentCoordinateSystemIdentification']
  },
  '0032': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '000A': ['CS', '1', 'StudyStatusID'],
    '000C': ['CS', '1', 'StudyPriorityID'],
    '0012': ['LO', '1', 'StudyIDIssuer'],
    '0032': ['DA', '1', 'StudyVerifiedDate'],
    '0033': ['TM', '1', 'StudyVerifiedTime'],
    '0034': ['DA', '1', 'StudyReadDate'],
    '0035': ['TM', '1', 'StudyReadTime'],
    '1000': ['DA', '1', 'ScheduledStudyStartDate'],
    '1001': ['TM', '1', 'ScheduledStudyStartTime'],
    '1010': ['DA', '1', 'ScheduledStudyStopDate'],
    '1011': ['TM', '1', 'ScheduledStudyStopTime'],
    '1020': ['LO', '1', 'ScheduledStudyLocation'],
    '1021': ['AE', '1-n', 'ScheduledStudyLocationAETitle'],
    '1030': ['LO', '1', 'ReasonForStudy'],
    '1031': ['SQ', '1', 'RequestingPhysicianIdentificationSequence'],
    '1032': ['PN', '1', 'RequestingPhysician'],
    '1033': ['LO', '1', 'RequestingService'],
    '1034': ['SQ', '1', 'RequestingServiceCodeSequence'],
    '1040': ['DA', '1', 'StudyArrivalDate'],
    '1041': ['TM', '1', 'StudyArrivalTime'],
    '1050': ['DA', '1', 'StudyCompletionDate'],
    '1051': ['TM', '1', 'StudyCompletionTime'],
    '1055': ['CS', '1', 'StudyComponentStatusID'],
    '1060': ['LO', '1', 'RequestedProcedureDescription'],
    '1064': ['SQ', '1', 'RequestedProcedureCodeSequence'],
    '1065': ['SQ', '1', 'RequestedLateralityCodeSequence'],
    '1066': ['UT', '1', 'ReasonForVisit'],
    '1067': ['SQ', '1', 'ReasonForVisitCodeSequence'],
    '1070': ['LO', '1', 'RequestedContrastAgent'],
    '4000': ['LT', '1', 'StudyComments']
  },
  '0034': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['SQ', '1', 'FlowIdentifierSequence'],
    '0002': ['OB', '1', 'FlowIdentifier'],
    '0003': ['UI', '1', 'FlowTransferSyntaxUID'],
    '0004': ['UL', '1', 'FlowRTPSamplingRate'],
    '0005': ['OB', '1', 'SourceIdentifier'],
    '0007': ['OB', '1', 'FrameOriginTimestamp'],
    '0008': ['CS', '1', 'IncludesImagingSubject'],
    '0009': ['SQ', '1', 'FrameUsefulnessGroupSequence'],
    '000A': ['SQ', '1', 'RealTimeBulkDataFlowSequence'],
    '000B': ['SQ', '1', 'CameraPositionGroupSequence'],
    '000C': ['CS', '1', 'IncludesInformation'],
    '000D': ['SQ', '1', 'TimeOfFrameGroupSequence']
  },
  '0038': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0004': ['SQ', '1', 'ReferencedPatientAliasSequence'],
    '0008': ['CS', '1', 'VisitStatusID'],
    '0010': ['LO', '1', 'AdmissionID'],
    '0011': ['LO', '1', 'IssuerOfAdmissionID'],
    '0014': ['SQ', '1', 'IssuerOfAdmissionIDSequence'],
    '0016': ['LO', '1', 'RouteOfAdmissions'],
    '001A': ['DA', '1', 'ScheduledAdmissionDate'],
    '001B': ['TM', '1', 'ScheduledAdmissionTime'],
    '001C': ['DA', '1', 'ScheduledDischargeDate'],
    '001D': ['TM', '1', 'ScheduledDischargeTime'],
    '001E': ['LO', '1', 'ScheduledPatientInstitutionResidence'],
    '0020': ['DA', '1', 'AdmittingDate'],
    '0021': ['TM', '1', 'AdmittingTime'],
    '0030': ['DA', '1', 'DischargeDate'],
    '0032': ['TM', '1', 'DischargeTime'],
    '0040': ['LO', '1', 'DischargeDiagnosisDescription'],
    '0044': ['SQ', '1', 'DischargeDiagnosisCodeSequence'],
    '0050': ['LO', '1', 'SpecialNeeds'],
    '0060': ['LO', '1', 'ServiceEpisodeID'],
    '0061': ['LO', '1', 'IssuerOfServiceEpisodeID'],
    '0062': ['LO', '1', 'ServiceEpisodeDescription'],
    '0064': ['SQ', '1', 'IssuerOfServiceEpisodeIDSequence'],
    '0100': ['SQ', '1', 'PertinentDocumentsSequence'],
    '0101': ['SQ', '1', 'PertinentResourcesSequence'],
    '0102': ['LO', '1', 'ResourceDescription'],
    '0300': ['LO', '1', 'CurrentPatientLocation'],
    '0400': ['LO', '1', 'PatientInstitutionResidence'],
    '0500': ['LO', '1', 'PatientState'],
    '0502': ['SQ', '1', 'PatientClinicalTrialParticipationSequence'],
    '4000': ['LT', '1', 'VisitComments']
  },
  '003A': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0004': ['CS', '1', 'WaveformOriginality'],
    '0005': ['US', '1', 'NumberOfWaveformChannels'],
    '0010': ['UL', '1', 'NumberOfWaveformSamples'],
    '001A': ['DS', '1', 'SamplingFrequency'],
    '0020': ['SH', '1', 'MultiplexGroupLabel'],
    '0200': ['SQ', '1', 'ChannelDefinitionSequence'],
    '0202': ['IS', '1', 'WaveformChannelNumber'],
    '0203': ['SH', '1', 'ChannelLabel'],
    '0205': ['CS', '1-n', 'ChannelStatus'],
    '0208': ['SQ', '1', 'ChannelSourceSequence'],
    '0209': ['SQ', '1', 'ChannelSourceModifiersSequence'],
    '020A': ['SQ', '1', 'SourceWaveformSequence'],
    '020C': ['LO', '1', 'ChannelDerivationDescription'],
    '0210': ['DS', '1', 'ChannelSensitivity'],
    '0211': ['SQ', '1', 'ChannelSensitivityUnitsSequence'],
    '0212': ['DS', '1', 'ChannelSensitivityCorrectionFactor'],
    '0213': ['DS', '1', 'ChannelBaseline'],
    '0214': ['DS', '1', 'ChannelTimeSkew'],
    '0215': ['DS', '1', 'ChannelSampleSkew'],
    '0218': ['DS', '1', 'ChannelOffset'],
    '021A': ['US', '1', 'WaveformBitsStored'],
    '0220': ['DS', '1', 'FilterLowFrequency'],
    '0221': ['DS', '1', 'FilterHighFrequency'],
    '0222': ['DS', '1', 'NotchFilterFrequency'],
    '0223': ['DS', '1', 'NotchFilterBandwidth'],
    '0230': ['FL', '1', 'WaveformDataDisplayScale'],
    '0231': ['US', '3', 'WaveformDisplayBackgroundCIELabValue'],
    '0240': ['SQ', '1', 'WaveformPresentationGroupSequence'],
    '0241': ['US', '1', 'PresentationGroupNumber'],
    '0242': ['SQ', '1', 'ChannelDisplaySequence'],
    '0244': ['US', '3', 'ChannelRecommendedDisplayCIELabValue'],
    '0245': ['FL', '1', 'ChannelPosition'],
    '0246': ['CS', '1', 'DisplayShadingFlag'],
    '0247': ['FL', '1', 'FractionalChannelDisplayScale'],
    '0248': ['FL', '1', 'AbsoluteChannelDisplayScale'],
    '0300': ['SQ', '1', 'MultiplexedAudioChannelsDescriptionCodeSequence'],
    '0301': ['IS', '1', 'ChannelIdentificationCode'],
    '0302': ['CS', '1', 'ChannelMode'],
    '0310': ['UI', '1', 'MultiplexGroupUID'],
    '0311': ['DS', '1', 'PowerlineFrequency'],
    '0312': ['SQ', '1', 'ChannelImpedanceSequence'],
    '0313': ['DS', '1', 'ImpedanceValue'],
    '0314': ['DT', '1', 'ImpedanceMeasurementDateTime'],
    '0315': ['DS', '1', 'ImpedanceMeasurementFrequency'],
    '0316': ['CS', '1', 'ImpedanceMeasurementCurrentType']
  },
  '0040': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['AE', '1-n', 'ScheduledStationAETitle'],
    '0002': ['DA', '1', 'ScheduledProcedureStepStartDate'],
    '0003': ['TM', '1', 'ScheduledProcedureStepStartTime'],
    '0004': ['DA', '1', 'ScheduledProcedureStepEndDate'],
    '0005': ['TM', '1', 'ScheduledProcedureStepEndTime'],
    '0006': ['PN', '1', 'ScheduledPerformingPhysicianName'],
    '0007': ['LO', '1', 'ScheduledProcedureStepDescription'],
    '0008': ['SQ', '1', 'ScheduledProtocolCodeSequence'],
    '0009': ['SH', '1', 'ScheduledProcedureStepID'],
    '000A': ['SQ', '1', 'StageCodeSequence'],
    '000B': ['SQ', '1', 'ScheduledPerformingPhysicianIdentificationSequence'],
    '0010': ['SH', '1-n', 'ScheduledStationName'],
    '0011': ['SH', '1', 'ScheduledProcedureStepLocation'],
    '0012': ['LO', '1', 'PreMedication'],
    '0020': ['CS', '1', 'ScheduledProcedureStepStatus'],
    '0026': ['SQ', '1', 'OrderPlacerIdentifierSequence'],
    '0027': ['SQ', '1', 'OrderFillerIdentifierSequence'],
    '0031': ['UT', '1', 'LocalNamespaceEntityID'],
    '0032': ['UT', '1', 'UniversalEntityID'],
    '0033': ['CS', '1', 'UniversalEntityIDType'],
    '0035': ['CS', '1', 'IdentifierTypeCode'],
    '0036': ['SQ', '1', 'AssigningFacilitySequence'],
    '0039': ['SQ', '1', 'AssigningJurisdictionCodeSequence'],
    '003A': ['SQ', '1', 'AssigningAgencyOrDepartmentCodeSequence'],
    '0100': ['SQ', '1', 'ScheduledProcedureStepSequence'],
    '0220': ['SQ', '1', 'ReferencedNonImageCompositeSOPInstanceSequence'],
    '0241': ['AE', '1', 'PerformedStationAETitle'],
    '0242': ['SH', '1', 'PerformedStationName'],
    '0243': ['SH', '1', 'PerformedLocation'],
    '0244': ['DA', '1', 'PerformedProcedureStepStartDate'],
    '0245': ['TM', '1', 'PerformedProcedureStepStartTime'],
    '0250': ['DA', '1', 'PerformedProcedureStepEndDate'],
    '0251': ['TM', '1', 'PerformedProcedureStepEndTime'],
    '0252': ['CS', '1', 'PerformedProcedureStepStatus'],
    '0253': ['SH', '1', 'PerformedProcedureStepID'],
    '0254': ['LO', '1', 'PerformedProcedureStepDescription'],
    '0255': ['LO', '1', 'PerformedProcedureTypeDescription'],
    '0260': ['SQ', '1', 'PerformedProtocolCodeSequence'],
    '0261': ['CS', '1', 'PerformedProtocolType'],
    '0270': ['SQ', '1', 'ScheduledStepAttributesSequence'],
    '0275': ['SQ', '1', 'RequestAttributesSequence'],
    '0280': ['ST', '1', 'CommentsOnThePerformedProcedureStep'],
    '0281': ['SQ', '1', 'PerformedProcedureStepDiscontinuationReasonCodeSequence'],
    '0293': ['SQ', '1', 'QuantitySequence'],
    '0294': ['DS', '1', 'Quantity'],
    '0295': ['SQ', '1', 'MeasuringUnitsSequence'],
    '0296': ['SQ', '1', 'BillingItemSequence'],
    '0300': ['US', '1', 'TotalTimeOfFluoroscopy'],
    '0301': ['US', '1', 'TotalNumberOfExposures'],
    '0302': ['US', '1', 'EntranceDose'],
    '0303': ['US', '1-2', 'ExposedArea'],
    '0306': ['DS', '1', 'DistanceSourceToEntrance'],
    '0307': ['DS', '1', 'DistanceSourceToSupport'],
    '030E': ['SQ', '1', 'ExposureDoseSequence'],
    '0310': ['ST', '1', 'CommentsOnRadiationDose'],
    '0312': ['DS', '1', 'XRayOutput'],
    '0314': ['DS', '1', 'HalfValueLayer'],
    '0316': ['DS', '1', 'OrganDose'],
    '0318': ['CS', '1', 'OrganExposed'],
    '0320': ['SQ', '1', 'BillingProcedureStepSequence'],
    '0321': ['SQ', '1', 'FilmConsumptionSequence'],
    '0324': ['SQ', '1', 'BillingSuppliesAndDevicesSequence'],
    '0330': ['SQ', '1', 'ReferencedProcedureStepSequence'],
    '0340': ['SQ', '1', 'PerformedSeriesSequence'],
    '0400': ['LT', '1', 'CommentsOnTheScheduledProcedureStep'],
    '0440': ['SQ', '1', 'ProtocolContextSequence'],
    '0441': ['SQ', '1', 'ContentItemModifierSequence'],
    '0500': ['SQ', '1', 'ScheduledSpecimenSequence'],
    '050A': ['LO', '1', 'SpecimenAccessionNumber'],
    '0512': ['LO', '1', 'ContainerIdentifier'],
    '0513': ['SQ', '1', 'IssuerOfTheContainerIdentifierSequence'],
    '0515': ['SQ', '1', 'AlternateContainerIdentifierSequence'],
    '0518': ['SQ', '1', 'ContainerTypeCodeSequence'],
    '051A': ['LO', '1', 'ContainerDescription'],
    '0520': ['SQ', '1', 'ContainerComponentSequence'],
    '0550': ['SQ', '1', 'SpecimenSequence'],
    '0551': ['LO', '1', 'SpecimenIdentifier'],
    '0552': ['SQ', '1', 'SpecimenDescriptionSequenceTrial'],
    '0553': ['ST', '1', 'SpecimenDescriptionTrial'],
    '0554': ['UI', '1', 'SpecimenUID'],
    '0555': ['SQ', '1', 'AcquisitionContextSequence'],
    '0556': ['ST', '1', 'AcquisitionContextDescription'],
    '0560': ['SQ', '1', 'SpecimenDescriptionSequence'],
    '0562': ['SQ', '1', 'IssuerOfTheSpecimenIdentifierSequence'],
    '059A': ['SQ', '1', 'SpecimenTypeCodeSequence'],
    '0600': ['LO', '1', 'SpecimenShortDescription'],
    '0602': ['UT', '1', 'SpecimenDetailedDescription'],
    '0610': ['SQ', '1', 'SpecimenPreparationSequence'],
    '0612': ['SQ', '1', 'SpecimenPreparationStepContentItemSequence'],
    '0620': ['SQ', '1', 'SpecimenLocalizationContentItemSequence'],
    '06FA': ['LO', '1', 'SlideIdentifier'],
    '0710': ['SQ', '1', 'WholeSlideMicroscopyImageFrameTypeSequence'],
    '071A': ['SQ', '1', 'ImageCenterPointCoordinatesSequence'],
    '072A': ['DS', '1', 'XOffsetInSlideCoordinateSystem'],
    '073A': ['DS', '1', 'YOffsetInSlideCoordinateSystem'],
    '074A': ['DS', '1', 'ZOffsetInSlideCoordinateSystem'],
    '08D8': ['SQ', '1', 'PixelSpacingSequence'],
    '08DA': ['SQ', '1', 'CoordinateSystemAxisCodeSequence'],
    '08EA': ['SQ', '1', 'MeasurementUnitsCodeSequence'],
    '09F8': ['SQ', '1', 'VitalStainCodeSequenceTrial'],
    '1001': ['SH', '1', 'RequestedProcedureID'],
    '1002': ['LO', '1', 'ReasonForTheRequestedProcedure'],
    '1003': ['SH', '1', 'RequestedProcedurePriority'],
    '1004': ['LO', '1', 'PatientTransportArrangements'],
    '1005': ['LO', '1', 'RequestedProcedureLocation'],
    '1006': ['SH', '1', 'PlacerOrderNumberProcedure'],
    '1007': ['SH', '1', 'FillerOrderNumberProcedure'],
    '1008': ['LO', '1', 'ConfidentialityCode'],
    '1009': ['SH', '1', 'ReportingPriority'],
    '100A': ['SQ', '1', 'ReasonForRequestedProcedureCodeSequence'],
    '1010': ['PN', '1-n', 'NamesOfIntendedRecipientsOfResults'],
    '1011': ['SQ', '1', 'IntendedRecipientsOfResultsIdentificationSequence'],
    '1012': ['SQ', '1', 'ReasonForPerformedProcedureCodeSequence'],
    '1060': ['LO', '1', 'RequestedProcedureDescriptionTrial'],
    '1101': ['SQ', '1', 'PersonIdentificationCodeSequence'],
    '1102': ['ST', '1', 'PersonAddress'],
    '1103': ['LO', '1-n', 'PersonTelephoneNumbers'],
    '1104': ['LT', '1', 'PersonTelecomInformation'],
    '1400': ['LT', '1', 'RequestedProcedureComments'],
    '2001': ['LO', '1', 'ReasonForTheImagingServiceRequest'],
    '2004': ['DA', '1', 'IssueDateOfImagingServiceRequest'],
    '2005': ['TM', '1', 'IssueTimeOfImagingServiceRequest'],
    '2006': ['SH', '1', 'PlacerOrderNumberImagingServiceRequestRetired'],
    '2007': ['SH', '1', 'FillerOrderNumberImagingServiceRequestRetired'],
    '2008': ['PN', '1', 'OrderEnteredBy'],
    '2009': ['SH', '1', 'OrderEntererLocation'],
    '2010': ['SH', '1', 'OrderCallbackPhoneNumber'],
    '2011': ['LT', '1', 'OrderCallbackTelecomInformation'],
    '2016': ['LO', '1', 'PlacerOrderNumberImagingServiceRequest'],
    '2017': ['LO', '1', 'FillerOrderNumberImagingServiceRequest'],
    '2400': ['LT', '1', 'ImagingServiceRequestComments'],
    '3001': ['LO', '1', 'ConfidentialityConstraintOnPatientDataDescription'],
    '4001': ['CS', '1', 'GeneralPurposeScheduledProcedureStepStatus'],
    '4002': ['CS', '1', 'GeneralPurposePerformedProcedureStepStatus'],
    '4003': ['CS', '1', 'GeneralPurposeScheduledProcedureStepPriority'],
    '4004': ['SQ', '1', 'ScheduledProcessingApplicationsCodeSequence'],
    '4005': ['DT', '1', 'ScheduledProcedureStepStartDateTime'],
    '4006': ['CS', '1', 'MultipleCopiesFlag'],
    '4007': ['SQ', '1', 'PerformedProcessingApplicationsCodeSequence'],
    '4008': ['DT', '1', 'ScheduledProcedureStepExpirationDateTime'],
    '4009': ['SQ', '1', 'HumanPerformerCodeSequence'],
    '4010': ['DT', '1', 'ScheduledProcedureStepModificationDateTime'],
    '4011': ['DT', '1', 'ExpectedCompletionDateTime'],
    '4015': ['SQ', '1', 'ResultingGeneralPurposePerformedProcedureStepsSequence'],
    '4016': ['SQ', '1', 'ReferencedGeneralPurposeScheduledProcedureStepSequence'],
    '4018': ['SQ', '1', 'ScheduledWorkitemCodeSequence'],
    '4019': ['SQ', '1', 'PerformedWorkitemCodeSequence'],
    '4020': ['CS', '1', 'InputAvailabilityFlag'],
    '4021': ['SQ', '1', 'InputInformationSequence'],
    '4022': ['SQ', '1', 'RelevantInformationSequence'],
    '4023': ['UI', '1', 'ReferencedGeneralPurposeScheduledProcedureStepTransactionUID'],
    '4025': ['SQ', '1', 'ScheduledStationNameCodeSequence'],
    '4026': ['SQ', '1', 'ScheduledStationClassCodeSequence'],
    '4027': ['SQ', '1', 'ScheduledStationGeographicLocationCodeSequence'],
    '4028': ['SQ', '1', 'PerformedStationNameCodeSequence'],
    '4029': ['SQ', '1', 'PerformedStationClassCodeSequence'],
    '4030': ['SQ', '1', 'PerformedStationGeographicLocationCodeSequence'],
    '4031': ['SQ', '1', 'RequestedSubsequentWorkitemCodeSequence'],
    '4032': ['SQ', '1', 'NonDICOMOutputCodeSequence'],
    '4033': ['SQ', '1', 'OutputInformationSequence'],
    '4034': ['SQ', '1', 'ScheduledHumanPerformersSequence'],
    '4035': ['SQ', '1', 'ActualHumanPerformersSequence'],
    '4036': ['LO', '1', 'HumanPerformerOrganization'],
    '4037': ['PN', '1', 'HumanPerformerName'],
    '4040': ['CS', '1', 'RawDataHandling'],
    '4041': ['CS', '1', 'InputReadinessState'],
    '4050': ['DT', '1', 'PerformedProcedureStepStartDateTime'],
    '4051': ['DT', '1', 'PerformedProcedureStepEndDateTime'],
    '4052': ['DT', '1', 'ProcedureStepCancellationDateTime'],
    '4070': ['SQ', '1', 'OutputDestinationSequence'],
    '4071': ['SQ', '1', 'DICOMStorageSequence'],
    '4072': ['SQ', '1', 'STOWRSStorageSequence'],
    '4073': ['UR', '1', 'StorageURL'],
    '4074': ['SQ', '1', 'XDSStorageSequence'],
    '8302': ['DS', '1', 'EntranceDoseInmGy'],
    '8303': ['CS', '1', 'EntranceDoseDerivation'],
    '9092': ['SQ', '1', 'ParametricMapFrameTypeSequence'],
    '9094': ['SQ', '1', 'ReferencedImageRealWorldValueMappingSequence'],
    '9096': ['SQ', '1', 'RealWorldValueMappingSequence'],
    '9098': ['SQ', '1', 'PixelValueMappingCodeSequence'],
    '9210': ['SH', '1', 'LUTLabel'],
    '9211': ['xs', '1', 'RealWorldValueLastValueMapped'],
    '9212': ['FD', '1-n', 'RealWorldValueLUTData'],
    '9213': ['FD', '1', 'DoubleFloatRealWorldValueLastValueMapped'],
    '9214': ['FD', '1', 'DoubleFloatRealWorldValueFirstValueMapped'],
    '9216': ['xs', '1', 'RealWorldValueFirstValueMapped'],
    '9220': ['SQ', '1', 'QuantityDefinitionSequence'],
    '9224': ['FD', '1', 'RealWorldValueIntercept'],
    '9225': ['FD', '1', 'RealWorldValueSlope'],
    'A007': ['CS', '1', 'FindingsFlagTrial'],
    'A010': ['CS', '1', 'RelationshipType'],
    'A020': ['SQ', '1', 'FindingsSequenceTrial'],
    'A021': ['UI', '1', 'FindingsGroupUIDTrial'],
    'A022': ['UI', '1', 'ReferencedFindingsGroupUIDTrial'],
    'A023': ['DA', '1', 'FindingsGroupRecordingDateTrial'],
    'A024': ['TM', '1', 'FindingsGroupRecordingTimeTrial'],
    'A026': ['SQ', '1', 'FindingsSourceCategoryCodeSequenceTrial'],
    'A027': ['LO', '1', 'VerifyingOrganization'],
    'A028': ['SQ', '1', 'DocumentingOrganizationIdentifierCodeSequenceTrial'],
    'A030': ['DT', '1', 'VerificationDateTime'],
    'A032': ['DT', '1', 'ObservationDateTime'],
    'A033': ['DT', '1', 'ObservationStartDateTime'],
    'A040': ['CS', '1', 'ValueType'],
    'A043': ['SQ', '1', 'ConceptNameCodeSequence'],
    'A047': ['LO', '1', 'MeasurementPrecisionDescriptionTrial'],
    'A050': ['CS', '1', 'ContinuityOfContent'],
    'A057': ['CS', '1-n', 'UrgencyOrPriorityAlertsTrial'],
    'A060': ['LO', '1', 'SequencingIndicatorTrial'],
    'A066': ['SQ', '1', 'DocumentIdentifierCodeSequenceTrial'],
    'A067': ['PN', '1', 'DocumentAuthorTrial'],
    'A068': ['SQ', '1', 'DocumentAuthorIdentifierCodeSequenceTrial'],
    'A070': ['SQ', '1', 'IdentifierCodeSequenceTrial'],
    'A073': ['SQ', '1', 'VerifyingObserverSequence'],
    'A074': ['OB', '1', 'ObjectBinaryIdentifierTrial'],
    'A075': ['PN', '1', 'VerifyingObserverName'],
    'A076': ['SQ', '1', 'DocumentingObserverIdentifierCodeSequenceTrial'],
    'A078': ['SQ', '1', 'AuthorObserverSequence'],
    'A07A': ['SQ', '1', 'ParticipantSequence'],
    'A07C': ['SQ', '1', 'CustodialOrganizationSequence'],
    'A080': ['CS', '1', 'ParticipationType'],
    'A082': ['DT', '1', 'ParticipationDateTime'],
    'A084': ['CS', '1', 'ObserverType'],
    'A085': ['SQ', '1', 'ProcedureIdentifierCodeSequenceTrial'],
    'A088': ['SQ', '1', 'VerifyingObserverIdentificationCodeSequence'],
    'A089': ['OB', '1', 'ObjectDirectoryBinaryIdentifierTrial'],
    'A090': ['SQ', '1', 'EquivalentCDADocumentSequence'],
    'A0B0': ['US', '2-2n', 'ReferencedWaveformChannels'],
    'A110': ['DA', '1', 'DateOfDocumentOrVerbalTransactionTrial'],
    'A112': ['TM', '1', 'TimeOfDocumentCreationOrVerbalTransactionTrial'],
    'A120': ['DT', '1', 'DateTime'],
    'A121': ['DA', '1', 'Date'],
    'A122': ['TM', '1', 'Time'],
    'A123': ['PN', '1', 'PersonName'],
    'A124': ['UI', '1', 'UID'],
    'A125': ['CS', '2', 'ReportStatusIDTrial'],
    'A130': ['CS', '1', 'TemporalRangeType'],
    'A132': ['UL', '1-n', 'ReferencedSamplePositions'],
    'A136': ['US', '1-n', 'ReferencedFrameNumbers'],
    'A138': ['DS', '1-n', 'ReferencedTimeOffsets'],
    'A13A': ['DT', '1-n', 'ReferencedDateTime'],
    'A160': ['UT', '1', 'TextValue'],
    'A161': ['FD', '1-n', 'FloatingPointValue'],
    'A162': ['SL', '1-n', 'RationalNumeratorValue'],
    'A163': ['UL', '1-n', 'RationalDenominatorValue'],
    'A167': ['SQ', '1', 'ObservationCategoryCodeSequenceTrial'],
    'A168': ['SQ', '1', 'ConceptCodeSequence'],
    'A16A': ['ST', '1', 'BibliographicCitationTrial'],
    'A170': ['SQ', '1', 'PurposeOfReferenceCodeSequence'],
    'A171': ['UI', '1', 'ObservationUID'],
    'A172': ['UI', '1', 'ReferencedObservationUIDTrial'],
    'A173': ['CS', '1', 'ReferencedObservationClassTrial'],
    'A174': ['CS', '1', 'ReferencedObjectObservationClassTrial'],
    'A180': ['US', '1', 'AnnotationGroupNumber'],
    'A192': ['DA', '1', 'ObservationDateTrial'],
    'A193': ['TM', '1', 'ObservationTimeTrial'],
    'A194': ['CS', '1', 'MeasurementAutomationTrial'],
    'A195': ['SQ', '1', 'ModifierCodeSequence'],
    'A224': ['ST', '1', 'IdentificationDescriptionTrial'],
    'A290': ['CS', '1', 'CoordinatesSetGeometricTypeTrial'],
    'A296': ['SQ', '1', 'AlgorithmCodeSequenceTrial'],
    'A297': ['ST', '1', 'AlgorithmDescriptionTrial'],
    'A29A': ['SL', '2-2n', 'PixelCoordinatesSetTrial'],
    'A300': ['SQ', '1', 'MeasuredValueSequence'],
    'A301': ['SQ', '1', 'NumericValueQualifierCodeSequence'],
    'A307': ['PN', '1', 'CurrentObserverTrial'],
    'A30A': ['DS', '1-n', 'NumericValue'],
    'A313': ['SQ', '1', 'ReferencedAccessionSequenceTrial'],
    'A33A': ['ST', '1', 'ReportStatusCommentTrial'],
    'A340': ['SQ', '1', 'ProcedureContextSequenceTrial'],
    'A352': ['PN', '1', 'VerbalSourceTrial'],
    'A353': ['ST', '1', 'AddressTrial'],
    'A354': ['LO', '1', 'TelephoneNumberTrial'],
    'A358': ['SQ', '1', 'VerbalSourceIdentifierCodeSequenceTrial'],
    'A360': ['SQ', '1', 'PredecessorDocumentsSequence'],
    'A370': ['SQ', '1', 'ReferencedRequestSequence'],
    'A372': ['SQ', '1', 'PerformedProcedureCodeSequence'],
    'A375': ['SQ', '1', 'CurrentRequestedProcedureEvidenceSequence'],
    'A380': ['SQ', '1', 'ReportDetailSequenceTrial'],
    'A385': ['SQ', '1', 'PertinentOtherEvidenceSequence'],
    'A390': ['SQ', '1', 'HL7StructuredDocumentReferenceSequence'],
    'A402': ['UI', '1', 'ObservationSubjectUIDTrial'],
    'A403': ['CS', '1', 'ObservationSubjectClassTrial'],
    'A404': ['SQ', '1', 'ObservationSubjectTypeCodeSequenceTrial'],
    'A491': ['CS', '1', 'CompletionFlag'],
    'A492': ['LO', '1', 'CompletionFlagDescription'],
    'A493': ['CS', '1', 'VerificationFlag'],
    'A494': ['CS', '1', 'ArchiveRequested'],
    'A496': ['CS', '1', 'PreliminaryFlag'],
    'A504': ['SQ', '1', 'ContentTemplateSequence'],
    'A525': ['SQ', '1', 'IdenticalDocumentsSequence'],
    'A600': ['CS', '1', 'ObservationSubjectContextFlagTrial'],
    'A601': ['CS', '1', 'ObserverContextFlagTrial'],
    'A603': ['CS', '1', 'ProcedureContextFlagTrial'],
    'A730': ['SQ', '1', 'ContentSequence'],
    'A731': ['SQ', '1', 'RelationshipSequenceTrial'],
    'A732': ['SQ', '1', 'RelationshipTypeCodeSequenceTrial'],
    'A744': ['SQ', '1', 'LanguageCodeSequenceTrial'],
    'A801': ['SQ', '1', 'TabulatedValuesSequence'],
    'A802': ['UL', '1', 'NumberOfTableRows'],
    'A803': ['UL', '1', 'NumberOfTableColumns'],
    'A804': ['UL', '1', 'TableRowNumber'],
    'A805': ['UL', '1', 'TableColumnNumber'],
    'A806': ['SQ', '1', 'TableRowDefinitionSequence'],
    'A807': ['SQ', '1', 'TableColumnDefinitionSequence'],
    'A808': ['SQ', '1', 'CellValuesSequence'],
    'A992': ['ST', '1', 'UniformResourceLocatorTrial'],
    'B020': ['SQ', '1', 'WaveformAnnotationSequence'],
    'DB00': ['CS', '1', 'TemplateIdentifier'],
    'DB06': ['DT', '1', 'TemplateVersion'],
    'DB07': ['DT', '1', 'TemplateLocalVersion'],
    'DB0B': ['CS', '1', 'TemplateExtensionFlag'],
    'DB0C': ['UI', '1', 'TemplateExtensionOrganizationUID'],
    'DB0D': ['UI', '1', 'TemplateExtensionCreatorUID'],
    'DB73': ['UL', '1-n', 'ReferencedContentItemIdentifier'],
    'E001': ['ST', '1', 'HL7InstanceIdentifier'],
    'E004': ['DT', '1', 'HL7DocumentEffectiveTime'],
    'E006': ['SQ', '1', 'HL7DocumentTypeCodeSequence'],
    'E008': ['SQ', '1', 'DocumentClassCodeSequence'],
    'E010': ['UR', '1', 'RetrieveURI'],
    'E011': ['UI', '1', 'RetrieveLocationUID'],
    'E020': ['CS', '1', 'TypeOfInstances'],
    'E021': ['SQ', '1', 'DICOMRetrievalSequence'],
    'E022': ['SQ', '1', 'DICOMMediaRetrievalSequence'],
    'E023': ['SQ', '1', 'WADORetrievalSequence'],
    'E024': ['SQ', '1', 'XDSRetrievalSequence'],
    'E025': ['SQ', '1', 'WADORSRetrievalSequence'],
    'E030': ['UI', '1', 'RepositoryUniqueID'],
    'E031': ['UI', '1', 'HomeCommunityID']
  },
  '0042': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['ST', '1', 'DocumentTitle'],
    '0011': ['OB', '1', 'EncapsulatedDocument'],
    '0012': ['LO', '1', 'MIMETypeOfEncapsulatedDocument'],
    '0013': ['SQ', '1', 'SourceInstanceSequence'],
    '0014': ['LO', '1-n', 'ListOfMIMETypes'],
    '0015': ['UL', '1', 'EncapsulatedDocumentLength']
  },
  '0044': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['ST', '1', 'ProductPackageIdentifier'],
    '0002': ['CS', '1', 'SubstanceAdministrationApproval'],
    '0003': ['LT', '1', 'ApprovalStatusFurtherDescription'],
    '0004': ['DT', '1', 'ApprovalStatusDateTime'],
    '0007': ['SQ', '1', 'ProductTypeCodeSequence'],
    '0008': ['LO', '1-n', 'ProductName'],
    '0009': ['LT', '1', 'ProductDescription'],
    '000A': ['LO', '1', 'ProductLotIdentifier'],
    '000B': ['DT', '1', 'ProductExpirationDateTime'],
    '0010': ['DT', '1', 'SubstanceAdministrationDateTime'],
    '0011': ['LO', '1', 'SubstanceAdministrationNotes'],
    '0012': ['LO', '1', 'SubstanceAdministrationDeviceID'],
    '0013': ['SQ', '1', 'ProductParameterSequence'],
    '0019': ['SQ', '1', 'SubstanceAdministrationParameterSequence'],
    '0100': ['SQ', '1', 'ApprovalSequence'],
    '0101': ['SQ', '1', 'AssertionCodeSequence'],
    '0102': ['UI', '1', 'AssertionUID'],
    '0103': ['SQ', '1', 'AsserterIdentificationSequence'],
    '0104': ['DT', '1', 'AssertionDateTime'],
    '0105': ['DT', '1', 'AssertionExpirationDateTime'],
    '0106': ['UT', '1', 'AssertionComments'],
    '0107': ['SQ', '1', 'RelatedAssertionSequence'],
    '0108': ['UI', '1', 'ReferencedAssertionUID'],
    '0109': ['SQ', '1', 'ApprovalSubjectSequence'],
    '010A': ['SQ', '1', 'OrganizationalRoleCodeSequence']
  },
  '0046': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0012': ['LO', '1', 'LensDescription'],
    '0014': ['SQ', '1', 'RightLensSequence'],
    '0015': ['SQ', '1', 'LeftLensSequence'],
    '0016': ['SQ', '1', 'UnspecifiedLateralityLensSequence'],
    '0018': ['SQ', '1', 'CylinderSequence'],
    '0028': ['SQ', '1', 'PrismSequence'],
    '0030': ['FD', '1', 'HorizontalPrismPower'],
    '0032': ['CS', '1', 'HorizontalPrismBase'],
    '0034': ['FD', '1', 'VerticalPrismPower'],
    '0036': ['CS', '1', 'VerticalPrismBase'],
    '0038': ['CS', '1', 'LensSegmentType'],
    '0040': ['FD', '1', 'OpticalTransmittance'],
    '0042': ['FD', '1', 'ChannelWidth'],
    '0044': ['FD', '1', 'PupilSize'],
    '0046': ['FD', '1', 'CornealSize'],
    '0047': ['SQ', '1', 'CornealSizeSequence'],
    '0050': ['SQ', '1', 'AutorefractionRightEyeSequence'],
    '0052': ['SQ', '1', 'AutorefractionLeftEyeSequence'],
    '0060': ['FD', '1', 'DistancePupillaryDistance'],
    '0062': ['FD', '1', 'NearPupillaryDistance'],
    '0063': ['FD', '1', 'IntermediatePupillaryDistance'],
    '0064': ['FD', '1', 'OtherPupillaryDistance'],
    '0070': ['SQ', '1', 'KeratometryRightEyeSequence'],
    '0071': ['SQ', '1', 'KeratometryLeftEyeSequence'],
    '0074': ['SQ', '1', 'SteepKeratometricAxisSequence'],
    '0075': ['FD', '1', 'RadiusOfCurvature'],
    '0076': ['FD', '1', 'KeratometricPower'],
    '0077': ['FD', '1', 'KeratometricAxis'],
    '0080': ['SQ', '1', 'FlatKeratometricAxisSequence'],
    '0092': ['CS', '1', 'BackgroundColor'],
    '0094': ['CS', '1', 'Optotype'],
    '0095': ['CS', '1', 'OptotypePresentation'],
    '0097': ['SQ', '1', 'SubjectiveRefractionRightEyeSequence'],
    '0098': ['SQ', '1', 'SubjectiveRefractionLeftEyeSequence'],
    '0100': ['SQ', '1', 'AddNearSequence'],
    '0101': ['SQ', '1', 'AddIntermediateSequence'],
    '0102': ['SQ', '1', 'AddOtherSequence'],
    '0104': ['FD', '1', 'AddPower'],
    '0106': ['FD', '1', 'ViewingDistance'],
    '0110': ['SQ', '1', 'CorneaMeasurementsSequence'],
    '0111': ['SQ', '1', 'SourceOfCorneaMeasurementDataCodeSequence'],
    '0112': ['SQ', '1', 'SteepCornealAxisSequence'],
    '0113': ['SQ', '1', 'FlatCornealAxisSequence'],
    '0114': ['FD', '1', 'CornealPower'],
    '0115': ['FD', '1', 'CornealAxis'],
    '0116': ['SQ', '1', 'CorneaMeasurementMethodCodeSequence'],
    '0117': ['FL', '1', 'RefractiveIndexOfCornea'],
    '0118': ['FL', '1', 'RefractiveIndexOfAqueousHumor'],
    '0121': ['SQ', '1', 'VisualAcuityTypeCodeSequence'],
    '0122': ['SQ', '1', 'VisualAcuityRightEyeSequence'],
    '0123': ['SQ', '1', 'VisualAcuityLeftEyeSequence'],
    '0124': ['SQ', '1', 'VisualAcuityBothEyesOpenSequence'],
    '0125': ['CS', '1', 'ViewingDistanceType'],
    '0135': ['SS', '2', 'VisualAcuityModifiers'],
    '0137': ['FD', '1', 'DecimalVisualAcuity'],
    '0139': ['LO', '1', 'OptotypeDetailedDefinition'],
    '0145': ['SQ', '1', 'ReferencedRefractiveMeasurementsSequence'],
    '0146': ['FD', '1', 'SpherePower'],
    '0147': ['FD', '1', 'CylinderPower'],
    '0201': ['CS', '1', 'CornealTopographySurface'],
    '0202': ['FL', '2', 'CornealVertexLocation'],
    '0203': ['FL', '1', 'PupilCentroidXCoordinate'],
    '0204': ['FL', '1', 'PupilCentroidYCoordinate'],
    '0205': ['FL', '1', 'EquivalentPupilRadius'],
    '0207': ['SQ', '1', 'CornealTopographyMapTypeCodeSequence'],
    '0208': ['IS', '2-2n', 'VerticesOfTheOutlineOfPupil'],
    '0210': ['SQ', '1', 'CornealTopographyMappingNormalsSequence'],
    '0211': ['SQ', '1', 'MaximumCornealCurvatureSequence'],
    '0212': ['FL', '1', 'MaximumCornealCurvature'],
    '0213': ['FL', '2', 'MaximumCornealCurvatureLocation'],
    '0215': ['SQ', '1', 'MinimumKeratometricSequence'],
    '0218': ['SQ', '1', 'SimulatedKeratometricCylinderSequence'],
    '0220': ['FL', '1', 'AverageCornealPower'],
    '0224': ['FL', '1', 'CornealISValue'],
    '0227': ['FL', '1', 'AnalyzedArea'],
    '0230': ['FL', '1', 'SurfaceRegularityIndex'],
    '0232': ['FL', '1', 'SurfaceAsymmetryIndex'],
    '0234': ['FL', '1', 'CornealEccentricityIndex'],
    '0236': ['FL', '1', 'KeratoconusPredictionIndex'],
    '0238': ['FL', '1', 'DecimalPotentialVisualAcuity'],
    '0242': ['CS', '1', 'CornealTopographyMapQualityEvaluation'],
    '0244': ['SQ', '1', 'SourceImageCornealProcessedDataSequence'],
    '0247': ['FL', '3', 'CornealPointLocation'],
    '0248': ['CS', '1', 'CornealPointEstimated'],
    '0249': ['FL', '1', 'AxialPower'],
    '0250': ['FL', '1', 'TangentialPower'],
    '0251': ['FL', '1', 'RefractivePower'],
    '0252': ['FL', '1', 'RelativeElevation'],
    '0253': ['FL', '1', 'CornealWavefront']
  },
  '0048': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['FL', '1', 'ImagedVolumeWidth'],
    '0002': ['FL', '1', 'ImagedVolumeHeight'],
    '0003': ['FL', '1', 'ImagedVolumeDepth'],
    '0006': ['UL', '1', 'TotalPixelMatrixColumns'],
    '0007': ['UL', '1', 'TotalPixelMatrixRows'],
    '0008': ['SQ', '1', 'TotalPixelMatrixOriginSequence'],
    '0010': ['CS', '1', 'SpecimenLabelInImage'],
    '0011': ['CS', '1', 'FocusMethod'],
    '0012': ['CS', '1', 'ExtendedDepthOfField'],
    '0013': ['US', '1', 'NumberOfFocalPlanes'],
    '0014': ['FL', '1', 'DistanceBetweenFocalPlanes'],
    '0015': ['US', '3', 'RecommendedAbsentPixelCIELabValue'],
    '0100': ['SQ', '1', 'IlluminatorTypeCodeSequence'],
    '0102': ['DS', '6', 'ImageOrientationSlide'],
    '0105': ['SQ', '1', 'OpticalPathSequence'],
    '0106': ['SH', '1', 'OpticalPathIdentifier'],
    '0107': ['ST', '1', 'OpticalPathDescription'],
    '0108': ['SQ', '1', 'IlluminationColorCodeSequence'],
    '0110': ['SQ', '1', 'SpecimenReferenceSequence'],
    '0111': ['DS', '1', 'CondenserLensPower'],
    '0112': ['DS', '1', 'ObjectiveLensPower'],
    '0113': ['DS', '1', 'ObjectiveLensNumericalAperture'],
    '0120': ['SQ', '1', 'PaletteColorLookupTableSequence'],
    '0200': ['SQ', '1', 'ReferencedImageNavigationSequence'],
    '0201': ['US', '2', 'TopLeftHandCornerOfLocalizerArea'],
    '0202': ['US', '2', 'BottomRightHandCornerOfLocalizerArea'],
    '0207': ['SQ', '1', 'OpticalPathIdentificationSequence'],
    '021A': ['SQ', '1', 'PlanePositionSlideSequence'],
    '021E': ['SL', '1', 'ColumnPositionInTotalImagePixelMatrix'],
    '021F': ['SL', '1', 'RowPositionInTotalImagePixelMatrix'],
    '0301': ['CS', '1', 'PixelOriginInterpretation'],
    '0302': ['UL', '1', 'NumberOfOpticalPaths'],
    '0303': ['UL', '1', 'TotalPixelMatrixFocalPlanes']
  },
  '0050': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0004': ['CS', '1', 'CalibrationImage'],
    '0010': ['SQ', '1', 'DeviceSequence'],
    '0012': ['SQ', '1', 'ContainerComponentTypeCodeSequence'],
    '0013': ['FD', '1', 'ContainerComponentThickness'],
    '0014': ['DS', '1', 'DeviceLength'],
    '0015': ['FD', '1', 'ContainerComponentWidth'],
    '0016': ['DS', '1', 'DeviceDiameter'],
    '0017': ['CS', '1', 'DeviceDiameterUnits'],
    '0018': ['DS', '1', 'DeviceVolume'],
    '0019': ['DS', '1', 'InterMarkerDistance'],
    '001A': ['CS', '1', 'ContainerComponentMaterial'],
    '001B': ['LO', '1', 'ContainerComponentID'],
    '001C': ['FD', '1', 'ContainerComponentLength'],
    '001D': ['FD', '1', 'ContainerComponentDiameter'],
    '001E': ['LO', '1', 'ContainerComponentDescription'],
    '0020': ['LO', '1', 'DeviceDescription'],
    '0021': ['ST', '1', 'LongDeviceDescription']
  },
  '0052': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['FL', '1', 'ContrastBolusIngredientPercentByVolume'],
    '0002': ['FD', '1', 'OCTFocalDistance'],
    '0003': ['FD', '1', 'BeamSpotSize'],
    '0004': ['FD', '1', 'EffectiveRefractiveIndex'],
    '0006': ['CS', '1', 'OCTAcquisitionDomain'],
    '0007': ['FD', '1', 'OCTOpticalCenterWavelength'],
    '0008': ['FD', '1', 'AxialResolution'],
    '0009': ['FD', '1', 'RangingDepth'],
    '0011': ['FD', '1', 'ALineRate'],
    '0012': ['US', '1', 'ALinesPerFrame'],
    '0013': ['FD', '1', 'CatheterRotationalRate'],
    '0014': ['FD', '1', 'ALinePixelSpacing'],
    '0016': ['SQ', '1', 'ModeOfPercutaneousAccessSequence'],
    '0025': ['SQ', '1', 'IntravascularOCTFrameTypeSequence'],
    '0026': ['CS', '1', 'OCTZOffsetApplied'],
    '0027': ['SQ', '1', 'IntravascularFrameContentSequence'],
    '0028': ['FD', '1', 'IntravascularLongitudinalDistance'],
    '0029': ['SQ', '1', 'IntravascularOCTFrameContentSequence'],
    '0030': ['SS', '1', 'OCTZOffsetCorrection'],
    '0031': ['CS', '1', 'CatheterDirectionOfRotation'],
    '0033': ['FD', '1', 'SeamLineLocation'],
    '0034': ['FD', '1', 'FirstALineLocation'],
    '0036': ['US', '1', 'SeamLineIndex'],
    '0038': ['US', '1', 'NumberOfPaddedALines'],
    '0039': ['CS', '1', 'InterpolationType'],
    '003A': ['CS', '1', 'RefractiveIndexApplied']
  },
  '0054': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['US', '1-n', 'EnergyWindowVector'],
    '0011': ['US', '1', 'NumberOfEnergyWindows'],
    '0012': ['SQ', '1', 'EnergyWindowInformationSequence'],
    '0013': ['SQ', '1', 'EnergyWindowRangeSequence'],
    '0014': ['DS', '1', 'EnergyWindowLowerLimit'],
    '0015': ['DS', '1', 'EnergyWindowUpperLimit'],
    '0016': ['SQ', '1', 'RadiopharmaceuticalInformationSequence'],
    '0017': ['IS', '1', 'ResidualSyringeCounts'],
    '0018': ['SH', '1', 'EnergyWindowName'],
    '0020': ['US', '1-n', 'DetectorVector'],
    '0021': ['US', '1', 'NumberOfDetectors'],
    '0022': ['SQ', '1', 'DetectorInformationSequence'],
    '0030': ['US', '1-n', 'PhaseVector'],
    '0031': ['US', '1', 'NumberOfPhases'],
    '0032': ['SQ', '1', 'PhaseInformationSequence'],
    '0033': ['US', '1', 'NumberOfFramesInPhase'],
    '0036': ['IS', '1', 'PhaseDelay'],
    '0038': ['IS', '1', 'PauseBetweenFrames'],
    '0039': ['CS', '1', 'PhaseDescription'],
    '0050': ['US', '1-n', 'RotationVector'],
    '0051': ['US', '1', 'NumberOfRotations'],
    '0052': ['SQ', '1', 'RotationInformationSequence'],
    '0053': ['US', '1', 'NumberOfFramesInRotation'],
    '0060': ['US', '1-n', 'RRIntervalVector'],
    '0061': ['US', '1', 'NumberOfRRIntervals'],
    '0062': ['SQ', '1', 'GatedInformationSequence'],
    '0063': ['SQ', '1', 'DataInformationSequence'],
    '0070': ['US', '1-n', 'TimeSlotVector'],
    '0071': ['US', '1', 'NumberOfTimeSlots'],
    '0072': ['SQ', '1', 'TimeSlotInformationSequence'],
    '0073': ['DS', '1', 'TimeSlotTime'],
    '0080': ['US', '1-n', 'SliceVector'],
    '0081': ['US', '1', 'NumberOfSlices'],
    '0090': ['US', '1-n', 'AngularViewVector'],
    '0100': ['US', '1-n', 'TimeSliceVector'],
    '0101': ['US', '1', 'NumberOfTimeSlices'],
    '0200': ['DS', '1', 'StartAngle'],
    '0202': ['CS', '1', 'TypeOfDetectorMotion'],
    '0210': ['IS', '1-n', 'TriggerVector'],
    '0211': ['US', '1', 'NumberOfTriggersInPhase'],
    '0220': ['SQ', '1', 'ViewCodeSequence'],
    '0222': ['SQ', '1', 'ViewModifierCodeSequence'],
    '0300': ['SQ', '1', 'RadionuclideCodeSequence'],
    '0302': ['SQ', '1', 'AdministrationRouteCodeSequence'],
    '0304': ['SQ', '1', 'RadiopharmaceuticalCodeSequence'],
    '0306': ['SQ', '1', 'CalibrationDataSequence'],
    '0308': ['US', '1', 'EnergyWindowNumber'],
    '0400': ['SH', '1', 'ImageID'],
    '0410': ['SQ', '1', 'PatientOrientationCodeSequence'],
    '0412': ['SQ', '1', 'PatientOrientationModifierCodeSequence'],
    '0414': ['SQ', '1', 'PatientGantryRelationshipCodeSequence'],
    '0500': ['CS', '1', 'SliceProgressionDirection'],
    '0501': ['CS', '1', 'ScanProgressionDirection'],
    '1000': ['CS', '2', 'SeriesType'],
    '1001': ['CS', '1', 'Units'],
    '1002': ['CS', '1', 'CountsSource'],
    '1004': ['CS', '1', 'ReprojectionMethod'],
    '1006': ['CS', '1', 'SUVType'],
    '1100': ['CS', '1', 'RandomsCorrectionMethod'],
    '1101': ['LO', '1', 'AttenuationCorrectionMethod'],
    '1102': ['CS', '1', 'DecayCorrection'],
    '1103': ['LO', '1', 'ReconstructionMethod'],
    '1104': ['LO', '1', 'DetectorLinesOfResponseUsed'],
    '1105': ['LO', '1', 'ScatterCorrectionMethod'],
    '1200': ['DS', '1', 'AxialAcceptance'],
    '1201': ['IS', '2', 'AxialMash'],
    '1202': ['IS', '1', 'TransverseMash'],
    '1203': ['DS', '2', 'DetectorElementSize'],
    '1210': ['DS', '1', 'CoincidenceWindowWidth'],
    '1220': ['CS', '1-n', 'SecondaryCountsType'],
    '1300': ['DS', '1', 'FrameReferenceTime'],
    '1310': ['IS', '1', 'PrimaryPromptsCountsAccumulated'],
    '1311': ['IS', '1-n', 'SecondaryCountsAccumulated'],
    '1320': ['DS', '1', 'SliceSensitivityFactor'],
    '1321': ['DS', '1', 'DecayFactor'],
    '1322': ['DS', '1', 'DoseCalibrationFactor'],
    '1323': ['DS', '1', 'ScatterFractionFactor'],
    '1324': ['DS', '1', 'DeadTimeFactor'],
    '1330': ['US', '1', 'ImageIndex'],
    '1400': ['CS', '1-n', 'CountsIncluded'],
    '1401': ['CS', '1', 'DeadTimeCorrectionFlag']
  },
  '0060': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '3000': ['SQ', '1', 'HistogramSequence'],
    '3002': ['US', '1', 'HistogramNumberOfBins'],
    '3004': ['xs', '1', 'HistogramFirstBinValue'],
    '3006': ['xs', '1', 'HistogramLastBinValue'],
    '3008': ['US', '1', 'HistogramBinWidth'],
    '3010': ['LO', '1', 'HistogramExplanation'],
    '3020': ['UL', '1-n', 'HistogramData']
  },
  '0062': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['CS', '1', 'SegmentationType'],
    '0002': ['SQ', '1', 'SegmentSequence'],
    '0003': ['SQ', '1', 'SegmentedPropertyCategoryCodeSequence'],
    '0004': ['US', '1', 'SegmentNumber'],
    '0005': ['LO', '1', 'SegmentLabel'],
    '0006': ['ST', '1', 'SegmentDescription'],
    '0007': ['SQ', '1', 'SegmentationAlgorithmIdentificationSequence'],
    '0008': ['CS', '1', 'SegmentAlgorithmType'],
    '0009': ['LO', '1-n', 'SegmentAlgorithmName'],
    '000A': ['SQ', '1', 'SegmentIdentificationSequence'],
    '000B': ['US', '1-n', 'ReferencedSegmentNumber'],
    '000C': ['US', '1', 'RecommendedDisplayGrayscaleValue'],
    '000D': ['US', '3', 'RecommendedDisplayCIELabValue'],
    '000E': ['US', '1', 'MaximumFractionalValue'],
    '000F': ['SQ', '1', 'SegmentedPropertyTypeCodeSequence'],
    '0010': ['CS', '1', 'SegmentationFractionalType'],
    '0011': ['SQ', '1', 'SegmentedPropertyTypeModifierCodeSequence'],
    '0012': ['SQ', '1', 'UsedSegmentsSequence'],
    '0013': ['CS', '1', 'SegmentsOverlap'],
    '0020': ['UT', '1', 'TrackingID'],
    '0021': ['UI', '1', 'TrackingUID']
  },
  '0064': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['SQ', '1', 'DeformableRegistrationSequence'],
    '0003': ['UI', '1', 'SourceFrameOfReferenceUID'],
    '0005': ['SQ', '1', 'DeformableRegistrationGridSequence'],
    '0007': ['UL', '3', 'GridDimensions'],
    '0008': ['FD', '3', 'GridResolution'],
    '0009': ['OF', '1', 'VectorGridData'],
    '000F': ['SQ', '1', 'PreDeformationMatrixRegistrationSequence'],
    '0010': ['SQ', '1', 'PostDeformationMatrixRegistrationSequence']
  },
  '0066': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['UL', '1', 'NumberOfSurfaces'],
    '0002': ['SQ', '1', 'SurfaceSequence'],
    '0003': ['UL', '1', 'SurfaceNumber'],
    '0004': ['LT', '1', 'SurfaceComments'],
    '0009': ['CS', '1', 'SurfaceProcessing'],
    '000A': ['FL', '1', 'SurfaceProcessingRatio'],
    '000B': ['LO', '1', 'SurfaceProcessingDescription'],
    '000C': ['FL', '1', 'RecommendedPresentationOpacity'],
    '000D': ['CS', '1', 'RecommendedPresentationType'],
    '000E': ['CS', '1', 'FiniteVolume'],
    '0010': ['CS', '1', 'Manifold'],
    '0011': ['SQ', '1', 'SurfacePointsSequence'],
    '0012': ['SQ', '1', 'SurfacePointsNormalsSequence'],
    '0013': ['SQ', '1', 'SurfaceMeshPrimitivesSequence'],
    '0015': ['UL', '1', 'NumberOfSurfacePoints'],
    '0016': ['OF', '1', 'PointCoordinatesData'],
    '0017': ['FL', '3', 'PointPositionAccuracy'],
    '0018': ['FL', '1', 'MeanPointDistance'],
    '0019': ['FL', '1', 'MaximumPointDistance'],
    '001A': ['FL', '6', 'PointsBoundingBoxCoordinates'],
    '001B': ['FL', '3', 'AxisOfRotation'],
    '001C': ['FL', '3', 'CenterOfRotation'],
    '001E': ['UL', '1', 'NumberOfVectors'],
    '001F': ['US', '1', 'VectorDimensionality'],
    '0020': ['FL', '1-n', 'VectorAccuracy'],
    '0021': ['OF', '1', 'VectorCoordinateData'],
    '0022': ['OD', '1', 'DoublePointCoordinatesData'],
    '0023': ['OW', '1', 'TrianglePointIndexList'],
    '0024': ['OW', '1', 'EdgePointIndexList'],
    '0025': ['OW', '1', 'VertexPointIndexList'],
    '0026': ['SQ', '1', 'TriangleStripSequence'],
    '0027': ['SQ', '1', 'TriangleFanSequence'],
    '0028': ['SQ', '1', 'LineSequence'],
    '0029': ['OW', '1', 'PrimitivePointIndexList'],
    '002A': ['UL', '1', 'SurfaceCount'],
    '002B': ['SQ', '1', 'ReferencedSurfaceSequence'],
    '002C': ['UL', '1', 'ReferencedSurfaceNumber'],
    '002D': ['SQ', '1', 'SegmentSurfaceGenerationAlgorithmIdentificationSequence'],
    '002E': ['SQ', '1', 'SegmentSurfaceSourceInstanceSequence'],
    '002F': ['SQ', '1', 'AlgorithmFamilyCodeSequence'],
    '0030': ['SQ', '1', 'AlgorithmNameCodeSequence'],
    '0031': ['LO', '1', 'AlgorithmVersion'],
    '0032': ['LT', '1', 'AlgorithmParameters'],
    '0034': ['SQ', '1', 'FacetSequence'],
    '0035': ['SQ', '1', 'SurfaceProcessingAlgorithmIdentificationSequence'],
    '0036': ['LO', '1', 'AlgorithmName'],
    '0037': ['FL', '1', 'RecommendedPointRadius'],
    '0038': ['FL', '1', 'RecommendedLineThickness'],
    '0040': ['OL', '1', 'LongPrimitivePointIndexList'],
    '0041': ['OL', '1', 'LongTrianglePointIndexList'],
    '0042': ['OL', '1', 'LongEdgePointIndexList'],
    '0043': ['OL', '1', 'LongVertexPointIndexList'],
    '0101': ['SQ', '1', 'TrackSetSequence'],
    '0102': ['SQ', '1', 'TrackSequence'],
    '0103': ['OW', '1', 'RecommendedDisplayCIELabValueList'],
    '0104': ['SQ', '1', 'TrackingAlgorithmIdentificationSequence'],
    '0105': ['UL', '1', 'TrackSetNumber'],
    '0106': ['LO', '1', 'TrackSetLabel'],
    '0107': ['UT', '1', 'TrackSetDescription'],
    '0108': ['SQ', '1', 'TrackSetAnatomicalTypeCodeSequence'],
    '0121': ['SQ', '1', 'MeasurementsSequence'],
    '0124': ['SQ', '1', 'TrackSetStatisticsSequence'],
    '0125': ['OF', '1', 'FloatingPointValues'],
    '0129': ['OL', '1', 'TrackPointIndexList'],
    '0130': ['SQ', '1', 'TrackStatisticsSequence'],
    '0132': ['SQ', '1', 'MeasurementValuesSequence'],
    '0133': ['SQ', '1', 'DiffusionAcquisitionCodeSequence'],
    '0134': ['SQ', '1', 'DiffusionModelCodeSequence']
  },
  '0068': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '6210': ['LO', '1', 'ImplantSize'],
    '6221': ['LO', '1', 'ImplantTemplateVersion'],
    '6222': ['SQ', '1', 'ReplacedImplantTemplateSequence'],
    '6223': ['CS', '1', 'ImplantType'],
    '6224': ['SQ', '1', 'DerivationImplantTemplateSequence'],
    '6225': ['SQ', '1', 'OriginalImplantTemplateSequence'],
    '6226': ['DT', '1', 'EffectiveDateTime'],
    '6230': ['SQ', '1', 'ImplantTargetAnatomySequence'],
    '6260': ['SQ', '1', 'InformationFromManufacturerSequence'],
    '6265': ['SQ', '1', 'NotificationFromManufacturerSequence'],
    '6270': ['DT', '1', 'InformationIssueDateTime'],
    '6280': ['ST', '1', 'InformationSummary'],
    '62A0': ['SQ', '1', 'ImplantRegulatoryDisapprovalCodeSequence'],
    '62A5': ['FD', '1', 'OverallTemplateSpatialTolerance'],
    '62C0': ['SQ', '1', 'HPGLDocumentSequence'],
    '62D0': ['US', '1', 'HPGLDocumentID'],
    '62D5': ['LO', '1', 'HPGLDocumentLabel'],
    '62E0': ['SQ', '1', 'ViewOrientationCodeSequence'],
    '62F0': ['SQ', '1', 'ViewOrientationModifierCodeSequence'],
    '62F2': ['FD', '1', 'HPGLDocumentScaling'],
    '6300': ['OB', '1', 'HPGLDocument'],
    '6310': ['US', '1', 'HPGLContourPenNumber'],
    '6320': ['SQ', '1', 'HPGLPenSequence'],
    '6330': ['US', '1', 'HPGLPenNumber'],
    '6340': ['LO', '1', 'HPGLPenLabel'],
    '6345': ['ST', '1', 'HPGLPenDescription'],
    '6346': ['FD', '2', 'RecommendedRotationPoint'],
    '6347': ['FD', '4', 'BoundingRectangle'],
    '6350': ['US', '1-n', 'ImplantTemplate3DModelSurfaceNumber'],
    '6360': ['SQ', '1', 'SurfaceModelDescriptionSequence'],
    '6380': ['LO', '1', 'SurfaceModelLabel'],
    '6390': ['FD', '1', 'SurfaceModelScalingFactor'],
    '63A0': ['SQ', '1', 'MaterialsCodeSequence'],
    '63A4': ['SQ', '1', 'CoatingMaterialsCodeSequence'],
    '63A8': ['SQ', '1', 'ImplantTypeCodeSequence'],
    '63AC': ['SQ', '1', 'FixationMethodCodeSequence'],
    '63B0': ['SQ', '1', 'MatingFeatureSetsSequence'],
    '63C0': ['US', '1', 'MatingFeatureSetID'],
    '63D0': ['LO', '1', 'MatingFeatureSetLabel'],
    '63E0': ['SQ', '1', 'MatingFeatureSequence'],
    '63F0': ['US', '1', 'MatingFeatureID'],
    '6400': ['SQ', '1', 'MatingFeatureDegreeOfFreedomSequence'],
    '6410': ['US', '1', 'DegreeOfFreedomID'],
    '6420': ['CS', '1', 'DegreeOfFreedomType'],
    '6430': ['SQ', '1', 'TwoDMatingFeatureCoordinatesSequence'],
    '6440': ['US', '1', 'ReferencedHPGLDocumentID'],
    '6450': ['FD', '2', 'TwoDMatingPoint'],
    '6460': ['FD', '4', 'TwoDMatingAxes'],
    '6470': ['SQ', '1', 'TwoDDegreeOfFreedomSequence'],
    '6490': ['FD', '3', 'ThreeDDegreeOfFreedomAxis'],
    '64A0': ['FD', '2', 'RangeOfFreedom'],
    '64C0': ['FD', '3', 'ThreeDMatingPoint'],
    '64D0': ['FD', '9', 'ThreeDMatingAxes'],
    '64F0': ['FD', '3', 'TwoDDegreeOfFreedomAxis'],
    '6500': ['SQ', '1', 'PlanningLandmarkPointSequence'],
    '6510': ['SQ', '1', 'PlanningLandmarkLineSequence'],
    '6520': ['SQ', '1', 'PlanningLandmarkPlaneSequence'],
    '6530': ['US', '1', 'PlanningLandmarkID'],
    '6540': ['LO', '1', 'PlanningLandmarkDescription'],
    '6545': ['SQ', '1', 'PlanningLandmarkIdentificationCodeSequence'],
    '6550': ['SQ', '1', 'TwoDPointCoordinatesSequence'],
    '6560': ['FD', '2', 'TwoDPointCoordinates'],
    '6590': ['FD', '3', 'ThreeDPointCoordinates'],
    '65A0': ['SQ', '1', 'TwoDLineCoordinatesSequence'],
    '65B0': ['FD', '4', 'TwoDLineCoordinates'],
    '65D0': ['FD', '6', 'ThreeDLineCoordinates'],
    '65E0': ['SQ', '1', 'TwoDPlaneCoordinatesSequence'],
    '65F0': ['FD', '4', 'TwoDPlaneIntersection'],
    '6610': ['FD', '3', 'ThreeDPlaneOrigin'],
    '6620': ['FD', '3', 'ThreeDPlaneNormal'],
    '7001': ['CS', '1', 'ModelModification'],
    '7002': ['CS', '1', 'ModelMirroring'],
    '7003': ['SQ', '1', 'ModelUsageCodeSequence'],
    '7004': ['UI', '1', 'ModelGroupUID'],
    '7005': ['UR', '1', 'RelativeURIReferenceWithinEncapsulatedDocument']
  },
  '006A': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['CS', '1', 'AnnotationCoordinateType'],
    '0002': ['SQ', '1', 'AnnotationGroupSequence'],
    '0003': ['UI', '1', 'AnnotationGroupUID'],
    '0005': ['LO', '1', 'AnnotationGroupLabel'],
    '0006': ['UT', '1', 'AnnotationGroupDescription'],
    '0007': ['CS', '1', 'AnnotationGroupGenerationType'],
    '0008': ['SQ', '1', 'AnnotationGroupAlgorithmIdentificationSequence'],
    '0009': ['SQ', '1', 'AnnotationPropertyCategoryCodeSequence'],
    '000A': ['SQ', '1', 'AnnotationPropertyTypeCodeSequence'],
    '000B': ['SQ', '1', 'AnnotationPropertyTypeModifierCodeSequence'],
    '000C': ['UL', '1', 'NumberOfAnnotations'],
    '000D': ['CS', '1', 'AnnotationAppliesToAllOpticalPaths'],
    '000E': ['SH', '1-n', 'ReferencedOpticalPathIdentifier'],
    '000F': ['CS', '1', 'AnnotationAppliesToAllZPlanes'],
    '0010': ['FD', '1-n', 'CommonZCoordinateValue'],
    '0011': ['OL', '1', 'AnnotationIndexList']
  },
  '0070': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['SQ', '1', 'GraphicAnnotationSequence'],
    '0002': ['CS', '1', 'GraphicLayer'],
    '0003': ['CS', '1', 'BoundingBoxAnnotationUnits'],
    '0004': ['CS', '1', 'AnchorPointAnnotationUnits'],
    '0005': ['CS', '1', 'GraphicAnnotationUnits'],
    '0006': ['ST', '1', 'UnformattedTextValue'],
    '0008': ['SQ', '1', 'TextObjectSequence'],
    '0009': ['SQ', '1', 'GraphicObjectSequence'],
    '0010': ['FL', '2', 'BoundingBoxTopLeftHandCorner'],
    '0011': ['FL', '2', 'BoundingBoxBottomRightHandCorner'],
    '0012': ['CS', '1', 'BoundingBoxTextHorizontalJustification'],
    '0014': ['FL', '2', 'AnchorPoint'],
    '0015': ['CS', '1', 'AnchorPointVisibility'],
    '0020': ['US', '1', 'GraphicDimensions'],
    '0021': ['US', '1', 'NumberOfGraphicPoints'],
    '0022': ['FL', '2-n', 'GraphicData'],
    '0023': ['CS', '1', 'GraphicType'],
    '0024': ['CS', '1', 'GraphicFilled'],
    '0040': ['IS', '1', 'ImageRotationRetired'],
    '0041': ['CS', '1', 'ImageHorizontalFlip'],
    '0042': ['US', '1', 'ImageRotation'],
    '0050': ['US', '2', 'DisplayedAreaTopLeftHandCornerTrial'],
    '0051': ['US', '2', 'DisplayedAreaBottomRightHandCornerTrial'],
    '0052': ['SL', '2', 'DisplayedAreaTopLeftHandCorner'],
    '0053': ['SL', '2', 'DisplayedAreaBottomRightHandCorner'],
    '005A': ['SQ', '1', 'DisplayedAreaSelectionSequence'],
    '0060': ['SQ', '1', 'GraphicLayerSequence'],
    '0062': ['IS', '1', 'GraphicLayerOrder'],
    '0066': ['US', '1', 'GraphicLayerRecommendedDisplayGrayscaleValue'],
    '0067': ['US', '3', 'GraphicLayerRecommendedDisplayRGBValue'],
    '0068': ['LO', '1', 'GraphicLayerDescription'],
    '0080': ['CS', '1', 'ContentLabel'],
    '0081': ['LO', '1', 'ContentDescription'],
    '0082': ['DA', '1', 'PresentationCreationDate'],
    '0083': ['TM', '1', 'PresentationCreationTime'],
    '0084': ['PN', '1', 'ContentCreatorName'],
    '0086': ['SQ', '1', 'ContentCreatorIdentificationCodeSequence'],
    '0087': ['SQ', '1', 'AlternateContentDescriptionSequence'],
    '0100': ['CS', '1', 'PresentationSizeMode'],
    '0101': ['DS', '2', 'PresentationPixelSpacing'],
    '0102': ['IS', '2', 'PresentationPixelAspectRatio'],
    '0103': ['FL', '1', 'PresentationPixelMagnificationRatio'],
    '0207': ['LO', '1', 'GraphicGroupLabel'],
    '0208': ['ST', '1', 'GraphicGroupDescription'],
    '0209': ['SQ', '1', 'CompoundGraphicSequence'],
    '0226': ['UL', '1', 'CompoundGraphicInstanceID'],
    '0227': ['LO', '1', 'FontName'],
    '0228': ['CS', '1', 'FontNameType'],
    '0229': ['LO', '1', 'CSSFontName'],
    '0230': ['FD', '1', 'RotationAngle'],
    '0231': ['SQ', '1', 'TextStyleSequence'],
    '0232': ['SQ', '1', 'LineStyleSequence'],
    '0233': ['SQ', '1', 'FillStyleSequence'],
    '0234': ['SQ', '1', 'GraphicGroupSequence'],
    '0241': ['US', '3', 'TextColorCIELabValue'],
    '0242': ['CS', '1', 'HorizontalAlignment'],
    '0243': ['CS', '1', 'VerticalAlignment'],
    '0244': ['CS', '1', 'ShadowStyle'],
    '0245': ['FL', '1', 'ShadowOffsetX'],
    '0246': ['FL', '1', 'ShadowOffsetY'],
    '0247': ['US', '3', 'ShadowColorCIELabValue'],
    '0248': ['CS', '1', 'Underlined'],
    '0249': ['CS', '1', 'Bold'],
    '0250': ['CS', '1', 'Italic'],
    '0251': ['US', '3', 'PatternOnColorCIELabValue'],
    '0252': ['US', '3', 'PatternOffColorCIELabValue'],
    '0253': ['FL', '1', 'LineThickness'],
    '0254': ['CS', '1', 'LineDashingStyle'],
    '0255': ['UL', '1', 'LinePattern'],
    '0256': ['OB', '1', 'FillPattern'],
    '0257': ['CS', '1', 'FillMode'],
    '0258': ['FL', '1', 'ShadowOpacity'],
    '0261': ['FL', '1', 'GapLength'],
    '0262': ['FL', '1', 'DiameterOfVisibility'],
    '0273': ['FL', '2', 'RotationPoint'],
    '0274': ['CS', '1', 'TickAlignment'],
    '0278': ['CS', '1', 'ShowTickLabel'],
    '0279': ['CS', '1', 'TickLabelAlignment'],
    '0282': ['CS', '1', 'CompoundGraphicUnits'],
    '0284': ['FL', '1', 'PatternOnOpacity'],
    '0285': ['FL', '1', 'PatternOffOpacity'],
    '0287': ['SQ', '1', 'MajorTicksSequence'],
    '0288': ['FL', '1', 'TickPosition'],
    '0289': ['SH', '1', 'TickLabel'],
    '0294': ['CS', '1', 'CompoundGraphicType'],
    '0295': ['UL', '1', 'GraphicGroupID'],
    '0306': ['CS', '1', 'ShapeType'],
    '0308': ['SQ', '1', 'RegistrationSequence'],
    '0309': ['SQ', '1', 'MatrixRegistrationSequence'],
    '030A': ['SQ', '1', 'MatrixSequence'],
    '030B': ['FD', '16', 'FrameOfReferenceToDisplayedCoordinateSystemTransformationMatrix'],
    '030C': ['CS', '1', 'FrameOfReferenceTransformationMatrixType'],
    '030D': ['SQ', '1', 'RegistrationTypeCodeSequence'],
    '030F': ['ST', '1', 'FiducialDescription'],
    '0310': ['SH', '1', 'FiducialIdentifier'],
    '0311': ['SQ', '1', 'FiducialIdentifierCodeSequence'],
    '0312': ['FD', '1', 'ContourUncertaintyRadius'],
    '0314': ['SQ', '1', 'UsedFiducialsSequence'],
    '0318': ['SQ', '1', 'GraphicCoordinatesDataSequence'],
    '031A': ['UI', '1', 'FiducialUID'],
    '031B': ['UI', '1', 'ReferencedFiducialUID'],
    '031C': ['SQ', '1', 'FiducialSetSequence'],
    '031E': ['SQ', '1', 'FiducialSequence'],
    '031F': ['SQ', '1', 'FiducialsPropertyCategoryCodeSequence'],
    '0401': ['US', '3', 'GraphicLayerRecommendedDisplayCIELabValue'],
    '0402': ['SQ', '1', 'BlendingSequence'],
    '0403': ['FL', '1', 'RelativeOpacity'],
    '0404': ['SQ', '1', 'ReferencedSpatialRegistrationSequence'],
    '0405': ['CS', '1', 'BlendingPosition'],
    '1101': ['UI', '1', 'PresentationDisplayCollectionUID'],
    '1102': ['UI', '1', 'PresentationSequenceCollectionUID'],
    '1103': ['US', '1', 'PresentationSequencePositionIndex'],
    '1104': ['SQ', '1', 'RenderedImageReferenceSequence'],
    '1201': ['SQ', '1', 'VolumetricPresentationStateInputSequence'],
    '1202': ['CS', '1', 'PresentationInputType'],
    '1203': ['US', '1', 'InputSequencePositionIndex'],
    '1204': ['CS', '1', 'Crop'],
    '1205': ['US', '1-n', 'CroppingSpecificationIndex'],
    '1206': ['CS', '1', 'CompositingMethod'],
    '1207': ['US', '1', 'VolumetricPresentationInputNumber'],
    '1208': ['CS', '1', 'ImageVolumeGeometry'],
    '1209': ['UI', '1', 'VolumetricPresentationInputSetUID'],
    '120A': ['SQ', '1', 'VolumetricPresentationInputSetSequence'],
    '120B': ['CS', '1', 'GlobalCrop'],
    '120C': ['US', '1-n', 'GlobalCroppingSpecificationIndex'],
    '120D': ['CS', '1', 'RenderingMethod'],
    '1301': ['SQ', '1', 'VolumeCroppingSequence'],
    '1302': ['CS', '1', 'VolumeCroppingMethod'],
    '1303': ['FD', '6', 'BoundingBoxCrop'],
    '1304': ['SQ', '1', 'ObliqueCroppingPlaneSequence'],
    '1305': ['FD', '4', 'Plane'],
    '1306': ['FD', '3', 'PlaneNormal'],
    '1309': ['US', '1', 'CroppingSpecificationNumber'],
    '1501': ['CS', '1', 'MultiPlanarReconstructionStyle'],
    '1502': ['CS', '1', 'MPRThicknessType'],
    '1503': ['FD', '1', 'MPRSlabThickness'],
    '1505': ['FD', '3', 'MPRTopLeftHandCorner'],
    '1507': ['FD', '3', 'MPRViewWidthDirection'],
    '1508': ['FD', '1', 'MPRViewWidth'],
    '150C': ['UL', '1', 'NumberOfVolumetricCurvePoints'],
    '150D': ['OD', '1', 'VolumetricCurvePoints'],
    '1511': ['FD', '3', 'MPRViewHeightDirection'],
    '1512': ['FD', '1', 'MPRViewHeight'],
    '1602': ['CS', '1', 'RenderProjection'],
    '1603': ['FD', '3', 'ViewpointPosition'],
    '1604': ['FD', '3', 'ViewpointLookAtPoint'],
    '1605': ['FD', '3', 'ViewpointUpDirection'],
    '1606': ['FD', '6', 'RenderFieldOfView'],
    '1607': ['FD', '1', 'SamplingStepSize'],
    '1701': ['CS', '1', 'ShadingStyle'],
    '1702': ['FD', '1', 'AmbientReflectionIntensity'],
    '1703': ['FD', '3', 'LightDirection'],
    '1704': ['FD', '1', 'DiffuseReflectionIntensity'],
    '1705': ['FD', '1', 'SpecularReflectionIntensity'],
    '1706': ['FD', '1', 'Shininess'],
    '1801': ['SQ', '1', 'PresentationStateClassificationComponentSequence'],
    '1802': ['CS', '1', 'ComponentType'],
    '1803': ['SQ', '1', 'ComponentInputSequence'],
    '1804': ['US', '1', 'VolumetricPresentationInputIndex'],
    '1805': ['SQ', '1', 'PresentationStateCompositorComponentSequence'],
    '1806': ['SQ', '1', 'WeightingTransferFunctionSequence'],
    '1807': ['US', '3', 'WeightingLookupTableDescriptor'],
    '1808': ['OB', '1', 'WeightingLookupTableData'],
    '1901': ['SQ', '1', 'VolumetricAnnotationSequence'],
    '1903': ['SQ', '1', 'ReferencedStructuredContextSequence'],
    '1904': ['UI', '1', 'ReferencedContentItem'],
    '1905': ['SQ', '1', 'VolumetricPresentationInputAnnotationSequence'],
    '1907': ['CS', '1', 'AnnotationClipping'],
    '1A01': ['CS', '1', 'PresentationAnimationStyle'],
    '1A03': ['FD', '1', 'RecommendedAnimationRate'],
    '1A04': ['SQ', '1', 'AnimationCurveSequence'],
    '1A05': ['FD', '1', 'AnimationStepSize'],
    '1A06': ['FD', '1', 'SwivelRange'],
    '1A07': ['OD', '1', 'VolumetricCurveUpDirections'],
    '1A08': ['SQ', '1', 'VolumeStreamSequence'],
    '1A09': ['LO', '1', 'RGBATransferFunctionDescription'],
    '1B01': ['SQ', '1', 'AdvancedBlendingSequence'],
    '1B02': ['US', '1', 'BlendingInputNumber'],
    '1B03': ['SQ', '1', 'BlendingDisplayInputSequence'],
    '1B04': ['SQ', '1', 'BlendingDisplaySequence'],
    '1B06': ['CS', '1', 'BlendingMode'],
    '1B07': ['CS', '1', 'TimeSeriesBlending'],
    '1B08': ['CS', '1', 'GeometryForDisplay'],
    '1B11': ['SQ', '1', 'ThresholdSequence'],
    '1B12': ['SQ', '1', 'ThresholdValueSequence'],
    '1B13': ['CS', '1', 'ThresholdType'],
    '1B14': ['FD', '1', 'ThresholdValue']
  },
  '0072': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['SH', '1', 'HangingProtocolName'],
    '0004': ['LO', '1', 'HangingProtocolDescription'],
    '0006': ['CS', '1', 'HangingProtocolLevel'],
    '0008': ['LO', '1', 'HangingProtocolCreator'],
    '000A': ['DT', '1', 'HangingProtocolCreationDateTime'],
    '000C': ['SQ', '1', 'HangingProtocolDefinitionSequence'],
    '000E': ['SQ', '1', 'HangingProtocolUserIdentificationCodeSequence'],
    '0010': ['LO', '1', 'HangingProtocolUserGroupName'],
    '0012': ['SQ', '1', 'SourceHangingProtocolSequence'],
    '0014': ['US', '1', 'NumberOfPriorsReferenced'],
    '0020': ['SQ', '1', 'ImageSetsSequence'],
    '0022': ['SQ', '1', 'ImageSetSelectorSequence'],
    '0024': ['CS', '1', 'ImageSetSelectorUsageFlag'],
    '0026': ['AT', '1', 'SelectorAttribute'],
    '0028': ['US', '1', 'SelectorValueNumber'],
    '0030': ['SQ', '1', 'TimeBasedImageSetsSequence'],
    '0032': ['US', '1', 'ImageSetNumber'],
    '0034': ['CS', '1', 'ImageSetSelectorCategory'],
    '0038': ['US', '2', 'RelativeTime'],
    '003A': ['CS', '1', 'RelativeTimeUnits'],
    '003C': ['SS', '2', 'AbstractPriorValue'],
    '003E': ['SQ', '1', 'AbstractPriorCodeSequence'],
    '0040': ['LO', '1', 'ImageSetLabel'],
    '0050': ['CS', '1', 'SelectorAttributeVR'],
    '0052': ['AT', '1-n', 'SelectorSequencePointer'],
    '0054': ['LO', '1-n', 'SelectorSequencePointerPrivateCreator'],
    '0056': ['LO', '1', 'SelectorAttributePrivateCreator'],
    '005E': ['AE', '1-n', 'SelectorAEValue'],
    '005F': ['AS', '1-n', 'SelectorASValue'],
    '0060': ['AT', '1-n', 'SelectorATValue'],
    '0061': ['DA', '1-n', 'SelectorDAValue'],
    '0062': ['CS', '1-n', 'SelectorCSValue'],
    '0063': ['DT', '1-n', 'SelectorDTValue'],
    '0064': ['IS', '1-n', 'SelectorISValue'],
    '0065': ['OB', '1', 'SelectorOBValue'],
    '0066': ['LO', '1-n', 'SelectorLOValue'],
    '0067': ['OF', '1', 'SelectorOFValue'],
    '0068': ['LT', '1', 'SelectorLTValue'],
    '0069': ['OW', '1', 'SelectorOWValue'],
    '006A': ['PN', '1-n', 'SelectorPNValue'],
    '006B': ['TM', '1-n', 'SelectorTMValue'],
    '006C': ['SH', '1-n', 'SelectorSHValue'],
    '006D': ['UN', '1', 'SelectorUNValue'],
    '006E': ['ST', '1', 'SelectorSTValue'],
    '006F': ['UC', '1-n', 'SelectorUCValue'],
    '0070': ['UT', '1', 'SelectorUTValue'],
    '0071': ['UR', '1', 'SelectorURValue'],
    '0072': ['DS', '1-n', 'SelectorDSValue'],
    '0073': ['OD', '1', 'SelectorODValue'],
    '0074': ['FD', '1-n', 'SelectorFDValue'],
    '0075': ['OL', '1', 'SelectorOLValue'],
    '0076': ['FL', '1-n', 'SelectorFLValue'],
    '0078': ['UL', '1-n', 'SelectorULValue'],
    '007A': ['US', '1-n', 'SelectorUSValue'],
    '007C': ['SL', '1-n', 'SelectorSLValue'],
    '007E': ['SS', '1-n', 'SelectorSSValue'],
    '007F': ['UI', '1-n', 'SelectorUIValue'],
    '0080': ['SQ', '1', 'SelectorCodeSequenceValue'],
    '0081': ['OV', '1', 'SelectorOVValue'],
    '0082': ['SV', '1-n', 'SelectorSVValue'],
    '0083': ['UV', '1-n', 'SelectorUVValue'],
    '0100': ['US', '1', 'NumberOfScreens'],
    '0102': ['SQ', '1', 'NominalScreenDefinitionSequence'],
    '0104': ['US', '1', 'NumberOfVerticalPixels'],
    '0106': ['US', '1', 'NumberOfHorizontalPixels'],
    '0108': ['FD', '4', 'DisplayEnvironmentSpatialPosition'],
    '010A': ['US', '1', 'ScreenMinimumGrayscaleBitDepth'],
    '010C': ['US', '1', 'ScreenMinimumColorBitDepth'],
    '010E': ['US', '1', 'ApplicationMaximumRepaintTime'],
    '0200': ['SQ', '1', 'DisplaySetsSequence'],
    '0202': ['US', '1', 'DisplaySetNumber'],
    '0203': ['LO', '1', 'DisplaySetLabel'],
    '0204': ['US', '1', 'DisplaySetPresentationGroup'],
    '0206': ['LO', '1', 'DisplaySetPresentationGroupDescription'],
    '0208': ['CS', '1', 'PartialDataDisplayHandling'],
    '0210': ['SQ', '1', 'SynchronizedScrollingSequence'],
    '0212': ['US', '2-n', 'DisplaySetScrollingGroup'],
    '0214': ['SQ', '1', 'NavigationIndicatorSequence'],
    '0216': ['US', '1', 'NavigationDisplaySet'],
    '0218': ['US', '1-n', 'ReferenceDisplaySets'],
    '0300': ['SQ', '1', 'ImageBoxesSequence'],
    '0302': ['US', '1', 'ImageBoxNumber'],
    '0304': ['CS', '1', 'ImageBoxLayoutType'],
    '0306': ['US', '1', 'ImageBoxTileHorizontalDimension'],
    '0308': ['US', '1', 'ImageBoxTileVerticalDimension'],
    '0310': ['CS', '1', 'ImageBoxScrollDirection'],
    '0312': ['CS', '1', 'ImageBoxSmallScrollType'],
    '0314': ['US', '1', 'ImageBoxSmallScrollAmount'],
    '0316': ['CS', '1', 'ImageBoxLargeScrollType'],
    '0318': ['US', '1', 'ImageBoxLargeScrollAmount'],
    '0320': ['US', '1', 'ImageBoxOverlapPriority'],
    '0330': ['FD', '1', 'CineRelativeToRealTime'],
    '0400': ['SQ', '1', 'FilterOperationsSequence'],
    '0402': ['CS', '1', 'FilterByCategory'],
    '0404': ['CS', '1', 'FilterByAttributePresence'],
    '0406': ['CS', '1', 'FilterByOperator'],
    '0420': ['US', '3', 'StructuredDisplayBackgroundCIELabValue'],
    '0421': ['US', '3', 'EmptyImageBoxCIELabValue'],
    '0422': ['SQ', '1', 'StructuredDisplayImageBoxSequence'],
    '0424': ['SQ', '1', 'StructuredDisplayTextBoxSequence'],
    '0427': ['SQ', '1', 'ReferencedFirstFrameSequence'],
    '0430': ['SQ', '1', 'ImageBoxSynchronizationSequence'],
    '0432': ['US', '2-n', 'SynchronizedImageBoxList'],
    '0434': ['CS', '1', 'TypeOfSynchronization'],
    '0500': ['CS', '1', 'BlendingOperationType'],
    '0510': ['CS', '1', 'ReformattingOperationType'],
    '0512': ['FD', '1', 'ReformattingThickness'],
    '0514': ['FD', '1', 'ReformattingInterval'],
    '0516': ['CS', '1', 'ReformattingOperationInitialViewDirection'],
    '0520': ['CS', '1-n', 'ThreeDRenderingType'],
    '0600': ['SQ', '1', 'SortingOperationsSequence'],
    '0602': ['CS', '1', 'SortByCategory'],
    '0604': ['CS', '1', 'SortingDirection'],
    '0700': ['CS', '2', 'DisplaySetPatientOrientation'],
    '0702': ['CS', '1', 'VOIType'],
    '0704': ['CS', '1', 'PseudoColorType'],
    '0705': ['SQ', '1', 'PseudoColorPaletteInstanceReferenceSequence'],
    '0706': ['CS', '1', 'ShowGrayscaleInverted'],
    '0710': ['CS', '1', 'ShowImageTrueSizeFlag'],
    '0712': ['CS', '1', 'ShowGraphicAnnotationFlag'],
    '0714': ['CS', '1', 'ShowPatientDemographicsFlag'],
    '0716': ['CS', '1', 'ShowAcquisitionTechniquesFlag'],
    '0717': ['CS', '1', 'DisplaySetHorizontalJustification'],
    '0718': ['CS', '1', 'DisplaySetVerticalJustification']
  },
  '0074': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0120': ['FD', '1', 'ContinuationStartMeterset'],
    '0121': ['FD', '1', 'ContinuationEndMeterset'],
    '1000': ['CS', '1', 'ProcedureStepState'],
    '1002': ['SQ', '1', 'ProcedureStepProgressInformationSequence'],
    '1004': ['DS', '1', 'ProcedureStepProgress'],
    '1006': ['ST', '1', 'ProcedureStepProgressDescription'],
    '1007': ['SQ', '1', 'ProcedureStepProgressParametersSequence'],
    '1008': ['SQ', '1', 'ProcedureStepCommunicationsURISequence'],
    '100A': ['UR', '1', 'ContactURI'],
    '100C': ['LO', '1', 'ContactDisplayName'],
    '100E': ['SQ', '1', 'ProcedureStepDiscontinuationReasonCodeSequence'],
    '1020': ['SQ', '1', 'BeamTaskSequence'],
    '1022': ['CS', '1', 'BeamTaskType'],
    '1024': ['IS', '1', 'BeamOrderIndexTrial'],
    '1025': ['CS', '1', 'AutosequenceFlag'],
    '1026': ['FD', '1', 'TableTopVerticalAdjustedPosition'],
    '1027': ['FD', '1', 'TableTopLongitudinalAdjustedPosition'],
    '1028': ['FD', '1', 'TableTopLateralAdjustedPosition'],
    '102A': ['FD', '1', 'PatientSupportAdjustedAngle'],
    '102B': ['FD', '1', 'TableTopEccentricAdjustedAngle'],
    '102C': ['FD', '1', 'TableTopPitchAdjustedAngle'],
    '102D': ['FD', '1', 'TableTopRollAdjustedAngle'],
    '1030': ['SQ', '1', 'DeliveryVerificationImageSequence'],
    '1032': ['CS', '1', 'VerificationImageTiming'],
    '1034': ['CS', '1', 'DoubleExposureFlag'],
    '1036': ['CS', '1', 'DoubleExposureOrdering'],
    '1038': ['DS', '1', 'DoubleExposureMetersetTrial'],
    '103A': ['DS', '4', 'DoubleExposureFieldDeltaTrial'],
    '1040': ['SQ', '1', 'RelatedReferenceRTImageSequence'],
    '1042': ['SQ', '1', 'GeneralMachineVerificationSequence'],
    '1044': ['SQ', '1', 'ConventionalMachineVerificationSequence'],
    '1046': ['SQ', '1', 'IonMachineVerificationSequence'],
    '1048': ['SQ', '1', 'FailedAttributesSequence'],
    '104A': ['SQ', '1', 'OverriddenAttributesSequence'],
    '104C': ['SQ', '1', 'ConventionalControlPointVerificationSequence'],
    '104E': ['SQ', '1', 'IonControlPointVerificationSequence'],
    '1050': ['SQ', '1', 'AttributeOccurrenceSequence'],
    '1052': ['AT', '1', 'AttributeOccurrencePointer'],
    '1054': ['UL', '1', 'AttributeItemSelector'],
    '1056': ['LO', '1', 'AttributeOccurrencePrivateCreator'],
    '1057': ['IS', '1-n', 'SelectorSequencePointerItems'],
    '1200': ['CS', '1', 'ScheduledProcedureStepPriority'],
    '1202': ['LO', '1', 'WorklistLabel'],
    '1204': ['LO', '1', 'ProcedureStepLabel'],
    '1210': ['SQ', '1', 'ScheduledProcessingParametersSequence'],
    '1212': ['SQ', '1', 'PerformedProcessingParametersSequence'],
    '1216': ['SQ', '1', 'UnifiedProcedureStepPerformedProcedureSequence'],
    '1220': ['SQ', '1', 'RelatedProcedureStepSequence'],
    '1222': ['LO', '1', 'ProcedureStepRelationshipType'],
    '1224': ['SQ', '1', 'ReplacedProcedureStepSequence'],
    '1230': ['LO', '1', 'DeletionLock'],
    '1234': ['AE', '1', 'ReceivingAE'],
    '1236': ['AE', '1', 'RequestingAE'],
    '1238': ['LT', '1', 'ReasonForCancellation'],
    '1242': ['CS', '1', 'SCPStatus'],
    '1244': ['CS', '1', 'SubscriptionListStatus'],
    '1246': ['CS', '1', 'UnifiedProcedureStepListStatus'],
    '1324': ['UL', '1', 'BeamOrderIndex'],
    '1338': ['FD', '1', 'DoubleExposureMeterset'],
    '133A': ['FD', '4', 'DoubleExposureFieldDelta'],
    '1401': ['SQ', '1', 'BrachyTaskSequence'],
    '1402': ['DS', '1', 'ContinuationStartTotalReferenceAirKerma'],
    '1403': ['DS', '1', 'ContinuationEndTotalReferenceAirKerma'],
    '1404': ['IS', '1', 'ContinuationPulseNumber'],
    '1405': ['SQ', '1', 'ChannelDeliveryOrderSequence'],
    '1406': ['IS', '1', 'ReferencedChannelNumber'],
    '1407': ['DS', '1', 'StartCumulativeTimeWeight'],
    '1408': ['DS', '1', 'EndCumulativeTimeWeight'],
    '1409': ['SQ', '1', 'OmittedChannelSequence'],
    '140A': ['CS', '1', 'ReasonForChannelOmission'],
    '140B': ['LO', '1', 'ReasonForChannelOmissionDescription'],
    '140C': ['IS', '1', 'ChannelDeliveryOrderIndex'],
    '140D': ['SQ', '1', 'ChannelDeliveryContinuationSequence'],
    '140E': ['SQ', '1', 'OmittedApplicationSetupSequence']
  },
  '0076': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['LO', '1', 'ImplantAssemblyTemplateName'],
    '0003': ['LO', '1', 'ImplantAssemblyTemplateIssuer'],
    '0006': ['LO', '1', 'ImplantAssemblyTemplateVersion'],
    '0008': ['SQ', '1', 'ReplacedImplantAssemblyTemplateSequence'],
    '000A': ['CS', '1', 'ImplantAssemblyTemplateType'],
    '000C': ['SQ', '1', 'OriginalImplantAssemblyTemplateSequence'],
    '000E': ['SQ', '1', 'DerivationImplantAssemblyTemplateSequence'],
    '0010': ['SQ', '1', 'ImplantAssemblyTemplateTargetAnatomySequence'],
    '0020': ['SQ', '1', 'ProcedureTypeCodeSequence'],
    '0030': ['LO', '1', 'SurgicalTechnique'],
    '0032': ['SQ', '1', 'ComponentTypesSequence'],
    '0034': ['SQ', '1', 'ComponentTypeCodeSequence'],
    '0036': ['CS', '1', 'ExclusiveComponentType'],
    '0038': ['CS', '1', 'MandatoryComponentType'],
    '0040': ['SQ', '1', 'ComponentSequence'],
    '0055': ['US', '1', 'ComponentID'],
    '0060': ['SQ', '1', 'ComponentAssemblySequence'],
    '0070': ['US', '1', 'Component1ReferencedID'],
    '0080': ['US', '1', 'Component1ReferencedMatingFeatureSetID'],
    '0090': ['US', '1', 'Component1ReferencedMatingFeatureID'],
    '00A0': ['US', '1', 'Component2ReferencedID'],
    '00B0': ['US', '1', 'Component2ReferencedMatingFeatureSetID'],
    '00C0': ['US', '1', 'Component2ReferencedMatingFeatureID']
  },
  '0078': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['LO', '1', 'ImplantTemplateGroupName'],
    '0010': ['ST', '1', 'ImplantTemplateGroupDescription'],
    '0020': ['LO', '1', 'ImplantTemplateGroupIssuer'],
    '0024': ['LO', '1', 'ImplantTemplateGroupVersion'],
    '0026': ['SQ', '1', 'ReplacedImplantTemplateGroupSequence'],
    '0028': ['SQ', '1', 'ImplantTemplateGroupTargetAnatomySequence'],
    '002A': ['SQ', '1', 'ImplantTemplateGroupMembersSequence'],
    '002E': ['US', '1', 'ImplantTemplateGroupMemberID'],
    '0050': ['FD', '3', 'ThreeDImplantTemplateGroupMemberMatchingPoint'],
    '0060': ['FD', '9', 'ThreeDImplantTemplateGroupMemberMatchingAxes'],
    '0070': ['SQ', '1', 'ImplantTemplateGroupMemberMatching2DCoordinatesSequence'],
    '0090': ['FD', '2', 'TwoDImplantTemplateGroupMemberMatchingPoint'],
    '00A0': ['FD', '4', 'TwoDImplantTemplateGroupMemberMatchingAxes'],
    '00B0': ['SQ', '1', 'ImplantTemplateGroupVariationDimensionSequence'],
    '00B2': ['LO', '1', 'ImplantTemplateGroupVariationDimensionName'],
    '00B4': ['SQ', '1', 'ImplantTemplateGroupVariationDimensionRankSequence'],
    '00B6': ['US', '1', 'ReferencedImplantTemplateGroupMemberID'],
    '00B8': ['US', '1', 'ImplantTemplateGroupVariationDimensionRank']
  },
  '0080': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['SQ', '1', 'SurfaceScanAcquisitionTypeCodeSequence'],
    '0002': ['SQ', '1', 'SurfaceScanModeCodeSequence'],
    '0003': ['SQ', '1', 'RegistrationMethodCodeSequence'],
    '0004': ['FD', '1', 'ShotDurationTime'],
    '0005': ['FD', '1', 'ShotOffsetTime'],
    '0006': ['US', '1-n', 'SurfacePointPresentationValueData'],
    '0007': ['US', '3-3n', 'SurfacePointColorCIELabValueData'],
    '0008': ['SQ', '1', 'UVMappingSequence'],
    '0009': ['SH', '1', 'TextureLabel'],
    '0010': ['OF', '1', 'UValueData'],
    '0011': ['OF', '1', 'VValueData'],
    '0012': ['SQ', '1', 'ReferencedTextureSequence'],
    '0013': ['SQ', '1', 'ReferencedSurfaceDataSequence']
  },
  '0082': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['CS', '1', 'AssessmentSummary'],
    '0003': ['UT', '1', 'AssessmentSummaryDescription'],
    '0004': ['SQ', '1', 'AssessedSOPInstanceSequence'],
    '0005': ['SQ', '1', 'ReferencedComparisonSOPInstanceSequence'],
    '0006': ['UL', '1', 'NumberOfAssessmentObservations'],
    '0007': ['SQ', '1', 'AssessmentObservationsSequence'],
    '0008': ['CS', '1', 'ObservationSignificance'],
    '000A': ['UT', '1', 'ObservationDescription'],
    '000C': ['SQ', '1', 'StructuredConstraintObservationSequence'],
    '0010': ['SQ', '1', 'AssessedAttributeValueSequence'],
    '0016': ['LO', '1', 'AssessmentSetID'],
    '0017': ['SQ', '1', 'AssessmentRequesterSequence'],
    '0018': ['LO', '1', 'SelectorAttributeName'],
    '0019': ['LO', '1', 'SelectorAttributeKeyword'],
    '0021': ['SQ', '1', 'AssessmentTypeCodeSequence'],
    '0022': ['SQ', '1', 'ObservationBasisCodeSequence'],
    '0023': ['LO', '1', 'AssessmentLabel'],
    '0032': ['CS', '1', 'ConstraintType'],
    '0033': ['UT', '1', 'SpecificationSelectionGuidance'],
    '0034': ['SQ', '1', 'ConstraintValueSequence'],
    '0035': ['SQ', '1', 'RecommendedDefaultValueSequence'],
    '0036': ['CS', '1', 'ConstraintViolationSignificance'],
    '0037': ['UT', '1', 'ConstraintViolationCondition'],
    '0038': ['CS', '1', 'ModifiableConstraintFlag']
  },
  '0088': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0130': ['SH', '1', 'StorageMediaFileSetID'],
    '0140': ['UI', '1', 'StorageMediaFileSetUID'],
    '0200': ['SQ', '1', 'IconImageSequence'],
    '0904': ['LO', '1', 'TopicTitle'],
    '0906': ['ST', '1', 'TopicSubject'],
    '0910': ['LO', '1', 'TopicAuthor'],
    '0912': ['LO', '1-32', 'TopicKeywords']
  },
  '0100': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0410': ['CS', '1', 'SOPInstanceStatus'],
    '0420': ['DT', '1', 'SOPAuthorizationDateTime'],
    '0424': ['LT', '1', 'SOPAuthorizationComment'],
    '0426': ['LO', '1', 'AuthorizationEquipmentCertificationNumber']
  },
  '0400': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0005': ['US', '1', 'MACIDNumber'],
    '0010': ['UI', '1', 'MACCalculationTransferSyntaxUID'],
    '0015': ['CS', '1', 'MACAlgorithm'],
    '0020': ['AT', '1-n', 'DataElementsSigned'],
    '0100': ['UI', '1', 'DigitalSignatureUID'],
    '0105': ['DT', '1', 'DigitalSignatureDateTime'],
    '0110': ['CS', '1', 'CertificateType'],
    '0115': ['OB', '1', 'CertificateOfSigner'],
    '0120': ['OB', '1', 'Signature'],
    '0305': ['CS', '1', 'CertifiedTimestampType'],
    '0310': ['OB', '1', 'CertifiedTimestamp'],
    '0315': ['FL', '1', ''],
    '0401': ['SQ', '1', 'DigitalSignaturePurposeCodeSequence'],
    '0402': ['SQ', '1', 'ReferencedDigitalSignatureSequence'],
    '0403': ['SQ', '1', 'ReferencedSOPInstanceMACSequence'],
    '0404': ['OB', '1', 'MAC'],
    '0500': ['SQ', '1', 'EncryptedAttributesSequence'],
    '0510': ['UI', '1', 'EncryptedContentTransferSyntaxUID'],
    '0520': ['OB', '1', 'EncryptedContent'],
    '0550': ['SQ', '1', 'ModifiedAttributesSequence'],
    '0551': ['SQ', '1', 'NonconformingModifiedAttributesSequence'],
    '0552': ['OB', '1', 'NonconformingDataElementValue'],
    '0561': ['SQ', '1', 'OriginalAttributesSequence'],
    '0562': ['DT', '1', 'AttributeModificationDateTime'],
    '0563': ['LO', '1', 'ModifyingSystem'],
    '0564': ['LO', '1', 'SourceOfPreviousValues'],
    '0565': ['CS', '1', 'ReasonForTheAttributeModification'],
    '0600': ['CS', '1', 'InstanceOriginStatus']
  },
  '1000': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['US', '3', 'EscapeTriplet'],
    '0011': ['US', '3', 'RunLengthTriplet'],
    '0012': ['US', '1', 'HuffmanTableSize'],
    '0013': ['US', '3', 'HuffmanTableTriplet'],
    '0014': ['US', '1', 'ShiftTableSize'],
    '0015': ['US', '3', 'ShiftTableTriplet']
  },
  '1010': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0004': ['US', '1-n', 'ZonalMap']
  },
  '2000': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['IS', '1', 'NumberOfCopies'],
    '001E': ['SQ', '1', 'PrinterConfigurationSequence'],
    '0020': ['CS', '1', 'PrintPriority'],
    '0030': ['CS', '1', 'MediumType'],
    '0040': ['CS', '1', 'FilmDestination'],
    '0050': ['LO', '1', 'FilmSessionLabel'],
    '0060': ['IS', '1', 'MemoryAllocation'],
    '0061': ['IS', '1', 'MaximumMemoryAllocation'],
    '0062': ['CS', '1', 'ColorImagePrintingFlag'],
    '0063': ['CS', '1', 'CollationFlag'],
    '0065': ['CS', '1', 'AnnotationFlag'],
    '0067': ['CS', '1', 'ImageOverlayFlag'],
    '0069': ['CS', '1', 'PresentationLUTFlag'],
    '006A': ['CS', '1', 'ImageBoxPresentationLUTFlag'],
    '00A0': ['US', '1', 'MemoryBitDepth'],
    '00A1': ['US', '1', 'PrintingBitDepth'],
    '00A2': ['SQ', '1', 'MediaInstalledSequence'],
    '00A4': ['SQ', '1', 'OtherMediaAvailableSequence'],
    '00A8': ['SQ', '1', 'SupportedImageDisplayFormatsSequence'],
    '0500': ['SQ', '1', 'ReferencedFilmBoxSequence'],
    '0510': ['SQ', '1', 'ReferencedStoredPrintSequence']
  },
  '2010': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['ST', '1', 'ImageDisplayFormat'],
    '0030': ['CS', '1', 'AnnotationDisplayFormatID'],
    '0040': ['CS', '1', 'FilmOrientation'],
    '0050': ['CS', '1', 'FilmSizeID'],
    '0052': ['CS', '1', 'PrinterResolutionID'],
    '0054': ['CS', '1', 'DefaultPrinterResolutionID'],
    '0060': ['CS', '1', 'MagnificationType'],
    '0080': ['CS', '1', 'SmoothingType'],
    '00A6': ['CS', '1', 'DefaultMagnificationType'],
    '00A7': ['CS', '1-n', 'OtherMagnificationTypesAvailable'],
    '00A8': ['CS', '1', 'DefaultSmoothingType'],
    '00A9': ['CS', '1-n', 'OtherSmoothingTypesAvailable'],
    '0100': ['CS', '1', 'BorderDensity'],
    '0110': ['CS', '1', 'EmptyImageDensity'],
    '0120': ['US', '1', 'MinDensity'],
    '0130': ['US', '1', 'MaxDensity'],
    '0140': ['CS', '1', 'Trim'],
    '0150': ['ST', '1', 'ConfigurationInformation'],
    '0152': ['LT', '1', 'ConfigurationInformationDescription'],
    '0154': ['IS', '1', 'MaximumCollatedFilms'],
    '015E': ['US', '1', 'Illumination'],
    '0160': ['US', '1', 'ReflectedAmbientLight'],
    '0376': ['DS', '2', 'PrinterPixelSpacing'],
    '0500': ['SQ', '1', 'ReferencedFilmSessionSequence'],
    '0510': ['SQ', '1', 'ReferencedImageBoxSequence'],
    '0520': ['SQ', '1', 'ReferencedBasicAnnotationBoxSequence']
  },
  '2020': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['US', '1', 'ImageBoxPosition'],
    '0020': ['CS', '1', 'Polarity'],
    '0030': ['DS', '1', 'RequestedImageSize'],
    '0040': ['CS', '1', 'RequestedDecimateCropBehavior'],
    '0050': ['CS', '1', 'RequestedResolutionID'],
    '00A0': ['CS', '1', 'RequestedImageSizeFlag'],
    '00A2': ['CS', '1', 'DecimateCropResult'],
    '0110': ['SQ', '1', 'BasicGrayscaleImageSequence'],
    '0111': ['SQ', '1', 'BasicColorImageSequence'],
    '0130': ['SQ', '1', 'ReferencedImageOverlayBoxSequence'],
    '0140': ['SQ', '1', 'ReferencedVOILUTBoxSequence']
  },
  '2030': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['US', '1', 'AnnotationPosition'],
    '0020': ['LO', '1', 'TextString']
  },
  '2040': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['SQ', '1', 'ReferencedOverlayPlaneSequence'],
    '0011': ['US', '1-99', 'ReferencedOverlayPlaneGroups'],
    '0020': ['SQ', '1', 'OverlayPixelDataSequence'],
    '0060': ['CS', '1', 'OverlayMagnificationType'],
    '0070': ['CS', '1', 'OverlaySmoothingType'],
    '0072': ['CS', '1', 'OverlayOrImageMagnification'],
    '0074': ['US', '1', 'MagnifyToNumberOfColumns'],
    '0080': ['CS', '1', 'OverlayForegroundDensity'],
    '0082': ['CS', '1', 'OverlayBackgroundDensity'],
    '0090': ['CS', '1', 'OverlayMode'],
    '0100': ['CS', '1', 'ThresholdDensity'],
    '0500': ['SQ', '1', 'ReferencedImageBoxSequenceRetired']
  },
  '2050': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['SQ', '1', 'PresentationLUTSequence'],
    '0020': ['CS', '1', 'PresentationLUTShape'],
    '0500': ['SQ', '1', 'ReferencedPresentationLUTSequence']
  },
  '2100': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['SH', '1', 'PrintJobID'],
    '0020': ['CS', '1', 'ExecutionStatus'],
    '0030': ['CS', '1', 'ExecutionStatusInfo'],
    '0040': ['DA', '1', 'CreationDate'],
    '0050': ['TM', '1', 'CreationTime'],
    '0070': ['AE', '1', 'Originator'],
    '0140': ['AE', '1', 'DestinationAE'],
    '0160': ['SH', '1', 'OwnerID'],
    '0170': ['IS', '1', 'NumberOfFilms'],
    '0500': ['SQ', '1', 'ReferencedPrintJobSequencePullStoredPrint']
  },
  '2110': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['CS', '1', 'PrinterStatus'],
    '0020': ['CS', '1', 'PrinterStatusInfo'],
    '0030': ['LO', '1', 'PrinterName'],
    '0099': ['SH', '1', 'PrintQueueID']
  },
  '2120': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['CS', '1', 'QueueStatus'],
    '0050': ['SQ', '1', 'PrintJobDescriptionSequence'],
    '0070': ['SQ', '1', 'ReferencedPrintJobSequence']
  },
  '2130': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['SQ', '1', 'PrintManagementCapabilitiesSequence'],
    '0015': ['SQ', '1', 'PrinterCharacteristicsSequence'],
    '0030': ['SQ', '1', 'FilmBoxContentSequence'],
    '0040': ['SQ', '1', 'ImageBoxContentSequence'],
    '0050': ['SQ', '1', 'AnnotationContentSequence'],
    '0060': ['SQ', '1', 'ImageOverlayBoxContentSequence'],
    '0080': ['SQ', '1', 'PresentationLUTContentSequence'],
    '00A0': ['SQ', '1', 'ProposedStudySequence'],
    '00C0': ['SQ', '1', 'OriginalImageSequence']
  },
  '2200': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['CS', '1', 'LabelUsingInformationExtractedFromInstances'],
    '0002': ['UT', '1', 'LabelText'],
    '0003': ['CS', '1', 'LabelStyleSelection'],
    '0004': ['LT', '1', 'MediaDisposition'],
    '0005': ['LT', '1', 'BarcodeValue'],
    '0006': ['CS', '1', 'BarcodeSymbology'],
    '0007': ['CS', '1', 'AllowMediaSplitting'],
    '0008': ['CS', '1', 'IncludeNonDICOMObjects'],
    '0009': ['CS', '1', 'IncludeDisplayApplication'],
    '000A': ['CS', '1', 'PreserveCompositeInstancesAfterMediaCreation'],
    '000B': ['US', '1', 'TotalNumberOfPiecesOfMediaCreated'],
    '000C': ['LO', '1', 'RequestedMediaApplicationProfile'],
    '000D': ['SQ', '1', 'ReferencedStorageMediaSequence'],
    '000E': ['AT', '1-n', 'FailureAttributes'],
    '000F': ['CS', '1', 'AllowLossyCompression'],
    '0020': ['CS', '1', 'RequestPriority']
  },
  '3002': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['SH', '1', 'RTImageLabel'],
    '0003': ['LO', '1', 'RTImageName'],
    '0004': ['ST', '1', 'RTImageDescription'],
    '000A': ['CS', '1', 'ReportedValuesOrigin'],
    '000C': ['CS', '1', 'RTImagePlane'],
    '000D': ['DS', '3', 'XRayImageReceptorTranslation'],
    '000E': ['DS', '1', 'XRayImageReceptorAngle'],
    '0010': ['DS', '6', 'RTImageOrientation'],
    '0011': ['DS', '2', 'ImagePlanePixelSpacing'],
    '0012': ['DS', '2', 'RTImagePosition'],
    '0020': ['SH', '1', 'RadiationMachineName'],
    '0022': ['DS', '1', 'RadiationMachineSAD'],
    '0024': ['DS', '1', 'RadiationMachineSSD'],
    '0026': ['DS', '1', 'RTImageSID'],
    '0028': ['DS', '1', 'SourceToReferenceObjectDistance'],
    '0029': ['IS', '1', 'FractionNumber'],
    '0030': ['SQ', '1', 'ExposureSequence'],
    '0032': ['DS', '1', 'MetersetExposure'],
    '0034': ['DS', '4', 'DiaphragmPosition'],
    '0040': ['SQ', '1', 'FluenceMapSequence'],
    '0041': ['CS', '1', 'FluenceDataSource'],
    '0042': ['DS', '1', 'FluenceDataScale'],
    '0050': ['SQ', '1', 'PrimaryFluenceModeSequence'],
    '0051': ['CS', '1', 'FluenceMode'],
    '0052': ['SH', '1', 'FluenceModeID']
  },
  '3004': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['CS', '1', 'DVHType'],
    '0002': ['CS', '1', 'DoseUnits'],
    '0004': ['CS', '1', 'DoseType'],
    '0005': ['CS', '1', 'SpatialTransformOfDose'],
    '0006': ['LO', '1', 'DoseComment'],
    '0008': ['DS', '3', 'NormalizationPoint'],
    '000A': ['CS', '1', 'DoseSummationType'],
    '000C': ['DS', '2-n', 'GridFrameOffsetVector'],
    '000E': ['DS', '1', 'DoseGridScaling'],
    '0010': ['SQ', '1', 'RTDoseROISequence'],
    '0012': ['DS', '1', 'DoseValue'],
    '0014': ['CS', '1-3', 'TissueHeterogeneityCorrection'],
    '0040': ['DS', '3', 'DVHNormalizationPoint'],
    '0042': ['DS', '1', 'DVHNormalizationDoseValue'],
    '0050': ['SQ', '1', 'DVHSequence'],
    '0052': ['DS', '1', 'DVHDoseScaling'],
    '0054': ['CS', '1', 'DVHVolumeUnits'],
    '0056': ['IS', '1', 'DVHNumberOfBins'],
    '0058': ['DS', '2-2n', 'DVHData'],
    '0060': ['SQ', '1', 'DVHReferencedROISequence'],
    '0062': ['CS', '1', 'DVHROIContributionType'],
    '0070': ['DS', '1', 'DVHMinimumDose'],
    '0072': ['DS', '1', 'DVHMaximumDose'],
    '0074': ['DS', '1', 'DVHMeanDose']
  },
  '3006': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['SH', '1', 'StructureSetLabel'],
    '0004': ['LO', '1', 'StructureSetName'],
    '0006': ['ST', '1', 'StructureSetDescription'],
    '0008': ['DA', '1', 'StructureSetDate'],
    '0009': ['TM', '1', 'StructureSetTime'],
    '0010': ['SQ', '1', 'ReferencedFrameOfReferenceSequence'],
    '0012': ['SQ', '1', 'RTReferencedStudySequence'],
    '0014': ['SQ', '1', 'RTReferencedSeriesSequence'],
    '0016': ['SQ', '1', 'ContourImageSequence'],
    '0018': ['SQ', '1', 'PredecessorStructureSetSequence'],
    '0020': ['SQ', '1', 'StructureSetROISequence'],
    '0022': ['IS', '1', 'ROINumber'],
    '0024': ['UI', '1', 'ReferencedFrameOfReferenceUID'],
    '0026': ['LO', '1', 'ROIName'],
    '0028': ['ST', '1', 'ROIDescription'],
    '002A': ['IS', '3', 'ROIDisplayColor'],
    '002C': ['DS', '1', 'ROIVolume'],
    '0030': ['SQ', '1', 'RTRelatedROISequence'],
    '0033': ['CS', '1', 'RTROIRelationship'],
    '0036': ['CS', '1', 'ROIGenerationAlgorithm'],
    '0037': ['SQ', '1', 'ROIDerivationAlgorithmIdentificationSequence'],
    '0038': ['LO', '1', 'ROIGenerationDescription'],
    '0039': ['SQ', '1', 'ROIContourSequence'],
    '0040': ['SQ', '1', 'ContourSequence'],
    '0042': ['CS', '1', 'ContourGeometricType'],
    '0044': ['DS', '1', 'ContourSlabThickness'],
    '0045': ['DS', '3', 'ContourOffsetVector'],
    '0046': ['IS', '1', 'NumberOfContourPoints'],
    '0048': ['IS', '1', 'ContourNumber'],
    '0049': ['IS', '1-n', 'AttachedContours'],
    '004A': ['SQ', '1', 'SourcePixelPlanesCharacteristicsSequence'],
    '0050': ['DS', '3-3n', 'ContourData'],
    '0080': ['SQ', '1', 'RTROIObservationsSequence'],
    '0082': ['IS', '1', 'ObservationNumber'],
    '0084': ['IS', '1', 'ReferencedROINumber'],
    '0085': ['SH', '1', 'ROIObservationLabel'],
    '0086': ['SQ', '1', 'RTROIIdentificationCodeSequence'],
    '0088': ['ST', '1', 'ROIObservationDescription'],
    '00A0': ['SQ', '1', 'RelatedRTROIObservationsSequence'],
    '00A4': ['CS', '1', 'RTROIInterpretedType'],
    '00A6': ['PN', '1', 'ROIInterpreter'],
    '00B0': ['SQ', '1', 'ROIPhysicalPropertiesSequence'],
    '00B2': ['CS', '1', 'ROIPhysicalProperty'],
    '00B4': ['DS', '1', 'ROIPhysicalPropertyValue'],
    '00B6': ['SQ', '1', 'ROIElementalCompositionSequence'],
    '00B7': ['US', '1', 'ROIElementalCompositionAtomicNumber'],
    '00B8': ['FL', '1', 'ROIElementalCompositionAtomicMassFraction'],
    '00B9': ['SQ', '1', 'AdditionalRTROIIdentificationCodeSequence'],
    '00C0': ['SQ', '1', 'FrameOfReferenceRelationshipSequence'],
    '00C2': ['UI', '1', 'RelatedFrameOfReferenceUID'],
    '00C4': ['CS', '1', 'FrameOfReferenceTransformationType'],
    '00C6': ['DS', '16', 'FrameOfReferenceTransformationMatrix'],
    '00C8': ['LO', '1', 'FrameOfReferenceTransformationComment'],
    '00C9': ['SQ', '1', 'PatientLocationCoordinatesSequence'],
    '00CA': ['SQ', '1', 'PatientLocationCoordinatesCodeSequence'],
    '00CB': ['SQ', '1', 'PatientSupportPositionSequence']
  },
  '3008': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['SQ', '1', 'MeasuredDoseReferenceSequence'],
    '0012': ['ST', '1', 'MeasuredDoseDescription'],
    '0014': ['CS', '1', 'MeasuredDoseType'],
    '0016': ['DS', '1', 'MeasuredDoseValue'],
    '0020': ['SQ', '1', 'TreatmentSessionBeamSequence'],
    '0021': ['SQ', '1', 'TreatmentSessionIonBeamSequence'],
    '0022': ['IS', '1', 'CurrentFractionNumber'],
    '0024': ['DA', '1', 'TreatmentControlPointDate'],
    '0025': ['TM', '1', 'TreatmentControlPointTime'],
    '002A': ['CS', '1', 'TreatmentTerminationStatus'],
    '002B': ['SH', '1', 'TreatmentTerminationCode'],
    '002C': ['CS', '1', 'TreatmentVerificationStatus'],
    '0030': ['SQ', '1', 'ReferencedTreatmentRecordSequence'],
    '0032': ['DS', '1', 'SpecifiedPrimaryMeterset'],
    '0033': ['DS', '1', 'SpecifiedSecondaryMeterset'],
    '0036': ['DS', '1', 'DeliveredPrimaryMeterset'],
    '0037': ['DS', '1', 'DeliveredSecondaryMeterset'],
    '003A': ['DS', '1', 'SpecifiedTreatmentTime'],
    '003B': ['DS', '1', 'DeliveredTreatmentTime'],
    '0040': ['SQ', '1', 'ControlPointDeliverySequence'],
    '0041': ['SQ', '1', 'IonControlPointDeliverySequence'],
    '0042': ['DS', '1', 'SpecifiedMeterset'],
    '0044': ['DS', '1', 'DeliveredMeterset'],
    '0045': ['FL', '1', 'MetersetRateSet'],
    '0046': ['FL', '1', 'MetersetRateDelivered'],
    '0047': ['FL', '1-n', 'ScanSpotMetersetsDelivered'],
    '0048': ['DS', '1', 'DoseRateDelivered'],
    '0050': ['SQ', '1', 'TreatmentSummaryCalculatedDoseReferenceSequence'],
    '0052': ['DS', '1', 'CumulativeDoseToDoseReference'],
    '0054': ['DA', '1', 'FirstTreatmentDate'],
    '0056': ['DA', '1', 'MostRecentTreatmentDate'],
    '005A': ['IS', '1', 'NumberOfFractionsDelivered'],
    '0060': ['SQ', '1', 'OverrideSequence'],
    '0061': ['AT', '1', 'ParameterSequencePointer'],
    '0062': ['AT', '1', 'OverrideParameterPointer'],
    '0063': ['IS', '1', 'ParameterItemIndex'],
    '0064': ['IS', '1', 'MeasuredDoseReferenceNumber'],
    '0065': ['AT', '1', 'ParameterPointer'],
    '0066': ['ST', '1', 'OverrideReason'],
    '0067': ['US', '1', 'ParameterValueNumber'],
    '0068': ['SQ', '1', 'CorrectedParameterSequence'],
    '006A': ['FL', '1', 'CorrectionValue'],
    '0070': ['SQ', '1', 'CalculatedDoseReferenceSequence'],
    '0072': ['IS', '1', 'CalculatedDoseReferenceNumber'],
    '0074': ['ST', '1', 'CalculatedDoseReferenceDescription'],
    '0076': ['DS', '1', 'CalculatedDoseReferenceDoseValue'],
    '0078': ['DS', '1', 'StartMeterset'],
    '007A': ['DS', '1', 'EndMeterset'],
    '0080': ['SQ', '1', 'ReferencedMeasuredDoseReferenceSequence'],
    '0082': ['IS', '1', 'ReferencedMeasuredDoseReferenceNumber'],
    '0090': ['SQ', '1', 'ReferencedCalculatedDoseReferenceSequence'],
    '0092': ['IS', '1', 'ReferencedCalculatedDoseReferenceNumber'],
    '00A0': ['SQ', '1', 'BeamLimitingDeviceLeafPairsSequence'],
    '00B0': ['SQ', '1', 'RecordedWedgeSequence'],
    '00C0': ['SQ', '1', 'RecordedCompensatorSequence'],
    '00D0': ['SQ', '1', 'RecordedBlockSequence'],
    '00D1': ['SQ', '1', 'RecordedBlockSlabSequence'],
    '00E0': ['SQ', '1', 'TreatmentSummaryMeasuredDoseReferenceSequence'],
    '00F0': ['SQ', '1', 'RecordedSnoutSequence'],
    '00F2': ['SQ', '1', 'RecordedRangeShifterSequence'],
    '00F4': ['SQ', '1', 'RecordedLateralSpreadingDeviceSequence'],
    '00F6': ['SQ', '1', 'RecordedRangeModulatorSequence'],
    '0100': ['SQ', '1', 'RecordedSourceSequence'],
    '0105': ['LO', '1', 'SourceSerialNumber'],
    '0110': ['SQ', '1', 'TreatmentSessionApplicationSetupSequence'],
    '0116': ['CS', '1', 'ApplicationSetupCheck'],
    '0120': ['SQ', '1', 'RecordedBrachyAccessoryDeviceSequence'],
    '0122': ['IS', '1', 'ReferencedBrachyAccessoryDeviceNumber'],
    '0130': ['SQ', '1', 'RecordedChannelSequence'],
    '0132': ['DS', '1', 'SpecifiedChannelTotalTime'],
    '0134': ['DS', '1', 'DeliveredChannelTotalTime'],
    '0136': ['IS', '1', 'SpecifiedNumberOfPulses'],
    '0138': ['IS', '1', 'DeliveredNumberOfPulses'],
    '013A': ['DS', '1', 'SpecifiedPulseRepetitionInterval'],
    '013C': ['DS', '1', 'DeliveredPulseRepetitionInterval'],
    '0140': ['SQ', '1', 'RecordedSourceApplicatorSequence'],
    '0142': ['IS', '1', 'ReferencedSourceApplicatorNumber'],
    '0150': ['SQ', '1', 'RecordedChannelShieldSequence'],
    '0152': ['IS', '1', 'ReferencedChannelShieldNumber'],
    '0160': ['SQ', '1', 'BrachyControlPointDeliveredSequence'],
    '0162': ['DA', '1', 'SafePositionExitDate'],
    '0164': ['TM', '1', 'SafePositionExitTime'],
    '0166': ['DA', '1', 'SafePositionReturnDate'],
    '0168': ['TM', '1', 'SafePositionReturnTime'],
    '0171': ['SQ', '1', 'PulseSpecificBrachyControlPointDeliveredSequence'],
    '0172': ['US', '1', 'PulseNumber'],
    '0173': ['SQ', '1', 'BrachyPulseControlPointDeliveredSequence'],
    '0200': ['CS', '1', 'CurrentTreatmentStatus'],
    '0202': ['ST', '1', 'TreatmentStatusComment'],
    '0220': ['SQ', '1', 'FractionGroupSummarySequence'],
    '0223': ['IS', '1', 'ReferencedFractionNumber'],
    '0224': ['CS', '1', 'FractionGroupType'],
    '0230': ['CS', '1', 'BeamStopperPosition'],
    '0240': ['SQ', '1', 'FractionStatusSummarySequence'],
    '0250': ['DA', '1', 'TreatmentDate'],
    '0251': ['TM', '1', 'TreatmentTime']
  },
  '300A': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['SH', '1', 'RTPlanLabel'],
    '0003': ['LO', '1', 'RTPlanName'],
    '0004': ['ST', '1', 'RTPlanDescription'],
    '0006': ['DA', '1', 'RTPlanDate'],
    '0007': ['TM', '1', 'RTPlanTime'],
    '0009': ['LO', '1-n', 'TreatmentProtocols'],
    '000A': ['CS', '1', 'PlanIntent'],
    '000B': ['LO', '1-n', 'TreatmentSites'],
    '000C': ['CS', '1', 'RTPlanGeometry'],
    '000E': ['ST', '1', 'PrescriptionDescription'],
    '0010': ['SQ', '1', 'DoseReferenceSequence'],
    '0012': ['IS', '1', 'DoseReferenceNumber'],
    '0013': ['UI', '1', 'DoseReferenceUID'],
    '0014': ['CS', '1', 'DoseReferenceStructureType'],
    '0015': ['CS', '1', 'NominalBeamEnergyUnit'],
    '0016': ['LO', '1', 'DoseReferenceDescription'],
    '0018': ['DS', '3', 'DoseReferencePointCoordinates'],
    '001A': ['DS', '1', 'NominalPriorDose'],
    '0020': ['CS', '1', 'DoseReferenceType'],
    '0021': ['DS', '1', 'ConstraintWeight'],
    '0022': ['DS', '1', 'DeliveryWarningDose'],
    '0023': ['DS', '1', 'DeliveryMaximumDose'],
    '0025': ['DS', '1', 'TargetMinimumDose'],
    '0026': ['DS', '1', 'TargetPrescriptionDose'],
    '0027': ['DS', '1', 'TargetMaximumDose'],
    '0028': ['DS', '1', 'TargetUnderdoseVolumeFraction'],
    '002A': ['DS', '1', 'OrganAtRiskFullVolumeDose'],
    '002B': ['DS', '1', 'OrganAtRiskLimitDose'],
    '002C': ['DS', '1', 'OrganAtRiskMaximumDose'],
    '002D': ['DS', '1', 'OrganAtRiskOverdoseVolumeFraction'],
    '0040': ['SQ', '1', 'ToleranceTableSequence'],
    '0042': ['IS', '1', 'ToleranceTableNumber'],
    '0043': ['SH', '1', 'ToleranceTableLabel'],
    '0044': ['DS', '1', 'GantryAngleTolerance'],
    '0046': ['DS', '1', 'BeamLimitingDeviceAngleTolerance'],
    '0048': ['SQ', '1', 'BeamLimitingDeviceToleranceSequence'],
    '004A': ['DS', '1', 'BeamLimitingDevicePositionTolerance'],
    '004B': ['FL', '1', 'SnoutPositionTolerance'],
    '004C': ['DS', '1', 'PatientSupportAngleTolerance'],
    '004E': ['DS', '1', 'TableTopEccentricAngleTolerance'],
    '004F': ['FL', '1', 'TableTopPitchAngleTolerance'],
    '0050': ['FL', '1', 'TableTopRollAngleTolerance'],
    '0051': ['DS', '1', 'TableTopVerticalPositionTolerance'],
    '0052': ['DS', '1', 'TableTopLongitudinalPositionTolerance'],
    '0053': ['DS', '1', 'TableTopLateralPositionTolerance'],
    '0055': ['CS', '1', 'RTPlanRelationship'],
    '0070': ['SQ', '1', 'FractionGroupSequence'],
    '0071': ['IS', '1', 'FractionGroupNumber'],
    '0072': ['LO', '1', 'FractionGroupDescription'],
    '0078': ['IS', '1', 'NumberOfFractionsPlanned'],
    '0079': ['IS', '1', 'NumberOfFractionPatternDigitsPerDay'],
    '007A': ['IS', '1', 'RepeatFractionCycleLength'],
    '007B': ['LT', '1', 'FractionPattern'],
    '0080': ['IS', '1', 'NumberOfBeams'],
    '0082': ['DS', '3', 'BeamDoseSpecificationPoint'],
    '0083': ['UI', '1', 'ReferencedDoseReferenceUID'],
    '0084': ['DS', '1', 'BeamDose'],
    '0086': ['DS', '1', 'BeamMeterset'],
    '0088': ['FL', '1', 'BeamDosePointDepth'],
    '0089': ['FL', '1', 'BeamDosePointEquivalentDepth'],
    '008A': ['FL', '1', 'BeamDosePointSSD'],
    '008B': ['CS', '1', 'BeamDoseMeaning'],
    '008C': ['SQ', '1', 'BeamDoseVerificationControlPointSequence'],
    '008D': ['FL', '1', 'AverageBeamDosePointDepth'],
    '008E': ['FL', '1', 'AverageBeamDosePointEquivalentDepth'],
    '008F': ['FL', '1', 'AverageBeamDosePointSSD'],
    '0090': ['CS', '1', 'BeamDoseType'],
    '0091': ['DS', '1', 'AlternateBeamDose'],
    '0092': ['CS', '1', 'AlternateBeamDoseType'],
    '0093': ['CS', '1', 'DepthValueAveragingFlag'],
    '0094': ['DS', '1', 'BeamDosePointSourceToExternalContourDistance'],
    '00A0': ['IS', '1', 'NumberOfBrachyApplicationSetups'],
    '00A2': ['DS', '3', 'BrachyApplicationSetupDoseSpecificationPoint'],
    '00A4': ['DS', '1', 'BrachyApplicationSetupDose'],
    '00B0': ['SQ', '1', 'BeamSequence'],
    '00B2': ['SH', '1', 'TreatmentMachineName'],
    '00B3': ['CS', '1', 'PrimaryDosimeterUnit'],
    '00B4': ['DS', '1', 'SourceAxisDistance'],
    '00B6': ['SQ', '1', 'BeamLimitingDeviceSequence'],
    '00B8': ['CS', '1', 'RTBeamLimitingDeviceType'],
    '00BA': ['DS', '1', 'SourceToBeamLimitingDeviceDistance'],
    '00BB': ['FL', '1', 'IsocenterToBeamLimitingDeviceDistance'],
    '00BC': ['IS', '1', 'NumberOfLeafJawPairs'],
    '00BE': ['DS', '3-n', 'LeafPositionBoundaries'],
    '00C0': ['IS', '1', 'BeamNumber'],
    '00C2': ['LO', '1', 'BeamName'],
    '00C3': ['ST', '1', 'BeamDescription'],
    '00C4': ['CS', '1', 'BeamType'],
    '00C5': ['FD', '1', 'BeamDeliveryDurationLimit'],
    '00C6': ['CS', '1', 'RadiationType'],
    '00C7': ['CS', '1', 'HighDoseTechniqueType'],
    '00C8': ['IS', '1', 'ReferenceImageNumber'],
    '00CA': ['SQ', '1', 'PlannedVerificationImageSequence'],
    '00CC': ['LO', '1-n', 'ImagingDeviceSpecificAcquisitionParameters'],
    '00CE': ['CS', '1', 'TreatmentDeliveryType'],
    '00D0': ['IS', '1', 'NumberOfWedges'],
    '00D1': ['SQ', '1', 'WedgeSequence'],
    '00D2': ['IS', '1', 'WedgeNumber'],
    '00D3': ['CS', '1', 'WedgeType'],
    '00D4': ['SH', '1', 'WedgeID'],
    '00D5': ['IS', '1', 'WedgeAngle'],
    '00D6': ['DS', '1', 'WedgeFactor'],
    '00D7': ['FL', '1', 'TotalWedgeTrayWaterEquivalentThickness'],
    '00D8': ['DS', '1', 'WedgeOrientation'],
    '00D9': ['FL', '1', 'IsocenterToWedgeTrayDistance'],
    '00DA': ['DS', '1', 'SourceToWedgeTrayDistance'],
    '00DB': ['FL', '1', 'WedgeThinEdgePosition'],
    '00DC': ['SH', '1', 'BolusID'],
    '00DD': ['ST', '1', 'BolusDescription'],
    '00DE': ['DS', '1', 'EffectiveWedgeAngle'],
    '00E0': ['IS', '1', 'NumberOfCompensators'],
    '00E1': ['SH', '1', 'MaterialID'],
    '00E2': ['DS', '1', 'TotalCompensatorTrayFactor'],
    '00E3': ['SQ', '1', 'CompensatorSequence'],
    '00E4': ['IS', '1', 'CompensatorNumber'],
    '00E5': ['SH', '1', 'CompensatorID'],
    '00E6': ['DS', '1', 'SourceToCompensatorTrayDistance'],
    '00E7': ['IS', '1', 'CompensatorRows'],
    '00E8': ['IS', '1', 'CompensatorColumns'],
    '00E9': ['DS', '2', 'CompensatorPixelSpacing'],
    '00EA': ['DS', '2', 'CompensatorPosition'],
    '00EB': ['DS', '1-n', 'CompensatorTransmissionData'],
    '00EC': ['DS', '1-n', 'CompensatorThicknessData'],
    '00ED': ['IS', '1', 'NumberOfBoli'],
    '00EE': ['CS', '1', 'CompensatorType'],
    '00EF': ['SH', '1', 'CompensatorTrayID'],
    '00F0': ['IS', '1', 'NumberOfBlocks'],
    '00F2': ['DS', '1', 'TotalBlockTrayFactor'],
    '00F3': ['FL', '1', 'TotalBlockTrayWaterEquivalentThickness'],
    '00F4': ['SQ', '1', 'BlockSequence'],
    '00F5': ['SH', '1', 'BlockTrayID'],
    '00F6': ['DS', '1', 'SourceToBlockTrayDistance'],
    '00F7': ['FL', '1', 'IsocenterToBlockTrayDistance'],
    '00F8': ['CS', '1', 'BlockType'],
    '00F9': ['LO', '1', 'AccessoryCode'],
    '00FA': ['CS', '1', 'BlockDivergence'],
    '00FB': ['CS', '1', 'BlockMountingPosition'],
    '00FC': ['IS', '1', 'BlockNumber'],
    '00FE': ['LO', '1', 'BlockName'],
    '0100': ['DS', '1', 'BlockThickness'],
    '0102': ['DS', '1', 'BlockTransmission'],
    '0104': ['IS', '1', 'BlockNumberOfPoints'],
    '0106': ['DS', '2-2n', 'BlockData'],
    '0107': ['SQ', '1', 'ApplicatorSequence'],
    '0108': ['SH', '1', 'ApplicatorID'],
    '0109': ['CS', '1', 'ApplicatorType'],
    '010A': ['LO', '1', 'ApplicatorDescription'],
    '010C': ['DS', '1', 'CumulativeDoseReferenceCoefficient'],
    '010E': ['DS', '1', 'FinalCumulativeMetersetWeight'],
    '0110': ['IS', '1', 'NumberOfControlPoints'],
    '0111': ['SQ', '1', 'ControlPointSequence'],
    '0112': ['IS', '1', 'ControlPointIndex'],
    '0114': ['DS', '1', 'NominalBeamEnergy'],
    '0115': ['DS', '1', 'DoseRateSet'],
    '0116': ['SQ', '1', 'WedgePositionSequence'],
    '0118': ['CS', '1', 'WedgePosition'],
    '011A': ['SQ', '1', 'BeamLimitingDevicePositionSequence'],
    '011C': ['DS', '2-2n', 'LeafJawPositions'],
    '011E': ['DS', '1', 'GantryAngle'],
    '011F': ['CS', '1', 'GantryRotationDirection'],
    '0120': ['DS', '1', 'BeamLimitingDeviceAngle'],
    '0121': ['CS', '1', 'BeamLimitingDeviceRotationDirection'],
    '0122': ['DS', '1', 'PatientSupportAngle'],
    '0123': ['CS', '1', 'PatientSupportRotationDirection'],
    '0124': ['DS', '1', 'TableTopEccentricAxisDistance'],
    '0125': ['DS', '1', 'TableTopEccentricAngle'],
    '0126': ['CS', '1', 'TableTopEccentricRotationDirection'],
    '0128': ['DS', '1', 'TableTopVerticalPosition'],
    '0129': ['DS', '1', 'TableTopLongitudinalPosition'],
    '012A': ['DS', '1', 'TableTopLateralPosition'],
    '012C': ['DS', '3', 'IsocenterPosition'],
    '012E': ['DS', '3', 'SurfaceEntryPoint'],
    '0130': ['DS', '1', 'SourceToSurfaceDistance'],
    '0131': ['FL', '1', 'AverageBeamDosePointSourceToExternalContourDistance'],
    '0132': ['FL', '1', 'SourceToExternalContourDistance'],
    '0133': ['FL', '3', 'ExternalContourEntryPoint'],
    '0134': ['DS', '1', 'CumulativeMetersetWeight'],
    '0140': ['FL', '1', 'TableTopPitchAngle'],
    '0142': ['CS', '1', 'TableTopPitchRotationDirection'],
    '0144': ['FL', '1', 'TableTopRollAngle'],
    '0146': ['CS', '1', 'TableTopRollRotationDirection'],
    '0148': ['FL', '1', 'HeadFixationAngle'],
    '014A': ['FL', '1', 'GantryPitchAngle'],
    '014C': ['CS', '1', 'GantryPitchRotationDirection'],
    '014E': ['FL', '1', 'GantryPitchAngleTolerance'],
    '0150': ['CS', '1', 'FixationEye'],
    '0151': ['DS', '1', 'ChairHeadFramePosition'],
    '0152': ['DS', '1', 'HeadFixationAngleTolerance'],
    '0153': ['DS', '1', 'ChairHeadFramePositionTolerance'],
    '0154': ['DS', '1', 'FixationLightAzimuthalAngleTolerance'],
    '0155': ['DS', '1', 'FixationLightPolarAngleTolerance'],
    '0180': ['SQ', '1', 'PatientSetupSequence'],
    '0182': ['IS', '1', 'PatientSetupNumber'],
    '0183': ['LO', '1', 'PatientSetupLabel'],
    '0184': ['LO', '1', 'PatientAdditionalPosition'],
    '0190': ['SQ', '1', 'FixationDeviceSequence'],
    '0192': ['CS', '1', 'FixationDeviceType'],
    '0194': ['SH', '1', 'FixationDeviceLabel'],
    '0196': ['ST', '1', 'FixationDeviceDescription'],
    '0198': ['SH', '1', 'FixationDevicePosition'],
    '0199': ['FL', '1', 'FixationDevicePitchAngle'],
    '019A': ['FL', '1', 'FixationDeviceRollAngle'],
    '01A0': ['SQ', '1', 'ShieldingDeviceSequence'],
    '01A2': ['CS', '1', 'ShieldingDeviceType'],
    '01A4': ['SH', '1', 'ShieldingDeviceLabel'],
    '01A6': ['ST', '1', 'ShieldingDeviceDescription'],
    '01A8': ['SH', '1', 'ShieldingDevicePosition'],
    '01B0': ['CS', '1', 'SetupTechnique'],
    '01B2': ['ST', '1', 'SetupTechniqueDescription'],
    '01B4': ['SQ', '1', 'SetupDeviceSequence'],
    '01B6': ['CS', '1', 'SetupDeviceType'],
    '01B8': ['SH', '1', 'SetupDeviceLabel'],
    '01BA': ['ST', '1', 'SetupDeviceDescription'],
    '01BC': ['DS', '1', 'SetupDeviceParameter'],
    '01D0': ['ST', '1', 'SetupReferenceDescription'],
    '01D2': ['DS', '1', 'TableTopVerticalSetupDisplacement'],
    '01D4': ['DS', '1', 'TableTopLongitudinalSetupDisplacement'],
    '01D6': ['DS', '1', 'TableTopLateralSetupDisplacement'],
    '0200': ['CS', '1', 'BrachyTreatmentTechnique'],
    '0202': ['CS', '1', 'BrachyTreatmentType'],
    '0206': ['SQ', '1', 'TreatmentMachineSequence'],
    '0210': ['SQ', '1', 'SourceSequence'],
    '0212': ['IS', '1', 'SourceNumber'],
    '0214': ['CS', '1', 'SourceType'],
    '0216': ['LO', '1', 'SourceManufacturer'],
    '0218': ['DS', '1', 'ActiveSourceDiameter'],
    '021A': ['DS', '1', 'ActiveSourceLength'],
    '021B': ['SH', '1', 'SourceModelID'],
    '021C': ['LO', '1', 'SourceDescription'],
    '0222': ['DS', '1', 'SourceEncapsulationNominalThickness'],
    '0224': ['DS', '1', 'SourceEncapsulationNominalTransmission'],
    '0226': ['LO', '1', 'SourceIsotopeName'],
    '0228': ['DS', '1', 'SourceIsotopeHalfLife'],
    '0229': ['CS', '1', 'SourceStrengthUnits'],
    '022A': ['DS', '1', 'ReferenceAirKermaRate'],
    '022B': ['DS', '1', 'SourceStrength'],
    '022C': ['DA', '1', 'SourceStrengthReferenceDate'],
    '022E': ['TM', '1', 'SourceStrengthReferenceTime'],
    '0230': ['SQ', '1', 'ApplicationSetupSequence'],
    '0232': ['CS', '1', 'ApplicationSetupType'],
    '0234': ['IS', '1', 'ApplicationSetupNumber'],
    '0236': ['LO', '1', 'ApplicationSetupName'],
    '0238': ['LO', '1', 'ApplicationSetupManufacturer'],
    '0240': ['IS', '1', 'TemplateNumber'],
    '0242': ['SH', '1', 'TemplateType'],
    '0244': ['LO', '1', 'TemplateName'],
    '0250': ['DS', '1', 'TotalReferenceAirKerma'],
    '0260': ['SQ', '1', 'BrachyAccessoryDeviceSequence'],
    '0262': ['IS', '1', 'BrachyAccessoryDeviceNumber'],
    '0263': ['SH', '1', 'BrachyAccessoryDeviceID'],
    '0264': ['CS', '1', 'BrachyAccessoryDeviceType'],
    '0266': ['LO', '1', 'BrachyAccessoryDeviceName'],
    '026A': ['DS', '1', 'BrachyAccessoryDeviceNominalThickness'],
    '026C': ['DS', '1', 'BrachyAccessoryDeviceNominalTransmission'],
    '0271': ['DS', '1', 'ChannelEffectiveLength'],
    '0272': ['DS', '1', 'ChannelInnerLength'],
    '0273': ['SH', '1', 'AfterloaderChannelID'],
    '0274': ['DS', '1', 'SourceApplicatorTipLength'],
    '0280': ['SQ', '1', 'ChannelSequence'],
    '0282': ['IS', '1', 'ChannelNumber'],
    '0284': ['DS', '1', 'ChannelLength'],
    '0286': ['DS', '1', 'ChannelTotalTime'],
    '0288': ['CS', '1', 'SourceMovementType'],
    '028A': ['IS', '1', 'NumberOfPulses'],
    '028C': ['DS', '1', 'PulseRepetitionInterval'],
    '0290': ['IS', '1', 'SourceApplicatorNumber'],
    '0291': ['SH', '1', 'SourceApplicatorID'],
    '0292': ['CS', '1', 'SourceApplicatorType'],
    '0294': ['LO', '1', 'SourceApplicatorName'],
    '0296': ['DS', '1', 'SourceApplicatorLength'],
    '0298': ['LO', '1', 'SourceApplicatorManufacturer'],
    '029C': ['DS', '1', 'SourceApplicatorWallNominalThickness'],
    '029E': ['DS', '1', 'SourceApplicatorWallNominalTransmission'],
    '02A0': ['DS', '1', 'SourceApplicatorStepSize'],
    '02A1': ['IS', '1', 'ApplicatorShapeReferencedROINumber'],
    '02A2': ['IS', '1', 'TransferTubeNumber'],
    '02A4': ['DS', '1', 'TransferTubeLength'],
    '02B0': ['SQ', '1', 'ChannelShieldSequence'],
    '02B2': ['IS', '1', 'ChannelShieldNumber'],
    '02B3': ['SH', '1', 'ChannelShieldID'],
    '02B4': ['LO', '1', 'ChannelShieldName'],
    '02B8': ['DS', '1', 'ChannelShieldNominalThickness'],
    '02BA': ['DS', '1', 'ChannelShieldNominalTransmission'],
    '02C8': ['DS', '1', 'FinalCumulativeTimeWeight'],
    '02D0': ['SQ', '1', 'BrachyControlPointSequence'],
    '02D2': ['DS', '1', 'ControlPointRelativePosition'],
    '02D4': ['DS', '3', 'ControlPoint3DPosition'],
    '02D6': ['DS', '1', 'CumulativeTimeWeight'],
    '02E0': ['CS', '1', 'CompensatorDivergence'],
    '02E1': ['CS', '1', 'CompensatorMountingPosition'],
    '02E2': ['DS', '1-n', 'SourceToCompensatorDistance'],
    '02E3': ['FL', '1', 'TotalCompensatorTrayWaterEquivalentThickness'],
    '02E4': ['FL', '1', 'IsocenterToCompensatorTrayDistance'],
    '02E5': ['FL', '1', 'CompensatorColumnOffset'],
    '02E6': ['FL', '1-n', 'IsocenterToCompensatorDistances'],
    '02E7': ['FL', '1', 'CompensatorRelativeStoppingPowerRatio'],
    '02E8': ['FL', '1', 'CompensatorMillingToolDiameter'],
    '02EA': ['SQ', '1', 'IonRangeCompensatorSequence'],
    '02EB': ['LT', '1', 'CompensatorDescription'],
    '0302': ['IS', '1', 'RadiationMassNumber'],
    '0304': ['IS', '1', 'RadiationAtomicNumber'],
    '0306': ['SS', '1', 'RadiationChargeState'],
    '0308': ['CS', '1', 'ScanMode'],
    '0309': ['CS', '1', 'ModulatedScanModeType'],
    '030A': ['FL', '2', 'VirtualSourceAxisDistances'],
    '030C': ['SQ', '1', 'SnoutSequence'],
    '030D': ['FL', '1', 'SnoutPosition'],
    '030F': ['SH', '1', 'SnoutID'],
    '0312': ['IS', '1', 'NumberOfRangeShifters'],
    '0314': ['SQ', '1', 'RangeShifterSequence'],
    '0316': ['IS', '1', 'RangeShifterNumber'],
    '0318': ['SH', '1', 'RangeShifterID'],
    '0320': ['CS', '1', 'RangeShifterType'],
    '0322': ['LO', '1', 'RangeShifterDescription'],
    '0330': ['IS', '1', 'NumberOfLateralSpreadingDevices'],
    '0332': ['SQ', '1', 'LateralSpreadingDeviceSequence'],
    '0334': ['IS', '1', 'LateralSpreadingDeviceNumber'],
    '0336': ['SH', '1', 'LateralSpreadingDeviceID'],
    '0338': ['CS', '1', 'LateralSpreadingDeviceType'],
    '033A': ['LO', '1', 'LateralSpreadingDeviceDescription'],
    '033C': ['FL', '1', 'LateralSpreadingDeviceWaterEquivalentThickness'],
    '0340': ['IS', '1', 'NumberOfRangeModulators'],
    '0342': ['SQ', '1', 'RangeModulatorSequence'],
    '0344': ['IS', '1', 'RangeModulatorNumber'],
    '0346': ['SH', '1', 'RangeModulatorID'],
    '0348': ['CS', '1', 'RangeModulatorType'],
    '034A': ['LO', '1', 'RangeModulatorDescription'],
    '034C': ['SH', '1', 'BeamCurrentModulationID'],
    '0350': ['CS', '1', 'PatientSupportType'],
    '0352': ['SH', '1', 'PatientSupportID'],
    '0354': ['LO', '1', 'PatientSupportAccessoryCode'],
    '0355': ['LO', '1', 'TrayAccessoryCode'],
    '0356': ['FL', '1', 'FixationLightAzimuthalAngle'],
    '0358': ['FL', '1', 'FixationLightPolarAngle'],
    '035A': ['FL', '1', 'MetersetRate'],
    '0360': ['SQ', '1', 'RangeShifterSettingsSequence'],
    '0362': ['LO', '1', 'RangeShifterSetting'],
    '0364': ['FL', '1', 'IsocenterToRangeShifterDistance'],
    '0366': ['FL', '1', 'RangeShifterWaterEquivalentThickness'],
    '0370': ['SQ', '1', 'LateralSpreadingDeviceSettingsSequence'],
    '0372': ['LO', '1', 'LateralSpreadingDeviceSetting'],
    '0374': ['FL', '1', 'IsocenterToLateralSpreadingDeviceDistance'],
    '0380': ['SQ', '1', 'RangeModulatorSettingsSequence'],
    '0382': ['FL', '1', 'RangeModulatorGatingStartValue'],
    '0384': ['FL', '1', 'RangeModulatorGatingStopValue'],
    '0386': ['FL', '1', 'RangeModulatorGatingStartWaterEquivalentThickness'],
    '0388': ['FL', '1', 'RangeModulatorGatingStopWaterEquivalentThickness'],
    '038A': ['FL', '1', 'IsocenterToRangeModulatorDistance'],
    '038F': ['FL', '1-n', 'ScanSpotTimeOffset'],
    '0390': ['SH', '1', 'ScanSpotTuneID'],
    '0391': ['IS', '1-n', 'ScanSpotPrescribedIndices'],
    '0392': ['IS', '1', 'NumberOfScanSpotPositions'],
    '0393': ['CS', '1', 'ScanSpotReordered'],
    '0394': ['FL', '1-n', 'ScanSpotPositionMap'],
    '0395': ['CS', '1', 'ScanSpotReorderingAllowed'],
    '0396': ['FL', '1-n', 'ScanSpotMetersetWeights'],
    '0398': ['FL', '2', 'ScanningSpotSize'],
    '0399': ['FL', '2-2n', 'ScanSpotSizesDelivered'],
    '039A': ['IS', '1', 'NumberOfPaintings'],
    '03A0': ['SQ', '1', 'IonToleranceTableSequence'],
    '03A2': ['SQ', '1', 'IonBeamSequence'],
    '03A4': ['SQ', '1', 'IonBeamLimitingDeviceSequence'],
    '03A6': ['SQ', '1', 'IonBlockSequence'],
    '03A8': ['SQ', '1', 'IonControlPointSequence'],
    '03AA': ['SQ', '1', 'IonWedgeSequence'],
    '03AC': ['SQ', '1', 'IonWedgePositionSequence'],
    '0401': ['SQ', '1', 'ReferencedSetupImageSequence'],
    '0402': ['ST', '1', 'SetupImageComment'],
    '0410': ['SQ', '1', 'MotionSynchronizationSequence'],
    '0412': ['FL', '3', 'ControlPointOrientation'],
    '0420': ['SQ', '1', 'GeneralAccessorySequence'],
    '0421': ['SH', '1', 'GeneralAccessoryID'],
    '0422': ['ST', '1', 'GeneralAccessoryDescription'],
    '0423': ['CS', '1', 'GeneralAccessoryType'],
    '0424': ['IS', '1', 'GeneralAccessoryNumber'],
    '0425': ['FL', '1', 'SourceToGeneralAccessoryDistance'],
    '0426': ['DS', '1', 'IsocenterToGeneralAccessoryDistance'],
    '0431': ['SQ', '1', 'ApplicatorGeometrySequence'],
    '0432': ['CS', '1', 'ApplicatorApertureShape'],
    '0433': ['FL', '1', 'ApplicatorOpening'],
    '0434': ['FL', '1', 'ApplicatorOpeningX'],
    '0435': ['FL', '1', 'ApplicatorOpeningY'],
    '0436': ['FL', '1', 'SourceToApplicatorMountingPositionDistance'],
    '0440': ['IS', '1', 'NumberOfBlockSlabItems'],
    '0441': ['SQ', '1', 'BlockSlabSequence'],
    '0442': ['DS', '1', 'BlockSlabThickness'],
    '0443': ['US', '1', 'BlockSlabNumber'],
    '0450': ['SQ', '1', 'DeviceMotionControlSequence'],
    '0451': ['CS', '1', 'DeviceMotionExecutionMode'],
    '0452': ['CS', '1', 'DeviceMotionObservationMode'],
    '0453': ['SQ', '1', 'DeviceMotionParameterCodeSequence'],
    '0501': ['FL', '1', 'DistalDepthFraction'],
    '0502': ['FL', '1', 'DistalDepth'],
    '0503': ['FL', '2', 'NominalRangeModulationFractions'],
    '0504': ['FL', '2', 'NominalRangeModulatedRegionDepths'],
    '0505': ['SQ', '1', 'DepthDoseParametersSequence'],
    '0506': ['SQ', '1', 'DeliveredDepthDoseParametersSequence'],
    '0507': ['FL', '1', 'DeliveredDistalDepthFraction'],
    '0508': ['FL', '1', 'DeliveredDistalDepth'],
    '0509': ['FL', '2', 'DeliveredNominalRangeModulationFractions'],
    '0510': ['FL', '2', 'DeliveredNominalRangeModulatedRegionDepths'],
    '0511': ['CS', '1', 'DeliveredReferenceDoseDefinition'],
    '0512': ['CS', '1', 'ReferenceDoseDefinition'],
    '0600': ['US', '1', 'RTControlPointIndex'],
    '0601': ['US', '1', 'RadiationGenerationModeIndex'],
    '0602': ['US', '1', 'ReferencedDefinedDeviceIndex'],
    '0603': ['US', '1', 'RadiationDoseIdentificationIndex'],
    '0604': ['US', '1', 'NumberOfRTControlPoints'],
    '0605': ['US', '1', 'ReferencedRadiationGenerationModeIndex'],
    '0606': ['US', '1', 'TreatmentPositionIndex'],
    '0607': ['US', '1', 'ReferencedDeviceIndex'],
    '0608': ['LO', '1', 'TreatmentPositionGroupLabel'],
    '0609': ['UI', '1', 'TreatmentPositionGroupUID'],
    '060A': ['SQ', '1', 'TreatmentPositionGroupSequence'],
    '060B': ['US', '1', 'ReferencedTreatmentPositionIndex'],
    '060C': ['US', '1', 'ReferencedRadiationDoseIdentificationIndex'],
    '060D': ['FD', '1', 'RTAccessoryHolderWaterEquivalentThickness'],
    '060E': ['US', '1', 'ReferencedRTAccessoryHolderDeviceIndex'],
    '060F': ['CS', '1', 'RTAccessoryHolderSlotExistenceFlag'],
    '0610': ['SQ', '1', 'RTAccessoryHolderSlotSequence'],
    '0611': ['LO', '1', 'RTAccessoryHolderSlotID'],
    '0612': ['FD', '1', 'RTAccessoryHolderSlotDistance'],
    '0613': ['FD', '1', 'RTAccessorySlotDistance'],
    '0614': ['SQ', '1', 'RTAccessoryHolderDefinitionSequence'],
    '0615': ['LO', '1', 'RTAccessoryDeviceSlotID'],
    '0616': ['SQ', '1', 'RTRadiationSequence'],
    '0617': ['SQ', '1', 'RadiationDoseSequence'],
    '0618': ['SQ', '1', 'RadiationDoseIdentificationSequence'],
    '0619': ['LO', '1', 'RadiationDoseIdentificationLabel'],
    '061A': ['CS', '1', 'ReferenceDoseType'],
    '061B': ['CS', '1', 'PrimaryDoseValueIndicator'],
    '061C': ['SQ', '1', 'DoseValuesSequence'],
    '061D': ['CS', '1-n', 'DoseValuePurpose'],
    '061E': ['FD', '3', 'ReferenceDosePointCoordinates'],
    '061F': ['SQ', '1', 'RadiationDoseValuesParametersSequence'],
    '0620': ['SQ', '1', 'MetersetToDoseMappingSequence'],
    '0621': ['SQ', '1', 'ExpectedInVivoMeasurementValuesSequence'],
    '0622': ['US', '1', 'ExpectedInVivoMeasurementValueIndex'],
    '0623': ['LO', '1', 'RadiationDoseInVivoMeasurementLabel'],
    '0624': ['FD', '2', 'RadiationDoseCentralAxisDisplacement'],
    '0625': ['FD', '1', 'RadiationDoseValue'],
    '0626': ['FD', '1', 'RadiationDoseSourceToSkinDistance'],
    '0627': ['FD', '3', 'RadiationDoseMeasurementPointCoordinates'],
    '0628': ['FD', '1', 'RadiationDoseSourceToExternalContourDistance'],
    '0629': ['SQ', '1', 'RTToleranceSetSequence'],
    '062A': ['LO', '1', 'RTToleranceSetLabel'],
    '062B': ['SQ', '1', 'AttributeToleranceValuesSequence'],
    '062C': ['FD', '1', 'ToleranceValue'],
    '062D': ['SQ', '1', 'PatientSupportPositionToleranceSequence'],
    '062E': ['FD', '1', 'TreatmentTimeLimit'],
    '062F': ['SQ', '1', 'CArmPhotonElectronControlPointSequence'],
    '0630': ['SQ', '1', 'ReferencedRTRadiationSequence'],
    '0631': ['SQ', '1', 'ReferencedRTInstanceSequence'],
    '0632': ['SQ', '1', 'ReferencedRTPatientSetupSequence'],
    '0634': ['FD', '1', 'SourceToPatientSurfaceDistance'],
    '0635': ['SQ', '1', 'TreatmentMachineSpecialModeCodeSequence'],
    '0636': ['US', '1', 'IntendedNumberOfFractions'],
    '0637': ['CS', '1', 'RTRadiationSetIntent'],
    '0638': ['CS', '1', 'RTRadiationPhysicalAndGeometricContentDetailFlag'],
    '0639': ['CS', '1', 'RTRecordFlag'],
    '063A': ['SQ', '1', 'TreatmentDeviceIdentificationSequence'],
    '063B': ['SQ', '1', 'ReferencedRTPhysicianIntentSequence'],
    '063C': ['FD', '1', 'CumulativeMeterset'],
    '063D': ['FD', '1', 'DeliveryRate'],
    '063E': ['SQ', '1', 'DeliveryRateUnitSequence'],
    '063F': ['SQ', '1', 'TreatmentPositionSequence'],
    '0640': ['FD', '1', 'RadiationSourceAxisDistance'],
    '0641': ['US', '1', 'NumberOfRTBeamLimitingDevices'],
    '0642': ['FD', '1', 'RTBeamLimitingDeviceProximalDistance'],
    '0643': ['FD', '1', 'RTBeamLimitingDeviceDistalDistance'],
    '0644': ['SQ', '1', 'ParallelRTBeamDelimiterDeviceOrientationLabelCodeSequence'],
    '0645': ['FD', '1', 'BeamModifierOrientationAngle'],
    '0646': ['SQ', '1', 'FixedRTBeamDelimiterDeviceSequence'],
    '0647': ['SQ', '1', 'ParallelRTBeamDelimiterDeviceSequence'],
    '0648': ['US', '1', 'NumberOfParallelRTBeamDelimiters'],
    '0649': ['FD', '2-n', 'ParallelRTBeamDelimiterBoundaries'],
    '064A': ['FD', '2-n', 'ParallelRTBeamDelimiterPositions'],
    '064B': ['FD', '2', 'RTBeamLimitingDeviceOffset'],
    '064C': ['SQ', '1', 'RTBeamDelimiterGeometrySequence'],
    '064D': ['SQ', '1', 'RTBeamLimitingDeviceDefinitionSequence'],
    '064E': ['CS', '1', 'ParallelRTBeamDelimiterOpeningMode'],
    '064F': ['CS', '1-n', 'ParallelRTBeamDelimiterLeafMountingSide'],
    '0650': ['UI', '1', 'PatientSetupUID'],
    '0651': ['SQ', '1', 'WedgeDefinitionSequence'],
    '0652': ['FD', '1', 'RadiationBeamWedgeAngle'],
    '0653': ['FD', '1', 'RadiationBeamWedgeThinEdgeDistance'],
    '0654': ['FD', '1', 'RadiationBeamEffectiveWedgeAngle'],
    '0655': ['US', '1', 'NumberOfWedgePositions'],
    '0656': ['SQ', '1', 'RTBeamLimitingDeviceOpeningSequence'],
    '0657': ['US', '1', 'NumberOfRTBeamLimitingDeviceOpenings'],
    '0658': ['SQ', '1', 'RadiationDosimeterUnitSequence'],
    '0659': ['SQ', '1', 'RTDeviceDistanceReferenceLocationCodeSequence'],
    '065A': ['SQ', '1', 'RadiationDeviceConfigurationAndCommissioningKeySequence'],
    '065B': ['SQ', '1', 'PatientSupportPositionParameterSequence'],
    '065C': ['CS', '1', 'PatientSupportPositionSpecificationMethod'],
    '065D': ['SQ', '1', 'PatientSupportPositionDeviceParameterSequence'],
    '065E': ['US', '1', 'DeviceOrderIndex'],
    '065F': ['US', '1', 'PatientSupportPositionParameterOrderIndex'],
    '0660': ['SQ', '1', 'PatientSupportPositionDeviceToleranceSequence'],
    '0661': ['US', '1', 'PatientSupportPositionToleranceOrderIndex'],
    '0662': ['SQ', '1', 'CompensatorDefinitionSequence'],
    '0663': ['CS', '1', 'CompensatorMapOrientation'],
    '0664': ['OF', '1', 'CompensatorProximalThicknessMap'],
    '0665': ['OF', '1', 'CompensatorDistalThicknessMap'],
    '0666': ['FD', '1', 'CompensatorBasePlaneOffset'],
    '0667': ['SQ', '1', 'CompensatorShapeFabricationCodeSequence'],
    '0668': ['SQ', '1', 'CompensatorShapeSequence'],
    '0669': ['FD', '1', 'RadiationBeamCompensatorMillingToolDiameter'],
    '066A': ['SQ', '1', 'BlockDefinitionSequence'],
    '066B': ['OF', '1', 'BlockEdgeData'],
    '066C': ['CS', '1', 'BlockOrientation'],
    '066D': ['FD', '1', 'RadiationBeamBlockThickness'],
    '066E': ['FD', '1', 'RadiationBeamBlockSlabThickness'],
    '066F': ['SQ', '1', 'BlockEdgeDataSequence'],
    '0670': ['US', '1', 'NumberOfRTAccessoryHolders'],
    '0671': ['SQ', '1', 'GeneralAccessoryDefinitionSequence'],
    '0672': ['US', '1', 'NumberOfGeneralAccessories'],
    '0673': ['SQ', '1', 'BolusDefinitionSequence'],
    '0674': ['US', '1', 'NumberOfBoluses'],
    '0675': ['UI', '1', 'EquipmentFrameOfReferenceUID'],
    '0676': ['ST', '1', 'EquipmentFrameOfReferenceDescription'],
    '0677': ['SQ', '1', 'EquipmentReferencePointCoordinatesSequence'],
    '0678': ['SQ', '1', 'EquipmentReferencePointCodeSequence'],
    '0679': ['FD', '1', 'RTBeamLimitingDeviceAngle'],
    '067A': ['FD', '1', 'SourceRollAngle'],
    '067B': ['SQ', '1', 'RadiationGenerationModeSequence'],
    '067C': ['SH', '1', 'RadiationGenerationModeLabel'],
    '067D': ['ST', '1', 'RadiationGenerationModeDescription'],
    '067E': ['SQ', '1', 'RadiationGenerationModeMachineCodeSequence'],
    '067F': ['SQ', '1', 'RadiationTypeCodeSequence'],
    '0680': ['DS', '1', 'NominalEnergy'],
    '0681': ['DS', '1', 'MinimumNominalEnergy'],
    '0682': ['DS', '1', 'MaximumNominalEnergy'],
    '0683': ['SQ', '1', 'RadiationFluenceModifierCodeSequence'],
    '0684': ['SQ', '1', 'EnergyUnitCodeSequence'],
    '0685': ['US', '1', 'NumberOfRadiationGenerationModes'],
    '0686': ['SQ', '1', 'PatientSupportDevicesSequence'],
    '0687': ['US', '1', 'NumberOfPatientSupportDevices'],
    '0688': ['FD', '1', 'RTBeamModifierDefinitionDistance'],
    '0689': ['SQ', '1', 'BeamAreaLimitSequence'],
    '068A': ['SQ', '1', 'ReferencedRTPrescriptionSequence'],
    '0700': ['UI', '1', 'TreatmentSessionUID'],
    '0701': ['CS', '1', 'RTRadiationUsage'],
    '0702': ['SQ', '1', 'ReferencedRTRadiationSetSequence'],
    '0703': ['SQ', '1', 'ReferencedRTRadiationRecordSequence'],
    '0704': ['US', '1', 'RTRadiationSetDeliveryNumber'],
    '0705': ['US', '1', 'ClinicalFractionNumber'],
    '0706': ['CS', '1', 'RTTreatmentFractionCompletionStatus'],
    '0707': ['CS', '1', 'RTRadiationSetUsage'],
    '0708': ['CS', '1', 'TreatmentDeliveryContinuationFlag'],
    '0709': ['CS', '1', 'TreatmentRecordContentOrigin'],
    '0714': ['CS', '1', 'RTTreatmentTerminationStatus'],
    '0715': ['SQ', '1', 'RTTreatmentTerminationReasonCodeSequence'],
    '0716': ['SQ', '1', 'MachineSpecificTreatmentTerminationCodeSequence'],
    '0722': ['SQ', '1', 'RTRadiationSalvageRecordControlPointSequence'],
    '0723': ['CS', '1', 'StartingMetersetValueKnownFlag'],
    '0730': ['ST', '1', 'TreatmentTerminationDescription'],
    '0731': ['SQ', '1', 'TreatmentToleranceViolationSequence'],
    '0732': ['CS', '1', 'TreatmentToleranceViolationCategory'],
    '0733': ['SQ', '1', 'TreatmentToleranceViolationAttributeSequence'],
    '0734': ['ST', '1', 'TreatmentToleranceViolationDescription'],
    '0735': ['ST', '1', 'TreatmentToleranceViolationIdentification'],
    '0736': ['DT', '1', 'TreatmentToleranceViolationDateTime'],
    '073A': ['DT', '1', 'RecordedRTControlPointDateTime'],
    '073B': ['US', '1', 'ReferencedRadiationRTControlPointIndex'],
    '073E': ['SQ', '1', 'AlternateValueSequence'],
    '073F': ['SQ', '1', 'ConfirmationSequence'],
    '0740': ['SQ', '1', 'InterlockSequence'],
    '0741': ['DT', '1', 'InterlockDateTime'],
    '0742': ['ST', '1', 'InterlockDescription'],
    '0743': ['SQ', '1', 'InterlockOriginatingDeviceSequence'],
    '0744': ['SQ', '1', 'InterlockCodeSequence'],
    '0745': ['SQ', '1', 'InterlockResolutionCodeSequence'],
    '0746': ['SQ', '1', 'InterlockResolutionUserSequence'],
    '0760': ['DT', '1', 'OverrideDateTime'],
    '0761': ['SQ', '1', 'TreatmentToleranceViolationTypeCodeSequence'],
    '0762': ['SQ', '1', 'TreatmentToleranceViolationCauseCodeSequence'],
    '0772': ['SQ', '1', 'MeasuredMetersetToDoseMappingSequence'],
    '0773': ['US', '1', 'ReferencedExpectedInVivoMeasurementValueIndex'],
    '0774': ['SQ', '1', 'DoseMeasurementDeviceCodeSequence'],
    '0780': ['SQ', '1', 'AdditionalParameterRecordingInstanceSequence'],
    '0782': ['US', '1', ''],
    '0783': ['ST', '1', 'InterlockOriginDescription'],
    '0784': ['SQ', '1', 'RTPatientPositionScopeSequence'],
    '0785': ['UI', '1', 'ReferencedTreatmentPositionGroupUID'],
    '0786': ['US', '1', 'RadiationOrderIndex'],
    '0787': ['SQ', '1', 'OmittedRadiationSequence'],
    '0788': ['SQ', '1', 'ReasonForOmissionCodeSequence'],
    '0789': ['SQ', '1', 'RTDeliveryStartPatientPositionSequence'],
    '078A': ['SQ', '1', 'RTTreatmentPreparationPatientPositionSequence'],
    '078B': ['SQ', '1', 'ReferencedRTTreatmentPreparationSequence'],
    '078C': ['SQ', '1', 'ReferencedPatientSetupPhotoSequence'],
    '078D': ['SQ', '1', 'PatientTreatmentPreparationMethodCodeSequence'],
    '078E': ['LT', '1', 'PatientTreatmentPreparationProcedureParameterDescription'],
    '078F': ['SQ', '1', 'PatientTreatmentPreparationDeviceSequence'],
    '0790': ['SQ', '1', 'PatientTreatmentPreparationProcedureSequence'],
    '0791': ['SQ', '1', 'PatientTreatmentPreparationProcedureCodeSequence'],
    '0792': ['LT', '1', 'PatientTreatmentPreparationMethodDescription'],
    '0793': ['SQ', '1', 'PatientTreatmentPreparationProcedureParameterSequence'],
    '0794': ['LT', '1', 'PatientSetupPhotoDescription'],
    '0795': ['US', '1', 'PatientTreatmentPreparationProcedureIndex'],
    '0796': ['US', '1', 'ReferencedPatientSetupProcedureIndex'],
    '0797': ['SQ', '1', 'RTRadiationTaskSequence'],
    '0798': ['SQ', '1', 'RTPatientPositionDisplacementSequence'],
    '0799': ['SQ', '1', 'RTPatientPositionSequence'],
    '079A': ['LO', '1', 'DisplacementReferenceLabel'],
    '079B': ['FD', '16', 'DisplacementMatrix'],
    '079C': ['SQ', '1', 'PatientSupportDisplacementSequence'],
    '079D': ['SQ', '1', 'DisplacementReferenceLocationCodeSequence'],
    '079E': ['CS', '1', 'RTRadiationSetDeliveryUsage']
  },
  '300C': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['SQ', '1', 'ReferencedRTPlanSequence'],
    '0004': ['SQ', '1', 'ReferencedBeamSequence'],
    '0006': ['IS', '1', 'ReferencedBeamNumber'],
    '0007': ['IS', '1', 'ReferencedReferenceImageNumber'],
    '0008': ['DS', '1', 'StartCumulativeMetersetWeight'],
    '0009': ['DS', '1', 'EndCumulativeMetersetWeight'],
    '000A': ['SQ', '1', 'ReferencedBrachyApplicationSetupSequence'],
    '000C': ['IS', '1', 'ReferencedBrachyApplicationSetupNumber'],
    '000E': ['IS', '1', 'ReferencedSourceNumber'],
    '0020': ['SQ', '1', 'ReferencedFractionGroupSequence'],
    '0022': ['IS', '1', 'ReferencedFractionGroupNumber'],
    '0040': ['SQ', '1', 'ReferencedVerificationImageSequence'],
    '0042': ['SQ', '1', 'ReferencedReferenceImageSequence'],
    '0050': ['SQ', '1', 'ReferencedDoseReferenceSequence'],
    '0051': ['IS', '1', 'ReferencedDoseReferenceNumber'],
    '0055': ['SQ', '1', 'BrachyReferencedDoseReferenceSequence'],
    '0060': ['SQ', '1', 'ReferencedStructureSetSequence'],
    '006A': ['IS', '1', 'ReferencedPatientSetupNumber'],
    '0080': ['SQ', '1', 'ReferencedDoseSequence'],
    '00A0': ['IS', '1', 'ReferencedToleranceTableNumber'],
    '00B0': ['SQ', '1', 'ReferencedBolusSequence'],
    '00C0': ['IS', '1', 'ReferencedWedgeNumber'],
    '00D0': ['IS', '1', 'ReferencedCompensatorNumber'],
    '00E0': ['IS', '1', 'ReferencedBlockNumber'],
    '00F0': ['IS', '1', 'ReferencedControlPointIndex'],
    '00F2': ['SQ', '1', 'ReferencedControlPointSequence'],
    '00F4': ['IS', '1', 'ReferencedStartControlPointIndex'],
    '00F6': ['IS', '1', 'ReferencedStopControlPointIndex'],
    '0100': ['IS', '1', 'ReferencedRangeShifterNumber'],
    '0102': ['IS', '1', 'ReferencedLateralSpreadingDeviceNumber'],
    '0104': ['IS', '1', 'ReferencedRangeModulatorNumber'],
    '0111': ['SQ', '1', 'OmittedBeamTaskSequence'],
    '0112': ['CS', '1', 'ReasonForOmission'],
    '0113': ['LO', '1', 'ReasonForOmissionDescription'],
    '0114': ['SQ', '1', 'PrescriptionOverviewSequence'],
    '0115': ['FL', '1', 'TotalPrescriptionDose'],
    '0116': ['SQ', '1', 'PlanOverviewSequence'],
    '0117': ['US', '1', 'PlanOverviewIndex'],
    '0118': ['US', '1', 'ReferencedPlanOverviewIndex'],
    '0119': ['US', '1', 'NumberOfFractionsIncluded'],
    '0120': ['SQ', '1', 'DoseCalibrationConditionsSequence'],
    '0121': ['FD', '1', 'AbsorbedDoseToMetersetRatio'],
    '0122': ['FD', '2', 'DelineatedRadiationFieldSize'],
    '0123': ['CS', '1', 'DoseCalibrationConditionsVerifiedFlag'],
    '0124': ['FD', '1', 'CalibrationReferencePointDepth'],
    '0125': ['SQ', '1', 'GatingBeamHoldTransitionSequence'],
    '0126': ['CS', '1', 'BeamHoldTransition'],
    '0127': ['DT', '1', 'BeamHoldTransitionDateTime'],
    '0128': ['SQ', '1', 'BeamHoldOriginatingDeviceSequence']
  },
  '300E': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0002': ['CS', '1', 'ApprovalStatus'],
    '0004': ['DA', '1', 'ReviewDate'],
    '0005': ['TM', '1', 'ReviewTime'],
    '0008': ['PN', '1', 'ReviewerName']
  },
  '3010': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['SQ', '1', 'RadiobiologicalDoseEffectSequence'],
    '0002': ['CS', '1', 'RadiobiologicalDoseEffectFlag'],
    '0003': ['SQ', '1', 'EffectiveDoseCalculationMethodCategoryCodeSequence'],
    '0004': ['SQ', '1', 'EffectiveDoseCalculationMethodCodeSequence'],
    '0005': ['LO', '1', 'EffectiveDoseCalculationMethodDescription'],
    '0006': ['UI', '1', 'ConceptualVolumeUID'],
    '0007': ['SQ', '1', 'OriginatingSOPInstanceReferenceSequence'],
    '0008': ['SQ', '1', 'ConceptualVolumeConstituentSequence'],
    '0009': ['SQ', '1', 'EquivalentConceptualVolumeInstanceReferenceSequence'],
    '000A': ['SQ', '1', 'EquivalentConceptualVolumesSequence'],
    '000B': ['UI', '1', 'ReferencedConceptualVolumeUID'],
    '000C': ['UT', '1', 'ConceptualVolumeCombinationExpression'],
    '000D': ['US', '1', 'ConceptualVolumeConstituentIndex'],
    '000E': ['CS', '1', 'ConceptualVolumeCombinationFlag'],
    '000F': ['ST', '1', 'ConceptualVolumeCombinationDescription'],
    '0010': ['CS', '1', 'ConceptualVolumeSegmentationDefinedFlag'],
    '0011': ['SQ', '1', 'ConceptualVolumeSegmentationReferenceSequence'],
    '0012': ['SQ', '1', 'ConceptualVolumeConstituentSegmentationReferenceSequence'],
    '0013': ['UI', '1', 'ConstituentConceptualVolumeUID'],
    '0014': ['SQ', '1', 'DerivationConceptualVolumeSequence'],
    '0015': ['UI', '1', 'SourceConceptualVolumeUID'],
    '0016': ['SQ', '1', 'ConceptualVolumeDerivationAlgorithmSequence'],
    '0017': ['ST', '1', 'ConceptualVolumeDescription'],
    '0018': ['SQ', '1', 'SourceConceptualVolumeSequence'],
    '0019': ['SQ', '1', 'AuthorIdentificationSequence'],
    '001A': ['LO', '1', 'ManufacturerModelVersion'],
    '001B': ['UC', '1', 'DeviceAlternateIdentifier'],
    '001C': ['CS', '1', 'DeviceAlternateIdentifierType'],
    '001D': ['LT', '1', 'DeviceAlternateIdentifierFormat'],
    '001E': ['LO', '1', 'SegmentationCreationTemplateLabel'],
    '001F': ['UI', '1', 'SegmentationTemplateUID'],
    '0020': ['US', '1', 'ReferencedSegmentReferenceIndex'],
    '0021': ['SQ', '1', 'SegmentReferenceSequence'],
    '0022': ['US', '1', 'SegmentReferenceIndex'],
    '0023': ['SQ', '1', 'DirectSegmentReferenceSequence'],
    '0024': ['SQ', '1', 'CombinationSegmentReferenceSequence'],
    '0025': ['SQ', '1', 'ConceptualVolumeSequence'],
    '0026': ['SQ', '1', 'SegmentedRTAccessoryDeviceSequence'],
    '0027': ['SQ', '1', 'SegmentCharacteristicsSequence'],
    '0028': ['SQ', '1', 'RelatedSegmentCharacteristicsSequence'],
    '0029': ['US', '1', 'SegmentCharacteristicsPrecedence'],
    '002A': ['SQ', '1', 'RTSegmentAnnotationSequence'],
    '002B': ['SQ', '1', 'SegmentAnnotationCategoryCodeSequence'],
    '002C': ['SQ', '1', 'SegmentAnnotationTypeCodeSequence'],
    '002D': ['LO', '1', 'DeviceLabel'],
    '002E': ['SQ', '1', 'DeviceTypeCodeSequence'],
    '002F': ['SQ', '1', 'SegmentAnnotationTypeModifierCodeSequence'],
    '0030': ['SQ', '1', 'PatientEquipmentRelationshipCodeSequence'],
    '0031': ['UI', '1', 'ReferencedFiducialsUID'],
    '0032': ['SQ', '1', 'PatientTreatmentOrientationSequence'],
    '0033': ['SH', '1', 'UserContentLabel'],
    '0034': ['LO', '1', 'UserContentLongLabel'],
    '0035': ['SH', '1', 'EntityLabel'],
    '0036': ['LO', '1', 'EntityName'],
    '0037': ['ST', '1', 'EntityDescription'],
    '0038': ['LO', '1', 'EntityLongLabel'],
    '0039': ['US', '1', 'DeviceIndex'],
    '003A': ['US', '1', 'RTTreatmentPhaseIndex'],
    '003B': ['UI', '1', 'RTTreatmentPhaseUID'],
    '003C': ['US', '1', 'RTPrescriptionIndex'],
    '003D': ['US', '1', 'RTSegmentAnnotationIndex'],
    '003E': ['US', '1', 'BasisRTTreatmentPhaseIndex'],
    '003F': ['US', '1', 'RelatedRTTreatmentPhaseIndex'],
    '0040': ['US', '1', 'ReferencedRTTreatmentPhaseIndex'],
    '0041': ['US', '1', 'ReferencedRTPrescriptionIndex'],
    '0042': ['US', '1', 'ReferencedParentRTPrescriptionIndex'],
    '0043': ['ST', '1', 'ManufacturerDeviceIdentifier'],
    '0044': ['SQ', '1', 'InstanceLevelReferencedPerformedProcedureStepSequence'],
    '0045': ['CS', '1', 'RTTreatmentPhaseIntentPresenceFlag'],
    '0046': ['CS', '1', 'RadiotherapyTreatmentType'],
    '0047': ['CS', '1-n', 'TeletherapyRadiationType'],
    '0048': ['CS', '1-n', 'BrachytherapySourceType'],
    '0049': ['SQ', '1', 'ReferencedRTTreatmentPhaseSequence'],
    '004A': ['SQ', '1', 'ReferencedDirectSegmentInstanceSequence'],
    '004B': ['SQ', '1', 'IntendedRTTreatmentPhaseSequence'],
    '004C': ['DA', '1', 'IntendedPhaseStartDate'],
    '004D': ['DA', '1', 'IntendedPhaseEndDate'],
    '004E': ['SQ', '1', 'RTTreatmentPhaseIntervalSequence'],
    '004F': ['CS', '1', 'TemporalRelationshipIntervalAnchor'],
    '0050': ['FD', '1', 'MinimumNumberOfIntervalDays'],
    '0051': ['FD', '1', 'MaximumNumberOfIntervalDays'],
    '0052': ['UI', '1-n', 'PertinentSOPClassesInStudy'],
    '0053': ['UI', '1-n', 'PertinentSOPClassesInSeries'],
    '0054': ['LO', '1', 'RTPrescriptionLabel'],
    '0055': ['SQ', '1', 'RTPhysicianIntentPredecessorSequence'],
    '0056': ['LO', '1', 'RTTreatmentApproachLabel'],
    '0057': ['SQ', '1', 'RTPhysicianIntentSequence'],
    '0058': ['US', '1', 'RTPhysicianIntentIndex'],
    '0059': ['CS', '1', 'RTTreatmentIntentType'],
    '005A': ['UT', '1', 'RTPhysicianIntentNarrative'],
    '005B': ['SQ', '1', 'RTProtocolCodeSequence'],
    '005C': ['ST', '1', 'ReasonForSuperseding'],
    '005D': ['SQ', '1', 'RTDiagnosisCodeSequence'],
    '005E': ['US', '1', 'ReferencedRTPhysicianIntentIndex'],
    '005F': ['SQ', '1', 'RTPhysicianIntentInputInstanceSequence'],
    '0060': ['SQ', '1', 'RTAnatomicPrescriptionSequence'],
    '0061': ['UT', '1', 'PriorTreatmentDoseDescription'],
    '0062': ['SQ', '1', 'PriorTreatmentReferenceSequence'],
    '0063': ['CS', '1', 'DosimetricObjectiveEvaluationScope'],
    '0064': ['SQ', '1', 'TherapeuticRoleCategoryCodeSequence'],
    '0065': ['SQ', '1', 'TherapeuticRoleTypeCodeSequence'],
    '0066': ['US', '1', 'ConceptualVolumeOptimizationPrecedence'],
    '0067': ['SQ', '1', 'ConceptualVolumeCategoryCodeSequence'],
    '0068': ['CS', '1', 'ConceptualVolumeBlockingConstraint'],
    '0069': ['SQ', '1', 'ConceptualVolumeTypeCodeSequence'],
    '006A': ['SQ', '1', 'ConceptualVolumeTypeModifierCodeSequence'],
    '006B': ['SQ', '1', 'RTPrescriptionSequence'],
    '006C': ['SQ', '1', 'DosimetricObjectiveSequence'],
    '006D': ['SQ', '1', 'DosimetricObjectiveTypeCodeSequence'],
    '006E': ['UI', '1', 'DosimetricObjectiveUID'],
    '006F': ['UI', '1', 'ReferencedDosimetricObjectiveUID'],
    '0070': ['SQ', '1', 'DosimetricObjectiveParameterSequence'],
    '0071': ['SQ', '1', 'ReferencedDosimetricObjectivesSequence'],
    '0073': ['CS', '1', 'AbsoluteDosimetricObjectiveFlag'],
    '0074': ['FD', '1', 'DosimetricObjectiveWeight'],
    '0075': ['CS', '1', 'DosimetricObjectivePurpose'],
    '0076': ['SQ', '1', 'PlanningInputInformationSequence'],
    '0077': ['LO', '1', 'TreatmentSite'],
    '0078': ['SQ', '1', 'TreatmentSiteCodeSequence'],
    '0079': ['SQ', '1', 'FractionPatternSequence'],
    '007A': ['UT', '1', 'TreatmentTechniqueNotes'],
    '007B': ['UT', '1', 'PrescriptionNotes'],
    '007C': ['IS', '1', 'NumberOfIntervalFractions'],
    '007D': ['US', '1', 'NumberOfFractions'],
    '007E': ['US', '1', 'IntendedDeliveryDuration'],
    '007F': ['UT', '1', 'FractionationNotes'],
    '0080': ['SQ', '1', 'RTTreatmentTechniqueCodeSequence'],
    '0081': ['SQ', '1', 'PrescriptionNotesSequence'],
    '0082': ['SQ', '1', 'FractionBasedRelationshipSequence'],
    '0083': ['CS', '1', 'FractionBasedRelationshipIntervalAnchor'],
    '0084': ['FD', '1', 'MinimumHoursBetweenFractions'],
    '0085': ['TM', '1-n', 'IntendedFractionStartTime'],
    '0086': ['LT', '1', 'IntendedStartDayOfWeek'],
    '0087': ['SQ', '1', 'WeekdayFractionPatternSequence'],
    '0088': ['SQ', '1', 'DeliveryTimeStructureCodeSequence'],
    '0089': ['SQ', '1', 'TreatmentSiteModifierCodeSequence'],
    '0090': ['CS', '1', 'RoboticBaseLocationIndicator'],
    '0091': ['SQ', '1', 'RoboticPathNodeSetCodeSequence'],
    '0092': ['UL', '1', 'RoboticNodeIdentifier'],
    '0093': ['FD', '3', 'RTTreatmentSourceCoordinates'],
    '0094': ['FD', '1', 'RadiationSourceCoordinateSystemYawAngle'],
    '0095': ['FD', '1', 'RadiationSourceCoordinateSystemRollAngle'],
    '0096': ['FD', '1', 'RadiationSourceCoordinateSystemPitchAngle'],
    '0097': ['SQ', '1', 'RoboticPathControlPointSequence'],
    '0098': ['SQ', '1', 'TomotherapeuticControlPointSequence'],
    '0099': ['FD', '1-n', 'TomotherapeuticLeafOpenDurations'],
    '009A': ['FD', '1-n', 'TomotherapeuticLeafInitialClosedDurations']
  },
  '4000': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['LT', '1', 'Arbitrary'],
    '4000': ['LT', '1', 'TextComments']
  },
  '4008': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0040': ['SH', '1', 'ResultsID'],
    '0042': ['LO', '1', 'ResultsIDIssuer'],
    '0050': ['SQ', '1', 'ReferencedInterpretationSequence'],
    '00FF': ['CS', '1', 'ReportProductionStatusTrial'],
    '0100': ['DA', '1', 'InterpretationRecordedDate'],
    '0101': ['TM', '1', 'InterpretationRecordedTime'],
    '0102': ['PN', '1', 'InterpretationRecorder'],
    '0103': ['LO', '1', 'ReferenceToRecordedSound'],
    '0108': ['DA', '1', 'InterpretationTranscriptionDate'],
    '0109': ['TM', '1', 'InterpretationTranscriptionTime'],
    '010A': ['PN', '1', 'InterpretationTranscriber'],
    '010B': ['ST', '1', 'InterpretationText'],
    '010C': ['PN', '1', 'InterpretationAuthor'],
    '0111': ['SQ', '1', 'InterpretationApproverSequence'],
    '0112': ['DA', '1', 'InterpretationApprovalDate'],
    '0113': ['TM', '1', 'InterpretationApprovalTime'],
    '0114': ['PN', '1', 'PhysicianApprovingInterpretation'],
    '0115': ['LT', '1', 'InterpretationDiagnosisDescription'],
    '0117': ['SQ', '1', 'InterpretationDiagnosisCodeSequence'],
    '0118': ['SQ', '1', 'ResultsDistributionListSequence'],
    '0119': ['PN', '1', 'DistributionName'],
    '011A': ['LO', '1', 'DistributionAddress'],
    '0200': ['SH', '1', 'InterpretationID'],
    '0202': ['LO', '1', 'InterpretationIDIssuer'],
    '0210': ['CS', '1', 'InterpretationTypeID'],
    '0212': ['CS', '1', 'InterpretationStatusID'],
    '0300': ['ST', '1', 'Impressions'],
    '4000': ['ST', '1', 'ResultsComments']
  },
  '4010': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['CS', '1', 'LowEnergyDetectors'],
    '0002': ['CS', '1', 'HighEnergyDetectors'],
    '0004': ['SQ', '1', 'DetectorGeometrySequence'],
    '1001': ['SQ', '1', 'ThreatROIVoxelSequence'],
    '1004': ['FL', '3', 'ThreatROIBase'],
    '1005': ['FL', '3', 'ThreatROIExtents'],
    '1006': ['OB', '1', 'ThreatROIBitmap'],
    '1007': ['SH', '1', 'RouteSegmentID'],
    '1008': ['CS', '1', 'GantryType'],
    '1009': ['CS', '1', 'OOIOwnerType'],
    '100A': ['SQ', '1', 'RouteSegmentSequence'],
    '1010': ['US', '1', 'PotentialThreatObjectID'],
    '1011': ['SQ', '1', 'ThreatSequence'],
    '1012': ['CS', '1', 'ThreatCategory'],
    '1013': ['LT', '1', 'ThreatCategoryDescription'],
    '1014': ['CS', '1', 'ATDAbilityAssessment'],
    '1015': ['CS', '1', 'ATDAssessmentFlag'],
    '1016': ['FL', '1', 'ATDAssessmentProbability'],
    '1017': ['FL', '1', 'Mass'],
    '1018': ['FL', '1', 'Density'],
    '1019': ['FL', '1', 'ZEffective'],
    '101A': ['SH', '1', 'BoardingPassID'],
    '101B': ['FL', '3', 'CenterOfMass'],
    '101C': ['FL', '3', 'CenterOfPTO'],
    '101D': ['FL', '6-n', 'BoundingPolygon'],
    '101E': ['SH', '1', 'RouteSegmentStartLocationID'],
    '101F': ['SH', '1', 'RouteSegmentEndLocationID'],
    '1020': ['CS', '1', 'RouteSegmentLocationIDType'],
    '1021': ['CS', '1-n', 'AbortReason'],
    '1023': ['FL', '1', 'VolumeOfPTO'],
    '1024': ['CS', '1', 'AbortFlag'],
    '1025': ['DT', '1', 'RouteSegmentStartTime'],
    '1026': ['DT', '1', 'RouteSegmentEndTime'],
    '1027': ['CS', '1', 'TDRType'],
    '1028': ['CS', '1', 'InternationalRouteSegment'],
    '1029': ['LO', '1-n', 'ThreatDetectionAlgorithmAndVersion'],
    '102A': ['SH', '1', 'AssignedLocation'],
    '102B': ['DT', '1', 'AlarmDecisionTime'],
    '1031': ['CS', '1', 'AlarmDecision'],
    '1033': ['US', '1', 'NumberOfTotalObjects'],
    '1034': ['US', '1', 'NumberOfAlarmObjects'],
    '1037': ['SQ', '1', 'PTORepresentationSequence'],
    '1038': ['SQ', '1', 'ATDAssessmentSequence'],
    '1039': ['CS', '1', 'TIPType'],
    '103A': ['CS', '1', 'DICOSVersion'],
    '1041': ['DT', '1', 'OOIOwnerCreationTime'],
    '1042': ['CS', '1', 'OOIType'],
    '1043': ['FL', '3', 'OOISize'],
    '1044': ['CS', '1', 'AcquisitionStatus'],
    '1045': ['SQ', '1', 'BasisMaterialsCodeSequence'],
    '1046': ['CS', '1', 'PhantomType'],
    '1047': ['SQ', '1', 'OOIOwnerSequence'],
    '1048': ['CS', '1', 'ScanType'],
    '1051': ['LO', '1', 'ItineraryID'],
    '1052': ['SH', '1', 'ItineraryIDType'],
    '1053': ['LO', '1', 'ItineraryIDAssigningAuthority'],
    '1054': ['SH', '1', 'RouteID'],
    '1055': ['SH', '1', 'RouteIDAssigningAuthority'],
    '1056': ['CS', '1', 'InboundArrivalType'],
    '1058': ['SH', '1', 'CarrierID'],
    '1059': ['CS', '1', 'CarrierIDAssigningAuthority'],
    '1060': ['FL', '3', 'SourceOrientation'],
    '1061': ['FL', '3', 'SourcePosition'],
    '1062': ['FL', '1', 'BeltHeight'],
    '1064': ['SQ', '1', 'AlgorithmRoutingCodeSequence'],
    '1067': ['CS', '1', 'TransportClassification'],
    '1068': ['LT', '1', 'OOITypeDescriptor'],
    '1069': ['FL', '1', 'TotalProcessingTime'],
    '106C': ['OB', '1', 'DetectorCalibrationData'],
    '106D': ['CS', '1', 'AdditionalScreeningPerformed'],
    '106E': ['CS', '1', 'AdditionalInspectionSelectionCriteria'],
    '106F': ['SQ', '1', 'AdditionalInspectionMethodSequence'],
    '1070': ['CS', '1', 'AITDeviceType'],
    '1071': ['SQ', '1', 'QRMeasurementsSequence'],
    '1072': ['SQ', '1', 'TargetMaterialSequence'],
    '1073': ['FD', '1', 'SNRThreshold'],
    '1075': ['DS', '1', 'ImageScaleRepresentation'],
    '1076': ['SQ', '1', 'ReferencedPTOSequence'],
    '1077': ['SQ', '1', 'ReferencedTDRInstanceSequence'],
    '1078': ['ST', '1', 'PTOLocationDescription'],
    '1079': ['SQ', '1', 'AnomalyLocatorIndicatorSequence'],
    '107A': ['FL', '3', 'AnomalyLocatorIndicator'],
    '107B': ['SQ', '1', 'PTORegionSequence'],
    '107C': ['CS', '1', 'InspectionSelectionCriteria'],
    '107D': ['SQ', '1', 'SecondaryInspectionMethodSequence'],
    '107E': ['DS', '6', 'PRCSToRCSOrientation']
  },
  '4FFE': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['SQ', '1', 'MACParametersSequence']
  },
  '5000': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0005': ['US', '1', 'CurveDimensions'],
    '0010': ['US', '1', 'NumberOfPoints'],
    '0020': ['CS', '1', 'TypeOfData'],
    '0022': ['LO', '1', 'CurveDescription'],
    '0030': ['SH', '1-n', 'AxisUnits'],
    '0040': ['SH', '1-n', 'AxisLabels'],
    '0103': ['US', '1', 'DataValueRepresentation'],
    '0104': ['US', '1-n', 'MinimumCoordinateValue'],
    '0105': ['US', '1-n', 'MaximumCoordinateValue'],
    '0106': ['SH', '1-n', 'CurveRange'],
    '0110': ['US', '1-n', 'CurveDataDescriptor'],
    '0112': ['US', '1-n', 'CoordinateStartValue'],
    '0114': ['US', '1-n', 'CoordinateStepValue'],
    '1001': ['CS', '1', 'CurveActivationLayer'],
    '2000': ['US', '1', 'AudioType'],
    '2002': ['US', '1', 'AudioSampleFormat'],
    '2004': ['US', '1', 'NumberOfChannels'],
    '2006': ['UL', '1', 'NumberOfSamples'],
    '2008': ['UL', '1', 'SampleRate'],
    '200A': ['UL', '1', 'TotalTime'],
    '200C': ['ox', '1', 'AudioSampleData'],
    '200E': ['LT', '1', 'AudioComments'],
    '2500': ['LO', '1', 'CurveLabel'],
    '2600': ['SQ', '1', 'CurveReferencedOverlaySequence'],
    '2610': ['US', '1', 'CurveReferencedOverlayGroup'],
    '3000': ['ox', '1', 'CurveData']
  },
  '5200': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '9229': ['SQ', '1', 'SharedFunctionalGroupsSequence'],
    '9230': ['SQ', '1', 'PerFrameFunctionalGroupsSequence']
  },
  '5400': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0100': ['SQ', '1', 'WaveformSequence'],
    '0110': ['ox', '1', 'ChannelMinimumValue'],
    '0112': ['ox', '1', 'ChannelMaximumValue'],
    '1004': ['US', '1', 'WaveformBitsAllocated'],
    '1006': ['CS', '1', 'WaveformSampleInterpretation'],
    '100A': ['ox', '1', 'WaveformPaddingValue'],
    '1010': ['ox', '1', 'WaveformData']
  },
  '5600': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['OF', '1', 'FirstOrderPhaseCorrectionAngle'],
    '0020': ['OF', '1', 'SpectroscopyData']
  },
  '6000': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['US', '1', 'OverlayRows'],
    '0011': ['US', '1', 'OverlayColumns'],
    '0012': ['US', '1', 'OverlayPlanes'],
    '0015': ['IS', '1', 'NumberOfFramesInOverlay'],
    '0022': ['LO', '1', 'OverlayDescription'],
    '0040': ['CS', '1', 'OverlayType'],
    '0045': ['LO', '1', 'OverlaySubtype'],
    '0050': ['SS', '2', 'OverlayOrigin'],
    '0051': ['US', '1', 'ImageFrameOrigin'],
    '0052': ['US', '1', 'OverlayPlaneOrigin'],
    '0060': ['CS', '1', 'OverlayCompressionCode'],
    '0061': ['SH', '1', 'OverlayCompressionOriginator'],
    '0062': ['SH', '1', 'OverlayCompressionLabel'],
    '0063': ['CS', '1', 'OverlayCompressionDescription'],
    '0066': ['AT', '1-n', 'OverlayCompressionStepPointers'],
    '0068': ['US', '1', 'OverlayRepeatInterval'],
    '0069': ['US', '1', 'OverlayBitsGrouped'],
    '0100': ['US', '1', 'OverlayBitsAllocated'],
    '0102': ['US', '1', 'OverlayBitPosition'],
    '0110': ['CS', '1', 'OverlayFormat'],
    '0200': ['US', '1', 'OverlayLocation'],
    '0800': ['CS', '1-n', 'OverlayCodeLabel'],
    '0802': ['US', '1', 'OverlayNumberOfTables'],
    '0803': ['AT', '1-n', 'OverlayCodeTableLocation'],
    '0804': ['US', '1', 'OverlayBitsForCodeWord'],
    '1001': ['CS', '1', 'OverlayActivationLayer'],
    '1100': ['US', '1', 'OverlayDescriptorGray'],
    '1101': ['US', '1', 'OverlayDescriptorRed'],
    '1102': ['US', '1', 'OverlayDescriptorGreen'],
    '1103': ['US', '1', 'OverlayDescriptorBlue'],
    '1200': ['US', '1-n', 'OverlaysGray'],
    '1201': ['US', '1-n', 'OverlaysRed'],
    '1202': ['US', '1-n', 'OverlaysGreen'],
    '1203': ['US', '1-n', 'OverlaysBlue'],
    '1301': ['IS', '1', 'ROIArea'],
    '1302': ['DS', '1', 'ROIMean'],
    '1303': ['DS', '1', 'ROIStandardDeviation'],
    '1500': ['LO', '1', 'OverlayLabel'],
    '3000': ['ox', '1', 'OverlayData'],
    '4000': ['LT', '1', 'OverlayComments']
  },
  '7F00': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0010': ['ox', '1', 'VariablePixelData'],
    '0011': ['US', '1', 'VariableNextDataGroup'],
    '0020': ['OW', '1', 'VariableCoefficientsSDVN'],
    '0030': ['OW', '1', 'VariableCoefficientsSDHN'],
    '0040': ['OW', '1', 'VariableCoefficientsSDDN']
  },
  '7FE0': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    '0001': ['OV', '1', 'ExtendedOffsetTable'],
    '0002': ['OV', '1', 'ExtendedOffsetTableLengths'],
    '0008': ['OF', '1', 'FloatPixelData'],
    '0009': ['OD', '1', 'DoubleFloatPixelData'],
    '0010': ['ox', '1', 'PixelData'],
    '0020': ['OW', '1', 'CoefficientsSDVN'],
    '0030': ['OW', '1', 'CoefficientsSDHN'],
    '0040': ['OW', '1', 'CoefficientsSDDN']
  },
  'FFFA': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    'FFFA': ['SQ', '1', 'DigitalSignaturesSequence']
  },
  'FFFC': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    'FFFC': ['OB', '1', 'DataSetTrailingPadding']
  },
  'FFFE': {
    '0000': ['UL', '1', 'GenericGroupLength'],
    'E000': ['NONE', '1', 'Item'],
    'E00D': ['NONE', '1', 'ItemDelimitationItem'],
    'E0DD': ['NONE', '1', 'SequenceDelimitationItem']
  }
}; // Dictionary

/**
 * Add tags to the dictionary.
 *
 * @param {string} group The group key.
 * @param {Object<string, string[]>} tags The tags to add as an
 *   object indexed by element key with values as:
 *   [VR, multiplicity, TagName] (all strings).
 */
export function addTagsToDictionary(group, tags) {
  // TODO: add checks!
  dictionary[group] = tags;
}

/**
 * Tag groups: key to name pairs.
 * Copied from gdcm-2.6.1\Source\DataDictionary\GroupName.dic
 * -> removed duplicates (commented).
 *
 * @type {Object<string, string>}
 */
export const tagGroups = {
  '0000': 'Command',
  '0002': 'Meta Element',
  '0004': 'File Set',
  //'0004': 'Directory',
  '0008': 'Identifying',
  '0009': 'SPI Identifying',
  '0010': 'Patient',
  '0012': 'Clinical Trial',
  '0018': 'Acquisition',
  '0019': 'SPI Acquisition',
  '0020': 'Image',
  '0021': 'SPI Image',
  '0022': 'Ophtalmology',
  '0028': 'Image Presentation',
  '0032': 'Study',
  '0038': 'Visit',
  '003A': 'Waveform',
  '0040': 'Procedure',
  //'0040': ''Modality Worklist',
  '0042': 'Encapsulated Document',
  '0050': 'Device Informations',
  //'0050': 'XRay Angio Device',
  '0054': 'Nuclear Medicine',
  '0060': 'Histogram',
  '0070': 'Presentation State',
  '0072': 'Hanging Protocol',
  '0088': 'Storage',
  //'0088': 'Medicine',
  '0100': 'Authorization',
  '0400': 'Digital Signature',
  '1000': 'Code Table',
  '1010': 'Zonal Map',
  '2000': 'Film Session',
  '2010': 'Film Box',
  '2020': 'Image Box',
  '2030': 'Annotation',
  '2040': 'Overlay Box',
  '2050': 'Presentation LUT',
  '2100': 'Print Job',
  '2110': 'Printer',
  '2120': 'Queue',
  '2130': 'Print Content',
  '2200': 'Media Creation',
  '3002': 'RT Image',
  '3004': 'RT Dose',
  '3006': 'RT StructureSet',
  '3008': 'RT Treatment',
  '300A': 'RT Plan',
  '300C': 'RT Relationship',
  '300E': 'RT Approval',
  '4000': 'Text',
  '4008': 'Results',
  '4FFE': 'MAC Parameters',
  '5000': 'Curve',
  '5002': 'Curve',
  '5004': 'Curve',
  '5006': 'Curve',
  '5008': 'Curve',
  '500A': 'Curve',
  '500C': 'Curve',
  '500E': 'Curve',
  '5400': 'Waveform Data',
  '6000': 'Overlays',
  '6002': 'Overlays',
  '6004': 'Overlays',
  '6008': 'Overlays',
  '600A': 'Overlays',
  '600C': 'Overlays',
  '600E': 'Overlays',
  'FFFC': 'Generic',
  '7FE0': 'Pixel Data',
  'FFFF': 'Unknown'
};

/**
 * List of Value Representation (VR) with 32bit Value Length (VL).
 *
 * Added locally used 'ox'.
 * See {@link http://dicom.nema.org/medical/dicom/2022a/output/chtml/part05/chapter_7.html#table_7.1-1}.
 *
 * @type {Object<string, boolean>}
 */
const vr32bitVL = {
  OB: true,
  OD: true,
  OF: true,
  OL: true,
  OV: true,
  OW: true,
  SQ: true,
  SV: true,
  UC: true,
  UN: true,
  UR: true,
  UT: true,
  UV: true,
  ox: true
};

/**
 * Does the input Value Representation (VR) have a 32bit Value Length (VL).
 *
 * @param {string} vr The data Value Representation (VR).
 * @returns {boolean} True if this VR has a 32-bit VL.
 */
export function is32bitVLVR(vr) {
  return typeof vr32bitVL[vr] !== 'undefined';
}

/**
 * List of string VR with extended or replaced default character repertoire defined in
 *   Specific Character Set (0008,0005).
 *
 * See {@link https://dicom.nema.org/medical/dicom/2022a/output/chtml/part05/chapter_6.html#sect_6.1.2.2}.
 *
 * @type {Object<string, boolean>}
 */
const vrCharSetString = {
  SH: true,
  LO: true,
  UC: true,
  ST: true,
  LT: true,
  UT: true,
  PN: true
};

/**
 * Does the input Value Representation (VR) have an special character repertoire.
 *
 * @param {string} vr The data VR.
 * @returns {boolean} True if this VR has a special char set.
 */
export function isCharSetStringVR(vr) {
  return typeof vrCharSetString[vr] !== 'undefined';
}

/**
 * VR equivalent javascript types.
 *
 * See {@link https://dicom.nema.org/medical/dicom/2022a/output/chtml/part05/sect_6.2.html#table_6.2-1}.
 *
 * @type {Object<string, string>}
 */
export const vrTypes = {
  AE: 'string',
  AS: 'string',
  AT: undefined,
  CS: 'string',
  DA: 'string',
  DS: 'string',
  DT: 'string',
  FL: 'Float32',
  FD: 'Float64',
  IS: 'string',
  LO: 'string',
  LT: 'string',
  OB: 'Uint8',
  OD: 'Uint64',
  OF: 'Uint32',
  OL: 'Uint32',
  OV: 'Uint64',
  OW: 'Uint16',
  PN: 'string',
  SH: 'string',
  SL: 'Int32',
  SQ: undefined,
  SS: 'Int16',
  ST: 'string',
  SV: 'Int64',
  TM: 'string',
  UC: 'string',
  UI: 'string',
  UL: 'Uint32',
  UN: 'Uint8',
  UR: 'string',
  US: 'Uint16',
  UT: 'string',
  UV: 'Uint64'
};

/**
 * Transfer syntaxes.
 *
 * See {@link https://dicom.nema.org/medical/dicom/2022a/output/chtml/part06/chapter_A.html#table_A-1}.
 *
 * @type {Object<string, string>}
 */
export const transferSyntaxes = {
  '1.2.840.10008.1.2': 'Implicit VR Little Endian',
  '1.2.840.10008.1.2.1': 'Explicit VR Little Endian',
  '1.2.840.10008.1.2.1.98': 'Encapsulated Uncompressed Explicit VR Little Endian',
  '1.2.840.10008.1.2.1.99': 'Deflated Explicit VR Little Endian',
  '1.2.840.10008.1.2.2': 'Explicit VR Big Endian (Retired)',
  '1.2.840.10008.1.2.4.50': 'JPEG Baseline (Process 1)',
  '1.2.840.10008.1.2.4.51': 'JPEG Extended (Process 2 & 4)',
  '1.2.840.10008.1.2.4.52': 'JPEG Extended (Process 3 & 5) (Retired)',
  '1.2.840.10008.1.2.4.53': 'JPEG Spectral Selection, Non-Hierarchical (Process 6 & 8) (Retired)',
  '1.2.840.10008.1.2.4.54': 'JPEG Spectral Selection, Non-Hierarchical (Process 7 & 9) (Retired)',
  '1.2.840.10008.1.2.4.55': 'JPEG Full Progression, Non-Hierarchical (Process 10 & 12) (Retired)',
  '1.2.840.10008.1.2.4.56': 'JPEG Full Progression, Non-Hierarchical (Process 11 & 13) (Retired)',
  '1.2.840.10008.1.2.4.57': 'JPEG Lossless, Non-Hierarchical (Process 14)',
  '1.2.840.10008.1.2.4.58': 'JPEG Lossless, Non-Hierarchical (Process 15) (Retired)',
  '1.2.840.10008.1.2.4.59': 'JPEG Extended, Hierarchical (Process 16 & 18) (Retired)',
  '1.2.840.10008.1.2.4.60': 'JPEG Extended, Hierarchical (Process 17 & 19) (Retired)',
  '1.2.840.10008.1.2.4.61': 'JPEG Spectral Selection, Hierarchical (Process 20 & 22) (Retired)',
  '1.2.840.10008.1.2.4.62': 'JPEG Spectral Selection, Hierarchical (Process 21 & 23) (Retired)',
  '1.2.840.10008.1.2.4.63': 'JPEG Full Progression, Hierarchical (Process 24 & 26) (Retired)',
  '1.2.840.10008.1.2.4.64': 'JPEG Full Progression, Hierarchical (Process 25 & 27) (Retired)',
  '1.2.840.10008.1.2.4.65': 'JPEG Lossless, Hierarchical (Process 28) (Retired)',
  '1.2.840.10008.1.2.4.66': 'JPEG Lossless, Hierarchical (Process 29) (Retired)',
  '1.2.840.10008.1.2.4.70': 'JPEG Lossless, Non-Hierarchical, First-Order Prediction (Process 14 [Selection Value 1])',
  '1.2.840.10008.1.2.4.80': 'JPEG-LS Lossless Image Compression',
  '1.2.840.10008.1.2.4.81': 'JPEG-LS Lossy (Near-Lossless) Image Compression',
  '1.2.840.10008.1.2.4.90': 'JPEG 2000 Image Compression (Lossless Only)',
  '1.2.840.10008.1.2.4.91': 'JPEG 2000 Image Compression',
  '1.2.840.10008.1.2.4.92': 'JPEG 2000 Part 2 Multi-component Image Compression (Lossless Only)',
  '1.2.840.10008.1.2.4.93': 'JPEG 2000 Part 2 Multi-component Image Compression',
  '1.2.840.10008.1.2.4.94': 'JPIP Referenced',
  '1.2.840.10008.1.2.4.95': 'JPIP Referenced Deflate',
  '1.2.840.10008.1.2.4.100': 'MPEG2 Main Profile / Main Level',
  '1.2.840.10008.1.2.4.101': 'MPEG2 Main Profile / High Level',
  '1.2.840.10008.1.2.4.102': 'MPEG-4 AVC/H.264 High Profile / Level 4.1',
  '1.2.840.10008.1.2.4.103': 'MPEG-4 AVC/H.264 BD-compatible High Profile / Level 4.1',
  '1.2.840.10008.1.2.4.104': 'MPEG-4 AVC/H.264 High Profile / Level 4.2 For 2D Video',
  '1.2.840.10008.1.2.4.105': 'MPEG-4 AVC/H.264 High Profile / Level 4.2 For 3D Video',
  '1.2.840.10008.1.2.4.106': 'MPEG-4 AVC/H.264 Stereo High Profile / Level 4.2',
  '1.2.840.10008.1.2.4.107': 'HEVC/H.265 Main Profile / Level 5.1',
  '1.2.840.10008.1.2.4.108': 'HEVC/H.265 Main 10 Profile / Level 5.1',
  '1.2.840.10008.1.2.5': 'RLE Lossless',
  '1.2.840.10008.1.2.6.1': 'RFC 2557 MIME encapsulation (Retired)',
  '1.2.840.10008.1.2.6.2': 'XML Encoding (Retired)',
  '1.2.840.10008.1.2.7.1': 'SMPTE ST 2110-20 Uncompressed Progressive Active Video',
  '1.2.840.10008.1.2.7.2': 'SMPTE ST 2110-20 Uncompressed Interlaced Active Video',
  '1.2.840.10008.1.2.7.3': 'SMPTE ST 2110-30 PCM Digital Audio',
  '1.2.840.10008.1.20': 'Papyrus 3 Implicit VR Little Endian (Retired)'
};

/**
 * Transfer syntaxes indexed by keyword.
 *
 * @type {Object<string, string>}
 */
export const transferSyntaxKeywords = {
  ImplicitVRLittleEndian: '1.2.840.10008.1.2',
  ExplicitVRLittleEndian: '1.2.840.10008.1.2.1',
  EncapsulatedUncompressedExplicitVRLittleEndian: '1.2.840.10008.1.2.1.98',
  DeflatedExplicitVRLittleEndian: '1.2.840.10008.1.2.1.99',
  ExplicitVRBigEndian: '1.2.840.10008.1.2.2',
  JPEGBaseline8Bit: '1.2.840.10008.1.2.4.50',
  JPEGExtended12Bit: '1.2.840.10008.1.2.4.51',
  JPEGExtended35: '1.2.840.10008.1.2.4.52',
  JPEGSpectralSelectionNonHierarchical68: '1.2.840.10008.1.2.4.53',
  JPEGSpectralSelectionNonHierarchical79: '1.2.840.10008.1.2.4.54',
  JPEGFullProgressionNonHierarchical1012: '1.2.840.10008.1.2.4.55',
  JPEGFullProgressionNonHierarchical1113: '1.2.840.10008.1.2.4.56',
  JPEGLossless: '1.2.840.10008.1.2.4.57',
  JPEGLosslessNonHierarchical15: '1.2.840.10008.1.2.4.58',
  JPEGExtendedHierarchical1618: '1.2.840.10008.1.2.4.59',
  JPEGExtendedHierarchical1719: '1.2.840.10008.1.2.4.60',
  JPEGSpectralSelectionHierarchical2022: '1.2.840.10008.1.2.4.61',
  JPEGSpectralSelectionHierarchical2123: '1.2.840.10008.1.2.4.62',
  JPEGFullProgressionHierarchical2426: '1.2.840.10008.1.2.4.63',
  JPEGFullProgressionHierarchical2527: '1.2.840.10008.1.2.4.64',
  JPEGLosslessHierarchical28: '1.2.840.10008.1.2.4.65',
  JPEGLosslessHierarchical29: '1.2.840.10008.1.2.4.66',
  JPEGLosslessSV1: '1.2.840.10008.1.2.4.70',
  JPEGLSLossless: '1.2.840.10008.1.2.4.80',
  JPEGLSNearLossless: '1.2.840.10008.1.2.4.81',
  JPEG2000Lossless: '1.2.840.10008.1.2.4.90',
  JPEG2000: '1.2.840.10008.1.2.4.91',
  JPEG2000MCLossless: '1.2.840.10008.1.2.4.92',
  JPEG2000MC: '1.2.840.10008.1.2.4.93',
  JPIPReferenced: '1.2.840.10008.1.2.4.94',
  JPIPReferencedDeflate: '1.2.840.10008.1.2.4.95',
  MPEG2MPML: '1.2.840.10008.1.2.4.100',
  MPEG2MPHL: '1.2.840.10008.1.2.4.101',
  MPEG4HP41: '1.2.840.10008.1.2.4.102',
  MPEG4HP41BD: '1.2.840.10008.1.2.4.103',
  MPEG4HP422D: '1.2.840.10008.1.2.4.104',
  MPEG4HP423D: '1.2.840.10008.1.2.4.105',
  MPEG4HP42STEREO: '1.2.840.10008.1.2.4.106',
  HEVCMP51: '1.2.840.10008.1.2.4.107',
  HEVCM10P51: '1.2.840.10008.1.2.4.108',
  RLELossless: '1.2.840.10008.1.2.5',
  RFC2557MIMEEncapsulation: '1.2.840.10008.1.2.6.1',
  XMLEncoding: '1.2.840.10008.1.2.6.2',
  SMPTEST211020UncompressedProgressiveActiveVideo: '1.2.840.10008.1.2.7.1',
  SMPTEST211020UncompressedInterlacedActiveVideo: '1.2.840.10008.1.2.7.2',
  SMPTEST211030PCMDigitalAudio: '1.2.840.10008.1.2.7.3',
  Papyrus3ImplicitVRLittleEndian: '1.2.840.10008.1.20'
};
