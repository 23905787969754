export const tableStyles = {
  tableCell: {
    fontWeight: "var(--neuro-font-weight-bold)",
    color: "var(--neuro-table-head-primary)",
    fontFamily: "var(--neuro-font-family-roboto-condensed)",
    fontSize: "14px",
  },
  subTableCell: {
    fontWeight: "var(--neuro-font-weight-regular)",
    color: "var(--neuro-table-head-primary)",
    fontFamily: "var(--neuro-font-family-roboto-condensed)",
    fontSize: "14px",
  },
};
