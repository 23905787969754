const CapsuleIcon = (props: any) => (
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="#54b895"
    strokeWidth={1}
    strokeLinecap="round"
    strokeLinejoin="miter"
    {...props}
  >
    <path d="M8.61,7.61l-5.1,5.1a5.52,5.52,0,0,0,0,7.78h0a5.52,5.52,0,0,0,7.78,0l5.1-5.1Z" />
    <path d="M3.51,20.49h0a5.5,5.5,0,0,1,0-7.78l4.6-4.6,4.6-4.6a5.5,5.5,0,0,1,7.78,0h0a5.5,5.5,0,0,1,0,7.78l-4.6,4.6-4.6,4.6a5.5,5.5,0,0,1-7.78,0Z" />
  </svg>
);
export default CapsuleIcon;
