export const scoreBasedMidasFormSchema = {
  "isScoreBased": true,
  "autoSubmit": true,
  "title": "MIDAS Questionnaire",
  "type": "object",
  "properties": {
    "step1": {
      "type": "object",
      "title": "On how many days in the last 3 months did you miss work or school because of your headaches?",
      "properties": {
        "answer": {
          "type": "string",
          "title": "Answer",
          "enum": [
            "0-5 days",
            "6-10 days",
            "11-20 days",
            "21+ days"
          ]
        }
      },
      "required": ["answer"]
    },
    "step2": {
      "type": "object",
      "title": "How many days in the last 3 months was your productivity at work or school reduced by half or more because of your headaches?",
      "properties": {
        "answer": {
          "type": "string",
          "title": "Answer",
          "enum": [
            "0-5 days",
            "6-10 days",
            "11-20 days",
            "21+ days"
          ]
        }
      },
      "required": ["answer"]
    },
    "step3": {
      "type": "object",
      "title": "On how many days in the last 3 months did you not do household work because of your headaches?",
      "properties": {
        "answer": {
          "type": "string",
          "title": "Answer",
          "enum": [
            "0-5 days",
            "6-10 days",
            "11-20 days",
            "21+ days"
          ]
        }
      },
      "required": ["answer"]
    },
    "step4": {
      "type": "object",
      "title": "How many days in the last 3 months was your productivity in household work reduced by half or more because of your headaches?",
      "properties": {
        "answer": {
          "type": "string",
          "title": "Answer",
          "enum": [
            "0-5 days",
            "6-10 days",
            "11-20 days",
            "21+ days"
          ]
        }
      },
      "required": ["answer"]
    },
    "step5": {
      "type": "object",
      "title": "On how many days in the last 3 months did you miss family, social or leisure activities because of your headaches?",
      "properties": {
        "answer": {
          "type": "string",
          "title": "Answer",
          "enum": [
            "0-5 days",
            "6-10 days",
            "11-20 days",
            "21+ days"
          ]
        }
      },
      "required": ["answer"]
    }
  },
  "required": ["step1", "step2", "step3", "step4", "step5"],
  "scores": {
    "step1": {
      "answer": {
        "0-5 days": 0,
        "6-10 days": 1,
        "11-20 days": 2,
        "21+ days": 3
      }
    },
    "step2": {
      "answer": {
        "0-5 days": 0,
        "6-10 days": 1,
        "11-20 days": 2,
        "21+ days": 3
      }
    },
    "step3": {
      "answer": {
        "0-5 days": 0,
        "6-10 days": 1,
        "11-20 days": 2,
        "21+ days": 3
      }
    },
    "step4": {
      "answer": {
        "0-5 days": 0,
        "6-10 days": 1,
        "11-20 days": 2,
        "21+ days": 3
      }
    },
    "step5": {
      "answer": {
        "0-5 days": 0,
        "6-10 days": 1,
        "11-20 days": 2,
        "21+ days": 3
      }
    }
  },
  "uiSchema": {
    "step1": {
      "answer": { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" }
    },
    "step2": {
      "answer": { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" }
    },
    "step3": {
      "answer": { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" }
    },
    "step4": {
      "answer": { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" }
    },
    "step5": {
      "answer": { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" }
    }
  },
  "formData": {
    "step1": {
      "answer": ""
    },
    "step2": {
      "answer": ""
    },
    "step3": {
      "answer": ""
    },
    "step4": {
      "answer": ""
    },
    "step5": {
      "answer": ""
    }
  }
};

export const scoreBasedFormSchema = {
  isScoreBased: true,
  autoSubmit: true,
  title: "HIT-6 Questionnaire",
  type: "object",
  properties: {
    step1: {
      type: "object",
      title: "When you have headaches, how often is the pain severe?",
      properties: {
        answer: {
          type: "string",
          title: "Answer",
          enum: ["Never", "Rarely", "Sometimes", "Very Often", "Always"],
        },
      },
      required: ["answer"],
    },
    step2: {
      type: "object",
      title:
        "How often do headaches limit your ability to do usual daily activities including household work, work, school, or social activities?",
      properties: {
        answer: {
          type: "string",
          title: "Answer",
          enum: ["Never", "Rarely", "Sometimes", "Very Often", "Always"],
        },
      },
      required: ["answer"],
    },
    step3: {
      type: "object",
      title:
        "When you have a headache, how often do you wish you could lie down?",
      properties: {
        answer: {
          type: "string",
          title: "Answer",
          enum: ["Never", "Rarely", "Sometimes", "Very Often", "Always"],
        },
      },
      required: ["answer"],
    },
    step4: {
      type: "object",
      title:
        "In the past 4 weeks, how often have you felt too tired to do work or daily activities because of your headaches?",
      properties: {
        answer: {
          type: "string",
          title: "Answer",
          enum: ["Never", "Rarely", "Sometimes", "Very Often", "Always"],
        },
      },
      required: ["answer"],
    },
    step5: {
      type: "object",
      title:
        "In the past 4 weeks, how often have you felt fed up or irritated because of your headaches?",
      properties: {
        answer: {
          type: "string",
          title: "Answer",
          enum: ["Never", "Rarely", "Sometimes", "Very Often", "Always"],
        },
      },
      required: ["answer"],
    },
    step6: {
      type: "object",
      title:
        "In the past 4 weeks, how often did headaches limit your ability to concentrate on work or daily activities?",
      properties: {
        answer: {
          type: "string",
          title: "Answer",
          enum: ["Never", "Rarely", "Sometimes", "Very Often", "Always"],
        },
      },
      required: ["answer"],
    },
  },
  required: ["step1", "step2", "step3", "step4", "step5", "step6"],
  scores: {
    step1: {
      answer: {
        Never: 6,
        Rarely: 8,
        Sometimes: 10,
        "Very Often": 11,
        Always: 13,
      },
    },
    step2: {
      answer: {
        Never: 6,
        Rarely: 8,
        Sometimes: 10,
        "Very Often": 11,
        Always: 13,
      },
    },
    step3: {
      answer: {
        Never: 6,
        Rarely: 8,
        Sometimes: 10,
        "Very Often": 11,
        Always: 13,
      },
    },
    step4: {
      answer: {
        Never: 6,
        Rarely: 8,
        Sometimes: 10,
        "Very Often": 11,
        Always: 13,
      },
    },
    step5: {
      answer: {
        Never: 6,
        Rarely: 8,
        Sometimes: 10,
        "Very Often": 11,
        Always: 13,
      },
    },
    step6: {
      answer: {
        Never: 6,
        Rarely: 8,
        Sometimes: 10,
        "Very Often": 11,
        Always: 13,
      },
    },
  },
  uiSchema: {
    step1: {
      answer: { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" },
    },
    step2: {
      answer: { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" },
    },
    step3: {
      answer: { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" },
    },
    step4: {
      answer: { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" },
    },
    step5: {
      answer: { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" },
    },
    step6: {
      answer: { "ui:widget": "radio", "ui:classNames": "rjsf-custom-radio" },
    },
  },
  formData: {
    step1: {
      answer: "",
    },
    step2: {
      answer: "",
    },
    step3: {
      answer: "",
    },
    step4: {
      answer: "",
    },
    step5: {
      answer: "",
    },
    step6: {
      answer: "",
    },
  },
};

export const dataDrivenFormSchema = {
  isScoreBased: false,
  autoSubmit: true,
  title: "HIT-6 Questionnaire",
  type: "object",
  properties: {
    step1: {
      type: "object",
      properties: {
        name: { type: "string", title: "Name" },
        age: { type: "number", title: "Age" },
        gender: {
          type: "string",
          title: "Gender",
          enum: ["Male", "Female", "Other"],
        },
        dob: { type: "string", title: "Date of Birth", format: "date" },
        address: { type: "string", title: "Address" },
        contactNumber: {
          type: "string",
          title: "Contact Number",
          minLength: 10,
        },
        email: { type: "string", title: "Email", format: "email" },
      },
      required: ["name", "age", "gender", "dob", "contactNumber", "email"],
    },
    step2: {
      type: "object",
      properties: {
        hypertension: {
          type: "string",
          title: "do you have Hypertension?",
          enum: ["Yes", "No"],
        },
      },
      required: ["hypertension"],
    },
    step3: {
      type: "object",
      properties: {
        diabetes: {
          type: "string",
          title: "do you have Diabetes?",
          enum: ["Yes", "No"],
        },
      },
      required: ["diabetes"],
    },
    step4: {
      type: "object",
      properties: {
        hyperlipidemia: {
          type: "string",
          title: "do you have Hyperlipidemia?",
          enum: ["Yes", "No"],
        },
      },
      required: ["hyperlipidemia"],
    },
    step5: {
      type: "object",
      properties: {
        thyroidDisorders: {
          type: "string",
          title: "do you have Thyroid Disorders?",
          enum: ["Yes", "No"],
        },
      },
      required: ["thyroidDisorders"],
    },
    step6: {
      type: "object",
      properties: {
        cardiovascularDiseases: {
          type: "string",
          title: "do you have Cardiovascular Diseases?",
          enum: ["Yes", "No"],
        },
      },
      required: ["cardiovascularDiseases"],
    },
    step7: {
      type: "object",
      properties: {
        respiratoryConditions: {
          type: "string",
          title: "do you have Respiratory Conditions?",
          enum: ["Yes", "No"],
        },
      },
      required: ["respiratoryConditions"],
    },
    step8: {
      type: "object",
      properties: {
        gastrointestinalDisorders: {
          type: "string",
          title: "do you have Gastrointestinal Disorders?",
          enum: ["Yes", "No"],
        },
      },
      required: ["gastrointestinalDisorders"],
    },
    step9: {
      type: "object",
      properties: {
        renalDisorders: {
          type: "string",
          title: "do you have Renal Disorders?",
          enum: ["Yes", "No"],
        },
      },
      required: ["renalDisorders"],
    },
    step10: {
      type: "object",
      properties: {
        liverDisorders: {
          type: "string",
          title: "do you have Liver Disorders?",
          enum: ["Yes", "No"],
        },
      },
      required: ["liverDisorders"],
    },
    step11: {
      type: "object",
      properties: {
        immunologicalDisorders: {
          type: "string",
          title: "do you have Immunological Disorders?",
          enum: ["Yes", "No"],
        },
      },
      required: ["immunologicalDisorders"],
    },
    step12: {
      type: "object",
      properties: {
        hematologicalDisorders: {
          type: "string",
          title: "do you have Hematological Disorders?",
          enum: ["Yes", "No"],
        },
      },
      required: ["hematologicalDisorders"],
    },
    step13: {
      type: "object",
      properties: {
        otherMedicalCondition: {
          type: "string",
          title: "Other Medical Condition (Specify)",
        },
      },
    },
  },
  required: [
    "step1",
    "step2",
    "step3",
    "step4",
    "step5",
    "step6",
    "step7",
    "step8",
    "step9",
    "step10",
    "step11",
    "step12",
  ],
  uiSchema: {
    step1: {
      name: {
        "ui:widget": "text",
        "ui:classNames": "rjsf-custom-input",
      },
      age: {
        "ui:widget": "updown",
        "ui:classNames": "rjsf-custom-input",
      },
      gender: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
      dob: {
        "ui:widget": "date",
        "ui:classNames": "rjsf-custom-input",
      },
      address: {
        "ui:widget": "text",
        "ui:classNames": "rjsf-custom-input",
      },
      contactNumber: {
        "ui:widget": "text",
        "ui:classNames": "rjsf-custom-input",
      },
      email: {
        "ui:widget": "email",
        "ui:classNames": "rjsf-custom-input",
      },
    },
    step2: {
      hypertension: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step3: {
      diabetes: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step4: {
      hyperlipidemia: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step5: {
      thyroidDisorders: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step6: {
      cardiovascularDiseases: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step7: {
      respiratoryConditions: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step8: {
      gastrointestinalDisorders: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step9: {
      renalDisorders: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step10: {
      liverDisorders: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step11: {
      immunologicalDisorders: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step12: {
      hematologicalDisorders: {
        "ui:widget": "radio",
        "ui:classNames": "rjsf-custom-radio",
      },
    },
    step13: {
      otherMedicalCondition: {
        "ui:widget": "text",
        "ui:classNames": "rjsf-custom-input",
      },
    },
  },
  formData: {
    step1: {
      name: "",
      age: null,
      gender: "",
      dob: "",
      address: "",
      contactNumber: "",
      email: "",
    },
    step2: {
      hypertension: "",
    },
    step3: {
      diabetes: "",
    },
    step4: {
      hyperlipidemia: "",
    },
    step5: {
      thyroidDisorders: "",
    },
    step6: {
      cardiovascularDiseases: "",
    },
    step7: {
      respiratoryConditions: "",
    },
    step8: {
      gastrointestinalDisorders: "",
    },
    step9: {
      renalDisorders: "",
    },
    step10: {
      liverDisorders: "",
    },
    step11: {
      immunologicalDisorders: "",
    },
    step12: {
      hematologicalDisorders: "",
    },
    step13: {
      otherMedicalCondition: "",
    },
  },
};
