import api from "./apiService";

export const getPayments = (patientId: string) => {
    const params = {
        patientId,
    }
    return api.get(`/api/payments/getall`, { params });
};

export const downloadPayment = (id: string) => {
    return api({
        method: "GET",
        url: `/api/downloadPdf/invoice/${id}`,
        params: { type: "PAYMENTS" },
        responseType: "blob",
    });
};