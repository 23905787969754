import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  IconButton,
} from "@mui/material";
import { Formik, Form, FieldArray } from "formik";
import { addDoctor, updateDoctor } from "../../services/doctorService";
import { fetchHospitals } from "../../services/hospitalService";
import { Doctor, Hospital } from "../../types/common";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useAppStore from "../../store/appStore";
// import { doctorValidationSchema } from "../../validations/doctorValidation";
import {
  AddEditDoctorModalProps,
  AddEditDoctorModalTabPanelProps,
} from "../../types/doctors";
import { countryCode } from "../../utils/common";
import { DOCTORS } from "../../utils/constants";

function CustomTabPanel(props: AddEditDoctorModalTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: "1.5rem" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddEditDoctorModal: React.FC<AddEditDoctorModalProps> = ({
  open,
  onClose,
  doctor,
  loadDoctors,
}) => {
  // props & state values
  const { showSnackbar, doctorsFilter } = useAppStore();

  const initialFormData: Doctor = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    specializations: [],
    about: "",
    acceptsInPerson: false,
    onlineConsultationFee: undefined, // Optional field
    inPersonConsultationFee: undefined, // Optional field
    yearsOfExperience: null,
    spokenLanguages: [],
    experienceDetails: "", // Optional field
    publishedWorks: "", // Optional field
    qualifications: "", // Optional field
    status: true,
    hospitalId: "",
    weeklyAvailabilitySettings: [], // Empty array as the default
    hospital: undefined, // Optional field, undefined by default
    isPayrollEmployee: false,
    isNetwork: false,
    isClinicAdmin: false,
    onlineDoctorFee: undefined,
    inPersonDoctorFee: undefined,
  };

  // const whatsappUpdates = watch("whatsappUpdates");

  const specializations = [
    {
      id: "1",
      name: "NEUROLOGIST",
    },
    {
      id: "2",
      name: "PRIMARY_CARE",
    },
    {
      id: "3",
      name: "PEDIATRICS",
    },
  ];

  const [formData, setFormData] = useState<Doctor>(initialFormData);
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [tabValue, setTabValue] = React.useState(0);

  // callbacks & functions
  const handleTabChange = (_: any, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const loadHospitals = async () => {
      try {
        const response = await fetchHospitals();
        setHospitals(response.data);
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };
    loadHospitals();
  }, []);

  useEffect(() => {
    if (doctor) {
      console.log("doctor in modal", doctor);
      // Assign hospitalId to doctor
      doctor.hospitalId = doctor.hospital ? doctor.hospital.id : "";

      // change mobile format
      doctor.mobile = doctor.mobile ? doctor.mobile.replace(/^\+91/, "") : "";

      // Delete hospital details
      delete doctor.hospital;

      // Delete taxes details
      delete (doctor as any).taxes;

      // Transform 'weeklyAvailabilitySettings' to keep only specific keys
      doctor.weeklyAvailabilitySettings = doctor.weeklyAvailabilitySettings.map(
        (setting) => ({
          dayOfWeek: setting.dayOfWeek,
          startTime: setting.startTime,
          endTime: setting.endTime,
          slotDuration: setting.slotDuration,
          appointmentMode: setting.appointmentMode,
        })
      );

      // const data = {
      // 	isPayrollEmployee: true,
      // 	onlineDoctorFee: undefined,
      // 	inPersonDoctorFee: undefined,
      // };

      setFormData({ ...doctor });
    } else {
      setFormData(initialFormData);
    }
  }, [doctor]);

  const handleSubmit = async (values: Doctor) => {
    console.log("values submit doctor", values);
    try {
      if (doctor) {
        console.log("doctor submit doctor", doctor);

        if (doctor?.email === values.email) {
          delete (values as any).email;
        }
        // if (doctor?.mobile === values.mobile) {
        //   delete (values as any).mobile;
        // }
        try {
          const data = {
            ...values,
            mobile: values?.mobile?.includes("+")
              ? values?.mobile
              : `+91${values?.mobile}`,
          };
          await updateDoctor(doctor?.id, data);
          loadDoctors(
            DOCTORS,
            doctorsFilter.customStartDate,
            doctorsFilter.customEndDate,
            doctorsFilter.searchValue || ""
          );
          showSnackbar("Doctor Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        try {
          const data = {
            ...values,
            mobile: `+91${values?.mobile}`,
          };
          console.log("doctor data", data);
          await addDoctor(data);
          loadDoctors(
            DOCTORS,
            doctorsFilter.customStartDate,
            doctorsFilter.customEndDate,
            doctorsFilter.searchValue || ""
          );
          showSnackbar("New Doctor Created Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
      onClose();
    } catch (error) {
      console.error("Error saving doctor:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{doctor ? "Edit Doctor" : "Add New Doctor"}</DialogTitle>
      <Formik
        initialValues={formData}
        // validationSchema={doctorValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // This will enable the form to reinitialize when formData changes
      >
        {({ errors, touched, handleChange, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <TextField
                label="Full Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: values.firstName !== undefined, // Ensures the label stays shrunk when there's a value
                }}
                margin="dense"
                required
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
              {/* <TextField
                label="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: values.lastName !== undefined, // Ensures the label stays shrunk when there's a value
                }}
                fullWidth
                autoFocus
                margin="dense"
                required
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
              /> */}

              <FormControlLabel
                disabled={values.isClinicAdmin}
                control={
                  <Checkbox
                    checked={values.isClinicAdmin ? false : values.isNetwork}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    color="primary"
                  />
                }
                name="isNetwork"
                label="Network Doctor"
              />

              <TextField
                label="Email"
                name="email"
                type="email"
                InputLabelProps={{
                  shrink: values.email !== "", // Ensures the label stays shrunk when there's a value
                }}
                value={values.email}
                onChange={handleChange}
                fullWidth
                autoFocus
                margin="dense"
                required
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />

              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{ maxWidth: "6.25rem" }}
                  name="countryCode"
                  value={"+91"}
                  onChange={handleChange}
                  fullWidth
                >
                  {countryCode.map((code) => (
                    <MenuItem key={code.id} value={code.value}>
                      {code.value}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  label="Mobile Number"
                  name="mobile"
                  //   value={values.mobile}
                  value={
                    values.mobile ? values.mobile.replace(/^\+91/, "") : ""
                  }
                  InputLabelProps={{
                    shrink: values.mobile !== "", // Ensures the label stays shrunk when there's a value
                  }}
                  onChange={handleChange}
                  fullWidth
                  required
                  autoFocus
                  margin="dense"
                  error={touched.mobile && !!errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                />
              </Box>

              <FormControl
                fullWidth
                margin="dense"
                required
                error={touched.hospitalId && !!errors.hospitalId}
              >
                <InputLabel>Clinic</InputLabel>
                <Select
                  label="hospital"
                  name="hospitalId"
                  value={values.hospitalId}
                  onChange={handleChange}
                  fullWidth
                >
                  {hospitals.map((hospital) => (
                    <MenuItem key={hospital.id} value={hospital.id}>
                      {hospital.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.hospitalId && errors.hospitalId && (
                  <div style={{ color: "red" }}>{errors.hospitalId}</div>
                )}
              </FormControl>

              <FormControlLabel
                disabled={values.isNetwork}
                control={
                  <Checkbox
                    checked={values.isNetwork ? false : values.isClinicAdmin}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    color="primary"
                  />
                }
                name="isClinicAdmin"
                label="Clinic Admin"
              />

              <FormControl
                fullWidth
                margin="dense"
                required
                error={touched.hospitalId && !!errors.hospitalId}
              >
                <InputLabel>Specalizations</InputLabel>
                <Select
                  multiple
                  label="Specializations"
                  name="specializations"
                  value={values.specializations}
                  onChange={handleChange}
                  fullWidth
                >
                  {specializations.map((specialization) => (
                    <MenuItem
                      key={specialization.id}
                      value={specialization.name}
                    >
                      {specialization.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.hospitalId && errors.hospitalId && (
                  <div style={{ color: "red" }}>{errors.hospitalId}</div>
                )}
              </FormControl>

              <TextField
                label="Qualifications"
                name="qualifications"
                type="input"
                value={values.qualifications}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: values.qualifications !== "", // Ensures the label stays shrunk when there's a value
                }}
                fullWidth
                autoFocus
                margin="dense"
                required
                error={touched.qualifications && !!errors.qualifications}
                helperText={touched.qualifications && errors.qualifications}
              />

              <TextField
                label="Years of Experience"
                name="yearsOfExperience"
                type="number"
                value={values.yearsOfExperience}
                InputLabelProps={{
                  shrink: values.yearsOfExperience !== 0, // Ensures the label stays shrunk when there's a value
                }}
                onChange={handleChange}
                fullWidth
                autoFocus
                margin="dense"
                required
                error={touched.yearsOfExperience && !!errors.yearsOfExperience}
                helperText={
                  touched.yearsOfExperience && errors.yearsOfExperience
                }
              />
              <TextField
                label="Spoken Languages (comma-separated)"
                name="spokenLanguages"
                value={values.spokenLanguages.join(", ")}
                onChange={(e) =>
                  setFieldValue(
                    "spokenLanguages",
                    e.target.value.split(",").map((s) => s.trim())
                  )
                }
                InputLabelProps={{
                  shrink: values.spokenLanguages.length > 0, // Ensures the label stays shrunk when there's a value
                }}
                fullWidth
                margin="dense"
                required
                autoFocus
                error={touched.spokenLanguages && !!errors.spokenLanguages}
                helperText={touched.spokenLanguages && errors.spokenLanguages}
              />

              <TextField
                label="About"
                name="about"
                value={values.about}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: values.about !== undefined, // Ensures the label stays shrunk when there's a value
                }}
                margin="dense"
                error={touched.about && !!errors.about}
                helperText={touched.about && errors.about}
              />

              {!values?.isNetwork && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isPayrollEmployee}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      color="primary"
                    />
                  }
                  name="isPayrollEmployee"
                  label="Payroll Employee"
                />
              )}

              {!values?.isNetwork && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2, // Adds some space between the two fields
                  }}
                >
                  <TextField
                    label="online consultation fee"
                    name="onlineConsultationFee"
                    type="number"
                    value={values.onlineConsultationFee}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: values.onlineConsultationFee !== undefined, // Ensures the label stays shrunk when there's a value
                    }}
                    fullWidth
                    margin="dense"
                    required
                    autoFocus
                    error={
                      touched.onlineConsultationFee &&
                      !!errors.onlineConsultationFee
                    }
                    helperText={
                      touched.onlineConsultationFee &&
                      errors.onlineConsultationFee
                    }
                  />

                  {!values.isPayrollEmployee && (
                    <TextField
                      label="Online doctor fee"
                      name="onlineDoctorFee"
                      type="number"
                      value={values.onlineDoctorFee}
                      InputLabelProps={{
                        shrink: values.onlineDoctorFee !== undefined, // Ensures the label stays shrunk when there's a value
                      }}
                      onChange={handleChange}
                      fullWidth
                      margin="dense"
                      required
                      autoFocus
                      error={
                        touched.onlineDoctorFee && !!errors.onlineDoctorFee
                      }
                      helperText={
                        touched.onlineDoctorFee && errors.onlineDoctorFee
                      }
                    />
                  )}
                </Box>
              )}

              {!values?.isNetwork && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.acceptsInPerson}
                      onChange={(event) => {
                        handleChange(event);
                        if (!event.target.checked) {
                          setFieldValue(
                            "weeklyAvailabilitySettings",
                            values.weeklyAvailabilitySettings.filter(
                              (availability) =>
                                availability.appointmentMode !== "IN_PERSON"
                            )
                          );
                          setFieldValue("inPersonConsultationFee", 0);
                        }
                      }}
                      color="primary"
                    />
                  }
                  name="acceptsInPerson"
                  label="Accept in Person"
                />
              )}

              {values.acceptsInPerson && !values?.isNetwork && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2, // Adds some space between the two fields
                  }}
                >
                  <TextField
                    label="In person consultation fee"
                    name="inPersonConsultationFee"
                    type="number"
                    value={values.inPersonConsultationFee}
                    InputLabelProps={{
                      shrink: values.inPersonConsultationFee !== undefined, // Ensures the label stays shrunk when there's a value
                    }}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    required
                    autoFocus
                    error={
                      touched.inPersonConsultationFee &&
                      !!errors.inPersonConsultationFee
                    }
                    helperText={
                      touched.inPersonConsultationFee &&
                      errors.inPersonConsultationFee
                    }
                  />

                  {!values.isPayrollEmployee && (
                    <TextField
                      label="In person doctor fee"
                      name="inPersonDoctorFee"
                      type="number"
                      value={values.inPersonDoctorFee}
                      InputLabelProps={{
                        shrink: values.inPersonDoctorFee !== undefined, // Ensures the label stays shrunk when there's a value
                      }}
                      onChange={handleChange}
                      fullWidth
                      margin="dense"
                      required
                      autoFocus
                      error={
                        touched.inPersonDoctorFee && !!errors.inPersonDoctorFee
                      }
                      helperText={
                        touched.inPersonDoctorFee && errors.inPersonDoctorFee
                      }
                    />
                  )}
                </Box>
              )}

              {!values?.isNetwork && (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      overflowY: "hidden",
                      overflowX: "auto",
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                      sx={{ width: "max-content" }}
                    >
                      <Tab label="Online (24hours format)" {...a11yProps(0)} />
                      {values.acceptsInPerson && (
                        <Tab label="In person" {...a11yProps(1)} />
                      )}
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabValue} index={0}>
                    {
                      <FieldArray name="weeklyAvailabilitySettings">
                        {({ push, remove }) => (
                          <div>
                            {values.weeklyAvailabilitySettings.map(
                              (availability, index) => {
                                if (availability.appointmentMode === "ONLINE")
                                  return (
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      key={index}
                                    >
                                      <Grid item xs={12} md={4}>
                                        <FormControl
                                          fullWidth
                                          margin="dense"
                                          required
                                        >
                                          <InputLabel>
                                            Day of the Week
                                          </InputLabel>
                                          <Select
                                            label="Day of the week"
                                            name={`weeklyAvailabilitySettings.${index}.dayOfWeek`}
                                            value={availability.dayOfWeek}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                          >
                                            <MenuItem value="MONDAY">
                                              Monday
                                            </MenuItem>
                                            <MenuItem value="TUESDAY">
                                              Tuesday
                                            </MenuItem>
                                            <MenuItem value="WEDNESDAY">
                                              Wednesday
                                            </MenuItem>
                                            <MenuItem value="THURSDAY">
                                              Thursday
                                            </MenuItem>
                                            <MenuItem value="FRIDAY">
                                              Friday
                                            </MenuItem>
                                            <MenuItem value="SATURDAY">
                                              Saturday
                                            </MenuItem>
                                            <MenuItem value="SUNDAY">
                                              Sunday
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} md={2.5}>
                                        <TextField
                                          label="Start Time"
                                          name={`weeklyAvailabilitySettings.${index}.startTime`}
                                          type="time"
                                          value={availability.startTime}
                                          InputLabelProps={{
                                            shrink:
                                              availability.startTime !== "", // Ensures the label stays shrunk when there's a value
                                          }}
                                          onChange={handleChange}
                                          fullWidth
                                          margin="dense"
                                          required
                                          autoFocus
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={2.5}>
                                        <TextField
                                          label="End Time"
                                          name={`weeklyAvailabilitySettings.${index}.endTime`}
                                          type="time"
                                          value={availability.endTime}
                                          InputLabelProps={{
                                            shrink: availability.endTime !== "", // Ensures the label stays shrunk when there's a value
                                          }}
                                          onChange={handleChange}
                                          fullWidth
                                          margin="dense"
                                          required
                                          autoFocus
                                        />
                                      </Grid>
                                      <Grid item xs={10} md={2}>
                                        <TextField
                                          label="Slot Duration"
                                          name={`weeklyAvailabilitySettings.${index}.slotDuration`}
                                          type="number"
                                          value={availability.slotDuration}
                                          InputLabelProps={{
                                            shrink:
                                              availability.slotDuration !== 0, // Ensures the label stays shrunk when there's a value
                                          }}
                                          onChange={handleChange}
                                          fullWidth
                                          margin="dense"
                                          required
                                          autoFocus
                                        />
                                      </Grid>
                                      <Grid item xs={2} md={1}>
                                        <IconButton
                                          color="secondary"
                                          onClick={() => remove(index)}
                                        >
                                          <RemoveCircleOutline />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  );
                              }
                            )}
                            <Button
                              type="button"
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                push({
                                  dayOfWeek: "",
                                  startTime: "",
                                  endTime: "",
                                  slotDuration: 30,
                                  appointmentMode: "ONLINE",
                                })
                              }
                              startIcon={<AddCircleOutline />}
                            >
                              Add Availability
                            </Button>
                          </div>
                        )}
                      </FieldArray>
                    }
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    <FieldArray name="weeklyAvailabilitySettings">
                      {({ push, remove }) => (
                        <div>
                          {values.weeklyAvailabilitySettings.map(
                            (availability, index) => {
                              if (availability.appointmentMode === "IN_PERSON")
                                return (
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    key={index}
                                  >
                                    <Grid item xs={12} md={4}>
                                      <FormControl
                                        fullWidth
                                        margin="dense"
                                        required
                                      >
                                        <InputLabel>Day of the Week</InputLabel>
                                        <Select
                                          label="Day of the week"
                                          name={`weeklyAvailabilitySettings.${index}.dayOfWeek`}
                                          value={availability.dayOfWeek}
                                          onChange={handleChange}
                                          fullWidth
                                          required
                                        >
                                          <MenuItem value="MONDAY">
                                            Monday
                                          </MenuItem>
                                          <MenuItem value="TUESDAY">
                                            Tuesday
                                          </MenuItem>
                                          <MenuItem value="WEDNESDAY">
                                            Wednesday
                                          </MenuItem>
                                          <MenuItem value="THURSDAY">
                                            Thursday
                                          </MenuItem>
                                          <MenuItem value="FRIDAY">
                                            Friday
                                          </MenuItem>
                                          <MenuItem value="SATURDAY">
                                            Saturday
                                          </MenuItem>
                                          <MenuItem value="SUNDAY">
                                            Sunday
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2.5}>
                                      <TextField
                                        label="Start Time"
                                        name={`weeklyAvailabilitySettings.${index}.startTime`}
                                        type="time"
                                        value={availability.startTime}
                                        InputLabelProps={{
                                          shrink: availability.startTime !== "", // Ensures the label stays shrunk when there's a value
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        required
                                        autoFocus
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={2.5}>
                                      <TextField
                                        label="End Time"
                                        name={`weeklyAvailabilitySettings.${index}.endTime`}
                                        type="time"
                                        value={availability.endTime}
                                        InputLabelProps={{
                                          shrink: availability.endTime !== "", // Ensures the label stays shrunk when there's a value
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        required
                                        autoFocus
                                      />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                      <TextField
                                        label="Slot Duration"
                                        name={`weeklyAvailabilitySettings.${index}.slotDuration`}
                                        type="number"
                                        value={availability.slotDuration}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                          shrink:
                                            availability.slotDuration !== 0, // Ensures the label stays shrunk when there's a value
                                        }}
                                        fullWidth
                                        margin="dense"
                                        required
                                        autoFocus
                                      />
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                      <IconButton
                                        color="secondary"
                                        onClick={() => remove(index)}
                                      >
                                        <RemoveCircleOutline />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                );
                            }
                          )}
                          <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              push({
                                dayOfWeek: "",
                                startTime: "",
                                endTime: "",
                                slotDuration: 30,
                                appointmentMode: "IN_PERSON",
                              })
                            }
                            startIcon={<AddCircleOutline />}
                          >
                            Add Availability
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </CustomTabPanel>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditDoctorModal;
