import Modal from "../../ui/Modal";
import {  PullRecordsModalProps ,PullAbhaRecordsFormInputs} from "../../../types/documents";
import { useForm ,SubmitHandler ,Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PullAbhaRecordsFormValidationSchema } from "../../../validations/documentValidation";
import {Box ,Select,MenuItem,Typography ,Button} from "@mui/material";
import Input from "../../ui/Input";
import {erroMessageStyles,inputLabelStyles,selectStyles,} from "../../../utils/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useAuthStore from "../../../store/authStore";
import { abhaRecordsConsentRequest } from "../../../services/documentsService";

const UploadFileModal: React.FC<PullRecordsModalProps> = ({open,onClose,closeIcon,patientId}) => {

  const {user} = useAuthStore(); 

  // props & state values
  const {handleSubmit, control,  register,formState: { errors }} = useForm<PullAbhaRecordsFormInputs>({
    resolver: yupResolver(PullAbhaRecordsFormValidationSchema),
    defaultValues: {},
  });

  const onSubmit:SubmitHandler<PullAbhaRecordsFormInputs> = async (values) => {
    console.log("consent-request-values",values)
    console.log(user?.doctorId)
    const data = {...values , doctorId:user?.doctorId , patientId}
    try{
      await abhaRecordsConsentRequest(data);
    }catch(error){
      console.log(error);
    }
  }

  return (
    <Modal open={open} onClose={onClose} closeIcon={closeIcon}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{display: "flex",flexDirection: "column",gap: "1rem",height: "100%"}}
      >
        <Controller name="purposeCode" control={control} render={({ field, fieldState: { error } }) => (
             <Box sx={{display: "flex",flexDirection: "column",gap: "0.375rem",width: "100%",}}>
                <Typography sx={inputLabelStyles}>Purpose *</Typography>
                <Select
                   {...field}
                   fullWidth
                   value={field.value}
                   sx={selectStyles.withGreyBackground}
                   margin="dense"
                   error={!!error} 
                >
                      <MenuItem key={"CAREMGT"} value={"CAREMGT"}>Care Management</MenuItem>
                      <MenuItem key={"BTG"} value={"BTG"}>Break the Glass</MenuItem>
                      <MenuItem key={"PUBHLTH"} value={"PUBHLTH"}>Public Health</MenuItem>
                      <MenuItem key={"HPAYMT"} value={"HPAYMT"}>Healthcare Payment</MenuItem>
                      <MenuItem key={"DSRCH"} value={"DSRCH"}>Disease Specific Healthcare Research</MenuItem>
                      <MenuItem key={"PATRQT"} value={"PATRQT"}>Self-Requested</MenuItem>
                </Select>
                {error && (<Typography sx={erroMessageStyles}>{error.message}</Typography>)}
             </Box>
        )}/>

        <Input
            type="text"
            className="input-primary"
            label="Reference Url"
            {...register("refUri")}
            error={!!errors.refUri}
            helperText={errors.refUri?.message}
        />

        <Controller name="hiTypes" control={control} render={({ field, fieldState: { error } }) => (
             <Box sx={{display: "flex",flexDirection: "column",gap: "0.375rem",width: "100%",}}>
                <Typography sx={inputLabelStyles}>Hi Types *</Typography>
                <Select
                   {...field}
                   value={field.value || []}
                   fullWidth
                   sx={selectStyles.withGreyBackground}
                   margin="dense"
                   multiple
                   error={!!error} 
                >
                      <MenuItem key={"Prescription"} value={"Prescription"}>Prescription</MenuItem>
                      <MenuItem key={"DiagnosticReport"} value={"DiagnosticReport"}>DiagnosticReport</MenuItem>
                      <MenuItem key={"DischargeSummary"} value={"DischargeSummary"}>DischargeSummary</MenuItem>
                      <MenuItem key={"ImmunizationRecord"} value={"ImmunizationRecord"}>ImmunizationRecord</MenuItem>
                      <MenuItem key={"HealthDocumentRecord"} value={"HealthDocumentRecord"}>HealthDocumentRecord</MenuItem>
                      <MenuItem key={"WellnessRecord"} value={"WellnessRecord"}>WellnessRecord</MenuItem>
                      <MenuItem key={"OPConsultation"} value={"OPConsultation"}>OPConsultation</MenuItem>
                </Select>
                {error && (<Typography sx={erroMessageStyles}>{error.message}</Typography>)}
             </Box>
        )}/>

        <Controller name="startDate" control={control} defaultValue={undefined} render={({ field, fieldState: { error } }) => (
            <Box sx={{display: "flex",flexDirection: "column",gap: "0.375rem",width: "100%"}} >
              <Typography sx={inputLabelStyles}>From Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker value={field.value || null} 
                           onChange={(date) => { field.onChange(date)}}
                            format="dd/MM/yyyy"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                placeholder: "From (DD/MM/YYYY)",
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                      height: "2.5rem",
                                      borderRadius: "0.625rem",
                                      backgroundColor:"var(--neuro-bg-light-grey-secondary)",
                                      borderColor:"var(--neuro-bg-light-grey-secondary)",
                                      "& >fieldset": {borderColor:"var(--neuro-bg-light-grey-secondary)"},
                                      "& >fieldset:hover": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >fieldset:active": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >fieldset:focus": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":{border:"1px solid var(--neuro-bg-light-grey-secondary)"},
                                      "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":{border:"1px solid var(--neuro-bg-light-grey-secondary)",}
                                    }
                                },
                              },
                            }}
                            sx={{"& .MuiFormControl-root": { width: "100%"},}}
                />
              </LocalizationProvider>
              {error && (<Typography sx={erroMessageStyles}> {error.message} </Typography>)}
            </Box>
        )}/>   

          <Controller name="endDate" control={control} defaultValue={undefined} render={({ field, fieldState: { error } }) => (
            <Box sx={{display: "flex",flexDirection: "column",gap: "0.375rem",width: "100%"}} >
              <Typography sx={inputLabelStyles}>To Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker value={field.value || null} 
                            onChange={(date) => { field.onChange(date)}}
                            format="dd/MM/yyyy"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                placeholder: "To (DD/MM/YYYY)",
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                      height: "2.5rem",
                                      borderRadius: "0.625rem",
                                      backgroundColor:"var(--neuro-bg-light-grey-secondary)",
                                      borderColor:"var(--neuro-bg-light-grey-secondary)",
                                      "& >fieldset": {borderColor:"var(--neuro-bg-light-grey-secondary)"},
                                      "& >fieldset:hover": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >fieldset:active": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >fieldset:focus": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":{border:"1px solid var(--neuro-bg-light-grey-secondary)"},
                                      "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":{border:"1px solid var(--neuro-bg-light-grey-secondary)",}
                                    }
                                },
                              },
                            }}
                            sx={{"& .MuiFormControl-root": { width: "100%"},}}
                />
              </LocalizationProvider>
              {error && (<Typography sx={erroMessageStyles}> {error.message} </Typography>)}
            </Box>
          )}/>    

          <Controller name="dataEraseAt" control={control} defaultValue={undefined} render={({ field, fieldState: { error } }) => (
            <Box sx={{display: "flex",flexDirection: "column",gap: "0.375rem",width: "100%"}} >
              <Typography sx={inputLabelStyles}>Data Erase Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker value={field.value || null} 
                            onChange={(date) => { field.onChange(date)}}
                            format="dd/MM/yyyy"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                placeholder: "(DD/MM/YYYY)",
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                      height: "2.5rem",
                                      borderRadius: "0.625rem",
                                      backgroundColor:"var(--neuro-bg-light-grey-secondary)",
                                      borderColor:"var(--neuro-bg-light-grey-secondary)",
                                      "& >fieldset": {borderColor:"var(--neuro-bg-light-grey-secondary)"},
                                      "& >fieldset:hover": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >fieldset:active": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >fieldset:focus": {borderColor:"var(--neuro-bg-light-grey-secondary)",},
                                      "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":{border:"1px solid var(--neuro-bg-light-grey-secondary)"},
                                      "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":{border:"1px solid var(--neuro-bg-light-grey-secondary)",}
                                    }
                                },
                              },
                            }}
                            sx={{"& .MuiFormControl-root": { width: "100%"},}}
                />
              </LocalizationProvider>
              {error && (<Typography sx={erroMessageStyles}> {error.message} </Typography>)}
            </Box>
          )}/>  

          <Box sx={{ display: "flex", alignItems: "center", gap: "0.938rem", flexWrap: "wrap", justifyContent: "center" }}>
              <Button variant="contained" color="primary" className="primary-button" type="submit" sx={{textTransform: "none"}}>
                Consent Request
              </Button>
          </Box>
      </Box>
    </Modal>
  );
};

export default UploadFileModal;
