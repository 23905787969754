import { useEffect, useState } from "react";

const DocuemtMetaData = ({ metaData }: { metaData: any }) => {
  // props & state values
  const [summaryMetaData, setSummaryMetaData] = useState<any>(metaData);

  // callbacks & functions
  useEffect(() => {
    if (metaData && metaData !== "") {
      setSummaryMetaData(metaData);
    } else if (!metaData || metaData === "") {
      const metaDataContent = "<html><p>No Meta data</p></html>";
      setSummaryMetaData(metaDataContent);
    }
  }, [metaData]);

  const handleSummayMetaData = (event: any) => {
    setSummaryMetaData(event.target.innerHTML);
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      contentEditable
      dangerouslySetInnerHTML={{
        __html: `<pre>${summaryMetaData}</pre>`,
      }}
      onBlur={handleSummayMetaData}
    />
  );
};

export default DocuemtMetaData;
