import React from "react";
import { Box, Typography } from "@mui/material";
import Switch from "../../ui/Switch";
import { MeetTypeToggleProps } from "../../../types/appointments";

const MeetTypeToggle: React.FC<MeetTypeToggleProps> = ({
  onChange,
  checked = false,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        sx={{
          color: checked
            ? "var(--neuro-darkgrey_border)"
            : "var(--neuro-black-text)",
          fontFamily: "var(--neuro-font-family-roboto)",
          lineHeight: "120%",
        }}
      >
        Online meet
      </Typography>

      <Switch checked={checked} onChange={onChange} />

      <Typography
        sx={{
          color: checked
            ? "var(--neuro-black-text)"
            : "var(--neuro-darkgrey_border)",
          fontFamily: "var(--neuro-font-family-roboto)",
          lineHeight: "120%",
        }}
      >
        Clinic visit
      </Typography>
    </Box>
  );
};

export default MeetTypeToggle;
