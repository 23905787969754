import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ReactOtpInput from "react-otp-input";
import { OtpInputProps } from "../../types/ui";

const OtpInput: React.FC<OtpInputProps> = ({
  value,
  numInputs,
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine the gap based on whether it's a mobile device or not
  const gap = isMobile ? "0.625rem" : "1.688rem";

  return (
    <ReactOtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      inputType="tel"
      shouldAutoFocus
      containerStyle={{
        display: "flex",
        justifyContent: "center",
        gap: gap, // Apply the determined gap value
      }}
      inputStyle={{
        width: "3.125rem",
        height: "4.688rem", // Height of each input box
        borderRadius: "0.5rem", // Rounded corners for the input box
        fontSize: "var(--neuro-font-size-smaller-plus)", // Font size inside the input box
        border: "0.063rem solid var(--neuro-darkgrey_border)", // Border color for the input box
        textAlign: "center", // Center the text inside each input box
        color: "var(--neuro-black-text)", // Text color inside the input box
        backgroundColor: "var(--neuro-white-text)", // Background color for the input box
        outline: "none", // Remove default focus outline
      }}
      renderInput={(inputProps) => <input {...inputProps} />}
      {...props}
    />
  );
};

export default OtpInput;
