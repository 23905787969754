import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import useAuthStore from "../../../store/authStore";
import { getToken } from "../../../services/appointmentsService";
import { ConferenceProps } from "../../../types/appointments";

const Conference: React.FC<ConferenceProps> = ({ appointmentId }) => {
  // props & state values
  const { name } = useAuthStore();
  const [token, setToken] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [isConnected, setIsConnected] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Initializing setup...");

  // callbacks & functions
  const loadMeetingToken = async (appointmentId: string) => {
    const data = {
      appointmentId,
    };
    try {
      const response = await getToken(data);
      setToken(response?.data?.token || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (appointmentId) {
      loadMeetingToken(appointmentId);
    }
  }, [appointmentId]);

  // Listen for the postMessage from the iframe
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === "connectedSuccess") {
        setIsConnected(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // Update status messages over time
  useEffect(() => {
    if (!isConnected && loading) {
      const messageSequence = [
        "Initializing setup...",
        "Connecting to conference server...",
        "Setting up your video and audio...",
        "Almost there, please wait...",
      ];

      messageSequence.forEach((message, index) => {
        setTimeout(() => {
          setStatusMessage(message);
        }, index * 2000); // Change message every 3 seconds
      });
    }
  }, [loading, isConnected]);

  const url = token
    ? `https://dev.racchabanda.com/?isDirect=true&rb_pass=${token}&displayName=${name}`
    : "";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // overflow: "scroll",
      }}
    >
      {!loading && !token && <h4>Conference could not be established</h4>}

      {!loading && token && (
        <>
          {(loading || !isConnected) && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 1,
                backgroundColor: "var(--neuro-black-text)",
                color: "var(--neuro-white-text)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
                textAlign: "center",
              }}
            >
              <h4>{statusMessage}</h4>
            </Box>
          )}

          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src={url}
              title="Conference"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                // overflow: "scroll",
                aspectRatio: "16/9", // Maintain aspect ratio
              }}
              allow="camera; microphone; fullscreen; speaker;"
              allowFullScreen
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Conference;
