import { Box, Grid } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Button from "../../ui/Button";
import VideoCallIcon from "../../svg/VideoCallIcon";
import ConfirmModal from "../../shared/ConfirmModal";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  getAppointmentStatus,
  getTimeDiffereForOngoing,
  handleOpenMap,
} from "../../../utils/appointments";
import { useNavigate } from "react-router-dom";
import Spinner from "../../ui/Spinner";
import LocationIcon from "../../svg/LocationIcon";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useAppStore from "../../../store/appStore";
import {
  CANCELLED,
  COMPLETED,
  IN_PERSON,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import { AppointmentDetailsButtonProps } from "../../../types/appointments";
import { isClinicAdminOrAdmin, isDoctor, isPatient } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";
import PrescriptionButton from "../Appointments/PrescriptionButton";
import PaymentIcon from "@mui/icons-material/Payment";
import config from "../../../config";
import api from "../../../services/apiService";
import PaidIcon from "@mui/icons-material/Paid";
import { offlinePayment } from "../../../services/appointmentsService";
import Tooltip from "../../ui/Tooltip";
import { isAfter, parseISO } from "date-fns";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { Tax } from "../../../types/common";
import { formatMinutesToHHMM } from "../../../utils/common";
import EditMeetingInfoButton from "../Appointments/EditMeetingInfoButton";
// import CancelAppointmentModal from "./CancelAppointmentModal";

const AppointmentDetailsButton: React.FC<AppointmentDetailsButtonProps> = ({
  appointmentCode,
  id,
  status,
  availableSlot,
  meetType,
  hospitalAddress,
  prescription,
  paymentStatus,
  patientId,
  patient,
  appointmentMode,
  loadAppointmentDetails,
  // appointmentDate,
  doctor,
  // isFromMeet = false,
  isFromDashboard = false,
}) => {
  // props & state values
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [payOfflineLoading, setPayOfflineLoading] = useState<boolean>(false);
  console.log("prescription in details button", prescription);
  const { userRole, user } = useAuthStore();

  const styles = {
    meetButtonStyles: {
      fontSize: "var(--neuro-font-size-smaller-plus)",
      textTransform: "none",
      height: "2.25rem",
      fontFamily: "var(--neuro-font-family-roboto)",
    },
  };

  const { showSnackbar, appointmentsFilter } = useAppStore();
  const navigate = useNavigate();

  const charges =
    appointmentMode === ONLINE
      ? doctor?.onlineConsultationFee || null
      : doctor?.inPersonConsultationFee || null;

  const taxes: Tax[] = doctor?.taxes;

  const calculateTotalAmount = () => {
    return taxes?.reduce((total, tax) => {
      if (tax.type === "PERCENTAGE") {
        return total + (charges * parseFloat(tax.value)) / 100 || null;
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  };

  const totalAmount = charges
    ? taxes && taxes?.length > 0
      ? calculateTotalAmount()
      : charges
    : null;

  const textToCopy = `https://22neuro.continuous.engineering/meet/${appointmentCode}`;

  const { cancelAppointment, loadingCancelAppointment, fetchAppointments } =
    useAppointmentStore();

  // Memoize appointment status and time difference to avoid multiple calculations
  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot?.startTime, availableSlot?.endTime]
  );

  const showDirectionsButton =
    (appointmentStatus === ONGOING || appointmentStatus === UPCOMING) &&
    status !== CANCELLED;

  const argument = isFromDashboard ? "DASHBOARD" : "";

  const isOngoingSoon = useMemo(
    () =>
      getTimeDiffereForOngoing(availableSlot?.startTime) <=
      config?.MEETING_ENABLE_TIME,
    [availableSlot?.startTime]
  );

  const meetingStarted = useMemo(() => {
    const currentTime = new Date();
    const localTargetTime = parseISO(availableSlot?.startTime);
    const timePassed = isAfter(currentTime, localTargetTime);
    return timePassed || false;
  }, [availableSlot?.startTime]);

  const meetButtonText = isDoctor(userRole)
    ? "Meet with patient"
    : "Meet with doctor";

  const isMeetingAllowed = useMemo(
    () => [UPCOMING, ONGOING].includes(appointmentStatus),
    [appointmentStatus]
  );

  const isCompleted = useMemo(
    () => status === COMPLETED || appointmentStatus === COMPLETED,
    [status, appointmentStatus]
  );

  const isCancelledOrCompleted = useMemo(
    () => status === CANCELLED || isCompleted,
    [status, isCompleted]
  );

  const showMeetButton = () => {
    if (appointmentMode === IN_PERSON) {
      if (
        (appointmentStatus === UPCOMING || appointmentStatus === ONGOING) &&
        isDoctor(userRole) &&
        status !== CANCELLED &&
        status !== COMPLETED
      ) {
        return true;
      }
      return false;
    } else {
      if (
        (appointmentStatus === UPCOMING || appointmentStatus === ONGOING) &&
        status !== CANCELLED &&
        status !== COMPLETED
      ) {
        return true;
      }
      return false;
    }
  };

  const showEditMeetingInfoButton =
    isCompleted && isDoctor(userRole) && doctor?.id === user?.doctorId;

  const payButtonCondition =
    status !== COMPLETED &&
    status !== CANCELLED &&
    appointmentStatus !== COMPLETED &&
    paymentStatus &&
    paymentStatus === "PENDING";

  const showPayButton =
    (isPatient(userRole) || isClinicAdminOrAdmin(userRole)) &&
    payButtonCondition;

  const showPayPendingDoctorButton = isDoctor(userRole) && payButtonCondition;

  const showPayOfflineButton =
    isClinicAdminOrAdmin(userRole) && payButtonCondition;

  // callbacks & functions
  const handleConfirm = useCallback(() => {
    if (!id || id === "") {
      showSnackbar("Could not cancel appointment, please try again", "error");
      return;
    }
    cancelAppointment(id);
  }, [id, cancelAppointment, showSnackbar]);

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleMeetingJoin = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      event.stopPropagation();
      navigate(`/meet/${id}`, {
        state: { appointmentMode: appointmentMode },
      });
    },
    [navigate]
  );

  const handleCopyClick = useCallback(() => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => showSnackbar("Link copied", "success"))
      .catch(() =>
        showSnackbar("Could not copy link, please try again", "info")
      );
  }, [textToCopy, showSnackbar]);

  const handlePayment = useCallback(
    async (appointmentId: string, amount: number | null) => {
      try {
        const params = { paymentType: "PAYLATER" };

        const { data } = await api.post(
          `/api/payments/createOrder`,
          { appointmentId, amount },
          { params }
        );
        const { id, currency } = data;

        const options = {
          key: config.RAZORPAY_KEY_ID,
          amount,
          currency,
          name: config.APP_NAME,
          description: config.RAZORPAY_DESCRIPTION,
          order_id: id,
          handler: async (response: any) => {
            try {
              const result = await api.post(`/api/payments/verifySignature`, {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                appointmentId,
              });
              if (result.data.status === "success") {
                showSnackbar("Payment successful", "success");
                fetchAppointments(
                  appointmentsFilter.customStartDate,
                  appointmentsFilter.customEndDate,
                  appointmentsFilter.status || "",
                  patientId || "",
                  argument
                );
              } else {
                showSnackbar(
                  "Payment not completed, please try again",
                  "error"
                );
              }
            } catch {
              showSnackbar("Payment not completed, please try again", "error");
            }
          },
          prefill: {
            // name:
            //   `${patient?.user?.firstName} ${patient?.user?.lastName}` || "",
            name: `${patient?.user?.firstName}` || "",
            email: patient?.user?.email || "",
            contact: patient?.user?.mobile || "",
          },
          theme: { color: "#3399cc" },
          modal: {
            ondismiss: () =>
              showSnackbar("Payment was cancelled by the user", "info"),
          },
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.on("payment.failed", (response: any) =>
          showSnackbar(
            `Payment failed. Reason: ${response.error.description}`,
            "error"
          )
        );
        rzp1.open();
      } catch (error) {
        console.error(error);
      }
    },
    [showSnackbar, fetchAppointments, patientId, patient, user]
  );

  // offline payment by admin
  const handleOfflinePayment = async (id: string) => {
    try {
      setPayOfflineLoading(true);
      const result = await offlinePayment(id);
      if (result && result?.data) {
        setPayOfflineLoading(false);
        showSnackbar("Paid offline, successufully", "success");
        if (loadAppointmentDetails) {
          loadAppointmentDetails(id);
        } else {
          fetchAppointments(
            appointmentsFilter.customStartDate,
            appointmentsFilter.customEndDate,
            appointmentsFilter.status || "",
            patientId || "",
            argument
          );
        }
      }
    } catch (error) {
      setPayOfflineLoading(false);
      showSnackbar("Could not complete pay offline, please try again", "error");
    }
  };

  return (
    <>
      {meetType === "Online" && (
        <Grid container spacing={2} sx={{ paddingLeft: "0.688rem" }}>
          {isMeetingAllowed && !isCancelledOrCompleted && (
            <>
              {showPayPendingDoctorButton && (
                <Tooltip
                  title={
                    isDoctor(userRole) ? "Payment not completed by patient" : ""
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      variant="contained"
                      className="warning-button"
                      startIcon={
                        <CreditCardOffIcon
                          sx={{
                            fontSize: "var(--neuro-font-size-tiny-plus)",
                            color: "var(--neuro-white-text)",
                          }}
                        />
                      }
                      sx={{
                        textTransform: "none",
                        height: "2.25rem",
                      }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                      disabled
                    >
                      Payment pending
                    </Button>
                  </Box>
                </Tooltip>
              )}

              {showPayButton && (
                <Grid item>
                  <Button
                    variant="contained"
                    className="success-gradient-button"
                    startIcon={
                      <PaymentIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      height: "2.25rem",
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handlePayment(id, totalAmount);
                    }}
                    disabled={!totalAmount}
                  >
                    Pay
                  </Button>
                </Grid>
              )}

              {showPayOfflineButton && (
                <Grid item>
                  <Button
                    className="primary-button"
                    variant="contained"
                    startIcon={
                      <PaidIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      height: "2.25rem",
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handleOfflinePayment(id);
                    }}
                    disabled={!totalAmount || payOfflineLoading}
                  >
                    {payOfflineLoading ? <Spinner /> : "Pay Offline"}
                  </Button>
                </Grid>
              )}

              {showMeetButton() && (
                <>
                  {!isOngoingSoon ? (
                    paymentStatus === "PENDING" ? (
                      <Tooltip
                        title={
                          isDoctor(userRole) && doctor?.id === user?.doctorId
                            ? "Payment not completed by patient"
                            : isClinicAdminOrAdmin(userRole) ||
                                (isDoctor(userRole) &&
                                  doctor?.id !== user?.doctorId)
                              ? "You are not a participant in this meet"
                              : "Please complete the payment to meet with doctor"
                        }
                      >
                        <Grid item>
                          <span
                            style={{ width: 0 }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          ></span>
                          <Button
                            startIcon={<VideoCallIcon />}
                            disabled={true}
                            color="secondary"
                            variant="outlined"
                            className="outlined-secondary-button"
                            sx={styles.meetButtonStyles}
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              event.stopPropagation();
                            }}
                          >
                            {meetButtonText}
                          </Button>
                        </Grid>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        // title={`You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`}
                        title={
                          isDoctor(userRole) && doctor?.id === user?.doctorId
                            ? `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                            : isClinicAdminOrAdmin(userRole) ||
                                (isDoctor(userRole) &&
                                  doctor?.id !== user?.doctorId)
                              ? "You are not a participant in this meet"
                              : `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                        }
                      >
                        <Grid item>
                          <span
                            style={{ width: 0 }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          ></span>
                          <Button
                            startIcon={<VideoCallIcon />}
                            disabled={true}
                            color="secondary"
                            variant="outlined"
                            className="outlined-secondary-button"
                            sx={styles.meetButtonStyles}
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              event.stopPropagation();
                            }}
                          >
                            {meetButtonText}
                          </Button>
                        </Grid>
                      </Tooltip>
                    )
                  ) : paymentStatus === "PENDING" ? (
                    <Tooltip
                      title={
                        isDoctor(userRole) && doctor?.id === user?.doctorId
                          ? "Payment not completed by patient"
                          : isClinicAdminOrAdmin(userRole) ||
                              (isDoctor(userRole) &&
                                doctor?.id !== user?.doctorId)
                            ? "You are not a participant in this meet"
                            : "Please complete the payment to meet with doctor"
                      }
                    >
                      <Grid item>
                        <span
                          style={{ width: 0 }}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        ></span>
                        <Button
                          startIcon={<VideoCallIcon />}
                          disabled={true}
                          color="secondary"
                          variant="outlined"
                          className="outlined-secondary-button"
                          sx={styles.meetButtonStyles}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            event.stopPropagation();
                          }}
                        >
                          {meetButtonText}
                        </Button>
                      </Grid>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                          ? "You are not a participant in this meet"
                          : ""
                      }
                    >
                      <Grid item>
                        {(isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) &&
                            doctor?.id !== user?.doctorId)) && (
                          <span
                            style={{ width: 0 }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          ></span>
                        )}
                        <Button
                          startIcon={<VideoCallIcon />}
                          disabled={
                            isClinicAdminOrAdmin(userRole) ||
                            (isDoctor(userRole) &&
                              doctor?.id !== user?.doctorId)
                          }
                          color="secondary"
                          variant="outlined"
                          className="outlined-secondary-button"
                          sx={styles.meetButtonStyles}
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            if (
                              isClinicAdminOrAdmin(userRole) ||
                              (isDoctor(userRole) &&
                                doctor?.id !== user?.doctorId)
                            ) {
                              event.stopPropagation();
                            } else {
                              handleMeetingJoin(event, appointmentCode);
                            }
                          }}
                        >
                          {meetButtonText}
                        </Button>
                      </Grid>
                    </Tooltip>
                  )}
                </>
              )}

              <Grid item>
                <Button
                  variant="contained"
                  className="warning-button"
                  sx={{
                    height: "2.25rem",
                  }}
                  onClick={handleCopyClick}
                  startIcon={
                    <ContentCopyIcon
                      sx={{ fontSize: "var(--neuro-font-size-micro)" }}
                    />
                  }
                >
                  Copy Invitation
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={
                    <NoMeetingRoomIcon
                      sx={{ fontSize: "var(--neuro-font-size-micro)" }}
                    />
                  }
                  className="outlined-secondary-button"
                  sx={{
                    height: "2.25rem",
                  }}
                  onClick={() => setOpenConfirmModal(true)}
                  disabled={loadingCancelAppointment || meetingStarted}
                >
                  {loadingCancelAppointment ? <Spinner /> : "Cancel Meeting"}
                </Button>
              </Grid>
            </>
          )}

          {prescription && prescription.length > 0 && (
            <Grid item>
              <PrescriptionButton
                key={id}
                id={id}
                prescription={prescription}
              />
            </Grid>
          )}

          {showEditMeetingInfoButton && (
            <Grid item>
              <EditMeetingInfoButton
                id={id}
                loadAppointmentDetails={loadAppointmentDetails}
              />
            </Grid>
          )}
        </Grid>
      )}

      {meetType === "Clinic" && (
        <Box
          sx={{
            marginTop: "3rem",
            display: "flex",
            alignItems: "center",
            gap: "0.625rem",
            flexWrap: "wrap",
          }}
        >
          {showPayPendingDoctorButton && (
            <Tooltip
              title={
                isDoctor(userRole) ? "Payment not completed by patient" : ""
              }
            >
              <Box
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <span
                  style={{ width: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                ></span>
                <Button
                  variant="contained"
                  className="warning-button"
                  startIcon={
                    <CreditCardOffIcon
                      sx={{
                        fontSize: "var(--neuro-font-size-tiny-plus)",
                        color: "var(--neuro-white-text)",
                      }}
                    />
                  }
                  sx={{
                    textTransform: "none",
                    height: "2.25rem",
                  }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                  }}
                  disabled
                >
                  Payment pending
                </Button>
              </Box>
            </Tooltip>
          )}

          {showPayButton && (
            <Button
              variant="contained"
              className="success-gradient-button"
              startIcon={
                <PaymentIcon
                  sx={{
                    fontSize: "var(--neuro-font-size-tiny-plus)",
                    color: "var(--neuro-white-text)",
                  }}
                />
              }
              sx={{
                textTransform: "none",
                height: "2.25rem",
              }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handlePayment(id, totalAmount);
              }}
              disabled={!totalAmount}
            >
              Pay
            </Button>
          )}

          {showPayOfflineButton && (
            <Button
              className="primary-button"
              variant="contained"
              startIcon={
                <PaidIcon
                  sx={{
                    fontSize: "var(--neuro-font-size-tiny-plus)",
                    color: "var(--neuro-white-text)",
                  }}
                />
              }
              sx={{
                textTransform: "none",
                height: "2.25rem",
              }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handleOfflinePayment(id);
              }}
              disabled={!totalAmount || payOfflineLoading}
            >
              {payOfflineLoading ? <Spinner /> : "Pay Offline"}
            </Button>
          )}

          {showMeetButton() && (
            <>
              {!isOngoingSoon ? (
                paymentStatus === "PENDING" ? (
                  <Tooltip
                    title={
                      isDoctor(userRole) && doctor?.id === user?.doctorId
                        ? "Payment not completed by patient"
                        : isClinicAdminOrAdmin(userRole) ||
                            (isDoctor(userRole) &&
                              doctor?.id !== user?.doctorId)
                          ? "You are not a participant in this meet"
                          : "Please complete the payment to meet with doctor"
                    }
                  >
                    <Grid item>
                      <span
                        style={{ width: 0 }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      ></span>
                      <Button
                        startIcon={<VideoCallIcon />}
                        disabled={true}
                        color="secondary"
                        variant="outlined"
                        className="outlined-secondary-button"
                        sx={styles.meetButtonStyles}
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.stopPropagation();
                        }}
                      >
                        {meetButtonText}
                      </Button>
                    </Grid>
                  </Tooltip>
                ) : (
                  <Tooltip
                    // title={`You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`}
                    title={
                      isDoctor(userRole) && doctor?.id === user?.doctorId
                        ? `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                        : isClinicAdminOrAdmin(userRole) ||
                            (isDoctor(userRole) &&
                              doctor?.id !== user?.doctorId)
                          ? "You are not a participant in this meet"
                          : `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                    }
                  >
                    <Grid item>
                      <span
                        style={{ width: 0 }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      ></span>
                      <Button
                        startIcon={<VideoCallIcon />}
                        disabled={true}
                        color="secondary"
                        variant="outlined"
                        className="outlined-secondary-button"
                        sx={styles.meetButtonStyles}
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.stopPropagation();
                        }}
                      >
                        {meetButtonText}
                      </Button>
                    </Grid>
                  </Tooltip>
                )
              ) : paymentStatus === "PENDING" ? (
                <Tooltip
                  title={
                    isDoctor(userRole) && doctor?.id === user?.doctorId
                      ? "Payment not completed by patient"
                      : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                        ? "You are not a participant in this meet"
                        : "Please complete the payment to meet with doctor"
                  }
                >
                  <Grid item>
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={true}
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Grid>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    isClinicAdminOrAdmin(userRole) ||
                    (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ? "You are not a participant in this meet"
                      : ""
                  }
                >
                  <Grid item>
                    {(isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) &&
                        doctor?.id !== user?.doctorId)) && (
                      <span
                        style={{ width: 0 }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      ></span>
                    )}
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={
                        isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      }
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (
                          isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                        ) {
                          event.stopPropagation();
                        } else {
                          handleMeetingJoin(event, appointmentCode);
                        }
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Grid>
                </Tooltip>
              )}
            </>
          )}

          {showDirectionsButton && (
            <Button
              className="outlined-secondary-button"
              startIcon={<LocationIcon />}
              color="secondary"
              variant="outlined"
              sx={{ height: "2.25rem" }}
              onClick={() => handleOpenMap(hospitalAddress || "")}
            >
              Directions
            </Button>
          )}

          {prescription && prescription.length > 0 && (
            <PrescriptionButton key={id} id={id} prescription={prescription} />
          )}

          {showEditMeetingInfoButton && (
            <EditMeetingInfoButton
              id={id}
              loadAppointmentDetails={loadAppointmentDetails}
            />
          )}

          {!isCancelledOrCompleted && (
            <Button
              variant="outlined"
              startIcon={
                <NoMeetingRoomIcon
                  sx={{ fontSize: "var(--neuro-font-size-micro)" }}
                />
              }
              className="outlined-secondary-button"
              sx={{
                height: "2.25rem",
              }}
              onClick={() => setOpenConfirmModal(true)}
              disabled={loadingCancelAppointment || meetingStarted}
            >
              {loadingCancelAppointment ? <Spinner /> : "Cancel Meeting"}
            </Button>
          )}
        </Box>
      )}

      <ConfirmModal
        open={openConfirmModal}
        description="Are you sure, you want to cancel this appointment?"
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />

      {/* <CancelAppointmentModal
        open={openConfirmModal}
        description="Are you sure, you want to cancel this appointment"
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      /> */}
    </>
  );
};

export default AppointmentDetailsButton;
