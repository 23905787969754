import React from "react";

interface AudioPlayerProps {
  audioUrl: string;
}

const AudioThumbnail: React.FC<AudioPlayerProps> = ({ audioUrl }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        width: "10rem",
        height: "9.375rem",
      }}
    >
      <audio
        controls
        style={{ width: "100%", height: "3.75rem" }}
        onClick={(event) => event?.stopPropagation()}
      >
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioThumbnail;
