import React, { useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PatientSignUpModalProps } from "../../types/patients";
import PatientSignUp from "./PatientSignUp";
// import PatientEmail from "./PatientEmail";
// import PatientOtp from "./PatientOtp";
import Modal from "../ui/Modal";

const PatientSignUpModal: React.FC<PatientSignUpModalProps> = ({
  open,
  onClose,
  loadPatients,
  ...props
}) => {
  // props & state values
  const theme = useTheme();

  // const [email, setEmail] = useState<any>("");
  // const [otpToken, setOtpToken] = useState<any>("");
  // const [isSignUpRequired, setIsSignUpRequired] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // callbacks & functions
  const handleClose = () => {
    onClose();
    // setOtpToken("");
    // setIsSignUpRequired(false);
  };

  useEffect(() => {
    // cleanup on unmount
    return () => {
      // setOtpToken("");
      // setIsSignUpRequired(false);
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeIcon
      width={isMobile ? "20rem" : "33.75rem"}
      {...props}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Add Patient Details
      </DialogTitle>
      <DialogContent
        sx={{ height: "27.35rem", overflowX: "hidden", overflowY: "auto" }}
      >
        <PatientSignUp loadPatients={loadPatients} handleClose={handleClose} />
      </DialogContent>
    </Modal>
  );
};

export default PatientSignUpModal;
