// third-party imports
import { RouterProvider } from "react-router-dom";
import "react-data-grid/lib/styles.css";

// project imports
import "./App.css";
import { router } from "./components/routing/Routes";
// import HelpSupportModal from "./components/shared/HelpSupportModal";
import CustomErrorBoundary from "./components/shared/CustomErrorBoundary";
import SnackbarProvider from "./components/ui/SnackbarProvider";

const App = () => {
  return (
    <SnackbarProvider>
      <CustomErrorBoundary>
        <RouterProvider router={router} />
        {/* <HelpSupportModal /> */}
      </CustomErrorBoundary>
    </SnackbarProvider>
  );
};

export default App;
