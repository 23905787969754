import React from "react";
import { List } from "@mui/material";
import SidebarMenuItem from "../ui/SidebarMenuItem";
import { SidebarMenuProps } from "../../types/common";

const SidebarMenu: React.FC<SidebarMenuProps> = ({ menuItems }) => {
  // const [expandedMenus, setExpandedMenus] = useState<{
  //   [key: number]: boolean;
  // }>({});

  // const toggleSubmenu = (index: number) => {
  //   setExpandedMenus((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };

  return (
    <List
      sx={{
        padding: 0,
        // marginTop: "0.75rem",
        // height: "calc(100% - 7.813rem)",
        height: "100%",
        overflow: "auto",
      }}
    >
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          <SidebarMenuItem
            text={item.text}
            icon={item.icon}
            route={item.route}
            selected={item.selected}
            hasSubmenus={!!item.submenus && item.submenus.length > 0}
            submenus={item.submenus}
            initiallyOpen
          />
        </React.Fragment>
      ))}
    </List>
  );
};

export default SidebarMenu;
