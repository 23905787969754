import { create } from "zustand";
import { getProfileApi, updateProfileApi } from "../services/profileService";
import useAuthStore from "./authStore";

interface ProfileStore {
  // States for fetching profile details
  profileDetails: any;
  loadingProfile: boolean;
  errorProfile: string | null;
  userProfile: string | null;
  userFirstName: string | null;
  userLastName: string | null;
  getProfileDetails: (
    id?: string,
    role?: string,
    query?: string
  ) => Promise<void>;
  cleanUpGetProfileState: () => void;

  // States for updating profile
  updatedProfileDetails: any;
  loadingUpdateProfile: boolean;
  errorUpdateProfile: string | null;
  updateProfile: (id: string, data: any) => Promise<void>;
  cleanUpUpdateProfileState: () => void;
}

const useProfileStore = create<ProfileStore>((set) => ({
  // Initial states for fetching profile
  profileDetails: null,
  loadingProfile: false,
  errorProfile: null,
  userProfile: null,
  userFirstName: null,
  userLastName: null,
  getProfileDetails: async (id?: string, role?: string, query?: string) => {
    set({ loadingProfile: true, errorProfile: null });
    try {
      const response = await getProfileApi(id, role, query);
      const userIdFromAuthStore = useAuthStore?.getState()?.user?.userId;
      const userIdFromResponse = response?.data?.user?.id;

      if (userIdFromAuthStore === userIdFromResponse) {
        set({
          profileDetails: response.data,
          loadingProfile: false,
          userProfile: response?.data?.user?.profileImageUrl,
          userFirstName: response?.data?.user?.firstName,
          userLastName: response?.data?.user?.lastName,
        });
      } else {
        set({
          profileDetails: response.data,
          loadingProfile: false,
        });
      }
    } catch (error) {
      set({ errorProfile: "Something went wrong!", loadingProfile: false });
    }
  },
  cleanUpGetProfileState: () => {
    set({ profileDetails: null, loadingProfile: false, errorProfile: null });
  },

  // Initial states for updating profile
  updatedProfileDetails: null,
  loadingUpdateProfile: false,
  errorUpdateProfile: null,
  updateProfile: async (id: string, data: any) => {
    set({ loadingUpdateProfile: true, errorUpdateProfile: null });
    try {
      const response = await updateProfileApi(id, data);
      console.log("response", response);
      set({
        updatedProfileDetails: response.data,
        loadingUpdateProfile: false,
      });
    } catch (error) {
      set({
        errorUpdateProfile: "Something went wrong!",
        loadingUpdateProfile: false,
      });
    }
  },
  cleanUpUpdateProfileState: () => {
    set({
      updatedProfileDetails: null,
      loadingUpdateProfile: false,
      errorUpdateProfile: null,
    });
  },
}));

export default useProfileStore;
