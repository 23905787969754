// import React, { useEffect, useState } from "react";

// // third-party imports
// import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

// // project imports
// import AppointmentDetails from "./AppointmentDetails";
// import Calendar from "../AppointmentScheduler/Calendar";
// import DoctorCard from "./DoctorCard";
// import { getDoctorsForDate } from "../../../services/appointmentsService";
// import { NewAppointmentYourAvailabilityProps } from "../../../types/appointments";
// // import TimeSlots from "../AppointmentScheduler/TimeSlots";

// const YourAvailability: React.FC<NewAppointmentYourAvailabilityProps> = ({
//   yourAvailabilitySelected,
//   isNeurologistSelected,
// }) => {
//   // props & state values
//   const theme = useTheme();
//   const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
//   const [selectedTime, setSelectedTime] = useState<string | null>(null);

//   const [doctorsToDisplay, setDoctorsToDisplay] = useState<any[]>([]);
//   const [doctorsToDisplayLoading, setDoctorsToDisplayLoading] =
//     useState<boolean>(false);

//   const [loading, setLoading] = useState<boolean>(false);

//   const [availableDoctors, setAvailableDoctors] = useState<any[]>([]);

//   const [meetType, setMeetType] = useState<string>("ONLINE");

//   const isDownXl = useMediaQuery(theme.breakpoints.down("xl"));
//   const isUpXl = useMediaQuery(theme.breakpoints.up("xl"));
//   const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
//   const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
//   const isMd = useMediaQuery(theme.breakpoints.only("md"));

//   const loadAvailableDoctors = async (
//     meetingType: string,
//     startTime: string
//   ) => {
//     const params = {
//       meetingType,
//       startTime,
//     };
//     try {
//       const response = await getDoctorsForDate(params);
//       setAvailableDoctors(response?.data || []);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//     }
//   };

//   const getDoctorsForSpecialization = (specialization: string) => {
//     const doctorsForSpecialization = availableDoctors?.filter((doctor) =>
//       doctor?.doctor?.specializations?.includes(specialization)
//     );
//     return doctorsForSpecialization;
//   };

//   const getRequiredDoctors = (isNeurologistSelected: boolean) => {
//     const requiredDoctors = isNeurologistSelected
//       ? getDoctorsForSpecialization("NEUROLOGIST")
//       : getDoctorsForSpecialization("PRIMARY_CARE");
//     return requiredDoctors;
//   };

//   useEffect(() => {
//     if (availableDoctors && availableDoctors.length > 0) {
//       setDoctorsToDisplayLoading(true);
//       const requiredDoctors = getRequiredDoctors(isNeurologistSelected);
//       setDoctorsToDisplay(requiredDoctors);
//       setDoctorsToDisplayLoading(false);
//     }
//   }, [availableDoctors, isNeurologistSelected]);

//   // callbacks & functions
//   const handleMeetTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setMeetType((event.target as HTMLInputElement).value);
//   };

//   const getSpacing = () => {
//     if (isDownXl) {
//       return 6.25;
//     } else if (isUpXl) {
//       return 12.5;
//     } else if (isDownMd) {
//       return 2.5;
//     } else {
//       return 12.5;
//     }
//   };

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         marginRight: "100px",
//         [theme.breakpoints.down("xl")]: { marginRight: "50px" },
//         [theme.breakpoints.up("xl")]: { marginRight: "100px" },
//         [theme.breakpoints.down("md")]: { marginRight: "0px" },
//       }}
//     >
//       <Grid
//         container
//         spacing={getSpacing()}
//         alignItems="baseline"
//         direction={isDownMd || isMd ? "column" : "row"}
//       >
//         <Grid item xs={12} sm={12} md={12} lg={8}>
//           <AppointmentDetails
//             meetType={meetType}
//             handleMeetTypeChange={handleMeetTypeChange}
//           />
//           <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
//             <Calendar
//               selectedDate={selectedDate}
//               onDateSelect={setSelectedDate}
//             />
//             {/* <TimeSlots
//               yourAvailabilitySelected={yourAvailabilitySelected}
//               meetType={meetType}
//               selectedTime={selectedTime}
//               onTimeSelect={setSelectedTime}
//               selectedDate={selectedDate}
//             /> */}
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={12} md={12} lg={4}>
//           <Box sx={{ display: "flex", flexDirection: "column", gap: "29px" }}>
//             <Typography
//               sx={{
//                 fontSize: "18px",
//                 fontWeight: 500,
//                 lineHeight: "120%",
//                 fontFamily: "Inter",
//                 color: "var(--neuro-darkblue-text)",
//               }}
//             >
//               Available Doctors
//             </Typography>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "14px",
//               }}
//             >
//               {!selectedTime && (
//                 <h4>Select a time slot to get available doctors</h4>
//               )}

//               {selectedTime && (loading || doctorsToDisplayLoading) && (
//                 <h4>Loading doctors...</h4>
//               )}

//               {selectedTime &&
//                 !loading &&
//                 availableDoctors &&
//                 availableDoctors.length === 0 && <h4>No available doctors</h4>}

//               {selectedTime &&
//                 !loading &&
//                 !doctorsToDisplayLoading &&
//                 availableDoctors &&
//                 availableDoctors.length > 0 &&
//                 doctorsToDisplay &&
//                 doctorsToDisplay.length === 0 &&
//                 isNeurologistSelected && <h4>No Neurologist available</h4>}

//               {selectedTime &&
//                 !loading &&
//                 availableDoctors &&
//                 availableDoctors.length > 0 &&
//                 doctorsToDisplay &&
//                 doctorsToDisplay.length === 0 &&
//                 !isNeurologistSelected && (
//                   <h4>No General Physician available</h4>
//                 )}

//               {selectedTime &&
//                 !loading &&
//                 availableDoctors &&
//                 availableDoctors?.length > 0 &&
//                 doctorsToDisplay &&
//                 doctorsToDisplay?.length > 0 &&
//                 doctorsToDisplay?.map((doctor, index) => {
//                   return (
//                     <DoctorCard
//                       key={index}
//                       doctor={doctor?.doctor}
//                       slotId={doctor?.slotId}
//                       selectedDate={selectedDate}
//                       yourAvailabilitySelected={yourAvailabilitySelected}
//                     />
//                   );
//                 })}
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default YourAvailability;

const YourAvailability = () => {
  return <div>YourAvailability</div>;
};

export default YourAvailability;
