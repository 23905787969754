import { Box, useMediaQuery } from "@mui/material";

import useAppStore from "../../store/appStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DoctorPersonalInfoForm from "../doctors/DoctorPersonalInfoForm";

const FormPersonalInfoFormPage = () => {
  // props & state values
  const {
    selectedDoctorInList,
    isMenuCollapsed,
    setDrawerOpen,
    setIsMenuCollapsed,
    showDoctorSubmenus,
    setSelectedDoctorInList,
    setShowDoctorSubmenus,
  } = useAppStore();
  const isBelow800 = useMediaQuery("(max-width:50rem)");
  const navigate = useNavigate();

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  // callbacks & functions
  useEffect(() => {
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    if (!showDoctorSubmenus) {
      navigate(`/doctors`);
    }
  }, [showDoctorSubmenus]);

  const hanleClose = () => {
    setSelectedDoctorInList(null);
    setShowDoctorSubmenus(false);
    navigate(`/doctors`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      <DoctorPersonalInfoForm
        doctor={selectedDoctorInList}
        onClose={hanleClose}
      />
    </Box>
  );
};

export default FormPersonalInfoFormPage;
