// import React, { useCallback, useState } from 'react';


// interface FileInputProps {
//   question: {
//     type: string;
//     name: string;
//     title: string;
//   };
//   index: number;
//   toggleRequired: (index: number) => void;
//   handleDuplicateQuestion: (index: number) => void;
//   handleRemoveQuestion: (index: number) => void;
//   updateQuestion: (index: number, updatedQuestion: any) => void;
//   updateQuestionTitle: (updatedTitle: string) => void; // For updating the title
// }

// const FileInput: React.FC<FileInputProps> = ({
//   question,
//   index,
//   toggleRequired,
//   handleDuplicateQuestion,
//   handleRemoveQuestion,
//   updateQuestion,
// }) => {
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const [localQuestion, setLocalQuestion] = useState(question);
//   const [editingName, _] = useState(question.name);
//   const [editingTitle, setEditingTitle] = useState(question.title); // Added state for title
//   const [isRequired, setIsRequired] = useState(false);

//   // Handle file change
//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files.length > 0) {
//       setSelectedFile(e.target.files[0]);
//     }
//   };

//   // Handle removing file
//   const handleRemoveFile = () => {
//     setSelectedFile(null);
//   };

//   const syncWithParent = useCallback(() => {
//     updateQuestion(index, { ...localQuestion, name: editingName, title: editingTitle });  // Include title in update
//   }, [index, localQuestion, editingName, editingTitle, updateQuestion]);


//   // Handle title change
//   const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const newTitle = e.target.value;
//     setEditingTitle(newTitle);
//     setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
//     syncWithParent(); // Sync with parent
//   };

//   // Toggle required state
//   const handleToggleRequired = () => {
//     setIsRequired((prev) => !prev);
//     toggleRequired(index);
//   };

//   return (
//     <div className="question-container">
//       <div className="question-header" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//         {/* Editable question title */}
//         {editingName} <input
//           type="text"
//           value={editingTitle}  // Bind the title input to editingTitle state
//           onChange={handleTitleChange}  // Handle title change
//           className="question-name-input"
//           aria-label="Question Title"
//         />

//         {/* Editable question name */}
       
        
//         {isRequired && <span className="required">*</span>}
//       </div>

//       <div className="file-input-container">
//         <label htmlFor={`file-${index}`} className="file-upload-label">
//           {selectedFile ? (
//             <span className="file-name">{selectedFile.name}</span>
//           ) : (
//             <span className="file-placeholder">
//               Drag and drop a file here or click the button below to select a file to upload.
//             </span>
//           )}
//         </label>
//         <input
//           type="file"
//           id={`file-${index}`}
//           onChange={handleFileChange}
//           className="file-input"
//         />
//         {selectedFile && (
//           <button className="remove-file-button" onClick={handleRemoveFile}>
//             Remove File
//           </button>
//         )}
//       </div>

//       <div className="question-controls">
//         {/* Duplicate Button */}
//         <button
//           className="duplicate-button button-spacing blue-bg"
//           onClick={() => handleDuplicateQuestion(index)}
//         >
//      Duplicate
//         </button>

//         {/* Delete Button */}
//         <button
//           className="delete-button button-spacing red-bg required-button"
//           onClick={() => handleRemoveQuestion(index)}
//         >
//   Delete
//         </button>

//         {/* Required/Optional Toggle Button */}
//         <button
//           className={`required-button ${isRequired ? 'active' : ''}`}
//           onClick={handleToggleRequired}
//         >
        
//           {isRequired ? 'Required' : 'Optional'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FileInput;



import React, { useCallback, useState } from 'react';

interface FileInputProps {
  question: {
    type: string;
    name: string;
    title: string;
    score: number;
    isRequired: boolean;
    showScore:boolean;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  updateQuestionTitle: (updatedTitle: string) => void; // For updating the title
}

const FileInput: React.FC<FileInputProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [localQuestion, setLocalQuestion] = useState(question);
  const [editingTitle, setEditingTitle] = useState(question.title);
  const [editingScore, setEditingScore] = useState(question.score);
  const [isRequired, setIsRequired] = useState(question.isRequired);

  const syncWithParent = useCallback(() => {
    updateQuestion(index, {
      ...localQuestion,
      title: editingTitle,
      score: editingScore,
      isRequired,
    });
  }, [index, localQuestion, editingTitle, editingScore, isRequired, updateQuestion]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setEditingTitle(newTitle);
    setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
    syncWithParent();
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScore = parseInt(e.target.value, 10) || 0;
    setEditingScore(newScore);
    setLocalQuestion((prev) => ({ ...prev, score: newScore }));
    syncWithParent();
  };

  const handleToggleRequired = () => {
    const updatedRequired = !isRequired;
    setIsRequired(updatedRequired);
    setLocalQuestion((prev) => ({ ...prev, isRequired: updatedRequired }));
    syncWithParent();
  };

  return (
    <div className="question-container">
      <div className="question-header">
        <input
          type="text"
          value={editingTitle}
          onChange={handleTitleChange}
          className="question-name-input"
          aria-label="Question Title"
        />
        {isRequired && <span className="required">*</span>}
      </div>

      <div className="file-input-container">
        <label htmlFor={`file-${index}`} className="file-upload-label">
          {selectedFile ? (
            <span className="file-name">{selectedFile.name}</span>
          ) : (
            <span className="file-placeholder">
              Drag and drop a file here or click to select.
            </span>
          )}
        </label>
        <input
          type="file"
          id={`file-${index}`}
          onChange={handleFileChange}
          className="file-input"
        />
        {selectedFile && (
          <button className="remove-file-button" onClick={handleRemoveFile}>
            Remove File
          </button>
        )}
      </div>
      {localQuestion.showScore && (
      <div className="score-input-container">
        <label htmlFor={`score-${index}`} style={{ fontSize: '14px', marginRight: '10px' }}>
          Score:
        </label>
        <input
          type="number"
          id={`score-${index}`}
          value={editingScore}
          onChange={handleScoreChange}
          className="score-input"
          aria-label="Score"
        />
      </div>
      )}

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${isRequired ? 'active' : ''}`}
          onClick={handleToggleRequired}
        >
          {isRequired ? 'Required' : 'Optional'}
        </button>
      </div>
    </div>
  );
};

export default FileInput;
