import React, { useMemo } from "react";
import { Box, Typography, Avatar, Divider, useMediaQuery } from "@mui/material";
import { calculateAgeInYearsOrMonths } from "../../utils/appointments";
import { capitalizeFirstLetter } from "../../utils/common";
import { format, isValid, parseISO, subYears } from "date-fns";
import Button from "../ui/Button";
import { getFormattedDate } from "../../utils/common";
import { CardViewPatientCardProps } from "../../types/patients";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/appStore";

const PatientCard: React.FC<CardViewPatientCardProps> = ({
  patient,
  selectedPatient,
  setSelectedPatient,
}) => {
  // props & state values
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:50rem)");

  const { setDrawerOpen, setIsMenuCollapsed } = useAppStore();

  const styles = {
    wrapperStyles: {
      display: "flex",
      alignItems: "center",
      gap: "0.2rem",
    },
    headingStyles: {
      fontWeight: "var(--neuro-font-weight-semibold)",
      color: "var(--neuro-button-bg-primary)",
      fontSize: "var(--neuro-font-size-smallest-x)",
      lineHeight: "120%",
    },
    subHeadingStyles: {
      fontWeight: "var(--neuro-font-weight-regular)",
      color: "var(--neuro-black-text)",
      fontSize: "var(--neuro-font-size-smallest-x)",
      lineHeight: "120%",
    },
  };

  const patientAge = `${patient?.age && patient?.age !== ""
      ? `${patient?.age} yrs`
      : `${calculateAgeInYearsOrMonths(String(patient?.dateOfBirth) || "")}` ||
      ""
    } ${patient?.age && patient?.age !== ""
      ? `(${getBirthDateFromAge(Number(patient?.age) || 0)})`
      : `(${formatDateString(patient?.dateOfBirth || "")})` || ""
    }`;

  const patientGender = patient?.gender
    ? patient?.gender?.charAt(0).toUpperCase() +
    patient?.gender.slice(1).toLowerCase()
    : "";

  const cardStyles = useMemo(() => {
    const background =
      selectedPatient && selectedPatient.id === patient.id
        ? "var(--neuro-bg-light-grey-secondary)"
        : "var(--neuro-white-text)";
    const borderLeft = "4px solid var(--neuro-button-bg-success)";

    return {
      background,
      borderLeft,
      opacity: 1,
    };
  }, [patient]);

  // callbacks & functions
  function getBirthDateFromAge(age: number): string {
    if (!age) {
      return "";
    }
    const birthDate = subYears(new Date(), age);
    return format(birthDate, "dd-MM-yyyy"); // Change to 'dd-MM-yyyy'
  }

  function formatDateString(
    dateString: string | Date | null | undefined
  ): string {
    console.log("dateString in call", dateString);

    if (!dateString) {
      return "";
    }

    let date: Date;

    if (typeof dateString === "string") {
      // Parse the date string into a Date object
      date = parseISO(dateString);

      // If parsing fails, return an empty string
      if (!isValid(date)) {
        console.warn("Invalid date string provided:", dateString);
        return "";
      }
    } else if (dateString instanceof Date) {
      // Use the date directly if it's a Date object
      date = dateString;
    } else {
      console.warn("Unsupported date input:", dateString);
      return "";
    }

    // Format the date into the desired string format
    return format(date, "dd-MM-yyyy");
  }

  const fullAddess = (city: any, state: any, country: any): string => {
    // `${hospital.city} ${hospital.state} ${hospital.country}`
    let address = "";

    if (city && city !== "") {
      address = `${city},`;
    }

    if (state && state !== "") {
      address = `${address} ${state},`;
    }

    if (country && country !== "") {
      address = `${address} ${country}`;
    }

    return address || "";
  };

  const CardClick = (patient: any) => {
    setSelectedPatient(patient || null);
    if (isMobile) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }
    navigate(`/patients/${patient?.id}/profile`);
  };

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        backgroundColor: cardStyles.background,
        display: "flex",
        gap: "0.75rem",
        padding: "0.625rem 1.188rem 0.625rem 1.188rem",
        marginBottom: "1rem",
        border: "0.063rem solid var(--neuro-secondary_border)",
        borderLeft: cardStyles.borderLeft,
        cursor: "pointer",
      }}
      onClick={() => CardClick(patient)}
    >
      <Box
        sx={{
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          lineHeight: "120%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "1.25rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "0.438rem",
            }}
          >
            <Avatar
              sx={{
                width: "2.875rem",
                height: "2.875rem",
                bgcolor: "var(--neuro-light-grey_border)",
                mt: "0.188rem",
              }}
              src={patient?.user?.profileImageUrl || undefined}
            />
            <Box
              sx={{
                display: "flex",
                marginTop: "0.563rem",
                flexDirection: "column",
                alignItems: "start",
                flexGrow: 1,
                flexBasis: 0,
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  color: "var(--neuro-bg-primary-seagreen)",
                  fontWeight: "var(--neuro-font-weight-bold)",
                  lineHeight: "120%",
                  mb: "0.125rem",
                }}
              >
                {`${capitalizeFirstLetter(patient?.user?.firstName || "") || "-"}`}
              </Typography>

              <Typography
                sx={{
                  color: "var(--neuro-bg-darkblue-primary)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  mb: "0.375rem",
                }}
              >
                {`${patientAge || ""}, ${patientGender || ""}`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  color: "var(--neuro-black-text)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  justifyContent: "space-between",
                  gap: "1.25rem",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Mobile:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {patient?.user?.mobile || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Email:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {patient?.user?.email || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>MRN:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {patient?.mrn || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Reg. Date:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {`${getFormattedDate(patient?.createdAt || "") || "-"}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: "0.75rem" }} />
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            marginTop: "0.75rem",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: "1.5rem",
            paddingLeft: "3.3rem",
          }}
        >
          <Box sx={styles.wrapperStyles}>
            <Typography sx={styles.headingStyles}>Address:</Typography>
            <Typography sx={styles.subHeadingStyles}>
              {`${fullAddess(patient?.city, patient?.state, patient?.country) || "-"}`}
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="success"
            className="success-button"
            onClick={() => CardClick(patient)}
            sx={{
              height: "2.25rem",
            }}
          >
            View
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientCard;
