import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import PatientsProfile from "./PatientsProfile";
import PatientDocuments from "./PatientDocuments";
import PatientForms from "./PatientForms";
import { capitalizeFirstLetter } from "../../utils/common";
import PatientAppointments from "./PatientAppointments";
import PatientAbhaDetails from "./PatientAbhaDetails";
import PatientDetails from "./PatientDetails";
import PatientAuditLogsList from "./patientAuditLogsList";
const PatientTabs = ({
  selectedTab,
  selectedPatient,
  patientId,
  userId,
  patientName,
  patientEmail,
  patientContact,
  showProfileEdit = false,
  from = "",
}: {
  selectedTab: string;
  selectedPatient: any;
  patientId?: string;
  userId?: string;
  patientName?: string;
  patientEmail?: string;
  patientContact?: string;
  from?: string;
  showProfileEdit?: boolean;
}) => {
  // props & state values
  const containerRef = useRef<HTMLDivElement | null>(null);

  // callbacks & functions
  useEffect(() => {
    // Scroll the container to the top when "Profile" tab is selected
    if (selectedTab === "Profile" && containerRef.current) {
      console.log("containerRef.current", containerRef.current);
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedTab]);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          height: from === "meet" ? "calc(100% - 3.75rem)" : "100%",
          maxHeight: from === "meet" ? "calc(100% - 3.75rem)" : "100%",
          flexGrow: 1,
          overflow: "auto",
          padding: "0.625rem",
        }}
      >
        {selectedTab === "Profile" &&
          (from === "meet" ? (
            <PatientDetails id={patientId || ""} from="meet" />
          ) : (
            <PatientsProfile
              patientId={patientId || selectedPatient?.id}
              showProfileEdit={showProfileEdit}
              from={from}
            />
          ))}

        {selectedTab === "Documents" && (
          <PatientDocuments
            from={from}
            patientId={patientId || selectedPatient?.id}
          />
        )}

        {selectedTab === "Forms" && (
          <PatientForms
            patientName={
              patientName ||
              `${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""}`
            }
            patientId={patientId || selectedPatient?.id}
          />
        )}

        {selectedTab === "Appointments" && (
          <PatientAppointments
            patientId={patientId || selectedPatient?.id}
            patientName={
              patientName ||
              `${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""}`
            }
            patientEmail={patientEmail || selectedPatient?.user?.email || ""}
            patientContact={
              patientContact || selectedPatient?.user?.mobile || ""
            }
            from={from}
          />
        )}

        {selectedTab === "ABHA Details" && (
          <PatientAbhaDetails
            userId={userId || selectedPatient?.userId || ""}
          />
        )}

        {selectedTab === "Audit Logs" && (
          <PatientAuditLogsList patientId={patientId || selectedPatient?.id} />
        )}
      </Box>
    </>
  );
};

export default PatientTabs;
