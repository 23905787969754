import { Box, Grid } from "@mui/material";
import NewPatientDetails from "../patients/NewPatientDetails";
import { useParams } from "react-router-dom";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";

const NewDoctorDetails = () => {
    // props & state values
    const { id } = useParams();

    const breadcrumbItems = [
        { label: "Appointments", href: "/appointments" },
        { label: "Doctor Details" },
    ];

    return (
        <Box
            sx={{
                paddingBlock: "1rem",
                paddingInline: "0.875rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                height: "100%",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <CustomBreadcrumbs items={breadcrumbItems} />
            </Box>


            {/* Main Content */}
            <Grid container spacing={2} sx={{ maxHeight: "calc(100% - 1.5rem)", overflowY: "auto" }}>
                <Grid item xs={12} sx={{ height: "100%" }}>
                    <NewPatientDetails
                        id={id || ""}
                        from="personal-info"
                        role="doctor"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewDoctorDetails;
