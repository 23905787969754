import React from "react";
import { Snackbar, Alert } from "@mui/material";
import useAppStore from "../../store/appStore";

const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { snackbarMessage, snackbarOpen, snackbarSeverity, closeSnackbar } =
    useAppStore();

  return (
    <>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarProvider;
