const HospitalIcon = (props: any) => {
  const { width = "2rem", height = "2rem" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.8837 29.6866H1.11628C0.506047 29.6866 0 30.211 0 30.8433C0 31.4756 0.506047 31.9999 1.11628 31.9999H30.8837C31.494 31.9999 32 31.4756 32 30.8433C32 30.211 31.494 29.6866 30.8837 29.6866Z"
        fill="#62B55A"
      />
      <path
        d="M23.4407 0H8.557C4.09189 0 2.60352 2.76048 2.60352 6.16867V30.8434H11.5337V21.4978C11.5337 20.6959 12.1589 20.0482 12.9328 20.0482H19.0798C19.8389 20.0482 20.4789 20.6959 20.4789 21.4978V30.8434H29.4091V6.16867C29.3942 2.76048 27.9058 0 23.4407 0ZM19.7198 11.1807H17.1151V13.8795C17.1151 14.5118 16.6091 15.0361 15.9989 15.0361C15.3886 15.0361 14.8826 14.5118 14.8826 13.8795V11.1807H12.2779C11.6677 11.1807 11.1617 10.6564 11.1617 10.0241C11.1617 9.39181 11.6677 8.86747 12.2779 8.86747H14.8826V6.16867C14.8826 5.53639 15.3886 5.01205 15.9989 5.01205C16.6091 5.01205 17.1151 5.53639 17.1151 6.16867V8.86747H19.7198C20.33 8.86747 20.8361 9.39181 20.8361 10.0241C20.8361 10.6564 20.33 11.1807 19.7198 11.1807Z"
        fill="#62B55A"
      />
    </svg>
  );
};
export default HospitalIcon;
