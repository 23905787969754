import axios from 'axios';
import useAuthStore from '../store/authStore';

const api = axios.create({
  baseURL: "",
});

// In-memory lock variable
let isRefreshing = false;
let failedQueue: any[] = [];

// Function to process the failed requests after token refresh
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Request interceptor to add the token to the request headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { refreshToken, logout } = useAuthStore.getState();

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = 'Bearer ' + token;
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        await refreshToken(); // Assuming this updates the token in localStorage
        const newToken = localStorage.getItem('token');
        processQueue(null, newToken);
        return api(originalRequest); // Retry the original request with the new token
      } catch (refreshError) {
        processQueue(refreshError, null);
        logout(); // If token refresh fails, log the user out
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    if (error.response.status === 403) {
      logout();
    }

    return Promise.reject(error);
  }
);

export default api;
