import React, { useState } from "react";
import Button from "../../ui/Button";
import { EditMeetingInfoButtonProps } from "../../../types/appointments";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Drawer from "../../shared/Drawer";
import Meet from "../Meet/Meet";

const EditMeetingInfoButton: React.FC<EditMeetingInfoButtonProps> = ({
  id,
  loadAppointmentDetails,
}) => {
  // props & state values
  const [showMeetingEditDrawer, setShowMeetingEditDrawer] =
    useState<boolean>(false);

  // callbacks & functions
  const closeButtonHandler = () => {
    if (loadAppointmentDetails) {
      loadAppointmentDetails(id);
    }
    setShowMeetingEditDrawer(false);
  };

  return (
    <>
      <Button
        startIcon={<ModeEditOutlineOutlinedIcon />}
        color="primary"
        variant="contained"
        className="primary-button"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setShowMeetingEditDrawer((prev) => !prev);
        }}
        sx={{
          height: "2.25rem",
        }}
      >
        Edit Meeting Info
      </Button>
      <Drawer
        open={showMeetingEditDrawer}
        onClose={closeButtonHandler}
        headerText="Edit Meeting Info"
      >
        <Meet
          id={id}
          hideConference
          hideGeneralDetails
          hidePatientHeader
          showCloseButton
          closeButtonHandler={closeButtonHandler}
          showLayoutHeader
          height="100%"
        />
      </Drawer>
    </>
  );
};

export default EditMeetingInfoButton;
