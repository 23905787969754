import { create } from "zustand";
import { submitFormResponseApi, assignFormApi } from "../services/formService";

interface FormsState {
  formSubmitted: any;
  formAssigned: any;
  submitLoading: boolean;
  assignLoading: boolean;
  submitError: string | null;
  assignError: string | null;
  submitFormResponse: (patientFormId: string, data: any) => Promise<void>;
  assignForm: (data: any) => Promise<void>;
  cleanUpFormSubmittedState: () => void;
  cleanUpFormAssignedState: () => void;
}

const useFormStore = create<FormsState>((set) => ({
  // Initial states
  formSubmitted: null,
  formAssigned: null,
  submitLoading: false,
  assignLoading: false,
  submitError: null,
  assignError: null,

  // Submit form response action
  submitFormResponse: async (patientFormId: string, data: any) => {
    set({ submitLoading: true, submitError: null });
    try {
      const response = await submitFormResponseApi(patientFormId, data);
      set({ formSubmitted: response.data, submitLoading: false });
    } catch (error) {
      set({
        submitError: "Failed to submit form response",
        submitLoading: false,
      });
    }
  },

  // Assign form action
  assignForm: async (data: any) => {
    set({ assignLoading: true, assignError: null });
    try {
      const response = await assignFormApi(data);
      set({ formAssigned: response.data, assignLoading: false });
    } catch (error) {
      set({
        assignError: "Failed to assign form",
        assignLoading: false,
      });
    }
  },

  // Cleanup form submitted state
  cleanUpFormSubmittedState: () => {
    set({ formSubmitted: null, submitLoading: false, submitError: null });
  },

  // Cleanup form assigned state
  cleanUpFormAssignedState: () => {
    set({ formAssigned: null, assignLoading: false, assignError: null });
  },
}));

export default useFormStore;
