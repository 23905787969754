import React, { useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

interface Validator {
  type: string;
  text: string;
  minValue: number;
  maxValue: number;
}

interface Question {
  type: string;
  name: string;
  title: string;
  inputType: string;
  placeholder: string;
  isRequired: boolean;
  validators: Validator[];
}

interface SurveyComponentProps {
  questionData: Question[];
}

const SurveyComponent1: React.FC<SurveyComponentProps> = ({ questionData }) => {
  const [score, setScore] = useState<number | null>(null);
  const [_, setTotalQuestions] = useState<number>(0);

  const savedData = localStorage.getItem("questionData");
  const questions = savedData ? JSON.parse(savedData) : questionData;

  // Generate the dynamic totalScore expression
  // const totalScoreExpression = questions
  //   .map((_:any, index:any) => `{${index + 1}}`)
  //   .join(" + ");


  const totalScoreExpression = questions
  .map((_:any, index:any) => `({${index + 1}} || 0) * 1`) // Force numeric addition
  .join(" + ");

  const [formname, setFormname] = useState(localStorage.getItem("Headache") || "Headache");
  useEffect(() => {
    // You could use an effect to react to formname changes if necessary
    // For example, if formname changes based on localStorage value:
    const storedFormname = localStorage.getItem("Headache");
    if (storedFormname) setFormname(storedFormname);
  }, []);
    const json = {
      title: formname === "Headache" ? "Headache Impact Assessment" : "Form  Assessment",
      pages: [
        {
          name: formname === "Headache" ? "headache-impact" : "Form  Assessment",
          title: "Form  Assessment",
          elements: [
            ...questions, // Includes all question elements
            {
              type: "expression",
              name: "totalScore", // Dynamic total score
              title: "Your Total Score",
              expression: totalScoreExpression, // Insert the generated expression
              cssClasses: { root: "hidden-score" },
            },
            {
              type: "expression",
              name: "maxScore", // Maximum score
              title: "Max Score",
              expression: `10 * ${questions.length}`, // Calculates max score
              cssClasses: { root: "hidden-score" },
            },
          ],
        },
      ],
    };
     

  const handleComplete = (survey: any) => {
    const totalScore = survey.data.totalScore || 0;
    setScore(totalScore);
    setTotalQuestions(questions.length);
  };

  return (
    <div className="surveyformviewer">
      <Survey json={questionData} onComplete={handleComplete} />
      {score !== null && (
        <div style={{ marginTop: "20px" }}>
          <h3>
            Your Score: {score} out of {10 * questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default SurveyComponent1;




// import React, { useState } from "react";
// import { Survey } from "survey-react-ui";
// import "survey-core/defaultV2.min.css";

// interface Validator {
//   type: string;
//   text: string;
//   minValue?: number;
//   maxValue?: number;
// }

// interface Question {
//   type: string;
//   name: string;
//   title: string;
//   inputType?: string;
//   placeholder?: string;
//   isRequired: boolean;
//   validators?: Validator[];
// }

// interface SurveyComponentProps {
//   questionData: Question[];
// }

// const SurveyComponent1: React.FC<SurveyComponentProps> = ({ questionData }) => {
//   const [score, setScore] = useState<number | null>(null);

//   // Retrieve questions from localStorage or use the provided question data
//   const savedData = localStorage.getItem("questionData");
//   const questions: Question[] = savedData ? JSON.parse(savedData) : questionData;

//   // Organize questions into pages
//   const pages = questions.map((question, index) => ({
//     name: `page${index + 1}`,
//     elements: [
//       {
//         ...question, // Use the question object directly
//         name: `${index + 1}`, // Ensure unique question names
//       },
//     ],
//   }));

//   // Generate the total score expression dynamically
//   const totalScoreExpression = questions
//     .map((_, index) => `{${index + 1}} || 0`) // Default to 0 if no response
//     .join(" + ");

//   const json = {
//     title: "Headache Impact Assessment",
//     pages: [
//       ...pages,
//       {
//         name: "summary",
//         title: "Summary",
//         elements: [
//           {
//             type: "expression",
//             name: "totalScore",
//             title: "Your Total Score",
//             expression: totalScoreExpression, // Dynamically calculate total score
//           },
//           {
//             type: "expression",
//             name: "maxScore",
//             title: "Max Score",
//             expression: `10 * ${questions.length}`, // Max score based on questions
//           },
//         ],
//       },
//     ],
//   };

//   const handleComplete = (survey: any) => {
//     const totalScore = survey.data.totalScore || 0;
//     setScore(totalScore);
//   };

//   return (
//     <div>
//       <Survey json={json} onComplete={handleComplete} />
//       {score !== null && (
//         <div style={{ marginTop: "20px" }}>
//           <h3>
//             Your Score: {score} out of {10 * questions.length}
//           </h3>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SurveyComponent1;
