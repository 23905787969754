import api from "./apiService"; // Assuming `api` is your Axios instance

export const fetchDoctors = (
  type: string,
  start?: string | null,
  end?: string | null,
  search?: string
) => {
  let params;

  if (start && end) {
    params = {
      start,
      end,
      type,
    };
  } else {
    params = {
      type,
    };
  }

  params = {
    ...params,
    search: search || "",
  };

  return api.get(`/api/doctors`, { params });
};

export const fetchDoctorById = (id: string) => {
  return api.get(`/api/doctors/${id}`);
};

export const addDoctor = (doctorData: any) => {
  return api.post(`/api/doctors`, doctorData);
};

export const updateDoctor = (id: string, doctorData: any) => {
  return api.put(`/api/doctors/${id}`, doctorData);
};

export const deleteDoctor = (id: string) => {
  return api.delete(`/api/doctors/${id}`);
};
