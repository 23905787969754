import React, { useState } from "react";
import { Box, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import CardViewItem from "../shared/CardViewItem";
import { getFullHospitalAddress } from "../../utils/appointments";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardViewWrapper from "../shared/CardViewWrapper";
import { CardViewReferralCardProps } from "../../types/referrals";
import useAuthStore from "../../store/authStore";
import {
  isAdminOrClinicAdminOrNetwork,
  isClinicAdminOrAdmin,
  isNetwork,
} from "../../utils/auth";

const CardViewReferralCard: React.FC<CardViewReferralCardProps> = ({
  referral,
  handleEdit,
  handleDelete,
  handleAccept,
  handleCancel,
}) => {
  // props & state values
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userRole } = useAuthStore();

  // callbacks & functions
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditCard = (referral: any) => {
    handleEdit(referral);
    handleMenuClose();
  };

  const handleDeleteCard = (id: string) => {
    handleDelete(id);
    handleMenuClose();
  };

  const handleAcceptCard = (referral: any) => {
    handleAccept(referral);
    handleMenuClose();
  };

  const handleCancelCard = (referral: any) => {
    handleCancel(referral);
    handleMenuClose();
  };

  const showAddress = (
    streetAddress: any,
    city: any,
    state: any,
    zipCode: any,
    country: any
  ) => {
    const address = {
      streetAddress: streetAddress || "",
      city: city || "",
      state: state || "",
      zipCode: zipCode || "",
      country: country || "",
    };
    const fullAddress = getFullHospitalAddress(address);
    return fullAddress || "-";
  };

  return (
    <CardViewWrapper>
      <Grid container spacing={2.5} alignItems="center">
        <Grid
          item
          container
          alignItems="baseline"
          sx={{ lineHeight: "120%" }}
          spacing={5}
          xs={12}
          lg={11.5}
        >
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Patient Name"
              value={`${capitalizeFirstLetter(referral?.firstName || "")}`}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Mobile Number"
              value={referral?.mobile || "-"}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Address"
              value={
                showAddress(
                  referral?.streetAddress,
                  referral?.city,
                  referral?.state,
                  referral?.zipCode,
                  referral?.country
                ) || "-"
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Urgency"
              value={referral?.urgencyOfReferral || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Referred To"
              value={referral?.referralDoctorFirstName || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Referred By"
              value={referral?.doctor?.user?.firstName || "-"}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Reg. Date"
              value={getFormattedDate(referral?.createdAt || "") || "-"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={0.5}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.25rem",
            }}
          >
            {isAdminOrClinicAdminOrNetwork(userRole) &&
              referral?.status === "Pending" && (
                <IconButton
                  onClick={handleMenuOpen}
                  disableRipple
                  size="small"
                  sx={{
                    p: 0,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
                </IconButton>
              )}

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {isNetwork(userRole) && (
                <MenuItem onClick={() => handleEditCard(referral)}>
                  Edit
                </MenuItem>
              )}

              {isNetwork(userRole) && (
                <MenuItem onClick={() => handleDeleteCard(referral?.id)}>
                  Delete
                </MenuItem>
              )}

              {isClinicAdminOrAdmin(userRole) && (
                <MenuItem onClick={() => handleAcceptCard(referral)}>
                  Accept
                </MenuItem>
              )}

              {isClinicAdminOrAdmin(userRole) && (
                <MenuItem onClick={() => handleCancelCard(referral)}>
                  Cancel
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </CardViewWrapper>
  );
};

export default CardViewReferralCard;
