import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Modal from "../ui/Modal";
import Signup from "./Signup"; // Import the Signup component
import { SignUpModalProps } from "../../types/auth";

const SignUpModal: React.FC<SignUpModalProps> = ({
  open,
  width,
  onClose,
  ...props
}) => {
  // Props & state values
  const userId = undefined;

  // need to take from decodedToken
  const emailForSignUp = "";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)

  return (
    <Modal
      open={open}
      closeIcon
      width={isMobile ? "20rem" : width ? width : "33.75rem"} // Adjust width
      height={undefined} // Adjust height
      onClose={onClose}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "1.25rem",
        }}
      >
        <Signup
          emailOrPhone={emailForSignUp || ""}
          userId={userId!}
          onClose={onClose}
        />
      </Box>
    </Modal>
  );
};

export default SignUpModal;
