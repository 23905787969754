import React, { useEffect, useState } from "react";

// third-party imports
import {
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useAppStore from "../../store/appStore";
import { SidebarMenuItemProps } from "../../types/ui";

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  text,
  icon,
  route,
  selected = false,
  sx = {},
  hasSubmenus = false,
  submenus = [],
  initiallyOpen = false,
}) => {
  // props & state values
  const navigate = useNavigate();

  const { setDrawerOpen, drawerOpen } = useAppStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  const [open, setOpen] = useState(initiallyOpen);

  // callbacks & functions
  const handleListItemClick = (route: string) => {
    navigate(`/${route}`);
    if (isMobile) {
      setDrawerOpen(!drawerOpen);
    }
  };

  useEffect(() => {
    if (initiallyOpen && hasSubmenus) {
      setOpen(true);
    }
  }, [initiallyOpen, hasSubmenus]);

  return (
    <>
      <ListItem
        button
        onClick={() => handleListItemClick(route)}
        sx={{
          color: selected
            ? "var(--neuro-white-text)"
            : "var(--neuro-black-text)",
          background: selected
            ? "linear-gradient(90deg, var(--neuro-bg-primary-seagreen) 0%, var(--neuro-button-bg-success) 100%)"
            : "transparent",
          borderRadius: "6.25rem",
          "&:hover": {
            backgroundColor: selected ? "#3CB371" : "#f0f0f0",
          },
          padding: "0.313rem 0.375rem",
          width: "auto",
          marginBottom: "0.563rem",
          marginInline: "0.625rem",
          "&:first-of-type": {
            marginTop: "1rem",
          },
          ...sx,
        }}
      >
        <Box display="flex" alignItems="center">
          <ListItemIcon
            sx={{
              color: selected
                ? "var(--neuro-black-text)"
                : "var(--neuro-white-text)",
              minWidth: "1.875rem",
              height: "1.875rem",
              width: "1.875rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: selected
                ? "var(--neuro-white-text)"
                : "var(--neuro-bg-primary-seagreen)",
              borderRadius: "50%",
              padding: "0.5rem",
              marginRight: selected ? "0.875rem" : "0.75rem",
              "&>svg": {
                width: "1.125rem",
                height: "1.125rem",
              },
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={text}
            sx={{
              "& .MuiTypography-root": {
                fontSize: "var(--neuro-font-size-smaller-plus)",
              },
            }}
          />
        </Box>

        {hasSubmenus && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              if (hasSubmenus) {
                setOpen(!open);
              }
            }}
            sx={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              width: "2.5rem",
              height: "2.5rem",
              // "& >svg": {
              //   width: 20,
              //   height: 20,
              // },
            }}
          >
            {open ? (
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: "var(--neuro-font-size-tiny-plus)",
                  color: "var(--neuro-black-text)",
                }}
              />
            ) : (
              <KeyboardArrowRightIcon
                sx={{
                  fontSize: "var(--neuro-font-size-tiny-plus)",
                  color: "var(--neuro-black-text)",
                }}
              />
            )}
          </IconButton>
        )}
      </ListItem>

      {hasSubmenus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {submenus.map((submenu, subIndex) => (
            <SidebarMenuItem
              key={subIndex}
              text={submenu.text}
              icon={submenu.icon}
              route={submenu.route}
              selected={submenu.selected}
              sx={{ paddingLeft: "2rem" }}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default SidebarMenuItem;
