// third-party imports
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

//project imports
import Button from "../../ui/Button";
import Conference from "./Conference";
import DoctorsNote from "./DoctorsNote";
import Medication from "./Medication";
import PatientHeader from "./PatientHeader";
import { useEffect, useState } from "react";
import useAuthStore from "../../../store/authStore";
import { isClinicAdminOrAdmin, isDoctor, isPatient } from "../../../utils/auth";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import useAppStore from "../../../store/appStore";
import {
  getConsultationSummary,
  postConsultationSummary,
} from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
// import { uploadRecording } from "../../../services/documentsService";
import ConfirmModal from "../../shared/ConfirmModal";
import { ADMIN, CLINIC_ADMIN, DOCTOR, ONLINE } from "../../../utils/constants";
import PatientDocuments from "../../patients/PatientDocuments";
import PatientAppointments from "../../patients/PatientAppointments";
import PatientForms from "../../patients/PatientForms";
import PatientAbhaDetails from "../../patients/PatientAbhaDetails";
import FollowUpBy from "./FollowUpBy";
import Symptomps from "./Symptomps";
import DoctorAdvices from "./DoctorAdvices";
import SubmenuSidebarContent from "../../sidebar/SubmenuSidebarContent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AppointmentConfirmation from "../AppointmentConfirmation";
import SingleReportViewer from "../../documents/view/SingleReportViewer";
import NewReportViewerComponent from "../../documents/view/NewReportViewerComponent";
import NewPatientDetailsPage from "../../patients/NewPatientDetailsPage";

const Meet = ({
  id,
}: {
  id?: string;
}) => {
  // props & state values
  const { appointmentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const appointmentModeForMeet = localStorage.getItem('appointmentModeForMeet');
  const hideConference = localStorage.getItem('hideConference');
  // const hideGeneralDetails = localStorage.getItem('hideGeneralDetails');
  const hidePatientHeader = localStorage.getItem('hidePatientHeader');
  const showCloseButton = localStorage.getItem('showCloseButton');
  const toMeetFrom = localStorage.getItem('toMeetFrom');

  const { medicines } = useAppointmentStore();

  const { isMenuCollapsed, setDrawerOpen, setIsMenuCollapsed, showSnackbar, setShowDoctorMeetSubmenus, setAppointmentIdForMeet, appointmentIdForMeet, showDoctorMeetSubmenus, showPatientDetailsInMeet, setShowSidebar } =
    useAppStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [createSummaryLoading, setCreateSummaryLoading] =
    useState<boolean>(false);

  const isBelow800 = useMediaQuery("(max-width:50rem)");

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  const [summaryDetails, setSummaryDetails] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [hideHeader, setHideHeader] = useState<boolean>(false);

  console.log("summaryDetails", summaryDetails);

  const { userRole } = useAuthStore();

  const showConference = appointmentModeForMeet && appointmentModeForMeet === ONLINE && hideConference !== "true";

  const submenus = showDoctorMeetSubmenus && appointmentIdForMeet
    ? [
      ...(showPatientDetailsInMeet
        ? [
          {
            route: `meet/${appointmentIdForMeet}/profile`,
            text: "Profile",
            icon: <CalendarTodayIcon />,
            selected: !!location.pathname.match(
              /^\/meet\/[a-zA-Z0-9-]+\/profile$/
            ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
        ]
        : []),
      ...(showPatientDetailsInMeet
        ? [
          {
            route: `meet/${appointmentIdForMeet}/documents`,
            text: "Documents",
            icon: <CalendarTodayIcon />,
            selected:
              !!location.pathname.match(
                /^\/meet\/[a-zA-Z0-9-]+\/documents$/
              ) ||
              !!location.pathname.match(
                /^\/meet\/[a-zA-Z0-9-]+\/documents\/view$/
              ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
        ]
        : []),
      ...(showPatientDetailsInMeet
        ? [
          {
            route: `meet/${appointmentIdForMeet}/appointments`,
            text: "Appointments",
            icon: <CalendarTodayIcon />,
            selected:
              !!location.pathname.match(
                /^\/meet\/[a-zA-Z0-9-]+\/appointments$/
              ) ||
              !!location.pathname.match(
                /^\/meet\/[a-zA-Z0-9-]+\/appointments(\/book|\/[a-zA-Z0-9-]+\/details)?$/
              ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
        ]
        : []),
      ...(showPatientDetailsInMeet
        ? [
          {
            route: `meet/${appointmentIdForMeet}/forms`,
            text: "Forms",
            icon: <CalendarTodayIcon />,
            selected: !!location.pathname.match(
              /^\/meet\/[a-zA-Z0-9-]+\/forms$/
            ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
        ]
        : []),
      ...(showPatientDetailsInMeet
        ? [
          {
            route: `meet/${appointmentIdForMeet}/abha-details`,
            text: "ABHA Details",
            icon: <CalendarTodayIcon />,
            selected: !!location.pathname.match(
              /^\/meet\/[a-zA-Z0-9-]+\/abha-details$/
            ),
            roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
          },
        ]
        : []),
      {
        route: `meet/${appointmentIdForMeet}/doctor-notes`,
        text: "Doctor Notes",
        icon: <CalendarTodayIcon />,
        selected: !!location.pathname.match(
          /^\/meet\/[a-zA-Z0-9-]+\/doctor-notes$/
        ),
        roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      },
      {
        route: `meet/${appointmentIdForMeet}/medication`,
        text: "Medication",
        icon: <CalendarTodayIcon />,
        selected: !!location.pathname.match(
          /^\/meet\/[a-zA-Z0-9-]+\/medication$/
        ),
        roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      },
      {
        route: `meet/${appointmentIdForMeet}/advices`,
        text: "Advices",
        icon: <CalendarTodayIcon />,
        selected: !!location.pathname.match(
          /^\/meet\/[a-zA-Z0-9-]+\/advices$/
        ),
        roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      },
      {
        route: `meet/${appointmentIdForMeet}/symptoms`,
        text: "Symptoms",
        icon: <CalendarTodayIcon />,
        selected: !!location.pathname.match(
          /^\/meet\/[a-zA-Z0-9-]+\/symptoms$/
        ),
        roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      },
      {
        route: `meet/${appointmentIdForMeet}/follow-up`,
        text: "Follow Up",
        icon: <CalendarTodayIcon />,
        selected: !!location.pathname.match(
          /^\/meet\/[a-zA-Z0-9-]+\/follow-up$/
        ),
        roles: [ADMIN, CLINIC_ADMIN, DOCTOR],
      },
    ].filter(Boolean)
    : [];


  // callbacks & functions
  useEffect(() => {
    setShowSidebar(false);
    if (!isPatient(userRole)) {
      setShowDoctorMeetSubmenus(true);
    }
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
      setShowSidebar(true);
    };
  }, []);

  useEffect(() => {
    if (hidePatientHeader === "true") {
      setHideHeader(true);
    }
  }, [hidePatientHeader])

  const fetchConsultationSummary = async (id: string) => {
    try {
      const response = await getConsultationSummary(id);
      setSummaryDetails(response?.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const createSummary = async (data: any, saveType?: string) => {
    setCreateSummaryLoading(true);
    showSnackbar("Updating medications, please wait", "info");
    let params;
    if (saveType) {
      params = {
        saveType,
      };
    } else {
      params = undefined;
    }

    try {
      const response = await postConsultationSummary(data, params);
      if (response?.data) {
        showSnackbar("Medications updated successfully", "success");
        // await uploadRecording(
        //   summaryDetails?.patientGeneralDetails?.id || "",
        //   appointmentId || ""
        // );
      }
      setCreateSummaryLoading(false);
    } catch (error) {
      showSnackbar("Could not update medications", "error");
      setCreateSummaryLoading(false);
    }
  };

  const handleConfirm = async () => {
    const navigation = () => {
      if (isClinicAdminOrAdmin(userRole)) {
        navigate(
          `/patients/${summaryDetails?.patientGeneralDetails?.id || ""}/appointments`
        );
      } else {
        navigate("/appointments");
      }
    };

    try {
      const data = {
        appointmentId: appointmentId || id,
      };
      await postConsultationSummary(data);
      navigation();
    } catch (error) {
      navigation();
      console.error("error", error);
    }
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    if (appointmentId || id) {
      setAppointmentIdForMeet(appointmentId || id);
      fetchConsultationSummary(appointmentId || id || "");
    }
  }, [appointmentId, id]);

  const handleCreateSummary = () => {
    const medicineData =
      medicines && medicines.length > 0
        ? medicines
          .filter((medicine) => {
            // Check that all required fields have values
            return (
              medicine.medication.trim() !== "" &&
              medicine.medicationType.trim() !== "" &&
              String(medicine.dosageAmount).trim() !== "" &&
              medicine.dosageUnit.trim() !== "" &&
              String(medicine.durationAmount).trim() !== "" &&
              medicine.durationUnit.trim() !== "" &&
              medicine.frequency.trim() !== ""
            );
          })
          .map((medicine) => ({
            ...medicine,
            dosageAmount: Number(medicine.dosageAmount),
            durationAmount: Number(medicine.durationAmount),
          }))
        : [];

    const data = {
      appointmentId: appointmentId || id,
      medications: medicineData,
    };

    if (appointmentId || id) {
      createSummary(data, "MEDICATIONS");
    }
  };

  const handleCloseMeeting = () => {
    setOpenConfirmModal(true)
  }

  const handleClose = () => {
    if (toMeetFrom === "edit-meeting-info") {
      navigate(`/appointment/${appointmentId || id || ""}`);
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%", // Ensure it takes full viewport height
        overflow: "hidden", // Prevent any potential overflow
      }}
    >
      <Grid container sx={{ height: "100%", overflow: "hidden" }}>
        {showConference && (
          <Grid
            item
            xs={12}
            // md={6}
            md={isDoctor(userRole) ? 5 : 12}
            sx={{ height: "100%", overflow: "hidden" }} // Ensure full height
          >
            <Conference appointmentId={appointmentId || id || ""} />
          </Grid>
        )}

        {isDoctor(userRole) && (
          <Grid
            item
            xs={12}
            md={
              appointmentModeForMeet && appointmentModeForMeet === ONLINE
                ? hideConference === "true"
                  ? 12
                  : 7
                : 12
            }
            sx={{
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "var(--neuro-white-text)",
              borderLeft: "1px solid var(--neuro-black-text)",
            }}
          >
            {loading && <LoadingScreen />}

            {!loading && !summaryDetails && (
              <ContentCenter>
                <Typography className="no-data">Details not found</Typography>
              </ContentCenter>
            )}

            {!loading && summaryDetails && (
              <>
                {hidePatientHeader !== "true" && (
                  <PatientHeader
                    name={
                      capitalizeWordsPreservingSpaces(
                        summaryDetails?.patientGeneralDetails?.name || ""
                      ) || ""
                    }
                    hideHeader={hideHeader}
                    setHideHeader={setHideHeader}
                    date={summaryDetails?.appointmentDate || ""}
                    id={summaryDetails?.appointmentId || ""}
                    showCloseButton={showCloseButton}
                    handleCreateSummary={handleCreateSummary}
                    createSummaryLoading={createSummaryLoading}
                    handleCloseMeeting={handleCloseMeeting}
                    handleClose={handleClose}
                  />
                )}

                {hideHeader &&
                  <Box sx={{
                    height: "3.25rem",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                    paddingBlock: "0.875rem",
                    paddingRight: "2.215rem",
                    borderBottom: "1px solid var(--neuro-secondary_border)",
                    transition: "height 0.3s ease"
                  }}>
                    {location.pathname.includes("medication") && <Button
                      variant="contained"
                      className="primary-button"
                      onClick={handleCreateSummary}
                      disabled={createSummaryLoading}
                      sx={{ height: "2.25rem" }}
                    >
                      Save Prescription
                    </Button>}

                    {showCloseButton !== "true" && (
                      <Button
                        variant="outlined"
                        className="outlined-secondary-button"
                        sx={{ height: "2.25rem" }}
                        onClick={handleCloseMeeting}
                      >
                        Close Meeting
                      </Button>
                    )}

                    {showCloseButton === "true" && (
                      <Button
                        variant="outlined"
                        className="outlined-secondary-button"
                        sx={{ height: "2.25rem" }}
                        onClick={() => {
                          if (toMeetFrom === "edit-meeting-info") {
                            navigate(`/appointment/${appointmentId || id || ""}`);
                          }
                        }}
                      >
                        Close
                      </Button>
                    )}

                    {hidePatientHeader !== "true" &&
                      <Button
                        variant="outlined"
                        className="outlined-secondary-button"
                        onClick={() => {
                          setHideHeader(false);
                        }}
                        sx={{
                          height: "2.25rem"
                        }}
                      >
                        Show Header
                      </Button>}
                  </Box>
                }

                <Box sx={{ display: "flex", height: hideHeader ? "calc(100% - 3.25rem)" : "calc(100% - 7rem)", overflow: "hiiden" }}>
                  <Box sx={{ display: "flex", height: "100%", width: "11rem" }}>
                    <SubmenuSidebarContent
                      menuItems={submenus}
                      isMenuCollapsed={isMenuCollapsed}
                      showIconsOnly={isMenuCollapsed}
                      height="100%"
                    />
                  </Box>

                  <Box
                    sx={{
                      paddingInline: "0.938rem",
                      paddingTop: "1.125rem",
                      paddingBottom: "1.125rem",
                      height: "100%",
                      width: "calc(100% - 11rem)",
                      overflowY: "auto",
                      backgroundColor: "var(--neuro-bg-light-grey-secondary)"
                    }}
                  >
                    {location.pathname.includes("profile") && showPatientDetailsInMeet &&
                      <NewPatientDetailsPage id={summaryDetails?.patientGeneralDetails?.id || ""} from="meet" directionColumn={showConference ? true : false} />
                    }

                    {location.pathname.includes("documents") && showPatientDetailsInMeet &&
                      <>
                        {
                          location.pathname.includes("view") ?
                            <NewReportViewerComponent patientId={summaryDetails?.patientGeneralDetails?.id || ""} from="meet" fullwidth={showConference ? true : false} />
                            :
                            location.pathname.includes("details") ?
                              <SingleReportViewer from="meet"
                              />
                              :
                              <PatientDocuments
                                from="meet"
                                patientId={summaryDetails?.patientGeneralDetails?.id || ""}
                              />
                        }
                      </>
                    }

                    {location.pathname.includes("appointments") && showPatientDetailsInMeet &&
                      <>
                        {
                          location.pathname.includes("details") ?
                            <AppointmentConfirmation from="meet" patientId={summaryDetails?.patientGeneralDetails?.id || ""} />
                            :
                            <PatientAppointments
                              patientId={summaryDetails?.patientGeneralDetails?.id || ""}
                              patientName={
                                `${capitalizeWordsPreservingSpaces(summaryDetails?.patientGeneralDetails?.name || "") || ""
                                }`
                              }
                              patientEmail={summaryDetails?.patientGeneralDetails?.email || ""}
                              patientContact={
                                summaryDetails?.patientGeneralDetails?.mobile || ""
                              }
                              from="meet"
                            />
                        }
                      </>
                    }

                    {location.pathname.includes("forms") && showPatientDetailsInMeet &&
                      <PatientForms
                        patientName={
                          `${capitalizeWordsPreservingSpaces(summaryDetails?.patientGeneralDetails?.name || "") || ""
                          }`
                        }
                        patientId={summaryDetails?.patientGeneralDetails?.id || ""}
                      />
                    }

                    {location.pathname.includes("abha-details") && showPatientDetailsInMeet &&
                      <PatientAbhaDetails
                        userId={summaryDetails?.patientGeneralDetails?.userId || ""}
                        from="meet"
                        directionColumn={showConference ? true : false}
                      />
                    }

                    {location.pathname.includes("doctor-notes") &&
                      <DoctorsNote
                        appointmentId={appointmentId || id || ""}
                        doctorNotes={summaryDetails?.doctorNotes}
                      />
                    }

                    {location.pathname.includes("medication") &&
                      <Medication
                        appointmentId={appointmentId || id || ""}
                        medications={summaryDetails?.prescription}
                      />
                    }

                    {location.pathname.includes("advices") &&
                      <DoctorAdvices
                        appointmentId={appointmentId || id || ""}
                        doctorAdvices={summaryDetails?.Advices}
                      />
                    }

                    {location.pathname.includes("symptoms") &&
                      <Symptomps
                        appointmentId={appointmentId || id || ""}
                        symptompsData={summaryDetails?.symptoms}
                      />
                    }

                    {location.pathname.includes("follow-up") &&
                      <FollowUpBy
                        appointmentId={appointmentId || id || ""}
                        followUpByData={summaryDetails?.followUpBy}
                      />
                    }

                    {/* <Box
                      sx={{
                        marginTop: "5rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {location.pathname.includes("medication") && <Button
                        variant="contained"
                        className="primary-button"
                        onClick={handleCreateSummary}
                        disabled={createSummaryLoading}
                        sx={{ marginRight: "0.875rem" }}
                      >
                        Save Prescription
                      </Button>}

                      {showCloseButton !== "true" && (
                        <Button
                          variant="outlined"
                          className="outlined-secondary-button"
                          onClick={() => setOpenConfirmModal(true)}
                        >
                          Close Meeting
                        </Button>
                      )}

                      {showCloseButton === "true" && (
                        <Button
                          variant="outlined"
                          className="outlined-secondary-button"
                          onClick={() => {
                            if (toMeetFrom === "edit-meeting-info") {
                              navigate(`/appointment/${appointmentId || id || ""}`);
                            }
                          }}
                        >
                          Close
                        </Button>
                      )}

                      <ConfirmModal
                        open={openConfirmModal}
                        description="Are you sure, you want to close the meeting?"
                        handleConfirm={handleConfirm}
                        handleCancel={handleCancel}
                      />
                    </Box> */}
                  </Box>
                </Box>

                <ConfirmModal
                  open={openConfirmModal}
                  description="Are you sure, you want to close the meeting?"
                  handleConfirm={handleConfirm}
                  handleCancel={handleCancel}
                />
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Meet;
