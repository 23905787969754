import { Box, Typography } from "@mui/material";
import WhatsAppChatButton from "../shared/WhatsAppChatButton";
import config from "../../config";
import AssistantIcon from "../svg/AssistantIcon";

const NeedHelpAlert = () => {
    return (
        <Box
            sx={{
                border: "0.063rem solid var(--neuro-secondary_border)",
                borderRadius: "0.625rem",
                padding: "0.625rem",
                height: "100%",
                backgroundColor: "#E5F6FD"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem"
                }}
                onClick={(event) => event.stopPropagation()}
            >
                <Box sx={{ marginBottom: "1rem" }}>
                    <AssistantIcon />
                </Box>

                <Box sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "column"
                }}>
                    <Typography
                        // className="form-heading"
                        sx={{
                            textAlign: "center",
                            fontWeight: "var(--neuro-font-weight-bold)",
                            fontSize: "var(--neuro-font-size-tiny)",
                            fontFamily: "var(--neuro-font-family-roboto-condensed)",
                            lineHeight: "120%",
                        }}
                    >
                        Need Assistance?
                    </Typography>

                    <Typography
                        // className="form-heading"
                        sx={{
                            textAlign: "center",
                            fontSize: "var(--neuro-font-size-medium-smallest)",
                            fontFamily: "var(--neuro-font-family-roboto-condensed)",
                            lineHeight: "120%",
                        }}
                    >
                        You are in the right place
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.5rem",
                                flexDirection: "column",
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontWeight: "var(--neuro-font-weight-bold)",
                                    fontSize: "var(--neuro-font-size-tiny)",
                                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                                    lineHeight: "120%",
                                }}
                            >
                                {`Call Us : +91 ${config.SUPPORT_NUMBER}`}
                            </Typography>

                            <WhatsAppChatButton phoneNumber={config.SUPPORT_NUMBER} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default NeedHelpAlert;
