import { Box } from "@mui/material";
import { ReactElement, ReactNode } from "react";

const CardViewWrapper = ({
  children,
  sx,
  onClick,
}: {
  children: ReactElement | ReactNode;
  sx?: {
    [key: string]: any;
  };
  onClick?: any;
}) => {
  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        backgroundColor: "var(--neuro-white-text)",
        display: "flex",
        width: "100%",
        padding: "1.25rem 1.375rem 1.25rem 1.25rem",
        // gap: "1.25rem",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid var(--neuro-secondary_border)",
        borderLeft: "0.25rem solid var(--neuro-button-bg-success)",
        // minHeight: "7.5rem",
        ...sx,
      }}
      onClick={() => onClick && onClick()}
    >
      {children}
    </Box>
  );
};

export default CardViewWrapper;
