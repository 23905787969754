// AuthRedirect.tsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { AuthRedirectProps } from "../../types/auth";

const AuthRedirect: React.FC<AuthRedirectProps> = ({ element }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuthStore();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return element;
};

export default AuthRedirect;
