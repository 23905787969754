import Modal from "../ui/Modal";
import { Box, Typography } from "@mui/material";
import AssistantIcon from "../svg/AssistantIcon";
import WhatsAppChatButton from "./WhatsAppChatButton";

const HelpSupportModalContent = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: any;
}) => {
  return (
    <Modal open={open} width="31.25rem" closeIcon={true} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <AssistantIcon />
        </Box>
        <Typography
          // className="form-heading"
          sx={{
            textAlign: "center",
            fontWeight: "var(--neuro-font-weight-bold)",
            fontSize: "var(--neuro-font-size-tiny)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            lineHeight: "120%",
            marginBottom: "0.5625rem", // 9px
          }}
        >
          Need Assistance?
        </Typography>

        <Typography
          // className="form-heading"
          sx={{
            textAlign: "center",
            fontSize: "var(--neuro-font-size-medium-smallest)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            lineHeight: "120%",
            marginBottom: "0.5625rem", // 9px
          }}
        >
          You are in the right place
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2.75rem", // 44px
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "var(--neuro-font-weight-bold)",
                fontSize: "var(--neuro-font-size-tiny)",
                fontFamily: "var(--neuro-font-family-roboto-condensed)",
                lineHeight: "120%",
              }}
            >
              Call Us : +91 84119-11234
            </Typography>

            <WhatsAppChatButton phoneNumber="8411911234" />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default HelpSupportModalContent;
