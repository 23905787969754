import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { CommentModalProps } from "../../types/referrals";

const CommentModal: React.FC<CommentModalProps> = ({
  open,
  status,
  onClose,
  onSubmit,
}) => {
  const [comment, setComment] = useState<string>("");

  const handleClose = () => {
    setComment(""); // Reset comment after closing
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="sm">
      <DialogTitle>
        {status === "Accepted" ? "Accept Referral" : "Reject Referral"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Comments(If any)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="comments"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(comment)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentModal;
