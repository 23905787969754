import * as Yup from "yup";

export const doctorValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  specializations: Yup.array()
    .of(Yup.string().required())
    .min(1, "At least one specialization is required"),
  acceptsInPerson: Yup.boolean().required(
    "Please specify if the doctor accepts in-person consultations"
  ),
  onlineConsultationFee: Yup.number()
    .nullable()
    .min(0, "Fee must be a positive number"),
  inPersonConsultationFee: Yup.number()
    .nullable()
    .min(0, "Fee must be a positive number"),
  yearsOfExperience: Yup.number()
    .required("Years of experience is required")
    .min(0, "Years of experience must be a positive number"),
  spokenLanguages: Yup.array()
    .of(Yup.string().trim().required("Each language is required"))
    .min(1, "At least one language is required"),
  // spokenLanguages: Yup.array()
  //   .of(Yup.string().required())
  //   .min(1, "At least one language is required"),
  experienceDetails: Yup.string().nullable(),
  publishedWorks: Yup.string().nullable(),
  qualifications: Yup.string().nullable(),
  status: Yup.boolean().required(),
});

export const doctorPersonalInfoFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  isNetwork: Yup.boolean()
    .oneOf([true, false], "Value must be true or false") // Only validate if checked
    .optional(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  hospitalId: Yup.string().required("Clinic is required"),
  isClinicAdmin: Yup.boolean()
    .oneOf([true, false], "Value must be true or false") // Only validate if checked
    .optional(),
  designation: Yup.string().required("Designation is required"),
  department: Yup.array()
    .of(Yup.string().required())
    .min(1, "At least one department is required")
    .required("Department is required"),
  supportedServices: Yup.array()
    .of(Yup.string().required())
    .min(1, "At least one services is required")
    .required("Supported Services is required"),
});

export const doctorPaymentSettinsFormValidationSchema = Yup.object().shape({
  isPayrollEmployee: Yup.boolean()
    .oneOf([true, false], "Value must be true or false") // Only validate if checked
    .optional(),
  onlineConsultationFee: Yup.string()
    .required("Fee is required")
    .test(
      "is-positive-number",
      "Fee must be a positive number",
      (value) => !isNaN(Number(value)) && Number(value) > 0
    ),
  onlineDoctorFee: Yup.string().when("isPayrollEmployee", {
    is: false,
    then: (schema) =>
      schema
        .required("Fee is required")
        .test(
          "is-positive-number",
          "Fee must be a positive number",
          (value) => !isNaN(Number(value)) && Number(value) > 0
        ),
    otherwise: (schema) => schema.optional(),
  }),
  inPersonConsultationFee: Yup.string()
    .required("Fee is required")
    .test(
      "is-positive-number",
      "Fee must be a positive number",
      (value) => !isNaN(Number(value)) && Number(value) > 0
    ),
  // inPersonConsultationFee: Yup.string().when("acceptsInPerson", {
  //   is: true,
  //   then: (schema) =>
  //     schema
  //       .required("Fee is required")
  //       .test(
  //         "is-positive-number",
  //         "Fee must be a positive number",
  //         (value) => !isNaN(Number(value)) && Number(value) > 0
  //       ),
  //   otherwise: (schema) => schema.optional(),
  // }),
  inPersonDoctorFee: Yup.string().when(
    ["isPayrollEmployee"],
    ([isPayrollEmployee], schema) =>
      !isPayrollEmployee
        ? schema
          .required("Fee is required")
          .test(
            "is-positive-number",
            "Fee must be a positive number",
            (value) => !isNaN(Number(value)) && Number(value) > 0
          )
        : schema.optional()
  ),
});

export const doctorProfessionalProfileFormValidationSchema = Yup.object().shape(
  {
    specializations: Yup.array()
      .of(Yup.string().required())
      .min(1, "At least one specialization is required")
      .required("Specializations are required"),
    yearsOfExperience: Yup.number()
      .transform((value) => (value === "" || !value ? null : value))
      .required("Years of experience is required")
      .min(0, "Years of experience must be a positive number"),
    spokenLanguages: Yup.array()
      .of(Yup.string().trim().required("Each language is required"))
      .min(1, "At least one language is required")
      .required("Spoken languages are required"),
    about: Yup.string().optional(),
    lincense: Yup.string().optional(),
    licensingBoard: Yup.string().optional(),
  }
);

export const doctorAcademicQualificationsFormValidationSchema = Yup.object().shape(
  {
    typeOfGraduation: Yup.string().required("Type Of Graduation is required"),
    qualification: Yup.string().required("Qualification is required"),
    yearOfCompletion: Yup.string().required("Year Of Completion is required").test(
      "is-positive-number",
      "Year must be a positive number",
      (value) => !isNaN(Number(value)) && Number(value) > 0
    ),
    collegeOrUniversity: Yup.string().required("College/Board/University is required"),
  }
);

export const doctorWorkExperienceFormValidationSchema = Yup.object().shape(
  {
    clinicName: Yup.string().required("Hospital is required"),
    designation: Yup.string().required("Designation is required"),
    specialization: Yup.string().required("Specialization is required"),
    location: Yup.string().required("Location is required"),
    clinicDetails: Yup.string().optional(),
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .when("isCurrent", {
        is: true, // When isCurrent is true
        then: (schema) => schema.optional(),
        otherwise: (schema) =>
          schema
            .required("End date is required")
            .test("is-greater", "End date must be after start date", function (value) {
              const { startDate } = this.parent; // Access sibling field 'startDate'
              if (!value || !startDate) {
                return true; // Skip validation if either date is not present
              }
              return value > startDate; // Ensure endDate is greater than startDate
            }),
      }),
    isCurrent: Yup.boolean()
      .oneOf([true, false], "Value must be true or false")
      .optional(),
  }
);

export type DoctorWorkExperienceFormInputs = Yup.InferType<
  typeof doctorWorkExperienceFormValidationSchema
>;

export const doctorLeaveSettingsFormValidationSchema = Yup.object().shape(
  {
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .when("startDate", (startDate, schema) => {
        return startDate
          ? schema.min(
            startDate,
            "End date must be greater than or equal to the start date"
          )
          : schema;
      }),
  }
);

// Session schema for validating each session
export const sessionSchema = Yup.object().shape({
  startTime: Yup.string()
    .matches(/^\d{2}:\d{2}$/, "Start time must be in HH:mm format")
    .required("Start time is required"),
  endTime: Yup.string()
    .matches(/^\d{2}:\d{2}$/, "End time must be in HH:mm format")
    .test(
      "end-time-greater-than-start-time",
      "End time must be greater than start time",
      function (endTime) {
        const { startTime } = this.parent;
        // Convert start and end time to Date objects
        const start = new Date(`1970-01-01T${startTime}:00`);
        const end = new Date(`1970-01-01T${endTime}:00`);
        return end > start; // Validate end time is greater than start time
      }
    )
    .required("End time is required"),
  service: Yup.array()
    .of(Yup.string().required())
    .min(1, "At least one service is required")
    .required("Service is required")
});

// Group schema for validating each availability group
export const groupSchema = Yup.object().shape({
  days: Yup.array().min(1, "Please select at least one day."),
  sessions: Yup.array()
    .of(sessionSchema)
    .min(1, "At least one session is required."),
});

// Main schema for the form
export const availabilitySchema = Yup.object().shape({
  slotDuration: Yup.number().required("Slot duration is required.").positive(),
  //   doctorId: Yup.string().required("Doctor ID is required."),
  availabilityArray: Yup.array()
    .of(groupSchema)
    .min(1, "At least one availability group is required."),
});
