import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "../ui/Checkbox";
import Input from "../ui/Input";


import { AddFormInputs } from "../../types/form";

import { useNavigate, useParams } from "react-router-dom";

import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { inputLabelStyles, selectStyles } from "../../utils/styles";
import { fetchFormRequestsApiFormid, submitFormResponseApisuryve } from "../../services/formService";
import useAppStore from "../../store/appStore";

const FormsCreateViewAndEdit = () => {
  const { id } = useParams();  // Extract the 'id' from the URL
  const navigate = useNavigate();
  const {
    isMenuCollapsed,
    setDrawerOpen,
    setIsMenuCollapsed,
  } = useAppStore();

  const isBelow800 = useMediaQuery("(max-width:50rem)");


  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  useEffect(() => {
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);
  // Log the id to the console if it's available in the URL
  useEffect(() => {
    if (id) {
      console.log("Form ID from URL: ", id);

      const fetchFormData = async () => {

        try {
          const response = await fetchFormRequestsApiFormid(id); // Pass the formId to the API call
          if (response?.data) {
            console.log("Form Data Response:", response.data);

          }
        } catch (error) {

          console.error("Error fetching form data:", error);
        } finally {

        }
      };

      fetchFormData(); // Call the fetch function
    } else {
      console.log("No ID in URL");
    }
  }, [id]);

  // props & state values
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<AddFormInputs>({});


  const {
    setShowFormSubmenus,
    showFormSubmenus

  } = useAppStore();

  // Track local state for dynamic form values
  const [filledBy, setFilledBy] = useState<string>(""); // State for "Form filled by"
  const [disorderId, setDisorderId] = useState<string>(""); // State for disorderId
  const [isScoreBased, setIsScoreBased] = useState<boolean>(false); // State for "Score Based"

  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  const handleFilledByChange = (event: SelectChangeEvent) => {
    setFilledBy(event.target.value);
  };

  const handleDisorderChange = (event: SelectChangeEvent) => {
    setDisorderId(event.target.value);
  };

  const handleScoreBasedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsScoreBased(event.target.checked);
  };

  const [fieldErrors, setFieldErrors] = useState<{
    name?: string;
    zipCode?: string;
    disorderId?: string;
    formType?: string;
    filledBy?: string;
  }>({});
  
  const validateFields = (values: AddFormInputs) => {
    const errors: typeof fieldErrors = {};
  
    if (!values.name) {
      errors.name = "Form Name is required.";
    }
  
    if (!values.zipCode) {
      errors.zipCode = "Zip Code is required.";
    }
  
    if (!disorderId) {
      errors.disorderId = "Please select a disorder.";
    }
  
    if (!values.formType) {
      errors.formType = "Form Type is required.";
    }
  
    if (!filledBy) {
      
      errors.filledBy = "Please specify who filled the form.";
    }
  
    return errors;
  };
  
  const onSubmittable: SubmitHandler<AddFormInputs> = async (values) => {
    const errors = validateFields(values);
  
    if (Object.keys(errors).length > 0) {
      console.error("Validation failed:", errors);
      setFieldErrors(errors); // Set specific field errors
      return; // Stop further execution if validation fails
    }
  
    setFieldErrors({}); // Clear field errors if validation passes
  
    const formData = {
      name: values.name,
      description: values.zipCode,
      disorderId: disorderId,
      type: values.formType,
      isScoreBased: isScoreBased,
      filledBy: filledBy,
    };
  
    try {
      const response = await submitFormResponseApisuryve(formData);
      console.log("Form submitted successfully", response);
      localStorage.setItem("isScoreBased", response.data.data.isScoreBased);
      localStorage.setItem("questions", response.data.data.questions);
      localStorage.setItem("formname", response.data.data.name);
      localStorage.setItem("formid", response.data.data.id);
      setShowFormSubmenus(!showFormSubmenus);
      navigate(`/forms/${response.data.data.id}/create`);
    } catch (error) {
      console.error("Error submitting form", error);
      setErrorMessage("An error occurred while submitting the form.");
    }
  };
  
  const onSubmit: SubmitHandler<AddFormInputs> = async (values) => {
    const errors = validateFields(values);
  
    if (Object.keys(errors).length > 0) {
      console.error("Validation failed:", errors);
      setFieldErrors(errors); // Set specific field errors
      return; // Stop further execution if validation fails
    }
  
    setFieldErrors({}); // Clear field errors if validation passes
  
    const formData = {
      name: values.name,
      description: values.zipCode,
      disorderId: disorderId,
      type: values.formType,
      isScoreBased: isScoreBased,
      filledBy: filledBy,
    };
  
    try {
      const response = await submitFormResponseApisuryve(formData);
      console.log("Form submitted successfully", response);
      localStorage.setItem("isScoreBased", response.data.data.isScoreBased);
      localStorage.setItem("questions", response.data.data.questions);
      localStorage.setItem("formname", response.data.data.name);
      localStorage.setItem("formid", response.data.data.id);
      setShowFormSubmenus(!showFormSubmenus);
      navigate(`/forms`);
    } catch (error) {
      console.error("Error submitting form", error);
      setErrorMessage("An error occurred while submitting the form.");
    }
  };
  
  const onclickCancel = () => {
    setShowFormSubmenus(!showFormSubmenus);
    navigate("/forms");
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.688rem",
        height: "100%",
        width: "100%",
        padding: '1rem'
      }}
    >
      <Box
        component="form"

        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <ComponentHeader title="Add New Form" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              sx={{
                height: "2.25rem",
              }}

              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>

            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}

              onClick={handleSubmit(onSubmittable)}
              
            >
              Save Next
            </Button>
            <Button
              // onClick={onClose}
              variant="contained"
              className="success-button"
              sx={{
                height: "2.25rem",
              }}
              onClick={onclickCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
        <Box
  sx={{
    p: "1.75rem",
    backgroundColor: "var(--neuro-white-text)",
    borderRadius: "0.625rem",
  }}
>
  <Grid container spacing={3.5}>
    {/* Form Name */}
    <Grid item container xs={12} md={4}>
      <Grid item xs={12}>
        <Input
          type="text"
          className="input-primary"
          label="Form Name *"
          {...register("name", { required: "Form Name is required" })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
      </Grid>

      {/* Form Type */}
      <Grid item xs={12}>
        <Controller
          name="formType"
          control={control}
          rules={{ required: "Form Type is required" }}
          render={({ field, fieldState: { error } }) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.375rem",
                width: "100%",
              }}
            >
              <Typography sx={inputLabelStyles}>Form Type *</Typography>
              <Select
                {...field}
                fullWidth
                sx={selectStyles.withGreyBackground}
                margin="dense"
                error={!!error}
              >
                <MenuItem value="GENERIC">GENERIC</MenuItem>
                <MenuItem value="SPECIFIC">SPECIFIC</MenuItem>
                <MenuItem value="COMMON">COMMON</MenuItem>
              </Select>
              {error && (
                <Typography color="error" variant="body2">
                  {error.message}
                </Typography>
              )}
            </Box>
          )}
        />
      </Grid>
    </Grid>

    {/* Disorder and Filled By */}
    <Grid item container xs={12} md={4}>
      <Grid item xs={12}>
        <Controller
          name="disorderId"
          control={control}
          rules={{ required: "Disorder is required" }}
          render={({ field, fieldState: { error } }) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.375rem",
                width: "100%",
              }}
            >
              <Typography sx={inputLabelStyles}>Disorder *</Typography>
              <Select
                {...field}
                fullWidth
                sx={selectStyles.withGreyBackground}
                margin="dense"
                error={!!error}
                value={disorderId}
                onChange={(event) => {
                  field.onChange(event);
                  handleDisorderChange(event);
                }}
              >
                <MenuItem value="cm06i4jj2001842domsdtgro8">Migraine</MenuItem>
                <MenuItem value="cm06i4jj2001842doms">Headache</MenuItem>
              </Select>
              {error && (
                <Typography color="error" variant="body2">
                  {error.message}
                </Typography>
              )}
            </Box>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="filledBy"
          control={control}
          // rules={{ required: "Filled By is required" }}
          render={({ field, fieldState: { error } }) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.375rem",
                width: "100%",
              }}
            >
              <Typography sx={inputLabelStyles}>Form Filled By *</Typography>
              <Select
                {...field}
                fullWidth
                sx={selectStyles.withGreyBackground}
                margin="dense"
                error={!!error}
                value={filledBy}
                onChange={handleFilledByChange}
              >
                <MenuItem value="DOCTOR">Doctor</MenuItem>
                <MenuItem value="PATIENT">Patient</MenuItem>
                <MenuItem value="BOTH">Both</MenuItem>
              </Select>
              {error && (
                <Typography color="error" variant="body2">
                  {error.message}
                </Typography>
              )}
            </Box>
          )}
        />
      </Grid>
    </Grid>

    {/* Description and Score Based */}
    <Grid item container xs={12} md={4}>
      <Grid item xs={12}>
        <Input
          type="text"
          label="Description *"
          {...register("zipCode", { required: "Description is required" })}
          error={!!errors.zipCode}
          helperText={errors.zipCode?.message}
          className="input-secondary-textarea"
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "baseline",
            width: "100%",
          }}
        >
          <Checkbox
            label="Score Based"
            checked={isScoreBased}
            onChange={handleScoreBasedChange}
          />
          {/* <Typography variant="body2">
            {isScoreBased ? "Enabled" : "Disabled"}
          </Typography> */}
        </Box>
      </Grid>
    </Grid>
  </Grid>

  {/* Global Error Message */}
  {errorMessage && (
    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
      {errorMessage}
    </Typography>
  )}
</Box>

      </Box>

      {/* Snackbar for form submission success */}
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar> */}
    </Box>
  );
};

export default FormsCreateViewAndEdit;
