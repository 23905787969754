import React, { useCallback, useEffect } from "react";

// third-party imports
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

// project imports
import useAppointmentStore from "../../../store/appointmentsStore";
import AppointmentDetailsButton from "./AppointmentDetailsButton";
import useAppStore from "../../../store/appStore";
import { OnlineMeetDetailsProps } from "../../../types/appointments";
import { isDoctor } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";

const OnlineMeetDetails: React.FC<OnlineMeetDetailsProps> = ({
  appointmentCode,
  appointmentId,
  status,
  detailsInfo,
  availableSlot,
  patientId,
  prescription,
  paymentStatus,
  appointmentMode,
  patient,
  doctor,
  appointmentDate,
  clinicalNotes,
  reasonForAppointment,
  loadAppointmentDetails,
  usedIn = "",
  isFromMeet = false,
  isFromDashboard = false,
}) => {
  // props & state values
  const navigate = useNavigate();

  const {
    cancelAppointmentInfo,
    errorCancelAppointment,
    cleanUpCancelAppointmentState,
    cleanUpAppointmentState,
  } = useAppointmentStore();

  const { showSnackbar } = useAppStore();
  const { userRole } = useAuthStore();

  // callbacks & functions
  const handleNavigation = useCallback(() => {
    if (patientId) {
      navigate(`/patients/${patientId}/appointments`);
    } else {
      if (usedIn === "dashboard") {
        navigate("/dashboard");
      } else {
        navigate("/appointments");
      }
    }
  }, [navigate, patientId, usedIn]);

  useEffect(() => {
    if (errorCancelAppointment) {
      showSnackbar("Could not cancel appointment, please try again", "error");
      cleanUpCancelAppointmentState();
    }
    if (cancelAppointmentInfo) {
      showSnackbar("Appointment cancelled", "success");
      handleNavigation();
      cleanUpAppointmentState();
      cleanUpCancelAppointmentState();
    }

    // clean up on unmount
    return () => {
      cleanUpAppointmentState();
      cleanUpCancelAppointmentState();
    };
  }, [cancelAppointmentInfo, errorCancelAppointment, handleNavigation]);

  return (
    <Box
      sx={{
        marginTop: "2.563rem",
      }}
    >
      <Typography
        sx={{
          color: "var(--neuro-black-text)",
          lineHeight: "120%",
          fontSize: "var(--neuro-font-size-mini)",
          fontWeight: "var(--neuro-font-weight-bold)",
          fontFamily: "var(--neuro-font-family-inter)",
          marginBottom: "0.875rem",
        }}
      >
        Meeting Details
      </Typography>
      <Typography
        sx={{
          color: "var(--neuro-black-text)",
          lineHeight: "var(--neuro-line-height-micro)",
          fontFamily: "var(--neuro-font-family-inter)",
          marginBottom: "1.563rem",
        }}
      >
        {detailsInfo || ""}
      </Typography>

      {reasonForAppointment && reasonForAppointment.trim()?.length > 0 &&
        <Box
          sx={{
            marginBottom: "1.563rem",
          }}
        >
          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
              fontSize: "var(--neuro-font-size-mini)",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontFamily: "var(--neuro-font-family-inter)",
              marginBottom: "0.875rem",
            }}
          >
            Reason For Appointment
          </Typography>

          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              lineHeight: "var(--neuro-line-height-micro)",
              fontFamily: "var(--neuro-font-family-inter)",
              marginBottom: "0.5rem",
            }}
          >
            {reasonForAppointment || ""}
          </Typography>
        </Box>
      }

      {clinicalNotes && clinicalNotes.length > 0 && isDoctor(userRole) && (
        <Box
          sx={{
            marginBottom: "1.563rem",
          }}
        >
          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
              fontSize: "var(--neuro-font-size-mini)",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontFamily: "var(--neuro-font-family-inter)",
              marginBottom: "0.875rem",
            }}
          >
            Clinical Notes
          </Typography>

          {clinicalNotes?.map((note) => (
            <Typography
              sx={{
                color: "var(--neuro-black-text)",
                lineHeight: "var(--neuro-line-height-micro)",
                fontFamily: "var(--neuro-font-family-inter)",
                marginBottom: "0.5rem",
              }}
            >
              {note || ""}
            </Typography>
          ))}
        </Box>
      )}

      <AppointmentDetailsButton
        appointmentCode={appointmentCode}
        id={appointmentId}
        status={status}
        availableSlot={availableSlot}
        meetType="Online"
        prescription={prescription}
        paymentStatus={paymentStatus}
        patientId={patientId}
        patient={patient}
        appointmentMode={appointmentMode}
        doctor={doctor}
        appointmentDate={appointmentDate}
        isFromMeet={isFromMeet}
        loadAppointmentDetails={loadAppointmentDetails}
        isFromDashboard={isFromDashboard}
      />
    </Box>
  );
};

export default OnlineMeetDetails;
