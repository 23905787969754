import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  //  Tabs, Tab,
  Grid,
  useMediaQuery,
} from "@mui/material";
// import CustomTabPanel from "../documents/view/CustomTabPanel";
import { getDoctorProfileApi } from "../../../services/profileService";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { capitalizeFirstLetter } from "../../../utils/common";

const GreenBorderBox = ({
  children,
}: {
  children: React.ReactElement | React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        borderLeft: "0.375rem solid var(--neuro-button-bg-success)",
        minHeight: "1.625rem",
        paddingLeft: "0.438rem",
      }}
    >
      {children}
    </Box>
  );
};

function AdminDetails({ id, from = "" }: { id: string; from?: string }) {
  // props & stat values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const styles = {
    textStyles: {
      fontFamily: "var(--neuro-font-family-roboto)",
      lineHeight: "120%",
      width: "100%",
      wordWrap: "break-word",
    },
  };

  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // callbacks & functions
  const getProfileDetails = async (id: string) => {
    try {
      const response = await getDoctorProfileApi(id);
      setDetails(response.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProfileDetails(id);
    }
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "var(--neuro-bg-light-grey-secondary)",
        width: "100%",
        height: "100%",
      }}
    >
      {loading && <LoadingScreen />}

      {!loading && !details && (
        <ContentCenter>
          <Typography className="no-data">No details found</Typography>
        </ContentCenter>
      )}

      {!loading && details && (
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.313rem",
            padding: "1.25rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: isMobile
                ? 0
                : from === "meet"
                  ? "1.25rem"
                  : from === "admin"
                    ? "5.938rem"
                    : "5.938rem",
              gap: "1.813rem",
              alignItems: "center",
              marginBottom: isMobile ? "1.5rem" : 0,
            }}
          >
            <Avatar
              sx={{
                width:
                  from === "meet"
                    ? "6.25rem"
                    : from === "admin"
                      ? "11.875rem"
                      : "11.875rem",
                height:
                  from === "meet"
                    ? "6.25rem"
                    : from === "admin"
                      ? "11.875rem"
                      : "11.875rem",
                border: "2px solid var(--neuro-button-bg-success)",
                backgroundColor: "var(--neuro-secondary_border)",
              }}
              src={details?.user?.profileImageUrl || undefined}
            />
          </Box>

          <Grid container spacing={3}>
            <Grid item container spacing={3.5} xs={12} sm={6}>
              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Full Name:</span>{" "}
                    {capitalizeFirstLetter(details?.user?.firstName || "-")}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Mobile No:</span>{" "}
                    {details?.user?.mobile || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Email:</span>{" "}
                    {details?.user?.email || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default AdminDetails;
