import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "../../ui/Button";
import Payment from "./Payment";
import { AppointmentCheckoutPaymentSummaryProps } from "../../../types/appointments";

const PaymentSummary: React.FC<AppointmentCheckoutPaymentSummaryProps> = ({
  selectedDoctor,
  appointmentType,
  appointmentDate,
  availableSlotId,
}) => {
  // props & state values
  const charges =
    appointmentType === "ONLINE"
      ? selectedDoctor?.onlineConsultationFee || null
      : selectedDoctor?.inPersonConsultationFee || null;

  const gstValue = selectedDoctor?.taxes?.gst.replace("%", "") || null;

  const gstAmount =
    charges && gstValue ? (Number(charges) * Number(gstValue)) / 100 : null;

  const totalAmount = gstAmount ? Number(charges) + gstAmount : null;

  return (
    <Box
      sx={{
        width: "35%",
        marginTop: "75px",
        "@media (max-width: 991px)": {
          width: "100%",
          marginTop: "0px",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "20px",
          border: "1px solid var(--neuro-secondary_border)",
          backgroundColor: "var(--neuro-white-text)",
          padding: "20px 30px 130px 25px",
          "@media (max-width: 991px)": {
            padding: "20px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily:
              "Roboto Condensed, -apple-system, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "25px",
            marginBottom: "25px",
          }}
        >
          Payment Summary
        </Typography>
        <Box
          sx={{
            borderTop: "1px solid var(--neuro-secondary_border)",
            paddingTop: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontFamily: "Inter",
                lineHeight: "50px",
                color: "var(--neuro-black-text)",
              }}
            >
              Appointment charge
            </Typography>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Inter", lineHeight: "50px" }}
            >
              {charges ? charges : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontFamily: "Inter",
                lineHeight: "50px",
                color: "var(--neuro-black-text)",
              }}
            >
              GST {selectedDoctor?.taxes?.gst}
            </Typography>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Inter", lineHeight: "50px" }}
            >
              {gstAmount ? gstAmount : "-"}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontFamily: "Inter",
                lineHeight: "50px",
                color: "var(--neuro-black-text)",
              }}
            >
              Hospital Credits (Balance $0)
            </Typography>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Inter", lineHeight: "50px" }}
            >
              {hospitalCredits}
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              borderTop: "1px solid var(--neuro-secondary_border)",
              paddingTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontFamily: "Inter",
                lineHeight: "50px",
                color: "var(--neuro-black-text)",
              }}
            >
              Total (Incl. of all taxes)
            </Typography>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Inter", lineHeight: "50px" }}
            >
              {totalAmount ? totalAmount : "-"}
            </Typography>
          </Box>
        </Box>
        {/* <Button
          fullWidth
          sx={{ marginTop: "40px", fontSize: "20px", height: "48px" }}
          className="warning-button"
          variant="contained"
        >
          Pay Now
        </Button> */}
        <Payment
          appointmentDate={appointmentDate}
          availableSlotId={availableSlotId}
          appointmentMode={appointmentType}
          amount={totalAmount}
          selectedDoctor={selectedDoctor}
        />
        {appointmentType === "IN_PERSON" && (
          <Button
            fullWidth
            sx={{ marginTop: "16px", fontSize: "20px", height: "48px" }}
            className="primary-button"
            disabled={totalAmount === null ? true : false}
            variant="contained"
          >
            Pay Later & Confirm
          </Button>
        )}
        <Button
          fullWidth
          sx={{ marginTop: "20px", fontSize: "20px", height: "48px" }}
          className="outlined-secondary-button"
          variant="contained"
        >
          Cancel Appointment
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSummary;
