import * as yup from "yup";

export const loginSchema = yup.object().shape({
  // emailOrPhone: yup
  //   .string()
  //   .required("Email is required")
  //   .email("Must be a valid email"),
  emailOrPhone: yup
    .string()
    // .required("Email or Phone or Aadhar or ABHA is required")
    .required("Email or Aadhar or ABHA is required")
    .test(
      "is-valid-email-or-phone-or-aadhar-or-abha",
      "Must be a valid Email or Aadhar or ABHA",
      (value) => {
        if (!value) return false;

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const phonePattern = /^[0-9]{10}$/; // Assuming 10 digit phone numbers
        const aadharPattern = /^[0-9]{12}$/; // Example Aadhar pattern - 12 digits
        const abhaPattern = /^[0-9]{14}$/; // Example Abha pattern

        return (
          // phonePattern.test(value) ||
          emailPattern.test(value) ||
          aadharPattern.test(value) ||
          abhaPattern.test(value)
        );
      }
    ),
  whatsappUpdates: yup
    .boolean()
    .oneOf([true], "Please accept terms and conditions")
    .required("Please accept terms and conditions"),
});

export const signUpSchema = yup.object().shape({
  // firstName: yup.string().required("First name is required"),
  // lastName: yup.string().required("Last name is required"),
  fullName: yup.string().required("Full name is required"),
  gender: yup.string().required("Gender is required"),
  age: yup
    .string()
    .nullable()
    .matches(/^\d*$/, "Age must be a number")
    .test(
      "age-or-dob",
      "Either Date of Birth or Age is required",
      function (value) {
        const { dateOfBirth } = this.parent;
        return !!value || !!dateOfBirth;
      }
    ),
  dateOfBirth: yup
    .date()
    .nullable()
    .test(
      "dob-or-age",
      "Either Date of Birth or Age is required",
      function (value) {
        const { age } = this.parent;
        return !!value || !!age;
      }
    ),
  mobile: yup
    .string()
    .required("Phone number is required")
    .matches(
      /^(\+?\d{1,4}[\s-]?)?(?!0+$)\d{10}$/,
      "Phone number must be a valid 10-digit number"
    ),
  reasonsForAppointments: yup.string().optional(),
});
