import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import { Box, CircularProgress, Typography } from "@mui/material";
import config from "../../../config";
import {
  Coordinates,
  MapComponentWithAddressProps,
} from "../../../types/appointments";

const containerStyle = {
  height: "22.5rem",
  borderRadius: "0.938rem",
};

const MapComponentWithAddress: React.FC<MapComponentWithAddressProps> = ({
  address,
}) => {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      config.GOOGLE_MAPS_API_KEY || "AIzaSyC1RyEK7PkeuJkR-Oskd54kQfCMpoTFsms",
  });

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const key =
          config.GOOGLE_MAPS_API_KEY ||
          "AIzaSyC1RyEK7PkeuJkR-Oskd54kQfCMpoTFsms";
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: address,
              key: key,
            },
          }
        );

        if (response.data.status === "OK") {
          const location = response.data.results[0].geometry.location;
          setCoordinates(location);
        } else {
          setError("Unable to fetch location data.");
          console.error("Geocode error:", response);
        }
      } catch (err) {
        setError("An error occurred while fetching location data.");
        console.error("Error fetching coordinates:", err);
      }
    };

    fetchCoordinates();
  }, [address]);

  if (loadError) {
    return <Typography>Error loading maps</Typography>;
  }

  if (!isLoaded) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "22.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <>
      {coordinates && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={15}
        >
          <Marker position={coordinates} onClick={() => setInfoOpen(true)} />
          {infoOpen && (
            <InfoWindow
              position={coordinates}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div>
                <Typography variant="body1">{address}</Typography>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default MapComponentWithAddress;
