import React, { useEffect, useState } from "react";

// third-party imports
import { Box, Grid } from "@mui/material";

// project imports
import AllAppointments from "../appointments/Appointments/AllAppointments";
import { loadDoctorDashboard } from "../../services/dashboardService";
import AppointmentsIcon from "../svg/AppointmentsIcon";
import DashboardCard from "./DashboardCard";
import { getStartAndEndDateTimesForRange } from "../../utils/common";
import useAppStore from "../../store/appStore";

interface DoctorDashboardProps {
  range: string;
}

interface Statistics {
  [key: string]: number;
  patientsCount: number;
  clinicsCount: number;
  referralsCount: number;
  bookingAppointmentsCount: number;
  onGoingAppointmentsCount: number;
}

const DoctorDashboard: React.FC<DoctorDashboardProps> = ({ range }) => {
  // props & state values
  const [statistics, setStatistics] = useState<Statistics>({
    doctorsCount: 0,
    patientsCount: 0,
    clinicsCount: 0,
    referralsCount: 0,
    bookingAppointmentsCount: 0,
    onGoingAppointmentsCount: 0,
  });

  const { dashboardFilter, setFilter } = useAppStore();

  const cardsData = [
    {
      name: "bookingAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Booked Appointments",
      navigateTo: `/appointments`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Booked",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Booked",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "completedAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Completed Appointments",
      navigateTo: `/appointments`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Completed",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Completed",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "cancelledAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Cancelled Appointments",
      navigateTo: `/appointments`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Cancelled",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Cancelled",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
  ];

  // callbacks & functions
  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        if (dashboardFilter.customStartDate && dashboardFilter.customEndDate) {
          const response = await loadDoctorDashboard(
            dashboardFilter.customStartDate,
            dashboardFilter.customEndDate
          );
          const data = response.data;
          setStatistics(data);
        } else {
          if (range === "TODAY") {
            const { start, end } = getStartAndEndDateTimesForRange(range);
            const response = await loadDoctorDashboard(start, end);
            const data = response.data;
            setStatistics(data);
          }
        }
      } catch (error) {
        console.error("error in dashboard api", error);
      }
    };

    fetchDashboard();
  }, [range, dashboardFilter.customStartDate, dashboardFilter.customEndDate]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container spacing={2.75}>
          <Grid item xs={12}>
            <Grid container spacing={2.5}>
              {cardsData?.map((data, i) => (
                <Grid item xs={12} md={4} key={i}>
                  <DashboardCard
                    icon={data?.icon}
                    count={statistics ? statistics[data?.name] || 0 : 0}
                    total={0}
                    text={data?.text}
                    navigateTo={data?.navigateTo}
                    action={data?.action}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2.5} sx={{ height: "100%" }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2.5}>
                    <Grid item container xs={12} spacing={2.5}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            border:
                              "0.063rem solid var(--neuro-secondary_border)",
                            borderRadius: "0.625rem",
                            padding: "0.625rem",
                            height: "100%",
                          }}
                        >
                          <AllAppointments from="dashboard" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DoctorDashboard;
