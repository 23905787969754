import api from "./apiService"; // Assuming `api` is your Axios instance

// Function to fetch all referrals
export const fetchReferrals = (
  filterBy: string,
  start?: string | null,
  end?: string | null,
  search?: string
) => {
  let params;
  if (start && end) {
    params = {
      start,
      end,
    };
  }
  params = {
    ...params,
    filterBy: filterBy.toUpperCase(),
    search,
  };
  return api.get(`/api/referrals`, { params });
};

// Function to fetch a referral by ID
export const fetchReferralById = (id: string) => {
  return api.get(`/api/referrals/${id}`);
};

// Function to add a new referral
export const addReferral = (referralData: any) => {
  return api.post(`/api/referrals`, referralData);
};

// Function to update an existing referral
export const updateReferral = (id: string, referralData: any) => {
  return api.put(`/api/referrals/${id}`, referralData);
};

// Function to delete a referral by ID
export const deleteReferral = (id: string) => {
  return api.delete(`/api/referrals/${id}`);
};

export const updateReferralStatus = (
  id: string,
  status: "Accepted" | "Rejected",
  comments: string | null
) => {
  return api.put(`/api/referrals/${id}/status`, { status, comments });
};
