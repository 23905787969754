import { Box, Grid, IconButton, Typography } from "@mui/material"
import ComponentHeader from "../shared/ComponentHeader"
import LoadingScreen from "../shared/LoadingScreen"
import ContentCenter from "../shared/ContentCenter"
import { PaymentsListProps } from "../../types/payments"
import NewPaymentCard from "./NewPaymentCard"
import ComponentFilter from "../shared/ComponentFilter"
import CardTableToggle from "../shared/CardTableToggle"
import { useCallback, useState } from "react"
import { getFormattedDate } from "../../utils/common"
import { TableWrapper } from "../shared/TableWrapper"
import Tooltip from "../ui/Tooltip"
import DownloadSvgIcon from "../svg/DownloadIcon";
import useAppStore from "../../store/appStore"
import { downloadPayment } from "../../services/paymentService"
import CurrencyIcon from "../shared/CurrencyIcon"
import config from "../../config"

const PaymentsList: React.FC<PaymentsListProps> = ({
    payments,
    loading,
    patientId,
}) => {
    // const props & state values
    // Initialize view from localStorage or default to "table"
    const [view, setView] = useState(
        () => localStorage.getItem("payemntsView") || config.DEFAULT_PAYMENTS_VIEW
    );

    const [selectedPayment, setSelectedPayment] = useState<any>(null);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const { showSnackbar } = useAppStore();

    // callbacks & functions
    const handleToggleView = useCallback((newView: string) => {
        setView((prevView) => {
            const updatedView = prevView !== newView ? newView : prevView;
            localStorage.setItem("payemntsView", updatedView); // Store updated view in localStorage
            return updatedView;
        });
    }, []);

    const downloadPrescriptionHandler = async (id: string) => {
        if (!id || id === "") {
            showSnackbar(
                "Could not download invoice, please try again",
                "error"
            );
            return;
        }
        setDownloadLoading(true);
        showSnackbar("Invoice is downloading, please wait", "info");
        try {
            const response = await downloadPayment(id);
            const contentDisposition = response?.headers["content-disposition"];
            const blobData = response?.data;
            const fileName = contentDisposition;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blobData);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
            showSnackbar("Invoice is downloaded", "success");
        } catch (error) {
            showSnackbar(
                "Could not download invoice, please try again",
                "error"
            );
        } finally {
            setDownloadLoading(false);
        }
    };


    const columns = [
        {
            id: "razorpayPaymentId",
            label: "Payment Id",
            sortable: true,
            render: (_: any, row: any) => `${row?.razorpayPaymentId || row?.id || "-"}`,
        },
        {
            id: "razorpayOrderId",
            label: "Order Id",
            sortable: true,
            render: (value: any) => `${value || "-"}`,
        },
        {
            id: "amount",
            label: "Amount",
            sortable: true,
            render: (_: any, row: any) => (
                row?.amount ?
                    <Box
                        sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <CurrencyIcon currency={row?.currency} />
                        <Typography>{row?.amount}</Typography>
                    </Box>
                    : "-"
            ),
        },
        {
            id: "method",
            label: "Method",
            sortable: false,
            render: (value: any) => `${value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : "-"}`,
        },
        {
            id: "createdAt",
            label: "Payment Date",
            sortable: true,
            render: (value: any) => `${value ? getFormattedDate(value || "") : "-"}`,
        },
        {
            id: "appointment.appointmentDate",
            label: "Appointment Date",
            sortable: true,
            render: (_: any, row: any) => `${getFormattedDate(row?.appointment?.appointmentDate || "")}`,
            sortComparator: (a: any, b: any) => new Date(a.appointment.appointmentDate).getTime() - new Date(b.appointment.appointmentDate).getTime(),
        },
        {
            id: "status",
            label: "Status",
            sortable: true,
            render: (value: any) => `${value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : "-"}`,
        },
        {
            id: "actions",
            label: "Actions",
            render: (_: any, row: any) => (
                <Box
                    sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                    }}
                >
                    {downloadLoading && selectedPayment && selectedPayment?.appointmentId === row?.appointmentId ?
                        <IconButton
                            disabled
                            onClick={() => {
                                setSelectedPayment(row);
                                downloadPrescriptionHandler(row?.appointmentId || "");
                            }}
                            sx={{
                                "& > svg": {
                                    opacity: 0.5,
                                },
                            }}
                        >
                            <DownloadSvgIcon />
                        </IconButton>
                        :
                        <Tooltip title="Download" placement="top">
                            <IconButton
                                onClick={() => {
                                    setSelectedPayment(row);
                                    downloadPrescriptionHandler(row?.appointmentId || "");
                                }}
                            >
                                <DownloadSvgIcon />
                            </IconButton>
                        </Tooltip>
                    }

                </Box>
            ),
        },
    ]

    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                height: "100%",
            }}
        >
            {!patientId && <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <ComponentHeader title="Payments" />
            </Box>}

            <Box sx={{ marginBottom: "1rem" }}>
                <ComponentFilter
                    isEmpty
                    hideSearchFilter
                    hideStatusFilter
                    hideRangeFilter
                    viewComponent={
                        <CardTableToggle view={view} setView={handleToggleView} />
                    }
                />
            </Box>

            {loading ? (
                <Box
                    sx={{
                        height: patientId ? "calc(100% - 5rem)" : "calc(100% - 1.5rem)",
                    }}
                >
                    <LoadingScreen />
                </Box>
            ) : payments && payments.length > 0 ? (
                <Box sx={{ height: patientId ? "calc(100% - 5rem)" : "calc(100% - 7.5rem)", overflowY: "hidden" }}>
                    {view === "card" ?
                        <Grid container spacing={2}
                            sx={{
                                height: "calc(100% + 1rem)",
                                overflowY: "auto",
                            }}
                        >
                            {payments?.map((payment) => (
                                <Grid item xs={12} key={payment.id}>
                                    <NewPaymentCard
                                        payment={payment}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        :
                        <TableWrapper
                            columns={columns}
                            rows={payments}
                            stickyHeader={true}
                            maxHeight="100%"
                        />
                    }
                </Box>

            ) : (
                <Box
                    sx={{
                        height: patientId ? "calc(100% - 5rem)" : "calc(100% - 1.5rem)",
                    }}
                >
                    <ContentCenter>
                        <Typography className="no-data">No payments found</Typography>
                    </ContentCenter>
                </Box>
            )}

        </Box>
    )
}

export default PaymentsList;