import React from "react";

// third-party imports
import { Box, CssBaseline, useMediaQuery } from "@mui/material";

// project imports
import Header from "./Header";
import NewSidebar from "../sidebar/NewSidebar";
import { Outlet } from "react-router-dom";
import useAppStore from "../../store/appStore";
import LoadingScreen from "../shared/LoadingScreen";

const MainLayout: React.FC<{
  children?: React.ReactNode;
  backgroundColor?: string;
}> = ({ backgroundColor }) => {
  // props & state values
  const {
    // isMenuCollapsed,
    showHeader,
    showSidebar,
    isLoading,
  } = useAppStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: backgroundColor
          ? backgroundColor
          : "var(--neuro-bg-light-grey-secondary)",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      {showHeader && <Header />}

      <Box
        sx={{
          display: "flex",
          backgroundColor: backgroundColor
            ? backgroundColor
            : "var(--neuro-bg-light-grey-secondary)",
          overflow: "hidden",
          maxWidth: "1200px",
          width: "100%",
          marginInline: "auto",
          // borderRight: "1px solid var(--neuro-secondary_border)",
          height: isMobile
            ? showHeader
              ? "calc(100% - 3.75rem)"
              : "100%"
            : showHeader
              ? "calc(100% - 5rem)"
              : "100%",
          "@media (max-width: 85.375rem)": {
            width: "100%",
            maxWidth:
              "100%" /* Ensure it takes the entire width of the screen */,
            marginInline: 0 /* Remove the auto margin */,
          },
        }}
      >
        <CssBaseline />
        {showSidebar && <NewSidebar />}

        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
              position: "relative",
            }}
          >
            <Outlet />
            {isLoading && (
              <LoadingScreen
                sx={{ position: "absolue", top: "50%", left: "50%" }}
              />
            )}
            {/* {children} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
