import React from "react";
import { Box, Typography, Divider, Badge } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

interface DashboardCardcopyProps {
  icon: React.ReactNode;
  text: string;
  count: number | string;
  total?: number;
  navigateTo: string;
  action: (status: "Accepted" | "Rejected") => void; // action now expects a status
  subtext: string;
  subtext2: string;
  subtextcount: string | number;
  subtextcount2: string | number;
}

const DashboardCardcopy: React.FC<DashboardCardcopyProps> = ({
  icon,
  text,
  // count,
  total,
  navigateTo,
  action,
  subtext,
  subtext2,
  subtextcount,
  subtextcount2,
}) => {
  const navigate = useNavigate();

  console.log(
    "subtext",
    "subtext2",
    "subtextcount",
    "subtextcount2",
    subtext,
    subtext2,
    subtextcount,
    subtextcount2
  );

  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor: "#fff",
        maxWidth: "312px",
        padding: "1.1rem",
        fontFamily:
          '"Roboto Condensed", -apple-system, Roboto, Helvetica, sans-serif',
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* Title and Count */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              color: "var(--Secondary-Neuro_black, #000)",
            }}
          >
            {text}
          </Typography>
          <Typography
            variant="h3"
            sx={{ color: "#000", fontSize: "40px", fontWeight: 300 }}
            onClick={() => {
              if (total !== 0) {
                // action();
                navigate(navigateTo);
              }
            }}
          >
            {total}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: "0.2rem" }}>{icon}</Box>

        {/* Conditional Badge */}
        <Badge
          badgeContent={`New - ${total}`}
          onClick={() => {
            if (total !== 0) {
              // action();
              navigate(navigateTo);
            }
          }}
          sx={{
            visibility: text === "Total Referrals" ? "visible" : "hidden",
            "& .MuiBadge-badge": {
              backgroundColor: "#62b55a",
              color: "#fff",
              fontSize: "14px",
              fontWeight: 400,
              padding: "4px 13px",
              borderRadius: "6px",
              position: "relative",
              right: "-10rem",
              top: "-1rem",
            },
          }}
        />

        <Divider sx={{ my: "8px 0 9px", backgroundColor: "#dcdcdc" }} />

        {/* Clickable Arrow Icon and Text */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 1,
            justifyContent: "space-between",
          }}
        >
          {subtext && (
            <Typography
              variant="body1"
              sx={{ color: "#292c67", fontSize: "16px", mr: 2 }}
              onClick={() => {
                if (subtextcount && subtextcount !== 0) {
                  action("Accepted");
                  navigate(navigateTo);
                }
              }}
            >
              {subtext} {subtextcount || 0}
            </Typography>
          )}

          {/* Vertical Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#dcdcdc", mx: 3 }}
          />

          {subtext2 && (
            <Typography
              variant="body1"
              sx={{ color: "#292c67", fontSize: "16px", mr: 2 }}
              onClick={() => {
                if (subtextcount2 && subtextcount2 !== 0) {
                  action("Rejected");
                  navigate(navigateTo);
                }
              }}
            >
              {subtext2} {subtextcount2 || 0}
            </Typography>
          )}

          {/* If subtext2 is empty, render a clickable arrow */}
          {subtext2 === "" && (
            <Box
              onClick={() => {
                action("Accepted");
                navigate(navigateTo);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#292c67",
                fontWeight: 700,
                "&:hover": {
                  color: "#0077ff",
                },
              }}
            >
              <ArrowForwardIcon sx={{ ml: 1 }} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCardcopy;
