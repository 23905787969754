import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { HorizontalTabsProps } from "../../../types/profile";

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({
  tabs,
  selectedTab,
  onClick,
  from = "",
}) => {
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width:
          from === "admin" && isMobile
            ? "100%"
            : from === "meet" || from === "admin" || from === "profile"
              ? "max-content"
              : "100%",
        overflowX: "auto",
        flexWrap: from === "admin" && isMobile ? "wrap" : "unset",
        minHeight: "2.25rem", // Consistent height for the tabs container
      }}
    >
      {tabs?.map((tab: string, index) => (
        <Box
          key={index}
          sx={{
            // paddingBottom: selectedTab === tab ? "0.125rem" : 0,
            paddingBottom: "0.125rem", // consistent padding
            // borderBottom:
            //   selectedTab === tab
            //     ? "0.25rem solid var(--neuro-button-bg-success)"
            //     : "none",
            borderBottom: "0.25rem solid",
            borderBottomColor:
              selectedTab === tab
                ? "var(--neuro-button-bg-success)"
                : "transparent",
            // height: "1.625rem",
            height: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "var(--neuro-font-size-medium-smallest)",
            lineHeight: "120%",
            fontStyle: "var(--neuro-font-family-roboto-condensed)",
            color:
              selectedTab === tab
                ? "var(--neuro-black-text)"
                : "var(--neuro-darkgrey_border)",
            // marginRight: selectedTab === tab ? "1.563rem" : "1.813rem",
            // "&:last-child": { marginRight: 0 },
            marginRight: "1.5rem", // consistent margin for all tabs
          }}
          onClick={() => onClick(tab)}
        >
          <Typography>{tab}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default HorizontalTabs;
