import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { Formik, Form } from "formik";
import { addHospital, updateHospital } from "../../services/hospitalService";
import { Hospital } from "../../types/common";
import useAppStore from "../../store/appStore";
import { hospitalValidationSchema } from "../../validations/hospitalValidation";
import { AddEditHospitalModalProps } from "../../types/hospitals";
import { countryCode, indianStates } from "../../utils/common";

const AddEditHospitalModal: React.FC<AddEditHospitalModalProps> = ({
  open,
  onClose,
  hospital,
  loadHospitals,
}) => {
  const initialFormData: Hospital = {
    id: "",
    name: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "India",
    contactNumber: "",
    operatingHours: "",
    latitude: 0,
    longitude: 0,
    timezone: "Asia/Kolkata",
    currency: "INR",
    website: null,
    email: null,
    status: true,
  };

  const [formData, setFormData] = useState(initialFormData);

  const { showSnackbar } = useAppStore();

  useEffect(() => {
    if (hospital) {
      hospital.contactNumber = hospital.contactNumber
        ? hospital.contactNumber.replace(/^\+91/, "")
        : "";
      setFormData({ ...hospital });
      // setFormData({
      //   id: hospital.id,
      //   name: hospital.name,
      //   streetAddress: hospital.streetAddress,
      //   city: hospital.city,
      //   state: hospital.state,
      //   zipCode: hospital.zipCode,
      //   country: hospital.country,
      //   // contactNumber: hospital.contactNumber,
      //   contactNumber: hospital.contactNumber
      //     ? hospital.contactNumber.replace(/^\+91/, "")
      //     : "",
      //   operatingHours: hospital.operatingHours || "",
      //   latitude: hospital.latitude,
      //   longitude: hospital.longitude,
      //   timezone: hospital.timezone,
      //   currency: hospital.currency,
      //   website: hospital.website || "",
      //   email: hospital.email || "",
      //   status: hospital.status,
      // });
    } else {
      setFormData(initialFormData); // Reset form data when adding a new hospital
    }
  }, [hospital]);

  const handleSubmit = async (values: typeof initialFormData) => {
    console.log("values in submit clinic", values);
    try {
      if (hospital) {
        console.log("hospital edit", hospital);
        const hospitalId = hospital.id;

        try {
          const data = {
            ...values,
            contactNumber: values?.contactNumber?.includes("+")
              ? values?.contactNumber
              : `+91${values?.contactNumber}`,
          };
          await updateHospital(hospitalId, data);
          loadHospitals();
          showSnackbar("Clinic Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        delete (values as any).id;
        try {
          const data = {
            ...values,
            contactNumber: values?.contactNumber?.includes("+")
              ? values?.contactNumber
              : `+91${values?.contactNumber}`,
          };
          await addHospital(data);
          loadHospitals();
          showSnackbar("New Clinic Created Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
      onClose();
    } catch (error) {
      const message = hospital
        ? "Could not update clinic, please try again"
        : "Could not add clinic, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{hospital ? "Edit Clinic" : "Add New Clinic"}</DialogTitle>
      <Formik
        // initialValues={hospital || initialFormData}
        initialValues={formData}
        validationSchema={hospitalValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form>
            <DialogContent>
              <TextField
                label="Name"
                name="name"
                required
                value={values.name}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              <TextField
                label="Street Address"
                name="streetAddress"
                required
                value={values.streetAddress}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.streetAddress && !!errors.streetAddress}
                helperText={touched.streetAddress && errors.streetAddress}
              />
              <TextField
                label="City"
                name="city"
                required
                value={values.city}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.city && !!errors.city}
                helperText={touched.city && errors.city}
              />

              {/* <TextField
                label="State"
                name="state"
                required
                value={values.state}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.state && !!errors.state}
                helperText={touched.state && errors.state}
              /> */}
              <Select
                name="state"
                value={values.state || ""}
                onChange={handleChange}
                fullWidth
                required
                displayEmpty
                margin="dense"
                renderValue={(selected) => {
                  if (selected === "") {
                    return <span style={{ color: "#9e9e9e" }}>State *</span>;
                  }
                  return selected;
                }}
                error={touched.state && !!errors.state}
              >
                <MenuItem value="" disabled>
                  State
                </MenuItem>
                {indianStates?.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                label="Zip Code"
                name="zipCode"
                required
                value={values.zipCode}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.zipCode && !!errors.zipCode}
                helperText={touched.zipCode && errors.zipCode}
              />

              <Select
                name="country"
                value={values.country || "India"}
                onChange={handleChange}
                // displayEmpty
                fullWidth
                required
                margin="dense"
                // renderValue={(selected) => {
                //   if (selected === "") {
                //     return <span style={{ color: "#9e9e9e" }}>Country *</span>;
                //   }
                //   return selected;
                // }}
                error={touched.country && !!errors.country}
              >
                {/* <MenuItem value="" disabled>
                  Country
                </MenuItem> */}
                <MenuItem value="India">India</MenuItem>
                {/* Add other countries here */}
              </Select>

              {/* <TextField
                label="Country"
                name="country"
                required
                value={values.country}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.country && !!errors.country}
                helperText={touched.country && errors.country}
              /> */}

              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{ maxWidth: "6.25rem" }}
                  name="countryCode"
                  value={"+91"}
                  onChange={handleChange}
                  fullWidth
                >
                  {countryCode.map((code) => (
                    <MenuItem key={code.id} value={code.value}>
                      {code.value}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  label="Contact Number"
                  name="contactNumber"
                  required
                  // value={values.contactNumber}
                  value={
                    values.contactNumber
                      ? values.contactNumber.replace(/^\+91/, "")
                      : ""
                  }
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.contactNumber && !!errors.contactNumber}
                  helperText={touched.contactNumber && errors.contactNumber}
                />
              </Box>

              <TextField
                label="Operating Hours"
                name="operatingHours"
                value={values.operatingHours}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.operatingHours && !!errors.operatingHours}
                helperText={touched.operatingHours && errors.operatingHours}
              />
              <TextField
                label="Latitude"
                name="latitude"
                type="number"
                required
                value={values.latitude}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.latitude && !!errors.latitude}
                helperText={touched.latitude && errors.latitude}
              />
              <TextField
                label="Longitude"
                name="longitude"
                type="number"
                required
                value={values.longitude}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.longitude && !!errors.longitude}
                helperText={touched.longitude && errors.longitude}
              />
              <TextField
                label="Timezone"
                name="timezone"
                value={values.timezone || "Asia/Kolkata"}
                onChange={handleChange}
                fullWidth
                required
                margin="dense"
                error={touched.timezone && !!errors.timezone}
                helperText={touched.timezone && errors.timezone}
              />
              <TextField
                label="Currency"
                name="currency"
                value={values.currency || "INR"}
                onChange={handleChange}
                fullWidth
                required
                margin="dense"
                error={touched.currency && !!errors.currency}
                helperText={touched.currency && errors.currency}
              />
              <TextField
                label="Website"
                name="website"
                value={values.website}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.website && !!errors.website}
                helperText={touched.website && errors.website}
              />
              <TextField
                label="Email"
                name="email"
                required
                value={values.email}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditHospitalModal;
