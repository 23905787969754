import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Modal from "../ui/Modal";
import AssistantIcon from "../svg/AssistantIcon";
import Button from "../ui/Button";
import useAppStore from "../../store/appStore";
import WhatsAppChatButton from "./WhatsAppChatButton";
import { HelpSupportModalProps } from "../../types/common";

const HelpSupportModal: React.FC<HelpSupportModalProps> = ({
  from = "",
  isButton = false,
}) => {
  const { isHelpModalOpen } = useAppStore();

  const [open, setOpen] = useState<boolean>(false);

  // callbacks & functions
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isHelpModalOpen ? (
        <>
          {!isButton && (
            <Box
              sx={{
                // position: "fixed",
                zIndex: 100,
                bottom: "0.625rem", // 10px
                right: "0.625rem", // 10px
                width: from === "login" || from === "sidebar" ? "100%" : "auto",
                display: from === "login" ? "flex" : "block",
                justifyContent: "center",
              }}
            >
              <Button
                className="warning-secondary-button"
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{
                  width:
                    from === "sidebar"
                      ? "100%" // 184px
                      : from === "login"
                        ? "max-content"
                        : "auto",
                  height: "2.25rem", // 36px
                }}
              >
                {from === "sidebar" || from === "login" ? (
                  "Click Here"
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.375rem", // 6px,
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontWeight: "var(-neuro-font-weight-medium)",
                          fontSize: "var(--neuro-font-size-smallest-x)",
                          lineHeight: "1rem",
                          textAlign: "end",
                        }}
                      >
                        Need
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "var(--neuro-font-weight-semibold)",
                          fontSize: "var(--neuro-font-size-smaller-plus)",
                          lineHeight: "1rem",
                        }}
                      >
                        Assistance
                      </Typography>
                    </Box>
                    <AssistantIcon
                      height="2rem"
                      width="2rem"
                      color="var(--neuro-white-text)"
                    />
                  </Box>
                )}
              </Button>
            </Box>
          )}

          {isButton && (
            <Button
              startIcon={<AssistantIcon height="1.5rem" width="1.5rem" />}
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              onClick={() => setOpen(true)}
            >
              Need Assistance?
            </Button>
          )}

          <Modal
            open={open}
            width="31.25rem"
            closeIcon={true}
            onClose={handleClose}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <Box sx={{ marginBottom: "1rem" }}>
                <AssistantIcon />
              </Box>
              <Typography
                // className="form-heading"
                sx={{
                  textAlign: "center",
                  fontWeight: "var(--neuro-font-weight-bold)",
                  fontSize: "var(--neuro-font-size-tiny)",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  lineHeight: "120%",
                  marginBottom: "0.5625rem", // 9px
                }}
              >
                Need Assistance?
              </Typography>

              <Typography
                // className="form-heading"
                sx={{
                  textAlign: "center",
                  fontSize: "var(--neuro-font-size-medium-smallest)",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  lineHeight: "120%",
                  marginBottom: "0.5625rem", // 9px
                }}
              >
                You are in the right place
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2.75rem", // 44px
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "var(--neuro-font-weight-bold)",
                      fontSize: "var(--neuro-font-size-tiny)",
                      fontFamily: "var(--neuro-font-family-roboto-condensed)",
                      lineHeight: "120%",
                    }}
                  >
                    Call Us : +91 84119-11234
                  </Typography>

                  <WhatsAppChatButton phoneNumber="8411911234" />
                  {/* <Button
                    variant="contained"
                    color="success"
                    className="success-button"
                  >
                    Chat on WhatsApp
                  </Button> */}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default HelpSupportModal;
