import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "../ui/Checkbox";
import Input from "../ui/Input";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import { inputLabelStyles, selectStyles } from "../../utils/styles";
import { fetchFormRequestsApiFormid, submitFormResponseApisuryveputcall } from "../../services/formService";
import useAppStore from "../../store/appStore";

const FormCreatorViewEditUsinId = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("Initial Name");
  const [zipCode, setZipCode] = useState<string>("Initial Description");
  const [formType, setFormType] = useState<string>("GENERIC");  // Default value
  const [filledBy, setFilledBy] = useState<string>("DOCTOR");
  const [disorderId, setDisorderId] = useState<string>("cm06i4jj2001842domsdtgro8");
  const [isScoreBased, setIsScoreBased] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [status, setStatus] = useState(true);


  const {
 
    isMenuCollapsed,
    setDrawerOpen,
    setIsMenuCollapsed,
    showSnackbar,
    setShowFormSubmenus,
  } = useAppStore();

  const isBelow800 = useMediaQuery("(max-width:50rem)");


  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );
  useEffect(() => {
    setShowFormSubmenus(true);
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);
  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchFormRequestsApiFormid(id || '');
        if (response?.data) {
          setName(response.data.data.name);
          setZipCode(response.data.data.description);
          setFormType(response.data.data.type);
          setFilledBy(response.data.data.filledBy);
          setDisorderId(response.data.data.disorderId);
          setIsScoreBased(response.data.data.isScoreBased);
          setStatus(response.data.data.status);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchData();
  }, [id]);



  const onSubmit = async () => {

 
    const formData = {
      name,
      description:zipCode,
      type:formType,
      filledBy,
      disorderId,
      isScoreBased,
      status,
    };

    try {
      const response = await submitFormResponseApisuryveputcall(id || '', formData);
      console.log(response);
      setSuccessMessage("Form updated successfully!");
      setOpenSnackbar(true);
      navigate("/forms");
    } catch (error) {
      console.error("Error submitting form", error);
      showSnackbar("Error submitting form" , "error")
    }
  };

  const onclickCancel=()=>{
    navigate("/forms");
  }



  return (
    <Box sx={{ backgroundColor: "var(--neuro-bg-light-grey-secondary)", height: "100%", padding: "1rem" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.688rem", height: "100%", width: "100%" }}>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: "1rem" }} onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1.25rem", alignItems: "center" }}>
            <ComponentHeader title="Edit Form" />
            <Box sx={{ display: "flex", gap: "1.25rem", alignItems: "center" }}>
              <Button
                className="success-button"
                startIcon={<SaveOutlinedIcon />}
                sx={{ height: "2.25rem" }}
                type="submit"
              >
                Save
              </Button>
              <Button
                // onClick={onClose}
                variant="contained"
                color="secondary"
                className="outlined-secondary-button"
                sx={{ height: "2.25rem" }}
                onClick={onclickCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
          <Box sx={{ p: "1.75rem", backgroundColor: "var(--neuro-white-text)", borderRadius: "0.625rem" }}>
            <Grid container spacing={3.5}>
              <Grid item xs={12} md={4}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Form Name *"
                  
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.375rem", width: "100%" }}>
                  <Typography sx={inputLabelStyles}>Form Type *</Typography>
                  <Select
                    value={formType}
                    onChange={(e) => setFormType(e.target.value)}
                    fullWidth
                    sx={selectStyles.withGreyBackground}
                  >
                    <MenuItem value="GENERIC">GENERIC</MenuItem>
                    <MenuItem value="SPECIFIC">SPECIFIC</MenuItem>
                    <MenuItem value="COMMON">COMMON</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  type="text"
                  label="Form Description *"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="input-secondary-textarea"
                  rows={4}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3.5}>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.375rem", width: "100%" }}>
                  <Typography sx={inputLabelStyles}>Form filled By *</Typography>
                  <Select
                    value={filledBy}
                    onChange={(e) => setFilledBy(e.target.value)}
                    fullWidth
                    sx={selectStyles.withGreyBackground}
                  >
                    <MenuItem value="DOCTOR">Doctor</MenuItem>
                    <MenuItem value="PATIENT">Patient</MenuItem>
                    <MenuItem value="BOTH">Both</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.375rem", width: "100%" }}>
                  <Typography sx={inputLabelStyles}>Disorder *</Typography>
                  <Select
                    value={disorderId}
                    onChange={(e) => setDisorderId(e.target.value)}
                    fullWidth
                    sx={selectStyles.withGreyBackground}
                  >
                    <MenuItem value="cm06i4jj2001842domsdtgro8">Migraine</MenuItem>
                    <MenuItem value="cm06i4jj2001842domrfff">Headache</MenuItem>
                  </Select> 
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Checkbox
                  checked={isScoreBased}
                  onChange={(e) => setIsScoreBased(e.target.checked)}
                  label="Is Score Based?"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {successMessage && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default FormCreatorViewEditUsinId;
