import { useCallback, useEffect, useState } from "react";
import RemirrorEditor from "./RemirrorEditor";

const DoctorSummary = ({ summary, id }: { summary: any; id: string }) => {
  const [doctorSummaryHtmlString, setDoctorSummaryHtmlString] =
    useState<string>(summary);

  const [doctorSummaryContent, setDoctorSummaryContent] = useState<any>(null);

  const extractBodyContent = useCallback((htmlString: string) => {
    const bodyContentMatch = htmlString?.match(
      /<body[^>]*>((.|[\n\r])*)<\/body>/im
    );
    return bodyContentMatch
      ? bodyContentMatch[1].trim()
      : "<body><p>No Doctor Summary</p></body>";
  }, []);

  useEffect(() => {
    setDoctorSummaryHtmlString(summary);
  }, [summary]);

  useEffect(() => {
    if (doctorSummaryHtmlString && doctorSummaryHtmlString !== "") {
      const innerDoctorSummaryContent = extractBodyContent(
        doctorSummaryHtmlString
      );
      setDoctorSummaryContent(innerDoctorSummaryContent);
    } else if (!doctorSummaryHtmlString || doctorSummaryHtmlString === "") {
      const innerDoctorSummaryContent = "<body><p>No Doctor Summary</p></body>";
      setDoctorSummaryContent(innerDoctorSummaryContent);
    }
  }, [doctorSummaryHtmlString]);

  return (
    <>
      {doctorSummaryContent && (
        <RemirrorEditor
          htmlContent={doctorSummaryContent}
          summaryType="doctor"
          setDoctorSummaryHtmlString={setDoctorSummaryHtmlString}
          documentId={id}
        />
      )}
    </>
  );
};

export default DoctorSummary;
