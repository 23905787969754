import React, { useState, useEffect } from "react";
import { fetchHospitals } from "../../services/hospitalService";
import { Box } from "@mui/material";
import { Hospital } from "../../types/common";
import HospitalsList from "./HospitalsList";
import useAppStore from "../../store/appStore";

const Hospitals: React.FC = () => {
  // props & state values
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { clinicsFilter } = useAppStore();

  // callbacks & functions
  const loadHospitals = async (
    start: string | null,
    end: string | null,
    search: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchHospitals(start, end, search);
      setHospitals(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching hospitals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHospitals(
      clinicsFilter.customStartDate,
      clinicsFilter.customEndDate,
      clinicsFilter.searchValue || ""
    );
  }, [clinicsFilter.customStartDate, clinicsFilter.customEndDate]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
          paddingBlock: "1rem",
          paddingInline: "0.875rem",
          overflow: "auto",
        }}
      >
        <HospitalsList
          hospitals={hospitals}
          loading={loading}
          loadHospitals={loadHospitals}
        />
      </Box>
    </>
  );
};

export default Hospitals;
