const NightIcon = (props: any) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.78154 1.00187C7.81068 0.540702 8.70226 1.58246 8.3803 2.48697C7.28788 5.5561 7.95907 9.04195 10.2266 11.3086C12.5057 13.5868 16.0146 14.2564 19.0937 13.1444C19.9896 12.8208 21.0031 13.7037 20.5601 14.7083C20.547 14.738 20.5338 14.7676 20.5205 14.7973C19.7321 16.5454 18.471 18.1894 16.8395 19.2917C15.1005 20.4666 13.0498 21.0942 10.9509 21.0942H10.9499C8.50922 21.0916 6.14542 20.2403 4.26372 18.6864C2.38202 17.1324 1.0996 14.9725 0.636409 12.5768C0.173218 10.1811 0.558122 7.69903 1.72511 5.55598C2.83141 3.52438 4.69172 1.9456 6.75128 1.01548L6.78154 1.00187Z"
      fill="#2581C4"
    />
  </svg>
);
export default NightIcon;
