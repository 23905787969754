import React, { useEffect } from "react";
import PersonalInfoPage from "../profile/patient/PersonalInfoPage";
import { useNavigate } from "react-router-dom";
import { PersonalInfoFormProps } from "../../types/profile";
import useAuthStore from "../../store/authStore";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import PatientDetails from "./PatientDetails";

const PatientsProfile: React.FC<PersonalInfoFormProps> = ({
  patientId,
  showProfileEdit = false,
  from = "",
}) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  useEffect(() => {
    if (!patientId) {
      if (isDoctorOrAdminOrClinicAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [patientId]);

  return (
    <>
      {showProfileEdit ? (
        <PersonalInfoPage patientId={patientId} />
      ) : (
        <PatientDetails id={patientId || ""} from={from} />
      )}
    </>
  );
};

export default PatientsProfile;
