import React from "react";
import { Box, Typography } from "@mui/material";
import { BadgeProps } from "../../types/ui";

const Badge: React.FC<BadgeProps> = ({
  label,
  top,
  width,
  left,
  color = "var(--neuro-white-text)",
  backgroundColor = "var(--neuro-secondary-light-error)",
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        color: color,
        padding: "0.125rem 0.5rem", // 2px 8px
        display: "inline-block",
        position: "absolute",
        transform: "rotate(-45deg)",
        transformOrigin: "top left",
        top: top || "6.25rem", // 100px
        left: left || "-3.5rem", // -56px
        height: "2rem", // 32px
        width: width || "100%",
        textAlign: "center",
        zIndex: 1,
        overflow: "hidden",
        margin: 0,
        fontWeight: "var(--neuro-font-weight-semibold)",
        fontSize: "var(--neuro-font-size-medium-smallest)",
        lineHeight: "143%",
        letterSpacing: "0.3rem",
        verticalAlign: "middle",
      }}
    >
      <Typography variant="body2" component="span">
        {label}
      </Typography>
    </Box>
  );
};

export default Badge;
