import React, { useEffect, useState } from "react";

// third-party imports
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

// project imports
import AppointmentDetails from "./AppointmentDetails";
import AppointmentSummary from "./AppointmentSummary";
import ContentCenter from "../../shared/ContentCenter";
import LoadingScreen from "../../shared/LoadingScreen";
import { getAppointmentDetails } from "../../../services/appointmentsService";
import { AppointmentConfirmationProps } from "../../../types/appointments";
import useAppStore from "../../../store/appStore";

const AppointmentConfirmation: React.FC<AppointmentConfirmationProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const query = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const { appointmentMeetId } = useAppStore();
  const [appointmenDetails, setAppointmenDetails] = useState(null);

  const navigate = useNavigate();

  // callbacks & function
  const loadAppointmentDetails = async (id: string) => {
    try {
      const response = await getAppointmentDetails(id);
      console.log("appointmenDetails respo", response);
      setAppointmenDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query && query.id) {
      loadAppointmentDetails(query.id);
    } else {
      if (from === "meet") {
        loadAppointmentDetails(appointmentMeetId);
      } else {
        navigate("/appointments");
      }
    }
  }, [query, appointmentMeetId]);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        paddingInline: patientId ? 0 : "0.875rem",
        paddingBlock: patientId ? 0 : "1rem",
        // height: "100%",
        width: "100%",
      }}
    >
      {((query && query.id) || appointmentMeetId) && (
        <>
          {loading && <LoadingScreen />}

          {!loading && !appointmenDetails && (
            <ContentCenter>
              <Typography className="no-data">Details not found</Typography>
            </ContentCenter>
          )}

          {!loading && appointmenDetails && (
            <Grid container spacing={2.5}>
              <Grid item xs={12} lg={appointmentMeetId ? 12 : 7.5}>
                <AppointmentDetails
                  patientId={patientId}
                  appointmentDetails={appointmenDetails}
                  loadAppointmentDetails={loadAppointmentDetails}
                />
              </Grid>

              <Grid item xs={12} lg={appointmentMeetId ? 12 : 4.5}>
                <AppointmentSummary
                  appointmentIdForMeet={appointmentMeetId}
                  appointmentDetails={appointmenDetails}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}

      {(!query || !query.id) && !appointmentMeetId && (
        <ContentCenter>
          <Typography className="no-data">
            Invalid appointment link. Please verify the link or contact support
            for assistance.
          </Typography>
        </ContentCenter>
      )}
    </Box>
  );
};

export default AppointmentConfirmation;
