import React, { useMemo } from "react";

// third-party imports
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from "@mui/material";

// project imports
import HospitalMeetDetails from "./HospitalMeetDetails";
import Logo from "../../shared/Logo";
import OnlineMeetDetails from "./OnlineMeetDetails";
import useAuthStore from "../../../store/authStore";
import {
  calculateAge,
  getAppointmentStatus,
  getFullHospitalAddress,
  specializationMapping,
} from "../../../utils/appointments";
import { isClinicAdminOrAdmin, isDoctor, isPatient } from "../../../utils/auth";
import ComponentHeader from "../../shared/ComponentHeader";
import { capitalizeFirstLetter } from "../../../utils/common";
import { getFormattedDateTime } from "../../../utils/appointments";
import UserAvatar from "../../shared/UserAvatar";
import { CANCELLED, COMPLETED, ONLINE } from "../../../utils/constants";
import { AppointmentDetailsPropTypes } from "../../../types/appointments";
import Button from "../../ui/Button";
import { useNavigate } from "react-router-dom";
import useDocumentStore from "../../../store/documentsStore";
import useAppStore from "../../../store/appStore";
import config from "../../../config";

const AppointmentDetails: React.FC<AppointmentDetailsPropTypes> = ({
  appointmentDetails,
  patientId,
  loadAppointmentDetails,
  from = "",
  usedIn = "",
  isFromMeet = false,
  isFromDashboard = false,
}) => {
  // props & state values
  const {
    appointmentDate,
    appointmentCode,
    status,
    appointmentMode,
    patient,
    doctor,
    availableSlot,
    id,
    isTranscriptionsAvailable,
    transcriptionsDocumentId,
    prescription,
    paymentStatus,
    clinicalNotes,
  } = appointmentDetails;

  const navigate = useNavigate();
  const { setSelectedDocumentId } = useDocumentStore();
  const { setDocumentsConferenceView } = useAppStore();

  const formattedDate = useMemo(() => {
    if (!appointmentDate) return null;
    const DateTimeObject = getFormattedDateTime(appointmentDate);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    } else return "";
  }, [availableSlot]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { userRole } = useAuthStore();

  const patientName = `${capitalizeFirstLetter(appointmentDetails.patient?.user?.firstName || "")}`;

  const doctorName = `Dr. ${capitalizeFirstLetter(appointmentDetails.doctor?.user?.firstName || "")}`;

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const isCompleted = useMemo(
    () => status === COMPLETED || appointmentStatus === COMPLETED,
    [status, appointmentStatus]
  );

  // Common appointment status message
  const appointmentInfo = useMemo(() => {
    if (status === CANCELLED) return "Your appointment has been cancelled!";
    if (
      status === COMPLETED ||
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime) ===
        COMPLETED
    ) {
      return "Your appointment has been completed!";
    }
    return "Your appointment has been confirmed!";
  }, [status, availableSlot]);

  const withName =
    isDoctor(userRole) || (!patientId && isClinicAdminOrAdmin(userRole))
      ? patientName
      : isPatient(userRole) || (isClinicAdminOrAdmin(userRole) && patientId)
        ? doctorName
        : "";

  const byName =
    isDoctor(userRole) || (!patientId && isClinicAdminOrAdmin(userRole))
      ? "patient"
      : isPatient(userRole) || (isClinicAdminOrAdmin(userRole) && patientId)
        ? "doctor"
        : "";

  const thanksNote =
    isPatient(userRole) || (isClinicAdminOrAdmin(userRole) && patientId)
      ? "The prescription has been sent."
      : isDoctor(userRole) || (!patientId && isClinicAdminOrAdmin(userRole))
        ? "Thank you for your time."
        : "";

  // Consultation message based on role and appointment status
  const consultationMessage = useMemo(() => {
    const isCompleted = status === COMPLETED || appointmentStatus === COMPLETED;

    const cancelledMsg = `Your consultation with ${withName} has been cancelled by you or by the ${byName}.`;

    const completedMsg = `Your consultation with ${withName} has been successfully completed.`;

    const scheduledMsg = `Your consultation with ${withName} has been scheduled.`;

    if (status === CANCELLED) return cancelledMsg;

    if (isCompleted) return `${completedMsg} ${thanksNote}`;

    return `${scheduledMsg} The meeting link will be shared with you ${config?.MEETING_ENABLE_TIME} minutes before the consultation.`;
  }, [status, userRole, patientName, doctorName, availableSlot]);

  const userName =
    (isClinicAdminOrAdmin(userRole) && patientId) || isPatient(userRole)
      ? patientName
      : isDoctor(userRole) || (!patientId && isClinicAdminOrAdmin(userRole))
        ? doctorName
        : "";

  // handle recordings button click
  const handleRecordingsClick = () => {
    setSelectedDocumentId(transcriptionsDocumentId || null);
    if (transcriptionsDocumentId) {
      if (patientId) {
        if (isFromMeet) {
          setDocumentsConferenceView("Single");
        } else {
          navigate(
            `/patients/${patientId}/documents/${transcriptionsDocumentId}/details`
          );
        }
      } else {
        navigate(`/documents/${transcriptionsDocumentId}/details`);
      }
    }
  };

  return (
    <Box>
      {from !== "list" && (
        <>
          <ComponentHeader title="Appointment Details" />

          <Typography
            sx={{
              marginBottom: "0.75rem",
              marginTop: "1rem",
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
            }}
          >
            {`${userName} ${appointmentInfo}`}
          </Typography>
        </>
      )}

      <Card
        sx={{
          padding:
            from === "list"
              ? 0
              : appointmentMode === "ONLINE"
                ? "1.563rem 1.188rem 12.5rem 1.313rem"
                : "1.563rem 1.188rem 1.5rem 1.313rem",
          borderRadius:
            from === "list" ? "0rem 0rem 0.75rem 0.75rem" : "0.75rem",
          boxShadow: "none",
          border:
            from === "list"
              ? "none"
              : "1px solid var(--neuro-secondary_border)",
        }}
      >
        <CardContent sx={{ padding: "0rem !important" }}>
          {from !== "list" && (
            <>
              <Grid
                container
                spacing={1.75}
                alignItems="center"
                sx={{ mb: "1.5rem" }}
              >
                <Grid item>
                  <UserAvatar
                    src={
                      isPatient(userRole) ||
                      (isClinicAdminOrAdmin(userRole) && patientId)
                        ? doctor?.user?.profileImageUrl
                        : isDoctor(userRole) ||
                            (!patientId && isClinicAdminOrAdmin(userRole))
                          ? patient?.user?.profileImageUrl
                          : undefined
                    }
                    alt={
                      isPatient(userRole) ||
                      (isClinicAdminOrAdmin(userRole) && patientId)
                        ? `${capitalizeFirstLetter(doctor?.user?.firstName || "Doctor-profile")}` ||
                          "Doctor-profile"
                        : isDoctor(userRole) ||
                            (!patientId && isClinicAdminOrAdmin(userRole))
                          ? `${capitalizeFirstLetter(patient?.user?.firstName || "User-profile")}` ||
                            "User-profile"
                          : "profile"
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-micro)",
                      fontWeight: "var(--neuro-font-weight-semibold)",
                      fontFamily: "var(--neuro-font-family-inter)",
                      color: "var(--neuro-bg-darkblue-primary)",
                      lineHeight: "120%",
                    }}
                  >
                    {isPatient(userRole) ||
                    (isClinicAdminOrAdmin(userRole) && patientId)
                      ? doctorName
                      : isDoctor(userRole) ||
                          (!patientId && isClinicAdminOrAdmin(userRole))
                        ? patientName
                        : ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-x-small)",
                      fontFamily: "var(--neuro-font-family-roboto-slab)",
                      color: "var(--neuro-button-bg-primary)",
                      lineHeight: "120%",
                    }}
                  >
                    {isPatient(userRole) ||
                    (isClinicAdminOrAdmin(userRole) && patientId)
                      ? doctor?.specializations &&
                        doctor?.specializations.length > 0
                        ? doctor?.specializations
                            .map(
                              (specialization: string) =>
                                specializationMapping[specialization] ||
                                specialization
                            )
                            .join(", ")
                        : ""
                      : isDoctor(userRole) ||
                          (!patientId && isClinicAdminOrAdmin(userRole))
                        ? `Gender: ${patient?.gender}` || ""
                        : ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-x-small)",
                      fontFamily: "var(--neuro-font-family-roboto-slab)",
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                      marginTop: "0.75rem",
                    }}
                  >
                    {isPatient(userRole) ||
                    (isClinicAdminOrAdmin(userRole) && patientId)
                      ? doctor?.yearsOfExperience
                        ? `${doctor?.yearsOfExperience} Years Experience`
                        : ""
                      : isDoctor(userRole) ||
                          (!patientId && isClinicAdminOrAdmin(userRole))
                        ? `Age: ${calculateAge(patient?.dateOfBirth || "")} Years`
                        : ""}
                  </Typography>
                </Grid>
                <Grid item>
                  {isPatient(userRole) ||
                  (isClinicAdminOrAdmin(userRole) && patientId) ? (
                    <Logo width="5.563rem" height="3.938rem" />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          )}

          <Box
            sx={{
              borderTop:
                from !== "list"
                  ? "1px solid var(--neuro-secondary_border)"
                  : "none",
              borderBottom: "1px solid var(--neuro-secondary_border)",
              paddingTop: "1rem",
              paddingLeft: "2rem",
              paddingBottom: "0.5rem",
              marginLeft: "-1.313rem",
              marginRight: "-1.188rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "var(--neuro-black-text)",
                        lineHeight: "120%",
                      }}
                    >
                      Type:
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--neuro-black-text)",
                        lineHeight: "120%",
                        fontSize: "var(--neuro-font-size-medium-smallest)",
                        fontWeight: "var(--neuro-font-weight-bold)",
                        fontFamily: "var(--neuro-font-family-roboto-condensed)",
                      }}
                    >
                      {appointmentMode
                        ? appointmentMode === ONLINE
                          ? "Online Consultation"
                          : "Clinic Visit"
                        : ""}{" "}
                    </Typography>
                  </Box>

                  {isCompleted &&
                    appointmentMode === ONLINE &&
                    isTranscriptionsAvailable && (
                      <Button
                        variant="outlined"
                        className="outlined-secondary-button"
                        onClick={handleRecordingsClick}
                      >
                        Recordings
                      </Button>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    paddingLeft: isMobile ? 0 : "1.75rem",
                    borderLeft: isMobile
                      ? "none"
                      : "1px solid var(--neuro-secondary_border)",
                    marginBottom: isMobile ? 0 : "-0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                    }}
                  >
                    Appointment Date:
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                      fontSize: "var(--neuro-font-size-medium-smallest)",
                      fontWeight: "var(--neuro-font-weight-bold)",
                      fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    }}
                  >
                    {formattedDate ? formattedDate.date : ""}{" "}
                    {formattedDate ? formattedDate.time : ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              paddingLeft: appointmentMode === ONLINE ? "1.063rem" : 0,
            }}
          >
            {appointmentMode === ONLINE ? (
              <OnlineMeetDetails
                appointmentCode={appointmentDetails?.appointmentCode}
                appointmentId={appointmentDetails?.id || ""}
                status={appointmentDetails?.status || ""}
                detailsInfo={consultationMessage || ""}
                availableSlot={appointmentDetails?.availableSlot}
                from={from}
                patientId={patientId}
                usedIn={usedIn}
                prescription={prescription}
                paymentStatus={paymentStatus}
                patient={patient}
                appointmentMode={appointmentMode}
                doctor={doctor}
                appointmentDate={appointmentDate}
                clinicalNotes={clinicalNotes}
                isFromMeet={isFromMeet}
                loadAppointmentDetails={loadAppointmentDetails}
                isFromDashboard={isFromDashboard}
              />
            ) : (
              <HospitalMeetDetails
                appointmentCode={appointmentCode}
                from={from}
                detailsInfo={consultationMessage || ""}
                appointmentId={id || ""}
                hospitalName={doctor?.defaultClinic?.name || ""}
                hospitalAddress={getFullHospitalAddress(doctor?.defaultClinic)}
                phoneNumber={doctor?.defaultClinic?.contactNumber || ""}
                availableSlot={availableSlot}
                status={status || ""}
                patientId={patientId}
                usedIn={usedIn}
                prescription={prescription}
                paymentStatus={paymentStatus}
                patient={patient}
                appointmentMode={appointmentMode}
                doctor={doctor}
                appointmentDate={appointmentDate}
                clinicalNotes={clinicalNotes}
                isFromMeet={isFromMeet}
                loadAppointmentDetails={loadAppointmentDetails}
                isFromDashboard={isFromDashboard}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AppointmentDetails;
