import { Navigate } from "react-router-dom";
import SignUpModal from "../auth/SignUpModal";
import useAuthStore from "../../store/authStore";

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const token = localStorage.getItem("token"); // Check for token in localStorage

  const { showSignUpModal, setShowSignUpModal } = useAuthStore();

  if (!token) {
    // If no token, redirect to login page
    return <Navigate to="/" replace />;
  }

  // If token exists, allow access to the element
  return (
    <>
      {element}
      <SignUpModal
        open={showSignUpModal}
        onClose={() => setShowSignUpModal(false)}
      />
    </>
  );
};

export default PrivateRoute;
