import React, { useState } from 'react';

interface DropdownGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    choices: { value: string; text: string; score: number }[];
    isRequired: boolean;
    correctAnswer: string[];
    showScore: boolean; // Add showScore property
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
}

const DynamicSelectMulti: React.FC<DropdownGroupProps> = ({
  question,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
}) => {
  const [editingChoiceIndex, setEditingChoiceIndex] = useState<number | null>(null);
  const [localQuestion, setLocalQuestion] = useState(question);

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  const handleAddChoice = () => {
    const newChoice = { value: `option${localQuestion.choices.length + 1}`, text: `Option ${localQuestion.choices.length + 1}`, score: 0 };
    updateLocalQuestion({
      ...localQuestion,
      choices: [...localQuestion.choices, newChoice],
    });
  };

  const handleRemoveChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter((_, idx) => idx !== choiceIndex);
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  const handleEditChoice = (choiceIndex: number, newText: string) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex].text = newText;
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  const handleEditScore = (choiceIndex: number, newScore: number) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex] = {
      ...updatedChoices[choiceIndex],
      score: newScore,
      value: `${newScore}`, // Update `value` to match the `score` as a string
    };
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };
  

  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  return (
    <div className="question-container">
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="dropdown-group">
        <select
          name={question.name}
          className="dropdown-select"
          multiple
          value={localQuestion.correctAnswer}
          onChange={(e) => {
            const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
            const updatedAnswers = Array.from(new Set([...localQuestion.correctAnswer, ...selectedValues]));
            updateLocalQuestion({ ...localQuestion, correctAnswer: updatedAnswers });
          }}
        >
          {localQuestion.choices.map((choice, choiceIndex) => (
            <option key={choiceIndex} value={choice.value}>
              {choice.text}
            </option>
          ))}
        </select>
      </div>

      {localQuestion.choices.map((choice, choiceIndex) => (
        <div className="editable-choice-container" key={choiceIndex}>
          <button className="remove-button" onClick={() => handleRemoveChoice(choiceIndex)}>
            -
          </button>
          <div className="editable-choice">
            {editingChoiceIndex === choiceIndex ? (
              <input
                type="text"
                value={choice.text}
                onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                onBlur={() => setEditingChoiceIndex(null)}
                autoFocus
                className="edit-label-input"
              />
            ) : (
              <span className="editable-text" onClick={() => setEditingChoiceIndex(choiceIndex)}>
                {choice.text}
              </span>
            )}
          </div>

          {/* Display score input when showScore is true */}
          {localQuestion.showScore && (
            <input
              type="number"
              value={choice.score}
              onChange={(e) => handleEditScore(choiceIndex, parseInt(e.target.value, 10))}
              className="score-input"
            />
          )}
        </div>
      ))}

      <button className="add-button" onClick={handleAddChoice}>
        +
      </button>

      <div className="question-controls">
        <button className="duplicate-button" onClick={() => handleDuplicateQuestion(index)}>
          Duplicate
        </button>
        <button className="delete-button" onClick={() => handleRemoveQuestion(index)}>
          Delete
        </button>
        <button className={`required-button ${localQuestion.isRequired ? 'active' : ''}`} onClick={handleToggleRequired}>
          {localQuestion.isRequired ? 'Required' : 'Optional'}
        </button>
      </div>
    </div>
  );
};

export default DynamicSelectMulti;
