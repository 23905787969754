const Doctorscard = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={36}
    viewBox="0 0 34 36"
    fill="none"
    {...props}
  >
    <path
      id="Vector"
      d="M17.6145 0C18.2931 0 18.8434 0.56225 18.8434 1.25581V1.67681C19.0256 1.67962 19.1811 1.68571 19.3261 1.69882C22.2922 1.96685 24.6432 4.36943 24.9055 7.40036C24.9295 7.67738 24.9295 7.99123 24.9295 8.45218V10.5002C24.9295 16.979 20.1054 22.3063 13.9277 22.9362V26.3721C13.9277 30.3023 17.0456 33.4884 20.8916 33.4884H22.3374C24.628 33.4884 26.5952 32.0618 27.4297 30.0259C27.4949 29.8668 27.5405 29.6855 27.5687 29.4775C25.5955 28.8245 24.1687 26.9314 24.1687 24.6976C24.1687 21.9232 26.3696 19.6743 29.0843 19.6743C31.7993 19.6743 34 21.9232 34 24.6976C34 27.1427 32.2905 29.1796 30.0262 29.6287C29.981 30.0699 29.8851 30.5366 29.6968 30.9962C28.493 33.9329 25.6529 36 22.3374 36H20.8916C15.6882 36 11.4699 31.6894 11.4699 26.3721V22.9386C5.03315 22.3068 2.07589e-05 16.765 2.07589e-05 10.0218L4.3511e-06 8.45228C-4.48055e-05 7.99128 -7.75586e-05 7.67741 0.0238945 7.40036C0.286178 4.36943 2.63732 1.96685 5.60335 1.69882C5.85843 1.67577 6.14542 1.67444 6.55424 1.67439V1.25581C6.55424 0.56225 7.10443 0 7.78315 0C8.46186 0 9.01207 0.56225 9.01207 1.25581V4.60463C9.01207 5.29819 8.46186 5.86044 7.78315 5.86044C7.10443 5.86044 6.55424 5.29819 6.55424 4.60463V4.18606C6.12263 4.18636 5.95439 4.18851 5.81984 4.20068C4.04024 4.36149 2.62954 5.80304 2.47217 7.6216C2.45905 7.77328 2.45785 7.96688 2.45785 8.51756V10.0218C2.45785 15.8016 7.04289 20.4869 12.6988 20.4869C18.0962 20.4869 22.4716 16.0157 22.4716 10.5002V8.51756C22.4716 7.96688 22.4705 7.77328 22.4572 7.6216C22.2999 5.80304 20.8893 4.36149 19.1096 4.20068C19.0403 4.1944 18.962 4.1908 18.8434 4.18874V4.60463C18.8434 5.29819 18.2931 5.86044 17.6145 5.86044C16.9358 5.86044 16.3855 5.29819 16.3855 4.60463V1.25581C16.3855 0.56225 16.9358 0 17.6145 0Z"
      fill="#868686"
    />
  </svg>
);
export default Doctorscard;
