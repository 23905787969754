import React, { useEffect, useRef } from "react";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import ViewerReportCard from "./ViewerReportCard";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentsProps } from "../../../types/documents";
import Tooltip from "../../ui/Tooltip";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ArticleIcon from "@mui/icons-material/Article";

const DocumentList: React.FC<DocumentsProps> = ({
  patientId,
  setIsDocumentsCollapsed,
  isDocumentsCollapsed = false,
  from = "",
}) => {
  // props & state values
  const { showSnackbar, documentsFilter } = useAppStore();

  const {
    documentDeleted,
    loadingDeleteDocument,
    errorDeleteDocument,
    cleanUpDeleteDocumentState,
    documents,
    loadingDocuments,
    errorDocuments,
    fetchDocuments,
    setSelectedDocumentId,
    selectedDocumentId,
  } = useDocumentStore();

  const listRef = useRef<HTMLDivElement | null>(null);
  const selectedCardRef = useRef<HTMLDivElement | null>(null);
  const isBelow900 = useMediaQuery("(max-width:56.25rem)");

  // callbacks & functions
  useEffect(() => {
    if (isBelow900 && setIsDocumentsCollapsed) {
      setIsDocumentsCollapsed(false);
    }
  }, [isBelow900]);

  useEffect(() => {
    if (loadingDeleteDocument) {
      showSnackbar("Deleting document, please wait", "info");
    } else if (errorDeleteDocument) {
      showSnackbar("Could not delete document, please try again", "error");
      cleanUpDeleteDocumentState();
    } else if (documentDeleted) {
      showSnackbar("Document deleted", "success");
      if (patientId) {
        fetchDocuments(
          patientId,
          documentsFilter.customStartDate,
          documentsFilter.customEndDate,
          documentsFilter.searchValue || ""
        );
      } else {
        fetchDocuments(
          "",
          documentsFilter.customStartDate,
          documentsFilter.customEndDate,
          documentsFilter.searchValue || ""
        );
      }
      cleanUpDeleteDocumentState();
    }
  }, [
    loadingDeleteDocument,
    errorDeleteDocument,
    documentDeleted,
    documentsFilter.customStartDate,
    documentsFilter.customEndDate,
  ]);

  useEffect(() => {
    if (selectedCardRef.current && listRef.current) {
      listRef.current.scrollTop = selectedCardRef.current.offsetTop - 250;
    }
  }, []);

  // Extracted styles to a variable
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    // paddingRight: "1.25rem",
    borderRight: "1px solid var(--neuro-secondary_border)",
    height: "100%",
    // overflowY: "auto",
  };

  const titleStyles = {
    fontSize: "var(--neuro-font-size-tiny)",
    lineHeight: "var(--neuro-line-height-micro)",
    color: "var(--neuro-black-text)",
    fontFamily: "var(--neuro-font-family-roboto-condensed)",
  };

  const listStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "0.875rem",
    height: "calc(100% - 6.25rem)",
    overflow: "auto",
    // paddingRight: "1rem",
    paddingRight: "1.25rem",
  };

  return (
    <Box sx={containerStyles}>
      {isDocumentsCollapsed ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1.188rem",
            borderBottom: "0.063rem dashed var(--neuro-secondary_border)",
            minHeight: "3.75rem",
            marginRight: "1.25rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.625rem",
            }}
          >
            <Tooltip title="Expand" placement="top-start">
              <IconButton
                disableRipple
                sx={{
                  p: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  if (setIsDocumentsCollapsed) {
                    setIsDocumentsCollapsed(!isDocumentsCollapsed);
                  }
                }}
              >
                <KeyboardArrowRightOutlinedIcon
                  sx={{ color: "var(--neuro-black-text)" }}
                />
              </IconButton>
            </Tooltip>
            <ArticleIcon />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1.188rem",
              borderBottom: "0.063rem dashed var(--neuro-secondary_border)",
              minHeight: "3.75rem",
              marginRight: "1.25rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.625rem",
              }}
            >
              {!isBelow900 && setIsDocumentsCollapsed && (
                <Tooltip title="Collapse" placement="top-start">
                  <IconButton
                    disableRipple
                    sx={{
                      p: 0,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => {
                      if (setIsDocumentsCollapsed) {
                        setIsDocumentsCollapsed(!isDocumentsCollapsed);
                      }
                    }}
                  >
                    <KeyboardArrowRightOutlinedIcon
                      sx={{ color: "var(--neuro-black-text)" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <ArticleIcon />
              <Typography sx={titleStyles}>
                {/* Uploaded Documents */}
                Documents
              </Typography>
            </Box>
          </Box>

          <Box sx={listStyles} ref={listRef}>
            <>
              {loadingDocuments && <LoadingScreen />}

              {errorDocuments && (
                <ContentCenter>
                  <Typography className="no-data">
                    Documents not found
                  </Typography>
                </ContentCenter>
              )}

              {!loadingDocuments &&
                !errorDocuments &&
                documents &&
                documents.length === 0 && (
                  <ContentCenter>
                    <Typography className="no-data">
                      Documents not found
                    </Typography>
                  </ContentCenter>
                )}

              {!loadingDocuments &&
                !errorDocuments &&
                documents &&
                documents.length > 0 &&
                documents.map((document) => (
                  <div
                    key={document?.id}
                    ref={
                      document.id === selectedDocumentId
                        ? selectedCardRef
                        : null
                    }
                  >
                    <ViewerReportCard
                      key={document?.id}
                      {...document}
                      setSelectedDocumentId={setSelectedDocumentId}
                      patientId={patientId}
                      isFromMeet={from === "meet" ? true : false}
                    />
                  </div>
                ))}
            </>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DocumentList;
