import React, { useCallback, useState } from "react";
import { Box, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import CardViewItem from "../shared/CardViewItem";
import LocationIcon from "../svg/LocationIcon";
import Button from "../ui/Button";
import {
  getFullHospitalAddress,
  handleOpenMap,
} from "../../utils/appointments";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { HospitalsCardProps } from "../../types/hospitals";
import CardViewWrapper from "../shared/CardViewWrapper";

const HospitalsCard: React.FC<HospitalsCardProps> = ({
  hospital,
  handleEdit,
  handleDelete,
}) => {
  // props & state values
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // callbacks & functions
  const handleViewMap = useCallback((address: any) => {
    handleOpenMap(getFullHospitalAddress(address));
  }, []);

  const showContactNumber = (number: string | null | undefined) => {
    if (!number || number === "") {
      return "-";
    } else {
      if (number.includes("+")) {
        return number;
      } else {
        return `+91${number}`;
      }
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditCard = (hospital: any) => {
    handleEdit(hospital);
    handleMenuClose();
  };

  const handleDeleteCard = (id: string) => {
    handleDelete(id);
    handleMenuClose();
  };

  return (
    <CardViewWrapper>
      <Grid container spacing={2.5} alignItems="center">
        <Grid
          item
          container
          alignItems="baseline"
          sx={{ lineHeight: "120%" }}
          spacing={5}
          xs={12}
          lg={10}
        >
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Clinic Name"
              value={`${capitalizeFirstLetter(hospital.name || "")}`}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem label="City" value={hospital?.city || "-"} />
          </Grid>

          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem label="State" value={hospital?.state || "-"} />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem label="Country" value={hospital?.country || "-"} />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Contact"
              value={`${showContactNumber(hospital?.contactNumber || "")}`}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem label="Timezone" value={hospital?.timezone || "-"} />
          </Grid>
          <Grid item xs={6} sm={4} md={2.5} lg={12 / 7}>
            <CardViewItem
              label="Reg. Date"
              value={getFormattedDate(hospital?.createdAt || "") || "-"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.25rem",
            }}
          >
            <Button
              startIcon={<LocationIcon />}
              color="secondary"
              variant="outlined"
              className="outlined-secondary-button"
              sx={{
                fontSize: "var(--neuro-font-size-smaller-plus)",
                textTransform: "none",
                height: "2.25rem",
                fontFamily: "var(--neuro-font-family-roboto)",
              }}
              onClick={() => {
                const address = {
                  streetAddress: hospital?.streetAddress || "",
                  city: hospital?.city || "",
                  state: hospital?.state || "",
                  zipCode: hospital?.zipCode || "",
                  country: hospital?.country || "",
                };
                handleViewMap(address);
              }}
            >
              Directions
            </Button>

            <IconButton
              onClick={handleMenuOpen}
              disableRipple
              size="small"
              sx={{
                p: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => handleEditCard(hospital)}>Edit</MenuItem>
              <MenuItem onClick={() => handleDeleteCard(hospital?.id)}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </CardViewWrapper>
  );
};

export default HospitalsCard;
