import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../ui/Button";
import Drawer from "../shared/Drawer";
import GeneralForm from "../forms/GeneralForm";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../utils/common";
import FormFilledDetails from "../forms/FormFilledDetails";
import useAuthStore from "../../store/authStore";
import { isPatient } from "../../utils/auth";
import assignFormStore from "../../store/formStore";
import { fetchFormRequestsApi } from "../../services/formService";
import useAppStore from "../../store/appStore";
import { PatientFormsListProps } from "../../types/patients";
import { TableWrapper } from "../shared/TableWrapper";

const PatientFormsList: React.FC<PatientFormsListProps> = ({
  patientId,
  patientName,
}) => {
  // props & state values
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  const [formAction, setFormAction] = useState<string | null>(null);

  const [formRequests, setFormRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const { showSnackbar } = useAppStore();

  const { userRole } = useAuthStore();

  const {
    formSubmitted,
    formAssigned,
    // assignForm,
    submitLoading,
    submitError,
    submitFormResponse,
    cleanUpFormSubmittedState,
  } = assignFormStore();

  // callbacks & functions
  const loadFormRequests = async (id: string) => {
    try {
      const response = await fetchFormRequestsApi(id);
      setFormRequests(response?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  const onSubmitForm = (responses: any, score: number) => {
    const data = {
      responses,
      score,
    };
    submitFormResponse(selectedForm?.id, data);
  };

  useEffect(() => {
    if (patientId) {
      loadFormRequests(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (formSubmitted && patientId) {
      loadFormRequests(patientId);
    }
  }, [formSubmitted, patientId]);

  useEffect(() => {
    if (formAssigned && patientId) {
      loadFormRequests(patientId);
    }
  }, [formAssigned, patientId]);

  useEffect(() => {
    if (submitError) {
      showSnackbar("Could not submit form, please try again", "error");
      cleanUpFormSubmittedState();
    }
    if (submitLoading) {
      showSnackbar("Form is submitting, please try again", "info");
    }

    if (formSubmitted) {
      showSnackbar("Form is submittied", "success");
    }
  }, [formSubmitted, submitLoading, submitError]);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedForm(null);
    setFormAction(null);
    cleanUpFormSubmittedState();
  };

  const formattedDate = (dateString: string | null) => {
    if (!dateString || dateString === "") {
      return "";
    }
    const formatedDate = format(new Date(dateString), "d MMMM yyyy");

    return formatedDate;
  };

  // table columns
  const columns = [
    {
      id: "form.name",
      label: "Form Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const nameA = a?.form?.name || "-"; // Default to "-" if name is undefined
        const nameB = b?.form?.name || "-"; // Default to "-" if name is undefined

        return nameA.localeCompare(nameB);
      },
      render: (_: any, row: any) => `${row?.form?.name || "-"}`,
    },
    {
      id: "assignedBy.firstName",
      label: "Assigned By",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const nameA =
          capitalizeFirstLetter(a?.assignedBy?.firstName || "") || "";
        const nameB =
          capitalizeFirstLetter(b?.assignedBy?.firstName || "") || "";

        return nameA.localeCompare(nameB);
      },
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.assignedBy?.firstName || "") || "-"}`,
    },
    {
      id: "createdAt",
      label: "Assigned On",
      render: (value: any) => `${formattedDate(value || "") || "-"}`,
      sortable: true,
    },
    {
      id: "status",
      label: "Status",
      render: (value: string) => `${value || "-"}`,
      sortable: true,
    },
    // Conditionally add the score column if the userRole is not a patient
    ...(!isPatient(userRole)
      ? [
          {
            id: "score",
            label: "Score",
            sortable: true,
            render: (_: any, row: any) =>
              `${
                row?.responses?.isScoreBased
                  ? row?.score === 0
                    ? 0
                    : row?.score || "-"
                  : "-"
              }`,
          },
        ]
      : []),
    {
      id: "actions",
      label: "Actions",
      render: (_: any, row: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <>
            {row?.status === "COMPLETED" && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  className="outlined-secondary-button"
                  onClick={() => {
                    setSelectedForm(row);
                    setFormAction("Edit");
                    setDrawerOpen(true);
                  }}
                  sx={{
                    minHeight: "1.375rem",
                    maxHeight: "1.375rem",
                  }}
                >
                  Edit
                </Button>
                <Box sx={{ height: "1.875rem" }}>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-tiny-plus)",
                      lineHeight: "120%",
                      color: "var(--neuro-secondary_border)",
                    }}
                  >
                    /
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  size="small"
                  className="outlined-secondary-button"
                  onClick={() => {
                    setSelectedForm(row);
                    setFormAction("View");
                    setDrawerOpen(true);
                  }}
                  sx={{
                    minHeight: "1.375rem",
                    maxHeight: "1.375rem",
                  }}
                >
                  View
                </Button>
              </>
            )}

            {row?.status === "PENDING" && (
              <Button
                size="small"
                variant="outlined"
                className="outlined-secondary-button"
                onClick={() => {
                  setSelectedForm(row);
                  setFormAction("Fill");
                  setDrawerOpen(true);
                }}
                sx={{
                  minHeight: "1.375rem",
                  maxHeight: "1.375rem",
                }}
              >
                Fill
              </Button>
            )}
          </>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {loading && <LoadingScreen />}

      {!loading && formRequests && formRequests.length === 0 && (
        <ContentCenter>
          <Typography className="no-data">Forms not found</Typography>
        </ContentCenter>
      )}

      {!loading && formRequests && formRequests.length > 0 && (
        <TableWrapper
          columns={columns}
          rows={formRequests}
          stickyHeader={true}
          maxHeight="100%"
        />
      )}

      {/* view/edit form drawer */}
      <Drawer
        open={drawerOpen}
        width={isMobile ? "100vw" : "45vw"}
        headerText={`${patientName}`}
        onClose={() => {
          setSelectedForm(null);
          setFormAction(null);
          setDrawerOpen(false);
          cleanUpFormSubmittedState();
        }}
      >
        <Box
          sx={{
            paddingInline: "1rem",
            paddingTop: "1.25rem",
            height: "calc(100% - 3.75rem)",
            overflowY: "auto",
          }}
        >
          <>
            {selectedForm && (
              <>
                {formSubmitted ? (
                  <FormFilledDetails
                    score={
                      selectedForm?.form?.isScoreBased &&
                      formSubmitted?.score !== null &&
                      formSubmitted?.score !== undefined
                        ? formSubmitted?.score
                        : null
                    }
                    patientName={patientName}
                    onClose={onCloseDrawer}
                  />
                ) : (
                  <GeneralForm
                    formSchema={
                      selectedForm?.status === "COMPLETED"
                        ? selectedForm?.responses
                        : selectedForm?.form?.questions || null
                    }
                    readOnly={formAction === "View" ? true : false}
                    onFinish={onSubmitForm}
                    loading={submitLoading}
                    patientName={patientName}
                    onClosing={
                      formAction === "View" ? onCloseDrawer : undefined
                    }
                    isEdit={formAction === "Edit" ? true : false}
                  />
                )}
              </>
            )}
          </>
        </Box>
      </Drawer>
    </Box>
  );
};

export default PatientFormsList;
