import React from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Button from "../ui/Button";

interface ReferralsTableActionsProps {
  handleMenuOpen: any;
  anchorEl: any;
  handleMenuClose: any;
  row: any;
  handleAccept?: any;
  handleCancel?: any;
  showNetworkActions?: boolean;
  handleEdit?: any;
  handleDelete?: any;
}

const ReferralsTableActions: React.FC<ReferralsTableActionsProps> = ({
  handleMenuOpen,
  anchorEl,
  handleMenuClose,
  row,
  handleAccept,
  handleCancel,
  handleEdit,
  handleDelete,
  showNetworkActions = false,
}) => {
  // props & state values

  return (
    <div>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleMenuOpen(event);
        }}
      >
        <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(event: any) => {
          event.stopPropagation();
          handleMenuClose();
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {!showNetworkActions && (
          <MenuItem
            onClick={() => {
              handleAccept(row);
              handleMenuClose();
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className="primary-button "
              onClick={() => handleAccept(row)}
              sx={{
                height: "2.25rem",
              }}
            >
              Accept
            </Button>
          </MenuItem>
        )}

        {!showNetworkActions && (
          <MenuItem
            onClick={() => {
              handleCancel(row);
              handleMenuClose();
            }}
          >
            <Button
              variant="contained"
              className="warning-button"
              onClick={() => handleCancel(row)}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>
          </MenuItem>
        )}

        {showNetworkActions && (
          <MenuItem
            onClick={() => {
              handleEdit(row);
              handleMenuClose();
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className="primary-button "
              onClick={() => handleEdit(row)}
              sx={{
                height: "2.25rem",
              }}
            >
              Edit
            </Button>
          </MenuItem>
        )}

        {showNetworkActions && (
          <MenuItem
            onClick={() => {
              handleDelete(row?.id);
              handleMenuClose();
            }}
          >
            <Button
              variant="contained"
              className="warning-button"
              onClick={() => handleDelete(row?.id)}
              sx={{
                height: "2.25rem",
              }}
            >
              Delete
            </Button>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ReferralsTableActions;
