import api from "./apiService";

export const uploadFiles = (formdata: FormData, id?: string) => {
  if (id) {
    const params = {
      patientId: id,
    };
    return api.post(`/api/documents`, formdata, { params });
  }
  return api.post(`/api/documents`, formdata);
};

export const getDocuments = (
  id?: string,
  start?: any,
  end?: any,
  search?: any
) => {
  let params;
  // patientId: id,

  if (id && id !== "") {
    params = {
      patientId: id,
    };
  }
  if (start && end) {
    params = {
      ...params,
      start,
      end,
    };
  }

  params = {
    ...params,
    search: search || "",
  };
  return api.get(`/api/documents`, { params });
};

export const getDocumentDetails = (id: string) => {
  return api.get(`/api/documents/${id}`);
};

export const removeDocument = (id: string, patientId?: string) => {
  if (patientId) {
    const params = {
      patientId: patientId,
    };
    return api.delete(`/api/documents/${id}`, { params });
  }
  return api.delete(`/api/documents/${id}`);
};

export const updateDocumentName = (id: string, data: any) => {
  return api.put(`/api/documents/name/${id}`, data);
};

export const getDoctorDocuments = (id: string) => {
  return api.get(`/api/documents/doctor/${id}`);
};

export const changeDoctorSummary = (id: string, doctorSummary: string) => {
  const data = {
    doctorSummary,
  };
  return api.put(`/api/documents/updateCustomDoctorSummary/${id}`, data);
};

export const changePatientSummary = (id: string, patientSummary: string) => {
  const data = {
    patientSummary,
  };
  return api.put(`/api/documents/updateCustomPatientSummary/${id}`, data);
};

export const updateDoctorApproveStatus = (
  id: string,
  isDoctorApproved: boolean
) => {
  const data = {
    isDoctorApproved,
  };
  return api.put(`/api/documents/doctorApprove/${id}`, data);
};

export const updateDocumentFeedback = (id: string, feedback: string) => {
  const data = {
    feedback,
  };
  return api.put(`/api/documents/feedback/${id}`, data);
};

export const updateDocumentMetaData = (id: string, metadata: string) => {
  const data = {
    metadata,
  };
  return api.put(`/api/documents/updateCustomMetadata/${id}`, data);
};

export const downloadDocumentDoctorSummary = (id: string) => {
  return api({
    method: "GET",
    url: `/api/downloadPdf/${id}`,
    responseType: "blob",
    params: { type: "DOCTOR_SUMMARY" },
  });
};

export const downloadDocumentPatientSummary = (id: string) => {
  return api({
    method: "GET",
    url: `/api/downloadPdf/${id}`,
    responseType: "blob",
    params: { type: "PATIENT_SUMMARY" },
  });
};

export const downloadPrescription = (id: string) => {
  return api({
    method: "GET",
    url: `/api/downloadPdf/${id}`,
    params: { type: "MEDICATIONS" },
    responseType: "blob",
  });
};

export const generateDocumentSummary = (id: string) => {
  return api.put(`/api/documents/regenerateSummary/${id}`);
};

// temporary api to upload recording in conference
export const uploadRecording = (patientId: string, appointmentId: string) => {
  return api.post(`/api/documents/recording`, { patientId, appointmentId });
};

// to change patient notes of a recording
export const changePatientNotes = (id: string, patientNote: string) => {
  const data = {
    patientNote,
  };
  return api.put(`/api/documents/updateCustomPatientNote/${id}`, data);
};

// to change patient notes of a recording
export const changeSymptomAssessment = (
  id: string,
  symptomAssessment: string
) => {
  const data = {
    symptomAssessment,
  };
  return api.put(`/api/documents/updateCustomSymptomAssessment/${id}`, data);
};
