import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { PatientHeaderProps } from "../../../types/appointments";
import { getFormattedDateTime } from "../../../utils/appointments";
import Button from "../../ui/Button";

const PatientHeader: React.FC<PatientHeaderProps> = ({ name, setHideHeader, handleCreateSummary, showCloseButton, createSummaryLoading, handleCloseMeeting, handleClose, id, date, hideHeader = false }) => {
  // props & state values
  const formattedDate = useMemo(() => {
    if (!date || date === "") return null;
    const DateTimeObject = getFormattedDateTime(date);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    }
    else return null;
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-primary-seagreen)",
        height: "7rem",
        display: hideHeader ? "none" : "flex",
        alignItems: "center",
        paddingInline: "2.125rem",
        paddingBlock: "0.5rem",
        transition: "display 0.3s ease"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-mini)",
              fontWeight: "var(--neuro-font-weight-bold)",
              lineHeight: "120%",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-white-text)",
              flex: 0.5
            }}
          >
            {name || ""}
          </Typography>

          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "0.875rem", mt: "0.5rem" }}>
            {location.pathname.includes("medication") && <Button
              variant="contained"
              className="primary-button"
              onClick={handleCreateSummary}
              disabled={createSummaryLoading}
              sx={{ height: "2.25rem" }}
            >
              Save Prescription
            </Button>}

            {showCloseButton !== "true" && (
              <Button
                variant="outlined"
                className="outlined-secondary-button"
                sx={{ height: "2.25rem" }}
                onClick={handleCloseMeeting}
              >
                Close Meeting
              </Button>
            )}

            {showCloseButton === "true" && (
              <Button
                variant="outlined"
                className="outlined-secondary-button"
                sx={{ height: "2.25rem" }}
                onClick={handleClose}
              >
                Close
              </Button>
            )}

            {!hideHeader && <Button
              variant="outlined"
              className="outlined-secondary-button"
              onClick={() => {
                setHideHeader(true);
              }}
              sx={{
                height: "2.25rem",
              }}
            >
              Hide Header
            </Button>}
          </Box>

        </Box>
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-medium-smallest)",
            fontWeight: "var(--neuro-font-weight-bold)",
            lineHeight: "120%",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            color: "var(--neuro-white-text)",
          }}
        >
          Appointment ID : {id || ""}
        </Typography>
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-medium-smallest)",
            fontWeight: "var(--neuro-font-weight-bold)",
            lineHeight: "120%",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            color: "var(--neuro-white-text)",
          }}
        >
          Appointment Date : {formattedDate ? formattedDate.date : ""}{" "}
          {formattedDate ? formattedDate.time : ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default PatientHeader;
