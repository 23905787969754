import { useEffect, useState } from "react";

// third-party imports
import { Box, Grid } from "@mui/material";

// project imports
import SignedUpModal from "./SignedUpModal";
import useAppStore from "../../store/appStore";
import AllAppointments from "../appointments/Appointments/AllAppointments";
import PatientInfoCard from "./PatientInfoCard";
import PatientDashboardAlert from "./PatientDashboardAlert";
import useProfileStore from "../../store/profileStore";
import { loadPatientDashboard } from "../../services/dashboardService";
import NeedHelpAlert from "./NeedHelpAlert";

const PatientDashboard = () => {
  // props & state values
  const [signedUpModalOpen, setSignedUpModalOpen] = useState<boolean>(false);
  const { profileDetails, userProfile } = useProfileStore();
  const [mrnNumber, setMrnNumber] = useState(null);

  const {
    isRedirectedFromSignUp,
    setIsRedirectedFromSignUp,
    // isMenuCollapsed
  } = useAppStore();

  // callbacks & functions
  const loadDashboard = async () => {
    try {
      const response = await loadPatientDashboard();
      setMrnNumber(response?.data?.mrn || null);
    }
    catch (error) {
      console.error("Something went wrong", error);
    }

  };

  useEffect(() => {
    loadDashboard();
  }, []);

  useEffect(() => {
    if (isRedirectedFromSignUp) {
      setSignedUpModalOpen(true);
    }
    return () => {
      setIsRedirectedFromSignUp(false);
    };
  }, [isRedirectedFromSignUp]);

  const handleCloseSignedUpModal = () => {
    setSignedUpModalOpen(false);
  };

  return (
    <>
      <Grid container spacing={2.5} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              height: "100%",
            }}
          >
            <Grid container spacing={2.75} sx={{ height: "100%" }}>
              <Grid item xs={12}>
                <NeedHelpAlert />
              </Grid>

              <Grid item xs={12}>
                <PatientInfoCard
                  mrnNumber={mrnNumber}
                  profileDetails={profileDetails}
                  userProfile={userProfile}
                />
              </Grid>

              {profileDetails &&
                (!profileDetails?.mrn || profileDetails?.mrn === "") && (
                  <Grid item xs={12}>
                    <PatientDashboardAlert />
                  </Grid>
                )}

              <Grid
                item
                container
                xs={12}
                spacing={2.5}
                sx={{ height: "100%" }}
              >
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: "0.063rem solid var(--neuro-secondary_border)",
                      borderRadius: "0.625rem",
                      padding: "0.625rem",
                      height: "100%",
                    }}
                  >
                    <AllAppointments from="dashboard" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* showing modal if navigated from sign up page */}
      <SignedUpModal
        open={signedUpModalOpen}
        handleClose={handleCloseSignedUpModal}
        mrnNumber={mrnNumber}
      />
    </>
  );
};

export default PatientDashboard;
