import { useEffect, useState } from "react";
import Input from "../../ui/Input";

const DocumentFeedback = ({
  feedback,
  feebackUpdateHandler,
  id,
}: {
  feedback: any;
  feebackUpdateHandler: any;
  id: string;
}) => {
  // props & state values
  const [feedbackValue, setFeedbackValue] = useState<string>(
    feedback ? feedback : ""
  );

  // callbacks & functions
  useEffect(() => {
    setFeedbackValue(feedback ? feedback : "");
  }, [feedback]);

  const handleFeedbackChange = (event: any) => {
    setFeedbackValue(event.target.value);
  };

  // feedback onBlur
  const handleFeebackBlur = () => {
    const checkvalue = feedback ? feedback : "";
    if (feedbackValue.trim() !== checkvalue) {
      const value = feedbackValue.trim();
      feebackUpdateHandler(id, value);
    }
  };
  return (
    <Input
      type="text"
      value={feedbackValue}
      label="Feedback on the summary to enhance its quality"
      onChange={handleFeedbackChange}
      onBlur={handleFeebackBlur}
      fullWidth
      multiline
    />
  );
};

export default DocumentFeedback;
