// authStore.ts
import { create } from "zustand";
import { jwtDecode } from "jwt-decode";
import { AuthState } from "../types/common";
import { refreshTokenAPI } from "../services/authService";

const useAuthStore = create<AuthState>((set) => ({
	isAuthenticated: false,
	user: null,
	userRole: null,
	name: null,
	clinicId: null,

	showSignUpModal: false,
	setShowSignUpModal: (value: boolean) => {
		set({
			showSignUpModal: value,
		});
	},

	login: (jwtToken: string, refreshToken: string) => {
		const decodedToken: any = jwtDecode(jwtToken);
		localStorage.setItem("token", jwtToken);
		localStorage.setItem("refreshToken", refreshToken);
		set({
			isAuthenticated: true,
			user: decodedToken,
			userRole: decodedToken.role,
			name:
				decodedToken.role === "DOCTOR"
					? "Dr." + decodedToken.name
					: decodedToken.name,
			clinicId: decodedToken?.clinicId,
		});
	},

	logout: () => {
		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("profile_image");
		localStorage.removeItem("prfile_image");
		localStorage.setItem("isSignUpRequired", "false");
		set({
			isAuthenticated: false,
			user: null,
			userRole: null,
			name: null,
			clinicId: null,
		});
	},

	refreshToken: async () => {
		const response = await refreshTokenAPI();
		const newToken = response.data.accessToken;
		const refreshToken = response.data.refreshToken;
		const decodedToken: any = jwtDecode(newToken);
		localStorage.setItem("token", newToken);
		localStorage.setItem("refreshToken", refreshToken);
		set({
			isAuthenticated: true,
			user: decodedToken,
			userRole: decodedToken.role,
			name:
				decodedToken.role === "DOCTOR"
					? "Dr." + decodedToken.name
					: decodedToken.name,
			clinicId: decodedToken?.clinicId,
		});
	},
}));

// Function to initialize the auth state from local storage
export const initializeAuth = async () => {
	const token = localStorage.getItem("token");
	const refreshToken = localStorage.getItem("refreshToken");

	if (token && refreshToken) {
		try {
			const decodedToken: any = jwtDecode(token);
			// Optionally, you could validate the token expiration here
			useAuthStore.setState({
				isAuthenticated: true,
				user: decodedToken,
				userRole: decodedToken.role,
				name:
					decodedToken.role === "DOCTOR"
						? "Dr." + decodedToken.name
						: decodedToken.name,
				clinicId: decodedToken?.clinicId,
			});
			// Optionally, you could refresh the token here if needed
			// await useAuthStore.getState().refreshToken();
		} catch (error) {
			useAuthStore.getState().logout();
		}
	}
};

export default useAuthStore;
