import {
  Box,
  Typography,
  Avatar,
  Link,
  Divider,
  useMediaQuery,
} from "@mui/material";
import useProfileStore from "../../store/profileStore";
import { useState } from "react";
import AadharModal from "../auth/AadharModal";
import AddAbhaAccountModal from "../profile/patient/AddAbhaAccountModal";
import useAuthStore from "../../store/authStore";
import { capitalizeFirstLetter } from "../../utils/common";

const PatientInfoCard = ({ mrnNumber }: { mrnNumber: any }) => {
  // props & state values
  const { profileDetails, userProfile } = useProfileStore();
  const { user } = useAuthStore();
  const [aadharModalOpen, setAadharModalOpen] = useState(false);
  const [addAbhaAccountModalOpen, setAddAbhaAccountModalOpen] = useState(false);
  const isBelow991 = useMediaQuery("(max-width:61.938rem)");
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  // callbacks & functions
  const calculateAgeInYearsOrMonths = (dateString: string): string => {
    if (!dateString || dateString === "") {
      return "";
    }

    let birthDate: Date;

    if (!isNaN(Date.parse(dateString))) {
      birthDate = new Date(dateString);
    } else {
      const [day, month, year] = dateString.split("-").map(Number);
      birthDate = new Date(year, month - 1, day);
    }

    const today = new Date();
    let ageInYears = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      ageInYears--;
    }

    if (ageInYears > 0) {
      return `${ageInYears}`;
    } else {
      return `less than 1`;
    }
  };

  function calculateDOBFromAge(ageStr: string): string {
    if (!ageStr || ageStr === "") {
      return "-";
    }
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - parseInt(ageStr, 10);

    const date = new Date(birthYear, 0, 1); // Assume January 1st as birthdate
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function convertToDateFormat(dateString: string): string {
    if (!dateString || dateString === "") {
      return "-";
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const onCloseAadharModal = () => {
    setAadharModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        borderRadius: "0.75rem",
        boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
        width: "100%",
        background:
          "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(84, 184, 149, 1))",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              borderRadius: "0.75rem 0 0 0.75rem",
              backgroundColor: "var(--neuro-bg-darkblue-primary)",
              width: "4.875rem",
              height: "100%",
              "@media (max-width: 61.938rem)": {
                height: "100%",
              },
            }}
          />
          <Avatar
            sx={{
              width: "7.5rem",
              height: "7.5rem",
              border: "0.125rem solid var(--neuro-button-bg-success)",
              marginTop: "1.25rem",
              marginLeft: "-3.75rem",
              "@media (max-width: 61.938rem)": {
                marginBlock: "auto",
              },
            }}
            src={userProfile || undefined}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingBlock: "1.75rem",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1.938rem 1.813rem",
            color: "var(--neuro-white-text)",
            flexWrap: "wrap",
            "@media (max-width: 61.938rem)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                alignSelf: "flex-start",
                fontWeight: "var(--neuro-font-weight-bold)",
                fontSize: "var(--neuro-font-size-medium-smallest)",
                lineHeight: "120%",
                fontFamily: "var(--neuro-font-family-roboto-condensed)",
              }}
            >
              {capitalizeFirstLetter(profileDetails?.user?.firstName || "") ||
                ""}
            </Typography>
            <Typography
              sx={{
                marginTop: "0.5rem",
                lineHeight: "150%",
              }}
            >
              Age:{" "}
              {profileDetails?.age && profileDetails?.age !== ""
                ? `${profileDetails?.age}`
                : `${calculateAgeInYearsOrMonths(profileDetails?.dateOfBirth || "")}` ||
                  ""}{" "}
              Years (
              {profileDetails?.age && profileDetails?.age !== ""
                ? calculateDOBFromAge(profileDetails?.age || "")
                : convertToDateFormat(profileDetails?.dateOfBirth || "")}
              ), Sex: {profileDetails?.gender || "-"}
              <br />
              MRN/Ref No: {mrnNumber || profileDetails?.referenceNumber || "-"}
              <br />
              Reg. On: {convertToDateFormat(profileDetails?.createdAt || "")}
            </Typography>
          </Box>

          <Divider
            orientation={isBelow991 ? "horizontal" : "vertical"}
            sx={{
              color: "var(--neuro-white-text)",
              backgroundColor: "var(--neuro-white-text)",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                alignSelf: "flex-start",
                fontWeight: "var(--neuro-font-weight-bold)",
                fontSize: "var(--neuro-font-size-medium-smallest)",
                lineHeight: "120%",
                fontFamily: "var(--neuro-font-family-roboto-condensed)",
                marginTop: "1rem",
              }}
            >
              Your ABHA Details
            </Typography>
            {profileDetails?.abhaNumber ? (
              <Typography
                sx={{
                  marginTop: "0.875rem",
                  lineHeight: "120%",
                }}
              >
                ABHA Number: {profileDetails?.abhaNumber || "-"}
                <br />
                ABHA Address: {profileDetails?.abhaAddress || "-"}
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: isMobile ? "flex-start" : "center",
                  gap: "1rem",
                  marginTop: "0.875rem",
                  "@media (max-width: 37.5rem)": {
                    flexDirection: "column",
                  },
                }}
              >
                <Link
                  className="universal-link-white"
                  onClick={() => setAddAbhaAccountModalOpen(true)}
                >
                  Add ABHA details
                </Link>

                {!isMobile && (
                  <Divider
                    orientation={"vertical"}
                    sx={{
                      height: "1.188rem",
                      color: "var(--neuro-white-text)",
                      backgroundColor: "var(--neuro-white-text)",
                    }}
                  />
                )}
                <Link
                  className="universal-link-white"
                  onClick={() => setAadharModalOpen(true)}
                >
                  Create ABHA account
                </Link>

                {/* create abha account modal */}
                <AadharModal
                  open={aadharModalOpen}
                  onClose={onCloseAadharModal}
                  userId={user?.userId}
                />

                {/* add abha account modal */}
                <AddAbhaAccountModal
                  open={addAbhaAccountModalOpen}
                  onClose={() => setAddAbhaAccountModalOpen(false)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientInfoCard;
