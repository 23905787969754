// import { ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
// import ViewModuleIcon from "@mui/icons-material/ViewModule";
// import ViewListIcon from "@mui/icons-material/ViewList";
// import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";

// type ViewType = "card" | "table" | "thumbnail";

// const CardTableThumbnailToggle = ({
//   view,
//   setView,
// }: {
//   view: string;
//   setView: (value: string) => void;
// }) => {
//   // callbacks & functions
//   const handleChange = (_: any, newView: ViewType | null) => {
//     if (newView !== null) {
//       setView(newView);
//     }
//   };

//   return (
//     <Box sx={{ display: "flex", justifyContent: "center" }}>
//       <ToggleButtonGroup
//         value={view}
//         exclusive
//         onChange={handleChange}
//         aria-label="view mode"
//         sx={{
//           height: "2.25rem",
//           "& .MuiToggleButton-root": {
//             border: "none",
//             "&.Mui-selected": {
//               backgroundColor: "var(--neuro-button-bg-success)",
//               color: "var(--neuro-white-text)",
//               "&:hover": {
//                 backgroundColor: "var(--neuro-button-bg-success)",
//               },
//             },
//             "&:not(.Mui-selected)": {
//               backgroundColor: "var(--neuro-white-text)",
//               color: "var(--neuro-black-text)",
//               border: "1px solid var(--neuro-secondary_border)",
//             },
//           },
//         }}
//       >
//         <ToggleButton
//           value="card"
//           aria-label="card view"
//           sx={{
//             borderRadius: "3.125rem 0rem 0rem 3.125rem",
//             textTransform: "none",
//           }}
//         >
//           <ViewModuleIcon sx={{ mr: 1 }} />
//           Card
//         </ToggleButton>

//         <ToggleButton
//           value="table"
//           aria-label="table view"
//           sx={{
//             borderRadius: "0rem 3.125rem 3.125rem 0rem",
//             textTransform: "none",
//           }}
//         >
//           <ViewListIcon sx={{ mr: 1 }} />
//           Table
//         </ToggleButton>

//         <ToggleButton
//           value="thumbnail"
//           aria-label="thumbnail view"
//           sx={{
//             borderRadius: "0rem 3.125rem 3.125rem 0rem",
//             textTransform: "none",
//           }}
//         >
//           <ImageSearchOutlinedIcon sx={{ mr: 1 }} />
//           Thumbnail
//         </ToggleButton>
//       </ToggleButtonGroup>
//     </Box>
//   );
// };

// export default CardTableThumbnailToggle;

import { IconButton, Box } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import Tooltip from "../ui/Tooltip";

const CardTableThumbnailToggle = ({
  view,
  setView,
}: {
  view: string;
  setView: any;
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
      <Tooltip title="Card">
        <IconButton
          onClick={() => setView("card")}
          aria-label="card view"
          sx={{
            width: "2.25rem",
            height: "2.25rem",
            borderRadius: "50%",
            backgroundColor:
              view === "card"
                ? "var(--neuro-button-bg-success)"
                : "var(--neuro-white-text)",
            color:
              view === "card"
                ? "var(--neuro-white-text)"
                : "var(--neuro-black-text)",
            border: "1px solid var(--neuro-secondary_border)",
            "&:hover": {
              backgroundColor:
                view === "card"
                  ? "var(--neuro-button-bg-success)"
                  : "var(--neuro-hover-bg)",
            },
          }}
        >
          <ViewModuleIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Table">
        <IconButton
          onClick={() => setView("table")}
          aria-label="table view"
          sx={{
            width: "2.25rem",
            height: "2.25rem",
            borderRadius: "50%",
            backgroundColor:
              view === "table"
                ? "var(--neuro-button-bg-success)"
                : "var(--neuro-white-text)",
            color:
              view === "table"
                ? "var(--neuro-white-text)"
                : "var(--neuro-black-text)",
            border: "1px solid var(--neuro-secondary_border)",
            "&:hover": {
              backgroundColor:
                view === "table"
                  ? "var(--neuro-button-bg-success)"
                  : "var(--neuro-hover-bg)",
            },
          }}
        >
          <ViewListIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Thumbnail">
        <IconButton
          onClick={() => setView("thumbnail")}
          aria-label="thumbnail view"
          sx={{
            width: "2.25rem",
            height: "2.25rem",
            borderRadius: "50%",
            backgroundColor:
              view === "thumbnail"
                ? "var(--neuro-button-bg-success)"
                : "var(--neuro-white-text)",
            color:
              view === "thumbnail"
                ? "var(--neuro-white-text)"
                : "var(--neuro-black-text)",
            border: "1px solid var(--neuro-secondary_border)",
            "&:hover": {
              backgroundColor:
                view === "thumbnail"
                  ? "var(--neuro-button-bg-success)"
                  : "var(--neuro-hover-bg)",
            },
          }}
        >
          <ImageSearchOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CardTableThumbnailToggle;
