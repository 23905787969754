import api from "./apiService";

export const fetchForms = () => {
  return api.get(`/api/forms`);
};

export const assignFormApi = (data: any) => {
  // data - patientId, formId;
  return api.post(`/api/patients/forms`, data);
};

export const submitFormResponseApi = (patientFormId: string, data: any) => {
  // data - responses, score
  return api.put(`/api/patients/forms/${patientFormId}`, data);
};

export const fetchFormRequestsApi = (patientId: string) => {
  const params = {
    patientId,
  };
  return api.get(`/api/patients/forms`, {
    params,
  });
};
