import React, { useState } from 'react';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css'; // SurveyJS default styles




interface SurveyComponentProps {
  questionData: any; // Adjusted the type as the data is dynamic
}

const SurveyComponent: React.FC<SurveyComponentProps> = ({ questionData }) => {
  const [score, setScore] = useState<number | null>(null);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);

  // Flattening the page elements
  const flattenElements = (pages: any[]) => {
    return pages.map((page) => ({
      ...page,
      elements: page.elements.flat() // Flatten the nested array of elements
    }));
  };

  // Transforming questionData dynamically to flatten elements
  const json = {
    title: "Headache Impact Assessment",
    completedHtmlOnCondition: [
      {
        expression: "{totalScore} > 60",
        html: "Your headache impact score is {totalScore} out of {maxScore}. You may need to consult a healthcare professional for management."
      },
      {
        expression: "{totalScore} > 30 and {totalScore} <= 60",
        html: "Your headache impact score is {totalScore} out of {maxScore}. Consider tracking your headaches and exploring management strategies."
      },
      {
        expression: "{totalScore} <= 30",
        html: "Your headache impact score is {totalScore} out of {maxScore}. Your headaches seem to have minimal impact."
      }
    ],
    pages: flattenElements([
      ...(questionData?.pages || []), // Dynamically add pages from props
    ])
  };

  const handleComplete = (survey: any) => {
    let totalScore = 0;
    const totalScoredQuestions = survey.getAllQuestions().filter((q: any) => q.correctAnswer).length;

    survey.getAllQuestions().forEach((question: any) => {
      const userAnswer = question.value;
      const correctAnswer = question.correctAnswer;

      if (question.name === "totalScore") {
        totalScore = question.value;
      }

      if (Array.isArray(correctAnswer)) {
        if (Array.isArray(userAnswer) && userAnswer.length === correctAnswer.length && userAnswer.every((val) => correctAnswer.includes(val))) {
          totalScore += question.score || 0;
        }
      } else {
        if (userAnswer === correctAnswer) {
          totalScore += question.score || 0;
        }
      }
    });

    setScore(totalScore);
    setTotalQuestions(totalScoredQuestions);
  };

  return (
    <div>
      <Survey json={json} onComplete={handleComplete} />

      {score !== null && (
        <div style={{ marginTop: '20px' }}>
          <h3>Your Score: {score} out of {totalQuestions}</h3>
        </div>
      )}
    </div>
  );
};

export default SurveyComponent;
