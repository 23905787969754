import React from "react";
import { PatientsListProps } from "../../types/patients";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import { Patient } from "../../types/common";
import { TableWrapper } from "../shared/TableWrapper";
import { Typography } from "@mui/material";
import Tooltip from "../ui/Tooltip";

const PatientsList: React.FC<PatientsListProps> = ({
  patients,
  setSelectedPatient,
}) => {
  // props & state values
  const navigate = useNavigate();

  // callbacks & functions
  const TableRowClick = (row: any) => {
    setSelectedPatient(row || null);
    navigate(`/patients/${row?.id}/profile`);
  };

  // table columns
  const columns = [
    {
      id: "user.firstName",
      label: "Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.user?.firstName || "";
        const bName = b?.user?.firstName || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: Patient) =>
        `${capitalizeFirstLetter(row?.user?.firstName || "") || "-"}`,
    },
    {
      id: "mrnOrReferenceNumber",
      label: "MRN/Ref No",
      render: (_: any, row: Patient) =>
        `${row?.mrn ? row?.mrn : row?.referenceNumber || "-"}`,
    },
    {
      id: "user.mobile",
      label: "Mobile",
      sortComparator: (a: any, b: any) => {
        const aMobile = a?.user?.mobile || "";
        const bMobile = b?.user?.mobile || "";

        // Move empty or null values to the end
        if (aMobile === "") return 1;
        if (bMobile === "") return -1;

        // Normal locale comparison for non-empty values
        return aMobile.localeCompare(bMobile);
      },
      render: (_: any, row: Patient) => `${row?.user?.mobile || "-"}`,
      sortable: true,
    },
    {
      id: "user.email",
      label: "Email",
      sortComparator: (a: any, b: any) => {
        const aEmail = a?.user?.email || "";
        const bEmail = b?.user?.email || "";

        // Move empty or null values to the end
        if (aEmail === "") return 1;
        if (bEmail === "") return -1;

        // Normal locale comparison for non-empty values
        return aEmail.localeCompare(bEmail);
      },
      render: (_: any, row: Patient) => (
        <Tooltip title={row?.user?.email || "Email not available"}>
          <Typography
            sx={{
              width: "12.5rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.user?.email || "-"}
          </Typography>
        </Tooltip>
      ),
      sortable: true,
    },
    
    {
      id: "gender",
      label: "Gender",
      render: (value: any) => value || "-",
      sortable: true,
    },
    {
      id: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (value: string) => `${getFormattedDate(value || "") || "-"}`,
    },
    {
      id: "isFirstAppointmentCompleted",
      label: "First Visit",
      sortable: true,
      render: (value: boolean) =>
        value ? (
          <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />
        ) : (
          <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />
        ),
    },
  ];

  return (
    <>
      <TableWrapper
        columns={columns}
        rows={patients}
        stickyHeader={true}
        maxHeight="100%"
        onRowClick={TableRowClick}
        rowSx={{
          cursor: "pointer",
        }}
      />
    </>
  );
};

export default PatientsList;
