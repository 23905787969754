import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import LoadingScreen from "../../shared/LoadingScreen";
import DocumentGrid from "./DocumentGrid";
import ContentCenter from "../../shared/ContentCenter";
import { DocumentsSectionProps } from "../../../types/documents";
import DocumentsTableView from "../view/DocumentsTableView";
import DocumentThumbnailView from "../view/DocumentThumbnailView";

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  isMenuCollapsed,
  loading,
  error,
  documents,
  patientId,
  view = "table",
  from = "",
}) => (
  <>
    {loading && <LoadingScreen />}

    {error && (
      <ContentCenter>
        <Typography className="no-data">Documents not found</Typography>
      </ContentCenter>
    )}

    {!loading && !error && documents && documents.length === 0 && (
      <ContentCenter>
        <Typography className="no-data">Documents not found</Typography>
      </ContentCenter>
    )}

    {!loading && !error && documents && documents.length > 0 && (
      <>
        {view === "card" && (
          <DocumentGrid
            documents={documents}
            isMenuCollapsed={isMenuCollapsed}
            from="list"
            isFromMeet={from === "meet" ? true : false}
            patientId={patientId}
          />
        )}
        {view === "table" && (
          <DocumentsTableView
            documents={documents}
            patientId={patientId}
            from="list"
            isFromMeet={from === "meet" ? true : false}
          />
        )}
        {view === "thumbnail" && (
          <Box
            sx={{
              maxHeight: "calc(100% - 6.25rem)",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2.5}>
              {documents?.map((document) => (
                <Grid item xs={12} sm={6} md={3} lg={12 / 5} key={document.id}>
                  <DocumentThumbnailView
                    {...document}
                    patientId={patientId}
                    from="list"
                    isFromMeet={from === "meet" ? true : false}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </>
    )}
  </>
);

export default DocumentsSection;
