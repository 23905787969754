import React, { useRef, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Box,
  Popper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useAppStore from "../../store/appStore";
import Button from "../ui/Button";
import { getStartAndEndDateTimesForRange } from "../../utils/common";

function DashboardFilter({
  updateRange,
  updateDates,
  range,
  isEmpty = false,
}: {
  updateRange: any;
  updateDates: any;
  range: string;
  isEmpty?: boolean;
}) {
  // props & state values
  const { showSnackbar } = useAppStore();
  const [open, setOpen] = useState(false);

  const today = new Date().toISOString().split("T")[0];

  const [popperOpen, setPopperOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const anchorRef = useRef<null | HTMLElement>(null);

  console.log("startDate, endDate", startDate, endDate);
  console.log("range in dashboard filter", range);

  const menuItems = [
    {
      value: "TODAY",
      label: "Today",
    },
    {
      value: "YESTERDAY",
      label: "Yesterday",
    },
    {
      value: "THIS_WEEK",
      label: "This Week",
    },
    {
      value: "LAST_WEEK",
      label: "Last Week",
    },
    {
      value: "THIS_MONTH",
      label: "This Month",
    },
    { value: "LAST_MONTH", label: "Last Month" },
    { value: "THIS_YEAR", label: "This Year" },
    { value: "LAST_YEAR", label: "Last Year" },
    { value: "CUSTOM", label: "Custom Range" },
  ];

  // callbacks & functions
  function convertToUTC(dateStr: string): string {
    const localDate = new Date(dateStr);
    return localDate.toISOString();
  }

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    updateRange(selectedValue);

    if (selectedValue !== "CUSTOM") {
      const { start, end } = getStartAndEndDateTimesForRange(selectedValue);
      updateDates(start, end);
    }

    if (selectedValue === "CUSTOM") {
      setPopperOpen(true);
    } else {
      setPopperOpen(false);
    }
  };

  const handleApply = (event: any) => {
    event.preventDefault();

    if (startDate === "") {
      showSnackbar("Start date is required", "error");
      return;
    }
    if (endDate === "") {
      showSnackbar("End date is required", "error");
      return;
    }
    const startDateUtc = convertToUTC(startDate);
    const endDateUtc = convertToUTC(endDate);
    updateDates(startDateUtc, endDateUtc);

    setPopperOpen(false);
  };

  return (
    <>
      <FormControl sx={{ minWidth: "8.75rem", minHeight: "1.25rem" }}>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          ref={anchorRef}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={range}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          displayEmpty={isEmpty ? true : false}
          sx={{
            height: "2.25rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "1.875rem",
            border: "1px solid var(--neuro-secondary_border)",
            boxShadow: "none !important",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // No border by default
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // Light border on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // No extra border on focus
            },
            "&.Mui-focused .MuiOutlinedInput-root": {
              borderColor: "transparent", // Override focus state
            },
            ".MuiSelect-select": {
              padding: "0.5rem 1rem",
              display: "flex",
              alignItems: "center",
            },
            ".MuiSvgIcon-root": {
              color: "var(--neuro-black-text)",
            },
          }}
        >
          {isEmpty && <MenuItem value="">Select range</MenuItem>}
          {menuItems?.map((menu, i) => (
            <MenuItem
              key={i}
              value={menu?.value}
              sx={{
                backgroundColor:
                  range === menu?.value
                    ? "var(--neuro-button-bg-success) !important"
                    : "inherit",
                color:
                  range === menu?.value
                    ? "var(--neuro-white-text) !important"
                    : "inherit",
              }}
            >
              {menu?.label}
            </MenuItem>
          ))}
        </Select>

        <Popper
          open={popperOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          sx={{
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              p: "1rem",
              backgroundColor: "var(--neuro-white-text)",
              borderRadius: "0.5rem",
              border: "1px solid var(--neuro-secondary_border)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.875rem",
              }}
              component="form"
            >
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputProps={{ inputProps: { max: today } }}
                InputLabelProps={{ shrink: true }}
                required
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputProps={{ inputProps: { max: today } }}
                InputLabelProps={{ shrink: true }}
                required
              />
              <Button
                type="submit"
                variant="contained"
                className="primary-button"
                onClick={handleApply}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Popper>
      </FormControl>
    </>
  );
}

export default React.memo(DashboardFilter);
