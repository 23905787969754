import  { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ComponentHeader from "../shared/ComponentHeader";import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import { capitalizeFirstLetter } from "../../utils/common";
import Button from "../ui/Button";
import useAppStore from "../../store/appStore";
import { TableWrapper } from "../shared/TableWrapper";
import CardTableToggle from "../shared/CardTableToggle";
import useAuthStore from "../../store/authStore";
import { isClinicAdmin } from "../../utils/auth";
import ComponentFilter from "../shared/ComponentFilter";
import { fetchFormRequestsApi, getFormResponseApisuryve, submitFormResponseApisuryvegetcall } from "../../services/formService";
import FormCreatorViewEdit from "./FormCreatorViewEditUsinId";

const FormListEdit = () => {


  const [apidata, setApidata] = useState<any[]>([]); // Use an empty array as initial state

  const [view, setView] = useState(
    () => localStorage.getItem("hospitalsView") || "table"
  );


  const [showList, setShowList] = useState(true);
  const [showListformedit, _] = useState(true);
  
  const { userRole } = useAuthStore();
  const {  clinicsFilter , setSelectedFormInList} = useAppStore();

  const navigate = useNavigate(); // Initialize navigate function from react-router-dom

  
  const {
    setShowFormSubmenus,
  } = useAppStore();


  const handleAddNew = () => {
    setShowFormSubmenus(true);
    localStorage.removeItem("formid");
    localStorage.setItem("isDisabled","true")
    navigate(`/form/add/?radio=`);

    
 
  };
  useEffect(()=>{
    setShowFormSubmenus(false)
  },[])


  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFormResponseApisuryve();
        const sortedData = response.data.data.sort(
          (a:any, b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setApidata(sortedData); // Store sorted data in apidata
        console.log("Data fetched and sorted successfully:", sortedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await submitFormResponseApisuryvegetcall("cm4492w310022zxuh5dfq9ogp");
  //       console.log("response", response.data.data);
  
  //       // Check if the response is an array or a single object
  //       let sortedData;
  //       if (Array.isArray(response.data.data)) {
  //         sortedData = response.data.data.sort(
  //           (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  //         );
  //       } else {
  //         // If it's a single object, wrap it in an array
  //         sortedData = [response.data.data];
  //       }
  
  //       setApidata(sortedData); // Store sorted data or the single object as an array
  //       console.log("Data fetched and processed successfully:", sortedData);
  //     } catch (err) {
  //       console.error("Error fetching data:", err);
  //     }
  //   };
  
  //   fetchData();
  // }, [5000]);
  


  const columns = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.name || "") || "-"}`,
    },
    // {
    //   id: "disorder",
    //   label: "Disorder",
    //   sortable: true,
    //   render: (_: any, row: any) =>
    //     `${row?.disorderId || "-"}`, // Adjust disorder rendering
    // },
    {
      id: "type",
      label: "Form Type",
      render: (value: string) => value || "-",
      sortable: true,
    },
    {
      id: "isScoreBased",
      label: "ScoreBased",
      render: (value: boolean) => (value ? "Yes" : "No"),
      sortable: true,
    },
    {
      id: "filledBy",
      label: "Filled By",
      sortable: true,
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.filledBy || "") || "-"}`,
    },
    // {
    //   id: "createdAt",
    //   label: "Created At",
    //   sortable: true,
    //   render: (_: any, row: any) => new Date(row?.createdAt).toLocaleString(),
    // },
        {
      id: "questions",
      label: "Form created",
      sortable: true,
      render: (value: boolean | null) =>
        value !== null ? (
          <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />
        ) : (
          <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />
        ),
    },
  ];


  // Handle table row click
  const handleRowClick = (row: any) => {
    const { id } = row;
    localStorage.setItem("isDisabled","false")
    navigate(`/forms/${id}/edit`);
    // navigate(`/forms/${id}/settings`); // Navigate to the form details page with the selected ID
    localStorage.setItem("formid" , id);
    setSelectedFormInList(row);
    setShowList(!showList);
  };

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("hospitalsView", updatedView);
      return updatedView;
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
        }}
      >
        {showList && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <ComponentHeader title="Form List" />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.5rem",
                }}
              >
                {!isClinicAdmin(userRole) && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="outlined-secondary-button"
                    startIcon={<AddIcon />}
                    onClick={handleAddNew}
                    sx={{
                      height: "2.25rem",
                    }}
                  >
                    Add New
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
              <ComponentFilter
                updateRange={() => {}}
                updateDates={() => {}}
                range={clinicsFilter.range}
                isEmpty
                viewComponent={
                  <CardTableToggle view={view} setView={handleToggleView} />
                }
                hideStatusFilter
                handleStoreSearchValueChange={() => {}}
                searchValue={clinicsFilter.searchValue || ""}
                searchFilterPlaceholder="Name , Filled By, ScoreBased"
                searchFilterWidth="17.3rem"
              />
            </Box>
            <TableWrapper
  columns={columns}
  rows={apidata} // Pass the sorted data
  stickyHeader={true}
  maxHeight="calc(100% - 8rem)"
  onRowClick={handleRowClick} // Add onRowClick handler
  rowSx={{ cursor: "pointer" }}
/>

          </>
        )}

        {!showList && (
          // <AddEditFormPage
          //   hospital={editHospital} // Pass the selected hospital data to the form
          //   loadHospitals={getUpdatedClinicsAfterAddEdit}
          //   onClose={() => setShowList(true)} // Close and go back to the list view
          // />
          <></>
        )}
           {!showListformedit && (
          <FormCreatorViewEdit />
        )}
      </Box>
    </>
  );
};

export default FormListEdit;
