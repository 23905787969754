import React from "react";
import { Box, Typography } from "@mui/material";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { SignedUpModalProps } from "../../types/dashboard";
import useProfileStore from "../../store/profileStore";

const SignedUpModal: React.FC<SignedUpModalProps> = ({
  open,
  handleClose,
  mrnNumber,
}) => {
  // props & state values
  const { profileDetails } = useProfileStore();

  return (
    <Modal
      open={open}
      closeIcon
      onClose={handleClose}
      width="37.5rem" // Customize width if needed
      //   height={400}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1.25rem",
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Typography
          sx={{
            textAlign: "center",
            // fontWeight: "var(--neuro-font-weight-bold)",
            marginBottom: "1rem",
          }}
        >
          You are successfully registered with 22Neuro Clinic.
          <br />
          <span className="bold-text">
            Your Reference No is{" "}
            {mrnNumber || profileDetails?.referenceNumber || "-"}
          </span>
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          Our team will reach out to you shortly to assist with booking your
          appointment.
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          We appreciate your patience and look forward to serving you.
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          className="outlined-secondary-button"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleClose();
          }}
          sx={{
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default SignedUpModal;
