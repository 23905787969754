import React from "react";
import { Box, Typography } from "@mui/material";
import { DisorderFormProps } from "../../../types/appointments";

const DisorderInfo: React.FC<DisorderFormProps> = ({ selectedDisorder }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "11px",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "120%",
          fontFamily: "Inter",
          color: "var(--neuro-black-text)",
        }}
      >
        Info
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          color: "var(--neuro-black-text)",
          lineHeight: "26px",
        }}
      >
        {selectedDisorder?.disorderInfo}
      </Typography>
    </Box>
  );
};

export default DisorderInfo;
