const BorderIcon = (props: any) => (
  <svg
    width={63}
    height={140}
    viewBox="0 0 63 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_3074_574" fill="white">
      <path d="M0 12C0 5.37258 5.37258 0 12 0H63V140H0V12Z" />
    </mask>
    <path
      d="M-2 12C-2 4.26801 4.26801 -2 12 -2H63V2H12C6.47715 2 2 6.47715 2 12H-2ZM63 140H0H63ZM-2 140V12C-2 4.26801 4.26801 -2 12 -2V2C6.47715 2 2 6.47715 2 12V140H-2ZM63 0V140V0Z"
      fill="#2581C4"
      mask="url(#path-1-inside-1_3074_574)"
    />
  </svg>
);
export default BorderIcon;
