import  { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ContentModal from "../shared/FullscreenModal";
import "./fullscreenviewfile.css";
import FormSidebarcomp from "./FormSidebarcomp";

import { useNavigate } from "react-router-dom";

import FormpagesEdit from "./FormEdit/FormpagesEdit";

const FormViewFullScreen = () => {
  const [fullScreenModalOpen] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("Form Details");

  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [submitTriggerQuestion, setSubmitTriggerQuestion] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleParentSubmit = () => {
    console.log("Tab clicked:", "handleParentSubmit");
    setSubmitTrigger(prev => !prev); // Toggle submitTrigger
  };
  const handleQuestionFormSubmit = () => {
    console.log("Tab clicked:", "handleQuestionFormSubmit");
    setSubmitTriggerQuestion(prev => !prev); // Toggle submitTrigger
  };

  const [isDisabled, setIsDisabled] = useState(localStorage.getItem("isDisabled") === "true");
  const [_, setIsScoreBasedData] = useState(localStorage.getItem("isScoreBased") === "true");

  useEffect(() => {
    // Listen for changes to localStorage
    const handleStorageChange = () => {
      // Check if 'isDisabled' or 'isScoreBased' has changed
      const updatedIsDisabled = localStorage.getItem("isDisabled") === "true";
      const updatedIsScoreBased = localStorage.getItem("isScoreBased") === "true";

      // Update respective states
      setIsDisabled(updatedIsDisabled);
      setIsScoreBasedData(updatedIsScoreBased);
    };

    // Add event listener
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on unmount
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const handleTabClick = (tab: string) => {
    if (!isDisabled) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (isDisabled) {
      setActiveTab("Form Details");
    } else {
      setActiveTab("Form Designer");
    }
  }, [isDisabled]);

  //   const toggleSidebar = () => {
  //       if (!isDisabled) {
  //           console.log("Sidebar toggled");
  //       }
  //   };



  // const handleTabClick = useCallback((tab: string) => {
  //     setActiveTab(tab);
  //     console.log("Tab clicked:", tab); // Debugging log
  // }, []);
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(`/forms`);
  }



  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ContentModal open={fullScreenModalOpen}>
      <Box
        sx={{
          backgroundColor: "var(--neuro-white-text)",
          height: "100%",
          padding: "0rem",
          paddingBottom: 0,
        }}
      >
        <div className="fullscreen-container">
          {/* Primary Header */}
          <header className="fullscreen-header primary-header">
            <h1>Create New Form</h1>
            <div className="fullscreen-header-actions">
    {activeTab === "Form Details" ? (
      <>

{/* <button className="fullscreen-btn fullscreen-save-btn success-button" style={{border:'none'}} >Save Draft</button> */}
        <button className="fullscreen-btn fullscreen-save-btn success-button"  style={{border:'none'}} onClick={handleParentSubmit}>Save</button>
        </> ) : (
        <>
            {/* {UserId !== "" ? (
                <button className="fullscreen-btn fullscreen-save-btn" onClick={handleQuestionFormSubmit}>Save Next</button>
            ) : (
                <button className="fullscreen-btn fullscreen-save-btn" onClick={handleQuestionFormSubmit}>Save</button>
            )} */}


                         <button className="fullscreen-btn fullscreen-save-btn success-button" style={{border:'none'}} onClick={handleQuestionFormSubmit}>Save</button>
        </>
    )}
    <button className="fullscreen-btn fullscreen-discard-btn" onClick={handleCancel}>Discard</button>

    {/* <button className="fullscreen-btn fullscreen-discard-btn" >Publish</button> */}
</div>

          </header>

          {/* Secondary Header */}
          <header className="fullscreen-header secondary-header">
            <div></div>
            <nav className="fullscreen-nav">
              <ul>
                {[
                  "Form Details",
                  "Form Designer",
                  "Preview",
                  "Themes",
                  "Logic",
                  "JSON Editor",
                
                ].map((tab) => (
                  <li
                    key={tab}
                    className={`fullscreen-nav-item ${activeTab === tab ? "active" : ""
                      } ${isDisabled && tab !== "Form Details" ? "disabled" : ""}`}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </nav>

            <div
              onClick={toggleSidebar}
              style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
              className={isDisabled ? "disabled" : ""}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
              >
                <path
                  d="M1 3.25H14.5"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M1 11.125H7.75"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M12.25 11.125H19"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M5.5 19H19"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M16.75 5.5C17.9926 5.5 19 4.49264 19 3.25C19 2.00736 17.9926 1 16.75 1C15.5074 1 14.5 2.00736 14.5 3.25C14.5 4.49264 15.5074 5.5 16.75 5.5Z"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10 13.375C11.2426 13.375 12.25 12.3676 12.25 11.125C12.25 9.88236 11.2426 8.875 10 8.875C8.75736 8.875 7.75 9.88236 7.75 11.125C7.75 12.3676 8.75736 13.375 10 13.375Z"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M3.25 21.25C4.49264 21.25 5.5 20.2426 5.5 19C5.5 17.7574 4.49264 16.75 3.25 16.75C2.00736 16.75 1 17.7574 1 19C1 20.2426 2.00736 21.25 3.25 21.25Z"
                  stroke="#62B55A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </header>


          <div className="fullscreen-body">
            <FormSidebarcomp isDisabled={isDisabled} />
            {/* Main Content */}
            <main className="fullscreen-main">
              <div className="fullscreen-content">
                {/* {isScoreBasedData ? <>
                  <Formpages navlinks={activeTab} submitTrigger={submitTrigger} submitTriggerQuestion={submitTriggerQuestion} />
                </> : <>
                  <FormpagesNotvalue navlinks={activeTab} submitTrigger={submitTrigger} submitTriggerQuestion={submitTriggerQuestion} />
                </>} */}

             <FormpagesEdit navlinks={activeTab} submitTrigger={submitTrigger} submitTriggerQuestion={submitTriggerQuestion} />
                {/* <Formpages navlinks={activeTab} submitTrigger={submitTrigger} submitTriggerQuestion={submitTriggerQuestion}/> */}

              </div>
            </main>

            {/* Right Sidebar */}
            <aside className={` ${isOpen ? "fullscreen-sidebar right-sidebar" : "closed"}`}>

              {/* Add right sidebar content */}
            </aside>
          </div>
        </div>
      </Box>
    </ContentModal>
  );
};

export default FormViewFullScreen;
